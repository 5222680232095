<template>
    <div class="form-entry">
        <div class="text-center form-entry__header">
<!--            <div>-->
<!--                <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">-->
<!--                    <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}-->
<!--                </button>-->
<!--            </div>-->
            <a v-on:click="$router.push({ path: '/' })" class="logo-link"><img src="../assets/logo-home.svg"/></a>
            <h1>{{ $t('commonSignInMsg')}}</h1>
        </div>

        <template v-if="isSigningIn">
            <form v-on:submit.prevent="signIn()">
                <ul>
                    <li v-if="showPasswordResetMessage" class="text-info">
                        <p>{{ $t('signInPasswordIsResetMsg')}}</p>
                    </li>
                    <li>
                        <label for="username">{{ $t('commonLabelUsernameMsg')}}</label>
                        <input type="text" id="username" v-model="username">
                        <p v-if="showValidateErrorUsername" class="form-entry__error">
                            {{ $t('commonEnterUsernameMsg')}}
                        </p>
                    </li>
                    <li>
                        <label for="password">{{ $t('commonPasswordMsg')}}</label>
                        <input type="password" ref="passwordInput" id="password" v-model="password">
                        <div class="form-entry--show-password">
                            <input id="showPassword" type="checkbox" v-model="showPassword">
                            <label for="showPassword">{{ $t('commonShowPasswordMsg')}}</label>
                        </div>
                        <p v-if="showValidateErrorPassword" class="form-entry__error">
                            {{ $t('commonEnterPasswordMsg')}}
                        </p>
                    </li>
                    <li v-if="errorMessage" class="text-center">
                        <p class="form-entry__error">
                            {{ $t('signInErrorSigningInMsg')}} {{errorMessage}}
                        </p>
                    </li>
                    <li v-show="processing">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </li>
                    <li class="form-entry__cta" v-show="!processing">
                        <button type="submit">Sign in</button>
                        <p class="text-center">{{ $t('signInDontHaveAccountMsg')}} <a href="#" v-on:click="$router.push({ path: '/register' })">{{ $t('signInRegisterMsg')}}</a></p>
                        <p class="text-center">{{ $t('signInForgotPasswordMsg')}} <a href="#" v-on:click="$router.push({ path: '/password-reset' })">{{ $t('commonResetPasswordMsg')}}</a></p>
                    </li>
                </ul>
            </form>
        </template>

        <template v-if="isVerifying">
            <form v-on:submit.prevent="verifyPassword()">
                <ul>
                    <li>
                        <label for="passwordVerified">{{ $t('signInVerifyPasswordMsg')}}</label>
                        <input type="password" ref="passwordVerifiedInput" id="passwordVerified" v-model="passwordVerified">
                        <div class="form-entry--show-password">
                            <input id="showVerifyPassword" type="checkbox" v-model="showPasswordVerified">
                            <label for="showVerifyPassword">{{ $t('commonShowPasswordMsg')}}</label>
                        </div>
                        <p v-if="showValidateErrorPasswordVerified" class="form-entry__error">
                            {{ $t('signInEnterPasswordAgainMsg')}}
                        </p>
                        <p v-if="showValidateErrorPasswordVerifiedMismatch" class="form-entry__error">
                            {{ $t('signInPasswordMismatchMsg')}}
                        </p>
                    </li>
                    <li v-if="errorMessage" class="text-center">
                        <p class="form-entry__error">
                            {{ $t('signInErrorSigningInMsg')}} {{errorMessage}}
                        </p>
                    </li>
                    <li v-show="processing">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </li>
                    <li class="form-entry__cta" v-show="!processing">
                        <button type="submit">{{ $t('signInVerifyMsg')}}</button>
                        <p class="text-center">{{ $t('signInDontHaveAccountMsg')}} <a href="#" v-on:click="$router.push({ path: '/register' })">{{ $t('signInRegisterMsg')}}</a></p>
                        <p class="text-center">{{ $t('signInForgotPasswordMsg')}} <a href="#" v-on:click="$router.push({ path: '/password-reset' })">{{ $t('commonResetPasswordMsg')}}</a></p>
                    </li>
                </ul>
            </form>
        </template>

    </div>
</template>

<script>
    import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
    import { Auth } from 'aws-amplify';

    // Queries & mutations
    import {canMigrateCognitoUser} from "../mytutor-graphql/queries";
    import {migrateCognitoUser} from "../mytutor-graphql/mutations";

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";
    import {i18nMixin} from "../mixins/I18nMixin";

    import Spinner from '../components/Spinner';

    export const SIGNIN_STATES = Object.freeze({
        SIGNING_IN: 0,
        VERIFYING: 1,
    });


    export default {
        name: "SignIn",
        components: {
            Spinner
        },
        created() {
        },
        mixins: [apiMixin, i18nMixin],
        data() {
            return {
                state: SIGNIN_STATES.SIGNING_IN,
                username: "",
                password: "",
                showPasswordResetMessage: false,
                showPassword: false,
                passwordVerified: "",
                showPasswordVerified: false,
                showValidateErrorUsername: false,
                showValidateErrorPassword: false,
                showValidateErrorPasswordVerified: false,
                showValidateErrorPasswordVerifiedMismatch: false,
                processing: false,
                errorMessage: ""
            }
        },
        computed: {
            isSigningIn: function() {
                return this.state === SIGNIN_STATES.SIGNING_IN;
            },
            isVerifying: function() {
                return this.state === SIGNIN_STATES.VERIFYING;
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            signInToNewUserPool: async function() {
                try {
                    const user = await Auth.signIn(this.username, this.password);
                    console.debug("Signed in with Auth");
                    return user;
                } catch (error) {
                    console.debug('Error signing in', error);
                    if (error.message) {
                        this.errorMessage = error.message;
                    } else {
                        this.errorMessage = this.$t('commonUnknownErrorMsg');
                    }
                    return null;
                }
            },
            canMigrateUserFromOldPool: async function(username) {
                console.debug('checking if can migrate the user: ' + username);
                try {
                    let variables = {
                        username: username,
                    };
                    const canMigrateUser = await API.graphql({
                        query: canMigrateCognitoUser,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(canMigrateUser);
                    if (canMigrateUser.data !== null) {
                        console.debug("Answer received");
                        if (canMigrateUser.data.canMigrateCognitoUser) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        console.debug("Error checking if can migrate user");
                        this.logError("Checking if can migrate user", "No data returned", true);
                        return false;
                    }
                } catch (error) {
                    this.logAPIError("Checking if can migrate user", error, true);
                    return false;
                }
            },
            migrateUserFromOldPool: async function(username, password) {
                console.debug('trying to migrate the user: ' + username);
                try {
                    let variables = {
                        username: username,
                        password: password
                    };
                    const migratedUser = await API.graphql({
                        query: migrateCognitoUser,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(migratedUser);
                    if (migratedUser.data !== null) {
                        console.debug("Answer received");
                        if (migratedUser.data.migrateCognitoUser) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        console.debug("Error migrating user");
                        this.logError("Migrating user", "No data returned", true);
                        return false;
                    }
                } catch (error) {
                    this.logAPIError("Migrating user", error, true);
                    return false;
                }
            },

            // ----------------------------------------------------------------------------
            //
            // Functionality
            //
            // ----------------------------------------------------------------------------
            signIn: async function() {
                console.debug("validating sign-in form");
                this.errorMessage = "";
                if (!this.validateSignInForm()) {
                    return;
                }

                console.log("Signing in: " + this.username);
                this.processing = true;

                // try to sign in
                try {
                    const user = await Auth.signIn(this.username, this.password);
                    console.debug("Signed in with Auth");

                    // success, tell App that we've signed in
                    this.$root.$emit('manual-sign-in', user);

                } catch (error) {

                    // otherwise we have an error
                    console.debug('Error signing in', error);

                    // if the user can't be found
                    if (error.code === "UserNotFoundException") {

                        // call our migration service
                        const retry = await this.canMigrateUserFromOldPool(this.username);

                        if (retry) {
                            // if we can migrate, confirm password
                            this.state = SIGNIN_STATES.VERIFYING;
                        } else {
                            // else inform the user that signing in failed
                            console.log("Could not migrate, informing user");
                            this.errorMessage = this.$t('signInUserDoesNotExistMsg');
                        }
                    } else {
                        if (error.message) {
                            this.errorMessage = error.message;
                        } else {
                            this.errorMessage = this.$t('commonUnknownErrorMsg');
                        }
                    }
                    this.processing = false;
                }
            },
            validateSignInForm: function () {
                let validated = true;

                this.showValidateErrorUsername = false;
                this.showValidateErrorPassword = false;

                if (!this.username) {
                    this.showValidateErrorUsername = true;
                    validated = false;
                }
                if (!this.password) {
                    this.showValidateErrorPassword = true;
                    validated = false;
                }
                return validated;
            },
            verifyPassword: async function() {
                console.debug("validating verify password form");
                this.errorMessage = "";
                if (!this.validateVerifyPasswordForm()) {
                    return;
                }

                console.log("Migrating user: " + this.username);
                this.processing = true;

                // otherwise call our migration service
                const migrated = await this.migrateUserFromOldPool(this.username, this.password);

                if (migrated) {
                    console.log("Migration successful, retrying");
                    let user = await this.signInToNewUserPool();
                    if (user) {
                        // success, tell App that we've signed in
                        this.$root.$emit('manual-sign-in', user);
                    }
                } else {
                    console.log("Could not migrate, informing user");
                    // inform the user that signing in failed
                    this.errorMessage = this.$t('commonUnknownErrorMsg');
                    this.processing = false;
                }

            },
            validateVerifyPasswordForm: function() {
                let validated = true;

                this.showValidateErrorPasswordVerified = false;
                this.showValidateErrorPasswordVerifiedMismatch = false;

                if (!this.passwordVerified) {
                    this.showValidateErrorPasswordVerified = true;
                    validated = false;
                    return validated;
                }
                if (this.passwordVerified !== this.password) {
                    this.showValidateErrorPasswordVerifiedMismatch = true;
                    validated = false;
                }
                return validated;
            },
        },
        watch: {
            'showPassword': function () {
                if (this.showPassword) {
                    this.$refs.passwordInput.type = "text";
                } else {
                    this.$refs.passwordInput.type = "password";
                }
            },
            'showPasswordVerified': function () {
                if (this.showPasswordVerified) {
                    this.$refs.passwordVerifiedInput.type = "text";
                } else {
                    this.$refs.passwordVerifiedInput.type = "password";
                }
            }
        },
        mounted() {
            var searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("password-reset")) {
                this.showPasswordResetMessage = true;
            }
        },
        beforeDestroy: async function () {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
</style>

<style lang="scss" scoped>
    form {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px 30px;
    }

    @media screen and (max-width:414px) {
        form {
            padding: 20px 10px;
        }
    }
</style>