<template>
    <!-- Main chat component -->
    <div class="chat-window">
        <header-bar></header-bar>

        <div class="chat-window__body">

            <div class="chat-window__conv" ref="chatContent" @scroll="scrollChat">
                <div class="chat-window__conv__scrollable">
                    <div class="chat-window__loading" v-show="loadingChat">
                        <spinner height="50px" colour="#1C1A4F"/>
                    </div>
                    <div v-if="user && hasLog">
                        <chat-line v-for="logEntry in log" v-bind:key="logEntry.id" v-bind:chatLine="logEntry"
                                   v-bind:user="user"
                                   v-bind:questionID="logEntry.questionID"/>
                    </div>
                </div>
            </div>

            <div class="chat-window-resp">
                <div v-show="acceptResponse" class="chat-window-resp--student">
                    <div class="chat-window-resp__text">
                        <input type="text" v-model="response" class="chat-window-resp__input"
                               v-bind:disabled="!acceptResponse"
                               v-on:keyup="autoComplete()" v-on:keyup.enter="processResponse()" ref="responseInput"
                               v-bind:placeholder="placeholderText" v-on:keydown="invalidResponse=false">
                        <div class="chat-window-resp__message" v-show="invalidResponse">
                            <div v-html="$t('chatInvalidAnswerMsg')"></div>
                        </div>
                        <div class="chat-window-resp__message chat-window-resp__message--choices"
                             v-show="showAutoComplete">
                            <div>
                                <ul>
                                    <li v-for="(item, index) in autoCompleteOptions" v-bind:key="index"
                                        v-on:click="selectAutoItem(item)">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button id="sendButton" class="chat-window-resp__button" v-on:click="processResponse()">
                        <div class="chat-window-resp__button-text">&#x27A4;</div>
                    </button>
                </div>
                <div v-show="!acceptResponse" class="chat-window-resp--mytutor">
                    {{loadingMessage}}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {API, graphqlOperation} from "@aws-amplify/api";

    import {store} from '../store.js'
    import {countries} from '../countries.js'

    // Generated queries & mutations
    import {logEntriesByByUserAndDate, getNextMessages} from "../graphql/queries";

    // Our custom queries & mutations
    import {searchSchools} from "../mytutor-graphql/queries";

    // Visual components
    import HeaderBar from '../components/Header.vue';
    import Spinner from '../components/Spinner.vue';
    import ChatLine from '../components/ChatLine.vue';

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    export default {
        name: 'Chat',
        components: {
            HeaderBar,
            Spinner,
            ChatLine,
        },
        mixins: [apiMixin],
        created() {
            // Declare constants. Declared here they are not added to the reactive system. Later can go to plugin / store.
            this.PAGE_SIZE = 10;
        },
        data() {
            return {
                log: [],
                loadingChat: false,
                nextTokenHistory: null,
                acceptResponse: false,
                loadingMessage: this.$t('chatBusyLoadingMsg'),
                invalidResponse: false,
                showAutoComplete: false,
                autoCompleteOptions: [],
                response: '',
            }
        },
        computed: {
            user: function () {
                return store.user;
            },
            hasLog: function () {
                return this.log && (this.log.length > 0);
            },
            getLastLogEntry: function () {
                if (this.hasLog) {
                    return this.log[this.log.length - 1];
                }
                return null;
            },
            placeholderText: function () {
                console.log(store.user);
                if (this.hasLog) {
                    // const lastLogEntry = store.user.getLastLogEntry();
                    const lastLogEntry = this.getLastLogEntry;
                    console.log(lastLogEntry);
                    if (lastLogEntry.placeholderText !== null && lastLogEntry.placeholderText !== '') {
                        return lastLogEntry.placeholderText;
                    }
                }
                return "";
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            fetchChatHistory: async function (initialLoad) {
                if (!initialLoad && this.nextTokenHistory === null) {
                    console.log("Fetched all history");
                    return [];
                }

                console.log('fetching chat history for user with ID: ' + store.user.getID())
                try {
                    var historyResponse = null;
                    if (initialLoad) {
                        historyResponse = await API.graphql(graphqlOperation(logEntriesByByUserAndDate, {
                            userID: store.user.getID(),
                            limit: this.PAGE_SIZE,
                            sortDirection: "DESC"
                        }));
                    } else {
                        historyResponse = await API.graphql(graphqlOperation(logEntriesByByUserAndDate, {
                            userID: store.user.getID(),
                            limit: this.PAGE_SIZE,
                            sortDirection: "DESC",
                            nextToken: this.nextTokenHistory
                        }));
                    }

                    if (historyResponse.data) {
                        this.nextTokenHistory = historyResponse.data.logEntriesByByUserAndDate.nextToken
                        // they are in the reverse order, so need to reverse before displaying / sending on
                        return historyResponse.data.logEntriesByByUserAndDate.items.reverse();
                    } else {
                        console.log("Error fetching chat history");
                        this.logError("Fetching history", "No data returned", true);
                        return [];
                    }
                } catch (error) {
                    this.logAPIError("Fetching history", error, true);
                    return [];
                }
            },
            fetchNextMessagesFromServer: async function (user, response) {
                console.log('fetching next messages user: ' + user.getID());
                try {
                    const timezoneOffsetHours = -new Date().getTimezoneOffset() / 60;
                    const nextMessagesRet = await API.graphql(graphqlOperation(getNextMessages, {
                        userID: user.getID(),
                        response: response,
                        timezoneOffsetHours: timezoneOffsetHours
                    }));
                    console.log(nextMessagesRet);
                    if (nextMessagesRet.data.getNextMessages !== null) {
                        console.log("Next messages found");
                        return nextMessagesRet.data.getNextMessages;
                    } else {
                        console.log("Error fetching next messages");
                        this.logError("Fetching next message", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching next message", error, true);
                    return null;
                }
            },
            fetchAutoCompleteOptionsFromServer: async function (user, response) {
                console.log('fetching auto complete options for response: ' + response);
                if (!response) {
                    return [];
                }

                try {
                    const searchText = response.toLowerCase();
                    const terms = searchText.split(" ");
                    let andTerms = [];
                    andTerms.push({country: {eq: user.country}});
                    for (let term of terms) {
                        andTerms.push({or: [{name: {wildcard: "*" + term + "*"}}, {nameASCII: {wildcard: "*" + term + "*"}}]});
                    }

                    const autoCompleteRet = await API.graphql(graphqlOperation(searchSchools, {
                        filter: {and: andTerms},
                        limit: 5
                    }));
                    console.log(autoCompleteRet);
                    if (autoCompleteRet.data.searchSchools !== null) {
                        console.log("Auto complete options found");
                        return autoCompleteRet.data.searchSchools.items;
                    } else {
                        console.log("Error fetching auto complete options");
                        this.logError("Fetching auto complete", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching auto complete", error, true);
                    return null;
                }
            },
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            addToChatDisplay(messages, scrollToBottom = true, delayedAdding = false, callback) {
                console.log("Adding messages to log: " + messages);
                if (delayedAdding) {
                    messages.delayedForEach(
                        (message) => {
                            console.log("Adding: " + message.text);
                            this.log.push(message);
                            this.$nextTick(() => {
                                this.scrollToBottom();
                            });
                        },
                        3000,
                        null,
                        callback
                    );
                } else {
                    this.log.push(...messages);
                    this.$nextTick(() => {
                        if (scrollToBottom) {
                            this.scrollToBottom();
                        } else {
                            this.scrollToTop();
                        }
                    });
                    if (callback) {
                        callback.call();
                    }
                }
                console.log("2");
            },
            scrollToTop: function () {
                console.log("Scrolling to top");
                var content = this.$refs.chatContent;
                content.scrollTop = 0;
            },
            scrollToBottom: function () {
                console.log("Scrolling to bottom");
                var content = this.$refs.chatContent;
                content.scrollTop = content.scrollHeight;
            },
            fetchNextQuestionAndEnableInput: async function (response) {
                console.log("Fetching next messages and enabling input");
                // store.user.getLog().push({ type: type, text: value});
                const messages = await this.fetchNextMessagesFromServer(store.user, response);
                console.log("Fetched messages");
                console.log(messages);
                if (messages !== null && messages.length > 0) {
                    this.addToChatDisplay(messages, true, true, () => {
                        this.acceptResponse = true;
                    });
                }
            },
            processResponse: async function () {
                console.log("Send button clicked. Action = " + this.response);
                this.invalidResponse = false;
                this.showAutoComplete = false;

                let validationRegex = this.getLastLogEntry.validationRegex;
                if (validationRegex !== null && validationRegex !== '') {
                    const regex = new RegExp(validationRegex, 'i');
                    if (!regex.test(this.response.toUpperCase())) {
                        this.invalidResponse = true;
                        return;
                    }
                }

                this.acceptResponse = false;
                this.addToChatDisplay([{
                    logType: "USER_TEXT",
                    userID: store.user.getID(),
                    text: this.response,
                    baseType: "LogEntry"
                }]);
                this.fetchNextQuestionAndEnableInput(this.response);
                this.response = '';

                // on activity, check if there is a new version
                this.$root.$emit('check-version-timer');
            },
            autoComplete: async function () {
                const lastMessage = this.getLastLogEntry;
                if (lastMessage.logType === "MYTUTOR_TEXT_AUTO_COMPLETE") {
                    if (lastMessage.text === "What is the name of your school?") {
                        let options = await this.fetchAutoCompleteOptionsFromServer(store.user, this.response);
                        this.autoCompleteOptions = options.map(school => school.name);
                        this.showAutoComplete = true;
                        return;
                    } else if (lastMessage.text === "In what country do you stay?") {
                        this.autoCompleteOptions = this.filterListOfCountries(this.response);
                        this.showAutoComplete = true;
                        return;
                    }
                }
                this.showAutoComplete = false;
            },
            filterListOfCountries: function (text) {
                return countries.filter(c => c.toLowerCase().includes(text.toLowerCase()));
            },
            selectAutoItem: function (item) {
                this.response = item;
                this.showAutoComplete = false;
            },
            scrollChat: async function (event) {
                var topOfWindow = event.target.scrollTop === 0;
                if (topOfWindow && store.user) {
                    console.log("Top of window");
                    this.loadingChat = true;
                    const history = await this.fetchChatHistory(false);
                    if (history.length > 0) {
                        console.log("Adding previous chat history");
                        this.log.unshift(...history);
                        event.target.scrollTop = 10;
                    }
                    this.$nextTick(() => {
                        this.loadingChat = false;
                    });
                }
            },
            signedIn: async function () {
                console.log("Signed in");
                var scrollToBottom = true;
                const history = await this.fetchChatHistory(true);
                if (history.length > 0) {
                    console.log("Chat history found");
                    this.addToChatDisplay(history, scrollToBottom, !scrollToBottom, () => {
                        this.acceptResponse = true;
                    });
                    this.loadingMessage = this.$t('chatBusyReplyingMsg');
                } else {
                    console.log("No chat history");
                }
            },
            signedOut: function () {
                console.log("Signed out");
                this.acceptResponse = false;
                this.invalidResponse = false;
                this.response = '';
                this.log.splice(0);
            }
        },
        watch: {
            'store.user': function () {
                if (store.user) {
                    console.log("Home - user changed: " + store.user.getID());
                    this.signedIn();
                } else {
                    console.log("Home - no user");
                    this.signedOut();
                }
            }
        },
        mounted() {
            if (store.user) {
                console.log("Chat mounted: " + store.user.getID());
                this.signedIn();
            } else {
                this.$router.push({ path: '/signin' });
            }
        }
    }
</script>

<style>
    .chat-window__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
    }

    .chat-window__conv {
        flex: 1;
        overflow-y: scroll;
        position: relative;
    }

    .chat-window__conv__scrollable {
        background-image: linear-gradient(rgba(247, 240, 234, .95), rgba(247, 240, 234, .95)), url("../assets/background-pattern.svg");
        background-size: 400px 400px;
        background-repeat: repeat;
        min-height: 100%;
        padding-bottom: 10px;
    }

    .chat-window-resp {
        width: 100%;
        padding: 10px;
        height: 65px;
        color: #1C1A4F;
        background-color: #1C1A4F;
    }

    .chat-window-resp--student {
        display: flex;
    }

    .chat-window-resp--mytutor {
        width: 100%;
        height: 100%;
        color: white;
        line-height: 3rem;
    }

    .chat-window-resp__text {
        flex: 1;
        position: relative;
    }

    .chat-window-resp__input {
        width: 100%;
        background-color: white;
        line-height: 1.8rem;
        border: none;
        padding: 5px 10px;
        font-size: 20px;
        border-radius: 0;
    }

    .chat-window-resp__input:focus {
        outline: none;
    }

    .chat-window-resp__message {
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: 49px;
    }

    .chat-window-resp__message > div {
        position: relative;
        padding: 10px;
        left: 0%;
        background-color: #5469F3;
        color: white;
        font-weight: bold;
    }

    .chat-window-resp__message > div:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #5469F3;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }

    .chat-window-resp__message--choices {
        text-align: left;
    }

    .chat-window-resp__message--choices > div {
        padding: 10px 20px;
    }

    .chat-window-resp__message--choices ul {
        list-style: none;
    }

    .chat-window-resp__message--choices li {
        padding: 10px;
    }

    .chat-window-resp__message--choices li:hover {
        cursor: pointer;
        background-color: #3E48BE;
    }

    .chat-window-resp__button {
        background-color: #5469F3;
        border: none;
        line-height: 25px;
        margin-left: 10px;
        padding: 8px 8px 12px 12px;
    }

    .chat-window-resp__button:focus {
        outline: none;
    }

    .chat-window-resp__button-text {
        font-size: 30px;
        color: #ffffff;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        /* webkit-transform: rotate(-45deg); */
        transform: rotate(-45deg);
    }

    .note {
        word-wrap: break-word;
        max-width: 100%;
        font-weight: normal;
        float: center;
        text-align: left;
        color: #ffffff;
        border-radius: 5px;
        background-color: #d88787;
        padding: 5px;
        font-size: 20px;
    }


    @media screen and (max-width: 375px) {
        .chat-window-resp__input {
            font-size: 16px;
        }
    }

</style>

<style lang="scss" scoped>
    @import "../styles/layout/chat_window";
</style>

