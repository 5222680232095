<template>
    <div class="profile">
        <div class="close" v-on:click="closeTestsToMark()">&times;</div>

        <template v-if="testsToMarkWithEntries">
            <div class="title">
                <div class="title__heading">
                    <span>Manual Marking</span>
                </div>
            </div>
            <ul class="my-information">
                <li v-for="(testToMark, index) in testsToMark" v-bind:key="testToMark.id">
                    <div class="my-information__detail">
                        <div>
                            <span class="test-name">{{testToMark.name}}</span>
                            <template v-if="testsToMarkWithEntries[index].loading">
                                <div class="profile-sub-heading">
                                    Loading
                                    <spinner height="20px" colour="#F9AC1B"/>
                                </div>
                            </template>
                            <template v-else>
                                <span class="profile-sub-heading">{{testsToMarkWithEntries[index].entries.length}} to mark</span>
                            </template>
                        </div>
                        <img v-bind:src="caret" alt="List test entries" v-on:click="toggleTestToMark(index)" v-bind:class="{'accordion-expand' : testsToMarkWithEntries[index].expanded === true}" class="accordion"/>
                    </div>
                    <table v-if="testsToMarkWithEntries[index].expanded" class="test-detail__entries test-detail__entries--sub-list">
                        <thead>
                            <th class="test-detail__entries-hdr--num">#</th>
                            <th>Finished</th>
                            <th class="test-detail__entries-hdr--time">Mark</th>
                        </thead>
                        <tr v-for="(testEntry, index) in testsToMarkWithEntries[index].entries" v-bind:key="testEntry.id">
                            <td>{{index+1}}</td>
                            <td>{{getDisplayDate(testEntry.finishedAt)}}</td>
                            <td v-on:click="openTestToMark(testEntry)" class="test-detail__entries-time"><img src="../assets/arrow.svg" alt="Mark test"/></td>
                        </tr>
                    </table>
                </li>
                <li v-if="testsToMark.length === 0" class="my-information__detail--empty">You are not configured to mark any tests</li>
            </ul>
        </template>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { API, graphqlOperation } from "@aws-amplify/api";

import Spinner from '../components/Spinner.vue';

// Our custom queries & mutations
import { testEntriesByTestAndMarkingState } from "../mytutor-graphql/queries";

// Mixins
import {apiMixin} from "../mixins/APIMixin";

export default {
  name: 'TestsToMark',
  components: {
    Spinner
  },
  mixins: [apiMixin],
  props: {
      user: Object,
      testsToMark: Array
  },
  data: function() {
      return {
          loadingTestsToMark: false,
          testsToMarkWithEntries: null,
          caret: require("../assets/select-down-arrow.svg"),
      }
  },
  computed: {
  },
  methods: {
    // ----------------------------------------------------------------------------
    // 
    // API calls
    //
    // ----------------------------------------------------------------------------
    fetchEntriesThatRequireMarkingForTest: async function(test) {
      console.log("Fetching unmarked entries for Test with ID: " + test.id);  
      try {
        const entriesRet = await API.graphql(graphqlOperation(testEntriesByTestAndMarkingState, { testID: test.id }));
        console.log(entriesRet);
        if (entriesRet.data.testEntriesByTestAndMarkingState !== null && entriesRet.data.testEntriesByTestAndMarkingState.items) {
            console.log("Entries found for test: " + test.id);
            return entriesRet.data.testEntriesByTestAndMarkingState.items;
        } else {
            console.log("Error finding entries for Test with ID: " + test.id);
            this.logError("Finding entries for Test", "No data returned", true);     
            return null;
        }
      } catch (error) {
        this.logAPIError("Finding entries for Test", error, true);       
        return null;
      }
    },
    // ----------------------------------------------------------------------------
    // 
    // Chat behaviour
    //
    // ----------------------------------------------------------------------------
    getDisplayDate: function(dateString) {
        const date = dayjs(dateString);
        return date.format('YYYY-MM-DD HH:mm');
    },
    toggleTestToMark: function(index) {
        this.testsToMarkWithEntries[index].expanded = !this.testsToMarkWithEntries[index].expanded;
    },
    loadEntriesThatRequireMarking: async function() {
        for (let i = 0; i < this.testsToMark.length; i++) {
            this.fetchEntriesThatRequireMarkingForTest(this.testsToMark[i])
            .then((entries) => {
                console.log("Found entries for test: " + i + " with ID: " + this.testsToMark[i].id);
                this.testsToMarkWithEntries[i].entries = entries;
                this.testsToMarkWithEntries[i].loading = false;
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    openTestToMark: function(testEntry) {
        console.log("opening test entry with ID: " + testEntry.id + " to mark it");
        this.$router.push({ path: '/manual-mark/' + testEntry.id });
        this.closeTestsToMark();
    },
    closeTestsToMark: function() {
        this.$root.$emit('close-tests-to-mark')
    }
  },
  mounted: async function() {
      this.testsToMarkWithEntries = [];
      for (let i = 0; i < this.testsToMark.length; i++) {
          this.testsToMarkWithEntries.push(({expanded: false, loading: true, entries: []}));
      }
      this.loadEntriesThatRequireMarking();
  }
}
</script>

<style lang="scss" scoped>
    @import "../styles/pages/profile";
    @import "../styles/pages/tests";
</style>

<style scoped>
    .accordion {
        transition: transform 0.5s;
    }

    .accordion-expand {
        transform: rotateZ(180deg);
    }
</style>