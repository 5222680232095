export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      email
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      country
      responses {
          items {
              id
          }
      }
      metrics {
          items {
              id
              userID
              totalAnswered
              totalRight
              rightStreak
              wrongStreak
              longestRightStreak
              daysActive(limit: 1, sortDirection:DESC) {
                  items {
                      id
                      studentMetricsID
                      date
                      totalAnswered
                      totalRight
                      rightStreak
                      eloScore
                      createdAt
                  }
              }
              eloScore
              highestELOScore
              score
              totalDaysActive
              registrationQuestionsAnswered
              totalBlueAwards
              totalBronzeAwards
              totalSilverAwards
              totalGoldAwards
              totalDiamondAwards        
              createdAt
              updatedAt
          }
      }
      owner
    }
  }
`;

export const onUpdateTestSection = /* GraphQL */ `
  subscription OnUpdateTestSection($id: ID!) {
    onUpdateTestSection(id: $id) {
      id
      duration
      startedAt
      endedAt
    }
  }
`;

export const onUpdateTestSectionPerformance = /* GraphQL */ `
  subscription OnUpdateTestSectionPerformance($id: ID!) {
    onUpdateTestSectionPerformance(id: $id) {
      id
      testSectionID
      totalStarted
      totalSubmitted
    }
  }
`;

export const onUpdateTestQuestionPerformance = /* GraphQL */ `
  subscription OnUpdateTestQuestionPerformance($id: ID!) {
    onUpdateTestQuestionPerformance(id: $id) {
      id
      testQuestionID
      totalAnswered
      answerCounts
    }
  }
`;

export const onRegisterEntrantForTest = /* GraphQL */ `
  subscription OnRegisterEntrantForTest($testHostID: ID!) {
    onRegisterEntrantForTest(testHostID: $testHostID) {
      testHostID
      testName
      testDate
      organisation {
        name
        logo
      }
      entry {
        id
        score
        scoreAndTime
        questionsAnswered
        timeTaken
        email
        firstName
        surname
        nickname
        entrySections {
          id
          testSectionID
          sectionScore
          sectionScoreAndTime
          totalScore
          questionsAnswered
          timeTaken
        }
        createdAt
      }
    }
  }
`;

export const onUpdateTestEntry = /* GraphQL */ `
  subscription OnUpdateTestEntry($testHostID: ID!) {
    onUpdateTestEntry(testHostID: $testHostID) {
      id
      testHostID
      score
      scoreAndTime
      questionsAnswered
      startedAt
      timeTaken
      entrySections {
        items {
          id
          testSectionID
          sectionScore
          sectionScoreAndTime
          totalScore
          questionsAnswered
          startedAt
          timeTaken
        }
      }
    }
  }
`;

export const onUpdateTestEntrySection = /* GraphQL */ `
  subscription OnUpdateTestEntrySection($id: ID!) {
    onUpdateTestEntrySection(id: $id) {
      id
      testSectionID
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      startedAt
      timeTaken
    }
  }
`;
