<template>
    <div class="test-practice" v-if="isBeforeStartingTest">
        <template v-if="loadingPracticeQuestions">
            <div>
                {{ $t('testPracticeFetchingMsg')}}
            </div>
            <div class="chat-window__loading">
                <spinner height="50px" colour="#1C1A4F"/>
            </div>
        </template>

        <template v-else>
            <template v-if="test.practiceQuestions && test.practiceQuestions.length > 0">
                <div class="test-round__header">{{ $t('testPracticeHeadingMsg')}}</div>

                <template v-if="test.isAdaptiveTest()">

                    <p>{{ $t('testPracticeInviteMsg')}}</p>

                    <template v-if="!startedPracticeQuestions">
                        <button v-on:click="startedPracticeQuestions = true" class="test-section__submit"> {{ $t('testPracticeStartButtonMsg')}}</button>
                    </template>

                    <template v-else>
                        <template v-if="!practiceQuestionsMarked">
                            <quiz-question
                                    v-bind:question="currentQuestion"
                                    v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                    v-bind:canAnswer="true"
                                    v-bind:isTeacher="false"
                                    class="quiz-question--one-at-a-time"/>
                            <div class="saving-answer">
                                <template v-if="changingAnswer">{{ $t('testPracticeSavingYourAnswerMsg')}}</template>
                            </div>
                        </template>
                        <template v-else>
                            <quiz-question v-for="(question, index) in test.practiceQuestions"
                                           ref="question"
                                           v-bind:key="index"
                                           v-bind:index="index"
                                           v-bind:question="question"
                                           v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                           v-bind:canAnswer="false"
                                           v-bind:isTeacher="false"/>
                        </template>
                    </template>
                </template>

                <template v-else>
                    <p>{{ $t('testPracticeInviteWhileWaitingMsg')}}</p>


                    <template v-if="!startedPracticeQuestions">
                        <button v-on:click="startedPracticeQuestions = true" class="test-section__submit">{{ $t('testPracticeStartButtonMsg')}}</button>
                    </template>

                    <template v-else>
                        <quiz-question v-for="(question, index) in test.practiceQuestions"
                                       ref="question"
                                       v-bind:key="index"
                                       v-bind:index="index"
                                       v-bind:question="question"
                                       v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                       v-bind:canAnswer="isBeforeStartingTest && !practiceQuestionsMarked"
                                       v-bind:isTeacher="false"/>

                        <button v-on:click="markQuestions()" v-show="!practiceQuestionsMarked"
                                class="test-section__submit">{{ $t('testPracticeSubmitButtonMsg')}}
                        </button>
                    </template>
                </template>

            </template>
        </template>
    </div>
</template>

<script>

    import Spinner from '../components/Spinner.vue';
    import QuizQuestion from '../components/QuizQuestion.vue';

    export default {
        name: 'TestPractice',
        mixins: [],
        components: {
            Spinner, QuizQuestion
        },
        created() {
        },
        props: {
            test: Object,
            isBeforeStartingTest: Boolean
        },
        data() {
            return {
                loadingPracticeQuestions: true,
                startedPracticeQuestions: false,
                currentQuestion: null,
                changingAnswer: false,
                practiceQuestionsMarked: false
            }
        },
        computed: {},
        methods: {
            questionAnswered: function (answerId, answer) {
                for (let i = 0; i < this.test.practiceQuestions.length; i++) {
                    const practiceQuestion = this.test.practiceQuestions[i];
                    if (practiceQuestion.id === answerId) {
                        if (practiceQuestion.answer === answer) {
                            practiceQuestion.answer = null;
                        } else {
                            practiceQuestion.answer = answer;
                        }
                        // adaptive tests ask the questions one at a time
                        if (this.test.isAdaptiveTest) {
                            this.changingAnswer = true;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    const nextQuestionIndex = i + 1;
                                    if (nextQuestionIndex < this.test.practiceQuestions.length) {
                                        this.currentQuestion = this.test.practiceQuestions[nextQuestionIndex];
                                    } else {
                                        this.currentQuestion = null;
                                        this.markQuestions();
                                    }
                                    this.changingAnswer = false;
                                }, 2000);
                            });
                        }
                        return;
                    }
                }
            },
            markQuestions: function () {
                this.practiceQuestionsMarked = true;
                this.test.practiceQuestions.forEach((question) => {
                    question.images = question.answerRightURLs;
                    if (question.answer === question.answerValue) {
                        question.correct = true;
                    } else {
                        question.correct = false;
                    }
                });
            },
        },
        mounted: async function () {
            await this.test.loadPracticeQuestions();
            // adaptive tests ask the questions one at a time
            if (this.test.isAdaptiveTest() && this.test.practiceQuestions.length > 0) {
                this.currentQuestion = this.test.practiceQuestions[0];
            }
            this.loadingPracticeQuestions = false;

            this.$root.$on('change-answer', this.questionAnswered);
        },
        beforeDestroy() {
            // this.$root.$off("change-answer");
        },
    }

</script>

<style lang="scss" scoped>
    @import "../styles/pages/test";
    @import "../styles/components/test-practice";
</style>
