<template>
    <!-- Main chat component -->
    <div class="chat-window">
        <header-bar></header-bar>

        <div class="chat-window__body">

            <div class="chat-window__conv" ref="chatContent">
                <div class="chat-window__conv__scrollable">
                    <template v-if="isLoadingTestEntry">
                        <div>
                            Fetching the test to mark...
                        </div>
                        <div class="chat-window__loading">
                            <spinner height="50px" colour="#1C1A4F"/>
                        </div>
                    </template>

                    <template v-else-if="errorLoadingTestEntry">
                        <div>
                            {{errorMessage}}
                        </div>
                    </template>

                    <div v-else>
                        <div v-for="(section) in testEntry.entrySections" v-bind:key="section.id">
                            <div v-for="(answer) in section.answers" v-bind:key="answer.id"
                                 v-bind:number="answer.number" v-bind:answer="answer">
                                <template v-if="requiresMarking(answer) && questionAnswered(answer)">
                                    <marking-question v-bind:number="answer.number" v-bind:answer="answer">
                                    </marking-question>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button v-bind:disabled="!allAnswersMarked || submittingTest" v-on:click="submitTestAsMarked()"
                                class="test-section__submit"
                                v-bind:class="{'test-section__submit--disabled' : !allAnswersMarked}">
                            <template v-if="submittingTest">
                                Submitting...
                            </template>
                            <template v-else-if="allAnswersMarked">
                                Submit marked test
                            </template>
                            <template v-else>
                                Mark all answers before submitting
                            </template>
                        </button>
                        <spinner v-if="submittingTest" height="50px" colour="#1C1A4F"/>
                    </div>
                </div>
            </div>

            <notifications v-if="showNotifications" v-bind:notifications="notifications"
                           v-bind:showSpinner="false"></notifications>

        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import {Storage} from 'aws-amplify';
    import {API, graphqlOperation} from "@aws-amplify/api";

    import {store} from '../store.js'

    // Generated queries & mutations
    import {getTestEntryForTeacherToMark} from "../graphql/queries";

    // Our custom queries & mutations
    import {markTestAnswer, entrantEndsTest} from "../mytutor-graphql/mutations";

    // Visual components
    import Spinner from '../components/Spinner.vue';
    import MarkingQuestion from '../components/MarkingQuestion.vue';
    import Notifications from '../components/Notifications.vue';

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    export default {
        name: 'ManualMark',
        components: {
            Spinner, MarkingQuestion, Notifications
        },
        mixins: [apiMixin],
        props: {},
        data: function () {
            return {
                testEntryID: null,
                testEntry: null,
                isLoadingTestEntry: true,
                errorLoadingTestEntry: false,
                errorMessage: "",
                answerMarkPromises: new Map(),
                submittingTest: false,
                notifications: [],
                showNotifications: false,
            }
        },
        computed: {
            allAnswersMarked: function () {
                let allMarked = false;
                if (this.testEntry) {
                    allMarked = true;
                    for (let section of this.testEntry.entrySections) {
                        for (let answer of section.answers) {
                            if (typeof answer.correct === 'undefined' || answer.correct === null) {
                                console.log("Found an unmarked answer");
                                allMarked = false;
                                break;
                            }
                            if (typeof answer.score === 'undefined' || answer.score === null || answer.score === "") {
                                console.log("Found an answer with no score");
                                allMarked = false;
                                break;
                            }
                        }
                    }
                }
                console.log("Returning all marked: " + allMarked);
                return allMarked;
            }
        },
        methods: {
            // ============================================================================
            //
            // API calls
            //
            // ============================================================================
            fetchTestEntryFromServer: async function (markerID, testEntryID) {
                console.log("Fetching test entry with ID: " + testEntryID);
                try {
                    const testEntryRet = await API.graphql(graphqlOperation(getTestEntryForTeacherToMark, {
                        markerID: markerID,
                        testEntryID: testEntryID
                    }));
                    console.log(testEntryRet);
                    if (testEntryRet.data.getTestEntryForTeacherToMark !== null) {
                        console.log("Test entry found");
                        return testEntryRet.data.getTestEntryForTeacherToMark;
                    } else {
                        console.log("Error opening test entry");
                        this.logError("Opening test entry", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    console.log(error);
                    this.logAPIError("Opening test entry", error, true);
                    return null;
                }
            },
            fetchSignedURLForAnswer: async function (answer, cognitoIdentityID) {
                console.log(answer);
                console.log(cognitoIdentityID);
                // fetch a signed URL to the image
                try {
                    Vue.set(answer, 'answerPhotoURL', await Storage.get(answer.answerPhotoS3Key, {
                        level: 'protected',
                        identityId: cognitoIdentityID,
                        expires: 5400
                    }));
                    console.log("answerPhotoURL: " + answer.answerPhotoURL);
                } catch (error) {
                    console.log("Error getting signed URL for photo in S3: " + answer.answerPhotoS3Key, error);
                }
            },
            markAnswerOnServer: async function (answerID, correct, score, markersComments) {
                console.log("Marking answer for TestAnswer: " + answerID);
                try {
                    const updateAnswerRet = await API.graphql(graphqlOperation(markTestAnswer, {
                        testAnswerID: answerID,
                        correct: correct,
                        score: score,
                        markersComments: markersComments
                    }));
                    console.log(updateAnswerRet);
                    if (updateAnswerRet.data.markTestAnswer !== null) {
                        console.log("Answer marked");
                        return updateAnswerRet.data.markTestAnswer;
                    } else {
                        console.log("Error marking answer");
                        this.logError("Marking answer", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Marking answer", error, true);
                    return null;
                }
            },
            submitTestAsMarkedOnServer: async function (testSectionEntryID) {
                console.log("Submitting marked test entry section with ID: " + testSectionEntryID);
                try {
                    const submitTestRet = await API.graphql(graphqlOperation(entrantEndsTest, {
                        testSectionEntryID: testSectionEntryID,
                        markerSubmittingTest: true
                    }));
                    console.log(submitTestRet);
                    if (submitTestRet.data.entrantEndsTest !== null) {
                        console.log("Submitted marked test");
                        return submitTestRet.data.entrantEndsTest;
                    } else {
                        console.log("Error submitting marked test");
                        this.logError("Submitting marked test", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    console.log(error);
                    this.logAPIError("Submitting marked test", error, true);
                    return null;
                }
            },
            // ============================================================================
            //
            // Marking behaviour
            //
            // ============================================================================
            loadTestEntryToMark: async function () {
                if (store.user && this.testEntryID) {
                    console.log("Loading test entry with ID: " + this.testEntryID);
                    this.testEntry = await this.fetchTestEntryFromServer(store.user.getID(), this.testEntryID);
                    this.isLoadingTestEntry = false;
                    if (this.testEntry === null) {
                        this.errorLoadingTestEntry = true;
                        this.errorMessage = "There was an error loading the test to mark";
                    } else {
                        this.sortByNumber(this.testEntry.entrySections);
                        // convert to format for ChatLine component
                        this.testEntry.entrySections.forEach((section) => {
                            this.sortByNumber(this.testEntry.answers);
                            section.answers.forEach((answer) => {
                                if (answer.answerRightURLs) {
                                    answer.images = answer.answerRightURLs;
                                }
                                answer.logType = 'MYTUTOR_FILE';
                                answer.submittingAnswer = false;
                                if (answer.answerPhotoS3Key && typeof this.testEntry.cognitoIdentityID !== 'undefined' && this.testEntry.cognitoIdentityID) {
                                    this.fetchSignedURLForAnswer(answer, this.testEntry.cognitoIdentityID);
                                } else {
                                    answer.answerPhotoS3Key = null;
                                }
                            });
                        });
                    }
                }
            },
            requiresMarking: function (answer) {
                return answer.layout === 'PARAGRAPH' || answer.layout === 'PARAGRAPH_AND_PHOTO';
            },
            questionAnswered: function (answer) {
                return answer.answer !== null || answer.answerPhotoS3Key !== null;
            },
            reset: function () {
                this.testEntry = null;
                this.isLoadingTestEntry = true;
                this.errorLoadingTestEntry = false;
            },
            resetAndReload: function () {
                this.reset();
                this.loadTestEntryToMark();
            },
            sortByNumber: function (list) {
                if (list) {
                    list.sort((first, second) => {
                        if (second.number < first.number) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                }
            },
            markAnswer: async function (answerID, correct, score, markersComments) {
                console.log("Updating correct for answer: " + answerID + " to: " + correct);
                console.log("Updating score for answer: " + answerID + " to: " + score);
                console.log("Updating markersComments for answer: " + answerID + " to: " + markersComments);
                this.$root.$emit('marking-answer', answerID, null);

                let promise = this.markAnswerOnServer(answerID, correct, score, markersComments);
                this.answerMarkPromises.set(answerID, promise);
                const result = await promise;
                this.answerMarkPromises.delete(answerID);

                if (result === null) {
                    this.$root.$emit('marked-answer', answerID, "Error");
                } else {
                    this.$root.$emit('marked-answer', answerID);
                }
            },
            submitTestAsMarked: async function () {
                console.log("Submit test as marked");
                this.submittingTest = true;
                await Promise.all(this.answerMarkPromises.values())
                    .then(async () => {
                        console.log("Finished waiting for all outstanding questions to be marked");
                        const testResult = await this.submitTestAsMarkedOnServer(this.testEntry.entrySections[0].id);
                        this.showTestSubmittedNotification(testResult);
                    })
                    .catch(() => {
                        console.log("Error waiting for a answer to finish being marked");
                        this.submittingTest = false;
                    });
            },
            showTestSubmittedNotification: function (testResult) {
                if (testResult && testResult.score) {
                    this.notifications = [{message: "Thank you for marking this test entry. The learner's score was: " + testResult.score + " and he/she has been sent an email with their result."}];
                    this.showNotifications = true;
                }
            },

        },
        watch: {
            'store.user': function () {
                console.log("Manual Mark.watch(store.user)");
                this.resetAndReload();
            },
            '$route.params.id': function (id) {
                console.log('Test entry changed to id: ' + id);
                this.testEntryID = id;
                this.resetAndReload();
            }
        },
        mounted: async function () {
            this.testEntryID = this.$route.params.id;
            this.loadTestEntryToMark();

            this.$root.$on('mark-answer', this.markAnswer);

            this.closeNotificationsFn = () => {
                console.log("clearning notifications");
                this.notifications = [];
                this.showNotifications = false;
                this.$router.push({path: '/'});
                this.$root.$emit('open-tests-to-mark');
            };
            this.$root.$on('close-notifications', this.closeNotificationsFn);
        },
        beforeDestroy() {
            this.$root.$off("mark-answer", this.markAnswer);
            this.$root.$off("close-notifications", this.closeNotificationsFn);
        },
    }
</script>

<style scoped>
    .test-section__submit {
        background-color: #1C1A4F;
        font: normal normal 600 28px/26px Inter;
        padding: 23px 72px;
        color: #fff;
        height: 80px;
        border-radius: 40px;
        border: none;
        margin-bottom: 50px;
    }

    .test-section__submit--disabled {
        background-color: #B8BABD;
    }
</style>

<style lang="scss" scoped>
    @import "../styles/layout/chat_window";
</style>
