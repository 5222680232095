import Vue from 'vue'
import App from './App.vue'
import router from './router'
import routes from './routes'
import i18n from './plugins/i18n';
import FlagIcon from 'vue-flag-icon';

import { Amplify } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import '@aws-amplify/ui-vue';
import awsconfig from "./aws-exports";
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';


/**
 * Extend the Date type
 * 
 * @param {Number} minutes Number of minutes to add to the Date
 * @returns 
 */
 Date.prototype.addMinutes = function(minutes) {
  this.setTime(this.getTime() + (minutes * 60 * 1000));
  return this;
}

console.log("Updated Date class");

/**
 * An array forEach with a delay between steps, and a callback that get's executed on each step and a done callback for the end.
 *
 * @param {Function} callbackStep Function to execute for each element. It receives three arguments, the element value, the element index and the array being traversed, respectivily.
 * @param {Number} timeout Number of milliseconds that the function call should be delayed by.
 * @param {Object} thisArg Object to use as this when executing callback.
 * @param {Function} callbackDone Function to execute for each element. It receives three arguments, the element value, the element index and the array being traversed, respectivily.
 * @this {Array}
 * @return {undefined}
 */
 Array.prototype.delayedForEach = function(callbackStep, timeout, thisArg, callbackDone){
  var i = 0,
      length = this.length,
      self = this;

  var caller = function() {
      callbackStep.call(thisArg || self, self[i], i, self);
      if(++i < length) {
          setTimeout(caller, timeout);
      } else if(callbackDone) {
          setTimeout(callbackDone, timeout);
      }
  };

  caller();
};




// TODO: Add back when adding federated login
// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

// TODO: Add back when adding federated login
// Assuming we have two redirect URIs, and the first is for localhost and second is for production
// const [
//   localRedirectSignIn,
//   productionRedirectSignIn,
// ] = awsconfig.oauth.redirectSignIn.split(",");

// TODO: Add back when adding federated login
// const [
//   localRedirectSignOut,
//   productionRedirectSignOut,
// ] = awsconfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsconfig,
  // TODO: Add back when adding federated login
  // oauth: {
  //   ...awsconfig.oauth,
  //   redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
  //   redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  // },
  // this has to be here, because we have IAM as our default authentication mechanism, and then Amplify makes that 
  // the default for connecting to AppSync, which we don't actually want
  // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

Amplify.configure(updatedAwsConfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

I18n.putVocabulariesForLanguage("en-US", {
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Please check your email for your verification code",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Verify"
});

Vue.use(FlagIcon);
Vue.config.productionTip = false

/*
 * Instantiate Vue / the app
 *
 */
const app = new Vue({
  router,
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      const matchingView = routes[this.currentRoute]
      return matchingView
        ? require('./pages/' + matchingView + '.vue').default
        : App
    }
  },
  i18n,
  render (h) {
    return h(this.ViewComponent)
  }
})

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname
})

