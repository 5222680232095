<template>

    <div class="quiz-question">
        <div class="quiz-question__number" v-bind:class="{'quiz-question__number--answered' : answered}">
            {{ $t('quizQuestionHeadingMsg')}} {{question.number}}
        </div>
        <div v-if="isTeacher">
            <div v-if="sectionPerformance && question.performance"
                 v-bind:style="{visibility: showStats ? 'visible' : 'hidden'}"
                 class="quiz-question__stats-total-answered">
                {{question.performance.totalAnswered}} / {{sectionPerformance.totalStarted}}
            </div>
        </div>
        <div v-else-if="question.score || question.score === 0">
            <div class="quiz-question__stats-total-answered">
                {{ $t('commonTestsScoreMsg')}} {{question.score}}
            </div>
        </div>
        <div v-else class="quiz-question__formula-sheets">
            <div v-if="hasFormulaSheets" v-on:click="showFormulas()">
                {{ $t('quizQuestionViewFormulaSheetMsg')}}
            </div>
        </div>

        <transition name="slide">
            <div v-if="canAnswer && hasClue && showClueToggle" class="quiz-question__clue">
                <input type="checkbox" v-model="showClue"> Show Clue
            </div>
        </transition>

        <chat-line v-bind:chatLine="question" v-bind:user="user" v-bind:questionID="question.id"
                   v-bind:questionInQuiz="true" v-bind:questionAnswered="answered" class="quiz-question__question"/>

        <div class="quiz-question__spacer" v-bind:class="{'quiz-question__spacer--answered' : answered}"></div>

        <template v-if="!isTeacher">
            <ul v-if="isMultipleChoiceAnswer" class="quiz-question__options"
                v-bind:class="{'quiz-question__options--answered' : answered, 'quiz-question__options--disabled' : !canAnswer, 'quiz-question__answer-text--marked ' : question.markersComments}">
                <li v-for="(answerOption, index) in answerOptionsAsArray" v-bind:key="index">
                    <div v-on:click="changeAnswer(answerOption)" class="quiz-question__option"
                         v-bind:class="{'quiz-question__option--answerable' : canAnswer}">
                        <div v-bind:class="{'quiz-question__option--selected': question.answer === answerOption, 'quiz-question__option--correct': question.answer === answerOption && question.correct === true, 'quiz-question__option--incorrect': question.answer === answerOption && question.correct === false}">
                            <span v-if="question.answer === answerOption && question.correct === true"><img
                                    src="../assets/tick.svg" alt="$t('quizQuestionCorrectMsg')" class=""/></span>
                            <span v-if="question.answer === answerOption && question.correct === false"><img
                                    src="../assets/cross.svg" alt="$t('quizQuestionIncorrectMsg')" class=""/></span>
                        </div>
                    </div>{{answerOption}}</li>  <!-- NOTE: This MUST be on one line else the option wrap! -->
            </ul>

            <template v-else-if="isWordAnswer">
                <div class="quiz-question__answer-text"
                     v-bind:class="{'quiz-question__answer-text--answered' : answered, 'quiz-question__answer-text--disabled' : !canAnswer}">
                    <span>{{ $t('quizQuestionAnswerMsg')}}:</span>
                    <input type="text" v-model="text" ref="answerInput" v-bind:disabled="savingAnswer || !canAnswer"
                           v-on:blur="saveAnswer()" v-bind:placeholder="placeholderText"
                           v-on:keydown="removeValidationMessage()"/>
                    <div v-if="sectionInProgress" class="quiz-question__answer-text-status">
                        {{savingMessage}}
                    </div>
                    <div v-else class="quiz-question__answer-text-marked-and-score">
                        <div class="quiz-question__answer-text-marked"
                             v-bind:class="{'quiz-question__option--correct': question.correct === true, 'quiz-question__option--incorrect': question.correct === false}">
                            <img v-if="question.correct === true" src="../assets/tick.svg" alt="$t('quizQuestionCorrectMsg')" class=""/>
                            <img v-if="question.correct === false" src="../assets/cross.svg" alt="$t('quizQuestionIncorrectMsg')" class=""/>
                        </div>
                        <template v-if="question.score">
                            {{question.score}} / {{question.points}}
                        </template>
                    </div>
                </div>
                <div class="quiz-question__message" v-show="invalidResponse">
                    <div v-html="$t('quizQuestionInvalidAnswerMsg')"></div>
                </div>
            </template>

            <template v-else-if="isParagraphAnswer || isParagraphAndPhotoAnswer">
                <div class="quiz-question__answer-text quiz-question__answer-text--paragraph"
                     v-bind:class="{'quiz-question__answer-text--answered' : answered, 'quiz-question__answer-text--disabled' : !canAnswer, 'quiz-question__answer-text--marked ' : question.markersComments}">
                    <span>{{ $t('quizQuestionAnswerMsg')}}:</span>
                    <div class="quiz-question__answer-text-row">
                        <textarea v-model="text"
                                  ref="answerInput"
                                  v-bind:disabled="savingAnswer || !canAnswer"
                                  v-on:blur="saveAnswer()"
                                  v-bind:placeholder="placeholderText"
                                  v-on:keydown="removeValidationMessage()"></textarea>
                        <span v-if="sectionInProgress" class="quiz-question__answer-text-status">
                            {{savingMessage}}
                        </span>
                        <div v-else class="quiz-question__answer-text-marked-and-score">
                            <div class="quiz-question__answer-text-marked"
                                 v-bind:class="{'quiz-question__option--correct': question.correct === true, 'quiz-question__option--incorrect': question.correct === false}">
                                <img v-if="question.correct === true" src="../assets/tick.svg" alt="$t('quizQuestionCorrectMsg')" class=""/>
                                <img v-if="question.correct === false" src="../assets/cross.svg" alt="$t('quizQuestionIncorrectMsg')"
                                     class=""/>
                            </div>
                            <template v-if="question.score">
                                {{question.score}} / {{question.points}}
                            </template>
                        </div>
                    </div>
                    <div class="quiz-question__answer-text-row">
                        <form enctype="multipart/form-data" novalidate
                              v-if="sectionInProgress && (!answeredWithPhoto || isPhotoLoaderStateDeleting || isPhotoLoaderStateDeleteFailed)"
                              class="quiz-question__answer-photo">
                            <input type="file"
                                   v-bind:name="uploadFieldName"
                                   v-bind:disabled="isPhotoLoaderStateSaving || isPhotoLoaderStateDeleting"
                                   v-on:change="saveAnswerPhoto($event.target.files)"
                                   accept="image/*"
                                   class="quiz-question__answer-photo-input-file">
                            <p v-if="isPhotoLoaderStateInitial" v-html="$t('quizQuestionDragPhotoMsg')"></p>
                            <p v-if="isPhotoLoaderStateSaving" v-html="$t('quizQuestionUploadPhotoMsg')"></p>
                            <p v-if="isPhotoLoaderStateFailed" v-html="$t('quizQuestionUploadFailedMsg')"></p>
                            <p v-if="isPhotoLoaderStateDeleting" v-html="$t('quizQuestionDeletingPhotoMsg')"></p>
                            <p v-if="isPhotoLoaderStateDeleteSuccess" v-html="$t('quizQuestionPhotoDeletedMsg')"></p>
                            <p v-if="isPhotoLoaderStateDeleteFailed" v-html="$t('quizQuestionDeleteFailedMsg')"></p>
                        </form>
                        <div v-else class="quiz-question__answer-photo-thumbnail">
                            <img v-if="answeredWithPhoto" v-bind:src="question.answerPhotoURL"/>
                            <div v-else class="quiz-question__answer-photo-thumbnail--no-photo">{{ $t('quizQuestionNoPhotoUploadedMsg')}}</div>
                        </div>
                        <div v-if="sectionInProgress" class="quiz-question__answer-text-status">
                            <div v-if="answeredWithPhoto" v-on:click="deleteAnswerPhoto"
                                 class="quiz-question__answer-photo-delete">
                                <img src="../assets/trash.png"/>
                            </div>
                        </div>
                        <div v-else class="quiz-question__answer-text-marked-and-score">
                            <!-- <div class="quiz-question__answer-text-marked" v-bind:class="{'quiz-question__option--correct': question.correct === true, 'quiz-question__option--incorrect': question.correct === false}">
                                <img v-if="question.correct === true" src="../assets/tick.svg" alt="Correct" class=""/>
                                <img v-if="question.correct === false" src="../assets/cross.svg" alt="Incorrect" class=""/>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="quiz-question__message" v-show="invalidResponse">
                    <div v-html="$t('quizQuestionInvalidAnswerMsg')"></div>
                </div>
            </template>

            <div v-if="question.markersComments"
                 class="quiz-question__answer-text quiz-question__answer-text--answered quiz-question__answer-text--disabled quiz-question__answer-text--feedback">
                <span>{{ $t('quizQuestionMarkingFeedbackMsg')}}</span>
                <div>{{question.markersComments}}</div>
            </div>

        </template>

        <ul v-if="isTeacher && sectionPerformance && question.performance"
            v-bind:style="{visibility: showStats ? 'visible' : 'hidden'}" class="quiz-question__options">
            <li v-for="(answerOption, index) in answerOptionsAsArray" v-bind:key="index">
                {{answerOption}}
                <span class="quiz-question__answer-stats">(<span class="hidden-mobile">{{ $t('quizQuestionTotalAbbreviationMsg')}} </span>{{question.performance.answerCounts[index]}})</span>
            </li>
        </ul>

    </div>

</template>

<script>
    import {store} from '../store.js'

    // Visual components
    import ChatLine from './ChatLine.vue';

    const PHOTO_LOADING_STATE_INITIAL = 0,
        PHOTO_LOADING_STATE_SAVING = 1,
        PHOTO_LOADING_STATE_SUCCESS = 2,
        PHOTO_LOADING_STATE_FAILED = 3,
        PHOTO_LOADING_STATE_DELETING = 4,
        PHOTO_LOADING_STATE_DELETE_SUCCESS = 5,
        PHOTO_LOADING_STATE_DELETE_FAILED = 6;

    export default {
        name: 'QuizQuestion',
        components: {
            ChatLine,
        },
        props: {
            index: Number,
            question: Object,
            formulaSheetSlides: Array,
            sectionInProgress: Boolean,
            canAnswer: Boolean,
            isTeacher: Boolean,
            showStats: Boolean,
            sectionPerformance: Object
        },
        created() {
        },
        data() {
            return {
                text: "",
                savingAnswer: false,
                savingMessage: "",
                showClueToggle: false,
                showClue: false,
                showSavingMessage: false,
                invalidResponse: false,
                photoLoadingState: PHOTO_LOADING_STATE_INITIAL,
                uploadFieldName: 'files'
            }
        },
        computed: {
            user: function () {
                return store.user;
            },
            hasFormulaSheets: function () {
                return typeof this.formulaSheetSlides !== 'undefined' && this.formulaSheetSlides.length > 0;
            },
            hasClue: function() {
                if (this.question.clueURL) {
                    return true;
                }
                return false;
            },
            isMultipleChoiceAnswer: function () {
                return this.question.layout === 'LAYOUT_1_2_3_4_5' ||
                    this.question.layout === 'LAYOUT_1_2_3_NL_4_5' ||
                    this.question.layout === 'LAYOUT_1_2_NL_3_4_NL_5' ||
                    this.question.layout === 'LAYOUT_XL_1_2_NL_3_4_NL_5' ||
                    this.question.layout === 'LAYOUT_1_NL_2_NL_3_NL_4_NL_5';
            },
            isWordAnswer: function () {
                return this.question.layout === 'WORD';
            },
            isParagraphAnswer: function () {
                return this.question.layout === 'PARAGRAPH';
            },
            isParagraphAndPhotoAnswer: function () {
                return this.question.layout === 'PARAGRAPH_AND_PHOTO';
            },
            placeholderText: function () {
                if (this.sectionInProgress) {
                    return this.question.placeholderText;
                }
                return this.$t('quizQuestionPlaceholderUnansweredMsg');
            },
            answered: function () {
                return this.question.answer !== null;
            },
            answeredWithPhoto: function () {
                return this.question.answerPhotoS3Key !== null;
            },
            answerOptionsAsArray: function () {
                if (this.question.answerOptions && this.question.answerOptions.length > 4) {
                    const options = this.question.answerOptions.substring(2, this.question.answerOptions.length - 2);
                    const answerOpts = options.split("|");
                    return answerOpts;
                } else {
                    console.log("Error, there are no answer options");
                    return [];
                }
            },
            isPhotoLoaderStateInitial: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_INITIAL;
            },
            isPhotoLoaderStateSaving: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_SAVING;
            },
            isPhotoLoaderStateSuccess: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_SUCCESS;
            },
            isPhotoLoaderStateFailed: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_FAILED;
            },
            isPhotoLoaderStateDeleting: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_DELETING;
            },
            isPhotoLoaderStateDeleteSuccess: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_DELETE_SUCCESS;
            },
            isPhotoLoaderStateDeleteFailed: function () {
                return this.photoLoadingState === PHOTO_LOADING_STATE_DELETE_FAILED;
            }
        },
        methods: {
            showFormulas: function () {
                this.$root.$emit('show-formula-sheets');
            },
            changeAnswer: function (answer) {
                if (this.canAnswer) {
                    this.$root.$emit('change-answer', this.question.id, answer);
                }
            },
            saveAnswer: function () {
                this.invalidResponse = false;
                if (this.canAnswer && this.question.answer !== this.text) {
                    if (this.text !== '') {
                        const regex = new RegExp(this.question.answerOptions);
                        if (!regex.test(this.text.toUpperCase())) {
                            console.debug("Invalid answer: " + this.text);
                            this.invalidResponse = true;
                            this.$refs.answerInput.focus();
                            this.$root.$emit('jump-to-question', this.index);
                            return;
                        }
                    }
                    this.savingAnswer = true;
                    this.$root.$emit('change-answer', this.question.id, this.text);
                }
            },
            saveAnswerPhoto: async function (fileList) {
                if (this.canAnswer && fileList.length > 0) {
                    console.log("uploading photo: " + fileList[0].name);
                    this.$root.$emit('change-answer-photo', this.question.id, fileList[0]);
                }
            },
            deleteAnswerPhoto: async function () {
                if (this.canAnswer) {
                    console.log("Deleting photo");
                    this.$root.$emit('delete-answer-photo', this.question.id);
                }
            },
            removeValidationMessage: function () {
                this.invalidResponse = false;
            }
        },
        watch: {
            'showClue': function () {
                if (this.showClue) {
                    console.log("Showing clue in QuizQuestion");
                    this.$root.$emit('show-clue', this.question.id);
                } else {
                    console.log("Hiding clue in QuizQuestion");
                    this.$root.$emit('hide-clue', this.question.id);
                }
            }
        },
        mounted() {
            if (!this.isMultipleChoiceAnswer && this.question.answer !== null) {
                this.text = this.question.answer;
            }

            setTimeout(() => {
                this.showClueToggle = true;
            }, 5000);

            this.savingAnswerFn = (questionID) => {
                if (questionID === this.question.id) {
                    this.savingMessage = this.$t('quizQuestionSavingMsg');
                    this.showSavingMessage = true;
                }
            };
            this.$root.$on('saving-answer', this.savingAnswerFn);

            this.savedAnswerFn = (questionID, error) => {
                if (questionID === this.question.id) {
                    if (!this.isMultipleChoiceAnswer) {
                        this.savingAnswer = false;
                        if (this.question.answer !== null) {
                            this.text = this.question.answer;
                        } else {
                            this.text = "";
                        }
                    }
                    if (error) {
                        this.savingMessage = error;
                    } else {
                        this.savingMessage = this.$t('quizQuestionSavedMsg');
                        setTimeout(() => {
                            this.savingMessage = "";
                        }, 3000);
                    }
                }
            };
            this.$root.$on('saved-answer', this.savedAnswerFn);

            this.savingAnswerPhotoFn = (questionID) => {
                if (questionID === this.question.id) {
                    this.photoLoadingState = PHOTO_LOADING_STATE_SAVING;
                }
            };
            this.$root.$on('saving-answer-photo', this.savingAnswerPhotoFn);

            this.savedAnswerPhotoFn = (questionID, error) => {
                if (questionID === this.question.id) {
                    if (error) {
                        console.log("failed to upload photo");
                        this.photoLoadingState = PHOTO_LOADING_STATE_FAILED;
                        this.savingMessage = error;
                    } else {
                        console.log("photo uploaded");
                        this.photoLoadingState = PHOTO_LOADING_STATE_SUCCESS;
                    }
                }
            };
            this.$root.$on('saved-answer-photo', this.savedAnswerPhotoFn);

            this.deletingAnswerPhotoFn = (questionID) => {
                if (questionID === this.question.id) {
                    this.photoLoadingState = PHOTO_LOADING_STATE_DELETING;
                }
            };
            this.$root.$on('deleting-answer-photo', this.deletingAnswerPhotoFn);

            this.deletedAnswerPhotoFn = (questionID, error) => {
                if (questionID === this.question.id) {
                    if (error) {
                        console.log("failed to delete photo");
                        this.photoLoadingState = PHOTO_LOADING_STATE_DELETE_FAILED;
                        setTimeout(() => {
                            this.photoLoadingState = PHOTO_LOADING_STATE_INITIAL;
                        }, 3000);
                    } else {
                        console.log("photo deleted");
                        this.photoLoadingState = PHOTO_LOADING_STATE_DELETE_SUCCESS;
                        setTimeout(() => {
                            this.photoLoadingState = PHOTO_LOADING_STATE_INITIAL;
                        }, 3000);
                    }
                }
            };
            this.$root.$on('deleted-answer-photo', this.deletedAnswerPhotoFn);
        },
        beforeDestroy() {
            this.$root.$off("saving-answer", this.savingAnswerFn);
            this.$root.$off("saved-answer", this.savedAnswerFn);
            this.$root.$off("saving-answer-photo", this.savingAnswerPhotoFn);
            this.$root.$off("saved-answer-photo", this.savedAnswerPhotoFn);
            this.$root.$off("deleting-answer-photo", this.deletingAnswerPhotoFn);
            this.$root.$off("deleted-answer-photo", this.deletedAnswerPhotoFn);
        },
    }
</script>

<style lang="scss">
    .slide-enter-active,
    .slide-leave-active {
        transition: transform 1s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-200%);
        transition: all 150ms ease-in 0s
    }

    .quiz-question {
        text-align: left;
        position: relative;
        margin-bottom: 100px;
    }

    .quiz-question__number, .quiz-question__stats-total-answered, .quiz-question__formula-sheets, .quiz-question__clue {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: -40px;
        width: 140px;
        margin-left: 10px;
        padding: 8px 0 16px 0;
        background-color: #ffffff;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        letter-spacing: 0.48px;
        text-align: center;
        border: 3px solid #FFFFFF;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .quiz-question__stats-total-answered, .quiz-question__formula-sheets, .quiz-question__clue {
        left: 150px;
        width: 500px;
        text-align: left;
        padding-left: 15px;
        background: none;
        border-color: transparent;
    }

    .quiz-question__formula-sheets, .quiz-question__clue {
        right: 0;
        width: inherit;
        text-align: left;
        text-decoration: underline;
        margin-right: 10px;
        padding: 8px 0 16px 0;
    }

    .quiz-question__formula-sheets:hover {
        cursor: pointer;
    }

    .quiz-question__formula-sheets img {
        height: 35px;
    }

    .quiz-question__clue {
        right: 0px;
        left: inherit;
        padding: 5px 16px;
        border-radius: 5px;
        margin-top: 3px;
        text-decoration: none;

        input[type="checkbox"] {
            vertical-align: bottom;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    .quiz-question__number--answered {
        border-color: #5469F3;
        background-color: #5469F3;
        color: #ffffff;
    }

    .quiz-question__question {
        position: relative;
        z-index: 2;
    }

    .quiz-question__spacer {
        height: 4px;
        border: 4px solid transparent;
        border-top: none;
        border-bottom: none;
    }

    .quiz-question__spacer--answered {
        border: 4px solid #5469F3;
        border-top: none;
        border-bottom: none;
        margin: 0 10px;
    }

    .quiz-question__options {
        text-align: center;
        list-style: none;
        margin: 0 10px;
        height: 100px;
        background-color: #F2F2F2;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__options--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__options li {
        display: inline-block;
        margin: 28px 4%;
        font: normal normal normal 26px/26px Inter;
        letter-spacing: 0px;
        color: #1C1A4F;
    }

    .quiz-question__option {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid #1C1A4F;
        border-radius: 20px;
        vertical-align: middle;
        margin-right: 10px;
    }

    .quiz-question__option--answerable:hover {
        cursor: pointer;
    }

    .quiz-question__options--disabled li {
        color: #B8BABD !important;
    }

    .quiz-question__options--disabled .quiz-question__option {
        border-color: #B8BABD;
    }

    .quiz-question__option div {
        border-radius: 15px;
        height: 28px;
        width: 28px;
        margin-top: 4px;
        margin-left: 4px;
    }

    .quiz-question__option span {
        color: white !important;
    }

    .quiz-question__option span img {
        width: 17px;
    }

    .quiz-question__option--selected {
        background-color: #5469F3;
    }

    .quiz-question__option--correct {
        background-color: #02C664;
    }

    .quiz-question__option--incorrect span {
        vertical-align: top;
    }

    .quiz-question__option--incorrect {
        background-color: #E0312E;
    }

    .quiz-question__answer-text {
        text-align: left;
        margin: 0 10px;
        font: normal normal 400 28px/44px Inter;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        padding: 10px 0;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__answer-text--paragraph {
        flex-direction: column;
    }

    .quiz-question__answer-text-row {
        flex-direction: row;
        display: flex;
        margin-bottom: 20px;
    }

    .quiz-question__answer-text span {
        padding: 13px 20px;
    }

    .quiz-question__answer-text input[type="text"], .quiz-question__answer-text--paragraph textarea {
        flex: 1;
        padding: 5px 15px;
        margin-left: 10px;
        font: normal normal 400 28px/22px Inter;
        color: #5469F3;
        /* border: 1px solid #363374; */
        border: 2px solid #666;
        border-radius: 10px;
        outline: none;
    }

    .quiz-question__answer-text input[type="text"]:focus {
        border-color: #5469F3;
    }

    .quiz-question__answer-text--paragraph textarea {
        height: 200px;
        min-height: 200px;
    }

    .quiz-question__answer-text--disabled {
        color: #ccc;
        /* border-color: #ccc; */
    }

    .quiz-question__answer-text--disabled input[type="text"] {
        color: #363374;
    }

    .quiz-question__answer-text-status {
        width: 120px;
    }

    .quiz-question__answer-text-marked-and-score {
        width: 120px;
    }

    .quiz-question__answer-text-marked {
        width: 40px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        padding: 10px 10px;
        border-radius: 20px;
        height: 40px;
        margin-top: 3px;
    }

    .quiz-question__answer-text--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__answer-text--marked {
        border-bottom: none !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .quiz-question__answer-text--feedback {
        display: block !important;
    }

    .quiz-question__answer-text--feedback div {
        padding: 13px 20px;
        font: normal normal 400 20px/24px Inter !important;
        color: #5469F3;
    }

    .quiz-question__answer-photo {
        min-height: 200px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 20px 10px 10px 10px;
        flex: 1;
        text-align: center;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: #DDE1FD url("../assets/camera.png") no-repeat center;
        color: dimgray;
        position: relative;
        cursor: pointer;
    }

    .quiz-question__answer-photo:hover {
        background: #5469F3 url("../assets/camera.png") no-repeat center;
        color: #fff;
    }

    .quiz-question__answer-photo-input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
    }

    .quiz-question__answer-photo p {
        font-size: 1.2em;
        text-align: center;
        margin-bottom: 0;
    }

    .quiz-question__answer-photo-thumbnail {
        min-height: 200px;
        max-height: 200px;
        margin-left: 10px;
        padding: 10px;
        flex: 1;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -5px;
    }

    .quiz-question__answer-photo-thumbnail img {
        max-height: 100%;
        max-width: 100%;
    }

    .quiz-question__answer-photo-thumbnail--no-photo {
        width: 100%;
        height: 100%;
        padding-top: 70px;
        text-align: center;
    }

    .quiz-question__answer-photo-delete {
        border-radius: 25px;
        background-color: #B8BABD;
        padding: 3px 0 0 3px;
        width: 48px;
        height: 48px;
        margin-left: 10px;
    }

    .quiz-question__message {
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: 49px;
        padding: 0 15px;
        z-index: 100;
        text-align: center;
    }

    .quiz-question__message > div {
        position: relative;
        padding: 10px;
        left: 0%;
        background-color: #5469F3;
        color: white;
        font-weight: bold;
    }

    .quiz-question__message > div:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #5469F3;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }

    .quiz-question__answer-stats {
        font: normal normal 600 14px/32px Inter;
        letter-spacing: 0.28px;
        color: #1C1A4F;
    }

    @media screen and (min-width: 375px)  and (max-width: 610px) {
        .quiz-question__number {
            font: normal normal 600 14px/18px Inter;
        }

        .quiz-question__options {
            height: 80px;
        }

        .quiz-question__options li {
            margin: 16px 3%;
            font: normal normal normal 20px/20px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 11px;
            height: 20px;
            width: 20px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 18px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 1px;
            vertical-align: top;
        }
    }

    @media screen and (max-width: 414px) {
        .quiz-question__options {
            height: 60px;
        }

        .quiz-question__options li {
            margin: 14px 3%;
            font: normal normal normal 18px/20px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 9px;
            height: 14px;
            width: 14px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 14px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 0;
            vertical-align: top;
        }

        .hidden-mobile {
            display: none !important;
        }

        .quiz-question__formula-sheets, .quiz-question__clue {
            font-size: 14px;
        }
    }

</style>
