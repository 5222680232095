<template>
    <div>
        <div class="chat-window__header">
            <img src="../assets/logo-home.svg" alt="MyTutor Logo"/>
        </div>

        <div class="form-entry">
            <h1>{{ $t('Unsubscribe')}}</h1>

            <template v-if="!unsubscribing && !unsubscribed && !error">
                <h2>{{ $t('unsubscribeInviteMsg')}}</h2>
                <button v-on:click="unsusubscribe" class="btn btn-primary">{{ $t('unsubscribeButtonMsg')}}</button>
            </template>
            <template v-if="unsubscribing">
                <h2>{{ $t('unsubscribeUnsubscribingMsg')}}</h2>
            </template>
            <template v-if="unsubscribed">
                <h2>{{ $t('commonSuccessMsg')}}</h2>
                <h4>{{ $t('unsubscribeUnsubscribedMsg')}}</h4>
            </template>
            <template v-if="error">
                <h2>{{ $t('commonErrorMsg')}}</h2>
                <h4>{{error}}</h4>
            </template>

            <template v-if="showSpinner">
                <spinner height="50px" colour="#f9ac1b"/>
            </template>

        </div>

    </div>
</template>

<script>
    import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";

    // Queries & mutations
    import { updateUserMarketingSubscription } from "../mytutor-graphql/mutations";

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    // Visual components
    import Spinner from '../components/Spinner';

    export default {
        components: {Spinner},
        mixins: [apiMixin],
        data() {
            return {
                userID: null,
                showSpinner: false,
                unsubscribing: false,
                unsubscribed: false,
                error: null
            }
        },
        computed: {
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            updateUserMarketingSubscription: async function(userID, subscribed) {
                console.debug('updating marketing subscription for user: ' + userID);
                try {
                    let variables = {
                        userID: userID,
                        subscribed: subscribed
                    };
                    const updateSubscriptionRet = await API.graphql({
                        query: updateUserMarketingSubscription,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(updateSubscriptionRet);
                    if (updateSubscriptionRet.data.updateUserMarketingSubscription !== null) {
                        console.debug("Answer received");
                        return updateSubscriptionRet.data.updateUserMarketingSubscription;
                    } else {
                        console.debug("Error updating marketing subscription for user");
                        this.logError("Updating marketing subscription for user", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Updating marketing subscription for user", error, true);
                    return null;
                }
            },

            // ----------------------------------------------------------------------------
            //
            // Account behaviour
            //
            // ----------------------------------------------------------------------------
            unsusubscribe: async function () {
                this.showSpinner = true;
                this.unsubscribing = true;
                const success = await this.updateUserMarketingSubscription(this.userID, false);
                if (success) {
                    this.unsubscribing = false;
                    this.unsubscribed = true;
                    this.showSpinner = false;
                } else {
                    this.unsubscribing = false;
                    this.unsubscribed = false;
                    this.showSpinner = false;
                    this.error = this.$t('unsubscribeErrorMsg');
                }
            },
        },
        mounted: async function () {
            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("uid")) {
                this.userID = searchParams.get("uid");
                console.debug("user ID: " + this.userID);
            }
        },
        beforeDestroy() {
        },
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";

    .form-entry {
        padding-top: 30px;
        text-align: center;

        button {
            width: fit-content;
        }
    }
</style>
