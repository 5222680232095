import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        homeRegisterMsg: 'Register',
        homeSignInMsg: 'Sign in',
        homeHeroMsg: 'Have Fun Improving Your Maths + Problem Solving Skills',
        homeTaglineMsg: 'Better mathematics for a better future.',
        homeCTAWatchVideoMsg: 'Watch The Video',
        homeHowItWorksMsg: 'How it Works',
        homeHowToRegisterMsg: 'Sign up and Register',
        homeHowToRegisterPoint1Msg: 'It takes a few minutes and only requires access to your email.',
        homeHowToRegisterPoint2Msg: 'MyTutor.chat works like any other instant messaging service, simply just answer us.',
        homeHowToRegisterPoint3Msg: 'The service is free!',
        homeHowToPracticeMsg: 'Practice Practice Practice',
        homeHowToPracticePoint1Msg: 'You now on the exciting part where we will give you questions. Use the question and test library to prepare for events.',
        homeHowToPracticePoint2Msg: 'The MyTutor.chat algorithm will present you with questions reflecting your level and ability.',
        homeHowToParticipateMsg: 'Participate in Events',
        homeHowToParticipatePoint1Msg: 'National and International events will be hosted here.',
        homeHowToParticipatePoint2Msg: 'Host local or school based events.',
        homeHowToCheckStatsMsg: 'CHECK YOUR STATS AND MORE',
        homeHowToCheckStatsPoint1Msg: 'Learners can access their statistics, win awards all while gaining leaderboard points.',
        homeHowToCheckStatsPoint2Msg: 'Additional functionality for teachers and parents to monitor progress.',
        homeGiveItAGoMsg: 'Give it a Go',
        homeGiveItAGoPoint1Msg: 'Come back here daily to try another.',
        homeGiveItAGoPoint2Msg: 'Sign up to gain access to hundreds of problems that will be selected for you to meet your level.',
        homeGiveItAGoTryMoreMsg: 'Want to try more?',
        homeGiveItAGoJoinForFreeMsg: 'Join For Free',
        homeWhyJoinMsg: 'Why join MyTutor.chat?',
        homeWhyJoinQuestionsMsg: 'ACCESS QUESTIONS',
        homeWhyJoinQuestionsPoint1Msg: 'Access to our question library with > 2000 questions',
        homeWhyJoinTestsMsg: 'Access tests',
        homeWhyJoinTestsPoint1Msg: 'Access to 50 30 minute tests from our test library',
        homeWhyJoinEventsMsg: 'Participate in Events',
        homeWhyJoinEventsPoint1Msg: 'Online participation in national and international events',
        homeWhyJoinSAMFMsg: 'SAMF talent search',
        homeWhyJoinSAMFPoint1Msg: 'Participate in the SAMF Talent Search for Grades 7-12',
        homeWhyJoinProgressMsg: 'Track your progress',
        homeWhyJoinProgressPoint1Msg: 'Earn awards and points to feature on our leaderboard',
        homeWhyJoinCompetitionsMsg: 'Enter competitions',
        homeWhyJoinCompetitionsPoint1Msg: 'Enter competitions where you could win prizes',
        homeEventsMsg: 'Current & Upcoming Events',
        homeEventsMyTutorHostedMsg: 'MyTutor hosted',
        homeEventsEnterMsg: 'Details',
        homeEventsLivingMathsMsg: 'Living Maths',
        homeEventsLivingMathsDateMsg: 'Oct-Dec 2023',
        homeEventsRWCGeographyMsg: 'RWC Geography',
        homeEventsRWCGeographyDateMsg: 'Oct 2023',
        homeEventsRWCHistoryMsg: 'RWC History',
        homeEventsRWCHistoryDateMsg: 'Oct 2023',
        homeEventsYear2023Msg: 'YEAR 2023',
        homeEventsYear2023DateMsg: 'Ends 31 Dec 2023',
        homeEventsRWCMsg: '2023 Rugby World Cup Maths',
        homeEventsRWCDateMsg: '8 Sep - 28 Oct 2023',
        homeQuickLinksMsg: 'Quick Links',
        homeQuickLinksAboutMsg: 'About',
        homeQuickLinksContactMsg: 'Contact Us',
        homeQuickLinksPrivacyMsg: 'Privacy Policy',
        homeQuickLinksTermsMsg: 'Terms & Conditions',
        commonLanguageEnglishMsg: 'English',
        commonLanguageFrenchMsg: 'French',
        commonLanguagePortugueseMsg: 'Portuguese',
        commonUserTypeLearnerMsg: 'Learner',
        commonUserTypeTeacherMsg: 'Teacher',
        commonUserTypeParentMsg: 'Parent',
        commonUserTypeObserverMsg: 'Observer',
        commonPasswordMsg: 'Password *',
        commonShowPasswordMsg: 'Show password',
        commonEnterPasswordMsg: 'Please enter a password',
        commonAnonymousMsg: 'Prefer not to say',
        commonGenderMaleMsg: 'Male',
        commonGenderFemaleMsg: 'Female',
        commonGenderOtherMsg: 'Other',
        commonRaceBlackMsg: 'Black',
        commonRaceColouredMsg: 'Coloured',
        commonRaceIndianMsg: 'Indian',
        commonRaceWhiteMsg: 'White',
        commonDoneMsg: 'Done',
        commonCloseMsg: 'Close',
        commonCancelMsg: 'Cancel',
        commonStartMsg: 'Start',
        commonSuccessMsg: 'Success',
        commonErrorMsg: 'Error',
        commonButtonAddMsg: 'ADD',
        commonButtonCancelMsg: 'CANCEL',
        commonButtonSaveMsg: 'SAVE',
        commonAlreadyHaveAccountMsg: 'Already have an account?',
        commonSignInMsg: 'Sign in',
        commonSignUpMsg: 'Sign up',
        commonUnknownErrorMsg: 'Unknown error',
        commonLabelUsernameMsg: 'Username *',
        commonEnterUsernameMsg: 'Please enter your username',
        commonResetPasswordMsg: 'Reset Password',
        commonSkipMsg: 'Skip',
        commonSkipLCMsg: 'skip',
        commonSkipsLCMsg: 'skips',
        commonSkipsLeftMsg: 'left',
        commonTeachersHeadingMsg: 'TEACHERS',
        commonTestsTitleLearnersMsg: 'LEARNERS',
        commonTestsRegisterMsg: 'register for a test',
        commonTestsRegisterAltMsg: 'Register for test',
        commonTestsOpenTestEntryMsg: 'open your test entry',
        commonTestsOpenCompletedTestEntryMsg: 'view completed entry',
        commonTestsOpenTestEntryAltMsg: 'Open test',
        commonTestsTitleUpcomingMsg: 'Upcoming',
        commonTestsTitleCurrentMsg: 'Current',
        commonTestsTitlePastMsg: 'Past',
        commonTestsTypeSelfStartMsg: 'SELF-START',
        commonTestsTypeInvigilatedMsg: 'INVIGILATED',
        commonTestsGradeMsg: 'Grade',
        commonTestsLearnersRegisteredMsg: 'learners',
        commonTestsStartWhenReadyMsg: 'Start when ready',
        commonTestsTermAndConditionsMsg: 'Terms & Conditions',
        commonTestsInviteLearnersMsg: 'Invite learners to participate',
        commonTestsScoreMsg: 'Score:',
        commonDownloadZIPMsg: 'Download ZIP',
        commonLeaderboardTopMsg: 'TOP',
        commonLeaderboardTitleMsg: 'Leaderboard',
        commonLeaderboardAllMsg: 'All',
        commonLeaderboardAllLearnersMsg: 'All learners',
        commonLeaderboardSlideLeftMsg: 'Slide Leaderboard grade selector left',
        commonLeaderboardSlideRightMsg: 'Slide Leaderboard grade selector right',
        commonLeaderboardGradeAbbreviationMsg: 'G',
        commonLeaderboardNameMsg: 'NAME',
        commonLeaderboardEventMsg: 'EVENT',
        commonLeaderboardGradeMsg: 'GRADE',
        commonLeaderboardDateMsg: 'DATE',
        commonLeaderboardSchoolMsg: 'SCHOOL',
        commonLeaderboardLearnerMsg: 'LEARNER',
        commonLeaderboardLearnersMsg: 'LEARNERS',
        commonLeaderboardQuestionsMsg: 'QUESTIONS',
        commonLeaderboardAnswerMsg: 'ANSWER',
        commonLeaderboardAnsweredMsg: 'ANSWERED',
        commonLeaderboardDoneMsg: 'DONE',
        commonLeaderboardRoundScoreMsg: 'ROUND SCORE',
        commonLeaderboardTotalScoreMsg: 'TOTAL SCORE',
        commonLeaderboardAvgScoreMsg: 'AVG SCORE',
        commonLeaderboardFirstNameMsg: 'FIRST NAME',
        commonLeaderboardSurnameMsg: 'SURNAME',
        commonLeaderboardEmailMsg: 'EMAIL',
        commonLeaderboardAnsweredAbrMsg: 'ANS',
        commonLeaderboardTimeMsg: 'TIME',
        commonLeaderboardScoreMsg: 'SCORE',
        commonLeaderboardEnteredMsg: 'ENTERED',
        commonLeaderboardSpreadMsg: 'SPREAD',
        commonLeaderboardCorrectMsg: 'CORRECT',
        commonLeaderboardCorrectInARowMsg: 'CORRECT IN A ROW',
        commonLeaderboardTotalAwardsMsg: 'Answered total awards',
        commonLeaderboardCorrectAwardsMsg: 'Answered correct awards',
        commonLeaderboardStreakAwardsMsg: 'Streak awards',
        commonCopyToClipboardMsg: 'Copy code to clipboard',
        commonCopiedMsg: 'Copied',
        commonWhatIsMyTutorMsg: 'What is MyTutor.chat?',
        headerMenuChatMsg: 'Question Chat (Home)',
        headerMenuLeaderboardMsg: 'Leaderboard',
        headerMenuHomeworkMsg: 'Extension Worksheets',
        headerMenuHomeworkUbuntuMsg: 'Ubuntu Worksheets',
        headerMenuHomeworkPastPaperMsg: 'Past Paper Worksheets',
        headerMenuEventsAndTestsMsg: 'Events & Tests',
        headerMenuCompetitionPreparationMsg: 'Competition Preparation',
        headerMenuPastPapersMsg: 'Past Papers',
        headerMenuKangarooPrefixMsg: 'Kangaroo',
        headerMenuKangarooSuffixMsg: 'Event',
        headerMenuYear2023Msg: 'Year 2 0 2 3 Event',
        headerMenuYear2024Msg: 'Year 2 0 2 4 Event',
        headerMenuUCTMsg: 'UCT Maths Competition',
        headerMenuRugbyWorldCupMsg: 'Rugby World Cup',
        headerMenuLivingMathsMsg: 'Living Maths Competition',
        headerMenuTestsToMarkMsg: 'Tests to mark',
        headerMenuMyProfileMsg: 'My Profile',
        headerMenuMyStudentsMsg: 'My Students',
        headerMenuMyStudentsTestEntriesMsg: 'My Student\'s Test Entries',
        headerMenuMyStudentsWorksheetEntriesMsg: 'My Student\'s Worksheets',
        headerMenuContactUsMsg: 'Contact us',
        chatBusyLoadingMsg: 'Loading your chat history ...',
        chatBusyReplyingMsg: 'MyTutor is replying ...',
        chatInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        eventEntryExternalRegisteringMsg: 'Registering you and opening your test',
        eventEntryExternalProgressRegisteringMsg: 'Registering you for the test',
        eventEntryExternalProgressSuccessMsg: 'Registration successful, authorising',
        eventEntryExternalProgressHousekeepingMsg: '',
        eventEntryExternalProgressErrorMsg: '',
        eventEntryExternalProgressOpeningMsg: '',
        eventEntryExternalProgressLoadingParametersMsg: '',
        eventEntryExternalProgressErrorMissingParametersMsg: '',
        openTestEntryTitleMsg: 'Opening your test',
        openTestEntryProgressLoadingMsg: 'Loading your Test Entry',
        openTestEntryProgressAuthorisingMsg: 'Test Entry found, authorising',
        openTestEntryProgressHouseKeepingMsg: 'Doing some housekeeping',
        openTestEntryProgressOpeningTestMsg: 'Opening test',
        openTestEntryProgressErrorOpeningMsg: 'There was an error opening your test entry',
        openTestEntryProgressErrorPrefixMsg: 'Error: ',
        openTestEntryErrorMsg: 'Please email info@mytutor.chat for assistance.',
        passwordResetTitleMsg: 'Reset your password',
        passwordResetButtonSendCodeMsg: 'Send Code',
        passwordResetCheckCodeMsg: 'Check your email for your code',
        passwordResetLabelVerificationCodeMsg: 'Verification code *',
        passwordResetEnterVerificationCodeMsg: 'Please enter the emailed verification code',
        passwordResetLabelNewPasswordMsg: 'New password *',
        passwordResetEnterNewPasswordMsg: 'Please choose a new password',
        passwordResetErrorUserDoesNotExistMsg: 'User does not exist, or internal error',
        passwordResetErrorMsg: 'There was an error resetting your password:',
        registerTitleMsg: 'Create an account',
        registerSubTitleMsg: 'Access the world\'s largest<br>maths olympiad training platform',
        registerLabelFirstNameMsg: 'First name *',
        registerEnterFirstNameMsg: 'Please enter your first name',
        registerLabelLastNameMsg: 'Last name *',
        registerEnterLastNameMsg: 'Please enter your last name',
        registerUsernameNote1Msg: 'Choose your unique username of at least 6 characters.',
        registerUsernameNote2Msg: 'Use letters, numbers and - _ . @',
        registerUsernameTakenMsg: 'That username has already been taken.',
        registerSignInInsteadPart1Msg: 'Would you like to',
        registerSignInInsteadPart2Msg: 'sign in',
        registerSignInInsteadPart3Msg: 'instead',
        registerEnterUsernameMsg: 'Please choose a unique username of at least 6 characters',
        registerLabelEmailMsg: 'Email',
        registerEmailRecommendedMsg: 'recommended',
        registerNote1EmailMsg: 'Needed for password recovery and to receive test results',
        registerNote2EmailMsg: 'Teachers should enter for scheduling of tests',
        registerEmailInvalidMsg: 'Please enter a valid email address',
        registerEmailRegistrationErrorMsg: 'There was an error with registration:',
        registerAboutYouHeadingMsg: 'Almost there!',
        registerAboutYouSubHeadingMsg: 'Please tell us a bit about yourself<br>so that we can personalise your experience',
        registerAboutYouRegistrationErrorMsg: 'There was an error finishing registration:',
        registerLabelLanguageMsg: 'Language *',
        registerLabelUserTypeMsg: 'I am a *',
        registerOptionUserTypeNotApplicableMsg: 'Not applicable',
        registerOptionUserTypeObserverMsg: 'Observer',
        registerLabelGradeMsg: 'In grade *',
        registerOptionGradeSelectMsg: 'Select a grade',
        registerGradeValidationErrorMsg: 'Please select your grade',
        registerLabelMathsMarkMsg: 'My maths mark last year was *',
        registerMathsMarkValidationErrorMsg: 'Please enter last year\'s maths mark, from 0 to 100',
        registerLabelNicknameMsg: 'My friends call me',
        registerLabelCountryMsg: 'I live in *',
        registerCountryNoteMsg: 'Different events run in different countries at different times',
        registerLabelEmail2Msg: 'Email *',
        registerEmailValidationErrorMsg: 'Please enter a valid email address',
        registerLabelSchoolMsg: 'My school name is *',
        registerSchoolValidationErrorMsg: 'Please enter your school name',
        registerLabelGenderMsg: 'My gender is *',
        registerOptionGenderSelectMsg: 'Select an answer',
        registerGenderValidationErrorMsg: 'Please select your gender',
        registerLabelRaceMsg: 'My demographic is *',
        registerOptionRaceSelectMsg: 'Select an answer',
        registerRaceValidationErrorMsg: 'Please select your demographic',
        registerButtonMsg: 'GET STARTED',
        registerErrorRegisteringOnPlatformMsg: 'Error registering you on the MyTutor platform',
        registerErrorRegisteringCannotConfirmMsg: 'Error registering - cannot confirm account',
        signInPasswordIsResetMsg: 'Your password has been reset',
        signInErrorSigningInMsg: 'Error signing in:',
        signInDontHaveAccountMsg: 'Don\'t have an account?',
        signInRegisterMsg: 'Register',
        signInForgotPasswordMsg: 'Forgot your password?',
        signInVerifyPasswordMsg: 'Verify password *',
        signInEnterPasswordAgainMsg: 'Please enter your password again',
        signInPasswordMismatchMsg: 'Password does not match. Please try again.',
        signInVerifyMsg: 'Verify',
        signInUserDoesNotExistMsg: 'User does not exist',
        testRoundMsg: 'ROUND',
        testFetchingTestMsg: 'Fetching your test...',
        testScheduledStartMsg: 'Your test is scheduled to start on',
        testScheduledStartComeBackMsg: 'Please come back again then, or go to Menu > Home, and do some preparation questions.',
        testStartAnytimePart1Msg: 'You can start the test at any time. Once you start, you have',
        testStartAnytimePart2Msg: 'to finish the test.',
        testInstructionsMsg: 'Instructions',
        testStartInMsg: 'The test will start in',
        testSectionStartInMsg: 'The next section will start in',
        testFinishedMsg: 'The test has finished',
        testLoadingQuestionMsg: 'Loading the next question...',
        testSavingAnswerMsg: 'Saving your answer',
        testNoQuestionsInTestEntryMsg: 'There were no questions in this test entry.',
        testFinishedSubmitMsg: 'I\'ve finished - Submit',
        testRankingsAfterRoundMsg: 'Rankings after Round',
        testThanksForTakingPartMsg: 'Thank you and your learners for taking part, the test has now come to a end. As this is a competition, the results and answers are not released immediately.',
        testFinishedWellDoneMsg: '<b>Well done</b>, you have come to the end of the test! We hope you enjoyed taking part.',
        testTestFinishedRoundAdviceMsg: 'Use the round buttons above to <b>look over your marked answers</b>. While you\'re there, have a look at the <b>solutions for each question</b> - see if your reasoning was correct or perhaps how to get to the solution if you struggled to answer a question.',
        testFinishedLeaderboardMsg: 'We\'ve also included the overall leaderboard below so that you can see how you fared.',
        testOverallLeaderboardMsg: 'Overall Leaderboard',
        testQuestionStatisticsMsg: 'Question statistics',
        testRoundLCMsg: 'Round',
        testRoundPeopleStartedMsg: 'people started',
        testLoadingTestMsg: 'Loading test',
        testStartsMsg: 'Starts:',
        testGoToTestMsg: 'Go to Test',
        testStartRoundMsg: 'Start Round',
        testStartTestMsg: 'Start Test',
        testStartingRoundMsg: 'Starting Round',
        testWillStartInMsg: 'Test will start in',
        testTimeRemainingMsg: 'Time remaining',
        testEndRoundMsg: 'End Round',
        testTestHasFinishedMsg: 'Test has finished',
        testLoadingMsg: 'Loading...',
        testComeBackAfterMsg: 'Come back after',
        testStartingMsg: 'Starting',
        testCompleteMsg: 'complete',
        testTestWillStartOnMsg: 'Test will start on ',
        testTestStartInMsg: 'Test starts in ',
        testRoundStartsInMsg: 'Round starts in ',
        testWaitingForRoundToStartMsg: 'Waiting for next round to start',
        testTypeQuizMsg: 'Quiz',
        testTypeTestMsg: 'Test',
        testTypeAssignmentMsg: 'Assignment',
        testTeacherNotInvigilatorMsg: 'Only the teacher that is the invigilator can open and run this test',
        testConfirmStartTestMsg: 'Are you sure you want to start this section or test?',
        testToggleStatsOffMsg: 'Toggle stats off',
        testToggleStatsOnMsg: 'Toggle stats on',
        unsubscribeTitleMsg: 'Unsubscribe',
        unsubscribeInviteMsg: 'Would you like to unsubscribe from future event notifications?',
        unsubscribeButtonMsg: 'Unsubscribe',
        unsubscribeUnsubscribingMsg: 'Unsubscribing you from all future event notifications',
        unsubscribeUnsubscribedMsg: 'You have been unsubscribed from all future event notifications',
        unsubscribeErrorMsg: 'There was an error un-scribing you, apologies. Please try again later.',
        addSchedulersTitleMainMsg: 'View & approve Schedulers',
        addSchedulersTitleListMsg: 'Schedulers',
        addSchedulersInstructionsPoint1Msg: 'You have been approved to schedule tests for the events listed below',
        addSchedulersInstructionsPoint2Msg: 'You can approve other schedulers for the same events',
        addSchedulersListMsg: 'Below is a list of schedulers you have approved',
        addSchedulersListNonApprovedMsg: 'You have not approved any schedulers yet',
        addSchedulersAddButtonSchedulerMsg: 'SCHEDULER',
        competitionPreparationNoTestsMsg: 'No current competition practice tests',
        listOfTestsNoTestsPrefixMsg: 'No current ',
        listOfTestsNoTestsSuffixMsg: ' tests',
        listOfTestsTalentSearchMsg: 'Talent Search',
        listOfTestsUCTMsg: 'UCT Maths Competition',
        listOfTestsCompetitionPreparationMsg: 'competition Preparation',
        listOfTestsPastPapersMsg: 'Past Papers',
        listOfTestsYear2023Msg: 'Year 2023 Event',
        listOfTestsYear2024Msg: 'Year 2024 Event',
        listOfTestsLivingMaths2023Msg: 'Living Maths Competition 2023',
        kangaroo2023TitleMsg: 'Kangaroo International Mathematics Competition 2023',
        kangaroo2023ParticipationOptionsMsg: 'Learners can participate either online or offline.',
        kangaroo2023RecommendationsHeadingMsg: 'Recommendations',
        kangaroo2023RecommendationPoint1PrefixMsg: 'Learners participate',
        kangaroo2023RecommendationPoint1Msg: 'Learners participate <strong>online</strong> if they have access to a device and data (by creating a free account learners can register now, and self-start in the test window from the Kangaroo 2023 menu item)',
        kangaroo2023RecommendationPoint2Msg: 'Learners participate <strong>offline</strong> if they are prevented from participating by load shedding, a lack of a device or have limited internet access (see below)',
        kangaroo2023OfflineHeadingMsg: 'Offline participation',
        kangaroo2023OfflineDownloadMsg: 'Teachers can download papers, solutions and answer sheets:',
        kangaroo2023FillInFormPrefixMsg: 'At the end of the event, please',
        kangaroo2023FillInFormCTAMsg: 'fill in this form',
        kangaroo2023FillInFormSuffixMsg: 'to tell us how many students participated (takes 1 minute)',
        kangaroo2023InternationalTitlePrefixMsg: 'Kangaroo',
        kangaroo2023InternationalTitleSuffixMsg: 'Mathematics Competition 2023',
        uctCompetitionTitleMsg: 'UCT Mathematics Competition',
        uctCompetitionPara1Msg: 'The UCT Mathematics Competition was established in 1977, making it one of the oldest mathematics competitions in the world. It is an annual event with 7500 learners from 400 high schools in the Western Cape, a province in South Africa, participating on the University of Cape Town Upper Campus.',
        uctCompetitionPara2Msg: 'Each school is able to enter 5 individuals and 5 pairs per grade (for a maximum of 15 learners). The event offers prizes and trophies for those that participate on the campus.',
        uctCompetitionPara3Msg: 'We have made the papers available here so that teachers can make the question papers available to more learners:<br/> - to those that did not represent their school<br/> - to those for whom the event is too far to travel, say for those that are from outside the Western Cape, whether they be in South Africa or further afield.',
        uctCompetitionPara4Msg: '<strong>Note:</strong> Only those participating on 25 July in the invigilated environment on upper campus will be eligible for the prizes, and the Mona Leeuwenburg Trophy in the inter-school competition.',
        uctCompetitionPara5Msg: 'In 2023, teachers can help learners to write by downloading the zip file for each of the Grade 8-Grade 12 papers. In 2024, MyTutor.chat will enable the learners that did not participate in the event on Upper Campus to participate digitally after that event.',
        uctCompetitionPara6Msg: 'Teachers can download papers, solutions and answer sheets here:',
        leaderboardByEntrantMsg: 'By Entrant',
        leaderboardBySchoolMsg: 'By School',
        leaderboardNoResultsMsg: 'There are no results for this test',
        myStudentsTitleMsg: 'My Students',
        myStudentsLast7DaysMsg: 'LAST 7 DAYS',
        myStudentsLastTodayMsg: 'TODAY',
        myStudentsNoStudentsMsg: 'No students',
        myStudentsUnlinkMsg: 'UNLINK',
        myStudentsUnlinkedPrefixMsg: 'Successfully unlinked',
        myStudentsUnlinkedSuffixMsg: 'learners',
        myStudentsUnlinkingErrorMsg: 'Error un-linking students',
        myStudentsTestEntriesTitleMsg: 'My Students Test Entries',
        myStudentsTestEntriesNoEntriesMsg: 'No test entries',
        myStudentsWorksheetEntriesTitleMsg: 'My Students Worksheet Entries',
        myStudentsWorksheetEntriesNoEntriesMsg: 'No worksheet entries',
        quizQuestionHeadingMsg: 'QUESTION',
        quizQuestionViewFormulaSheetMsg: 'View Formula Sheet',
        quizQuestionAnswerMsg: 'ANSWER',
        quizQuestionCorrectMsg: 'Correct',
        quizQuestionIncorrectMsg: 'Incorrect',
        quizQuestionInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        quizQuestionDragPhotoMsg: 'Drag your photo here to upload<br> or click to browse',
        quizQuestionUploadPhotoMsg: 'Uploading your photo...',
        quizQuestionUploadFailedMsg: 'Upload failed',
        quizQuestionDeletingPhotoMsg: 'Deleting your photo...',
        quizQuestionPhotoDeletedMsg: 'Photo deleted',
        quizQuestionDeleteFailedMsg: 'Delete failed',
        quizQuestionNoPhotoUploadedMsg: 'No photo uploaded',
        quizQuestionMarkingFeedbackMsg: 'MARKING FEEDBACK:',
        quizQuestionTotalAbbreviationMsg: 'Tot:',
        quizQuestionPlaceholderUnansweredMsg: 'unanswered',
        quizQuestionSavingMsg: 'saving...',
        quizQuestionSavedMsg: '...saved',
        createTestHeadingMsg: 'Create Test',
        createTestHeadingDesktopSuffixMsg: 'from Question Set',
        createTestCreatingMsg: 'Creating test ...',
        createTestOpenToScheduleMsg: 'open test to schedule',
        createTestOpenToScheduleAltMsg: 'Open test',
        createTestQuestionSetMsg: 'QUESTION SET',
        createTestWhenCanScheduleMsg: 'WHEN CAN YOU SCHEDULE THIS TEST?',
        createTestDurationMsg: 'DURATION',
        createTestNameMsg: 'TEST NAME',
        createTestAddTestButtonMsg: 'ADD TEST',
        createTestFormatOlympiadMsg: 'Olympiad',
        createTestFormatPubQuizMsg: 'Pub quiz',
        createTestFormatWorksheetMsg: 'Worksheet',
        createTestOutsideWindowMsg: 'The test cannot be scheduled outside the participation window. Please try again',
        createTestErrorMsg: 'Error creating test',
        testPracticeFetchingMsg: 'Fetching practice questions ...',
        testPracticeHeadingMsg: 'Practice questions',
        testPracticeInviteMsg: 'Before starting the test, we invite you to try the 3 practice questions below to familiarise yourself with the test format. Once you\'ve answered the last practice question, the questions will be marked and will reload showing whether your answered them correctly or not, and you will be able to view the solutions',
        testPracticeStartButtonMsg: 'Start practice',
        testPracticeSavingYourAnswerMsg: 'Saving your answer...',
        testPracticeInviteWhileWaitingMsg: 'While you wait for the test to start, we suggest that you answer the below practice questions to familiarise yourself with the test format. Once you\'re finished, press "Submit practice questions" to mark them and see how you\'ve done.',
        testPracticeSubmitButtonMsg: 'Submit practice questions',
        eventsTitleMsg: 'Events & Tests',
        eventsLoadingMsg: 'Loading tests',
        eventsOpenTestMsg: 'open a test you scheduled',
        viewTestDetailsHeadingMsg: 'Test Details',
        viewTestDetailsCloseMsg: 'Close test details',
        viewTestDetailsEditDateMsg: 'Edit date',
        viewTestDetailsTestCodeMsg: 'TEST CODE',
        viewTestDetailsTestRegistrationURLMsg: 'TEST REGISTRATION URL',
        viewTestDetailsAvailableMsg: 'Available',
        viewTestDetailsFromMsg: 'from',
        viewTestDetailsTestEntrantsMsg: 'Test Entrants',
        profileMyMsg: 'My',
        profileImagePlaceholderMsg: 'Placeholder profile image',
        profileInformationMsg: 'Information',
        profileStatisticsMsg: 'Statistics',
        profileLinkToTeacherAndFriendsMsg: 'LINK LEARNERS TO TRACK THEIR PROGRESS - ASK THEM TO VISIT THIS URL',
        profileLinkCodeMsg: 'YOUR LINK CODE',
        profileEditEmailMsg: 'Edit email',
        profileNotificationsMsg: 'NOTIFICATIONS',
        profileEmailMeMsg: 'Email me about future events & competitions',
        profileNicknameMsg: 'NICKNAME',
        profileEditNicknameMsg: 'Edit nickname',
        profileEditFirstNameMsg: 'Edit first name',
        profileEditSurnameMsg: 'Edit surname',
        profileEditSchoolMsg: 'Edit School',
        profileAccountTypeMsg: 'TYPE',
        profileLanguageMsg: 'LANGUAGE',
        profileGenderMsg: 'GENDER',
        profileCountryMsg: 'COUNTRY',
        profileMyStatisticsMsg: 'My Statistics',
        profileQuestionsAnsweredMsg: 'Questions answered',
        profileQuestionsAnsweredCorrectlyMsg: 'Questions answered correctly',
        profileStreakCorrectMsg: 'Longest streak of correct answers',
        profileDaysActiveMsg: 'Days Active',
        profilePointsAccumulatedMsg: 'Points accumulated',
        profileMyBadgesMsg: 'My Badges',
        profileTotalQuestionsMsg: 'TOTAL QUESTIONS',
        profileNotEnteredYetMsg: 'not entered yet',
        page404Msg: 'Page not found',
        aboutTitleMsg: 'About',
        aboutIntro1Msg: '<b>MyTutor.chat</b> is a South African built free-to-access digital teaching tool that assists with mathematics education by offering learners appropriately graded questions to address their learning objectives. By offering personal journeys - tailored to each learners age, grade and capability - we reach the users where they are: in their homes, in their <b>zone of proximal development</b> where learning is most productive.',
        aboutIntro2Msg: 'Our initial focus is on high school students, and those dealing with <b>mathematics competition questions</b> which have an emphasis on problem solving techniques. We intend extending our offering to cover components of the CAPS curriculum over the course of 2021 and beyond.',
        aboutFuturePlansHeadingMsg: 'Future plans',
        aboutFuturePlansPart1Msg: 'In addition to constantly extending the question and answer bank, we are planning on adding worksheets, online testing functionality and offering our service in additional languages.',
        aboutFuturePlansPart2Msg: 'We hope to also offer functionality for teachers and parents as well as learners.',
        aboutFounderHeadingMsg: 'Founder',
        aboutFounderPart1Msg: 'MyTutor.chat was founded by Greg Becker in 2020. Greg is a retired actuary and mathematics teacher who saw the need for improving access to quality mathematics education across South Africa.',
        aboutStateOfSAMathsHeadingMsg: 'The State of South African Mathematics Education',
        aboutStateOfSAMathsPart1Msg: 'Very few South Africans are obtaining marks for matric mathematics that prepares them for the rigors of a university education in a STEM field. The current goal for the Minister of Basic Education is to improve results so that 35,000 obtain at least 60% for mathematics by 2024 - a rise from 28,000 in 2019. While this is a movement in the right direction, this is a very low number when compared to other countries - those that will be competing with us in the globalised world of tomorrow.',
        aboutStateOfSAMathsPart2Msg: 'The international benchmarking assessment conducted by TIMSS in 2019 shows that while less than 1% of South African students scored more than 625 on their competency scale, 51% of those in Singapore reached obtained this mark. At the other end of the scale, 98% of learners in Singapore obtained at least 400 - while only 41% of learners assessed in South Africa did. We were joint last with Morocco, behind countries like Chile, Egypt, Malaysia, Oman, Romania, and Turkey.',
        aboutStateOfSAMathsPart3Msg: 'For those who are interested, international studies produced by TIMSS are worth looking into, as are those by SACEMA and the annual matric results released by the Department of Education. The international benchmarking studies allow for an interpretation of our domestic matric results.',
        contactUsTitleMsg: 'Contact',
        contactUsPart1Msg: '<strong>Sharks Quiz:</strong> If you are from Ghana and having problems signing up for the Sharks Quiz, please make sure that you have selected Ghana as your country (the test is not available to those who have not completed the initial registration questions and listed their country as Ghana. (You can change this by going to MyProfile on the menu.)',
        contactUsPart2Msg: 'You can reach us by email on',
        contactUsPart3Msg: 'Please include a screenshot of your MyProfile so that we can follow up your situation.',
        logicDayCTAMsg: 'Enter the Logic Day Challenge',
        talentSearchCTAMsg: 'Enter the Talent Search',
        homeworkTitleMsg: 'Homework',
        homeworkNoWorksheetsMsg: 'There are currently no worksheets',
        homeworkWorksheetsHeadingMsg: 'Worksheets',
    },
    'fr': {
        homeRegisterMsg: 'S\'inscrire',
        homeSignInMsg: 'S\'identifier',
        homeHeroMsg: 'Amusez-vous à améliorer vos compétences en mathématiques et en résolution de problèmes',
        homeTaglineMsg: 'De meilleures mathématiques pour un meilleur avenir.',
        homeCTAWatchVideoMsg: 'Regarder la vidéo',
        homeHowItWorksMsg: 'Comment ça marche',
        homeHowToRegisterMsg: 'S\'inscrire et s\'enregistrer',
        homeHowToRegisterPoint1Msg: 'Cela ne prend que quelques minutes et ne nécessite qu\'un accès à votre e-mail.',
        homeHowToRegisterPoint2Msg: 'MyTutor.chat fonctionne comme n\'importe quel autre service de messagerie instantanée, il suffit de nous répondre.',
        homeHowToRegisterPoint3Msg: 'Le service est gratuit !',
        homeHowToPracticeMsg: 'Pratique Pratique Pratique',
        homeHowToPracticePoint1Msg: 'Vous êtes maintenant dans la partie passionnante où nous allons vous poser des questions. Utilisez la bibliothèque de questions et de tests pour vous préparer aux événements.',
        homeHowToPracticePoint2Msg: 'L\'algorithme de MyTutor.chat vous présentera des questions selon votre niveau et vos capacités.',
        homeHowToParticipateMsg: 'Participez aux événements',
        homeHowToParticipatePoint1Msg: 'Des événements nationaux et internationaux seront organisés ici.',
        homeHowToParticipatePoint2Msg: 'Organisez des événements locaux ou basés sur l\'école.',
        homeHowToCheckStatsMsg: 'VÉRIFIEZ VOS STATISTIQUES ET PLUS ENCORE',
        homeHowToCheckStatsPoint1Msg: 'Les apprenants peuvent accéder à leurs statistiques, gagner des récompenses tout en gagnant des points au classement.',
        homeHowToCheckStatsPoint2Msg: 'Une fonctionnalité supplémentaire permet aux enseignants et aux parents de suivre les progrès.',
        homeGiveItAGoMsg: 'Tentez votre chance',
        homeGiveItAGoPoint1Msg: 'Revenez ici tous les jours pour en essayer un autre.',
        homeGiveItAGoPoint2Msg: 'Inscrivez-vous pour accéder à des centaines de problèmes qui seront sélectionnés pour vous en fonction de votre niveau.',
        homeGiveItAGoTryMoreMsg: 'Vous voulez en essayer plus ?',
        homeGiveItAGoJoinForFreeMsg: 'S\'inscrire gratuitement',
        homeWhyJoinMsg: 'Pourquoi rejoindre MyTutor.chat ?',
        homeWhyJoinQuestionsMsg: 'ACCÈS AUX QUESTIONS',
        homeWhyJoinQuestionsPoint1Msg: 'Accès à notre bibliothèque de questions avec > 2000 questions',
        homeWhyJoinTestsMsg: 'Accéder aux tests',
        homeWhyJoinTestsPoint1Msg: 'Accès à 50 tests de 30 minutes de notre bibliothèque de tests',
        homeWhyJoinEventsMsg: 'Participer à des événements',
        homeWhyJoinEventsPoint1Msg: 'Participation en ligne à des événements nationaux et internationaux',
        homeWhyJoinSAMFMsg: 'Recherche de talents SAMF',
        homeWhyJoinSAMFPoint1Msg: 'Participez à la recherche de talents SAMF pour les élèves de la 5ème à la Terminale',
        homeWhyJoinProgressMsg: 'Suivez vos progrès',
        homeWhyJoinProgressPoint1Msg: 'Gagnez des récompenses et des points pour figurer sur notre tableau de classement.',
        homeWhyJoinCompetitionsMsg: 'Participez à des concours',
        homeWhyJoinCompetitionsPoint1Msg: 'Participez à des concours et gagnez des prix',
        homeEventsMsg: 'Événements actuels et à venir',
        homeEventsMyTutorHostedMsg: 'MyTutor hébergé',
        homeEventsEnterMsg: 'Détails',
        homeEventsLivingMathsMsg: 'Vivre Mathématiques',
        homeEventsLivingMathsDateMsg: 'Oct-Déc 2023',
        homeEventsRWCGeographyMsg: 'RWC Géographie',
        homeEventsRWCGeographyDateMsg: 'Oct. 2023',
        homeEventsRWCHistoryMsg: 'RWC Histoire',
        homeEventsRWCHistoryDateMsg: 'Oct. 2023',
        homeEventsYear2023Msg: 'ANNÉE 2023',
        homeEventsYear2023DateMsg: 'Se termine le 31 décembre 2023',
        homeEventsRWCMsg: '2023 Coupe du monde de rugby Maths',
        homeEventsRWCDateMsg: '8 Sep - 28 Oct 2023',
        homeQuickLinksMsg: 'Liens rapides',
        homeQuickLinksAboutMsg: 'A propos de',
        homeQuickLinksContactMsg: 'Nous contacter',
        homeQuickLinksPrivacyMsg: 'Politique de confidentialité',
        homeQuickLinksTermsMsg: 'Conditions générales',
        commonLanguageEnglishMsg: 'English',
        commonLanguageFrenchMsg: 'French',
        commonLanguagePortugueseMsg: 'Portuguese',
        commonUserTypeLearnerMsg: 'Learner',
        commonUserTypeTeacherMsg: 'Teacher',
        commonUserTypeParentMsg: 'Parent',
        commonUserTypeObserverMsg: 'Observer',
        commonPasswordMsg: 'Password *',
        commonShowPasswordMsg: 'Show password',
        commonEnterPasswordMsg: 'Please enter a password',
        commonAnonymousMsg: 'Prefer not to say',
        commonGenderMaleMsg: 'Male',
        commonGenderFemaleMsg: 'Female',
        commonGenderOtherMsg: 'Other',
        commonRaceBlackMsg: 'Black',
        commonRaceColouredMsg: 'Coloured',
        commonRaceIndianMsg: 'Indian',
        commonRaceWhiteMsg: 'White',
        commonDoneMsg: 'Done',
        commonCloseMsg: 'Close',
        commonCancelMsg: 'Cancel',
        commonStartMsg: 'Start',
        commonSuccessMsg: 'Success',
        commonErrorMsg: 'Error',
        commonButtonAddMsg: 'ADD',
        commonButtonCancelMsg: 'CANCEL',
        commonButtonSaveMsg: 'SAVE',
        commonAlreadyHaveAccountMsg: 'Already have an account?',
        commonSignInMsg: 'Sign in',
        commonSignUpMsg: 'Sign up',
        commonUnknownErrorMsg: 'Unknown error',
        commonLabelUsernameMsg: 'Username *',
        commonEnterUsernameMsg: 'Please enter your username',
        commonResetPasswordMsg: 'Reset Password',
        commonSkipMsg: 'Skip',
        commonSkipLCMsg: 'skip',
        commonSkipsLCMsg: 'skips',
        commonSkipsLeftMsg: 'left',
        commonTeachersHeadingMsg: 'TEACHERS',
        commonTestsTitleLearnersMsg: 'LEARNERS',
        commonTestsRegisterMsg: 'register for a test',
        commonTestsRegisterAltMsg: 'Register for test',
        commonTestsOpenTestEntryMsg: 'open your test entry',
        commonTestsOpenCompletedTestEntryMsg: 'view completed entry',
        commonTestsOpenTestEntryAltMsg: 'Open test',
        commonTestsTitleUpcomingMsg: 'Upcoming',
        commonTestsTitleCurrentMsg: 'Current',
        commonTestsTitlePastMsg: 'Past',
        commonTestsTypeSelfStartMsg: 'SELF-START',
        commonTestsTypeInvigilatedMsg: 'INVIGILATED',
        commonTestsGradeMsg: 'Grade',
        commonTestsLearnersRegisteredMsg: 'learners',
        commonTestsStartWhenReadyMsg: 'Start when ready',
        commonTestsTermAndConditionsMsg: 'Terms & Conditions',
        commonTestsInviteLearnersMsg: 'Invite learners to participate',
        commonTestsScoreMsg: 'Score:',
        commonDownloadZIPMsg: 'Download ZIP',
        commonLeaderboardTopMsg: 'TOP',
        commonLeaderboardTitleMsg: 'Leaderboard',
        commonLeaderboardAllMsg: 'All',
        commonLeaderboardAllLearnersMsg: 'All learners',
        commonLeaderboardSlideLeftMsg: 'Slide Leaderboard grade selector left',
        commonLeaderboardSlideRightMsg: 'Slide Leaderboard grade selector right',
        commonLeaderboardGradeAbbreviationMsg: 'G',
        commonLeaderboardNameMsg: 'NAME',
        commonLeaderboardEventMsg: 'EVENT',
        commonLeaderboardGradeMsg: 'GRADE',
        commonLeaderboardDateMsg: 'DATE',
        commonLeaderboardSchoolMsg: 'SCHOOL',
        commonLeaderboardLearnerMsg: 'LEARNER',
        commonLeaderboardLearnersMsg: 'LEARNERS',
        commonLeaderboardQuestionsMsg: 'QUESTIONS',
        commonLeaderboardAnswerMsg: 'ANSWER',
        commonLeaderboardAnsweredMsg: 'ANSWERED',
        commonLeaderboardDoneMsg: 'DONE',
        commonLeaderboardRoundScoreMsg: 'ROUND SCORE',
        commonLeaderboardTotalScoreMsg: 'TOTAL SCORE',
        commonLeaderboardAvgScoreMsg: 'AVG SCORE',
        commonLeaderboardFirstNameMsg: 'FIRST NAME',
        commonLeaderboardSurnameMsg: 'SURNAME',
        commonLeaderboardEmailMsg: 'EMAIL',
        commonLeaderboardAnsweredAbrMsg: 'ANS',
        commonLeaderboardTimeMsg: 'TIME',
        commonLeaderboardScoreMsg: 'SCORE',
        commonLeaderboardEnteredMsg: 'ENTERED',
        commonLeaderboardSpreadMsg: 'SPREAD',
        commonLeaderboardCorrectMsg: 'CORRECT',
        commonLeaderboardCorrectInARowMsg: 'CORRECT IN A ROW',
        commonLeaderboardTotalAwardsMsg: 'Answered total awards',
        commonLeaderboardCorrectAwardsMsg: 'Answered correct awards',
        commonLeaderboardStreakAwardsMsg: 'Streak awards',
        commonCopyToClipboardMsg: 'Copy code to clipboard',
        commonCopiedMsg: 'Copied',
        commonWhatIsMyTutorMsg: 'What is MyTutor.chat?',
        headerMenuChatMsg: 'Question Chat (Home)',
        headerMenuLeaderboardMsg: 'Leaderboard',
        headerMenuHomeworkMsg: 'Extension Worksheets',
        headerMenuHomeworkUbuntuMsg: 'Ubuntu Worksheets',
        headerMenuHomeworkPastPaperMsg: 'Past Paper Worksheets',
        headerMenuEventsAndTestsMsg: 'Events & Tests',
        headerMenuCompetitionPreparationMsg: 'Competition Preparation',
        headerMenuPastPapersMsg: 'Past Papers',
        headerMenuKangarooPrefixMsg: 'Kangaroo',
        headerMenuKangarooSuffixMsg: 'Event',
        headerMenuYear2023Msg: 'Year 2 0 2 3 Event',
        headerMenuYear2024Msg: 'Year 2 0 2 4 Event',
        headerMenuUCTMsg: 'UCT Maths Competition',
        headerMenuRugbyWorldCupMsg: 'Rugby World Cup',
        headerMenuLivingMathsMsg: 'Living Maths Competition',
        headerMenuTestsToMarkMsg: 'Tests to mark',
        headerMenuMyProfileMsg: 'My Profile',
        headerMenuMyStudentsMsg: 'My Students',
        headerMenuMyStudentsTestEntriesMsg: 'My Student\'s Test Entries',
        headerMenuMyStudentsWorksheetEntriesMsg: 'My Student\'s Worksheets',
        headerMenuContactUsMsg: 'Contact us',
        chatBusyLoadingMsg: 'Loading your chat history ...',
        chatBusyReplyingMsg: 'MyTutor is replying ...',
        chatInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        eventEntryExternalRegisteringMsg: 'Registering you and opening your test',
        eventEntryExternalProgressRegisteringMsg: 'Registering you for the test',
        eventEntryExternalProgressSuccessMsg: 'Registration successful, authorising',
        eventEntryExternalProgressHousekeepingMsg: '',
        eventEntryExternalProgressErrorMsg: '',
        eventEntryExternalProgressOpeningMsg: '',
        eventEntryExternalProgressLoadingParametersMsg: '',
        eventEntryExternalProgressErrorMissingParametersMsg: '',
        openTestEntryTitleMsg: 'Opening your test',
        openTestEntryProgressLoadingMsg: 'Loading your Test Entry',
        openTestEntryProgressAuthorisingMsg: 'Test Entry found, authorising',
        openTestEntryProgressHouseKeepingMsg: 'Doing some housekeeping',
        openTestEntryProgressOpeningTestMsg: 'Opening test',
        openTestEntryProgressErrorOpeningMsg: 'There was an error opening your test entry',
        openTestEntryProgressErrorPrefixMsg: 'Error: ',
        openTestEntryErrorMsg: 'Please email info@mytutor.chat for assistance.',
        passwordResetTitleMsg: 'Reset your password',
        passwordResetButtonSendCodeMsg: 'Send Code',
        passwordResetCheckCodeMsg: 'Check your email for your code',
        passwordResetLabelVerificationCodeMsg: 'Verification code *',
        passwordResetEnterVerificationCodeMsg: 'Please enter the emailed verification code',
        passwordResetLabelNewPasswordMsg: 'New password *',
        passwordResetEnterNewPasswordMsg: 'Please choose a new password',
        passwordResetErrorUserDoesNotExistMsg: 'User does not exist, or internal error',
        passwordResetErrorMsg: 'There was an error resetting your password:',
        registerTitleMsg: 'Create an account',
        registerSubTitleMsg: 'Access the world\'s largest<br>maths olympiad training platform',
        registerLabelFirstNameMsg: 'First name *',
        registerEnterFirstNameMsg: 'Please enter your first name',
        registerLabelLastNameMsg: 'Last name *',
        registerEnterLastNameMsg: 'Please enter your last name',
        registerUsernameNote1Msg: 'Choose your unique username of at least 6 characters.',
        registerUsernameNote2Msg: 'Use letters, numbers and - _ . @',
        registerUsernameTakenMsg: 'That username has already been taken.',
        registerSignInInsteadPart1Msg: 'Would you like to',
        registerSignInInsteadPart2Msg: 'sign in',
        registerSignInInsteadPart3Msg: 'instead',
        registerEnterUsernameMsg: 'Please choose a unique username of at least 6 characters',
        registerLabelEmailMsg: 'Email',
        registerEmailRecommendedMsg: 'recommended',
        registerNote1EmailMsg: 'Needed for password recovery and to receive test results',
        registerNote2EmailMsg: 'Teachers should enter for scheduling of tests',
        registerEmailInvalidMsg: 'Please enter a valid email address',
        registerEmailRegistrationErrorMsg: 'There was an error with registration:',
        registerAboutYouHeadingMsg: 'Almost there!',
        registerAboutYouSubHeadingMsg: 'Please tell us a bit about yourself<br>so that we can personalise your experience',
        registerAboutYouRegistrationErrorMsg: 'There was an error finishing registration:',
        registerLabelLanguageMsg: 'Language *',
        registerLabelUserTypeMsg: 'I am a *',
        registerOptionUserTypeNotApplicableMsg: 'Not applicable',
        registerOptionUserTypeObserverMsg: 'Observer',
        registerLabelGradeMsg: 'In grade *',
        registerOptionGradeSelectMsg: 'Select a grade',
        registerGradeValidationErrorMsg: 'Please select your grade',
        registerLabelMathsMarkMsg: 'My maths mark last year was *',
        registerMathsMarkValidationErrorMsg: 'Please enter last year\'s maths mark, from 0 to 100',
        registerLabelNicknameMsg: 'My friends call me',
        registerLabelCountryMsg: 'I live in *',
        registerCountryNoteMsg: 'Different events run in different countries at different times',
        registerLabelEmail2Msg: 'Email *',
        registerEmailValidationErrorMsg: 'Please enter a valid email address',
        registerLabelSchoolMsg: 'My school name is *',
        registerSchoolValidationErrorMsg: 'Please enter your school name',
        registerLabelGenderMsg: 'My gender is *',
        registerOptionGenderSelectMsg: 'Select an answer',
        registerGenderValidationErrorMsg: 'Please select your gender',
        registerLabelRaceMsg: 'My demographic is *',
        registerOptionRaceSelectMsg: 'Select an answer',
        registerRaceValidationErrorMsg: 'Please select your demographic',
        registerButtonMsg: 'GET STARTED',
        registerErrorRegisteringOnPlatformMsg: 'Error registering you on the MyTutor platform',
        registerErrorRegisteringCannotConfirmMsg: 'Error registering - cannot confirm account',
        signInPasswordIsResetMsg: 'Your password has been reset',
        signInErrorSigningInMsg: 'Error signing in:',
        signInDontHaveAccountMsg: 'Don\'t have an account?',
        signInRegisterMsg: 'Register',
        signInForgotPasswordMsg: 'Forgot your password?',
        signInVerifyPasswordMsg: 'Verify password *',
        signInEnterPasswordAgainMsg: 'Please enter your password again',
        signInPasswordMismatchMsg: 'Password does not match. Please try again.',
        signInVerifyMsg: 'Verify',
        signInUserDoesNotExistMsg: 'User does not exist',
        testRoundMsg: 'ROUND',
        testFetchingTestMsg: 'Fetching your test...',
        testScheduledStartMsg: 'Your test is scheduled to start on',
        testScheduledStartComeBackMsg: 'Please come back again then, or go to Menu > Home, and do some preparation questions.',
        testStartAnytimePart1Msg: 'You can start the test at any time. Once you start, you have',
        testStartAnytimePart2Msg: 'to finish the test.',
        testInstructionsMsg: 'Instructions',
        testStartInMsg: 'The test will start in',
        testSectionStartInMsg: 'The next section will start in',
        testFinishedMsg: 'The test has finished',
        testLoadingQuestionMsg: 'Loading the next question...',
        testSavingAnswerMsg: 'Saving your answer',
        testNoQuestionsInTestEntryMsg: 'There were no questions in this test entry.',
        testFinishedSubmitMsg: 'I\'ve finished - Submit',
        testRankingsAfterRoundMsg: 'Rankings after Round',
        testThanksForTakingPartMsg: 'Thank you and your learners for taking part, the test has now come to a end. As this is a competition, the results and answers are not released immediately.',
        testFinishedWellDoneMsg: '<b>Well done</b>, you have come to the end of the test! We hope you enjoyed taking part.',
        testTestFinishedRoundAdviceMsg: 'Use the round buttons above to <b>look over your marked answers</b>. While you\'re there, have a look at the <b>solutions for each question</b> - see if your reasoning was correct or perhaps how to get to the solution if you struggled to answer a question.',
        testFinishedLeaderboardMsg: 'We\'ve also included the overall leaderboard below so that you can see how you fared.',
        testOverallLeaderboardMsg: 'Overall Leaderboard',
        testQuestionStatisticsMsg: 'Question statistics',
        testRoundLCMsg: 'Round',
        testRoundPeopleStartedMsg: 'people started',
        testLoadingTestMsg: 'Loading test',
        testStartsMsg: 'Starts:',
        testGoToTestMsg: 'Go to Test',
        testStartRoundMsg: 'Start Round',
        testStartTestMsg: 'Start Test',
        testStartingRoundMsg: 'Starting Round',
        testWillStartInMsg: 'Test will start in',
        testTimeRemainingMsg: 'Time remaining',
        testEndRoundMsg: 'End Round',
        testTestHasFinishedMsg: 'Test has finished',
        testLoadingMsg: 'Loading...',
        testComeBackAfterMsg: 'Come back after',
        testStartingMsg: 'Starting',
        testCompleteMsg: 'complete',
        testTestWillStartOnMsg: 'Test will start on ',
        testTestStartInMsg: 'Test starts in ',
        testRoundStartsInMsg: 'Round starts in ',
        testWaitingForRoundToStartMsg: 'Waiting for next round to start',
        testTypeQuizMsg: 'Quiz',
        testTypeTestMsg: 'Test',
        testTypeAssignmentMsg: 'Assignment',
        testTeacherNotInvigilatorMsg: 'Only the teacher that is the invigilator can open and run this test',
        testConfirmStartTestMsg: 'Are you sure you want to start this section or test?',
        testToggleStatsOffMsg: 'Toggle stats off',
        testToggleStatsOnMsg: 'Toggle stats on',
        unsubscribeTitleMsg: 'Unsubscribe',
        unsubscribeInviteMsg: 'Would you like to unsubscribe from future event notifications?',
        unsubscribeButtonMsg: 'Unsubscribe',
        unsubscribeUnsubscribingMsg: 'Unsubscribing you from all future event notifications',
        unsubscribeUnsubscribedMsg: 'You have been unsubscribed from all future event notifications',
        unsubscribeErrorMsg: 'There was an error un-scribing you, apologies. Please try again later.',
        addSchedulersTitleMainMsg: 'View & approve Schedulers',
        addSchedulersTitleListMsg: 'Schedulers',
        addSchedulersInstructionsPoint1Msg: 'You have been approved to schedule tests for the events listed below',
        addSchedulersInstructionsPoint2Msg: 'You can approve other schedulers for the same events',
        addSchedulersListMsg: 'Below is a list of schedulers you have approved',
        addSchedulersListNonApprovedMsg: 'You have not approved any schedulers yet',
        addSchedulersAddButtonSchedulerMsg: 'SCHEDULER',
        competitionPreparationNoTestsMsg: 'No current competition practice tests',
        listOfTestsNoTestsPrefixMsg: 'No current ',
        listOfTestsNoTestsSuffixMsg: ' tests',
        listOfTestsTalentSearchMsg: 'Talent Search',
        listOfTestsUCTMsg: 'UCT Maths Competition',
        listOfTestsCompetitionPreparationMsg: 'competition Preparation',
        listOfTestsPastPapersMsg: 'Past Papers',
        listOfTestsYear2023Msg: 'Year 2023 Event',
        listOfTestsYear2024Msg: 'Year 2024 Event',
        listOfTestsLivingMaths2023Msg: 'Living Maths Competition 2023',
        kangaroo2023TitleMsg: 'Kangaroo International Mathematics Competition 2023',
        kangaroo2023ParticipationOptionsMsg: 'Learners can participate either online or offline.',
        kangaroo2023RecommendationsHeadingMsg: 'Recommendations',
        kangaroo2023RecommendationPoint1PrefixMsg: 'Learners participate',
        kangaroo2023RecommendationPoint1Msg: 'Learners participate <strong>online</strong> if they have access to a device and data (by creating a free account learners can register now, and self-start in the test window from the Kangaroo 2023 menu item)',
        kangaroo2023RecommendationPoint2Msg: 'Learners participate <strong>offline</strong> if they are prevented from participating by load shedding, a lack of a device or have limited internet access (see below)',
        kangaroo2023OfflineHeadingMsg: 'Offline participation',
        kangaroo2023OfflineDownloadMsg: 'Teachers can download papers, solutions and answer sheets:',
        kangaroo2023FillInFormPrefixMsg: 'At the end of the event, please',
        kangaroo2023FillInFormCTAMsg: 'fill in this form',
        kangaroo2023FillInFormSuffixMsg: 'to tell us how many students participated (takes 1 minute)',
        kangaroo2023InternationalTitlePrefixMsg: 'Kangaroo',
        kangaroo2023InternationalTitleSuffixMsg: 'Mathematics Competition 2023',
        uctCompetitionTitleMsg: 'UCT Mathematics Competition',
        uctCompetitionPara1Msg: 'The UCT Mathematics Competition was established in 1977, making it one of the oldest mathematics competitions in the world. It is an annual event with 7500 learners from 400 high schools in the Western Cape, a province in South Africa, participating on the University of Cape Town Upper Campus.',
        uctCompetitionPara2Msg: 'Each school is able to enter 5 individuals and 5 pairs per grade (for a maximum of 15 learners). The event offers prizes and trophies for those that participate on the campus.',
        uctCompetitionPara3Msg: 'We have made the papers available here so that teachers can make the question papers available to more learners:<br/> - to those that did not represent their school<br/> - to those for whom the event is too far to travel, say for those that are from outside the Western Cape, whether they be in South Africa or further afield.',
        uctCompetitionPara4Msg: '<strong>Note:</strong> Only those participating on 25 July in the invigilated environment on upper campus will be eligible for the prizes, and the Mona Leeuwenburg Trophy in the inter-school competition.',
        uctCompetitionPara5Msg: 'In 2023, teachers can help learners to write by downloading the zip file for each of the Grade 8-Grade 12 papers. In 2024, MyTutor.chat will enable the learners that did not participate in the event on Upper Campus to participate digitally after that event.',
        uctCompetitionPara6Msg: 'Teachers can download papers, solutions and answer sheets here:',
        leaderboardByEntrantMsg: 'By Entrant',
        leaderboardBySchoolMsg: 'By School',
        leaderboardNoResultsMsg: 'There are no results for this test',
        myStudentsTitleMsg: 'My Students',
        myStudentsLast7DaysMsg: 'LAST 7 DAYS',
        myStudentsLastTodayMsg: 'TODAY',
        myStudentsNoStudentsMsg: 'No students',
        myStudentsUnlinkMsg: 'UNLINK',
        myStudentsUnlinkedPrefixMsg: 'Successfully unlinked',
        myStudentsUnlinkedSuffixMsg: 'learners',
        myStudentsUnlinkingErrorMsg: 'Error un-linking students',
        myStudentsTestEntriesTitleMsg: 'My Students Test Entries',
        myStudentsTestEntriesNoEntriesMsg: 'No test entries',
        myStudentsWorksheetEntriesTitleMsg: 'My Students Worksheet Entries',
        myStudentsWorksheetEntriesNoEntriesMsg: 'No worksheet entries',
        quizQuestionHeadingMsg: 'QUESTION',
        quizQuestionViewFormulaSheetMsg: 'View Formula Sheet',
        quizQuestionAnswerMsg: 'ANSWER',
        quizQuestionCorrectMsg: 'Correct',
        quizQuestionIncorrectMsg: 'Incorrect',
        quizQuestionInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        quizQuestionDragPhotoMsg: 'Drag your photo here to upload<br> or click to browse',
        quizQuestionUploadPhotoMsg: 'Uploading your photo...',
        quizQuestionUploadFailedMsg: 'Upload failed',
        quizQuestionDeletingPhotoMsg: 'Deleting your photo...',
        quizQuestionPhotoDeletedMsg: 'Photo deleted',
        quizQuestionDeleteFailedMsg: 'Delete failed',
        quizQuestionNoPhotoUploadedMsg: 'No photo uploaded',
        quizQuestionMarkingFeedbackMsg: 'MARKING FEEDBACK:',
        quizQuestionTotalAbbreviationMsg: 'Tot:',
        quizQuestionPlaceholderUnansweredMsg: 'unanswered',
        quizQuestionSavingMsg: 'saving...',
        quizQuestionSavedMsg: '...saved',
        createTestHeadingMsg: 'Create Test',
        createTestHeadingDesktopSuffixMsg: 'from Question Set',
        createTestCreatingMsg: 'Creating test ...',
        createTestOpenToScheduleMsg: 'open test to schedule',
        createTestOpenToScheduleAltMsg: 'Open test',
        createTestQuestionSetMsg: 'QUESTION SET',
        createTestWhenCanScheduleMsg: 'WHEN CAN YOU SCHEDULE THIS TEST?',
        createTestDurationMsg: 'DURATION',
        createTestNameMsg: 'TEST NAME',
        createTestAddTestButtonMsg: 'ADD TEST',
        createTestFormatOlympiadMsg: 'Olympiad',
        createTestFormatPubQuizMsg: 'Pub quiz',
        createTestFormatWorksheetMsg: 'Worksheet',
        createTestOutsideWindowMsg: 'The test cannot be scheduled outside the participation window. Please try again',
        createTestErrorMsg: 'Error creating test',
        testPracticeFetchingMsg: 'Fetching practice questions ...',
        testPracticeHeadingMsg: 'Practice questions',
        testPracticeInviteMsg: 'Before starting the test, we invite you to try the 3 practice questions below to familiarise yourself with the test format. Once you\'ve answered the last practice question, the questions will be marked and will reload showing whether your answered them correctly or not, and you will be able to view the solutions',
        testPracticeStartButtonMsg: 'Start practice',
        testPracticeSavingYourAnswerMsg: 'Saving your answer...',
        testPracticeInviteWhileWaitingMsg: 'While you wait for the test to start, we suggest that you answer the below practice questions to familiarise yourself with the test format. Once you\'re finished, press "Submit practice questions" to mark them and see how you\'ve done.',
        testPracticeSubmitButtonMsg: 'Submit practice questions',
        eventsTitleMsg: 'Events & Tests',
        eventsLoadingMsg: 'Loading tests',
        eventsOpenTestMsg: 'open a test you scheduled',
        viewTestDetailsHeadingMsg: 'Test Details',
        viewTestDetailsCloseMsg: 'Close test details',
        viewTestDetailsEditDateMsg: 'Edit date',
        viewTestDetailsTestCodeMsg: 'TEST CODE',
        viewTestDetailsTestRegistrationURLMsg: 'TEST REGISTRATION URL',
        viewTestDetailsAvailableMsg: 'Available',
        viewTestDetailsFromMsg: 'from',
        viewTestDetailsTestEntrantsMsg: 'Test Entrants',
        profileMyMsg: 'My',
        profileImagePlaceholderMsg: 'Placeholder profile image',
        profileInformationMsg: 'Information',
        profileStatisticsMsg: 'Statistics',
        profileLinkToTeacherAndFriendsMsg: 'LINK LEARNERS TO TRACK THEIR PROGRESS - ASK THEM TO VISIT THIS URL',
        profileLinkCodeMsg: 'YOUR LINK CODE',
        profileEditEmailMsg: 'Edit email',
        profileNotificationsMsg: 'NOTIFICATIONS',
        profileEmailMeMsg: 'Email me about future events & competitions',
        profileNicknameMsg: 'NICKNAME',
        profileEditNicknameMsg: 'Edit nickname',
        profileEditFirstNameMsg: 'Edit first name',
        profileEditSurnameMsg: 'Edit surname',
        profileEditSchoolMsg: 'Edit School',
        profileAccountTypeMsg: 'TYPE',
        profileLanguageMsg: 'LANGUAGE',
        profileGenderMsg: 'GENDER',
        profileCountryMsg: 'COUNTRY',
        profileMyStatisticsMsg: 'My Statistics',
        profileQuestionsAnsweredMsg: 'Questions answered',
        profileQuestionsAnsweredCorrectlyMsg: 'Questions answered correctly',
        profileStreakCorrectMsg: 'Longest streak of correct answers',
        profileDaysActiveMsg: 'Days Active',
        profilePointsAccumulatedMsg: 'Points accumulated',
        profileMyBadgesMsg: 'My Badges',
        profileTotalQuestionsMsg: 'TOTAL QUESTIONS',
        profileNotEnteredYetMsg: 'not entered yet',
        page404Msg: 'Page not found',
        aboutTitleMsg: 'About',
        aboutIntro1Msg: '<b>MyTutor.chat</b> is a South African built free-to-access digital teaching tool that assists with mathematics education by offering learners appropriately graded questions to address their learning objectives. By offering personal journeys - tailored to each learners age, grade and capability - we reach the users where they are: in their homes, in their <b>zone of proximal development</b> where learning is most productive.',
        aboutIntro2Msg: 'Our initial focus is on high school students, and those dealing with <b>mathematics competition questions</b> which have an emphasis on problem solving techniques. We intend extending our offering to cover components of the CAPS curriculum over the course of 2021 and beyond.',
        aboutFuturePlansHeadingMsg: 'Future plans',
        aboutFuturePlansPart1Msg: 'In addition to constantly extending the question and answer bank, we are planning on adding worksheets, online testing functionality and offering our service in additional languages.',
        aboutFuturePlansPart2Msg: 'We hope to also offer functionality for teachers and parents as well as learners.',
        aboutFounderHeadingMsg: 'Founder',
        aboutFounderPart1Msg: 'MyTutor.chat was founded by Greg Becker in 2020. Greg is a retired actuary and mathematics teacher who saw the need for improving access to quality mathematics education across South Africa.',
        aboutStateOfSAMathsHeadingMsg: 'The State of South African Mathematics Education',
        aboutStateOfSAMathsPart1Msg: 'Very few South Africans are obtaining marks for matric mathematics that prepares them for the rigors of a university education in a STEM field. The current goal for the Minister of Basic Education is to improve results so that 35,000 obtain at least 60% for mathematics by 2024 - a rise from 28,000 in 2019. While this is a movement in the right direction, this is a very low number when compared to other countries - those that will be competing with us in the globalised world of tomorrow.',
        aboutStateOfSAMathsPart2Msg: 'The international benchmarking assessment conducted by TIMSS in 2019 shows that while less than 1% of South African students scored more than 625 on their competency scale, 51% of those in Singapore reached obtained this mark. At the other end of the scale, 98% of learners in Singapore obtained at least 400 - while only 41% of learners assessed in South Africa did. We were joint last with Morocco, behind countries like Chile, Egypt, Malaysia, Oman, Romania, and Turkey.',
        aboutStateOfSAMathsPart3Msg: 'For those who are interested, international studies produced by TIMSS are worth looking into, as are those by SACEMA and the annual matric results released by the Department of Education. The international benchmarking studies allow for an interpretation of our domestic matric results.',
        contactUsTitleMsg: 'Contact',
        contactUsPart1Msg: '<strong>Sharks Quiz:</strong> If you are from Ghana and having problems signing up for the Sharks Quiz, please make sure that you have selected Ghana as your country (the test is not available to those who have not completed the initial registration questions and listed their country as Ghana. (You can change this by going to MyProfile on the menu.)',
        contactUsPart2Msg: 'You can reach us by email on',
        contactUsPart3Msg: 'Please include a screenshot of your MyProfile so that we can follow up your situation.',
        logicDayCTAMsg: 'Enter the Logic Day Challenge',
        talentSearchCTAMsg: 'Enter the Talent Search',
        homeworkTitleMsg: 'Homework',
    },
    'pt': {
        homeRegisterMsg: 'Registar',
        homeSignInMsg: 'Iniciar sessão',
        homeHeroMsg: 'Diverte-te a melhorar as tuas capacidades de matemática e de resolução de problemas',
        homeTaglineMsg: 'Melhor matemática para um futuro melhor.',
        homeCTAWatchVideoMsg: 'Ver o vídeo',
        homeHowItWorksMsg: 'Como funciona',
        homeHowToRegisterMsg: 'Inscreve-te e regista-te',
        homeHowToRegisterPoint1Msg: 'Demora alguns minutos e só requer o  acesso ao teu e-mail.',
        homeHowToRegisterPoint2Msg: 'MyTutor.chat funciona como qualquer outro serviço de mensagens instantâneas, basta responder-nos.',
        homeHowToRegisterPoint3Msg: 'O serviço é gratuito!',
        homeHowToPracticeMsg: 'Prática Prática Prática',
        homeHowToPracticePoint1Msg: 'Agora estás na parte emocionante onde nós te daremos perguntas. Usa a biblioteca de perguntas e testes para te preparares para os eventos.',
        homeHowToPracticePoint2Msg: 'O algoritmo do MyTutor.chat vai apresentar-te perguntas que reflectem o teu nível e capacidade.',
        homeHowToParticipateMsg: 'Participa nos eventos',
        homeHowToParticipatePoint1Msg: 'Eventos nacionais e internacionais serão organizados aqui.',
        homeHowToParticipatePoint2Msg: 'Organize eventos locais ou  escolares.',
        homeHowToCheckStatsMsg: 'VERIFICA AS TUAS ESTATÍSTICAS E MUITO MAIS',
        homeHowToCheckStatsPoint1Msg: 'Os alunos podem aceder às tuas estatísticas, ganhar prémios e ganhar pontos na tabela de classificação.',
        homeHowToCheckStatsPoint2Msg: 'Funcionalidade adicional para os professores e pais monitorizarem os progressos.',
        homeGiveItAGoMsg: 'Experimenta',
        homeGiveItAGoPoint1Msg: 'Volta aqui diariamente para experimentar outro.',
        homeGiveItAGoPoint2Msg: 'Regista-te para ter acesso a centenas de problemas que serão seleccionados de acordo com o teu nível.',
        homeGiveItAGoTryMoreMsg: 'Queres experimentar mais?',
        homeGiveItAGoJoinForFreeMsg: 'Inscreve-te gratuitamente',
        homeWhyJoinMsg: 'Porquê juntar-te a MyTutor.chat?',
        homeWhyJoinQuestionsMsg: 'ACESSAR À PERGUNTAS',
        homeWhyJoinQuestionsPoint1Msg: 'Acesso à nossa biblioteca de perguntas com > 2000 perguntas',
        homeWhyJoinTestsMsg: 'Acessar à testes',
        homeWhyJoinTestsPoint1Msg: 'Acesso à 50 testes de 30 minutos da nossa biblioteca de testes',
        homeWhyJoinEventsMsg: 'Participar nos eventos',
        homeWhyJoinEventsPoint1Msg: 'Participação online em eventos nacionais e internacionais',
        homeWhyJoinSAMFMsg: 'Pesquisar de talentos SAMF',
        homeWhyJoinSAMFPoint1Msg: 'Participe no SAMF Talent Search para os graus 7-12',
        homeWhyJoinProgressMsg: 'Acompanhe  o seu progresso',
        homeWhyJoinProgressPoint1Msg: 'Ganhe prémios e pontos para figurar na nossa tabela de classificação',
        homeWhyJoinCompetitionsMsg: 'Participar em concursos',
        homeWhyJoinCompetitionsPoint1Msg: 'Participa em concursos onde pode ganhar prémios',
        homeEventsMsg: 'Atual & Próximos Eventos',
        homeEventsMyTutorHostedMsg: 'MyTutor hospedado',
        homeEventsEnterMsg: 'Detalhes',
        homeEventsLivingMathsMsg: 'Viver a Matemática',
        homeEventsLivingMathsDateMsg: 'outubro-dezembro 2023',
        homeEventsRWCGeographyMsg: 'RWC Geografia',
        homeEventsRWCGeographyDateMsg: 'Out 2023',
        homeEventsRWCHistoryMsg: 'RWC História',
        homeEventsRWCHistoryDateMsg: 'Out 2023',
        homeEventsYear2023Msg: 'ANO 2023',
        homeEventsYear2023DateMsg: 'Termina a 31 de dezembro de 2023',
        homeEventsRWCMsg: 'Matemática do Campeonato do Mundo de Rugby de 2023',
        homeEventsRWCDateMsg: '8 de setembro - 28 de outubro de 2023',
        homeQuickLinksMsg: 'Ligações rápidas',
        homeQuickLinksAboutMsg: 'Acerca de',
        homeQuickLinksContactMsg: 'Contacta-nos',
        homeQuickLinksPrivacyMsg: 'Política de privacidade',
        homeQuickLinksTermsMsg: 'Termos e Condições',
        commonLanguageEnglishMsg: 'English',
        commonLanguageFrenchMsg: 'French',
        commonLanguagePortugueseMsg: 'Portuguese',
        commonUserTypeLearnerMsg: 'Learner',
        commonUserTypeTeacherMsg: 'Teacher',
        commonUserTypeParentMsg: 'Parent',
        commonUserTypeObserverMsg: 'Observer',
        commonPasswordMsg: 'Password *',
        commonShowPasswordMsg: 'Show password',
        commonEnterPasswordMsg: 'Please enter a password',
        commonAnonymousMsg: 'Prefer not to say',
        commonGenderMaleMsg: 'Male',
        commonGenderFemaleMsg: 'Female',
        commonGenderOtherMsg: 'Other',
        commonRaceBlackMsg: 'Black',
        commonRaceColouredMsg: 'Coloured',
        commonRaceIndianMsg: 'Indian',
        commonRaceWhiteMsg: 'White',
        commonDoneMsg: 'Done',
        commonCloseMsg: 'Close',
        commonCancelMsg: 'Cancel',
        commonStartMsg: 'Start',
        commonSuccessMsg: 'Success',
        commonErrorMsg: 'Error',
        commonButtonAddMsg: 'ADD',
        commonButtonCancelMsg: 'CANCEL',
        commonButtonSaveMsg: 'SAVE',
        commonAlreadyHaveAccountMsg: 'Already have an account?',
        commonSignInMsg: 'Sign in',
        commonSignUpMsg: 'Sign up',
        commonUnknownErrorMsg: 'Unknown error',
        commonLabelUsernameMsg: 'Username *',
        commonEnterUsernameMsg: 'Please enter your username',
        commonResetPasswordMsg: 'Reset Password',
        commonSkipMsg: 'Skip',
        commonSkipLCMsg: 'skip',
        commonSkipsLCMsg: 'skips',
        commonSkipsLeftMsg: 'left',
        commonTeachersHeadingMsg: 'TEACHERS',
        commonTestsTitleLearnersMsg: 'LEARNERS',
        commonTestsRegisterMsg: 'register for a test',
        commonTestsRegisterAltMsg: 'Register for test',
        commonTestsOpenTestEntryMsg: 'open your test entry',
        commonTestsOpenCompletedTestEntryMsg: 'view completed entry',
        commonTestsOpenTestEntryAltMsg: 'Open test',
        commonTestsTitleUpcomingMsg: 'Upcoming',
        commonTestsTitleCurrentMsg: 'Current',
        commonTestsTitlePastMsg: 'Past',
        commonTestsTypeSelfStartMsg: 'SELF-START',
        commonTestsTypeInvigilatedMsg: 'INVIGILATED',
        commonTestsGradeMsg: 'Grade',
        commonTestsLearnersRegisteredMsg: 'learners',
        commonTestsStartWhenReadyMsg: 'Start when ready',
        commonTestsTermAndConditionsMsg: 'Terms & Conditions',
        commonTestsInviteLearnersMsg: 'Invite learners to participate',
        commonTestsScoreMsg: 'Score:',
        commonDownloadZIPMsg: 'Download ZIP',
        commonLeaderboardTopMsg: 'TOP',
        commonLeaderboardTitleMsg: 'Leaderboard',
        commonLeaderboardAllMsg: 'All',
        commonLeaderboardAllLearnersMsg: 'All learners',
        commonLeaderboardSlideLeftMsg: 'Slide Leaderboard grade selector left',
        commonLeaderboardSlideRightMsg: 'Slide Leaderboard grade selector right',
        commonLeaderboardGradeAbbreviationMsg: 'G',
        commonLeaderboardNameMsg: 'NAME',
        commonLeaderboardEventMsg: 'EVENT',
        commonLeaderboardGradeMsg: 'GRADE',
        commonLeaderboardDateMsg: 'DATE',
        commonLeaderboardSchoolMsg: 'SCHOOL',
        commonLeaderboardLearnerMsg: 'LEARNER',
        commonLeaderboardLearnersMsg: 'LEARNERS',
        commonLeaderboardQuestionsMsg: 'QUESTIONS',
        commonLeaderboardAnswerMsg: 'ANSWER',
        commonLeaderboardAnsweredMsg: 'ANSWERED',
        commonLeaderboardDoneMsg: 'DONE',
        commonLeaderboardRoundScoreMsg: 'ROUND SCORE',
        commonLeaderboardTotalScoreMsg: 'TOTAL SCORE',
        commonLeaderboardAvgScoreMsg: 'AVG SCORE',
        commonLeaderboardFirstNameMsg: 'FIRST NAME',
        commonLeaderboardSurnameMsg: 'SURNAME',
        commonLeaderboardEmailMsg: 'EMAIL',
        commonLeaderboardAnsweredAbrMsg: 'ANS',
        commonLeaderboardTimeMsg: 'TIME',
        commonLeaderboardScoreMsg: 'SCORE',
        commonLeaderboardEnteredMsg: 'ENTERED',
        commonLeaderboardSpreadMsg: 'SPREAD',
        commonLeaderboardCorrectMsg: 'CORRECT',
        commonLeaderboardCorrectInARowMsg: 'CORRECT IN A ROW',
        commonLeaderboardTotalAwardsMsg: 'Answered total awards',
        commonLeaderboardCorrectAwardsMsg: 'Answered correct awards',
        commonLeaderboardStreakAwardsMsg: 'Streak awards',
        commonCopyToClipboardMsg: 'Copy code to clipboard',
        commonCopiedMsg: 'Copied',
        commonWhatIsMyTutorMsg: 'What is MyTutor.chat?',
        headerMenuChatMsg: 'Question Chat (Home)',
        headerMenuLeaderboardMsg: 'Leaderboard',
        headerMenuHomeworkMsg: 'Extension Worksheets',
        headerMenuHomeworkUbuntuMsg: 'Ubuntu Worksheets',
        headerMenuHomeworkPastPaperMsg: 'Past Paper Worksheets',
        headerMenuEventsAndTestsMsg: 'Events & Tests',
        headerMenuCompetitionPreparationMsg: 'Competition Preparation',
        headerMenuPastPapersMsg: 'Past Papers',
        headerMenuKangarooPrefixMsg: 'Kangaroo',
        headerMenuKangarooSuffixMsg: 'Event',
        headerMenuYear2023Msg: 'Year 2 0 2 3 Event',
        headerMenuYear2024Msg: 'Year 2 0 2 4 Event',
        headerMenuUCTMsg: 'UCT Maths Competition',
        headerMenuRugbyWorldCupMsg: 'Rugby World Cup',
        headerMenuLivingMathsMsg: 'Living Maths Competition',
        headerMenuTestsToMarkMsg: 'Tests to mark',
        headerMenuMyProfileMsg: 'My Profile',
        headerMenuMyStudentsMsg: 'My Students',
        headerMenuMyStudentsTestEntriesMsg: 'My Student\'s Test Entries',
        headerMenuMyStudentsWorksheetEntriesMsg: 'My Student\'s Worksheets',
        headerMenuContactUsMsg: 'Contact us',
        chatBusyLoadingMsg: 'Loading your chat history ...',
        chatBusyReplyingMsg: 'MyTutor is replying ...',
        chatInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        eventEntryExternalRegisteringMsg: 'Registering you and opening your test',
        eventEntryExternalProgressRegisteringMsg: 'Registering you for the test',
        eventEntryExternalProgressSuccessMsg: 'Registration successful, authorising',
        eventEntryExternalProgressHousekeepingMsg: '',
        eventEntryExternalProgressErrorMsg: '',
        eventEntryExternalProgressOpeningMsg: '',
        eventEntryExternalProgressLoadingParametersMsg: '',
        eventEntryExternalProgressErrorMissingParametersMsg: '',
        openTestEntryTitleMsg: 'Opening your test',
        openTestEntryProgressLoadingMsg: 'Loading your Test Entry',
        openTestEntryProgressAuthorisingMsg: 'Test Entry found, authorising',
        openTestEntryProgressHouseKeepingMsg: 'Doing some housekeeping',
        openTestEntryProgressOpeningTestMsg: 'Opening test',
        openTestEntryProgressErrorOpeningMsg: 'There was an error opening your test entry',
        openTestEntryProgressErrorPrefixMsg: 'Error: ',
        openTestEntryErrorMsg: 'Please email info@mytutor.chat for assistance.',
        passwordResetTitleMsg: 'Reset your password',
        passwordResetButtonSendCodeMsg: 'Send Code',
        passwordResetCheckCodeMsg: 'Check your email for your code',
        passwordResetLabelVerificationCodeMsg: 'Verification code *',
        passwordResetEnterVerificationCodeMsg: 'Please enter the emailed verification code',
        passwordResetLabelNewPasswordMsg: 'New password *',
        passwordResetEnterNewPasswordMsg: 'Please choose a new password',
        passwordResetErrorUserDoesNotExistMsg: 'User does not exist, or internal error',
        passwordResetErrorMsg: 'There was an error resetting your password:',
        registerTitleMsg: 'Create an account',
        registerSubTitleMsg: 'Access the world\'s largest<br>maths olympiad training platform',
        registerLabelFirstNameMsg: 'First name *',
        registerEnterFirstNameMsg: 'Please enter your first name',
        registerLabelLastNameMsg: 'Last name *',
        registerEnterLastNameMsg: 'Please enter your last name',
        registerUsernameNote1Msg: 'Choose your unique username of at least 6 characters.',
        registerUsernameNote2Msg: 'Use letters, numbers and - _ . @',
        registerUsernameTakenMsg: 'That username has already been taken.',
        registerSignInInsteadPart1Msg: 'Would you like to',
        registerSignInInsteadPart2Msg: 'sign in',
        registerSignInInsteadPart3Msg: 'instead',
        registerEnterUsernameMsg: 'Please choose a unique username of at least 6 characters',
        registerLabelEmailMsg: 'Email',
        registerEmailRecommendedMsg: 'recommended',
        registerNote1EmailMsg: 'Needed for password recovery and to receive test results',
        registerNote2EmailMsg: 'Teachers should enter for scheduling of tests',
        registerEmailInvalidMsg: 'Please enter a valid email address',
        registerEmailRegistrationErrorMsg: 'There was an error with registration:',
        registerAboutYouHeadingMsg: 'Almost there!',
        registerAboutYouSubHeadingMsg: 'Please tell us a bit about yourself<br>so that we can personalise your experience',
        registerAboutYouRegistrationErrorMsg: 'There was an error finishing registration:',
        registerLabelLanguageMsg: 'Language *',
        registerLabelUserTypeMsg: 'I am a *',
        registerOptionUserTypeNotApplicableMsg: 'Not applicable',
        registerOptionUserTypeObserverMsg: 'Observer',
        registerLabelGradeMsg: 'In grade *',
        registerOptionGradeSelectMsg: 'Select a grade',
        registerGradeValidationErrorMsg: 'Please select your grade',
        registerLabelMathsMarkMsg: 'My maths mark last year was *',
        registerMathsMarkValidationErrorMsg: 'Please enter last year\'s maths mark, from 0 to 100',
        registerLabelNicknameMsg: 'My friends call me',
        registerLabelCountryMsg: 'I live in *',
        registerCountryNoteMsg: 'Different events run in different countries at different times',
        registerLabelEmail2Msg: 'Email *',
        registerEmailValidationErrorMsg: 'Please enter a valid email address',
        registerLabelSchoolMsg: 'My school name is *',
        registerSchoolValidationErrorMsg: 'Please enter your school name',
        registerLabelGenderMsg: 'My gender is *',
        registerOptionGenderSelectMsg: 'Select an answer',
        registerGenderValidationErrorMsg: 'Please select your gender',
        registerLabelRaceMsg: 'My demographic is *',
        registerOptionRaceSelectMsg: 'Select an answer',
        registerRaceValidationErrorMsg: 'Please select your demographic',
        registerButtonMsg: 'GET STARTED',
        registerErrorRegisteringOnPlatformMsg: 'Error registering you on the MyTutor platform',
        registerErrorRegisteringCannotConfirmMsg: 'Error registering - cannot confirm account',
        signInPasswordIsResetMsg: 'Your password has been reset',
        signInErrorSigningInMsg: 'Error signing in:',
        signInDontHaveAccountMsg: 'Don\'t have an account?',
        signInRegisterMsg: 'Register',
        signInForgotPasswordMsg: 'Forgot your password?',
        signInVerifyPasswordMsg: 'Verify password *',
        signInEnterPasswordAgainMsg: 'Please enter your password again',
        signInPasswordMismatchMsg: 'Password does not match. Please try again.',
        signInVerifyMsg: 'Verify',
        signInUserDoesNotExistMsg: 'User does not exist',
        testRoundMsg: 'ROUND',
        testFetchingTestMsg: 'Fetching your test...',
        testScheduledStartMsg: 'Your test is scheduled to start on',
        testScheduledStartComeBackMsg: 'Please come back again then, or go to Menu > Home, and do some preparation questions.',
        testStartAnytimePart1Msg: 'You can start the test at any time. Once you start, you have',
        testStartAnytimePart2Msg: 'to finish the test.',
        testInstructionsMsg: 'Instructions',
        testStartInMsg: 'The test will start in',
        testSectionStartInMsg: 'The next section will start in',
        testFinishedMsg: 'The test has finished',
        testLoadingQuestionMsg: 'Loading the next question...',
        testSavingAnswerMsg: 'Saving your answer',
        testNoQuestionsInTestEntryMsg: 'There were no questions in this test entry.',
        testFinishedSubmitMsg: 'I\'ve finished - Submit',
        testRankingsAfterRoundMsg: 'Rankings after Round',
        testThanksForTakingPartMsg: 'Thank you and your learners for taking part, the test has now come to a end. As this is a competition, the results and answers are not released immediately.',
        testFinishedWellDoneMsg: '<b>Well done</b>, you have come to the end of the test! We hope you enjoyed taking part.',
        testTestFinishedRoundAdviceMsg: 'Use the round buttons above to <b>look over your marked answers</b>. While you\'re there, have a look at the <b>solutions for each question</b> - see if your reasoning was correct or perhaps how to get to the solution if you struggled to answer a question.',
        testFinishedLeaderboardMsg: 'We\'ve also included the overall leaderboard below so that you can see how you fared.',
        testOverallLeaderboardMsg: 'Overall Leaderboard',
        testQuestionStatisticsMsg: 'Question statistics',
        testRoundLCMsg: 'Round',
        testRoundPeopleStartedMsg: 'people started',
        testLoadingTestMsg: 'Loading test',
        testStartsMsg: 'Starts:',
        testGoToTestMsg: 'Go to Test',
        testStartRoundMsg: 'Start Round',
        testStartTestMsg: 'Start Test',
        testStartingRoundMsg: 'Starting Round',
        testWillStartInMsg: 'Test will start in',
        testTimeRemainingMsg: 'Time remaining',
        testEndRoundMsg: 'End Round',
        testTestHasFinishedMsg: 'Test has finished',
        testLoadingMsg: 'Loading...',
        testComeBackAfterMsg: 'Come back after',
        testStartingMsg: 'Starting',
        testCompleteMsg: 'complete',
        testTestWillStartOnMsg: 'Test will start on ',
        testTestStartInMsg: 'Test starts in ',
        testRoundStartsInMsg: 'Round starts in ',
        testWaitingForRoundToStartMsg: 'Waiting for next round to start',
        testTypeQuizMsg: 'Quiz',
        testTypeTestMsg: 'Test',
        testTypeAssignmentMsg: 'Assignment',
        testTeacherNotInvigilatorMsg: 'Only the teacher that is the invigilator can open and run this test',
        testConfirmStartTestMsg: 'Are you sure you want to start this section or test?',
        testToggleStatsOffMsg: 'Toggle stats off',
        testToggleStatsOnMsg: 'Toggle stats on',
        unsubscribeTitleMsg: 'Unsubscribe',
        unsubscribeInviteMsg: 'Would you like to unsubscribe from future event notifications?',
        unsubscribeButtonMsg: 'Unsubscribe',
        unsubscribeUnsubscribingMsg: 'Unsubscribing you from all future event notifications',
        unsubscribeUnsubscribedMsg: 'You have been unsubscribed from all future event notifications',
        unsubscribeErrorMsg: 'There was an error un-scribing you, apologies. Please try again later.',
        addSchedulersTitleMainMsg: 'View & approve Schedulers',
        addSchedulersTitleListMsg: 'Schedulers',
        addSchedulersInstructionsPoint1Msg: 'You have been approved to schedule tests for the events listed below',
        addSchedulersInstructionsPoint2Msg: 'You can approve other schedulers for the same events',
        addSchedulersListMsg: 'Below is a list of schedulers you have approved',
        addSchedulersListNonApprovedMsg: 'You have not approved any schedulers yet',
        addSchedulersAddButtonSchedulerMsg: 'SCHEDULER',
        competitionPreparationNoTestsMsg: 'No current competition practice tests',
        listOfTestsNoTestsPrefixMsg: 'No current ',
        listOfTestsNoTestsSuffixMsg: ' tests',
        listOfTestsTalentSearchMsg: 'Talent Search',
        listOfTestsUCTMsg: 'UCT Maths Competition',
        listOfTestsCompetitionPreparationMsg: 'competition Preparation',
        listOfTestsPastPapersMsg: 'Past Papers',
        listOfTestsYear2023Msg: 'Year 2023 Event',
        listOfTestsYear2024Msg: 'Year 2024 Event',
        listOfTestsLivingMaths2023Msg: 'Living Maths Competition 2023',
        kangaroo2023TitleMsg: 'Kangaroo International Mathematics Competition 2023',
        kangaroo2023ParticipationOptionsMsg: 'Learners can participate either online or offline.',
        kangaroo2023RecommendationsHeadingMsg: 'Recommendations',
        kangaroo2023RecommendationPoint1PrefixMsg: 'Learners participate',
        kangaroo2023RecommendationPoint1Msg: 'Learners participate <strong>online</strong> if they have access to a device and data (by creating a free account learners can register now, and self-start in the test window from the Kangaroo 2023 menu item)',
        kangaroo2023RecommendationPoint2Msg: 'Learners participate <strong>offline</strong> if they are prevented from participating by load shedding, a lack of a device or have limited internet access (see below)',
        kangaroo2023OfflineHeadingMsg: 'Offline participation',
        kangaroo2023OfflineDownloadMsg: 'Teachers can download papers, solutions and answer sheets:',
        kangaroo2023FillInFormPrefixMsg: 'At the end of the event, please',
        kangaroo2023FillInFormCTAMsg: 'fill in this form',
        kangaroo2023FillInFormSuffixMsg: 'to tell us how many students participated (takes 1 minute)',
        kangaroo2023InternationalTitlePrefixMsg: 'Kangaroo',
        kangaroo2023InternationalTitleSuffixMsg: 'Mathematics Competition 2023',
        uctCompetitionTitleMsg: 'UCT Mathematics Competition',
        uctCompetitionPara1Msg: 'The UCT Mathematics Competition was established in 1977, making it one of the oldest mathematics competitions in the world. It is an annual event with 7500 learners from 400 high schools in the Western Cape, a province in South Africa, participating on the University of Cape Town Upper Campus.',
        uctCompetitionPara2Msg: 'Each school is able to enter 5 individuals and 5 pairs per grade (for a maximum of 15 learners). The event offers prizes and trophies for those that participate on the campus.',
        uctCompetitionPara3Msg: 'We have made the papers available here so that teachers can make the question papers available to more learners:<br/> - to those that did not represent their school<br/> - to those for whom the event is too far to travel, say for those that are from outside the Western Cape, whether they be in South Africa or further afield.',
        uctCompetitionPara4Msg: '<strong>Note:</strong> Only those participating on 25 July in the invigilated environment on upper campus will be eligible for the prizes, and the Mona Leeuwenburg Trophy in the inter-school competition.',
        uctCompetitionPara5Msg: 'In 2023, teachers can help learners to write by downloading the zip file for each of the Grade 8-Grade 12 papers. In 2024, MyTutor.chat will enable the learners that did not participate in the event on Upper Campus to participate digitally after that event.',
        uctCompetitionPara6Msg: 'Teachers can download papers, solutions and answer sheets here:',
        leaderboardByEntrantMsg: 'By Entrant',
        leaderboardBySchoolMsg: 'By School',
        leaderboardNoResultsMsg: 'There are no results for this test',
        myStudentsTitleMsg: 'My Students',
        myStudentsLast7DaysMsg: 'LAST 7 DAYS',
        myStudentsLastTodayMsg: 'TODAY',
        myStudentsNoStudentsMsg: 'No students',
        myStudentsUnlinkMsg: 'UNLINK',
        myStudentsUnlinkedPrefixMsg: 'Successfully unlinked',
        myStudentsUnlinkedSuffixMsg: 'learners',
        myStudentsUnlinkingErrorMsg: 'Error un-linking students',
        myStudentsTestEntriesTitleMsg: 'My Students Test Entries',
        myStudentsTestEntriesNoEntriesMsg: 'No test entries',
        myStudentsWorksheetEntriesTitleMsg: 'My Students Worksheet Entries',
        myStudentsWorksheetEntriesNoEntriesMsg: 'No worksheet entries',
        quizQuestionHeadingMsg: 'QUESTION',
        quizQuestionViewFormulaSheetMsg: 'View Formula Sheet',
        quizQuestionAnswerMsg: 'ANSWER',
        quizQuestionCorrectMsg: 'Correct',
        quizQuestionIncorrectMsg: 'Incorrect',
        quizQuestionInvalidAnswerMsg: 'That is not a valid answer.<br/>Try again',
        quizQuestionDragPhotoMsg: 'Drag your photo here to upload<br> or click to browse',
        quizQuestionUploadPhotoMsg: 'Uploading your photo...',
        quizQuestionUploadFailedMsg: 'Upload failed',
        quizQuestionDeletingPhotoMsg: 'Deleting your photo...',
        quizQuestionPhotoDeletedMsg: 'Photo deleted',
        quizQuestionDeleteFailedMsg: 'Delete failed',
        quizQuestionNoPhotoUploadedMsg: 'No photo uploaded',
        quizQuestionMarkingFeedbackMsg: 'MARKING FEEDBACK:',
        quizQuestionTotalAbbreviationMsg: 'Tot:',
        quizQuestionPlaceholderUnansweredMsg: 'unanswered',
        quizQuestionSavingMsg: 'saving...',
        quizQuestionSavedMsg: '...saved',
        createTestHeadingMsg: 'Create Test',
        createTestHeadingDesktopSuffixMsg: 'from Question Set',
        createTestCreatingMsg: 'Creating test ...',
        createTestOpenToScheduleMsg: 'open test to schedule',
        createTestOpenToScheduleAltMsg: 'Open test',
        createTestQuestionSetMsg: 'QUESTION SET',
        createTestWhenCanScheduleMsg: 'WHEN CAN YOU SCHEDULE THIS TEST?',
        createTestDurationMsg: 'DURATION',
        createTestNameMsg: 'TEST NAME',
        createTestAddTestButtonMsg: 'ADD TEST',
        createTestFormatOlympiadMsg: 'Olympiad',
        createTestFormatPubQuizMsg: 'Pub quiz',
        createTestFormatWorksheetMsg: 'Worksheet',
        createTestOutsideWindowMsg: 'The test cannot be scheduled outside the participation window. Please try again',
        createTestErrorMsg: 'Error creating test',
        testPracticeFetchingMsg: 'Fetching practice questions ...',
        testPracticeHeadingMsg: 'Practice questions',
        testPracticeInviteMsg: 'Before starting the test, we invite you to try the 3 practice questions below to familiarise yourself with the test format. Once you\'ve answered the last practice question, the questions will be marked and will reload showing whether your answered them correctly or not, and you will be able to view the solutions',
        testPracticeStartButtonMsg: 'Start practice',
        testPracticeSavingYourAnswerMsg: 'Saving your answer...',
        testPracticeInviteWhileWaitingMsg: 'While you wait for the test to start, we suggest that you answer the below practice questions to familiarise yourself with the test format. Once you\'re finished, press "Submit practice questions" to mark them and see how you\'ve done.',
        testPracticeSubmitButtonMsg: 'Submit practice questions',
        eventsTitleMsg: 'Events & Tests',
        eventsLoadingMsg: 'Loading tests',
        eventsOpenTestMsg: 'open a test you scheduled',
        viewTestDetailsHeadingMsg: 'Test Details',
        viewTestDetailsCloseMsg: 'Close test details',
        viewTestDetailsEditDateMsg: 'Edit date',
        viewTestDetailsTestCodeMsg: 'TEST CODE',
        viewTestDetailsTestRegistrationURLMsg: 'TEST REGISTRATION URL',
        viewTestDetailsAvailableMsg: 'Available',
        viewTestDetailsFromMsg: 'from',
        viewTestDetailsTestEntrantsMsg: 'Test Entrants',
        profileMyMsg: 'My',
        profileImagePlaceholderMsg: 'Placeholder profile image',
        profileInformationMsg: 'Information',
        profileStatisticsMsg: 'Statistics',
        profileLinkToTeacherAndFriendsMsg: 'LINK LEARNERS TO TRACK THEIR PROGRESS - ASK THEM TO VISIT THIS URL',
        profileLinkCodeMsg: 'YOUR LINK CODE',
        profileEditEmailMsg: 'Edit email',
        profileNotificationsMsg: 'NOTIFICATIONS',
        profileEmailMeMsg: 'Email me about future events & competitions',
        profileNicknameMsg: 'NICKNAME',
        profileEditNicknameMsg: 'Edit nickname',
        profileEditFirstNameMsg: 'Edit first name',
        profileEditSurnameMsg: 'Edit surname',
        profileEditSchoolMsg: 'Edit School',
        profileAccountTypeMsg: 'TYPE',
        profileLanguageMsg: 'LANGUAGE',
        profileGenderMsg: 'GENDER',
        profileCountryMsg: 'COUNTRY',
        profileMyStatisticsMsg: 'My Statistics',
        profileQuestionsAnsweredMsg: 'Questions answered',
        profileQuestionsAnsweredCorrectlyMsg: 'Questions answered correctly',
        profileStreakCorrectMsg: 'Longest streak of correct answers',
        profileDaysActiveMsg: 'Days Active',
        profilePointsAccumulatedMsg: 'Points accumulated',
        profileMyBadgesMsg: 'My Badges',
        profileTotalQuestionsMsg: 'TOTAL QUESTIONS',
        profileNotEnteredYetMsg: 'not entered yet',
        page404Msg: 'Page not found',
        aboutTitleMsg: 'About',
        aboutIntro1Msg: '<b>MyTutor.chat</b> is a South African built free-to-access digital teaching tool that assists with mathematics education by offering learners appropriately graded questions to address their learning objectives. By offering personal journeys - tailored to each learners age, grade and capability - we reach the users where they are: in their homes, in their <b>zone of proximal development</b> where learning is most productive.',
        aboutIntro2Msg: 'Our initial focus is on high school students, and those dealing with <b>mathematics competition questions</b> which have an emphasis on problem solving techniques. We intend extending our offering to cover components of the CAPS curriculum over the course of 2021 and beyond.',
        aboutFuturePlansHeadingMsg: 'Future plans',
        aboutFuturePlansPart1Msg: 'In addition to constantly extending the question and answer bank, we are planning on adding worksheets, online testing functionality and offering our service in additional languages.',
        aboutFuturePlansPart2Msg: 'We hope to also offer functionality for teachers and parents as well as learners.',
        aboutFounderHeadingMsg: 'Founder',
        aboutFounderPart1Msg: 'MyTutor.chat was founded by Greg Becker in 2020. Greg is a retired actuary and mathematics teacher who saw the need for improving access to quality mathematics education across South Africa.',
        aboutStateOfSAMathsHeadingMsg: 'The State of South African Mathematics Education',
        aboutStateOfSAMathsPart1Msg: 'Very few South Africans are obtaining marks for matric mathematics that prepares them for the rigors of a university education in a STEM field. The current goal for the Minister of Basic Education is to improve results so that 35,000 obtain at least 60% for mathematics by 2024 - a rise from 28,000 in 2019. While this is a movement in the right direction, this is a very low number when compared to other countries - those that will be competing with us in the globalised world of tomorrow.',
        aboutStateOfSAMathsPart2Msg: 'The international benchmarking assessment conducted by TIMSS in 2019 shows that while less than 1% of South African students scored more than 625 on their competency scale, 51% of those in Singapore reached obtained this mark. At the other end of the scale, 98% of learners in Singapore obtained at least 400 - while only 41% of learners assessed in South Africa did. We were joint last with Morocco, behind countries like Chile, Egypt, Malaysia, Oman, Romania, and Turkey.',
        aboutStateOfSAMathsPart3Msg: 'For those who are interested, international studies produced by TIMSS are worth looking into, as are those by SACEMA and the annual matric results released by the Department of Education. The international benchmarking studies allow for an interpretation of our domestic matric results.',
        contactUsTitleMsg: 'Contact',
        contactUsPart1Msg: '<strong>Sharks Quiz:</strong> If you are from Ghana and having problems signing up for the Sharks Quiz, please make sure that you have selected Ghana as your country (the test is not available to those who have not completed the initial registration questions and listed their country as Ghana. (You can change this by going to MyProfile on the menu.)',
        contactUsPart2Msg: 'You can reach us by email on',
        contactUsPart3Msg: 'Please include a screenshot of your MyProfile so that we can follow up your situation.',
        logicDayCTAMsg: 'Enter the Logic Day Challenge',
        talentSearchCTAMsg: 'Enter the Talent Search',
        homeworkTitleMsg: 'Homework',
    },
};

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages, // set locale messages
});

export default i18n