import { render, staticRenderFns } from "./ManualMark.vue?vue&type=template&id=4c37c50f&scoped=true&"
import script from "./ManualMark.vue?vue&type=script&lang=js&"
export * from "./ManualMark.vue?vue&type=script&lang=js&"
import style0 from "./ManualMark.vue?vue&type=style&index=0&id=4c37c50f&prod&scoped=true&lang=css&"
import style1 from "./ManualMark.vue?vue&type=style&index=1&id=4c37c50f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c37c50f",
  null
  
)

export default component.exports