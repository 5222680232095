import ActivityBasedTimer from '../services/activity-based-timer'
import axios from 'axios';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      versioningTimer: null,
      newHash: ''
    }
  },
  methods: {
    initVersionCheck: function() {
      this.versioningTimer = new ActivityBasedTimer();

      const self = this;
      this.versioningTimer.setInterval({
        async callback() {
          console.log('Version callback entered');
          const newVersionAvailable = await self.isNewerVersionAvailable('/version.json');
          console.log('Finished callback');
          if (!newVersionAvailable) {
            console.log('No new version');
            return;
          }

          console.log('New version available - notify');
          self.$root.$emit("new-version-available");
        },
        // Normal interval is once every 10 minutes
        interval: 1000 * 60 * 10,
        // Forced interval is once per day
        forcedInterval: 1000 * 60 * 60 * 24,
      });
    },
    isNewerVersionAvailable: async function(url) {
      console.log("Checking for new version of app");
      if (this.currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}") {
        console.log("Skipping check as initial hash not set - dev?");
        return false;
      }
      try {
        const fileResponse = await axios.get(`${url}?t=${new Date().getTime()}`);
        this.newHash = fileResponse.data.hash;
        return this.hasHashChanged(this.currentHash, this.newHash);
      } catch (error) {
        if (!error.response) {
          console.log('Error: Network Error');
        } else if (error.response.status === 404) {
          console.log('Error: File not found');
        } else {
          console.log('Error: ' + error.response.data.message);
        }
        return false;
      }
    },
    hasHashChanged: function(currentHash, newHash) {
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true;
      }
      return currentHash !== newHash;
    },
    reloadApp: function() {
      window.location.reload();
    }
  }
};