<template>

    <div class="quiz-question">
        <div class="quiz-question__number quiz-question__number--answered">
            QUESTION {{answer.number}}
        </div>

        <!-- Question and solution -->
        <chat-line v-bind:chatLine="answer" v-bind:questionInQuiz="true" v-bind:questionAnswered="true"
                   class="quiz-question__question"/>

        <!-- Memo - NOT-DEFINED-YET -->

        <div class="quiz-question__spacer answered quiz-question__spacer--answered"></div>

        <!-- Entrant's answer -->
        <div class="quiz-question__answer-text quiz-question__answer-text--paragraph quiz-question__answer-text--answered">
            <span>ANSWER:</span>
            <div class="quiz-question__answer-text-row">
                <textarea ref="answerInput" v-model="editableAnswer.answer" disabled></textarea>
                <div class="quiz-question__answer-text-marked"></div>
            </div>
            <div class="quiz-question__answer-text-row">
                <div class="quiz-question__answer-photo-thumbnail">
                    <template v-if="answeredWithPhoto">
                        <img v-bind:src="editableAnswer.answerPhotoURL" v-on:click="showPhotoModal = true"/>
                        <img src="../assets/magnify.png" class="quiz-question__answer-photo-thumbnail-magnify"/>
                    </template>
                    <div v-else class="quiz-question__answer-photo-thumbnail--no-photo">No photo uploaded</div>
                </div>
                <div class="quiz-question__answer-text-marked"></div>
            </div>
        </div>

        <div class="quiz-question__answer-text quiz-question__answer-text--paragraph quiz-question__answer-text--answered">
            <span>MARKING:</span>

            <!-- Right / wrong -->
            <div class="quiz-question__mark">
                <div>
                    <div class="quiz-question__option" v-on:click="markCorrect()"
                         v-bind:class="{'quiz-question__option--selected': editableAnswer.correct === true}"></div>
                    <img src="../assets/tick.svg" alt="Correct" class="quiz-question__mark--correct"/>
                    Correct
                </div>

                <div>
                    <div class="quiz-question__option" v-on:click="markIncorrect()"
                         v-bind:class="{'quiz-question__option--selected': editableAnswer.correct === false}"></div>
                    <img src="../assets/cross.svg" alt="Incorrect" class="quiz-question__mark--incorrect"/>
                    Incorrect
                </div>
            </div>

            <!-- Scoring -->
            <div class="quiz-question__scoring">
                <div class="quiz-question__answer-text-row">
                    <input type="text"
                           v-model="editableAnswer.score"
                           ref="scoreInput"
                           v-bind:disabled="markingAnswer"
                           v-on:blur="markAnswer()"
                           placeholder="Score"
                           v-on:keydown="removeValidationMessage()"/>
                    <span class="quiz-question__answer-text-status">
                        {{markingAnswerMessage}}
                    </span>
                </div>
                <div class="quiz-question__message" v-show="invalidScore">
                    <div>
                        {{invalidScore}}
                    </div>
                </div>
            </div>

            <!-- Comments -->
            <div class="quiz-question__answer-text-row">
                <textarea v-model="editableAnswer.markersComments"
                          ref="commentsInput"
                          v-bind:disabled="markingAnswer"
                          v-on:blur="markAnswer()"
                          placeholder="Comments"></textarea>
                <span class="quiz-question__answer-text-status">
                    {{markingAnswerMessage}}
                </span>
            </div>

            <div class="modal" v-if="showPhotoModal" v-on:click="closePhotoPopup">
                <!-- <div class="modal-wrapper"> -->
                <div class="modal-content">
                    <div class="modal-content__image"><img v-if="answeredWithPhoto" v-bind:src="editableAnswer.answerPhotoURL"/>
                    </div>
                    <div class="modal-content__close"><img src="../assets/cross.svg" alt="Close"
                                                           v-on:click="closePhotoPopup"/></div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>

</template>

<script>
    import ChatLine from './ChatLine.vue';

    export default {
        name: 'MarkingQuestion',
        components: {
            ChatLine,
        },
        props: {
            index: Number,
            answer: Object,
        },
        data() {
            return {
                editableAnswer: this.answer,
                answerPhotoURL: null,
                showPhotoModal: false,
                invalidScore: null,
                markingAnswer: false,
                markingAnswerMessage: "",
            }
        },
        computed: {
            answeredWithPhoto: function () {
                return this.editableAnswer.answerPhotoS3Key !== null;
            },
        },
        methods: {
            closePhotoPopup: function () {
                this.showPhotoModal = false;
            },
            markCorrect: async function () {
                if (!this.markingAnswer) {
                    this.editableAnswer.correct = true;
                    await this.markAnswer();
                }
            },
            markIncorrect: async function () {
                if (!this.markingAnswer) {
                    this.editableAnswer.correct = false;
                    await this.markAnswer();
                }
            },
            markAnswer: async function () {
                const regex = new RegExp("^\\d+(\\.\\d+)?$");
                if (!this.editableAnswer.score || (this.editableAnswer.score && !regex.test(this.editableAnswer.score))) {
                    console.log("invalid score");
                    this.invalidScore = "Please enter a number";
                } else if (this.editableAnswer.score > this.editableAnswer.points) {
                    console.log("invalid score, " + this.editableAnswer.score + " is more than points that question is worth (" + this.editableAnswer.points + ")");
                    this.invalidScore = "Score must be <= " + this.editableAnswer.points;
                } else {
                    console.log("saving score and comments");
                    this.invalidScore = null;
                    this.$root.$emit('mark-answer', this.editableAnswer.id, this.editableAnswer.correct, this.editableAnswer.score, this.editableAnswer.markersComments);
                }
            },
            removeValidationMessage: function () {
                this.invalidScore = null;
            }
        },
        async mounted() {
            const answerTextArea = this.$refs.answerInput;
            answerTextArea.setAttribute("style", "height:" + (answerTextArea.scrollHeight) + "px;overflow-y:hidden;")
            this.$root.$on('marking-answer', (answerID) => {
                if (answerID === this.editableAnswer.id) {
                    this.markingAnswerMessage = "saving...";
                    this.markingAnswer = true;
                }
            });
            this.$root.$on('marked-answer', (answerID, error) => {
                if (answerID === this.editableAnswer.id) {
                    this.markingAnswer = false;
                    if (error) {
                        this.markingAnswerMessage = error;
                    } else {
                        this.markingAnswerMessage = "...saved";
                        setTimeout(() => {
                            this.markingAnswerMessage = "";
                        }, 3000);
                    }
                }
            });
        },
        beforeDestroy() {
            this.$root.$off("marking-answer");
            this.$root.$off("marked-answer");
        },
    }
</script>

<style>
    .quiz-question {
        text-align: left;
        position: relative;
        margin-bottom: 100px;
    }

    .quiz-question__number, .quiz-question__stats-total-answered, .quiz-question__formula-sheets {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: -40px;
        width: 140px;
        margin-left: 10px;
        padding: 8px 0 16px 0;
        background-color: #ffffff;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        letter-spacing: 0.48px;
        text-align: center;
        border: 3px solid #FFFFFF;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .quiz-question__stats-total-answered, .quiz-question__formula-sheets {
        left: 150px;
        width: 500px;
        text-align: left;
        padding-left: 15px;
        background: none;
        border-color: transparent;
    }

    .quiz-question__formula-sheets {
        right: 0;
        width: inherit;
        text-align: left;
        text-decoration: underline;
        margin-right: 10px;
        padding: 8px 0 16px 0;
    }

    .quiz-question__formula-sheets:hover {
        cursor: pointer;
    }

    .quiz-question__formula-sheets img {
        height: 35px;
    }

    .quiz-question__number--answered {
        border-color: #5469F3;
        background-color: #5469F3;
        color: #ffffff;
    }

    .quiz-question__question {
        position: relative;
        z-index: 2;
    }

    .quiz-question__spacer {
        height: 4px;
        border: 4px solid transparent;
        border-top: none;
        border-bottom: none;
    }

    .quiz-question__spacer--answered {
        border: 4px solid #5469F3;
        border-top: none;
        border-bottom: none;
        margin: 0 10px;
    }

    .quiz-question__options {
        text-align: center;
        list-style: none;
        margin: 0 10px;
        height: 100px;
        background-color: #F2F2F2;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__options--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__options li {
        display: inline-block;
        margin: 28px 4%;
        font: normal normal normal 26px/26px Inter;
        letter-spacing: 0px;
        color: #1C1A4F;
    }

    .quiz-question__option {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid #1C1A4F;
        border-radius: 20px;
        vertical-align: middle;
        margin-right: 10px;
    }

    .quiz-question__options--disabled li {
        color: #B8BABD !important;
    }

    .quiz-question__options--disabled .quiz-question__option {
        border-color: #B8BABD;
    }

    .quiz-question__option div {
        border-radius: 15px;
        height: 28px;
        width: 28px;
        margin-top: 4px;
        margin-left: 4px;
    }

    .quiz-question__option span {
        color: white !important;
    }

    .quiz-question__option span img {
        width: 17px;
    }

    .quiz-question__option--selected {
        background-color: #5469F3;
    }

    .quiz-question__option--correct {
        background-color: #02C664;
    }

    .quiz-question__option--incorrect span {
        vertical-align: top;
    }

    .quiz-question__option--incorrect {
        background-color: #E0312E;
    }

    .quiz-question__answer-text {
        text-align: left;
        margin: 0 10px;
        font: normal normal 400 28px/44px Inter;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        padding: 10px 0;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__answer-text--paragraph {
        flex-direction: column;
    }

    .quiz-question__answer-text-row {
        flex-direction: row;
        display: flex;
        margin-bottom: 20px;
    }

    .quiz-question__answer-text span {
        padding: 13px 20px;
    }

    .quiz-question__answer-text input[type="text"], .quiz-question__answer-text--paragraph textarea {
        flex: 1;
        padding: 5px 15px;
        margin-left: 10px;
        font: normal normal 400 28px/22px Inter;
        color: #5469F3;
        /* border: 1px solid #363374; */
        border: 2px solid #666;
        border-radius: 10px;
        outline: none;
    }

    .quiz-question__answer-text--paragraph textarea {
        font: normal normal 400 20px/24px Inter !important;
    }

    .quiz-question__answer-text input[type="text"]:focus {
        border-color: #5469F3;
    }

    .quiz-question__answer-text--paragraph textarea {
        height: 200px;
        min-height: 200px;
    }

    .quiz-question__answer-text--disabled {
        color: #ccc;
        /* border-color: #ccc; */
    }

    .quiz-question__answer-text--disabled input[type="text"] {
        color: #363374;
    }

    .quiz-question__answer-text-status {
        width: 100px;
    }

    .quiz-question__answer-text-marked {
        width: 40px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        padding: 10px 10px;
        border-radius: 20px;
        height: 40px;
        margin-top: 3px;
    }

    .quiz-question__answer-text--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__answer-photo {
        min-height: 200px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 20px 10px 10px 10px;
        flex: 1;
        text-align: center;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: #DDE1FD url("../assets/camera.png") no-repeat center;
        color: dimgray;
        position: relative;
        cursor: pointer;
    }

    .quiz-question__answer-photo:hover {
        background: #5469F3 url("../assets/camera.png") no-repeat center;
        color: #fff;
    }

    .quiz-question__answer-photo-input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
    }

    .quiz-question__answer-photo p {
        font-size: 1.2em;
        text-align: center;
        margin-bottom: 0;
    }

    .quiz-question__answer-photo-thumbnail {
        min-height: 200px;
        max-height: 200px;
        margin-left: 10px;
        padding: 10px;
        flex: 1;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -5px;
        text-align: center;
        position: relative;
    }

    .quiz-question__answer-photo-thumbnail:hover {
        cursor: pointer;
    }

    .quiz-question__answer-photo-thumbnail img {
        max-height: 100%;
        max-width: 100%;
    }

    .quiz-question__answer-photo-thumbnail-magnify {
        position: absolute;
        left: 47%;
        top: 34%;
    }

    .quiz-question__answer-photo-thumbnail--no-photo {
        width: 100%;
        height: 100%;
        padding-top: 70px;
        text-align: center;
    }

    .quiz-question__answer-photo-delete {
        border-radius: 25px;
        background-color: #B8BABD;
        padding: 3px 0 0 3px;
        width: 48px;
        height: 48px;
        margin-left: 10px;
    }

    .quiz-question__message {
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: 49px;
        padding: 0 15px;
        z-index: 100;
        text-align: center;
    }

    .quiz-question__message > div {
        position: relative;
        padding: 10px;
        left: 0%;
        background-color: #5469F3;
        color: white;
        font-weight: bold;
    }

    .quiz-question__message > div:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #5469F3;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }

    .quiz-question__answer-stats {
        font: normal normal 600 14px/32px Inter;
        letter-spacing: 0.28px;
        color: #1C1A4F;
    }

    .quiz-question__mark {
        padding: 0px 10px 20px 10px;
        /* margin-left: auto;
        margin-right: auto; */
    }

    .quiz-question__mark > div {
        margin-right: 150px;
        display: inline-block;
    }

    .quiz-question__mark img {
        width: 25px;
        vertical-align: middle;
    }

    .quiz-question__mark--correct {
        filter: invert(57%) sepia(19%) saturate(5998%) hue-rotate(111deg) brightness(97%) contrast(98%);
    }

    .quiz-question__mark--incorrect {
        filter: invert(50%) sepia(20%) saturate(6374%) hue-rotate(-21deg) brightness(94%) contrast(88%)
    }

    .quiz-question__scoring {
        position: relative;
    }

    .modal {
        overflow: inherit;
        display: flex;
        justify-content: center;
        flex-direction: inherit;
    }

    .modal-content {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        margin: 0 auto;
        display: inline-block;
    }

    .modal-content__image {
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        text-align: center;
    }


    @media screen and (min-width: 375px)  and (max-width: 610px) {
        .quiz-question__number {
            font: normal normal 600 14px/18px Inter;
        }

        .quiz-question__options {
            height: 80px;
        }

        .quiz-question__options li {
            margin: 16px 3%;
            font: normal normal normal 20px/20px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 11px;
            height: 20px;
            width: 20px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 18px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 1px;
            vertical-align: top;
        }
    }

    @media screen and (max-width: 414px) {
        .quiz-question__number {
            font: normal normal 600 14px/18px Inter;
        }

        .quiz-question__options {
            height: 60px;
        }

        .quiz-question__options li {
            margin: 14px 3%;
            font: normal normal normal 18px/18px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 9px;
            height: 14px;
            width: 14px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 14px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 0;
            vertical-align: top;
        }

        .hidden-mobile {
            display: none !important;
        }

        .quiz-question__formula-sheets {
            font-size: 14px;
        }
    }

</style>