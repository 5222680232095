<template>
    <div>
        <div class="chat-window__header">
            <img src="../assets/logo-home.svg" alt="MyTutor Logo"/>
        </div>

        <form class="form-entry">
            <h1>2022 World Cup Maths Event</h1>
            <span class="form-entry--note">
                <p v-if="showTestAndTeacher">
                    You have been invited to participate in <strong>{{selectedTestName}}</strong>
                    by <strong>{{teacher.name}}</strong> of <strong>{{teacher.school}}</strong>.
                </p>
                <br/>
                <!--                <p>You can join 4 million learners from around the world and participate in the <b>Kangaroo Maths-->
                <!--                    Competition</b> - the world’s largest.</p>-->
                <!--                <br/>-->
                <p>Participation is <b>free</b>.Enter one or more papers before the World Cup Final on <b>18 December</b>.
                    Anyone can participate, although the papers are most suitable for learners from <b>Grade 4 and above</b>. </p>
                <br/>
                <p>If you have a MyTutor.chat account, <router-link to="/login">sign in</router-link> to your account
                    and participate by clicking on the <b>2022 World Cup</b> menu and register for a test as this
                    will allow you to see your results and solutions forever. </p>
                <br/>
                <p>We recommend starting with the <b>Group 10-minute</b> event before moving to the <b>Group 30-minute</b>, and
                    then the <b>Knockout 10-minute</b> and <b>Knockout 30-minute</b>.  You can do the events on different days over
                    the world cup!</p>
            </span>

            <ul>
                <li>
                    <div>
                        <label for="event">Event *</label>
                    </div>
                    <div>
                        <select v-model="testHostLinkCode" id="event" v-on:change="testChanged">
                            <option value="">Select an event</option>
                            <option v-for="(test, index) of tests" v-bind:key="index"
                                    v-bind:value="test.testHostLinkCode">{{getTestDisplayName(test)}}
                            </option>
                        </select>
                        <span v-if="showValidateErrorNoEvent" class="form-entry__error">
                            Please select an event
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="firstName">First name *</label>
                    </div>
                    <div>
                        <input v-model="firstName" id="firstName" type="text"/>
                        <span v-if="showValidateErrorNoFirstName" class="form-entry__error">
                            Please enter your first name
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="surname">Surname *</label>
                    </div>
                    <div>
                        <input v-model="surname" id="surname" type="text"/>
                        <span v-if="showValidateErrorNoSurname" class="form-entry__error">
                            Please enter your surname
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="surname">Country *</label>
                    </div>
                    <div>
                        <input v-model="country" id="country" type="text"/>
                        <span v-if="showValidateErrorNoCountry" class="form-entry__error">
                            Please enter your country
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="surname">School *</label>
                    </div>
                    <div>
                        <input v-model="school" id="school" type="text"/>
                        <span v-if="showValidateErrorNoSchool" class="form-entry__error">
                            Please enter your school
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="grade">Grade *</label>
                    </div>
                    <div>
                        <select v-model="grade" id="grade">
                            <option value="0">Select a grade</option>
                            <option v-for="gradeNum in 12" v-bind:key="gradeNum" v-bind:value="gradeNum">{{gradeNum}}
                            </option>
                        </select>
                        <span v-if="showValidateErrorNoGrade" class="form-entry__error">
                            Please enter your grade
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label>Is this your first attempt? <span class="fifa-maths-quiz__required">*</span></label>
                        <br/>
                        <span class="form-entry--note">Only those participating for the first time will be added to the leaderboard.</span>
                    </div>
                    <div>
                        <input type="radio" id="first-time" v-model="showOnLeaderboard" value="true" required/>
                        <label for="first-time">First time</label>
                        <input type="radio" id="repeat" v-model="showOnLeaderboard" value="false"/>
                        <label for="repeat">Repeat visit</label>
                        <br/>
                        <span v-if="showValidateErrorNoAttempt" class="form-entry__error">
                            Please indicate if this is your first attempt
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="email">Email <span class="form-entry--note">(optional)</span></label>
                        <br/>
                        <span class="form-entry--note">We will email your results here</span>
                    </div>
                    <div>
                        <input v-model="notificationEmail" id="email" type="text"/>
                    </div>
                </li>
                <li>
                    <div>
                        <label for="teacherLinkCode">Teacher link code</label>
                        <br/>
                        <span class="form-entry--note">You teacher can login and find this under "My Profile"</span>
                    </div>
                    <div>
                        <input v-model="teacherLinkCode" v-on:blur="checkTeacherCode" v-on:change="teacherCodeChanged"
                               id="teacherLinkCode" type="text"/>
                        <span v-if="showValidateErrorTeacherNotFound" class="form-entry__error">
                            We could not find a teacher with this link code. Please try again.
                        </span>
                        <!--                        <span v-if="showValidateErrorNoTeacherLinkCode" class="form-entry__error">-->
                        <!--                            Please enter a teacher link code-->
                        <!--                        </span>-->
                    </div>
                </li>
                <li v-if="selectedTestTsAndCs" class="form-entry--note form-entry-notes">
                    <div>
                        <input v-model="agreeToTsAndCs" type="checkbox"> I agree to the <a
                            v-bind:href="selectedTestTsAndCs" target="_blank">Terms &amp; Conditions</a>.<br/>(If you're
                        under 18, please ask your mom/dad/educator<br/> to read them before agreeing)
                    </div>
                    <div v-if="showValidateErrorAgreeToTsAndCs" class="form-entry__error">
                        Please read and agree to the Terms &amp; Conditions
                    </div>
                </li>
                <li class="form-entry-cta">
                    <button v-on:click.prevent="register" class="btn btn-primary">Register and Start</button>
                </li>
            </ul>
        </form>

        <notifications v-if="showNotifications" v-bind:notifications="notifications"
                       v-bind:showSpinner="showNotificationSpinner"></notifications>
    </div>
</template>

<script>
    import {Auth} from "@aws-amplify/auth";

    // Visual components
    import Notifications from '../components/Notifications.vue';

    // Mixins
    import {eventEntryMixin} from "../mixins/EventEntryMixin";

    export default {
        components: {Notifications},
        mixins: [eventEntryMixin],
        data() {
            return {
                registration: null,
                tests: [],
                teacher: null,
                agreeToTsAndCs: false,
                notifications: [],
                showNotificationSpinner: false,
                showNotifications: false,
                showTestAndTeacher: false,
                showValidateErrorNoEvent: false,
                showValidateErrorNoFirstName: false,
                showValidateErrorNoSurname: false,
                showValidateErrorNoCountry: false,
                showValidateErrorNoSchool: false,
                showValidateErrorNoGrade: false,
                showValidateErrorNoAttempt: false,
                showValidateErrorNoTeacherLinkCode: false,
                showValidateErrorTeacherNotFound: false,
                showValidateErrorAgreeToTsAndCs: false
            }
        },
        computed: {
            selectedTestName: function () {
                if (this.testHostLinkCode) {
                    for (let test of this.tests) {
                        if (test.testHostLinkCode === this.testHostLinkCode) {
                            return test.name;
                        }
                    }
                }
                return null;
            },
            selectedTestTsAndCs: function () {
                if (this.testHostLinkCode) {
                    for (let test of this.tests) {
                        if (test.testHostLinkCode === this.testHostLinkCode) {
                            return test.termsAndConditionsURL;
                        }
                    }
                }
                return null;
            },
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Account behaviour
            //
            // ----------------------------------------------------------------------------
            loadTestsAndTeacherDetails: async function () {
                this.notifications = [{message: "Loading tests"}];
                this.showNotificationSpinner = true;
                this.showNotifications = true;

                this.tests = this.tests.slice();
                let listOfTests = await this.fetchTestsThatCanBeEnteredWithoutAnAccount();
                if (listOfTests === null) {
                    this.showNotificationSpinner = false;
                    this.notifications[0].message = "There was a problem loading tests from the server.<br/><br/>Please email info@mytutor.chat for assistance.";
                } else {
                    this.tests.push(...listOfTests);
                    this.tests.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'});
                    });

                    if (this.teacherLinkCode) {
                        this.teacher = await this.getTeacherDetails(this.teacherLinkCode);
                        if (this.teacher) {
                            this.showValidateErrorTeacherNotFound = false;

                            if (this.selectedTestName) {
                                this.showTestAndTeacher = true;
                            }
                        }
                    }
                    this.showNotificationSpinner = false;
                    this.showNotifications = false;
                }
            },
            getTestDisplayName: function (test) {
                let text = test.name;
                let parts = test.suggestedDuration.split(":");
                text = text + " (" + ((parseInt(parts[0]) * 60) + parseInt(parts[1])) + " mins)";
                return text;
            },
            testChanged: function () {
                this.showTestAndTeacher = false;
            },
            checkTeacherCode: async function () {
                if (this.teacherLinkCode) {
                    this.teacher = await this.getTeacherDetails(this.teacherLinkCode);
                    if (this.teacher) {
                        this.showValidateErrorTeacherNotFound = false;
                        return;
                    } else {
                        this.showValidateErrorTeacherNotFound = true;
                        return;
                    }
                }
                this.showValidateErrorTeacherNotFound = false;
            },
            teacherCodeChanged: function () {
                this.showTestAndTeacher = false;
                this.showValidateErrorTeacherNotFound = false;
            },
            register: async function () {
                console.debug("validating form");
                if (!this.validateForm()) {
                    return;
                }

                console.debug("create User and register for Test");
                this.notifications = [{message: "Registering you for the test"}];
                this.showNotificationSpinner = true;
                this.showNotifications = true;

                this.registration = await this.createUserAndRegisterForTest(
                    this.firstName,
                    this.surname,
                    this.country,
                    this.school,
                    this.grade,
                    this.showOnLeaderboard,
                    this.notificationEmail,
                    this.testHostLinkCode,
                    this.teacherLinkCode,
                    this.affiliate);

                if (this.registration) {
                    // manually sign in
                    this.notifications = [{message: "Registration complete, doing some housekeeping"}];
                    console.debug("Auto login");
                    try {
                        const user = await Auth.signIn(this.registration.email, this.registration.password);
                        console.debug("Signed in with Auth");
                        // tell App that we've signed in
                        this.$root.$emit('manual-sign-in', user);
                    } catch (error) {
                        console.debug('Error signing in', error);
                    }

                    // wait till App has handled the sign-in
                    this.$root.$on('signed-in', this.displayLinkToGoToTest);
                } else {
                    this.notifications = [
                        {message: "There was an error registering you for the test"}
                    ];
                    this.showNotificationSpinner = false;
                }
            },
            displayLinkToGoToTest: function() {
                console.debug("Display link to go to Test");
                this.notifications = [
                    {message: "You have successfully registered for the test"}
                ];
                this.notifications[0].link = {
                    text: "Go to test",
                    callback: () => {
                        this.showNotifications = false;
                        this.$router.push({path: "/test/" + this.registration.testHostID + "/" + this.registration.testEntryID});
                    }
                }
                this.showNotificationSpinner = false;
            },
            validateForm: function () {
                let validated = true;
                this.showValidateErrorNoEvent = false;
                this.showValidateErrorNoFirstName = false;
                this.showValidateErrorNoSurname = false;
                this.showValidateErrorNoCountry = false;
                this.showValidateErrorNoSchool = false;
                this.showValidateErrorNoGrade = false;
                this.showValidateErrorNoAttempt = false;
                this.showValidateErrorNoTeacherLinkCode = false;
                this.showValidateErrorAgreeToTsAndCs = false;

                if (!this.testHostLinkCode) {
                    this.showValidateErrorNoEvent = true;
                    validated = false;
                }
                if (!this.firstName) {
                    this.showValidateErrorNoFirstName = true;
                    validated = false;
                }
                if (!this.surname) {
                    this.showValidateErrorNoSurname = true;
                    validated = false;
                }
                if (!this.country) {
                    this.showValidateErrorNoCountry = true;
                    validated = false;
                }
                if (!this.school) {
                    this.showValidateErrorNoSchool = true;
                    validated = false;
                }
                if (!this.grade) {
                    this.showValidateErrorNoGrade = true;
                    validated = false;
                }
                if (this.selectedTestTsAndCs && !this.agreeToTsAndCs) {
                    this.showValidateErrorAgreeToTsAndCs = true;
                    validated = false;
                }
                return validated;
            }
        },
        mounted: async function () {
            this.closeNotificationsFn = (text) => {
                console.log("clearing notifications: " + text);
                this.notifications = [];
                this.showNotifications = false;
            };
            this.$root.$on('close-notifications', this.closeNotificationsFn);

            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("tr")) {
                this.teacherLinkCode = searchParams.get("tr");
                console.debug("teacher link code: " + this.teacherLinkCode);
            }
            if (searchParams.has("rft")) {
                this.testHostLinkCode = searchParams.get("rft");
                console.debug("test host link code: " + this.testHostLinkCode);
            }
            if (searchParams.has("aff")) {
                this.affiliate = searchParams.get("aff");
                console.debug("affiliate: " + this.affiliate);
            }
            await this.loadTestsAndTeacherDetails();
        },
        beforeDestroy() {
            this.$root.$off('close-notifications', this.closeNotificationsFn);
            this.$root.$off('signed-in', this.displayLinkToGoToTest);
        },
    }
</script>

<style lang="scss" scoped>
        @import "../styles/layout/forms";
</style>
