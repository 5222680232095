<template>
      <div class="modal">
        <div class="modal-wrapper">
          <div class="modal-content">
            <div class="modal-content__slides">
              <slider v-bind:images="formulaSheetSlidesAsImageArray"/>
            </div>
            <div class="modal-content__close"><img src="../assets/cross.svg" alt="$t('commonCloseMsg')" v-on:click="close"/></div>
          </div>
        </div>
      </div>
</template>

<script>
import Slider from '../components/Slider.vue';

export default {
  name: 'FormulaSheets',
  components: {
    Slider
  },
  props: {
      formulaSheetSlides: Array
  },
  data() {
      return {
      }
  },
  computed: {
    formulaSheetSlidesAsImageArray: function() {
      let slides = [];
      for (let slide of this.formulaSheetSlides) {
        slides.push(slide.slideURL);
      }
      return slides;
    }
  },
  methods: {
      close: function() {
          this.$root.$emit('hide-formula-sheets');
      }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 200;
    overflow: scroll;
    display: table;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-content {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 0 100px rgb(0 0 0 / 70%);
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 70%);
    background-color: #5469F3;
    border-radius: 10px;
    color: white;
    padding: 60px 30px;
    z-index: 300;
    text-align: center;
    display: flex;
    flex-direction: row;
  }

  .modal-content div {
    display: inline-block;
    vertical-align: middle;
  }

  .modal-content ul {
      list-style: none;
  }

  .modal-content__cta {
      margin-top: 20px;
      width: 100%;
  }

  .modal-content__slides {
    font: normal normal 500 26px/32px Inter;
    width: 100%;
  }

  .modal-content__slides img {
    width: 100%;
  }

  .modal-content__close {
    position: absolute;
    top: 22px;
    right: 23px;
    color: #1C1A4F;
  }

  .modal-content__close img {
    width: 23px;
    height: 23px;
  }

  .btn {
    font: normal normal normal 20px/22px Inter;
    letter-spacing: 0.6px;
    color: #fff;
    font-weight: 600;
    border-radius: 20px;
    height: 40px;
    padding: 0 30px;
    margin-bottom: 40px;
    text-align: center;
    opacity: 1;
    border: none;
  }

  .btn:hover {
    cursor: pointer;
  }

  .btn-primary {
      background: #F9AC1B 0% 0% no-repeat padding-box;
  }

  .btn-secondary {
      background: #5469F3 0% 0% no-repeat padding-box;
  }

  .dot-container {
    position: relative !important;
  }

  @media screen and (max-width:414px) {
      .modal-content {
          padding: 40px 20px;
      }

      .modal-content__close {
          top: 10px;
          right: 15px;
      }
  }
  
</style>