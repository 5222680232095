<template>
  <div class="profile">
    <div class="close" v-on:click="closeDialog()">&times;</div>

    <template v-if="loadingHomework">
      <spinner height="50px" colour="#F9AC1B"/>
    </template>

    <template v-if="!loadingHomework">

      <div class="title">
        <div class="title__heading">
          <span>{{listsType}}</span>
        </div>
      </div>

      <div class="lists-of-tests">

        <div class="lists-of-tests__test-list">
          <h6>{{ $t('homeworkWorksheetsHeadingMsg')}}</h6>
          <ul class="my-information">
            <li v-for="worksheet in worksheets" v-bind:key="worksheet.id" class="my-information__detail" v-bind:class="{'worksheets__worksheet--in-progress' : worksheet.isInProgress()}">
              <div class="test__detail">
                {{worksheet.getName()}}
<!--                {{worksheet.name}}-->
              </div>
              <div class="worksheets__cta">
                <div v-if="worksheet.canStart()" v-on:click="startWorksheet(worksheet)" class="worksheets__cta--can-start">
                  <img src="../assets/plus.svg" alt="$t('')"/>
                </div>
                <div v-else-if="worksheet.isInProgress()" v-on:click="openWorksheet(worksheet)" class="worksheets__cta--in-progress">
                  <img src="../assets/arrow.svg" alt="$t('')"/>
                </div>
                <div v-else-if="worksheet.isComplete()" v-on:click="openWorksheet(worksheet)" class="worksheets__cta--complete">
                  <img src="../assets/tick-green.svg" alt="$t('')"/>
                  <div>
                    <template v-if="isOneStarPerformance(worksheet)">
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                      <img src="../assets/star.svg" alt="$t('')"/>
                      <img src="../assets/star.svg" alt="$t('')"/>
                    </template>
                    <template v-else-if="isTwoStarPerformance(worksheet)">
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                      <img src="../assets/star.svg" alt="$t('')"/>
                    </template>
                    <template v-else-if="isThreeStarPerformance(worksheet)">
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                      <img src="../assets/star-yellow.svg" alt="$t('')"/>
                    </template>
                  </div>
                </div>
                <div v-else class="worksheets__cta--locked">
                  <img src="../assets/lock.svg" alt="$t('')"/>
                </div>
              </div>
            </li>
            <li v-if="worksheets && worksheets.length === 0" class="my-information__detail--empty">
              {{ $t('homeworkNoWorksheetsMsg')}}
            </li>
          </ul>
        </div>

      </div>


    </template>

    <!-- ----------------------- -->
    <!-- POPUPS                  -->
    <!-- ----------------------- -->
    <notifications v-if="showNotifications"
                   v-bind:notifications="notifications"
                   v-bind:showSpinner="false">
    </notifications>

  </div>
</template>

<script>
    import Spinner from './Spinner.vue';
    import Notifications from '../components/Notifications';

    export default {
        name: 'ListOfWorksheets',
        components: {
            Spinner, Notifications
        },
        mixins: [],
        props: {
            user: Object,
            listsType: String,
            worksheetsFn: Function,
            worksheetsFnParams: Array,
            closeDialogKey: String
        },
        data: function () {
            return {
                loadingHomework: false,
                worksheets: [],
                showNotifications: false
            }
        },
        computed: {},
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            loadHomework: async function() {
                this.loadingHomework = true;
                this.worksheets = await this.worksheetsFn.call(this, this.worksheetsFnParams),
                this.loadingHomework = false;
            },
            isOneStarPerformance: function (worksheet) {
                return worksheet.performance <= 33;
            },
            isTwoStarPerformance: function (worksheet) {
                return worksheet.performance > 33 && worksheet.performance < 66;
            },
            isThreeStarPerformance: function (worksheet) {
                return worksheet.performance >= 66;
            },
            startWorksheet: function (worksheet) {
                console.log("starting worksheet: " + worksheet.getName());
                if (worksheet.canStart()) {
                    let newPath = '/worksheet/' + worksheet.getID() + "/?start=true";
                    if (this.$router.currentRoute.path === newPath) {
                        console.log("route identical, ignoring");
                    } else {
                        this.$router.push({path: newPath});
                    }
                }
                this.closeDialog();
            },
            openWorksheet: function (worksheet) {
                console.log("opening worksheet: " + worksheet.getName());
                if (worksheet.isInProgress() || worksheet.isComplete()) {
                    let newPath = '/worksheet/' + worksheet.entry.id;
                    if (this.$router.currentRoute.path === newPath) {
                        console.log("route identical, ignoring");
                    } else {
                        this.$router.push({path: newPath});
                    }
                }
                this.closeDialog();
            },
            closeDialog: function () {
                this.$root.$emit(this.closeDialogKey);
            }
        },
        mounted: async function () {
            await this.loadHomework();
        },
        beforeDestroy() {
        },
    }
</script>

<style lang="scss" scoped>
  @import "../styles/pages/profile";
  @import "../styles/pages/tests";
  @import "../styles/pages/homework";
</style>

