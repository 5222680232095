<template>
  <main-layout>
    <h1 id="About">{{ $t('aboutTitleMsg')}}</h1>
    <p v-html="$t('aboutIntro1Msg')"></p>
    <p v-html="$t('aboutIntro2Msg')"></p>
    <h2>{{ $t('aboutFuturePlansHeadingMsg')}}</h2>
    <p v-html="$t('aboutFuturePlansPart1Msg')"></p>
    <p v-html="$t('aboutFuturePlansPart2Msg')"></p>
    <h2>{{ $t('aboutFounderHeadingMsg')}}Founder</h2>
    <p v-html="$t('aboutFounderPart1Msg')"></p>
    <h2>{{ $t('aboutStateOfSAMathsHeadingMsg')}}</h2>
    <p v-html="$t('aboutStateOfSAMathsPart1Msg')"></p>
    <p v-html="$t('aboutStateOfSAMathsPart2Msg')"></p>
    <p v-html="$t('aboutStateOfSAMathsPart3Msg')"></p>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>