<template>
    <div class="leaderboard">
        <div class="flag"><img src="../assets/leaderboard-flag.svg" alt="Leaderboard flag"/></div>
        <div class="close" v-on:click="$root.$emit('close-leaderboard')">&times;</div>
        <div class="title">
            <div class="title__flag">
                <span class="title__flag-top">{{ $t('commonLeaderboardTopMsg')}}</span>
                <span class="title__flag-10">10</span>
            </div>
            <div class="title__heading">
                <span class="grade">{{gradeTitle}}</span>
                <span>{{ $t('commonLeaderboardTitleMsg')}}</span>
            </div>
        </div>
        <div class="leaderboard__grade-selector">
            <img v-if="gradesToShow.length > 6" v-bind:src="hover === 'left' ? leftArrowHover : leftArrow" v-on:mouseover="hover = 'left'" v-on:mouseleave="hover = null" class="leaderboard__grade-selector-arrow" alt="$t('commonLeaderboardSlideLeftMsg')" v-on:click="slideSelectorLeft()"/>
            <ul class="leaderboard__grade-selector-list">
                <li v-for="(grade, index) in gradesShown" v-bind:key="index" v-on:click="selectGrade(grade)">
                    <span v-bind:class="{'leaderboard__grade-selector--selected': selectedGrade === grade}">
                        <template v-if="grade === 0">{{ $t('commonLeaderboardAllMsg')}}</template>
                        <template v-else><span class="hidden-mobile">{{ $t('commonLeaderboardGradeAbbreviationMsg')}}</span>{{grade}}</template>
                    </span>
                </li>
            </ul>
            <img v-if="gradesToShow.length > 6" v-bind:src="hover === 'right' ? rightArrowHover : rightArrow" v-on:mouseover="hover = 'right'" v-on:mouseleave="hover = null" class="leaderboard__grade-selector-arrow" alt="$t('commonLeaderboardSlideRightMsg')" v-on:click="slideSelectorRight()"/>
        </div>
        <table class="board">
            <thead>
                <th class="board-hdr__hll"></th>
                <th class="board-hdr__num board-hdr--nostretch">#</th>
                <th class="board-hdr__name">{{ $t('commonLeaderboardNameMsg')}}</th>
                <th class="board-hdr__award board-hdr--nostretch"><img src="../assets/star.svg" alt="$t('commonLeaderboardTotalAwardsMsg')"/></th>
                <th class="board-hdr__award board-hdr--nostretch"><img src="../assets/crown.svg" alt="$t('commonLeaderboardCorrectAwardsMsg')"/></th>
                <th class="board-hdr__award board-hdr--nostretch"><img src="../assets/cup.svg" alt="$t('commonLeaderboardStreakAwardsMsg')"/></th>
                <th class="board-hdr__score board-hdr--nostretch">{{ $t('commonLeaderboardScoreMsg')}}</th>
                <th class="board-hdr__hlr"></th>
            </thead>
            <template v-if="loadingLeaderboard">
                <tr class="board__loading">
                    <td colspan="8">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </td>
                </tr>
            </template>
            <template v-if="!loadingLeaderboard">
                <tr v-for="(result, index) in leaderboard"
                    v-bind:key="index"
                    v-bind:class="{'board__row--selected': result.smId == user.getMetricsID(), 'board__row--above-selected': isRowAboveSelected(index)}" >
                    <td></td>
                    <td class="board__num">{{result.position}}</td>
                    <td class="board__name">
                        <template v-if="result.nickname">{{result.nickname}}</template>
                        <template v-else>{{result.firstName}}</template>
                    </td>
                    <td class="board__award">
                        <img v-if="result.totalAnswered >= 5 && result.totalAnswered < 25" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_BRONZE.png">
                        <img v-if="result.totalAnswered >= 25 && result.totalAnswered < 75" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_SILVER.png">
                        <img v-if="result.totalAnswered >= 75" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_GOLD.png">
                    </td>
                    <td class="board__award">
                        <img v-if="result.totalRight >= 3 && result.totalRight < 10" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_BRONZE.png">
                        <img v-if="result.totalRight >= 10 && result.totalRight < 25" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_SILVER.png">
                        <img v-if="result.totalRight >= 25" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_GOLD.png">
                    </td>
                    <td class="board__award">
                        <img v-if="result.longestRightStreak >= 3 && result.longestRightStreak < 10" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_BRONZE.png">
                        <img v-if="result.longestRightStreak >= 10 && result.longestRightStreak < 15" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_SILVER.png">
                        <img v-if="result.longestRightStreak >= 15" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_GOLD.png">
                    </td>
                    <td class="board__score">{{result.score}}</td>
                    <td></td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";

// Our custom queries & mutations
import { getLeaderboard } from "../mytutor-graphql/queries";

// Visual components
import Spinner from '../components/Spinner.vue';

// Mixins
import {apiMixin} from "../mixins/APIMixin";

export default {
  name: 'Leaderboard',
  components: {
    Spinner
  },
  mixins: [apiMixin],
  props: {
      user: Object
  },
  data() {
      return {
        gradesToShow: [],
        gradesShown: [],
        loadingLeaderboard: true,
        leaderboard: [],
        selectedGrade: 0,
        leftArrow: require("../assets/arrow_left_outline.svg"),
        rightArrow: require("../assets/arrow_right_outline.svg"),
        leftArrowHover: require("../assets/arrow_left_solid.svg"),
        rightArrowHover: require("../assets/arrow_right_solid.svg"),
        hover: null,
      }
  },
  computed: {
    gradeTitle: function() {
        if (this.selectedGrade === 0) {
            return this.$t('commonLeaderboardAllLearnersMsg');
        } else {
            return this.$t('commonTestsGradeMsg') + " " + this.selectedGrade;
        }
    },
    hasGradeLeft: function() {
        return this.gradesToShow.indexOf(this.gradesShown[0]) > 0;
    },
    hasGradeRight: function() {
        return this.gradesToShow.indexOf(this.gradesShown[5]) > this.gradesToShow.length;
    },
  },
  methods: {
    // ----------------------------------------------------------------------------
    //
    // API calls
    //
    // ----------------------------------------------------------------------------
    fetchLeaderboardFromServer: async function(user) {
      console.log('fetching leaderboard for user with ID: ' + user.getID());
      try {

        let metricsRet = null;
        if (this.selectedGrade > 0) {
            metricsRet = await API.graphql(graphqlOperation(getLeaderboard, { metricsID: user.getMetricsID(), grade: this.selectedGrade }));
        } else {
            metricsRet = await API.graphql(graphqlOperation(getLeaderboard, { metricsID: user.getMetricsID() }));
        }
        console.log(metricsRet);
        if (metricsRet.data.getLeaderboard !== null) {
            console.log("Leaderboard found");
            return metricsRet.data.getLeaderboard;
        } else {
            console.log("Error fetching leaderboard");
            this.logError("Fetching leaderboard", "No data returned", false);
            return null;
        }
      } catch (error) {
        this.logAPIError("Fetching leaderboard", error, false);
      }
    },
    // ----------------------------------------------------------------------------
    //
    // Leaderboard behaviour
    //
    // ----------------------------------------------------------------------------
    isRowAboveSelected: function(index) {
        if (index === 0) {
            return;
        }
        if (this.leaderboard && this.leaderboard[index-1] && this.leaderboard[index-1].smId == this.user.getMetricsID()) {
            return true;
        }
        return false;
    },
    selectGrade: async function(grade) {
        this.selectedGrade = grade;
        this.loadLeaderboard();
    },
    loadLeaderboard: async function() {
        console.log("Loading leaderboard");
        this.leaderboard.splice(0);
        this.loadingLeaderboard = true;

        this.leaderboard = await this.fetchLeaderboardFromServer(this.user);
        if (this.leaderboard.length === 10 && this.leaderboard[3].position > 4) {
            this.leaderboard.splice(3, 0, {
                position: "...",
                nickname: "...",
                totalAnswered: 0,
                totalRight: 0,
                longestRightStreak: 0,
                score: "..."
            });
        }

        this.loadingLeaderboard = false;
    },
    slideSelectorLeft: function() {
        console.log("shifting left");
        let indexInGrades = this.gradesToShow.indexOf(this.gradesShown[0]);
        console.log(indexInGrades);
        if (indexInGrades > 0) {
            this.gradesShown = this.gradesToShow.slice(indexInGrades-1, indexInGrades+5);
        }
    },
    slideSelectorRight: function() {
        console.log("shifting right. last = " + this.gradesShown[5]);
        let indexInGrades = this.gradesToShow.indexOf(this.gradesShown[5]);
        console.log(indexInGrades);
        if (indexInGrades < this.gradesToShow.length-1) {
            this.gradesShown = this.gradesToShow.slice(indexInGrades-4, indexInGrades+2);
        }
    }
  },
  mounted() {
    console.log("Mounted leaderboard");
    if (this.user.getGrade() !== null) {
        this.selectedGrade = this.user.getGrade();
    } else {
        this.selectedGrade = 0;
    }
    if (!this.user.isTeacher()) {
        switch (this.selectedGrade) {
            case 1:
            case 2:
                this.gradesToShow = [0, 1, 2, 3, 4, 5];
                break;
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                this.gradesToShow = [0, 3, 4, 5, 6, 7];
                break;
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            default:
                this.gradesToShow = [0, 8, 9, 10, 11, 12];
                break;
        }
        this.gradesShown = this.gradesToShow.slice();
    } else {
        this.gradesToShow = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.gradesShown = this.gradesToShow.slice(0, 6);
    }
    this.loadLeaderboard();
  }
}
</script>

<style scoped>
    .leaderboard {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: #1C1A4F;
        padding: 34px 40px;
        overflow-y: auto;
    }

    .close {
        text-align: right;
        color: white;
        line-height: 23px;
        font-size: 46px;
    }

    .close:hover {
        cursor: pointer;
    }

    .flag {
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .title {
        color: white;
        z-index: 1000;
        text-align: left;
        /* font-size: 46px; */
    }

    .title__flag {
        padding-left: 10px;
        display: inline-block;
        vertical-align: top;
        padding-top: 6px;
    }

    .title__flag-top {
        font: normal normal bold 25px/25px Inter !important;
        letter-spacing: 0.5px;
    }

    .title__flag-10 {
        letter-spacing: 0.92px;
    }

    .title__heading {
        margin-top: 20px;
        padding-left: 10%;
        display: inline-block;
    }

    .title span {
        display: block;
        text-align: left;
        font: normal normal 600 46px/48px Inter;
    }

    .title span.grade {
        font: normal normal 300 46px/48px Inter;
    }

    .leaderboard__grade-selector {
        margin-top: 15%;
        display: flex;
        flex-direction: row;
    }

    .leaderboard__grade-selector-arrow {
        width: 56px !important;
    }

    .leaderboard__grade-selector ul {
        flex: 1;
        list-style-type: none;
        background-color: #14123E;
        border-radius: 33px;
        padding: 10px 16px;
        margin: 0 23px;
        overflow: hidden;
        display: table;
    }

    .leaderboard__grade-selector li {
        text-align: center;
        font: normal normal normal 20px/24px Inter;
        letter-spacing: 0.6px;
        color: #B8BABD;
        display: table-cell;
        text-align: center;
    }

    .leaderboard__grade-selector li > span {
        display: inline-block;
        box-sizing: border-box;
        border: #14123E 2px solid;
        padding: 10px 20px;
        width: 80px;
        height: 50px;
    }

    .leaderboard__grade-selector li > span:hover {
        cursor: pointer;
        border: #5469F3 2px solid;
        box-shadow: 2px 2px 2px #00000066;
        border-radius: 23px;
    }

    .leaderboard__grade-selector--selected {
        background: #5469F3 0% 0% no-repeat padding-box;
        box-shadow: 2px 2px 2px #00000066;
        border-radius: 23px;
    }

    .board {
        margin-top: 10%;
        width: 100%;
        color: white;
        border-collapse: collapse;
        table-layout: auto;
    }

    .board th {
        padding: 10px 3px;
        color: #B8BABD;
        text-align: left;
        font: normal normal medium Inter;
        font-size: 14px;
        line-height: 32px;
    }

    .board td {
        padding: 17px 3px;
        text-align: left;
        font: normal normal medium Inter;
        font-size: 20px;
        line-height: 32px;
        border-top: 1px solid #363374;
        box-shadow: 0 -1px 0 0 black;
    }

    .board td:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .board td:first-child, .board td:last-child {
        border-top: none;
        box-shadow: none;
    }

    .board-hdr__hll, .board-hdr__hlr {
        min-width: 40px;
        width: 40px;
    }

    .board-hdr__num {
        min-width: 20px;
        width: 20px;
    }

    .board-hdr--nostretch {
        white-space: nowrap;
    }

    .board-hdr__award {
        width: 50px !important;
        min-width: 50px !important;
        text-align: center !important;
    }

    .board-hdr__score {
        min-width: 28px;
        width: 28px;
        text-align: right !important;
    }

    .board__name {
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .board__award img {
        width: 36px;
        display: block;
        margin: auto;
    }

    .board__score {
        text-align: right !important;
        font: normal normal bold 20px/32px Inter;
    }

    tr.board__row--selected td {
        background: #5469F3 0% 0% no-repeat padding-box;
        box-shadow: 2px 2px 2px #00000066;
        border-top: none;
    }

    tr.board__row--selected td:first-child {
        border-color: #1C1A4F;
        border-radius: 70px 0 0 70px;
    }

    tr.board__row--selected td:last-child {
        border-color: #1C1A4F;
        border-radius: 0 70px 70px 0;
    }

    tr.board__row--above-selected td {
        box-shadow: none;
        border-top: none;
    }

    @media screen and (max-width:800px) {
        .leaderboard__grade-selector-arrow {
            width: 40px !important;
        }

        .leaderboard__grade-selector ul {
            border-radius: 22px;
            padding: 8px 12px;
            margin: 0 15px;
        }

        .leaderboard__grade-selector li > span {
            padding: 5px 5px;
            height: 40px;
            width: 50px;
        }

    }
    @media screen and (max-width:600px) {
        .leaderboard {
            padding: 15px 10px;
        }

        .flag {
            top: -50px;
        }

        .title__flag {
            padding-left: 20px;
        }

        .title__flag-top {
            font: normal normal bold 16px/16px Inter !important;
        }

        .title__heading {
            padding-left: 15%;
        }

        .title span {
            font: normal normal 600 30px/32px Inter;
        }

        .title span.grade {
            font: normal normal 300 30px/32px Inter;
        }

        .leaderboard__grade-selector-arrow {
            width: 40px !important;
        }

        .leaderboard__grade-selector ul {
            border-radius: 22px;
            padding: 5px 8px;
        }

        .leaderboard__grade-selector li {
            text-align: center;
            font: normal normal normal 14px/18px Inter;
        }

        .leaderboard__grade-selector li > span {
            padding: 5px 5px;
            height: 32px;
            width: 40px;
        }

        .board th {
            padding: 8px 3px;
            font-size: 12px;
        }

        .board td {
            padding: 8px 3px;
            font-size: 14px;
        }

        .board-hdr__hll, .board-hdr__hlr {
            min-width: 20px;
            width: 20px;
        }

        .board-hdr__num {
            min-width: 20px;
            width: 20px;
        }

        .board-hdr__award, .board__award {
            width: 34px !important;
            min-width: 34px !important;
        }

        .board-hdr__score {
            min-width: 28px;
            width: 28px;
        }

        .board__award img {
            width: 28px;
        }
    }

    @media screen and (max-width:414px) {
        .leaderboard__grade-selector-arrow {
            width: 30px !important;
            height: 30px !important;
        }

        .leaderboard__grade-selector ul {
            border-radius: 22px;
            padding: 5px 8px;
            margin: 0 5px;
        }

        .leaderboard__grade-selector li span {
            width: 35px;
        }

        .hidden-mobile {
            display: none !important;
        }
    }

    @media screen and (max-width:375px) {
        .leaderboard__grade-selector-arrow {
            width: 25px !important;
        }

        .leaderboard__grade-selector ul {
            border-radius: 22px;
            padding: 5px 8px;
            margin: 0 5px;
        }

        .leaderboard__grade-selector li span {
            width: 32px;
        }

        .hidden-mobile {
            display: none !important;
        }
    }

</style>