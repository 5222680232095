<template>
    <div class="leaderboard">
        <div class="flag"><img src="../assets/leaderboard-flag.svg" alt="Leaderboard flag"/></div>
        <div class="close" v-on:click="$root.$emit('close-my-students')">&times;</div>
        <div class="title">
            <div class="title__flag">
            </div>
            <div class="title__heading">
                <span class="grade">{{gradeTitle}}</span>
                <span>{{ $t('myStudentsTitleMsg')}}</span>
            </div>
        </div>
        <div class="leaderboard__grade-selector">
            <img v-if="gradesToShow.length > 6" v-bind:src="hover === 'left' ? leftArrowHover : leftArrow"
                 v-on:mouseover="hover = 'left'" v-on:mouseleave="hover = null"
                 class="leaderboard__grade-selector-arrow" alt="$t('commonLeaderboardSlideLeftMsg')"
                 v-on:click="slideSelectorLeft()"/>
            <ul class="leaderboard__grade-selector-list">
                <li v-for="(grade, index) in gradesShown" v-bind:key="index" v-on:click="selectGrade(grade)">
                    <span v-bind:class="{'leaderboard__grade-selector--selected': selectedGrade === grade}">
                        <template v-if="grade === 0">{{ $t('commonLeaderboardAllMsg')}}</template>
                        <template v-else><span class="hidden-mobile">{{ $t('commonLeaderboardGradeAbbreviationMsg')}}</span>{{grade}}</template>
                    </span>
                </li>
            </ul>
            <img v-if="gradesToShow.length > 6" v-bind:src="hover === 'right' ? rightArrowHover : rightArrow"
                 v-on:mouseover="hover = 'right'" v-on:mouseleave="hover = null"
                 class="leaderboard__grade-selector-arrow" alt="$t('commonLeaderboardSlideRightMsg')"
                 v-on:click="slideSelectorRight()"/>
        </div>
        <div class="board-heading-tap-on-name">
            <ul>
                <li>
                    Tap on a learner's name to view their profile
                </li>
                <li>
                    Learners missing? Share this link to track their progress: {{userLinkURL}}
                    <img v-if="!showLinkCopied" v-on:click="copyToClipboard" src="../assets/copy.svg" alt="$t('commonCopyToClipboardMsg')"/>
                    <span v-if="showLinkCopied">{{ $t('commonCopiedMsg')}}</span>
                </li>
            </ul>
        </div>
        <table class="board" ref="learnerList">
            <thead>
            <th class="board-hdr__hll"></th>
            <th class="board-hdr__num board-hdr--nostretch"></th>
            <th class="board-hdr__name"></th>
            <th class="board-hdr__total board-hdr--nostretch board-hdr--vert-left board-hdr--vert-right" colspan="2">
                {{ $t('myStudentsLast7DaysMsg')}}
            </th>
            <th class="board-hdr__total board-hdr--nostretch board-hdr--vert-right" colspan="2">{{ $t('myStudentsLastTodayMsg')}}</th>
            <th class="board-hdr__score board-hdr--nostretch"></th>
            <th class="board-hdr__hlr"></th>
            </thead>
            <thead>
            <th class="board-hdr__hll"><input type="checkbox" v-model="selectAll" v-on:change="selectOrDeselectAll"></th>
            <th class="board-hdr__num board-hdr--nostretch">#</th>
            <th class="board-hdr__name">{{ $t('commonLeaderboardNameMsg')}}</th>
            <th class="board-hdr__total board-hdr--nostretch board-hdr--vert-left">{{ $t('commonLeaderboardDoneMsg')}}</th>
            <th class="board-hdr__total board-hdr--nostretch board-hdr--vert-right">&#10003;</th>
            <th class="board-hdr__total board-hdr--nostretch">{{ $t('commonLeaderboardDoneMsg')}}</th>
            <th class="board-hdr__total board-hdr--nostretch board-hdr--vert-right">&#10003;</th>
            <th class="board-hdr__score board-hdr--nostretch">{{ $t('commonLeaderboardScoreMsg')}}</th>
            <th class="board-hdr__hlr"></th>
            </thead>
            <template v-if="loadingMyStudents">
                <tr class="board__loading">
                    <td colspan="8">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </td>
                </tr>
            </template>
            <template v-if="!loadingMyStudents">
                <tr v-for="(result, index) in myStudents" v-bind:key="index">
                    <td><input type="checkbox" v-model="result.selected"></td>
                    <td class="board__num">{{index+1}}</td>
                    <td class="board__name"
                        v-on:mouseover="hoverOverStudent = index"
                        v-on:mouseleave="hoverOverStudent = null"
                        v-bind:colspan="nameColspan(index)"
                        v-on:click="openFriendProfile(result.id)">
                        {{result.student}}
                    </td>
                    <td class="board__total board-hdr--vert-left" v-show="index !== hoverOverStudent">{{result.performanceLast7Days.totalAnswered}}</td>
                    <td class="board__total" v-show="index !== hoverOverStudent">{{result.performanceLast7Days.totalRight}}</td>
                    <td class="board__total board-hdr--vert-left" v-show="index !== hoverOverStudent">{{result.performanceToday.totalAnswered}}</td>
                    <td class="board__total" v-show="index !== hoverOverStudent">{{result.performanceToday.totalRight}}</td>
                    <td class="board__score board-hdr--vert-left">{{result.score}}</td>
                    <td></td>
                </tr>
                <tr v-if="myStudents.length === 0">
                    <td></td>
                    <td colspan="7">{{ $t('myStudentsNoStudentsMsg')}}</td>
                    <td></td>
                </tr>
                <tr v-if="myStudents.length > 0 && !loadingMyStudents">
                    <td></td>
                    <td colspan="7" class="board__row--centered">
                        <template v-if="unlinking">
                            <spinner height="50px" colour="#F9AC1B"/>
                        </template>
                        <template v-else>
                            <div v-if="infoMessage">{{infoMessage}}</div>
                            <div v-if="errorMessage" class="text-error">{{errorMessage}}</div>
                            <button v-on:click="unlinkLearners" class="learners__un-link">{{ $t('myStudentsUnlinkMsg')}}</button>
                        </template>
                    </td>
                    <td></td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
    import {API, graphqlOperation} from "@aws-amplify/api";

    import user from '../domain/user';

    // Our custom queries & mutations
    import {getMyStudents, getFriendProfile} from "../mytutor-graphql/queries";
    import {unlinkUsers} from "../mytutor-graphql/mutations";

    // Visual components
    import Spinner from './Spinner.vue';

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    export default {
        name: 'MyStudents',
        components: {
            Spinner
        },
        mixins: [apiMixin],
        props: {
            user: Object
        },
        data() {
            return {
                loadingMyStudents: true,
                myStudents: [],
                gradesToShow: [],
                gradesShown: [],
                selectedGrade: 0,
                leftArrow: require("../assets/arrow_left_outline.svg"),
                rightArrow: require("../assets/arrow_right_outline.svg"),
                leftArrowHover: require("../assets/arrow_left_solid.svg"),
                rightArrowHover: require("../assets/arrow_right_solid.svg"),
                showLinkCopied: false,
                selectAll: false,
                hover: null,
                hoverOverStudent: null,
                unlinking: false,
                infoMessage: "",
                errorMessage: "",
            }
        },
        computed: {
            gradeTitle: function () {
                if (this.selectedGrade === 0) {
                    return this.$t('commonLeaderboardAllLearnersMsg');
                } else {
                    return this.$t('commonTestsGradeMsg') + " " + this.selectedGrade;
                }
            },
            hasGradeLeft: function () {
                return this.gradesToShow.indexOf(this.gradesShown[0]) > 0;
            },
            hasGradeRight: function () {
                return this.gradesToShow.indexOf(this.gradesShown[5]) > this.gradesToShow.length;
            },
            userLinkURL: function() {
                return document.location.origin + "/?tr=" + this.user.getLinkCode();
            },
        },
        methods: {
            // ----------------------------------------------------------------------------
            // 
            // API calls
            //
            // ----------------------------------------------------------------------------
            fetchMyStudentsFromServer: async function (user) {
                console.log('fetching students for teacher with ID: ' + user.getID());
                try {
                    const timezoneOffsetHours = -new Date().getTimezoneOffset() / 60;
                    let performanceRet = null;
                    if (this.selectedGrade > 0) {
                        performanceRet = await API.graphql(graphqlOperation(getMyStudents, {
                            teacherID: user.getID(),
                            grade: this.selectedGrade,
                            timezoneOffsetHours: timezoneOffsetHours
                        }));
                    } else {
                        performanceRet = await API.graphql(graphqlOperation(getMyStudents, {
                            teacherID: user.getID(),
                            timezoneOffsetHours: timezoneOffsetHours
                        }));
                    }
                    console.log(performanceRet);
                    if (performanceRet.data.getMyStudents !== null) {
                        console.log("Students found");
                        return performanceRet.data.getMyStudents;
                    } else {
                        console.log("Error fetching students");
                        this.logError("Fetching students", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching students", error, false);
                }
            },
            unlinkUsers: async function (userID, friendIDs) {
                console.log('unlinking students for teacher with ID: ' + userID);
                console.log(friendIDs);
                try {
                    const item = {
                        userID: userID,
                        friendIDs: friendIDs
                    };
                    const unlinkUsersRet = await API.graphql(graphqlOperation(unlinkUsers, item ));
                    console.log(unlinkUsersRet);
                    return unlinkUsersRet.data.unlinkUsers;
                } catch (error) {
                    this.logAPIError("Unlinking students from teacher", error, false);
                }
            },
            fetchFriendProfile: async function (friendID) {
                console.log('fetching profile for user with ID: ' + friendID);
                try {
                    this.loadingMyStudents = true;
                    const item = {
                        friendID: friendID
                    };
                    const friendProfileRet = await API.graphql(graphqlOperation(getFriendProfile, item ));
                    this.loadingMyStudents = false;
                    if (friendProfileRet.data.getFriendProfile !== null) {
                        console.log("Friend found");
                        console.log(friendProfileRet.data.getFriendProfile);
                        return friendProfileRet.data.getFriendProfile;
                    } else {
                        console.log("Error fetching friend profile");
                        this.logError("Fetching friend profile", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching friend profile", error, false);
                }
            },

            // ----------------------------------------------------------------------------
            // 
            // MyStudents behaviour
            //
            // ----------------------------------------------------------------------------
            selectGrade: async function (grade) {
                this.selectedGrade = grade;
                this.loadMyStudents();
            },
            nameColspan: function(index) {
                if (this.hoverOverStudent === index) {
                    return 5;
                } else {
                    return 1;
                }
            },
            copyToClipboard: function() {
                console.log("copying to clipboard");
                const el = document.createElement('textarea');
                el.value = this.userLinkURL;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showLinkCopied = true;
                setTimeout(() => {
                    this.showLinkCopied = false;
                }, 2000);
            },
            selectOrDeselectAll: function() {
                for (let learner of this.myStudents) {
                    learner.selected = this.selectAll;
                }
            },
            loadMyStudents: async function () {
                console.log("Loading MyStudents");
                this.loadingMyStudents = true;
                this.myStudents = await this.fetchMyStudentsFromServer(this.user);
                // safety, in case an error occurs
                if (!this.myStudents) {
                    this.myStudents = [];
                }
                this.loadingMyStudents = false;
            },
            slideSelectorLeft: function () {
                console.log("shifting left");
                let indexInGrades = this.gradesToShow.indexOf(this.gradesShown[0]);
                console.log(indexInGrades);
                if (indexInGrades > 0) {
                    this.gradesShown = this.gradesToShow.slice(indexInGrades - 1, indexInGrades + 5);
                }
            },
            slideSelectorRight: function () {
                console.log("shifting right. last = " + this.gradesShown[5]);
                let indexInGrades = this.gradesToShow.indexOf(this.gradesShown[5]);
                console.log(indexInGrades);
                if (indexInGrades < this.gradesToShow.length - 1) {
                    this.gradesShown = this.gradesToShow.slice(indexInGrades - 4, indexInGrades + 2);
                }
            },
            unlinkLearners: async function () {
                this.unlinking = true;
                this.errorMessage = "";
                this.infoMessage = "";
                let selectedLearnerIDs = [];
                for (let learner of this.myStudents) {
                    if (learner.selected) {
                        selectedLearnerIDs.unshift(learner.id);
                    }
                }
                const success = await this.unlinkUsers(this.user.getID(), selectedLearnerIDs);
                if (success) {
                    for (let revIndex = this.myStudents.length - 1; revIndex >= 0; revIndex--) {
                        if (this.myStudents[revIndex].selected) {
                            this.myStudents.splice(revIndex, 1);
                        }
                    }
                    this.infoMessage = this.$t('myStudentsUnlinkedPrefixMsg') + " " + selectedLearnerIDs.length + " " + this.$t('myStudentsUnlinkedSuffixMsg');
                } else {
                    this.errorMessage = this.$t('myStudentsUnlinkingErrorMsg');
                }
                this.unlinking = false;
            },
            openFriendProfile: async function (friendID) {
                console.log("Friend ID: " + friendID);
                const friendProfile = await this.fetchFriendProfile(friendID);
                let friendUser = user(friendProfile);
                friendUser.metrics = friendProfile.metrics;
                // friendProfile.getLinkCode = function() { return friendProfile.linkCode; };
                // friendProfile.hasNickname = function() { return friendProfile.linkCode; };
                console.log("Friend profile: ");
                console.log(friendUser);
                this.$root.$emit('show-friend-profile', friendUser);
                // this.$root.$emit('close-my-students');
            }
        },
        mounted() {
            console.log("Mounted MyStudents");
            this.loadMyStudents();
            this.gradesToShow = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            this.gradesShown = this.gradesToShow.slice(0, 6);
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/pages/my-students";

    .board__name:hover {
        cursor: pointer;
    }
</style>