<template>
    <div class="slideshow">
        <div class="slideshow-container">
            <div class="slide fade" v-for="(image, index) in images" v-bind:key="index" @mousedown.prevent="mouseDown" @mouseup="mouseUp" @touchstart="touchStart" @touchend="touchEnd">
                <!-- Handle link for Daily Puzzle advert-->
                <template v-if="image.endsWith('advert.jpg') || image.endsWith('advert.png')">
                    <a href="https://mytutor.chat/event-entry/?aff=DailyPuzzle" target="_blank">
                        <img v-bind:src="image" v-show="index == slideIndex">
                    </a>
                </template>
                <template v-else-if="image.endsWith('advert_register.jpg') || image.endsWith('advert_register.png')">
                    <a href="https://mytutor.chat/register" target="_blank">
                        <img v-bind:src="image" v-show="index == slideIndex">
                    </a>
                </template>
                <template v-else>
                    <img v-bind:src="image" v-show="index == slideIndex">
                </template>
            </div>
            <a class="prev" v-on:click.stop="plusSlides(-1)" v-show="slideIndex > 0">&#10094;</a>
            <a class="next" v-on:click.stop="plusSlides(1)" v-show="slideIndex < images.length-1">&#10095;</a>
        </div>
        <div class="dot-container">
            <span v-for="(image, index) in images" v-bind:key="index" class="dot" v-on:click.stop="currentSlide(index)" v-bind:class="{'active': slideIndex == index}"></span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    images: Array,
    parentID: String
  },
  data() {
    return {
        slideIndex: 0,
        touchStartX: null
    }
  },
  methods: {
    mouseDown: function(event) {
        this.dragStart(event.clientX);
    },
    mouseUp: function(event) {
        this.dragEnd(event.clientX);
    },
    touchStart: function(event) {
        this.dragStart(event.changedTouches[0].clientX);
    },
    touchEnd: function(event) {
        this.dragEnd(event.changedTouches[0].clientX);
    },
    dragStart: function(startX) {
        this.touchStartX = startX;
    },
    dragEnd: function(endX) {
        if (Math.abs(this.touchStartX - endX) > 50) { 
            var isLeft = this.touchStartX > endX;
            if (isLeft) {
                this.plusSlides(1);
            } else {
                this.plusSlides(-1);
            }
        }
        this.touchStartX = 0;
    },
    plusSlides: function(n) {
        console.log("plusSlides: " + n);
        this.slideIndex += n;
        if (this.slideIndex >= this.images.length) {
            this.slideIndex = 0;
        }
        if (this.slideIndex < 0) {
            this.slideIndex = this.images.length-1;
        }
        this.$root.$emit('slider-slid', this.parentID, this.images[this.slideIndex]);
    },
    currentSlide: function (n) {
        console.log("currentSlide: " + n);
        this.slideIndex = n;
        this.$root.$emit('slider-slid', this.parentID, this.images[this.slideIndex]);
    }
  }
}
</script>

<style scoped>
    * {
        box-sizing:border-box
    }

    .slideshow {
        position: relative;
    }

    .slideshow-container {
        max-width: 1000px;
        position: relative;
        margin: auto;
    }

    .slideshow-container img {
        width: 100%;
    }

    .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 16px;
        color: #1C1A4F;
        font-weight: bold;
        font-size: 22px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
    }

    .next {
        right: -20px;
        border-radius: 3px 0 0 3px;
    }

    .prev {
        left: -20px;
    }

    .dot-container {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -35px;
        left: 0;
    }

    .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        margin: 0 8px;
        background-color: #B8BABD;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

    .active, .dot:hover {
        background-color: #1C1A4F;
    }

    .fade {
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1.5s;
        animation-name: fade;
        animation-duration: 1.5s;
    }

    @-webkit-keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }
</style>