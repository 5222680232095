<template>
  <blank-layout>
    <div class="daily-puzzle__wrapper">
        <daily-puzzle class="section-puzzle__puzzle"></daily-puzzle>
    </div>
  </blank-layout>
</template>

<script>
  import BlankLayout from '../layouts/Blank.vue'
  import DailyPuzzle from '../components/DailyPuzzle.vue';

  export default {
    components: {
      BlankLayout, DailyPuzzle
    },
    mounted: async function() {
      document.body.style.background = "none";
      console.log(document.body.style);
    }
  }
</script>

<style scoped>
</style>