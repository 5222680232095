<template>
  <main-layout>
    <div class="cta">
      <a href="https://mytutor.chat"><button class="btn btn-primary">{{ $t('talentSearchCTAMsg')}}</button></a>
    </div>
    <img src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/SAMFTalentSearchFlyer.jpeg"/>
    <div class="cta">
      <a href="https://mytutor.chat"><button class="btn btn-primary">{{ $t('talentSearchCTAMsg')}}</button></a>
      <br/>
      <a href="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/MyTutor.chat+Introduction+-+learner.pdf" target="__blank">
        <button class="btn btn-secondary">{{ $t('commonWhatIsMyTutorMsg')}}</button>
      </a>
    </div>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>

<style scoped>
  img {
    width: 100%;
    margin-bottom: 30px;
  }

  @media screen and (max-width:414px) {
    img {
      margin-bottom: 15px;
    }
  }

</style>