<template>
    <div class="form-entry">
        <h1>{{ $t('openTestEntryTitleMsg')}}</h1>
        <div class="progress" v-bind:class="{'progress--error' : showError}">
            <progress v-bind:value="progress" max="100"/>
            <div class="progress__message">
                {{progressMessage}}
                <template v-if="showError"><br/><br/>{{ $t('openTestEntryErrorMsg')}}</template>
            </div>
        </div>
        <spinner v-if="!showError" height="50px" colour="#FFF"/>
    </div>
</template>

<script>
    import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
    import {Auth} from "@aws-amplify/auth";

    // Visual components
    import Spinner from '../components/Spinner';

    // Our custom queries & mutations
    import {openTestEntryForUserWithoutAccount} from "../mytutor-graphql/mutations";

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    export default {
        name: 'OpenTestEntry',
        components: {Spinner},
        mixins: [apiMixin],
        data() {
            return {
                progress: 0,
                progressMessage: "",
                showError: false
            }
        },
        computed: {
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            getTestRegistrationAndAccountDetails: async function(testEntryID) {
                console.debug("getting test entry and account details");
                try {
                    let variables = {
                        testEntryID: testEntryID
                    };
                    const detailsRet = await API.graphql({
                        query: openTestEntryForUserWithoutAccount,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(detailsRet);
                    if (detailsRet.data.openTestEntryForUserWithoutAccount !== null) {
                        console.debug("Test entry and account details found");
                        return detailsRet.data.openTestEntryForUserWithoutAccount;
                    } else {
                        console.debug("Error opening test entry");
                        this.logError("Opening test entry", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Opening test entry", error, true);
                    if (error.errors && error.errors[0] && error.errors[0].message) {
                        throw new Error(error.errors[0].message);
                    } else {
                        throw new Error("Unspecified error occurred");
                    }
                }
            },
            // ----------------------------------------------------------------------------
            //
            // Account behaviour
            //
            // ----------------------------------------------------------------------------
            openTestForUserWithoutAccount: async function () {
                console.debug("opening test entry");

                this.progress = 25;
                this.progressMessage = this.$t('openTestEntryProgressLoadingMsg');

                try {
                    const testAndAccountDetails = await this.getTestRegistrationAndAccountDetails(this.testEntryID);
                    if (testAndAccountDetails) {
                        this.progress = 50;
                        this.progressMessage = this.$t('openTestEntryProgressAuthorisingMsg');

                        if (testAndAccountDetails.accountType === 'NO_ACCOUNT_USER') {
                            // manually sign in
                            console.debug("Auto login");
                            try {
                                const user = await Auth.signIn(testAndAccountDetails.email, testAndAccountDetails.password);
                                console.debug("Signed in with Auth");
                                // tell App that we've signed in
                                this.$root.$emit('manual-sign-in', user);
                            } catch (error) {
                                console.debug('Error signing in', error);
                            }

                            this.progress = 75;
                            this.progressMessage = this.$t('openTestEntryProgressHouseKeepingMsg');

                            // wait till App has handled the sign-in
                            const self = this;
                            this.$root.$on('signed-in', () => {
                                self.progress = 100;
                                self.progressMessage = this.$t('openTestEntryProgressOpeningTestMsg');

                                setTimeout(() => {
                                    console.debug("Display link to go to Test");
                                    this.$router.push({path: "/test/" + testAndAccountDetails.testHostID + "/" + testAndAccountDetails.testEntryID});
                                }, 500);
                            });
                        } else {
                            // send to login page
                            this.$router.push({ name: 'signin', query: { redirect: "/test/" + testAndAccountDetails.testHostID + "/" + testAndAccountDetails.testEntryID } } );
                        }
                    } else {
                        this.showError = true;
                        this.progressMessage = this.$t('openTestEntryProgressErrorOpeningMsg');
                    }
                } catch (error) {
                    this.showError = true;
                    this.progressMessage = this.$t('openTestEntryProgressErrorPrefixMsg') + error.message;
                }
            },
        },
        mounted: async function () {
            console.debug("opening anonymous test entry");
            this.testEntryID = this.$route.params.testEntryID;

            console.debug("test entry ID: " + this.testEntryID);
            if (this.testEntryID) {
                this.openTestForUserWithoutAccount();
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
</style>