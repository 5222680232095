import dayjs from 'dayjs';
import { isDateInPast, getDisplayDate } from "../services/utils";

export const testsMixin = {
    data() {
        return {
            loadingTests: true,
            showAvailable: true,

            futureTests: [],
            currentTests: [],
            pastTests: [],

            listOfAvailableTestsAndEntries: [],
            listOfDoneTestEntries: [],

            selectedTest: null,
            leaderboardTest: null,
            notifications: [],
            showNotifications: false
        }
    },
    computed: {
        isTeacher: function () {
            return this.user.isTeacher();
        },
        isParent: function () {
            return this.user.isParent();
        },
        hasCurrentTest: function() {
            return this.currentTests && this.currentTests.length > 0;
        }
    },
    methods: {
        resetTestsLists: function() {
            this.futureTests = [];
            this.currentTests = [];
            this.pastTests = [];
        },
        sortCurrentAndFutureTests: function() {
            this.futureTests = this.sortTestsAndEntriesByName(this.futureTests);
            this.currentTests = this.sortTestsAndEntriesByName(this.currentTests);
        },
        addTestAndTestEntriesAndDivideIntoPastCurrentAndFuture: function (testsHosting,
                                                                          nonInvigilatedTests,
                                                                          testEntries,
                                                                          allowMultipleEntriesForSameTest,
                                                                          selectFirst10Tests) {
            const startOfToday = dayjs().startOf('day');
            const endOfToday = dayjs().endOf('day');

            // first split into available and done, as this removes tests that have been entered or done
            this.addTestAndTestEntriesTAndDivideIntoAvailableAndDone(
                testsHosting,
                nonInvigilatedTests,
                testEntries,
                allowMultipleEntriesForSameTest,
                selectFirst10Tests);

            // divide by when the test took place or takes place - all about PARTICIPATION or SCHEDULED dates
            let allTests = [];
            allTests.push(...this.listOfAvailableTestsAndEntries);
            allTests.push(...this.listOfDoneTestEntries);

            this.resetTestsLists();

            for (let test of allTests) {
                if (test.test){
                    // FUTURE (show entered and not yet entered):
                    // - non-invigilated tests for which registration has started but not participation [TEACHER & ENTRANT]
                    // - tests (invigilated) you have scheduled that start in the future [TEACHER]
                    // - scheduled (invigilated) tests you have entered that are starting in the future [ENTRANT]
                    if (endOfToday.isBefore(dayjs(test.questionSet.participationStart))) {
                        this.futureTests.push(test);
                    }
                    else if (test.test.date && endOfToday.isBefore(dayjs(test.test.date))) {
                        this.futureTests.push(test);
                    }

                    // PAST
                    // - tests (invigilated) you have scheduled for which the scheduled test date was before today [TEACHERS]
                    // - test entries finished before today [TEACHER & ENTRANT]
                    else if (test.testEntry === null
                            && test.questionSet.invigilation !== "NO_INVIGILATOR"
                            && test.test.date
                            && startOfToday.isAfter(dayjs(test.test.date))) {
                        this.pastTests.push(test);
                    }
                    else if (test.testEntry && test.testEntry.finishedAt && startOfToday.isAfter(dayjs(test.testEntry.finishedAt))) {
                        this.pastTests.push(test);
                    }

                    // CURRENT
                    // - non-invigilated tests for which registration has started and participation is open (hidden if you
                    //      have an unfinished entry) [TEACHER & ENTRANT]
                    // - tests (invigilated) you have scheduled that start today [TEACHERS]
                    // - test entries not started but for which participation is allowed [TEACHER & ENTRANT]
                    // - test entries started today [TEACHER & ENTRANT]
                    else {
                        this.currentTests.push(test);
                    }
                }
            }

            this.futureTests = this.sortTestsAndEntriesByName(this.futureTests);
            this.currentTests = this.sortTestsAndEntriesByName(this.currentTests);
            this.pastTests = this.sortTestsAndEntriesByDateAndName(this.pastTests);
        },
        addTestAndTestEntriesTAndDivideIntoAvailableAndDone: function (testHosting,
                                                                       nonInvigilatedTests,
                                                                       testEntries,
                                                                       allowMultipleEntriesForSameTest,
                                                                       selectFirst10Tests) {
            const now = dayjs();

            // marshall the testEntries into out combined format
            testEntries = testEntries.map(te => this.marshalTestEntryIntoCombinedFormat(te));

            // sort test entries
            // testEntries = this.sortTestsAndEntries(testEntries);

            // listOfDoneTestEntries are entries have have been started and finished
            this.listOfDoneTestEntries = testEntries.filter((te) => {
                if (te.testEntry.startedAt) {
                    if ((te.testEntry.finishedAt && dayjs(te.testEntry.finishedAt).isBefore(now))
                        || (te.testEntry.scheduledToFinishAt && dayjs(te.testEntry.scheduledToFinishAt).isBefore(now))) {
                        return true;
                    }
                }
                return false;
            });

            // keep track of test entries that haven't been started, or have been started but not finished
            // but remove those for which the participation window has closed
            let testsEntered = testEntries.filter((te) => {
                if (!te.testEntry.startedAt) {
                    if (!te.test.questionSet.participationEnd || new Date() < new Date(te.test.questionSet.participationEnd)) {
                        return true;
                    }
                } else if (!te.testEntry.finishedAt) {
                    // invigilated dont have a scheduledToFinishAt property, only non-invigilated
                    if (!te.testEntry.scheduledToFinishAt ||  (te.testEntry.scheduledToFinishAt && dayjs(te.testEntry.scheduledToFinishAt).isAfter(now))) {
                        return true;
                    }
                }
                return false;
            });

            // marshall the test hosts into out combined format
            let tests = testHosting.map(t => this.marshalTestHostIntoListFormat(t));

            // and then non-invigilated tests (we need to exclude tests for which we are the host as they have been added)!
            const testsNotHosting = nonInvigilatedTests.filter((test) => {
                for (const addedTest of tests) {
                    if (test.id === addedTest.questionSet.id) {
                        return false;
                    }
                }
                return true;
            });
            tests.push(...testsNotHosting.map(t => this.marshalTestIntoListFormat(t)));

            // start listOfAvailableTestsAndEntries as the list of tests
            // but with any tests the entrant has done, or is already registered for filtered out
            this.listOfAvailableTestsAndEntries = tests.filter((t) => {
                // sanity check
                if (!t.test) {
                    return false;
                }
                // if we don't allow multiple entries for the same test, remove any tests that have been done already
                if (!allowMultipleEntriesForSameTest) {
                    for (const test of this.listOfDoneTestEntries) {
                        if (test.test.id === t.test.id) {
                            return false;
                        }
                    }
                }
                // remove any tests that have been entered already
                for (const test of testsEntered) {
                    if (test.test.id === t.test.id) {
                        return false;
                    }
                }
                return true;
            });

            if (selectFirst10Tests && this.listOfAvailableTestsAndEntries.length > 0) {
                this.listOfAvailableTestsAndEntries = this.listOfAvailableTestsAndEntries.slice(0, 10);
            }

            // and add the list of test entries that aren't done
            this.listOfAvailableTestsAndEntries.push(...testsEntered);
        },
        addScheduledTest(testHost) {
            const listEntry = this.marshalTestHostIntoListFormat(testHost);
            const endOfToday = dayjs().endOf('day');
            if (endOfToday.isBefore(dayjs(listEntry.test.date))) {
                this.futureTests.push(listEntry);
            } else {
                this.currentTests.push(listEntry);
            }
            this.notifications.push({
                message: "Your test " + testHost.test.name + " has been scheduled to take place on " + this.getDisplayDate(testHost.test.date),
            });
            this.showNotifications = true;
        },
        marshalTestIntoListFormat(questionSet) {
            let listEntry = {
                questionSet: questionSet,
                test: null,
                testHost: null,
                testEntry: null
            };
            if (questionSet.tests && questionSet.tests.items && questionSet.tests.items.length > 0) {
                listEntry.test = questionSet.tests.items[0];
                if (questionSet.tests.items[0].testHosts && questionSet.tests.items[0].testHosts.items && questionSet.tests.items[0].testHosts.items.length > 0) {
                    listEntry.testHost = questionSet.tests.items[0].testHosts.items[0];
                }
            }
            return listEntry;
        },
        marshalTestEntryIntoCombinedFormat(testEntry) {
            let listEntry = {
                questionSet: null,
                test: testEntry.test,
                testHost: testEntry.testHost,
                testEntry: testEntry
            };
            if (testEntry.test && testEntry.test.questionSet) {
                listEntry.questionSet = testEntry.test.questionSet;
            }
            return listEntry;
        },
        marshalTestHostIntoListFormat(testHost) {
            let listEntry = {
                questionSet: testHost.test.questionSet,
                test: testHost.test,
                testHost: testHost,
                testEntry: null
            };
            return listEntry;
        },
        updateTestDate: async function (testHostID, testID, dateString) {
            const success = await this.user.updateTestDateOnServer(testHostID, testID, new Date(dateString).toISOString());
            if (success) {
                this.selectedTest.test.date = dateString;
                this.selectedTest.testHost.testDate = dateString;
            }
        },
        sortTestsAndEntriesByName: function (listOfTestsAndEntries) {
            listOfTestsAndEntries = listOfTestsAndEntries.sort((a, b) => {
                return a.test.name.localeCompare(b.test.name);
            });
            return listOfTestsAndEntries;
        },
        sortTestsAndEntriesByDateAndName: function (listOfTestsAndEntries) {
            listOfTestsAndEntries = listOfTestsAndEntries.sort((a, b) => {
                let firstDate = (a.testEntry && a.testEntry.startedAt) ? a.testEntry.startedAt : a.test.date;
                let secondDate = (b.testEntry && b.testEntry.startedAt) ? b.testEntry.startedAt : b.test.date;
                if (dayjs(firstDate).isAfter(dayjs(secondDate))) {
                    return -1;
                } else if (dayjs(firstDate).isBefore(dayjs(secondDate))) {
                    return 1;
                } else {
                    return a.test.name.localeCompare(b.test.name);
                }
            });
            return listOfTestsAndEntries;
        },
        isDateInPast: function (dateString) {
            return isDateInPast(dateString);
        },
        getDisplayDate: function (dateString) {
            return getDisplayDate(dateString);
        },
        selectTest: async function (test) {
            console.log("selected test with testHostID: " + test.testHost.id);
            if (this.user.isTeacher() && test.questionSet.invigilation !== 'NO_INVIGILATOR') {
                console.log("Opening an invigilated test");
                this.selectedTest = test;
                this.$root.$emit('open-view-test-details');
            } else {
                this.openTest(test);
            }
        },
        registerForTest: function (test) {
            console.log("checking if the user needs to agree to T&Cs");
            if (test.termsAndConditionsURL) {
                console.log("user needs to agree to T&Cs");
                this.getConfirmationOfTAndCs(test);
            } else {
                this.registerAndCloseDialog(test);
            }
        },
        registerAndCloseDialog: function (test) {
            console.log("opening register link for test with testHostID: " + test.testHost.id);
            this.$nextTick(() => {
                this.$root.$emit('register-for-test', test.testHost.linkCode);
                this.closeDialog();
            });
        },
        copyTestInviteLink: function (test) {
            console.debug("Copying test link for test: " + test.testHost.linkCode);
            this.copyTestInviteLinkClipboard(test);
            this.notifications = [];
            this.notifications.push({
                message: "Invite link copied to clipboard.<br/><br/>Please send this link to your learners by pasting it into an " +
                    "email or a WhatsApp message, and then ask you learners to open the link",
            });
            this.showNotifications = true;
        },
        copyTestInviteLinkClipboard: function (test) {
            console.log("copying test link to clipboard");
            const el = document.createElement('textarea');
            el.value = document.location.origin + "/event-entry/?tr=" + this.user.getLinkCode() + "&rft=" + test.testHost.linkCode;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.showLinkCopied = true;
            setTimeout(() => {
                this.showLinkCopied = false;
            }, 2000);
        },
        getConfirmationOfTAndCs: async function (test) {
            // confirm that the learner wants to go ahead after checking the T&Cs
            this.notifications = [];
            this.notifications.push({
                message: "Before registering, do you agree to the <a href='" + test.questionSet.termsAndConditionsURL + "' target='_blank'>terms and conditions</a>?",
                link: {
                    text: "Yes, register me",
                    callback: () => {
                        this.showNotifications = false;
                        this.registerAndCloseDialog(test);
                    }
                },
                cancelLink: {
                    text: "Cancel",
                    callback: () => {
                        this.showNotifications = false;
                    }
                }
            });
            this.showNotifications = true;
        },
        openTest: function (test) {
            console.log("opening test with testHost ID: " + test.testHost.id);
            let newPath = '/test/' + test.testHost.id;
            if (test.testEntry) {
                newPath += "/" + test.testEntry.id;
            }
            console.log("new path: " + newPath);
            if (this.$router.currentRoute.path === newPath) {
                console.log("route identical, ignoring");
            } else {
                this.$router.push({path: newPath});
            }
            this.closeDialog();
        },
        selectLeaderboard: async function (test) {
            this.leaderboardTest = test;
        },
    },
    mounted() {
        this.closeNotificationsFn = (text) => {
            console.log("clearing notifications: " + text);
            this.notifications = [];
            this.showNotifications = false;
        };
        this.$root.$on('close-notifications', this.closeNotificationsFn);

        this.addScheduledHostFn = (testHost) => {
            this.addScheduledTest(testHost);
        };
        this.$root.$on('test-scheduled', this.addScheduledHostFn);

        this.$root.$on('update-test-date', this.updateTestDate);

        this.$root.$on('close-schedule-test', this.sortCurrentAndFutureTests);
    },
    beforeDestroy() {
        this.$root.$off("close-notifications", this.closeNotificationsFn);
        this.$root.$off("test-scheduled", this.addScheduledHostFn);
        this.$root.$off('update-test-date', this.updateTestDate);
        this.$root.$off('close-schedule-test', this.sortCurrentAndFutureTests);
    },
}