export const apiMixin = {
    data() {
        return {}
    },
    methods: {
        logAPIError: function(context, error, fatal) {
            if (error.errors && error.errors[0] && error.errors[0].message) {
                this.logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
            } else {
                this.logError(context, JSON.stringify(error, null, 2), fatal);
            }
        },
        logError: function(context, errorMessage, fatal) {
            console.log("Logging an error: " + errorMessage);
            var dataObject = {
                'event': 'exception',
                'errorMessage': {
                    'category': 'API',
                    'description': context + ': ' + errorMessage,
                    'fatal': fatal
                }
            };
            if(typeof window.dataLayer != 'undefined'){
                console.log("Sending the error to the dataLayer");
                window.dataLayer.push(dataObject);
            }
        },
    }
};