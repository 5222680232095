<template>
    <div class="form-entry">
        <div class="text-center">
            <img src="../assets/logo-home.svg" class="logo-link" alt="MyTutor Logo"/>
            <h1>{{ $t('passwordResetTitleMsg')}}</h1>
        </div>

        <form v-if="!sentVerificationCode" v-on:submit.prevent="requestPasswordReset()">
            <ul>
                <li>
                    <label for="username">{{ $t('commonLabelUsernameMsg')}}</label>
                    <input type="text" id="username" v-model="username">
                    <p v-if="showValidateErrorUsername" class="form-entry__error">
                        {{ $t('commonEnterUsernameMsg')}}
                    </p>
                </li>
                <li v-if="errorMessage" >
                    <p class="form-entry__error">
                        {{ $t('passwordResetErrorMsg')}}<br>{{errorMessage}}
                    </p>
                </li>
                <li v-show="sendingCode">
                    <spinner height="40px" colour="#F9AC1B"/>
                </li>
                <li v-show="!sendingCode">
                    <button type="submit">{{ $t('passwordResetButtonSendCodeMsg')}}</button>
                    <p class="text-center">{{ $t('commonAlreadyHaveAccountMsg')}} <a href="#" v-on:click="$router.push({ path: '/signin' })">{{ $t('commonSignInMsg')}}</a></p>
                </li>
            </ul>
        </form>

        <form v-if="sentVerificationCode" v-on:submit.prevent="setNewPassword()">
            <h5>{{ $t('passwordResetCheckCodeMsg')}}</h5>
            <ul>
                <li>
                    <label for="code">{{ $t('passwordResetLabelVerificationCodeMsg')}}</label>
                    <input type="text" id="code" v-model="code">
                    <p v-if="showValidateErrorCode" class="form-entry__error">
                        {{ $t('passwordResetEnterVerificationCodeMsg')}}
                    </p>
                </li>
                <li>
                    <label for="code">{{ $t('passwordResetLabelNewPasswordMsg')}}</label>
                    <input type="password" id="password" v-model="password">
                    <p v-if="showValidateErrorPassword" class="form-entry__error">
                        {{ $t('passwordResetEnterNewPasswordMsg')}}
                    </p>
                </li>
                <li v-if="errorMessage" >
                    <p class="form-entry__error">
                        {{ $t('passwordResetErrorMsg')}}<br>{{errorMessage}}
                    </p>
                </li>
                <li v-show="settingNewPassword">
                    <spinner height="40px" colour="#F9AC1B"/>
                </li>
                <li v-show="!settingNewPassword">
                    <button type="submit">{{ $t('commonResetPasswordMsg')}}</button>
                </li>
            </ul>
        </form>
    </div>
</template>

<script>
    import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
    import { Auth } from 'aws-amplify';

    // Queries & mutations
    import {forgotPassword} from "../mytutor-graphql/mutations";

    // Visual components
    import Spinner from '../components/Spinner.vue';

    export default {
        name: "PasswordReset",
        components: {
            Spinner
        },
        created() {
        },
        data() {
            return {
                username: "",
                code: "",
                password: "",
                sendingCode: false,
                sentVerificationCode: false,
                settingNewPassword: false,
                showValidateErrorUsername: false,
                showValidateErrorCode: false,
                showValidateErrorPassword: false,
                errorMessage: ""
            }
        },
        computed: {
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            passwordReset: async function(username) {
                console.debug('requesting password reset for user: ' + username);
                try {
                    let variables = {
                        username: username,
                    };
                    const requestSuccessful = await API.graphql({
                        query: forgotPassword,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(requestSuccessful);
                    if (requestSuccessful.data !== null) {
                        console.debug("Answer received");
                        if (requestSuccessful.data.forgotPassword) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        console.debug("Error trying to reset password");
                        this.logError("Trying to reset password", "No data returned", true);
                        return false;
                    }
                } catch (error) {
                    this.logAPIError("Trying to reset password", error, true);
                    return false;
                }
            },

            // ----------------------------------------------------------------------------
            //
            // Functionality
            //
            // ----------------------------------------------------------------------------
            requestPasswordReset: async function() {
                console.debug("validating password reset request form");
                this.errorMessage = "";
                if (!this.validateRequestPasswordResetForm()) {
                    return;
                }

                console.log("Requesting password reset");
                this.sendingCode = true;
                const requestSuccessful = await this.passwordReset(this.username);
                if (requestSuccessful) {
                    console.debug("Request password reset with Auth");
                    try {
                        await Auth.forgotPassword(this.username);
                        this.sentVerificationCode = true;
                    } catch (error) {
                        console.debug('Error requesting password reset', error);
                        if (error.message) {
                            this.errorMessage = error.message;
                        } else {
                            this.errorMessage = this.$t('commonUnknownErrorMsg');
                        }
                    }
                } else {
                    console.debug('Error requesting password reset');
                    this.errorMessage = this.$t('passwordResetErrorUserDoesNotExistMsg');
                }
                this.sendingCode = false;
            },
            validateRequestPasswordResetForm: function () {
                let validated = true;

                this.showValidateErrorUsername = false;

                if (!this.username) {
                    this.showValidateErrorUsername = true;
                    validated = false;
                }
                return validated;
            },
            setNewPassword: async function() {
                console.debug("validating password reset form");
                this.errorMessage = "";
                if (!this.validatePasswordResetForm()) {
                    return;
                }

                console.log("Resetting password");
                this.settingNewPassword = true;
                try {
                    await Auth.forgotPasswordSubmit(this.username, this.code, this.password);
                    console.debug("Successfully changed password");
                    this.$router.push('/signin?password-reset=true');
                } catch (error) {
                    console.debug('Error requesting password reset', error);
                    if (error.message) {
                        this.errorMessage = error.message;
                    } else {
                        this.errorMessage = this.$t('commonUnknownErrorMsg');
                    }
                }
                this.settingNewPassword = false;
            },
            validatePasswordResetForm: function () {
                let validated = true;

                this.showValidateErrorCode = false;
                this.showValidateErrorPassword = false;

                if (!this.code) {
                    this.showValidateErrorCode = true;
                    validated = false;
                }
                if (!this.password) {
                    this.showValidateErrorPassword = true;
                    validated = false;
                }
                return validated;
            }
        },
        mounted() {
        },
        beforeDestroy: async function () {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
</style>

<style lang="scss" scoped>
    form {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px 30px;
    }
</style>