import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";

// Our custom queries & mutations
import {getTestsCanEnterWithoutAccount, getTeacherDetails} from "../mytutor-graphql/queries";
import {registerForTestWithoutAccount} from "../mytutor-graphql/mutations";

// Mixins
import {apiMixin} from "../mixins/APIMixin";

export const eventEntryMixin = {
    mixins: [apiMixin],
    data() {
        return {
            affiliate: null,
            firstName: null,
            surname: null,
            country: null,
            school: null,
            grade: null,
            showOnLeaderboard: true,
            notificationEmail: "",
            testHostLinkCode: "",
            teacherLinkCode: "",
        }
    },
    computed: {},
    methods: {
        // ----------------------------------------------------------------------------
        //
        // API calls
        //
        // ----------------------------------------------------------------------------
        fetchTestsThatCanBeEnteredWithoutAnAccount: async function () {
            console.debug('fetching tests that can be entered without an account');
            try {
                const testsRet = await API.graphql({
                    query: getTestsCanEnterWithoutAccount,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });
                console.debug(testsRet);
                if (testsRet.data.getTestsCanEnterWithoutAccount !== null) {
                    console.debug("Tests found");
                    return testsRet.data.getTestsCanEnterWithoutAccount;
                } else {
                    console.debug("Error fetching tests");
                    this.logError("Creating user", "No data returned", true);
                    return null;
                }
            } catch (error) {
                this.logAPIError("Fetching tests", error, true);
                return null;
            }
        },
        createUserAndRegisterForTest: async function (firstName,
                                                      surname,
                                                      country,
                                                      school,
                                                      grade,
                                                      leaderboard,
                                                      notificationEmail,
                                                      testHostLinkCode,
                                                      teacherLinkCode,
                                                      affiliate) {
            console.debug('creating user: ' + firstName + ' ' + surname);
            try {
                let variables = {
                    firstName: firstName,
                    surname: surname,
                    country: country,
                    school: school,
                    grade: grade,
                    showOnLeaderboard: leaderboard,
                    testHostLinkCode: testHostLinkCode,
                    teacherLinkCode: teacherLinkCode
                };
                if (notificationEmail) {
                    variables.notificationEmail = notificationEmail;
                }
                if (affiliate) {
                    variables.affiliate = affiliate;
                }
                const userRet = await API.graphql({
                    query: registerForTestWithoutAccount,
                    variables: variables,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });
                console.debug(userRet);
                if (userRet.data.registerForTestWithoutAccount !== null) {
                    console.debug("user created");
                    return userRet.data.registerForTestWithoutAccount;
                } else {
                    console.debug("Error creating user");
                    this.logError("Creating user", "No data returned", true);
                    return null;
                }
            } catch (error) {
                this.logAPIError("Creating user", error, true);
                if (error.errors && error.errors[0] && error.errors[0].message) {
                    throw new Error(error.errors[0].message);
                } else {
                    throw new Error("Unspecified error occurred");
                }
            }
        },
        getTeacherDetails: async function (linkCode) {
            console.debug('fetching teacher');
            try {
                let variables = {
                    linkCode: linkCode
                };
                const testsRet = await API.graphql({
                    query: getTeacherDetails,
                    variables: variables,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });
                console.debug(testsRet);
                if (testsRet.data.getTeacherDetails !== null) {
                    console.debug("Tests found");
                    return testsRet.data.getTeacherDetails;
                } else {
                    console.debug("Error fetching tests");
                    this.logError("Creating user", "No data returned", true);
                    return null;
                }
            } catch (error) {
                this.logAPIError("Fetching tests", error, true);
                return null;
            }
        },
    },
    mounted() {
    }
}