<template>
    <div class="profile">
        <div class="close" v-on:click="$root.$emit('close-profile')">&times;</div>
        <img src="../assets/profile-image-placeholder.svg" alt="$t('profileImagePlaceholderMsg')"/>

        <template v-if="loadingProfile">
            <spinner height="50px" colour="#F9AC1B"/>
        </template>
            
        <template v-if="!loadingProfile">
            <div>
                <ul class="leaderboard__grade-selector">
                    <li v-on:click="showMyInformation = true">
                        <div v-bind:class="{'leaderboard__grade-selector--selected': showMyInformation}"><span v-if="!showingFriendProfile" class="hidden-mobile">{{ $t('profileMyMsg')}}</span> {{ $t('profileInformationMsg')}}</div>
                    </li>
                    <li v-on:click="showMyInformation = false">
                        <div v-bind:class="{'leaderboard__grade-selector--selected': !showMyInformation}"><span v-if="!showingFriendProfile" class="hidden-mobile">{{ $t('profileMyMsg')}}</span> {{ $t('profileStatisticsMsg')}}</div>
                    </li>
                </ul>
            </div>

            <div v-show="showMyInformation">
                <ul class="my-information">
                    <li>
                        <span class="profile-sub-heading">{{ $t('profileLinkToTeacherAndFriendsMsg')}}</span>
                        <div>
                            <span class="my-information__data">{{userLinkURL}}</span>
                            <img v-if="!showLinkCopied" v-on:click="copyToClipboard" src="../assets/copy.svg" alt="$t('commonCopyToClipboardMsg')"/>
                            <span v-if="showLinkCopied" class="my-information__copied">{{ $t('commonCopiedMsg')}}</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('profileLinkCodeMsg')}}</span>
                        <div>
                            <span class="my-information__data">{{user.getLinkCode()}}</span>
                            <img v-if="!showLinkCodeCopied" v-on:click="copyLinkCodeToClipboard" src="../assets/copy.svg" alt="$t('commonCopyToClipboardMsg')"/>
                            <span v-if="showLinkCodeCopied" class="my-information__copied">{{ $t('commonCopiedMsg')}}</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('commonLeaderboardEmailMsg')}}</span>
                        <div v-if="!editingEmail">
                            <span class="my-information__data">{{user.email}}</span>
                            <img v-on:click="editEmail" src="../assets/edit.svg" alt="$t('profileEditEmailMsg')"/>
                        </div>
                        <div v-if="editingEmail" class="my-information__edit">
                            <input ref="editEmailInput" type="text" v-model="editProfile.email">
                            <span v-on:click="cancelEditEmail" class="my-information__edit-cancel">&times;</span>
                            <span v-on:click="updateProfile" class="my-information__edit-save">&#10003;</span>
                        </div>
                    </li>
                    <li v-if="!showingFriendProfile">
                        <span class="profile-sub-heading">{{ $t('profileNotificationsMsg')}}</span>
                        <div class="my-information__notifications">
                            <input type="checkbox" v-model="editProfile.receiveMarketingEmails" v-on:change="updateProfile">
                            <span>{{ $t('profileEmailMeMsg')}}</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('profileNicknameMsg')}}</span>
                        <div v-if="!editingNickname">
                            <span class="my-information__data">{{nickname}}</span>
                            <img v-on:click="editNickname" src="../assets/edit.svg" alt="$t('profileEditNicknameMsg')"/>
                        </div>
                        <div v-if="editingNickname" class="my-information__edit">
                            <input ref="editNicknameInput" type="text" v-model="editProfile.nickname">
                            <span v-on:click="cancelEditNickname" class="my-information__edit-cancel">&times;</span>
                            <span v-on:click="updateProfile" class="my-information__edit-save">&#10003;</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('commonLeaderboardFirstNameMsg')}}</span>
                        <div v-if="!editingFirstName">
                            <span class="my-information__data">{{user.firstName}}</span>
                            <img v-on:click="editFirstName" src="../assets/edit.svg" alt="$t('profileEditFirstNameMsg')"/>
                        </div>
                        <div v-if="editingFirstName" class="my-information__edit">
                            <input ref="editFirstNameInput" type="text" v-model="editProfile.firstName">
                            <span v-on:click="cancelEditFirstName" class="my-information__edit-cancel">&times;</span>
                            <span v-on:click="updateProfile" class="my-information__edit-save">&#10003;</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('commonLeaderboardSurnameMsg')}}</span>
                        <div v-if="!editingSurname">
                            <span class="my-information__data">{{user.surname}}</span>
                            <img v-on:click="editSurname" src="../assets/edit.svg" alt="$t('profileEditSurnameMsg')"/>
                        </div>
                        <div v-if="editingSurname" class="my-information__edit">
                            <input ref="editSurnameInput" type="text" v-model="editProfile.surname">
                            <span v-on:click="cancelEditSurname" class="my-information__edit-cancel">&times;</span>
                            <span v-on:click="updateProfile" class="my-information__edit-save">&#10003;</span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('commonLeaderboardSchoolMsg')}}</span>
                        <div v-if="!editingSchool">
                            <span class="my-information__data">{{user.school}}</span>
                            <img v-on:click="editSchool" src="../assets/edit.svg" alt="$t('profileEditSchoolMsg')"/>
                        </div>
                        <div v-if="editingSchool" class="my-information__edit">
                            <input ref="editSchoolInput" type="text" v-model="editProfile.school" v-on:keyup="autoComplete()">
                            <span v-on:click="cancelEditSchool" class="my-information__edit-cancel">&times;</span>
                            <span v-on:click="updateProfile" class="my-information__edit-save">&#10003;</span>
                            <div class="my-information__edit-school" v-show="showAutoComplete">
                                <div>
                                    <ul>
                                        <li v-for="(item, index) in autoCompleteOptions" v-bind:key="index" v-on:click="selectSchool(item)">{{item}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li v-if="!isTeacher">
                        <span class="profile-sub-heading">{{ $t('commonTestsGradeMsg')}}</span>
                        <div  class="my-information__edit">
                            <select v-model="editProfile.grade" v-on:change="updateGrade" v-on:focus="editGrade">
                                <option value="1">Grade 1</option>
                                <option value="2">Grade 2</option>
                                <option value="3">Grade 3</option>
                                <option value="4">Grade 4</option>
                                <option value="5">Grade 5</option>
                                <option value="6">Grade 6</option>
                                <option value="7">Grade 7</option>
                                <option value="8">Grade 8</option>
                                <option value="9">Grade 9</option>
                                <option value="10">Grade 10</option>
                                <option value="11">Grade 11</option>
                                <option value="12">Grade 12</option>
                            </select>
                        </div>
                    </li>
                    <li v-else>
                        <span class="profile-sub-heading">{{ $t('profileAccountTypeMsg')}}</span>
                        <div class="my-information__edit">
                            <span class="my-information__data">
                                <template v-if="isTeacher">{{ $t('commonUserTypeTeacherMsg')}}</template>
                                <template v-else-if="isParent">{{ $t('commonUserTypeParentMsg')}}</template>
                                <template v-else-if="isObserver">{{ $t('commonUserTypeObserverMsg')}}</template>
                                <template v-else>{{ $t('commonUserTypeLearnerMsg')}} - {{ $t('commonTestsGradeMsg')}} {{editProfile.grade}}</template>
                            </span>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('profileLanguageMsg')}}</span>
                        <div  class="my-information__edit">
                            <select v-model="editProfile.language" v-on:change="updateProfile" v-on:focus="editLanguage">
                                <option value="ENGLISH">{{ $t('commonLanguageEnglishMsg')}}</option>
                                <option value="FRENCH">{{ $t('commonLanguageFrenchMsg')}}</option>
                                <option value="PORTUGUESE">{{ $t('commonLanguagePortugueseMsg')}}</option>
                            </select>
                        </div>
                    </li>
                    <li v-if="!isTeacher && user.isSouthAfrican()">
                        <span class="profile-sub-heading">{{ $t('profileGenderMsg')}}</span>
                        <div  class="my-information__edit">
                            <select v-model="editProfile.gender" v-on:change="updateProfile" v-on:focus="editGender">
                                <option value="FEMALE">{{ $t('commonGenderFemaleMsg')}}</option>
                                <option value="MALE">{{ $t('commonGenderMaleMsg')}}</option>
                                <option value="OTHER">{{ $t('commonGenderOtherMsg')}}</option>
                                <option value="ANONYMOUS">{{ $t('commonAnonymousMsg')}}</option>
                            </select>
                        </div>
                    </li>
                    <li v-if="!isTeacher && user.isSouthAfrican()">
                        <span class="profile-sub-heading">RACE</span>
                        <div  class="my-information__edit">
                            <select v-model="editProfile.race" v-on:change="updateProfile" v-on:focus="editRace">
                                <option value="BLACK">{{ $t('commonRaceBlackMsg')}}</option>
                                <option value="COLOURED">{{ $t('commonRaceColouredMsg')}}</option>
                                <option value="INDIAN">{{ $t('commonRaceIndianMsg')}}</option>
                                <option value="WHITE">{{ $t('commonRaceWhiteMsg')}}</option>
                                <option value="ANONYMOUS">{{ $t('commonAnonymousMsg')}}</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <span class="profile-sub-heading">{{ $t('profileCountryMsg')}}</span>
                        <div  class="my-information__edit">
                            <select v-model="editProfile.country" v-on:change="updateProfile" v-on:focus="editCountry">
                                <option v-for="(country, index) in countries" v-bind:key="index" v-bind:value="country">{{country}}</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-show="!showMyInformation">
                <div class="profile-heading">
                    <div class="line"></div>
                    <div class="profile-heading__text">{{ $t('profileMyStatisticsMsg')}}</div>
                    <div class="line"></div>
                </div>

                <table class="stats">
                    <tr>
                        <td>{{ $t('profileQuestionsAnsweredMsg')}}</td>
                        <td>{{metrics.totalAnswered}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profileQuestionsAnsweredCorrectlyMsg')}}</td>
                        <td>{{metrics.totalRight}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profileStreakCorrectMsg')}}</td>
                        <td>{{metrics.longestRightStreak}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profileDaysActiveMsg')}}</td>
                        <td>{{metrics.totalDaysActive}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('profilePointsAccumulatedMsg')}}</td>
                        <td>{{metrics.score}}</td>
                    </tr>
                </table>

                <div class="profile-heading">
                    <div class="line"></div>
                    <div class="profile-heading__text">{{ $t('profileMyBadgesMsg')}}</div>
                    <div class="line"></div>
                </div>

                <table class="stats awards">
                    <thead>
                        <th>{{ $t('profileTotalQuestionsMsg')}}<br/>{{ $t('commonLeaderboardAnsweredMsg')}}</th>
                        <th>{{ $t('profileTotalQuestionsMsg')}}<br/>{{ $t('commonLeaderboardCorrectMsg')}}</th>
                        <th>{{ $t('profileTotalQuestionsMsg')}}<br/>{{ $t('commonLeaderboardCorrectInARowMsg')}}</th>
                    </thead>
                    <tr>
                        <td>
                            <img v-if="metrics.totalAnswered < 5" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_BLUE.png" class="awards--no-award">
                            <img v-if="metrics.totalAnswered >= 5 && metrics.totalAnswered < 10" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_BLUE.png">
                            <img v-if="metrics.totalAnswered >= 10 && metrics.totalAnswered < 50" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_BRONZE.png">
                            <img v-if="metrics.totalAnswered >= 50 && metrics.totalAnswered < 100" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_SILVER.png">
                            <img v-if="metrics.totalAnswered >= 100" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_ANSWERED_GOLD.png">
                            <div class="awards-counter">
                                <span class="awards-counter">{{metrics.totalAnswered}}</span>
                            </div>
                        </td>
                        <td>
                            <img v-if="metrics.totalRight < 3" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_BLUE.png" class="awards--no-award">
                            <img v-if="metrics.totalRight >= 3 && metrics.totalRight < 5" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_BLUE.png">
                            <img v-if="metrics.totalRight >= 5 && metrics.totalRight < 15" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_BRONZE.png">
                            <img v-if="metrics.totalRight >= 15 && metrics.totalRight < 25" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_SILVER.png">
                            <img v-if="metrics.totalRight >= 25" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_CORRECT_GOLD.png">
                            <div class="awards-counter">
                                <span class="awards-counter">{{metrics.totalRight}}</span>
                            </div>
                        </td>
                        <td>
                            <img v-if="metrics.longestRightStreak < 3" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_BLUE.png" class="awards--no-award">
                            <img v-if="metrics.longestRightStreak >= 3 && metrics.longestRightStreak < 5" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_BLUE.png">
                            <img v-if="metrics.longestRightStreak >= 5 && metrics.longestRightStreak < 10" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_BRONZE.png">
                            <img v-if="metrics.longestRightStreak >= 10 && metrics.longestRightStreak < 15" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_SILVER.png">
                            <img v-if="metrics.longestRightStreak >= 15" src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/BADGE_STREAK_GOLD.png">
                            <div class="awards-counter">
                                <span class="awards-counter">{{metrics.longestRightStreak}}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

        </template>
    </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import { countries } from '../countries.js'

import Spinner from '../components/Spinner.vue';

// Our custom queries & mutations
import { getUserProfileWithMetrics, searchSchools } from "../mytutor-graphql/queries";
import { updateUser, updateStudentGrade } from "../mytutor-graphql/mutations";

// Mixins
import {apiMixin} from "../mixins/APIMixin";

export default {
  name: 'Profile',
  components: {
    Spinner
  },
  mixins: [apiMixin],
  props: {
      user: Object,
      showingFriendProfile: Boolean
  },
  data: function() {
      return {
        loadingProfile: true,
        profileWithMetrics: null,
        showMyInformation: true,
        editProfile : {
            email: "",
            nickname: "",
            firstName: "",
            surname: "",
            grade: 0,
            language: "",
            gender: "",
            race: "",
            school: "",
            country: ""
        },
        showLinkCopied: false,
        showLinkCodeCopied: false,
        editingEmail: false,
        editingNickname: false,
        editingFirstName: false,
        editingSurname: false,
        editingSchool: false,
        showAutoComplete: false,
        autoCompleteOptions: ["Rondebosch Junion School", "Rondebosch High School"],
      }
  },
  computed: {
      userLinkURL: function() {
          return document.location.origin + "/?tr=" + this.user.getLinkCode();
      },
      isTeacher: function() {
          return this.user.isTeacher();
      },
      isParent: function() {
          return this.user.isParent();
      },
      isObserver: function() {
          return this.user.isObserver();
      },
      metrics: function() {
          if (this.profileWithMetrics !== null) {
              return this.profileWithMetrics.metrics.items[0];
          }
          return null;
      },
      nickname: function() {
          if (this.user.hasNickname()) {
              return this.user.getNickname();
          } else {
              return this.$t('profileNotEnteredYetMsg');
          }
      },
      grade: function() {
          if (this.user.hasGrade()) {
              return this.user.grade;
          } else {
              return this.$t('profileNotEnteredYetMsg');
          }
      },
      countries: function() {
          return countries;
      }
  },
  methods: {
    // ----------------------------------------------------------------------------
    // 
    // API calls
    //
    // ----------------------------------------------------------------------------
    fetchUserProfileWithMetrics: async function(user) {
      console.log('fetching user metrics with ID: ' + user.getID());
      try {
        const metricsRet = await API.graphql(graphqlOperation(getUserProfileWithMetrics, { id: user.getID() }));
        console.log(metricsRet);
        if (metricsRet.data.getUser !== null) {
            console.log("User found");
            return metricsRet.data.getUser;
        } else {
            console.log("Error fetching metrics");
            this.logError("Fetching metrics", "No data returned", false);
            return null;
        }
      } catch (error) {
        this.logAPIError("Fetching metrics", error, false);
      }
    },
    updateProfile: async function() {
        console.log('updating user profile: ' + this.user.getID());
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
        this.showAutoComplete = false;
        this.editProfile.email = this.editProfile.email.toLowerCase();
        try {
            const item = {
                input: {
                    id: this.user.getID(),
                    email: this.editProfile.email,
                    notificationEmail: this.editProfile.email,
                    receiveMarketingEmails: this.editProfile.receiveMarketingEmails,
                    nickname: this.editProfile.nickname,
                    firstName: this.editProfile.firstName,
                    surname: this.editProfile.surname,
                    language: this.editProfile.language,
                    gender: this.editProfile.gender,
                    race: this.editProfile.race,
                    school: this.editProfile.school,
                    country: this.editProfile.country
                }
            };
            const updateUserRet = await API.graphql(graphqlOperation(updateUser, item ));
            console.log(updateUserRet);
            if (updateUserRet.data.updateUser !== null) {
                console.log("Updated user");
                this.user.setEmail(this.editProfile.email);
                this.user.setReceiveMarketingEmails(this.editProfile.receiveMarketingEmails);
                this.user.setNickname(this.editProfile.nickname);
                this.user.setFirstName(this.editProfile.firstName);
                this.user.setSurname(this.editProfile.surname);
                this.user.setLanguage(this.editProfile.language);
                this.user.setGender(this.editProfile.gender);
                this.user.setRace(this.editProfile.race);
                this.user.setSchool(this.editProfile.school);
                this.user.setCountry(this.editProfile.country);
            } else {
                console.log("Error updating user");
                this.logError("Updating user", "No data returned", false);
            }
        } catch (error) {
            this.logAPIError("Updating user", error, false);
        }
    },
    updateGrade: async function() {
        console.log('updating user grade: ' + this.user.getID());
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
        try {
            const updateGradeRet = await API.graphql(graphqlOperation(updateStudentGrade, {userID: this.user.getID(), grade: this.editProfile.grade} ));
            console.log(updateGradeRet);
            if (updateGradeRet.data.updateStudentGrade !== null) {
                console.log("Updated user grade");
                this.user.setGrade(this.editProfile.grade);
            } else {
                console.log("Error updating user grade");
                this.logError("Updating user grade", "No data returned", false);
            }
        } catch (error) {
            this.logAPIError("Updating user grade", error, false);
        }
    },
    fetchAutoCompleteOptionsFromServer: async function(user, response) {
      console.log('fetching auto complete options for response: ' + response);
      if (!response) {
        return [];
      }

      try {
        const searchText = response.toLowerCase();
        const terms = searchText.split(" ");
        let andTerms = [];
        andTerms.push({country: {eq: user.country}});
        for (let term of terms) {
          andTerms.push({ or: [{name: {wildcard: "*" + term + "*"}}, {nameASCII: {wildcard: "*" + term + "*"}}] });
        }

        const autoCompleteRet = await API.graphql(graphqlOperation(searchSchools, {filter: {and: andTerms} , limit: 5 }));
        console.log(autoCompleteRet);
        if (autoCompleteRet.data.searchSchools !== null) {
            console.log("Auto complete options found");
            return autoCompleteRet.data.searchSchools.items;
        } else {
            console.log("Error fetching auto complete options");
            this.logError("Fetching auto complete", "No data returned", true);     
            return null;
        }
      } catch (error) {
        this.logAPIError("Fetching auto complete", error, true);       
        return null;
      }
    },
    // ----------------------------------------------------------------------------
    // 
    // Chat behaviour
    //
    // ----------------------------------------------------------------------------
    copyToClipboard: function() {
        console.log("copying to clipboard");
        const el = document.createElement('textarea');
        el.value = this.userLinkURL;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.showLinkCopied = true;
        setTimeout(() => {
            this.showLinkCopied = false;
        }, 2000);
    },
    copyLinkCodeToClipboard: function() {
        console.log("copying link code to clipboard");
        const el = document.createElement('textarea');
        el.value = this.user.getLinkCode();
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.showLinkCodeCopied = true;
        setTimeout(() => {
            this.showLinkCodeCopied = false;
        }, 2000);
    },
    editEmail: function() {
        this.editingEmail = true;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
        setTimeout(() => {
            this.$refs.editEmailInput.focus();
        }, 500);
    },
    cancelEditEmail: function() {
        this.editingEmail = false;
        this.editProfile.email = this.user.getEmail();
    },
    editNickname: function() {
        this.editingEmail = false;
        this.editingNickname = true;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
        setTimeout(() => {
            this.$refs.editNicknameInput.focus();
        }, 500);
    },
    cancelEditNickname: function() {
        this.editingNickname = false;
        this.editProfile.nickname = this.user.getNickname();
    },
    editFirstName: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = true;
        this.editingSurname = false;
        this.editingSchool = false;
        setTimeout(() => {
            this.$refs.editFirstNameInput.focus();
        }, 500);
    },
    cancelEditFirstName: function() {
        this.editingFirstName = false;
        this.editProfile.firstName = this.user.getFirstName();
    },
    editSurname: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = true;
        this.editingSchool = false;
        setTimeout(() => {
            this.$refs.editSurnameInput.focus();
        }, 500);
    },
    cancelEditSurname: function() {
        this.editingSurname = false;
        this.editProfile.surname = this.user.getSurname();
    },
    editGrade: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
    },
    editLanguage: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
    },
    editGender: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
    },
    editRace: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
    },
    editSchool: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = true;
        setTimeout(() => {
            this.$refs.editSchoolInput.focus();
        }, 500);
    },
    cancelEditSchool: function() {
        this.editingSchool = false;
        this.editProfile.school = this.user.getSchool();
        this.showAutoComplete = false;
    },
    autoComplete: async function() {
        let options = await this.fetchAutoCompleteOptionsFromServer(this.user, this.editProfile.school);
        this.autoCompleteOptions = options.map(school => school.name);
        this.showAutoComplete = true;
    },
    selectSchool: function(school) {
      this.editProfile.school = school;
      this.updateProfile();
      this.showAutoComplete = false;
    },
    editCountry: function() {
        this.editingEmail = false;
        this.editingNickname = false;
        this.editingFirstName = false;
        this.editingSurname = false;
        this.editingSchool = false;
    },
  },
  mounted: async function() {
      this.loadingProfile = true;
      if (this.showingFriendProfile) {
          this.profileWithMetrics = this.user;
          this.editProfile.email = this.user.email;
          // this.editProfile.receiveMarketingEmails = this.user.receiveMarketingEmails;
          this.editProfile.receiveMarketingEmails = false;
          this.editProfile.nickname = this.user.nickname;
          this.editProfile.firstName = this.user.firstName;
          this.editProfile.surname = this.user.surname;
          this.editProfile.language = this.user.language;
          this.editProfile.grade = this.user.grade;
          this.editProfile.gender = this.user.gender;
          this.editProfile.race = this.user.race;
          this.editProfile.school = this.user.school;
          this.editProfile.country = this.user.country;
      } else {
          this.profileWithMetrics = await this.fetchUserProfileWithMetrics(this.user);
          this.editProfile.email = this.user.getEmail();
          this.editProfile.receiveMarketingEmails = this.user.getReceiveMarketingEmails();
          this.editProfile.nickname = this.user.getNickname();
          this.editProfile.firstName = this.user.getFirstName();
          this.editProfile.surname = this.user.getSurname();
          this.editProfile.language = this.user.getLanguage();
          this.editProfile.grade = this.user.getGrade();
          this.editProfile.gender = this.user.getGender();
          this.editProfile.race = this.user.getRace();
          this.editProfile.school = this.user.getSchool();
          this.editProfile.country = this.user.getCountry();
      }
      this.loadingProfile = false;
  }
}
</script>

<style lang="scss" scoped>
    @import "../styles/pages/profile";
</style>