import Vue from 'vue';

import {API, graphqlOperation} from "@aws-amplify/api";

// Generated queries & mutations
import {getTestSectionForEntrant, getTestResultForEntrant} from "../graphql/queries";

// Our custom queries & mutations
import {
    getTestHostWithTestByID,
    getTestForTeacher,
    getPracticeQuestions,
    getSectionLeaderboard,
    getOverallLeaderboard
} from "../mytutor-graphql/queries";

import {
    startSectionAt,
    endSection,
    entrantEndsTest as entrantEndsTestQuery,
    saveTestAnswer,
    skipQuestionAdaptiveTest
} from "../mytutor-graphql/mutations";

import {
    onUpdateTestSection,
    onUpdateTestSectionPerformance,
    onUpdateTestQuestionPerformance,
    onRegisterEntrantForTest,
    onUpdateTestEntry
} from "../mytutor-graphql/subscriptions";

import {sortByNumber, sortByField} from "../services/utils";

/**
 * This represents a Test from an Entrant or Teacher point of view. It's a composition of a Test and TestEntry
 * if one compares to the schema
 *
 * @param testHostID
 * @returns {Promise<null>}
 */
let test = async function (testHostID) {
    let that = {};

    that.testSubscription = null;
    that.sectionSubscriptions = [];
    that.entrySubscription = null;
    that.sectionPerformanceSubscriptions = [];
    that.questionPerformanceSubscriptions = [];

    let setTestEntrySectionIDResolver = function (testEntrySectionIDResolver) {
        that.testEntrySectionIDResolver = testEntrySectionIDResolver;
    };
    that.setTestEntrySectionIDResolver = setTestEntrySectionIDResolver;


    // ============================================================================
    //
    // API calls
    //
    // ============================================================================

    let fetchTestFromServer = async function (testHostID) {
        console.log("Fetching test by testHostID: " + testHostID);
        try {
            const testRet = await API.graphql(graphqlOperation(getTestHostWithTestByID, {id: testHostID}));
            console.log(testRet);
            if (testRet.data.getTestHost === null) {
                logError("Opening test", "No data returned", true);
                throw new Error("Error opening test - not found");
            }
            const data = testRet.data.getTestHost;
            that = Object.assign(that, data.test);
            that.host = {id: data.id, userID: data.userID};
            that.date = data.testDate;              // slight hack - shouldn't have date on Test and TestHost
            that.sections = that.sections.items;
            that.questionSet.formulaSheetSlides = that.questionSet.formulaSheetSlides.items;
            sortByNumber(that.sections);
            sortByNumber(that.questionSet.formulaSheetSlides);
        } catch (error) {
            logAPIError("Opening test", error, true);
            throw new Error("API Error opening test");
        }
    };

    let fetchTestSectionsForTeacherFromServerAndSubscribeToChanges = async function (teacher, testID, callback, subscribeToUpdates) {
        console.log("Fetching test for teacher: " + teacher.getEmail());

        // first subscribe to new entries, before getting the test (which includes entries), as we might miss some due to timing
        setupOverallLeaderboard();

        if (subscribeToUpdates) {
            await subscribeToNewEntries(that.host.id);
            await subscribeToEntryChanges();
        }

        // then fetch the test and setup the rest
        try {
            const testRet = await API.graphql(graphqlOperation(getTestForTeacher, {
                teacherID: teacher.getID(),
                testID: testID
            }));
            console.log(testRet);
            if (testRet.data.getTestForTeacher === null) {
                logError("Opening test", "No data returned", true);
                throw new Error("Error opening test");
            }
            Object.assign(that.sections, testRet.data.getTestForTeacher.sections);
            sortByNumber(that.sections);

            for (let i = 0; i < that.sections.length; i++) {
                const section = that.sections[i];
                await prepareQuestionsForTest(section.answers);
                Vue.set(that.sections, i, section);
            }

            setupSectionLeaderboards();
            that.leaderboard.entries.forEach((entry) => {
                addEntryToSectionLeaderboards(entry);
            });

            if (testRet.data.getTestForTeacher.entries) {
                let leaderboardEntries = testRet.data.getTestForTeacher.entries;

                console.log("setting up leaderboard");
                populateLeaderboards(leaderboardEntries);
                sortLeaderboards();

                console.log("setting up subscriptions");
                if (subscribeToUpdates) {
                    await subscribeToSectionAndQuestionChanges(callback);
                }

                console.log("finished setting up leaderboard & subscriptions");
            }
        } catch (error) {
            logAPIError("Opening test", error, true);
            throw new Error("API Error opening test");
        }
    };

    let setupOverallLeaderboard = function () {
        that.leaderboard = {
            entries: [],
            entryMap: Object.create(null),
            entrySectionMap: Object.create(null),
            sectionIDToSectionLeaderboardMap: Object.create(null)
        };
    };

    let setupSectionLeaderboards = function () {
        for (let i = 0; i < that.sections.length; i++) {
            let section = that.sections[i];
            section.leaderboard = {
                entries: []
            };
            that.leaderboard.sectionIDToSectionLeaderboardMap[section.id] = section.leaderboard;
        }
    };

    let subscribeToNewEntries = async function () {
        console.log("subscribing to new entries for test host: " + that.host.id);
        try {
            that.testSubscription =
                API.graphql(
                    graphqlOperation(onRegisterEntrantForTest, {testHostID: that.host.id})
                ).subscribe({
                    next: entryAdded,
                    error: (error) => {
                        console.warn(error)
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    let entryAdded = function (response) {
        console.log("new test entry added");
        console.log(response);
        if (response.value.data.onRegisterEntrantForTest !== null) {
            const entry = response.value.data.onRegisterEntrantForTest.entry;
            addEntryToOverallLeaderboard(entry);
            addEntryToSectionLeaderboards(entry);
        }
    };

    let addEntryToOverallLeaderboard = function (entry) {
        if (typeof that.leaderboard.entryMap[entry.id] === 'undefined') {
            console.log("Adding entry to leaderboard: " + entry.id);
            that.leaderboard.entries.push(entry);
            that.leaderboard.entryMap[entry.id] = entry;
        }
    };

    let addEntryToSectionLeaderboards = function (entry) {
        entry.entrySections.forEach((entrySection) => {
            if (typeof that.leaderboard.entrySectionMap[entrySection.id] === 'undefined') {
                entrySection.email = entry.email;
                entrySection.firstName = entry.firstName;
                entrySection.surname = entry.surname;
                that.leaderboard.entrySectionMap[entrySection.id] = entrySection;
                // add the TestSectionEntry to the leaderboard for the correct section
                that.leaderboard.sectionIDToSectionLeaderboardMap[entrySection.testSectionID].entries.push(entrySection);
            }
        });
    };

    let subscribeToEntryChanges = async function () {
        console.log("Subscribing to entry changes");
        try {
            that.entrySubscription =
                API.graphql(
                    graphqlOperation(onUpdateTestEntry, {testHostID: that.host.id})
                ).subscribe({
                    next: entryUpdated,
                    error: (error) => {
                        console.warn(error)
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    let entryUpdated = function (response) {
        console.log("Entry updated");
        if (response.value.data.onUpdateTestEntry !== null) {
            const updatedEntry = response.value.data.onUpdateTestEntry;
            if (typeof that.leaderboard.entryMap[updatedEntry.id] === 'undefined') {
                console.log("ERORR - could not find entry on leaderboard" + updatedEntry.id);
            } else {
                let existingEntry = that.leaderboard.entryMap[updatedEntry.id];
                existingEntry.score = updatedEntry.score;
                existingEntry.scoreAndTime = updatedEntry.scoreAndTime;
                existingEntry.questionsAnswered = updatedEntry.questionsAnswered;
                existingEntry.timeTaken = updatedEntry.timeTaken;
                console.log("Updating leaderboard entry: " + existingEntry.id + " to score: " + existingEntry.score);
                updatedEntry.entrySections.items.forEach((entrySection) => {
                    entrySectionUpdated(entrySection);
                });
            }
            sortByField(that.leaderboard.entries, "scoreAndTime");
        }
    };

    let entrySectionUpdated = function (entrySection) {
        console.log("EntrySection updated");
        if (typeof that.leaderboard.entrySectionMap[entrySection.id] === 'undefined') {
            console.log("ERROR - could not find entry on section leaderboard" + entrySection.id);
        } else {
            let existingEntrySection = that.leaderboard.entrySectionMap[entrySection.id];
            existingEntrySection.sectionScore = entrySection.sectionScore;
            existingEntrySection.sectionScoreAndTime = entrySection.sectionScoreAndTime;
            existingEntrySection.totalScore = entrySection.totalScore;
            existingEntrySection.questionsAnswered = entrySection.questionsAnswered;
            existingEntrySection.timeTaken = entrySection.timeTaken;
            console.log("Updating section leaderboard entry: " + existingEntrySection.id + " to score: " + existingEntrySection.sectionScore);
        }
        let sectionLeaderboard = that.leaderboard.sectionIDToSectionLeaderboardMap[entrySection.testSectionID];
        sortByField(sectionLeaderboard.entries, "sectionScoreAndTime");
    };

    let populateLeaderboards = function (entries) {
        entries.forEach((entry) => {
            addEntryToOverallLeaderboard(entry);
            addEntryToSectionLeaderboards(entry);
        });
    };

    let sortLeaderboards = function () {
        // that.sortByField(that.leaderboard.entries, "score");
        sortByField(that.leaderboard.entries, "scoreAndTime");
        that.sections.forEach((section => {
            // that.sortByField(section.leaderboard.entries, "totalScore");
            sortByField(section.leaderboard.entries, "sectionScoreAndTime");
        }));
    };

    let subscribeToSectionAndQuestionChanges = async function (callback) {
        that.sectionPerformanceSubscriptions.length = 0;
        that.questionPerformanceSubscriptions.length = 0;
        if (that.sections && that.sections.length > 0) {
            that.sections.forEach((section) => {
                console.log("Subscribing to section changes: " + section.id);
                try {
                    that.sectionSubscriptions.push(
                        API.graphql(
                            graphqlOperation(onUpdateTestSection, {id: section.id})
                        ).subscribe({
                            next: (response) => {
                                sectionUpdatedForTeacher(response, section, callback);
                            },
                            error: (error) => {
                                console.warn(error);
                            }
                        }));
                } catch (error) {
                    console.log(error);
                }
                if (section.performance) {
                    console.log("Subscribing to section performance: " + section.performance.id);
                    try {
                        that.sectionPerformanceSubscriptions.push(
                            API.graphql(
                                graphqlOperation(onUpdateTestSectionPerformance, {id: section.performance.id})
                            ).subscribe({
                                next: sectionPerformanceUpdated,
                                error: (error) => {
                                    console.warn(error)
                                }
                            }));
                        console.log("Setting initial performance for section: " + section.id);
                        findSectionAndUpdatePerformance(section.performance);
                    } catch (error) {
                        console.log(error);
                    }
                }
                if (section.answers.length > 0) {
                    section.answers.forEach((answer) => {
                        if (answer.performance) {
                            console.log("Subscribing to question performance: " + answer.performance.id);
                            try {
                                that.questionPerformanceSubscriptions.push(
                                    API.graphql(
                                        graphqlOperation(onUpdateTestQuestionPerformance, {id: answer.performance.id})
                                    ).subscribe({
                                        next: questionPerformanceUpdated,
                                        error: (error) => {
                                            console.warn(error)
                                        }
                                    }));
                            } catch (error) {
                                console.log(error);
                            }
                            console.log("Setting initial performance for question: " + answer.id);
                            findQuestionAndUpdatePerformance(answer.performance);
                        }
                    });
                }
            });
        }
    };

    let sectionUpdatedForTeacher = function (response, section, callback) {
        console.log("Section updated for teacher");
        console.log(response);
        if (response.value.data.onUpdateTestSection.startedAt !== null) {
            if (response.value.data.onUpdateTestSection.endedAt !== null) {
                console.log("Section: " + response.value.data.onUpdateTestSection.id + " has just ended by scheduled event");
                section.endedAt = response.value.data.onUpdateTestSection.endedAt;
                callback.call();
            } else {
                console.log("Section: " + response.value.data.onUpdateTestSection.id + " has just started by me");
            }
        }
    };

    let sectionPerformanceUpdated = function (response) {
        console.log("SectionPerformance updated");
        console.log(response);
        if (response.value.data.onUpdateTestSectionPerformance !== null) {
            const questionPerformance = response.value.data.onUpdateTestSectionPerformance;
            findSectionAndUpdatePerformance(questionPerformance);
        }
    };

    let findSectionAndUpdatePerformance = function (sectionPerformance) {
        for (let i = 0; i < that.sections.length; i++) {
            let section = that.sections[i];
            if (section.id === sectionPerformance.testSectionID) {
                section.performance = sectionPerformance;
                break;
            }
        }
    };

    let questionPerformanceUpdated = function (response) {
        console.log("QuestionPerformance updated");
        console.log(response);
        if (response.value.data.onUpdateTestQuestionPerformance !== null) {
            const questionPerformance = response.value.data.onUpdateTestQuestionPerformance;
            findQuestionAndUpdatePerformance(questionPerformance);
        }
    };

    let findQuestionAndUpdatePerformance = function (questionPerformance) {
        try {
            findingQuestion: {
                for (let i = 0; i < that.sections.length; i++) {
                    let section = that.sections[i];
                    for (let j = 0; j < section.answers.length; j++) {
                        let question = section.answers[j];
                        if (question.id === questionPerformance.testQuestionID) {
                            question.performance = questionPerformance;
                            break findingQuestion;
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    let fetchPracticeQuestionsFromServer = async function () {
        console.log("Loading practice questions for test: " + that.id);
        try {
            const practiceQuestionsRet = await API.graphql(graphqlOperation(getPracticeQuestions, {testID: that.id}));
            console.log(practiceQuestionsRet);

            if (practiceQuestionsRet.data.getPracticeQuestions === null) {
                logError("Loading practice questions", "No data returned", true);
                throw new Error("Error loading practice questions");
            }

            let practiceQuestions = practiceQuestionsRet.data.getPracticeQuestions;
            sortByNumber(practiceQuestions);
            // convert to format for QuizQuestion/ChatLine component
            practiceQuestions.forEach((question) => {
                question.images = question.questionURLs;
                question.logType = 'MYTUTOR_FILE';
                question.answer = null;
            });
            Vue.set(that, 'practiceQuestions', practiceQuestions);
        } catch (error) {
            logAPIError("Loading practice questions", error, true);
            throw new Error("API Error loading practice questions");
        }
    };

    let loadSectionForEntrantFromServer = async function (sectionIndex, testEntrySectionID) {
        console.log("Loading section");
        try {
            let sectionRet = await API.graphql(graphqlOperation(getTestSectionForEntrant, {
                testEntrySectionID: testEntrySectionID
            }));
            console.log(sectionRet);

            if (sectionRet.data.getTestSectionForEntrant === null) {
                logError("Opening test section", "No data returned", true);
                throw new Error("Error opening test section");
            }

            console.log("Test section found");
            // Todo: this is a hack to manage the fact that the same object is used for the learner and teacher!
            let section = sectionRet.data.getTestSectionForEntrant;
            section.id = section.testSectionID;
            await prepareQuestionsForTest(section.answers);
            Vue.set(that.sections, sectionIndex, section);
            return section;
        } catch (error) {
            logAPIError("Opening test section", error, true);
            throw new Error("API Error opening test section");
        }
    };

    let prepareQuestionsForTest = async function (answers) {
        if (answers) {
            if (isAdaptiveTest()) {
                renumberQuestionsForAdaptiveTest(answers);
            } else {
                sortByNumber(answers);
            }
            // convert to format for ChatLine/QuizQuestion component
            await Promise.all(answers.map(async (answer) => {
                prepareQuestionForTest(answer);
            }));
        }
    };

    let prepareQuestionForTest = async function (answer) {
        if (answer.answerRightURLs) {
            if (answer.correct) {
                answer.images = answer.answerRightURLs;
            } else {
                answer.images = answer.answerWrongURLs;
            }
        } else {
            answer.images = answer.questionURLs;
        }
        answer.logType = 'MYTUTOR_FILE';
        answer.submittingAnswer = false;
        if (answer.answerPhotoS3Key) {
            return fetchSignedURLForAnswer(answer);
        } else {
            answer.answerPhotoS3Key = null;
            return Promise.resolve();
        }
    };

    let renumberQuestionsForAdaptiveTest = function (answers) {
        for (let i = 0; i < answers.length; i++) {
            answers[i].number = i + 1;
        }
    }

    let fetchSignedURLForAnswer = async function (answer) {
        // fetch a signed URL to the image
        try {
            Vue.set(answer, 'answerPhotoURL', await Storage.get(answer.answerPhotoS3Key, {
                level: 'protected',
                expires: 5400
            }));
            console.log("answerPhotoURL: " + answer.answerPhotoURL);
        } catch (error) {
            console.log("Error getting signed URL for photo in S3: " + answer.answerPhotoS3Key, error);
        }
    };

    let subscribeToSectionChangesOnServer = async function(callbackSectionStarted, callbackSectionEnded) {
        console.log("Subscribing to section changes");
        that.sectionSubscriptions.length = 0;
        if (that.sections && that.sections.length > 0) {
            that.sections.forEach((section) => {
                console.log("Subscribing to section: " + section.id);
                try {
                    that.sectionSubscriptions.push(
                        API.graphql(
                            graphqlOperation(onUpdateTestSection, { id: section.id })
                        ).subscribe({
                            next: (response) => {
                                sectionUpdatedForEntrant(response, callbackSectionStarted, callbackSectionEnded);
                            },
                            error: (error) => { console.warn(error) }
                        }));
                } catch(error) {
                    console.log(error);
                }
            });
        }
    };

    let sectionUpdatedForEntrant = async function(response, callbackSectionStarted, callbackSectionEnded) {
        console.log("Section updated");
        console.log(response);

        // work out section index and testEntrySectionID
        const sectionIndex = that.getSectionIndexFromSectionID(response.value.data.onUpdateTestSection.id);
        const testEntrySectionID = that.testEntrySectionIDResolver.call(this, response.value.data.onUpdateTestSection.id);

        // load the section
        const section = await loadSectionForEntrantFromServer(sectionIndex, testEntrySectionID);

        if (response.value.data.onUpdateTestSection.startedAt !== null) {
            if (response.value.data.onUpdateTestSection.endedAt !== null) {
                console.log("Section: " + response.value.data.onUpdateTestSection.id + " has just ended");
                if (section != null) {
                    callbackSectionEnded.call(this, sectionIndex);
                }
            } else {
                console.log("Section: " + response.value.data.onUpdateTestSection.id + " has just started");
                if (section !== null) {
                    callbackSectionStarted.call(this, sectionIndex, section);
                }
            }
        }
    };

    let saveTestAnswerOnServer = async function (answerID, answer, answerPhotoS3Key) {
        console.log("Saving answer for TestAnswer: " + answerID);
        try {
            const updateAnswerRet = await API.graphql(graphqlOperation(saveTestAnswer, {
                testAnswerID: answerID,
                answer: answer,
                answerPhotoS3Key: answerPhotoS3Key
            }));
            console.log(updateAnswerRet);
            if (updateAnswerRet.data.saveTestAnswer !== null) {
                console.log("Answer updated");
                return updateAnswerRet.data.saveTestAnswer;
            } else {
                // for an adaptive test this means no more questions
                if (isAdaptiveTest()) {
                    return null;
                }
                console.log("Error updating answer");
                logError("Updating answer", "No data returned", true);
                throw new Error("Error updating answer");
            }
        } catch (error) {
            logAPIError("Updating answer", error, true);
            throw new Error("API Error updating answer");
        }
    };

    let skipAndAddNextQuestionToSection = async function (sectionIndex, testEntrySectionID) {
        console.log("Skipping question for section: " + that.sections[sectionIndex].id);
        try {
            const nextQuestionRet = await API.graphql(graphqlOperation(skipQuestionAdaptiveTest, {
                testEntrySectionID: testEntrySectionID
            }));
            console.log(nextQuestionRet);

            if (nextQuestionRet.data.skipQuestionAdaptiveTest === null) {
                console.info("No more questions to add");
                return false;
            }

            await addQuestionToSection(that.sections[sectionIndex], nextQuestionRet.data.skipQuestionAdaptiveTest);
            console.log("Skipped question");
            return true;
        } catch (error) {
            logAPIError("Skipping question", error, true);
            throw new Error("API Error skipping question");
        }
    };

    let addQuestionToSection = async function (section, question) {
        question.number = section.answers.length + 1;
        await prepareQuestionForTest(question);
        section.answers.push(question);
    };

    let fetchTestResultForEntrantFromServer = async function (testEntryID) {
        console.log("Loading test result");
        try {
            let resultRet = await API.graphql(graphqlOperation(getTestResultForEntrant, {
                testEntryID: testEntryID
            }));
            console.log(resultRet);

            if (resultRet.data.getTestResultForEntrant === null) {
                logError("Loading test result", "No data returned", true);
                throw new Error("Error loading test result");
            }

            console.log("Test result found");
            return resultRet.data.getTestResultForEntrant;
        } catch (error) {
            logAPIError("Loading test result", error, true);
            throw new Error("API Error loading test result");
        }
    };

    let loadSectionLeaderboardFromServer = async function (user, sectionIndex) {
        console.log("Loading section leaderboard");
        try {
            let section = that.sections[sectionIndex];
            const sectionLeaderboardRet = await API.graphql(graphqlOperation(getSectionLeaderboard, {
                userID: user.getID(),
                testSectionID: section.id
            }));
            console.log(sectionLeaderboardRet);

            if (sectionLeaderboardRet.data.getTestSectionLeaderboardForEntrant === null) {
                logError("Opening test section leaderboard", "No data returned", true);
                throw new Error("Error opening test section leaderboard");
            }

            console.log("Test section leaderboard found");
            Vue.set(section, 'leaderboard', sectionLeaderboardRet.data.getTestSectionLeaderboardForEntrant);
        } catch (error) {
            logAPIError("Opening test section leaderboard", error, true);
            throw new Error("API Error opening test section leaderboard");
        }
    };

    let loadOverallLeaderboardFromServer = async function (user) {
        console.log("Loading overall leaderboard");
        try {
            const leaderboardRet = await API.graphql(graphqlOperation(getOverallLeaderboard, {
                userID: user.getID(),
                testID: that.id
            }));
            console.log(leaderboardRet);

            if (leaderboardRet.data.getTestLeaderboardForEntrant === null) {
                logError("Opening test overall leaderboard", "No data returned", true);
                throw new Error("Error opening test overall leaderboard");
            }

            console.log("Test overall leaderboard found");
            return leaderboardRet.data.getTestLeaderboardForEntrant;
        } catch (error) {
            logAPIError("Opening test overall leaderboard", error, true);
            throw new Error("API Error opening test overall leaderboard");
        }
    };

    let teacherStartSectionOnServer = async function(teacher, sectionID, startAt) {
        console.log("Starting section: " + sectionID);
        try {
            const startSectionRet = await API.graphql(graphqlOperation(startSectionAt, { teacherID: teacher.getID(), testSectionID: sectionID, startAt: startAt }));
            console.log(startSectionRet);

            if (startSectionRet.data.updateTestSectionStart === null) {
                logError("Starting section", "No data returned", true);
                throw new Error("Error starting section");
            }

            console.log("Started section");
            return startSectionRet.data.startSectionAt;
        } catch (error) {
            console.log(error);
            logAPIError("Starting section", error, true);
            throw new Error("API Error starting section");
        }
    };

    let teacherEndsSectionOnServer = async function(teacher, sectionID) {
        console.log("Ending section: " + sectionID);
        try {
            const endSectionRet = await API.graphql(graphqlOperation(endSection, { teacherID: teacher.getID(), testSectionID: sectionID }));
            console.log(endSectionRet);

            if (endSectionRet.data.endSection === null) {
                logError("Starting section", "No data returned", true);
                throw new Error("Error ending section");
            }

            // has the last section just finished? If so, reload the test
            let sectionIndex = getSectionIndexFromSectionID(sectionID);
            if (sectionIndex+1 === that.sections.length) {
                console.log("Last section finished, reloading test");
                await fetchTestSectionsForTeacherFromServerAndSubscribeToChanges(teacher, that.id, null, false);
            }

            console.log("Ending section");
            return endSectionRet.data.endSection;
        } catch (error) {
            console.log(error);
            logAPIError("Ending section", error, true);
            throw new Error("API Error ending section");
        }
    };

    let entrantEndsTestOnServer = async function(testEntrySectionID) {
        console.log("Ending test: " + testEntrySectionID);
        try {
            const endTestRet = await API.graphql(graphqlOperation(entrantEndsTestQuery, { testEntrySectionID: testEntrySectionID }));
            console.log(endTestRet);

            if (endTestRet.data.entrantEndsTest === null) {
                logError("Ending test", "No data returned", true);
                throw new Error("Error ending test");
            }

            console.log("Ended test");
            return endTestRet.data.entrantEndsTest;
        } catch (error) {
            console.log(error);
            logAPIError("Ending test", error, true);
            throw new Error("API Error ending test");
        }
    };

    let logAPIError = function (context, error, fatal) {
        console.log(error);
        if (error.errors && error.errors[0] && error.errors[0].message) {
            logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
        } else {
            logError(context, JSON.stringify(error, null, 2), fatal);
        }
    };

    let logError = function (context, errorMessage, fatal) {
        console.log("Logging an error: " + errorMessage);
        var dataObject = {
            'event': 'exception',
            'errorMessage': {
                'category': 'API',
                'description': context + ': ' + errorMessage,
                'fatal': fatal
            }
        };
        if (typeof window.dataLayer != 'undefined') {
            console.log("Sending the error to the dataLayer");
            window.dataLayer.push(dataObject);
        }
    };


    /*
     * Basic properties. These are left exposed so that Vue reactivity works. 
     * 
     * NB: All Getters and Setters must use these values for reactivity to work
     * 
     */


    /*
     * Getters and Setters with behaviour, or properties we want to have encapsulation on. If the method isn't using 
     * a basic property above, the property value will be on the data object passed into the constructor.
     */
    let isOlympiad = function () {
        return that.questionSet.format === 'OLYMPIAD';
    };
    that.isOlympiad = isOlympiad;

    let isPubQuiz = function () {
        return that.questionSet.format === 'PUB_QUIZ';
    };
    that.isPubQuiz = isPubQuiz;

    let isWorksheet = function () {
        return that.questionSet.format === 'WORKSHEET';
    };
    that.isWorksheet = isWorksheet;

    let isTest = function () {
        return that.questionSet.format === 'TEST';
    };
    that.isTest = isTest;

    let isAdaptiveTest = function () {
        return that.questionSet.format === 'ADAPTIVE_TEST';
    };
    that.isAdaptiveTest = isAdaptiveTest;

    let isInvigilated = function () {
        return that.questionSet.invigilation !== 'NO_INVIGILATOR';
    };
    that.isInvigilated = isInvigilated;

    let hasFormulaSheets = function () {
        return that.questionSet &&
            that.questionSet.formulaSheetSlides &&
            that.questionSet.formulaSheetSlides.length > 0;
    }
    that.hasFormulaSheets = hasFormulaSheets;

    let isMultipleChoiceQuestion = function (question) {
        return question.layout === 'LAYOUT_1_2_3_4_5' ||
            question.layout === 'LAYOUT_1_2_3_NL_4_5' ||
            question.layout === 'LAYOUT_1_2_NL_3_4_NL_5' ||
            question.layout === 'LAYOUT_XL_1_2_NL_3_4_NL_5' ||
            question.layout === 'LAYOUT_1_NL_2_NL_3_NL_4_NL_5';

    };
    that.isMultipleChoiceQuestion = isMultipleChoiceQuestion;

    let getLastQuestionInSection = function (sectionIndex) {
        if (sectionIndex < 0 || sectionIndex > that.sections.length) {
            throw new Error("Section index out of range");
        }
        if (that.sections[sectionIndex].answers.length === 0) {
            return null;
        }
        return that.sections[sectionIndex].answers[that.sections[sectionIndex].answers.length - 1];
    }
    that.getLastQuestionInSection = getLastQuestionInSection;

    /**
     *
     * @param testEntry
     * @returns {boolean}
     */
    let isInParticipationWindow = function (testEntry) {
        // the current time needs to be in the participation window of the QuestionSet,
        // as well as after the participationStart of the TestEntry (if present)
        console.log("Checking if in participation window");
        if (!that.questionSet) {
            return false;
        }

        const now = new Date();
        const participationStart = new Date(that.questionSet.participationStart);
        const participationEnd = new Date(that.questionSet.participationEnd);
        if (now < participationStart || now > participationEnd) {
            console.log("Outside window");
            return false;
        }

        if (testEntry && testEntry.participationStart) {
            const entryParticipationStart = new Date(testEntry.participationStart);
            if (now < entryParticipationStart) {
                console.log("Before TestEntry participationStart");
                return false;
            }
        }
        console.log("In participation window");
        return true;
    };
    that.isInParticipationWindow = isInParticipationWindow;

    /**
     *
     * @returns {boolean}
     */
    let isShowMarkingAfterTestWindow = function () {
        if (that.questionSet &&
            that.questionSet.markingConfiguration &&
            that.questionSet.markingConfiguration.whenToShow === "AFTER_TEST_WINDOW") {
            if (new Date() < new Date(that.questionSet.participationEnd)) {
                return true;
            }
        }
        return false;
    };
    that.isShowMarkingAfterTestWindow = isShowMarkingAfterTestWindow;

    /**
     *
     * @returns {Promise<void>}
     */
    let loadPracticeQuestions = async function () {
        await fetchPracticeQuestionsFromServer();
    };
    that.loadPracticeQuestions = loadPracticeQuestions;

    /**
     *
     * @param user
     * @returns {Promise<void>}
     */
    let loadAllSectionsAndSubscribeToChangesForTeacher = async function (user, callback) {
        console.log("Loading all sections for teacher");
        await fetchTestSectionsForTeacherFromServerAndSubscribeToChanges(user, that.id, callback, true);
    };
    that.loadAllSectionsAndSubscribeToChangesForTeacher = loadAllSectionsAndSubscribeToChangesForTeacher;

    /**
     *  Unsubscribe from leaderboard and section changes
     */
    let unsubscribeFromUpdates = function() {
        console.log("Unsubscribing from updates");
        if (that.testSubscription !== null) {
            that.testSubscription.unsubscribe();
            that.testSubscription = null;
            that.entrySubscription.unsubscribe();
            that.entrySubscription = null;
            unsubscribe(that.sectionPerformanceSubscriptions);
            that.sectionPerformanceSubscriptions = [];
            unsubscribe(that.questionPerformanceSubscriptions);
            that.questionPerformanceSubscriptions = [];
        }
        unsubscribe(that.sectionSubscriptions);
        that.sectionSubscriptions = [];
    };
    that.unsubscribeFromUpdates = unsubscribeFromUpdates;

    let unsubscribe = function(subscriptions) {
        if (subscriptions && subscriptions.length > 0) {
            for (let i = 0; i < subscriptions.length; i++) {
                subscriptions[i].unsubscribe();
            }
        }
    };

    /**
     *
     * @param email
     * @param sectionIndex
     * @returns {Promise<void>}
     */
    let loadSectionForEntrant = async function (sectionIndex, testEntrySectionID) {
        if (!that.sections || sectionIndex < 0 || sectionIndex > that.sections.length) {
            throw new Error("Test no loaded or section index out of range");
        }
        return await loadSectionForEntrantFromServer(sectionIndex, testEntrySectionID);
    };
    that.loadSectionForEntrant = loadSectionForEntrant;

    let subscribeToSectionChanges = async function(callbackSectionStarted, callbackSectionEnded) {
        await subscribeToSectionChangesOnServer(callbackSectionStarted, callbackSectionEnded);
    };
    that.subscribeToSectionChanges = subscribeToSectionChanges;

    let getSectionIndexFromSectionID = function(sectionID) {
        console.log("Looking for section index for section with ID: " + sectionID);
        for (let i = 0; i < that.sections.length; i++) {
            if (sectionID === that.sections[i].id) {
                return i;
            }
        }
        logError("Updating section after subscription message", "Could not find section", true);
        return null;
    };
    that.getSectionIndexFromSectionID = getSectionIndexFromSectionID;

    /**
     *
     * @param answerID
     * @param answer
     * @param answerPhotoS3Key
     * @returns {Promise<void>}
     */
    let saveAnswer = async function (answerID, answer, answerPhotoS3Key, sectionIndex) {
        let testAnswer = await saveTestAnswerOnServer(answerID, answer, answerPhotoS3Key);
        if (answerPhotoS3Key && testAnswer) {
            await fetchSignedURLForAnswer(testAnswer);
        }
        if (isAdaptiveTest()) {
            if (testAnswer) {
                await addQuestionToSection(that.sections[sectionIndex], testAnswer);
                return true;
            } else {
                console.info("No more questions to add");
                return false;
            }
        } else {
            return testAnswer !== null;
        }
    };
    that.saveAnswer = saveAnswer;

    /**
     *
     * @returns {Promise<void>}
     */
    let skipQuestion = async function (sectionIndex, testEntrySectionID) {
        if (!isAdaptiveTest()) {
            throw new Error("This is not an adaptive test, so cannot skip a question!");
        }
        if (sectionIndex < 0 || sectionIndex > that.sections.length) {
            throw new Error("Section index out of range");
        }
        return await skipAndAddNextQuestionToSection(sectionIndex, testEntrySectionID);
    };
    that.skipQuestion = skipQuestion;

    /**
     *
     * @param testEntryID
     * @returns {Promise<void>}
     */
    let fetchTestResultForEntrant = async function (testEntryID) {
        return fetchTestResultForEntrantFromServer(testEntryID);
    };
    that.fetchTestResultForEntrant = fetchTestResultForEntrant;

    /**
     *
     * @param user
     * @param sectionIndex
     * @returns {Promise<*|undefined>}
     */
    let loadSectionLeaderboard = async function (user, sectionIndex) {
        return loadSectionLeaderboardFromServer(user, sectionIndex);
    };
    that.loadSectionLeaderboard = loadSectionLeaderboard;

    /**
     *
     * @param user
     * @param testID
     * @returns {Promise<*|undefined>}
     */
    let loadOverallLeaderboard = async function (user) {
        return loadOverallLeaderboardFromServer(user);
    };
    that.loadOverallLeaderboard = loadOverallLeaderboard;

    let teacherStartsSection = async function(teacher, sectionID, startAt) {
        return teacherStartSectionOnServer(teacher, sectionID, startAt);
    };
    that.teacherStartsSection = teacherStartsSection;

    let teacherEndsSection = async function(teacher, sectionID) {
        return teacherEndsSectionOnServer(teacher, sectionID);
    }
    that.teacherEndsSection = teacherEndsSection;

    let entrantEndsTest = async function(testEntrySectionID) {
        return entrantEndsTestOnServer(testEntrySectionID);
    }
    that.entrantEndsTest = entrantEndsTest;

    try {
        await fetchTestFromServer(testHostID);
    } catch (error) {
        console.log(error);
        return null;
    }

    return that;
};

export default test;