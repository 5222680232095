/*
This code is taken from here:
https://dev.to/originalexe/can-you-please-refresh-or-how-we-version-our-single-page-application-335l

The timer exposes an interface for running the code in an interval (just like setInterval does), 
but with no guarantee that the code will actually execute at that interval. It instead also 
exposes a function one should call to check for all timers and execute them as necessary. It 
basically loops through all the intervals, checks when they were last executed, and if more time 
than what is defined as an interval time has passed, it executes the callback. There is an 
additional, third parameter, in the setInterval implementation that takes a "forced" interval. 
This interval uses a native setInterval function so it more or less provides a guarantee of 
running every x milliseconds.

 */
const ActivityBasedTimer = function() {
    let globalTimerId = 0;
    const timers = new Map();

    const maybeExecuteTimerCallback = ({ timerId, forced = false }) => {
        console.log('Checking timer with ID: ' + timerId);
        const timer = timers.get(timerId);

        if (timer === undefined) {
            console.log('Version timer not found');
            return;
        }

        const {
            callback,
            interval,
            forcedInterval,
            forcedIntervalId,
            lastExecution,
        } = timer;
        const intervalToCheckFor = forced === true
            ? forcedInterval
            : interval;
        const now = Date.now();

        if (now - lastExecution < intervalToCheckFor) {
            console.log('Version timer not due to run yet');
            return;
        }

        const newTimer = {
            ...timer,
            lastExecution: now,
        };

        if (forcedIntervalId !== undefined) {
            window.clearInterval(forcedIntervalId);
            newTimer.forcedIntervalId = window.setInterval(() => {
                maybeExecuteTimerCallback({ timerId, forced: true });
            }, forcedInterval);
        }

        console.log('Calling version timer callback');
        timers.set(timerId, newTimer);
        callback({ forced, timerId });
    };

    const setInterval = ({ callback, interval, forcedInterval } = {}) => {
        const timerId = globalTimerId;

        if (typeof callback !== 'function' || typeof interval !== 'number') {
            return undefined;
        }

        const timer = {
            callback,
            interval,
            lastExecution: Date.now(),
        };

        if (forcedInterval !== undefined) {
            timer.forcedInterval = forcedInterval;
            timer.forcedIntervalId = window.setInterval(() => {
                maybeExecuteTimerCallback({ timerId, forced: true });
            }, forcedInterval);
        }

        timers.set(timerId, timer);
        globalTimerId += 1;
        return timerId;
    };

    const clearInterval = (timerId) => {
        const timer = timers.get(timerId);

        if (timer === undefined) {
            return;
        }

        const { forcedIntervalId } = timer;

        if (forcedIntervalId !== undefined) {
            window.clearInterval(forcedIntervalId);
        }

        timers.delete(timerId);
    };

    const runTimersCheck = () => {
        console.log('Checking timers (' + timers.size + ' timers)');
        timers.forEach((_timer, timerId) => {
            maybeExecuteTimerCallback({ timerId });
        });
    };

    return {
        setInterval,
        clearInterval,
        runTimersCheck,
    };
};

export default ActivityBasedTimer;