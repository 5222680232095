<template>
  <main-layout>
    <h1 id="Contact">{{ $t('contactUsTitleMsg')}}</h1>
    <p class="content">{{ $t('contactUsPart1Msg')}}</p>
    <p class="content">{{ $t('contactUsPart2Msg')}} <img src="../assets/email-address.png" class="email-image"/></p>
    <p class="content">{{ $t('contactUsPart3Msg')}}</p>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>

<style scoped>
  .email-image {
    width: 168px;
    display: inline;
    vertical-align: top;
  }

  .content {
    display: inherit;
    flex: inherit;
    flex-direction: inherit;
  }
</style>