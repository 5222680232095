<template>
    <div class="test-detail">
        <div class="test-detail__header-bar">
            <div v-on:click="closeDialog()" class="image-button">
                <img src="../assets/arrow.svg" alt="$t('viewTestDetailsCloseMsg')"/>
            </div>
            <div class="test-detail__heading">{{ $t('viewTestDetailsHeadingMsg')}}</div>
        </div>

        <ul class="my-information">
            <li>
                <span class="profile-sub-heading">{{ $t('commonLeaderboardNameMsg')}}</span>
                <div class="my-information__detail">
                    <div>{{selectedTest.test.name}}</div>
                </div>
            </li>
            <li>
                <span class="profile-sub-heading">{{ $t('commonLeaderboardDateMsg')}}</span>
                <div v-if="!editingDate" class="my-information__detail">
                    <div v-html="getDisplayDate(selectedTest.test.date)"></div>
                    <img v-on:click="editDate" src="../assets/edit.svg" alt="$t('viewTestDetailsEditDateMsg')"
                         class="image-button"/>
                </div>
                <div v-if="editingDate" class="my-information__edit">
                    <input type="text" v-model="editTestDate">
                    <button v-on:click="cancelEditDate()" class="button-secondary">{{ $t('commonButtonCancelMsg')}}</button>
                    <button v-on:click="saveDate()">{{ $t('commonButtonSaveMsg')}}</button>
                </div>
            </li>
            <li>
                <span class="profile-sub-heading">{{ $t('viewTestDetailsTestCodeMsg')}}</span>
                <div class="my-information__detail">
                    <div>{{selectedTest.testHost.linkCode}}</div>
                    <img v-if="!showCodeCopied" v-on:click="copyCodeToClipboard" src="../assets/copy.svg"
                         alt="$t('commonCopyToClipboardMsg')" class="image-button"/>
                    <span v-if="showCodeCopied" class="my-information__copied">{{ $t('commonCopiedMsg')}}</span>
                </div>
            </li>
            <li>
                <span class="profile-sub-heading">{{ $t('viewTestDetailsTestRegistrationURLMsg')}}</span>
                <div class="my-information__detail">
                    <div>{{testRegistrationURL}}</div>
                    <img v-if="!showLinkCopied" v-on:click="copyToClipboard" src="../assets/copy.svg"
                         alt="$t('commonCopyToClipboardMsg')" class="image-button"/>
                    <span v-if="showLinkCopied" class="my-information__copied">{{ $t('commonCopiedMsg')}}</span>
                </div>
            </li>
        </ul>

        <button v-on:click="closeDialogAndOpenTest()" class="test-detail__open"
                v-bind:disabled="!canTeacherViewTest"
                v-bind:class="{'test-detail__open--disabled' : !canTeacherViewTest}">
            <template v-if="canTeacherViewTest">Open Teacher View</template>
            <template v-else>{{ $t('viewTestDetailsAvailableMsg')}}<span class="hidden-mobile"> {{ $t('viewTestDetailsFromMsg')}}</span>:
                {{dateFromWhenTeacherCanOpenTest}}
            </template>
        </button>
        <!-- <div v-else>You can open this test after: {{dateFromWhenTeacherCanOpenTest}}</div> -->

        <div class="test-detail__heading">{{ $t('viewTestDetailsTestEntrantsMsg')}}</div>

        <template v-if="loadingTestEntrants">
            <spinner height="50px" colour="#F9AC1B"/>
        </template>

        <template v-else>
            <table class="test-detail__entries">
                <thead>
                <th class="test-detail__entries-hdr--num">#</th>
                <th class="test-detail__entries-hdr--name">{{ $t('commonLeaderboardLearnerMsg')}}</th>
                <th class="test-detail__entries-hdr--email hidden-mobile">{{ $t('commonLeaderboardEmailMsg')}}</th>
                <th class="test-detail__entries-hdr--score">{{ $t('commonLeaderboardScoreMsg')}}</th>
                <th class="test-detail__entries-hdr--time">{{ $t('commonLeaderboardTimeMsg')}}</th>
                <th class="test-detail__entries-hdr--entered hidden-mobile">{{ $t('commonLeaderboardEnteredMsg')}}</th>
                </thead>
                <tr v-for="(entry, index) in selectedTest.entries" v-bind:key="entry.email">
                    <td class="test-detail__entries-num">{{index+1}}</td>
                    <td class="test-detail__entries-name">{{entry.surname}}, {{entry.firstName}}</td>
                    <td class="test-detail__entries-email hidden-mobile">{{entry.email}}</td>
                    <td class="test-detail__entries-score">{{entry.score}}</td>
                    <td class="test-detail__entries-time">
                        <template v-if="entry.timeTaken">
                            {{entry.timeTaken}}
                        </template>
                        <template v-else>
                            --:--
                        </template>
                    </td>
                    <td class="test-detail__entries-entered hidden-mobile"
                        v-html="getDescriptiveDisplayDate(entry.createdAt)"></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dayjs from 'dayjs';
    import Spinner from './Spinner.vue';

    import { getDisplayDate, getDescriptiveDisplayDate, getDisplayDateForEditing } from "../services/utils";

    export default {
        name: 'ViewTestDetails',
        components: {
            Spinner
        },
        mixins: [],
        props: {
            user: Object,
            selectedTest: Object
        },
        data: function () {
            return {
                loadingTestEntrants: false,
                showCodeCopied: false,
                showLinkCopied: false,
                editingDate: false,
                editTestName: "",
                editTestDate: "",
            }
        },
        computed: {
            canTeacherViewTest: function () {
                if (!this.selectedTest) {
                    return false;
                }
                if (this.selectedTest.test.questionSet.format !== "OLYMPIAD") {
                    return true;
                }
                if (this.selectedTest.test.questionSet.publicOrPrivate === "public") {
                    return true;
                }
                let showTestFrom = dayjs(this.selectedTest.test.date).subtract(30, 'minute');
                return dayjs().isAfter(showTestFrom);
            },
            dateFromWhenTeacherCanOpenTest: function () {
                if (this.selectedTest && this.selectedTest.test.questionSet.format === "OLYMPIAD") {
                    const showTestFrom = dayjs(this.selectedTest.test.date).subtract(30, 'minute');
                    return showTestFrom.format('DD MMM [at] HH:mm');
                }
                return "";
            },
            testRegistrationURL: function () {
                return document.location.origin + "/?rft=" + this.selectedTest.testHost.linkCode;
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            getDisplayDate: function (dateString) {
                return getDisplayDate(dateString);
            },
            getDescriptiveDisplayDate: function (dateString) {
                return getDescriptiveDisplayDate(dateString);
            },
            copyCodeToClipboard: function () {
                console.log("copying code to clipboard");
                const el = document.createElement('textarea');
                el.value = this.selectedTest.testHost.linkCode;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showCodeCopied = true;
                setTimeout(() => {
                    this.showCodeCopied = false;
                }, 2000);
            },
            copyToClipboard: function () {
                console.log("copying to clipboard");
                const el = document.createElement('textarea');
                el.value = this.testRegistrationURL;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showLinkCopied = true;
                setTimeout(() => {
                    this.showLinkCopied = false;
                }, 2000);
            },
            editDate: function () {
                this.editingDate = true;
            },
            cancelEditDate: function () {
                this.editingDate = false;
            },
            saveDate: async function () {
                this.editingDate = false;
                this.$root.$emit('update-test-date', this.selectedTest.testHost.id, this.selectedTest.test.id, this.editTestDate)
            },
            closeDialog: function () {
                this.$root.$emit('close-view-test-details', false);
            },
            closeDialogAndOpenTest: function() {
                this.$root.$emit('close-view-test-details', true);
            }
        },
        mounted: async function () {
            this.editTestName = this.selectedTest.test.name;
            this.editTestDate = getDisplayDateForEditing(this.selectedTest.test.date);
            this.loadingTestEntrants = true;
            const testEntries = await this.user.fetchTestEntriesForTestHost(this.selectedTest.test.id);
            Vue.set(this.selectedTest, 'entries', testEntries);
            this.loadingTestEntrants = false;
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/buttons";
    @import "../styles/pages/profile";
    @import "../styles/pages/tests";
    @import "../styles/pages/approvers";
</style>
