// import { API, graphqlOperation } from "@aws-amplify/api";
// import user from "./user";

// Our custom queries & mutations
// import { openWorksheetEntry } from "../mytutor-graphql/queries";
// import {  } from "../mytutor-graphql/mutations";

// import { convertAWSTimeToMillis, convertMillisToDuration } from "../services/utils";

// import {getTestEntry} from "../mytutor-graphql/queries";

/**
 * This represents a Worksheet.
 *
 * @param
 * @returns {Promise<null>}
 */
let worksheet = function(data, worksheetEntryData) {
    let that = {};
    that.entry = worksheetEntryData;

    /* Basic properties. These are left exposed so that Vue reactivity works.
     *
     * NB: All Getters and Setters must use these values for reactivity to work
     *
     */

    // ============================================================================
    //
    // API calls
    //
    // ============================================================================


    // let logAPIError = function(context, error, fatal) {
    //     if (error.errors && error.errors[0] && error.errors[0].message) {
    //         logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
    //     } else {
    //         logError(context, JSON.stringify(error, null, 2), fatal);
    //     }
    // }

    // let logError = function(context, errorMessage, fatal) {
    //     console.log("Logging an error: " + errorMessage);
    //     let dataObject = {
    //         'event': 'exception',
    //         'errorMessage': {
    //             'category': 'API',
    //             'description': context + ': ' + errorMessage,
    //             'fatal': fatal
    //         }
    //     };
    //     if(typeof window.dataLayer != 'undefined'){
    //         console.log("Sending the error to the dataLayer");
    //         window.dataLayer.push(dataObject);
    //     }
    // }

    /*
    /*
     * Getters and Setters with behaviour, or properties we want to have encapsulation on. If the method isn't using
     * a basic property above, the property value will be on the data object passed into the constructor.
     */
    let getID = function() {
        return data.id;
    };

    let getName = function() {
        return data.name;
    };

    let getTag = function() {
        return data.tag;
    };

    let getInstructions = function() {
        return data.instructions;
    };

    let getCreated = function() {
        return data.createdAt;
    };

    let canStart = function() {
        if (!that.entry ) {
            return true;
        }
        return false;
    }

    let isLocked = function() {
        if (that.entry && !that.entry.finishedAt) {
            return true;
        }
        return false;
    }

    let isInProgress = function() {
        if (that.entry && !that.entry.finishedAt) {
            return true;
        }
        return false;
    }

    let isComplete = function() {
        if (that.entry && that.entry.finishedAt) {
            return true;
        }
        return false;
    }

    that.getID = getID;
    that.getName = getName;
    that.getTag = getTag;
    that.getInstructions = getInstructions;
    that.getCreated = getCreated;

    that.canStart = canStart;
    that.isLocked = isLocked;
    that.isInProgress = isInProgress;
    that.isComplete = isComplete;

    return that;
};

export default worksheet;