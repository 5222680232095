<template>
    <div class="profile">
        <div class="close" v-on:click="closeDialog()">&times;</div>

        <template v-if="loadingTests">
            <div class="title">
                <div class="title__heading">
                    <span>2023 South African Mathematics Challenge</span>
                </div>
            </div>

            <div class="instructions">
                Loading tests
            </div>

            <spinner height="50px" colour="#F9AC1B"/>
        </template>

        <template v-if="!loadingTests">

            <div class="title">
                <div class="title__heading">
                    <span>2023 South African Mathematics Challenge</span>
                </div>
            </div>

            <div v-show="!showAddSchedulers && !showScheduleTest && !showTestDetails">
                <div v-if="isTeacher" class="instructions">

                    <h3>Schedule a test</h3>

                    <template>
                        <template v-if="authorisedForPrivateInvigilatedQuestionSets">
                            You have been authorised to schedule tests for the 2023 edition of the SAMC. Additionally, you can
                            authorise other teachers at your institution to be able to schedule these tests as well.

                            <div class="instructions__cta">
                                <button v-on:click="openScheduleTest" class="btn btn-primary">Schedule a Test</button><br/>
                                <button v-on:click="openAddSchedulers" class="btn btn-secondary">Approve <span class="hidden-mobile">other</span> Schedulers</button>
                            </div>
                        </template>

                        <template v-else>
                            <p><strong>You have not yet been authorised to schedule these tests.</strong> If your school has entered the
                                event with SAMF, please ask you school organiser to approve you. If your school has not
                                entered, please contact SAMF. <a href="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/MyTutor_school_SAMF_event_instructions.pdf" target="_blank">Read instructions here</a></p>
                        </template>
                    </template>

                </div>

                <div class="lists-of-tests">

                    <ul class="lists-of-tests__help">
                        <template v-if="isTeacher">
                            <h6>TEACHERS</h6>
                            <li><img src="../assets/arrow.svg" alt="Open test"/> open a test you scheduled</li>
                        </template>
                        <h6>LEARNERS</h6>
<!--                        <li><img src="../assets/plus.svg" alt="Register for test"/> register for a test</li>-->
                        <li><img src="../assets/arrow.svg" alt="Open test"/> open your test entry</li>
                    </ul>

                    <test-entry-by-code></test-entry-by-code>

                    <div v-if="futureTests.length > 0" class="lists-of-tests__test-list">
                        <h6>Upcoming</h6>
                        <ul class="my-information">
                            <li v-for="test in futureTests" v-bind:key="test.id" class="my-information__detail">
                                <div class="test__detail">
                                    <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">SELF-START</span>
                                    <span v-else class="profile-sub-heading profile-sub-heading--strong">INVIGILATED</span>
                                    <span class="test-name">{{test.test.name}}</span>
                                    <span class="profile-sub-heading">
                                        Grade {{test.questionSet.minGrade}}
                                        <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                                            - {{test.questionSet.maxGrade}}
                                        </template>
                                    </span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">{{test.test.totalRegistered}} learners</span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">
                                        <template v-if="test.test">
                                            {{getDisplayDate(test.test.date)}}
                                        </template>
                                        <template v-else>
                                            {{getDisplayDate(test.questionSet.participationStart)}}
                                        </template>
                                    </span>
                                </div>
                                <div class="test__cta">
                                    <!-- Choice of actions: Open test, or register for test -->
                                    <div v-if="test.testEntry || test.questionSet.invigilation !== 'NO_INVIGILATOR'" v-on:click="selectTest(test)" class="image-button">
                                        <img src="../assets/arrow.svg" alt="Open test"/>
                                    </div>
                                    <div v-else v-on:click="registerForTest(test)" class="image-button">
                                        <img src="../assets/plus.svg" alt="Register for test"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="lists-of-tests__test-list">
                        <h6>Current</h6>
                        <ul class="my-information">
                            <li v-for="test in currentTests" v-bind:key="test.id" class="my-information__detail">
                                <div class="test__detail">
                                    <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">SELF-START</span>
                                    <span v-else class="profile-sub-heading profile-sub-heading--strong">INVIGILATED</span>
                                    <span class="test-name">{{test.test.name}}</span>
                                    <span class="profile-sub-heading">
                                        Grade {{test.questionSet.minGrade}}
                                        <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                                            - {{test.questionSet.maxGrade}}
                                        </template>
                                    </span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">{{test.test.totalRegistered}} learners</span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">
                                        <!-- if our TestEntry has a specific start date -->
                                        <template v-if="test.testEntry && test.testEntry.participationStart">
                                            <!-- we've passed the participation start -->
                                            <template v-if="isDateInPast(test.testEntry.participationStart)">
                                                Start when ready
                                            </template>
                                            <!-- else show when we can start -->
                                            <template v-else>
                                                {{getDisplayDate(test.testEntry.participationStart)}}
                                            </template>
                                        </template>
                                            <!-- otherwise look at the QuestionSet -->
                                        <template v-else>
                                            <!-- un-invigilated tests -->
                                            <template v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'">
                                                Start when ready
                                            </template>
                                            <!-- else it's an invigilated test, waiting for invigilator to start -->
                                            <template v-else>
                                                {{getDisplayDate(test.test.date)}}
                                            </template>
                                        </template>
                                    </span>
                                    <template v-if="test.questionSet.termsAndConditionsURL">
                                        <span class="pipe-break">|</span>
                                        <span class="profile-sub-heading"><a v-bind:href="test.questionSet.termsAndConditionsURL" target="_blank">Terms &amp; Conditions</a></span>
                                        <template v-if="isTeacher || isParent">
                                            <br/>
                                            <span class="test__invite-link" v-on:click="copyTestInviteLink(test)">
                                            Invite learners to participate
                                            <img src="../assets/copy.svg" alt="Copy to clipboard"/>
                                        </span>
                                        </template>
                                    </template>
                                </div>
                                <div class="test__cta">
                                    <!-- Choice of actions: Open test, or register for test -->
                                    <div v-if="test.testEntry || test.questionSet.invigilation !== 'NO_INVIGILATOR'" v-on:click="selectTest(test)" class="image-button">
                                        <img src="../assets/arrow.svg" alt="Open test"/>
                                    </div>
                                    <div v-else v-on:click="registerForTest(test)" class="image-button">
                                        <img src="../assets/plus.svg" alt="Register for test"/>
                                    </div>
                                </div>
                            </li>
                            <li v-if="currentTests.length === 0" class="my-information__detail--empty">
                                <template v-if="isTeacher">
                                    None scheduled for today.
                                </template>
                                <template v-else>
                                    Please ask your teacher for a link to enter the 2023 South African Mathematics Challenge.
                                </template>
                            </li>
                        </ul>
                    </div>

                    <div v-if="pastTests.length > 0" class="lists-of-tests__test-list">
                        <h6>Past</h6>
                        <ul class="my-information">
                            <li v-for="test in pastTests" v-bind:key="test.id" class="my-information__detail">
                                <div class="test__detail">
                                    <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">SELF-START</span>
                                    <span v-else class="profile-sub-heading profile-sub-heading--strong">INVIGILATED</span>
                                    <span class="test-name">{{test.test.name}}</span>
                                    <span class="profile-sub-heading">
                                        Grade {{test.questionSet.minGrade}}
                                        <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                                            - {{test.questionSet.maxGrade}}
                                        </template>
                                    </span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">{{test.test.totalRegistered}} learners</span>
                                    <span class="pipe-break">|</span>
                                    <span class="profile-sub-heading">
                                        <template v-if="test.testEntry">
                                            <template v-if="test.testEntry.startedAt">
                                                {{getDisplayDate(test.testEntry.startedAt)}}
                                            </template>
                                            <template v-else-if="test.testEntry.participationStart">
                                                {{getDisplayDate(test.testEntry.participationStart)}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            {{getDisplayDate(test.test.date)}}
                                        </template>
                                    </span>
                                    <template v-if="test.testEntry">
                                        <span class="pipe-break">|</span>
                                        <span class="profile-sub-heading">Score: {{test.testEntry.score}}</span>
                                    </template>
                                </div>
                                <div class="test__cta">
                                    <div v-on:click="selectLeaderboard(test)" class="image-button image-button--leaderboard">
                                        <img src="../assets/MYTUTOR ICONS-06.svg" alt="icon"/>
                                    </div>
                                    <div v-on:click="selectTest(test)" class="image-button">
                                        <img src="../assets/arrow.svg" alt="Open test"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

            <add-schedulers v-bind:user="user" v-bind:questionSets="privateInvigilatedQuestionSets" v-if="showAddSchedulers"></add-schedulers>
            <schedule-test v-bind:user="user" v-bind:questionSets="privateInvigilatedQuestionSets" v-if="showScheduleTest"></schedule-test>
            <view-test-details v-bind:user="user" v-bind:selectedTest="selectedTest" v-if="showTestDetails"></view-test-details>
        </template>

        <!-- ----------------------- -->
        <!-- POPUPS                  -->
        <!-- ----------------------- -->
        <notifications v-if="showNotifications"
                       v-bind:notifications="notifications"
                       v-bind:showSpinner="false">
        </notifications>

    </div>
</template>

<script>
    import Spinner from './Spinner.vue';
    import AddSchedulers from './AddSchedulers';
    import ScheduleTest from './ScheduleTest';
    import ViewTestDetails from './ViewTestDetails';
    import Notifications from '../components/Notifications';
    import TestEntryByCode from "../components/TestEntryByCode";

    // Mixins
    import {testsMixin} from "../mixins/TestsMixin";

    export default {
        name: 'SAMC',
        components: {
            Spinner, AddSchedulers, ScheduleTest, ViewTestDetails, Notifications, TestEntryByCode
        },
        mixins: [testsMixin],
        props: {
            user: Object
        },
        data: function () {
            return {
                showAddSchedulers: false,
                showScheduleTest: false,
                showTestDetails: false,
                questionSets: [],
                showLinkCopied: false
            }
        },
        computed: {
            privateInvigilatedQuestionSets: function() {
                let privateQuestionSets = [];
                for (const questionSet of this.questionSets) {
                    if (questionSet.private && questionSet.invigilation && questionSet.invigilation !== 'NO_INVIGILATOR') {
                        privateQuestionSets.push(questionSet);
                    }
                }
                return privateQuestionSets;
            },
            authorisedForPrivateInvigilatedQuestionSets: function() {
                for (const questionSet of this.questionSets) {
                    if (questionSet.private && questionSet.invigilation && questionSet.invigilation !== 'NO_INVIGILATOR') {
                        return true;
                    }
                }
                return false;
            },
            userLinkURL: function() {
                return document.location.origin + "/event-entry/?tr=" + this.user.getLinkCode();
            },
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            loadTests: async function() {
                this.loadingTests = true;
                this.resetTestsLists();
                let testsHosting = await this.user.fetchTestsHosting(["2023 South African Mathematics Challenge", "2023 Suid-Afrikaanse Wiskunde-uitdaging"]);
                let tests = await this.user.fetchSAMCTests();
                let entries = await this.user.fetchSAMCTestEntries();
                this.addTestAndTestEntriesAndDivideIntoPastCurrentAndFuture(testsHosting, tests, entries, false, false);
                this.questionSets = await this.user.fetchQuestionSets(["2023 South African Mathematics Challenge", "2023 Suid-Afrikaanse Wiskunde-uitdaging"]);
                this.loadingTests = false;
            },
            openAddSchedulers: function() {
                console.log("Show approve window");
                this.showAddSchedulers = true;
                window.scrollTo(0, 1);
            },
            openScheduleTest: function() {
                console.log("Show schedule test window");
                this.showScheduleTest = true;
                window.scrollTo(0, 1);
            },
            copyToClipboard: function() {
                console.log("copying to clipboard");
                const el = document.createElement('textarea');
                el.value = this.userLinkURL;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showLinkCopied = true;
                setTimeout(() => {
                    this.showLinkCopied = false;
                }, 2000);
            },
            closeDialog: function () {
                this.$root.$emit('close-samc')
            },
        },
        mounted: async function () {
            await this.loadTests();
            this.$root.$on('refresh-tests', this.loadTests);

            this.closeSchedulersFn = () => {
                console.debug("Hiding show schedulers");
                this.showAddSchedulers = false;
            };
            this.$root.$on('close-show-schedulers', this.closeSchedulersFn);

            this.closeScheduleTestFn = () => {
                console.debug("Hiding schedule test");
                this.showScheduleTest = false;
            };
            this.$root.$on('close-schedule-test', this.closeScheduleTestFn);

            this.openDetailsFn = () => {
                console.debug("Showing test details");
                this.showTestDetails = true;
            };
            this.$root.$on('open-view-test-details', this.openDetailsFn);

            this.closeDetailsFn = (openTest) => {
                console.debug("Hiding view test");
                const testToOpen = this.selectedTest;
                this.selectedTest = null;
                this.showTestDetails = false;
                if (openTest && testToOpen) {
                    this.openTest(testToOpen);
                }
            };
            this.$root.$on('close-view-test-details', this.closeDetailsFn);
        },
        beforeDestroy() {
            this.$root.$off('refresh-tests', this.loadTests);
            this.$root.$off("close-show-schedulers", this.closeSchedulersFn);
            this.$root.$off("close-schedule-test", this.closeScheduleTestFn);
            this.$root.$off("open-view-test-details", this.openDetailsFn);
            this.$root.$off("close-view-test-details", this.closeDetailsFn);
        },
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/buttons";
    @import "../styles/pages/profile";
    @import "../styles/pages/tests";
</style>

