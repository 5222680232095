/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const lockTokensByStatusAndToken = /* GraphQL */ `
  query LockTokensByStatusAndToken(
    $status: String
    $lockToken: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLockTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lockTokensByStatusAndToken(
      status: $status
      lockToken: $lockToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        lockToken
        lockedBy
        created
        expectedCompletion
        actualCompletion
        result
        ttl
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $baseType: String
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      baseType: $baseType
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByLinkCode = /* GraphQL */ `
  query UserByLinkCode(
    $baseType: String
    $linkCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByLinkCode(
      baseType: $baseType
      linkCode: $linkCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $baseType: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      baseType: $baseType
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPasswordResetRequest = /* GraphQL */ `
  query GetPasswordResetRequest($id: ID!) {
    getPasswordResetRequest(id: $id) {
      id
      userID
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPasswordResetRequests = /* GraphQL */ `
  query ListPasswordResetRequests(
    $filter: ModelPasswordResetRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPasswordResetRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        reference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const passwordRequestByUserAndDate = /* GraphQL */ `
  query PasswordRequestByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPasswordResetRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    passwordRequestByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        reference
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($email: AWSEmail!) {
    getStudent(email: $email) {
      id
      email
      notificationEmail
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $email: AWSEmail
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        notificationEmail
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentMetrics = /* GraphQL */ `
  query GetStudentMetrics($id: ID!) {
    getStudentMetrics(id: $id) {
      id
      userID
      name
      school
      teacherID
      grade
      totalAnswered
      totalRight
      rightStreak
      wrongStreak
      longestRightStreak
      eloScore
      highestELOScore
      score
      totalDaysActive
      registrationQuestionsAnswered
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      daysActive {
        nextToken
        __typename
      }
      weeksActive {
        nextToken
        __typename
      }
      monthsActive {
        nextToken
        __typename
      }
      yearsActive {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listStudentMetricss = /* GraphQL */ `
  query ListStudentMetricss(
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentMetricss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByUserAndDate = /* GraphQL */ `
  query StudentMetricsByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByScore = /* GraphQL */ `
  query StudentMetricsByScore(
    $baseType: String
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByScore(
      baseType: $baseType
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByGradeAndScore = /* GraphQL */ `
  query StudentMetricsByGradeAndScore(
    $grade: Int
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByGradeAndScore(
      grade: $grade
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByTeacherAndScore = /* GraphQL */ `
  query StudentMetricsByTeacherAndScore(
    $teacherID: ID
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByTeacherAndScore(
      teacherID: $teacherID
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByTeacherGradeAndScore = /* GraphQL */ `
  query StudentMetricsByTeacherGradeAndScore(
    $teacherID: ID
    $gradeScore: ModelStudentMetricsSmbtgasCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByTeacherGradeAndScore(
      teacherID: $teacherID
      gradeScore: $gradeScore
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByTeacherAndName = /* GraphQL */ `
  query StudentMetricsByTeacherAndName(
    $teacherID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByTeacherAndName(
      teacherID: $teacherID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentMetricsByTeacherGradeAndName = /* GraphQL */ `
  query StudentMetricsByTeacherGradeAndName(
    $teacherID: ID
    $gradeName: ModelStudentMetricsSmbtganCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetricsByTeacherGradeAndName(
      teacherID: $teacherID
      gradeName: $gradeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        school
        teacherID
        grade
        totalAnswered
        totalRight
        rightStreak
        wrongStreak
        longestRightStreak
        eloScore
        highestELOScore
        score
        totalDaysActive
        registrationQuestionsAnswered
        totalBlueAwards
        totalBronzeAwards
        totalSilverAwards
        totalGoldAwards
        totalDiamondAwards
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentDayActive = /* GraphQL */ `
  query GetStudentDayActive($id: ID!) {
    getStudentDayActive(id: $id) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudentDayActives = /* GraphQL */ `
  query ListStudentDayActives(
    $filter: ModelStudentDayActiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentDayActives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentMetricsID
        date
        totalAnswered
        totalRight
        rightStreak
        eloScore
        startScore
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentWeekActive = /* GraphQL */ `
  query GetStudentWeekActive($id: ID!) {
    getStudentWeekActive(id: $id) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudentWeekActives = /* GraphQL */ `
  query ListStudentWeekActives(
    $filter: ModelStudentWeekActiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentWeekActives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentMetricsID
        date
        totalAnswered
        totalRight
        rightStreak
        eloScore
        startScore
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentMonthActive = /* GraphQL */ `
  query GetStudentMonthActive($id: ID!) {
    getStudentMonthActive(id: $id) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudentMonthActives = /* GraphQL */ `
  query ListStudentMonthActives(
    $filter: ModelStudentMonthActiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentMonthActives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentMetricsID
        date
        totalAnswered
        totalRight
        rightStreak
        eloScore
        startScore
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentYearActive = /* GraphQL */ `
  query GetStudentYearActive($id: ID!) {
    getStudentYearActive(id: $id) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudentYearActives = /* GraphQL */ `
  query ListStudentYearActives(
    $filter: ModelStudentYearActiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentYearActives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentMetricsID
        date
        totalAnswered
        totalRight
        rightStreak
        eloScore
        startScore
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeacher = /* GraphQL */ `
  query GetTeacher($id: ID!) {
    getTeacher(id: $id) {
      id
      studentEmail
      linkCode
      school
      baseType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeachers = /* GraphQL */ `
  query ListTeachers(
    $filter: ModelTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeachers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentEmail
        linkCode
        school
        baseType
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teacherByLinkCode = /* GraphQL */ `
  query TeacherByLinkCode(
    $baseType: String
    $linkCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teacherByLinkCode(
      baseType: $baseType
      linkCode: $linkCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentEmail
        linkCode
        school
        baseType
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFriend = /* GraphQL */ `
  query GetFriend($id: ID!) {
    getFriend(id: $id) {
      id
      userID
      friendID
      inviterID
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      friend {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        friendID
        inviterID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersFriends = /* GraphQL */ `
  query UsersFriends(
    $userID: ID
    $friendID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersFriends(
      userID: $userID
      friendID: $friendID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        friendID
        inviterID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const friendsUsers = /* GraphQL */ `
  query FriendsUsers(
    $friendID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendsUsers(
      friendID: $friendID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        friendID
        inviterID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      logo
      schoolId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      country
      name
      nameASCII
      province
      lat
      lng
      natEmis
      dataYear
      status
      sector
      typePED
      phasePED
      specialization
      ownerLand
      exDept
      gisSource
      districtMunicipalityName
      localMunicipalityName
      ward
      addressee
      suburb
      townCity
      streetAddress
      postalAddress
      telephone
      quintile
      noFeeSchool
      learners
      educators
      myTutorType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country
        name
        nameASCII
        province
        lat
        lng
        natEmis
        dataYear
        status
        sector
        typePED
        phasePED
        specialization
        ownerLand
        exDept
        gisSource
        districtMunicipalityName
        localMunicipalityName
        ward
        addressee
        suburb
        townCity
        streetAddress
        postalAddress
        telephone
        quintile
        noFeeSchool
        learners
        educators
        myTutorType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSchools = /* GraphQL */ `
  query SearchSchools(
    $filter: SearchableSchoolFilterInput
    $sort: SearchableSchoolSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSchools(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        country
        name
        nameASCII
        province
        lat
        lng
        natEmis
        dataYear
        status
        sector
        typePED
        phasePED
        specialization
        ownerLand
        exDept
        gisSource
        districtMunicipalityName
        localMunicipalityName
        ward
        addressee
        suburb
        townCity
        streetAddress
        postalAddress
        telephone
        quintile
        noFeeSchool
        learners
        educators
        myTutorType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getRegistrationQuestion = /* GraphQL */ `
  query GetRegistrationQuestion($id: ID!) {
    getRegistrationQuestion(id: $id) {
      id
      edition
      registrationSection
      numberInSection
      question
      answerOptions
      placeholderText
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistrationQuestions = /* GraphQL */ `
  query ListRegistrationQuestions(
    $filter: ModelRegistrationQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrationQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationQuestionsBySection = /* GraphQL */ `
  query RegistrationQuestionsBySection(
    $baseType: String
    $registrationSection: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationQuestionsBySection(
      baseType: $baseType
      registrationSection: $registrationSection
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationQuestionsBySectionAndNumber = /* GraphQL */ `
  query RegistrationQuestionsBySectionAndNumber(
    $baseType: String
    $registrationSectionNumberInSection: ModelRegistrationQuestionRqbsanCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationQuestionsBySectionAndNumber(
      baseType: $baseType
      registrationSectionNumberInSection: $registrationSectionNumberInSection
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistrationResponse = /* GraphQL */ `
  query GetRegistrationResponse($id: ID!) {
    getRegistrationResponse(id: $id) {
      id
      userID
      registrationQuestionID
      response
      createdAt
      updatedAt
      registrationQuestion {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listRegistrationResponses = /* GraphQL */ `
  query ListRegistrationResponses(
    $filter: ModelRegistrationResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrationResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        registrationQuestionID
        response
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationResponsesByUserAndDate = /* GraphQL */ `
  query RegistrationResponsesByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationResponsesByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        registrationQuestionID
        response
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      edition
      purpose
      questionURL
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      canRandomiseOptions
      rating
      coveredInGrade
      organisationID
      createdAt
      updatedAt
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionsByPurposeAndRating = /* GraphQL */ `
  query QuestionsByPurposeAndRating(
    $purpose: Purpose
    $rating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByPurposeAndRating(
      purpose: $purpose
      rating: $rating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      baseType
      createdAt
      updatedAt
      taggedQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tagByName = /* GraphQL */ `
  query TagByName(
    $baseType: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagByName(
      baseType: $baseType
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      studentEmail
      userID
      questionID
      answer
      correct
      eloScoreAfterAnswering
      createdAt
      timezoneOffsetHours
      secondsToAnswer
      answeredInQuestionSet
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentEmail
        userID
        questionID
        answer
        correct
        eloScoreAfterAnswering
        createdAt
        timezoneOffsetHours
        secondsToAnswer
        answeredInQuestionSet
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const responsesByUserAndDate = /* GraphQL */ `
  query ResponsesByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentEmail
        userID
        questionID
        answer
        correct
        eloScoreAfterAnswering
        createdAt
        timezoneOffsetHours
        secondsToAnswer
        answeredInQuestionSet
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSolutionView = /* GraphQL */ `
  query GetSolutionView($id: ID!) {
    getSolutionView(id: $id) {
      id
      userID
      questionID
      solutionURL
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listSolutionViews = /* GraphQL */ `
  query ListSolutionViews(
    $filter: ModelSolutionViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolutionViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        questionID
        solutionURL
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const solutionViewsByUserAndQuestion = /* GraphQL */ `
  query SolutionViewsByUserAndQuestion(
    $userID: ID
    $questionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSolutionViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    solutionViewsByUserAndQuestion(
      userID: $userID
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionID
        solutionURL
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const solutionViewsByUserAndDate = /* GraphQL */ `
  query SolutionViewsByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSolutionViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    solutionViewsByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionID
        solutionURL
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEncouragement = /* GraphQL */ `
  query GetEncouragement($id: ID!) {
    getEncouragement(id: $id) {
      id
      edition
      order
      imageURL
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEncouragements = /* GraphQL */ `
  query ListEncouragements(
    $filter: ModelEncouragementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncouragements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const encouragementByOrder = /* GraphQL */ `
  query EncouragementByOrder(
    $baseType: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEncouragementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    encouragementByOrder(
      baseType: $baseType
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEncouragementReceived = /* GraphQL */ `
  query GetEncouragementReceived($id: ID!) {
    getEncouragementReceived(id: $id) {
      id
      userID
      encouragementID
      totalAnswered
      totalWrong
      createdAt
      updatedAt
      encouragement {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listEncouragementReceiveds = /* GraphQL */ `
  query ListEncouragementReceiveds(
    $filter: ModelEncouragementReceivedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncouragementReceiveds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        encouragementID
        totalAnswered
        totalWrong
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const encouragementsReceivedByUserAndDate = /* GraphQL */ `
  query EncouragementsReceivedByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEncouragementReceivedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    encouragementsReceivedByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        encouragementID
        totalAnswered
        totalWrong
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogEntry = /* GraphQL */ `
  query GetLogEntry($id: ID!) {
    getLogEntry(id: $id) {
      id
      userID
      logType
      registrationQuestionID
      questionID
      text
      images
      validationRegex
      placeholderText
      baseType
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const listLogEntrys = /* GraphQL */ `
  query ListLogEntrys(
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        logType
        registrationQuestionID
        questionID
        text
        images
        validationRegex
        placeholderText
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logEntriesByByUserAndQuestion = /* GraphQL */ `
  query LogEntriesByByUserAndQuestion(
    $userID: ID
    $questionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logEntriesByByUserAndQuestion(
      userID: $userID
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        logType
        registrationQuestionID
        questionID
        text
        images
        validationRegex
        placeholderText
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logEntriesByByUserAndDate = /* GraphQL */ `
  query LogEntriesByByUserAndDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logEntriesByByUserAndDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        logType
        registrationQuestionID
        questionID
        text
        images
        validationRegex
        placeholderText
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailyPuzzle = /* GraphQL */ `
  query GetDailyPuzzle($id: ID!) {
    getDailyPuzzle(id: $id) {
      id
      date
      questionID
      language
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listDailyPuzzles = /* GraphQL */ `
  query ListDailyPuzzles(
    $filter: ModelDailyPuzzleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyPuzzles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        questionID
        language
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dailyPuzzleByByDate = /* GraphQL */ `
  query DailyPuzzleByByDate(
    $baseType: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyPuzzleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyPuzzleByByDate(
      baseType: $baseType
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        questionID
        language
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHomePageEvent = /* GraphQL */ `
  query GetHomePageEvent($id: ID!) {
    getHomePageEvent(id: $id) {
      id
      name
      hostedByMyTutor
      imageURL
      entryURL
      startDate
      dateMessage
      displayFrom
      displayTo
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHomePageEvents = /* GraphQL */ `
  query ListHomePageEvents(
    $filter: ModelHomePageEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomePageEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        hostedByMyTutor
        imageURL
        entryURL
        startDate
        dateMessage
        displayFrom
        displayTo
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const homePageEventsToDisplay = /* GraphQL */ `
  query HomePageEventsToDisplay(
    $language: String
    $displayTo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHomePageEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    homePageEventsToDisplay(
      language: $language
      displayTo: $displayTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        hostedByMyTutor
        imageURL
        entryURL
        startDate
        dateMessage
        displayFrom
        displayTo
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorksheet = /* GraphQL */ `
  query GetWorksheet($id: ID!) {
    getWorksheet(id: $id) {
      id
      name
      tag
      instructions
      nextQuestionStrategy
      progression
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorksheets = /* GraphQL */ `
  query ListWorksheets(
    $filter: ModelWorksheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorksheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const worksheetsByPrefix = /* GraphQL */ `
  query WorksheetsByPrefix(
    $baseType: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorksheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    worksheetsByPrefix(
      baseType: $baseType
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const allWorksheetEntriesForUser = /* GraphQL */ `
  query AllWorksheetEntriesForUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWorksheetEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allWorksheetEntriesForUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const worksheetEntryForUser = /* GraphQL */ `
  query WorksheetEntryForUser(
    $worksheetID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorksheetEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    worksheetEntryForUser(
      worksheetID: $worksheetID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorksheetEntry = /* GraphQL */ `
  query GetWorksheetEntry($id: ID!) {
    getWorksheetEntry(id: $id) {
      id
      worksheetID
      userID
      currentLevel
      startedAt
      finishedAt
      streak
      answered
      correct
      createdAt
      owner
      updatedAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listWorksheetEntrys = /* GraphQL */ `
  query ListWorksheetEntrys(
    $filter: ModelWorksheetEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorksheetEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const worksheetAnswersByWorksheetEntryAndDate = /* GraphQL */ `
  query WorksheetAnswersByWorksheetEntryAndDate(
    $worksheetEntryID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorksheetAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    worksheetAnswersByWorksheetEntryAndDate(
      worksheetEntryID: $worksheetEntryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worksheetEntryID
        questionID
        number
        answer
        answeredAt
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorksheetAnswer = /* GraphQL */ `
  query GetWorksheetAnswer($id: ID!) {
    getWorksheetAnswer(id: $id) {
      id
      worksheetEntryID
      questionID
      number
      answer
      answeredAt
      correct
      createdAt
      owner
      updatedAt
      worksheetEntry {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listWorksheetAnswers = /* GraphQL */ `
  query ListWorksheetAnswers(
    $filter: ModelWorksheetAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorksheetAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worksheetEntryID
        questionID
        number
        answer
        answeredAt
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      organiserRegisteredEmail {
        subject
        content
        __typename
      }
      createdAt
      updatedAt
      entries {
        nextToken
        __typename
      }
      questionSets {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventEntry = /* GraphQL */ `
  query GetEventEntry($id: ID!) {
    getEventEntry(id: $id) {
      id
      eventID
      externalSchoolIdentifier
      numberOfEntries
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventEntrys = /* GraphQL */ `
  query ListEventEntrys(
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        externalSchoolIdentifier
        numberOfEntries
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSet = /* GraphQL */ `
  query GetQuestionSet($id: ID!) {
    getQuestionSet(id: $id) {
      id
      name
      organisationID
      eventID
      logo
      publicOrPrivate
      minGrade
      maxGrade
      rating
      suggestedDuration
      registrationStart
      registrationEnd
      registrationRequiresPriorTest
      registrationConfirmationEmail {
        subject
        content
        __typename
      }
      allowRegistrationWithoutAccount
      participationStart
      participationEnd
      invigilation
      instructions
      termsAndConditionsURL
      category
      format
      adaptiveTestConfiguration {
        progression
        maxQuestions
        maxSkips
        __typename
      }
      totalPoints
      markingConfiguration {
        whenToShow
        whenToShowDate
        negativeMarking
        notifyByEmail
        __typename
      }
      createdByID
      createdByEmail {
        subject
        content
        __typename
      }
      updateUserELOAfterTestEnded
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      formulaSheetSlides {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      nextQuestionSets {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionSets = /* GraphQL */ `
  query ListQuestionSets(
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByCreatedDate = /* GraphQL */ `
  query QuestionSetsByCreatedDate(
    $baseType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByCreatedDate(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByCreatedBy = /* GraphQL */ `
  query QuestionSetsByCreatedBy(
    $baseType: String
    $createdByID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByCreatedBy(
      baseType: $baseType
      createdByID: $createdByID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByMinGrade = /* GraphQL */ `
  query QuestionSetsByMinGrade(
    $baseType: String
    $minGrade: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByMinGrade(
      baseType: $baseType
      minGrade: $minGrade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByMaxGrade = /* GraphQL */ `
  query QuestionSetsByMaxGrade(
    $baseType: String
    $maxGrade: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByMaxGrade(
      baseType: $baseType
      maxGrade: $maxGrade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByPublicOrPrivate = /* GraphQL */ `
  query QuestionSetsByPublicOrPrivate(
    $baseType: String
    $publicOrPrivate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByPublicOrPrivate(
      baseType: $baseType
      publicOrPrivate: $publicOrPrivate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByRegistrationEnd = /* GraphQL */ `
  query QuestionSetsByRegistrationEnd(
    $publicOrPrivate: String
    $registrationEnd: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByRegistrationEnd(
      publicOrPrivate: $publicOrPrivate
      registrationEnd: $registrationEnd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByRating = /* GraphQL */ `
  query QuestionSetsByRating(
    $publicOrPrivate: String
    $rating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByRating(
      publicOrPrivate: $publicOrPrivate
      rating: $rating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsByRegistrationWithoutAccount = /* GraphQL */ `
  query QuestionSetsByRegistrationWithoutAccount(
    $allowRegistrationWithoutAccount: String
    $registrationEnd: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByRegistrationWithoutAccount(
      allowRegistrationWithoutAccount: $allowRegistrationWithoutAccount
      registrationEnd: $registrationEnd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetSection = /* GraphQL */ `
  query GetQuestionSetSection($id: ID!) {
    getQuestionSetSection(id: $id) {
      id
      questionSetID
      number
      suggestedDuration
      createdAt
      updatedAt
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionSetSections = /* GraphQL */ `
  query ListQuestionSetSections(
    $filter: ModelQuestionSetSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        number
        suggestedDuration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetQuestion = /* GraphQL */ `
  query GetQuestionSetQuestion($id: ID!) {
    getQuestionSetQuestion(id: $id) {
      id
      questionSetSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionSetQuestions = /* GraphQL */ `
  query ListQuestionSetQuestions(
    $filter: ModelQuestionSetQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetFormulaSheetSlide = /* GraphQL */ `
  query GetQuestionSetFormulaSheetSlide($id: ID!) {
    getQuestionSetFormulaSheetSlide(id: $id) {
      id
      number
      questionSetID
      slideURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestionSetFormulaSheetSlides = /* GraphQL */ `
  query ListQuestionSetFormulaSheetSlides(
    $filter: ModelQuestionSetFormulaSheetSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetFormulaSheetSlides(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        questionSetID
        slideURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetPracticeQuestion = /* GraphQL */ `
  query GetQuestionSetPracticeQuestion($id: ID!) {
    getQuestionSetPracticeQuestion(id: $id) {
      id
      number
      questionSetID
      questionID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestionSetPracticeQuestions = /* GraphQL */ `
  query ListQuestionSetPracticeQuestions(
    $filter: ModelQuestionSetPracticeQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetPracticeQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        questionSetID
        questionID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetPracticeQuestionsByQuestionSet = /* GraphQL */ `
  query QuestionSetPracticeQuestionsByQuestionSet(
    $baseType: String
    $questionSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetPracticeQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetPracticeQuestionsByQuestionSet(
      baseType: $baseType
      questionSetID: $questionSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        questionSetID
        questionID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetProgression = /* GraphQL */ `
  query GetQuestionSetProgression($id: ID!) {
    getQuestionSetProgression(id: $id) {
      id
      fromQuestionSetID
      toQuestionSetID
      markFrom
      markTo
      email {
        subject
        content
        __typename
      }
      message
      baseType
      createdAt
      updatedAt
      from {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionSetProgressions = /* GraphQL */ `
  query ListQuestionSetProgressions(
    $filter: ModelQuestionSetProgressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetProgressions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromQuestionSetID
        toQuestionSetID
        markFrom
        markTo
        message
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetProgressionsByFromID = /* GraphQL */ `
  query QuestionSetProgressionsByFromID(
    $baseType: String
    $fromQuestionSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetProgressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetProgressionsByFromID(
      baseType: $baseType
      fromQuestionSetID: $fromQuestionSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromQuestionSetID
        toQuestionSetID
        markFrom
        markTo
        message
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionSetScheduler = /* GraphQL */ `
  query GetQuestionSetScheduler($id: ID!) {
    getQuestionSetScheduler(id: $id) {
      id
      questionSetID
      studentEmail
      userID
      authorisedByID
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listQuestionSetSchedulers = /* GraphQL */ `
  query ListQuestionSetSchedulers(
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionSetSchedulers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersWhoCanScheduleTestForQuestionSet = /* GraphQL */ `
  query UsersWhoCanScheduleTestForQuestionSet(
    $baseType: String
    $questionSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersWhoCanScheduleTestForQuestionSet(
      baseType: $baseType
      questionSetID: $questionSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const schedulersForQuestionSet = /* GraphQL */ `
  query SchedulersForQuestionSet(
    $questionSetID: ID
    $studentEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulersForQuestionSet(
      questionSetID: $questionSetID
      studentEmail: $studentEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const schedulersForQuestionSetByID = /* GraphQL */ `
  query SchedulersForQuestionSetByID(
    $questionSetID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulersForQuestionSetByID(
      questionSetID: $questionSetID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsUserCanSchedule = /* GraphQL */ `
  query QuestionSetsUserCanSchedule(
    $baseType: String
    $studentEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsUserCanSchedule(
      baseType: $baseType
      studentEmail: $studentEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionSetsUserCanScheduleByID = /* GraphQL */ `
  query QuestionSetsUserCanScheduleByID(
    $baseType: String
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsUserCanScheduleByID(
      baseType: $baseType
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const schedulersAuthorisedByUser = /* GraphQL */ `
  query SchedulersAuthorisedByUser(
    $baseType: String
    $authorisedByID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulersAuthorisedByUser(
      baseType: $baseType
      authorisedByID: $authorisedByID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        studentEmail
        userID
        authorisedByID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByCreatedDate = /* GraphQL */ `
  query TestsByCreatedDate(
    $baseType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByCreatedDate(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByQuestionSet = /* GraphQL */ `
  query TestsByQuestionSet(
    $baseType: String
    $questionSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByQuestionSet(
      baseType: $baseType
      questionSetID: $questionSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByMinGrade = /* GraphQL */ `
  query TestsByMinGrade(
    $baseType: String
    $minGrade: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByMinGrade(
      baseType: $baseType
      minGrade: $minGrade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByMaxGrade = /* GraphQL */ `
  query TestsByMaxGrade(
    $baseType: String
    $maxGrade: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByMaxGrade(
      baseType: $baseType
      maxGrade: $maxGrade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      questionSetID
      organisationID
      name
      minGrade
      maxGrade
      date
      totalRegistered
      totalStarted
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      testHosts {
        nextToken
        __typename
      }
      entries {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testSectionsByScheduledToEndAt = /* GraphQL */ `
  query TestSectionsByScheduledToEndAt(
    $ended: String
    $scheduledToEndAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testSectionsByScheduledToEndAt(
      ended: $ended
      scheduledToEndAt: $scheduledToEndAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestSection = /* GraphQL */ `
  query GetTestSection($id: ID!) {
    getTestSection(id: $id) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTestSections = /* GraphQL */ `
  query ListTestSections(
    $filter: ModelTestSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestSectionPerformance = /* GraphQL */ `
  query GetTestSectionPerformance($id: ID!) {
    getTestSectionPerformance(id: $id) {
      id
      testSectionID
      totalStarted
      totalSubmitted
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTestSectionPerformances = /* GraphQL */ `
  query ListTestSectionPerformances(
    $filter: ModelTestSectionPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestSectionPerformances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testSectionID
        totalStarted
        totalSubmitted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testSectionPerformanceByTestSection = /* GraphQL */ `
  query TestSectionPerformanceByTestSection(
    $baseType: String
    $testSectionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestSectionPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testSectionPerformanceByTestSection(
      baseType: $baseType
      testSectionID: $testSectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testSectionID
        totalStarted
        totalSubmitted
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestQuestion = /* GraphQL */ `
  query GetTestQuestion($id: ID!) {
    getTestQuestion(id: $id) {
      id
      testSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTestQuestions = /* GraphQL */ `
  query ListTestQuestions(
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestQuestionPerformance = /* GraphQL */ `
  query GetTestQuestionPerformance($id: ID!) {
    getTestQuestionPerformance(id: $id) {
      id
      testQuestionID
      totalAnswered
      answerCounts
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTestQuestionPerformances = /* GraphQL */ `
  query ListTestQuestionPerformances(
    $filter: ModelTestQuestionPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestQuestionPerformances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testQuestionID
        totalAnswered
        answerCounts
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testQuestionPerformanceByTestQuestion = /* GraphQL */ `
  query TestQuestionPerformanceByTestQuestion(
    $baseType: String
    $testQuestionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testQuestionPerformanceByTestQuestion(
      baseType: $baseType
      testQuestionID: $testQuestionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testQuestionID
        totalAnswered
        answerCounts
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testForTeacher = /* GraphQL */ `
  query TestForTeacher(
    $testID: ID
    $teacherID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testForTeacher(
      testID: $testID
      teacherID: $teacherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsByTeacherAndDate = /* GraphQL */ `
  query TestsByTeacherAndDate(
    $teacherID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByTeacherAndDate(
      teacherID: $teacherID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testHostsByTestAndHost = /* GraphQL */ `
  query TestHostsByTestAndHost(
    $testID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testHostsByTestAndHost(
      testID: $testID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testHostsByHostAndDate = /* GraphQL */ `
  query TestHostsByHostAndDate(
    $userID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testHostsByHostAndDate(
      userID: $userID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testHostByLinkCode = /* GraphQL */ `
  query TestHostByLinkCode(
    $baseType: String
    $linkCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testHostByLinkCode(
      baseType: $baseType
      linkCode: $linkCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestHost = /* GraphQL */ `
  query GetTestHost($id: ID!) {
    getTestHost(id: $id) {
      id
      testID
      teacherID
      userID
      linkCode
      testDate
      owner
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTestHosts = /* GraphQL */ `
  query ListTestHosts(
    $filter: ModelTestHostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestHosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testsForTeacherToMark = /* GraphQL */ `
  query TestsForTeacherToMark(
    $baseType: String
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestMarkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsForTeacherToMark(
      baseType: $baseType
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        userID
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestMarker = /* GraphQL */ `
  query GetTestMarker($id: ID!) {
    getTestMarker(id: $id) {
      id
      testID
      userID
      baseType
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listTestMarkers = /* GraphQL */ `
  query ListTestMarkers(
    $filter: ModelTestMarkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestMarkers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        userID
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByTestHostAndUser = /* GraphQL */ `
  query TestEntriesByTestHostAndUser(
    $testHostID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByTestHostAndUser(
      testHostID: $testHostID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByUser = /* GraphQL */ `
  query TestEntriesByUser(
    $baseType: String
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByUser(
      baseType: $baseType
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByTest = /* GraphQL */ `
  query TestEntriesByTest(
    $baseType: String
    $testID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByTest(
      baseType: $baseType
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByTestAndScore = /* GraphQL */ `
  query TestEntriesByTestAndScore(
    $testID: ID
    $score: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByTestAndScore(
      testID: $testID
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByTestScoreAndTime = /* GraphQL */ `
  query TestEntriesByTestScoreAndTime(
    $testID: ID
    $scoreAndTime: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByTestScoreAndTime(
      testID: $testID
      scoreAndTime: $scoreAndTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByTestAndMarkingState = /* GraphQL */ `
  query TestEntriesByTestAndMarkingState(
    $testID: ID
    $marked: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByTestAndMarkingState(
      testID: $testID
      marked: $marked
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntriesByScheduledToFinishAt = /* GraphQL */ `
  query TestEntriesByScheduledToFinishAt(
    $marked: String
    $scheduledToFinishAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntriesByScheduledToFinishAt(
      marked: $marked
      scheduledToFinishAt: $scheduledToFinishAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestEntry = /* GraphQL */ `
  query GetTestEntry($id: ID!) {
    getTestEntry(id: $id) {
      id
      testID
      testHostID
      userID
      school
      score
      scoreAndTime
      currentLevel
      questionsSkipped
      questionsAnswered
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      affiliate
      showOnLeaderboard
      baseType
      marked
      manuallyMarkedDate
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testHost {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      entrySections {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTestEntrys = /* GraphQL */ `
  query ListTestEntrys(
    $filter: ModelTestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestEntrySection = /* GraphQL */ `
  query GetTestEntrySection($id: ID!) {
    getTestEntrySection(id: $id) {
      id
      userID
      testEntryID
      testSectionID
      questionsToAsk {
        level
        testQuestionID
        __typename
      }
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      startedAt
      timeTaken
      baseType
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      testAnswers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTestEntrySections = /* GraphQL */ `
  query ListTestEntrySections(
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestEntrySections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntrySectionsByTestSection = /* GraphQL */ `
  query TestEntrySectionsByTestSection(
    $baseType: String
    $testSectionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntrySectionsByTestSection(
      baseType: $baseType
      testSectionID: $testSectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntrySectionsBySectionIDAndUser = /* GraphQL */ `
  query TestEntrySectionsBySectionIDAndUser(
    $testSectionID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntrySectionsBySectionIDAndUser(
      testSectionID: $testSectionID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntrySectionsBySectionIDAndUserAndDate = /* GraphQL */ `
  query TestEntrySectionsBySectionIDAndUserAndDate(
    $testSectionID: ID
    $userIDCreatedAt: ModelTestEntrySectionTesbidaubdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntrySectionsBySectionIDAndUserAndDate(
      testSectionID: $testSectionID
      userIDCreatedAt: $userIDCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntrySectionsBySectionScore = /* GraphQL */ `
  query TestEntrySectionsBySectionScore(
    $testSectionID: ID
    $sectionScore: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntrySectionsBySectionScore(
      testSectionID: $testSectionID
      sectionScore: $sectionScore
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntrySectionsBySectionScoreAndTime = /* GraphQL */ `
  query TestEntrySectionsBySectionScoreAndTime(
    $testSectionID: ID
    $sectionScoreAndTime: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntrySectionsBySectionScoreAndTime(
      testSectionID: $testSectionID
      sectionScoreAndTime: $sectionScoreAndTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestAnswer = /* GraphQL */ `
  query GetTestAnswer($id: ID!) {
    getTestAnswer(id: $id) {
      id
      userID
      testQuestionID
      testEntrySectionID
      answer
      answerPhotoS3Key
      answeredDate
      correct
      score
      markersComments
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testQuestion {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      testEntrySection {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listTestAnswers = /* GraphQL */ `
  query ListTestAnswers(
    $filter: ModelTestAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        testQuestionID
        testEntrySectionID
        answer
        answerPhotoS3Key
        answeredDate
        correct
        score
        markersComments
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testPracticeQuestionsByTest = /* GraphQL */ `
  query TestPracticeQuestionsByTest(
    $baseType: String
    $testID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestPracticeQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testPracticeQuestionsByTest(
      baseType: $baseType
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        testID
        questionID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestPracticeQuestion = /* GraphQL */ `
  query GetTestPracticeQuestion($id: ID!) {
    getTestPracticeQuestion(id: $id) {
      id
      number
      testID
      questionID
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listTestPracticeQuestions = /* GraphQL */ `
  query ListTestPracticeQuestions(
    $filter: ModelTestPracticeQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestPracticeQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        testID
        questionID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestEntryELORecalc = /* GraphQL */ `
  query GetTestEntryELORecalc($id: ID!) {
    getTestEntryELORecalc(id: $id) {
      id
      userID
      questionSetID
      testEntryID
      eloBefore
      eloAfter
      questionsAnsweredBeforeTest
      questionsInTest
      testDifficulty
      score
      parScore
      inertia
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listTestEntryELORecalcs = /* GraphQL */ `
  query ListTestEntryELORecalcs(
    $filter: ModelTestEntryELORecalcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestEntryELORecalcs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionSetID
        testEntryID
        eloBefore
        eloAfter
        questionsAnsweredBeforeTest
        questionsInTest
        testDifficulty
        score
        parScore
        inertia
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntryELORecalcsByQuestionSet = /* GraphQL */ `
  query TestEntryELORecalcsByQuestionSet(
    $baseType: String
    $questionSetID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryELORecalcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntryELORecalcsByQuestionSet(
      baseType: $baseType
      questionSetID: $questionSetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionSetID
        testEntryID
        eloBefore
        eloAfter
        questionsAnsweredBeforeTest
        questionsInTest
        testDifficulty
        score
        parScore
        inertia
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntryELORecalcsByUser = /* GraphQL */ `
  query TestEntryELORecalcsByUser(
    $baseType: String
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryELORecalcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntryELORecalcsByUser(
      baseType: $baseType
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionSetID
        testEntryID
        eloBefore
        eloAfter
        questionsAnsweredBeforeTest
        questionsInTest
        testDifficulty
        score
        parScore
        inertia
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testEntryELORecalcsByUserAndTestEntry = /* GraphQL */ `
  query TestEntryELORecalcsByUserAndTestEntry(
    $userID: ID
    $testEntryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestEntryELORecalcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testEntryELORecalcsByUserAndTestEntry(
      userID: $userID
      testEntryID: $testEntryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        questionSetID
        testEntryID
        eloBefore
        eloAfter
        questionsAnsweredBeforeTest
        questionsInTest
        testDifficulty
        score
        parScore
        inertia
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFileDownloaded = /* GraphQL */ `
  query GetFileDownloaded($id: ID!) {
    getFileDownloaded(id: $id) {
      id
      userID
      fileURL
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const listFileDownloadeds = /* GraphQL */ `
  query ListFileDownloadeds(
    $filter: ModelFileDownloadedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileDownloadeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        fileURL
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isUsernameTaken = /* GraphQL */ `
  query IsUsernameTaken($username: String) {
    isUsernameTaken(username: $username)
  }
`;
export const canMigrateCognitoUser = /* GraphQL */ `
  query CanMigrateCognitoUser($username: String!) {
    canMigrateCognitoUser(username: $username)
  }
`;
export const getNextMessages = /* GraphQL */ `
  query GetNextMessages(
    $userID: ID!
    $response: String!
    $timezoneOffsetHours: Int!
  ) {
    getNextMessages(
      userID: $userID
      response: $response
      timezoneOffsetHours: $timezoneOffsetHours
    ) {
      id
      userID
      logType
      registrationQuestionID
      questionID
      text
      images
      validationRegex
      placeholderText
      baseType
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($metricsID: ID!, $grade: Int, $country: String) {
    getLeaderboard(metricsID: $metricsID, grade: $grade, country: $country) {
      smId
      position
      percentile
      firstName
      surname
      nickname
      school
      teacher
      grade
      totalAnswered
      totalRight
      rightStreak
      longestRightStreak
      score
      totalDaysActive
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
      __typename
    }
  }
`;
export const getLeaderboardForTest = /* GraphQL */ `
  query GetLeaderboardForTest(
    $userID: ID!
    $questionSetID: ID!
    $leaderboardIndex: TestLeaderboardIndex!
    $school: String
    $grade: Int
    $country: String
  ) {
    getLeaderboardForTest(
      userID: $userID
      questionSetID: $questionSetID
      leaderboardIndex: $leaderboardIndex
      school: $school
      grade: $grade
      country: $country
    ) {
      id
      position
      email
      firstName
      surname
      nickname
      grade
      school
      country
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      percentile
      __typename
    }
  }
`;
export const getSchoolLeaderboardForTest = /* GraphQL */ `
  query GetSchoolLeaderboardForTest(
    $userID: ID!
    $questionSetID: ID!
    $leaderboardIndex: TestLeaderboardIndex!
    $school: String
  ) {
    getSchoolLeaderboardForTest(
      userID: $userID
      questionSetID: $questionSetID
      leaderboardIndex: $leaderboardIndex
      school: $school
    ) {
      position
      school
      top10
      entries
      averageScore
      percentile
      __typename
    }
  }
`;
export const getMyStudents = /* GraphQL */ `
  query GetMyStudents(
    $teacherID: ID!
    $grade: Int
    $timezoneOffsetHours: Int!
  ) {
    getMyStudents(
      teacherID: $teacherID
      grade: $grade
      timezoneOffsetHours: $timezoneOffsetHours
    ) {
      id
      student
      school
      grade
      score
      performanceLast7Days {
        totalAnswered
        totalRight
        longestRightStreak
        highestEloScore
        endScore
        __typename
      }
      performanceToday {
        totalAnswered
        totalRight
        longestRightStreak
        highestEloScore
        endScore
        __typename
      }
      __typename
    }
  }
`;
export const getMyStudentsTestEntries = /* GraphQL */ `
  query GetMyStudentsTestEntries($teacherID: ID!) {
    getMyStudentsTestEntries(teacherID: $teacherID) {
      testName
      id
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      email
      firstName
      surname
      nickname
      grade
      entrySections {
        id
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const getMyStudentsWorksheetEntries = /* GraphQL */ `
  query GetMyStudentsWorksheetEntries($teacherID: ID!) {
    getMyStudentsWorksheetEntries(teacherID: $teacherID) {
      worksheetName
      id
      email
      firstName
      surname
      nickname
      grade
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      __typename
    }
  }
`;
export const getFriendProfile = /* GraphQL */ `
  query GetFriendProfile($friendID: ID!) {
    getFriendProfile(friendID: $friendID) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getTestSectionForEntrant = /* GraphQL */ `
  query GetTestSectionForEntrant($testEntrySectionID: ID!) {
    getTestSectionForEntrant(testEntrySectionID: $testEntrySectionID) {
      id
      testSectionID
      number
      duration
      startedAt
      endedAt
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      timeTaken
      answers {
        id
        number
        points
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        answer
        answerPhotoS3Key
        answerDate
        correct
        score
        markersComments
        __typename
      }
      performance {
        id
        testSectionID
        totalStarted
        totalSubmitted
        __typename
      }
      __typename
    }
  }
`;
export const getTestLeaderboardForEntrant = /* GraphQL */ `
  query GetTestLeaderboardForEntrant($userID: ID!, $testID: ID!) {
    getTestLeaderboardForEntrant(userID: $userID, testID: $testID) {
      id
      position
      email
      firstName
      surname
      nickname
      grade
      school
      country
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      percentile
      __typename
    }
  }
`;
export const getTestSectionLeaderboardForEntrant = /* GraphQL */ `
  query GetTestSectionLeaderboardForEntrant($userID: ID!, $testSectionID: ID!) {
    getTestSectionLeaderboardForEntrant(
      userID: $userID
      testSectionID: $testSectionID
    ) {
      id
      position
      firstName
      surname
      nickname
      grade
      school
      country
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      timeTaken
      percentile
      __typename
    }
  }
`;
export const getTestResultForEntrant = /* GraphQL */ `
  query GetTestResultForEntrant($testEntryID: ID!) {
    getTestResultForEntrant(testEntryID: $testEntryID) {
      score
      message
      __typename
    }
  }
`;
export const getPracticeQuestions = /* GraphQL */ `
  query GetPracticeQuestions($testID: ID!) {
    getPracticeQuestions(testID: $testID) {
      id
      number
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      __typename
    }
  }
`;
export const getTestForTeacher = /* GraphQL */ `
  query GetTestForTeacher($teacherID: ID!, $testID: ID!) {
    getTestForTeacher(teacherID: $teacherID, testID: $testID) {
      sections {
        id
        testSectionID
        number
        duration
        startedAt
        endedAt
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
        __typename
      }
      entries {
        testName
        id
        score
        scoreAndTime
        questionsAnswered
        timeTaken
        email
        firstName
        surname
        nickname
        grade
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const getTestEntriesForTeacher = /* GraphQL */ `
  query GetTestEntriesForTeacher($teacherID: ID!, $testID: ID!) {
    getTestEntriesForTeacher(teacherID: $teacherID, testID: $testID) {
      testName
      id
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      email
      firstName
      surname
      nickname
      grade
      entrySections {
        id
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const getTestEntryForTeacherToMark = /* GraphQL */ `
  query GetTestEntryForTeacherToMark($markerID: ID!, $testEntryID: ID!) {
    getTestEntryForTeacherToMark(
      markerID: $markerID
      testEntryID: $testEntryID
    ) {
      id
      score
      questionsAnswered
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      marked
      manuallyMarkedDate
      email
      cognitoIdentityID
      entrySections {
        id
        testSectionID
        number
        duration
        startedAt
        endedAt
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
        __typename
      }
      __typename
    }
  }
`;
export const getTodaysPuzzle = /* GraphQL */ `
  query GetTodaysPuzzle(
    $timezoneOffsetHours: Int
    $questionID: ID
    $date: AWSDate
    $language: String
  ) {
    getTodaysPuzzle(
      timezoneOffsetHours: $timezoneOffsetHours
      questionID: $questionID
      date: $date
      language: $language
    ) {
      id
      edition
      purpose
      questionURL
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      canRandomiseOptions
      rating
      coveredInGrade
      organisationID
      createdAt
      updatedAt
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getTestsCanEnterWithoutAccount = /* GraphQL */ `
  query GetTestsCanEnterWithoutAccount {
    getTestsCanEnterWithoutAccount {
      name
      logo
      suggestedDuration
      participationEnd
      minGrade
      maxGrade
      termsAndConditionsURL
      testHostLinkCode
      __typename
    }
  }
`;
export const getTeacherDetails = /* GraphQL */ `
  query GetTeacherDetails($linkCode: String) {
    getTeacherDetails(linkCode: $linkCode) {
      name
      school
      country
      __typename
    }
  }
`;
export const openWorksheetEntry = /* GraphQL */ `
  query OpenWorksheetEntry($worksheetEntryID: ID!) {
    openWorksheetEntry(worksheetEntryID: $worksheetEntryID) {
      id
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        __typename
      }
      __typename
    }
  }
`;
export const getWorksheetEntryHistory = /* GraphQL */ `
  query GetWorksheetEntryHistory($worksheetEntryID: ID!, $nextToken: String) {
    getWorksheetEntryHistory(
      worksheetEntryID: $worksheetEntryID
      nextToken: $nextToken
    ) {
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const openWorksheetEntryForTeacher = /* GraphQL */ `
  query OpenWorksheetEntryForTeacher($worksheetEntryID: ID!) {
    openWorksheetEntryForTeacher(worksheetEntryID: $worksheetEntryID) {
      id
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        __typename
      }
      __typename
    }
  }
`;
