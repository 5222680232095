<template>
    <div id="home-wrapper">
        <div id="wrapper">
            <header id="header">
                <div class="container">
<!--                    <div class="header-holder hidden-desktop">-->
<!--                        <div class="mobile-language-selector">-->
<!--                            <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">-->
<!--                                <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="header-holder">
                        <div class="logo"><a href="#"><img src="../assets/logo-home.svg" alt="MyTutorChat" width="217" height="57"></a></div>
<!--                        <div class="hidden-mobile">-->
<!--                            <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">-->
<!--                                <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}-->
<!--                            </button>-->
<!--                        </div>-->
                        <div class="box" v-on:click="showApp">
                            <a class="login-link" v-on:click="$router.push({ path: '/register' })">{{
                                $t('homeRegisterMsg')}}</a> |
                            <a class="login-link" v-on:click="$router.push({ path: '/signin' })">{{
                                $t('homeSignInMsg')}}</a>
                        </div>
                    </div>
                </div>
            </header>
            <main id="main">
                <section class="hero-section viewport-section"><span class="hero-section__decor viewport-section"><img src="../assets/bg-number-transparent.png" alt="number" width="975" height="656"></span>
                    <div class="container"> 
                        <div class="hero-section__columns">
                            <div class="hero-section__content">
                                <h1>{{ $t('homeHeroMsg')}}</h1>
                                <div class="hero-section__body">
                                    <p>{{$t('homeTaglineMsg')}}</p>
                                </div>
                                <div class="button-wrap"><a class="btn btn-yellow lightbox" v-on:click="launchVideo" href="#"><span class="text"><img src="../assets/play.svg" alt="image" width="20" height="22"> {{ $t('homeCTAWatchVideoMsg')}}</span><span class="text-hover"><img src="../assets/play.svg" alt="image" width="20" height="22"> {{ $t('homeCTAWatchVideoMsg')}}</span></a></div>
                            </div>
                            <div class="hero-section__visual">
                                <div class="hero-section__visual-holder"><img src="../assets/image-01.png" srcset="../assets/image-01-2x.png 2x" alt="image" width="530" height="720"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="modal" v-if="showVideo">
                    <div class="modal-wrapper">
                        <div class="modal-content">
                            <div class="modal-content__video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/83ohJX8HS5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                            <div class="modal-content__close"><img src="../assets/cross.svg" alt="Close" v-on:click="closeVideo" /></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="popup-holder">
                    <div class="lightbox-video" id="popup1">
                        <div class="video-wrap"><iframe width="910" height="506" src="https://www.youtube.com/embed/83ohJX8HS5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    </div>
                </div> -->
                <section class="step-section" id="step-section">
                    <div class="container">
                        <div id="heading-section">
                            <div class="heading-section viewport-section">
                                <h2>{{ $t('homeHowItWorksMsg')}}</h2>
                            </div>
                        </div>
                        <div class="gallery-step">
                            <div class="gallery-step-wrapper">
                                <div class="gallery-step-slide" id="step-01">
                                    <div class="step-item">
                                        <div class="step-item__columns">
                                            <div class="step-item__col">
                                                <div class="step-item__title">
                                                    <h3>1. {{ $t('homeHowToRegisterMsg')}}</h3>
                                                </div>
                                                <div class="step-item__content">
                                                    <p>{{ $t('homeHowToRegisterPoint1Msg')}}</p>
                                                    <p>{{ $t('homeHowToRegisterPoint2Msg')}}</p>
                                                    <p>{{ $t('homeHowToRegisterPoint3Msg')}}</p>
                                                </div>
                                            </div>
                                            <div class="step-item__col">
                                                <div class="step-item__visual"><img src="../assets/image-02.png" srcset="../assets/image-02-2x.png 2x" alt="image" width="296" height="600"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="gallery-step-slide" id="step-02">
                                    <div class="step-item">
                                        <div class="step-item__columns">
                                            <div class="step-item__col">
                                                <div class="step-item__title">
                                                    <h3>2. {{ $t('homeHowToPracticeMsg')}}</h3>
                                                </div>
                                                <div class="step-item__content">
                                                    <p>{{ $t('homeHowToPracticePoint1Msg')}}</p>
                                                    <p>{{ $t('homeHowToPracticePoint2Msg')}}</p>
                                                </div>
                                            </div>
                                            <div class="step-item__col">
                                                <div class="step-item__visual"><img src="../assets/image-03.png" srcset="../assets/image-03-2x.png 2x" alt="image" width="296" height="600"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="gallery-step-slide" id="step-03">
                                    <div class="step-item">
                                        <div class="step-item__columns">
                                            <div class="step-item__col">
                                                <div class="step-item__title">
                                                    <h3>3. {{ $t('homeHowToParticipateMsg')}}</h3>
                                                </div>
                                                <div class="step-item__content">
                                                    <p>{{ $t('homeHowToParticipatePoint1Msg')}}</p>
                                                    <p>{{ $t('homeHowToParticipatePoint2Msg')}}</p>
                                                </div>
                                            </div>
                                            <div class="step-item__col">
                                                <div class="step-item__visual"><img src="../assets/image-04.png" srcset="../assets/image-04-2x.png 2x" alt="image" width="296" height="600"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="gallery-step-slide" id="step-04">
                                    <div class="step-item">
                                        <div class="step-item__columns">
                                            <div class="step-item__col">
                                                <div class="step-item__title">
                                                    <h3>4. {{ $t('homeHowToCheckStatsMsg')}}</h3>
                                                </div>
                                                <div class="step-item__content">
                                                    <p>{{ $t('homeHowToCheckStatsPoint1Msg')}}</p>
                                                    <p>{{ $t('homeHowToCheckStatsPoint2Msg')}}</p>
                                                </div>
                                            </div>
                                            <div class="step-item__col">
                                                <div class="step-item__visual"><img src="../assets/image-05.png" srcset="../assets/image-05-2x.png 2x" alt="image" width="296" height="600"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="gallery-step-pagination" id="gallery-step-pagination">
                                <li><a class="anchor-bullet" href="#step-01"></a></li>
                                <li><a class="anchor-bullet" href="#step-02"></a></li>
                                <li><a class="anchor-bullet" href="#step-03"></a></li>
                                <li><a class="anchor-bullet" href="#step-04"></a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="text-and-image">
                    <div class="container">
                        <div class="text-and-image__holder">
                            <div class="heading-section viewport-section">
                                <h2>{{ $t('homeGiveItAGoMsg')}}</h2>
                            </div>
                            <div class="text-and-image__visual">
                                <!-- <img class="viewport-section" src="../assets/image-06.png" srcset="../assets/image-06-2x.png 2x" alt="image-06" width="600" height="500"> -->
                                <div class="viewport-section section-puzzle__puzzle">
                                    <div class="section-puzzle__puzzle-wrapper">
                                        <daily-puzzle class=""></daily-puzzle>
                                    </div>
                                </div>
                            </div>
                            <div class="text-and-image__content viewport-section">
                                <p>{{ $t('homeGiveItAGoPoint1Msg')}}</p>
                                <p>{{ $t('homeGiveItAGoPoint2Msg')}}</p>
                            </div>
                            <div class="text-and-image__footer viewport-section"><span class="text-and-image__footer-text">{{ $t('homeGiveItAGoTryMoreMsg')}}</span><a class="btn btn-sm btn-yellow" v-on:click="showApp" href="#" data-text="Join For Free">{{ $t('homeGiveItAGoJoinForFreeMsg')}}</a></div>
                        </div>
                    </div>
                </section>
                <section class="tutor-chat-section">
                    <div class="container">
                        <h2 class="viewport-section">{{ $t('homeWhyJoinMsg')}}</h2>
                        <ul class="items-list">
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-01.png" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinQuestionsMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinQuestionsPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-02.svg" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinTestsMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinTestsPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-03.svg" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinEventsMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinEventsPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-04.svg" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinSAMFMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinSAMFPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-05.svg" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinProgressMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinProgressPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item viewport-section">
                                    <div class="item__icon"><img src="../assets/MYTUTOR ICONS-06.svg" alt="icon"/></div>
                                    <div class="item__body">
                                        <h3>{{ $t('homeWhyJoinCompetitionsMsg')}}</h3>
                                    </div>
                                    <div class="item__text">
                                        <p>{{ $t('homeWhyJoinCompetitionsPoint1Msg')}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section id="events" class="events-section" v-if="events.length > 0">
                    <div class="container">
                        <h2 class="viewport-section">{{ $t('homeEventsMsg')}}</h2>
                        <div class="event-cards-list event-card-gallery">
                            <div class="slide" v-for="(event, index) of events" v-bind:key="index">
                                <div class="event-card viewport-section">
                                    <a class="event-card__visual" href="${event.entryURL}" target="_blank">
                                        <span v-if="event.hostedByMyTutor" class="event-card__label">{{ $t('homeEventsMyTutorHostedMsg')}}</span>
                                        <div>
                                            <span class="event-card__logo-helper"></span>
                                            <img v-bind:src="event.imageURL" alt="image" width="280" height="250"/>
                                        </div>
                                    </a>
                                    <div class="event-card__body">
                                        <h3><a v-bind:href="event.entryURL" target="_blank">{{event.name}}</a></h3>
                                        <time v-bind:datetime="event.startDate">{{event.dateMessage}}</time>
                                    </div>
                                    <div class="event-card__button-wrap">
                                        <a class="btn btn-sm btn-yellow-full" v-bind:href="event.entryURL" target="_blank" data-text="Enter">{{ $t('homeEventsEnterMsg')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer id="footer">
                <div class="container">
                    <div class="footer-holder">
                        <div class="footer-logo"><a href="#"><img src="../assets/logo-home.svg" alt="MyTutorChat"></a></div>
                        <div class="footer-box">
                            <div class="footer-box__col">
                                <nav class="footer-menu">
                                    <h4>{{ $t('homeQuickLinksMsg')}}</h4>
                                    <ul class="menu">
                                        <li><a href="/about/" target="_blank">{{ $t('homeQuickLinksAboutMsg')}}</a></li>
                                        <li><a href="/contact-us/" target="_blank">{{ $t('homeQuickLinksContactMsg')}}</a></li>
                                        <li><a href="/privacy-policy/" target="_blank">{{ $t('homeQuickLinksPrivacyMsg')}}</a></li>
                                        <li><a href="/terms-conditions/" target="_blank">{{ $t('homeQuickLinksTermsMsg')}}</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="footer-box__col">
                                <ul class="list-contacts">
                                    <!-- <li><a class="email" href="mailto:exemple@gmail.com">Email <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="29" height="15" viewBox="0 0 29.1 15.1" style="enable-background:new 0 0 29.1 15.1;" xml:space="preserve"><polygon class="st0" points="21.5,0 20.5,1.1 26.2,6.8 0,6.8 0,8.3 26.2,8.3 20.5,14 21.5,15.1 29.1,7.5 "/></svg></a></li> -->
                                    <!-- <li><a href="https://www.facebook.com" target="_blank">Facebook</a></li>
                                    <li><a href="https://www.instagram.com" target="_blank">Instagram</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";

import jQuery from 'jquery';

import DailyPuzzle from '../components/DailyPuzzle.vue';

import 'slick-carousel'
import '../assets/jquery-sticky-box'
import SmoothScroll from '../assets/smooth-scroll'

// Queries & mutations
import {homePageEventsToDisplay} from "../mytutor-graphql/queries";

// Mixins
import {i18nMixin} from "../mixins/I18nMixin";

export default {
  name: 'Home',
  components: {
    DailyPuzzle
  },
  mixins: [i18nMixin],
  props: {
    hide: Boolean
  },
  data() {
      return {
          showVideo: false,
          events: []
      }
  },
  methods: {
      // ----------------------------------------------------------------------------
      //
      // API calls
      //
      // ----------------------------------------------------------------------------
      fetchEvents: async function() {
          console.debug('Loading events');
          try {
              let variables = {
                  language: this.locale,
                  displayTo: {gt: dayjs().startOf('day').format('YYYY-MM-DD')}
              };
              const eventsRet = await API.graphql({
                  query: homePageEventsToDisplay,
                  variables: variables,
                  authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });
              console.debug(eventsRet);
              if (eventsRet.data.homePageEventsToDisplay !== null) {
                  console.debug("Events found");
                  return eventsRet.data.homePageEventsToDisplay.items;
              } else {
                  console.debug("Error fetching events");
                  this.logError("Fetching events", "No data returned", true);
                  return null;
              }
          } catch (error) {
              this.logAPIError("Fetching events", error, true);
              return null;
          }
      },


      // ----------------------------------------------------------------------------
      //
      // Functionality
      //
      // ----------------------------------------------------------------------------
      loadEvents: async function() {
          const eventsFromServer = await this.fetchEvents();
          this.events.splice(0, this.events.length);
          for (let event of eventsFromServer) {
              this.events.push(event);
          }
      },
      goToLogin: function() {
          console.log("Going to login");
          this.$router.push({ path: '/signin' });
      },
      launchVideo: function() {
          this.showVideo = true;
      },
      closeVideo: function() {
          this.showVideo = false;
      },
      showApp: function() {
        this.$root.$emit('open-app');
      },
  },
  watch: {
      '$i18n.locale': async function() {
          console.log("Local changed");
          await this.loadEvents();
      }
  },
  mounted: async function () {
    await this.loadEvents();

    jQuery(function () {
      console.log("Initilising functions");
      initSlickCarousel();
      initStickyScrollBlock();
      initAnchors();
      console.log("Finished initilising functions");
    });
    
    import('../assets/jquery-in-viewport').then(() => {
      initInViewport();
    });

    (function( w ) {
      var vh = w.innerHeight * 0.01;
    
      refreshVH();
      w.addEventListener('load', refreshVH);
      w.addEventListener('resize', refreshVH);
      w.addEventListener('orientationchange', refreshVH);
    
      function refreshVH() {
        vh = w.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
      }
    }(window));
    
    // initialize fixed blocks on scroll
    function initStickyScrollBlock() {
      var getWindowHeight = function() {
        return window.innerHeight || document.documentElement.clientHeight;
      };
    
      var resizeObserver = new ResizeObserver(function() {
        jQuery(window).trigger('refreshStickyScrollBlock').trigger('refreshAnchor.SmoothScroll');
      });
      resizeObserver.observe(document.body);
    
      jQuery('.step-item').stickyScrollBlock({
        setBoxHeight: true,
        activeClass: 'fixed-item-position',
        container: '#step-section',
        // scrollContainer: jQuery("#app"),
        positionType: 'fixed',
      });
    
      jQuery('#gallery-step-pagination').stickyScrollBlock({
        setBoxHeight: false,
        activeClass: 'fixed-position',
        container: '#step-section',
        // scrollContainer: jQuery("#app"),
        positionType: 'fixed',
      });
    
      jQuery('#heading-section').stickyScrollBlock({
        setBoxHeight: true,
        activeClass: 'fixed-heading-position',
        container: '#step-section',
        // scrollContainer: jQuery("#app"),
        positionType: 'fixed',
        extraTop: 10,
        extraBottom: function() {
          var extraBottom = getWindowHeight() - jQuery('#heading-section').outerHeight() + 10;
    
          return extraBottom;
        }
      });
    }

    // initialize smooth anchor links
    function initAnchors() {
      new SmoothScroll({
        anchorLinks: '.anchor-bullet',
        activeClasses: 'link',
        wheelBehavior: 'none',
        container: jQuery('#home-wrapper'),
        // scrollContainer: jQuery("#app")
      });
    }
    
    // slick init
    function initSlickCarousel() {
    
      window.ResponsiveHelper.addRange({
        '..767': {
          on: function () {
            jQuery('.event-card-gallery').slick({
              slidesToScroll: 1,
              rows: 0,
              arrows: false,
              dots: true,
              dotsClass: 'slick-dots',
              fade: true,
              adaptiveHeight: true,
              pauseOnHover: false
            });
          },
          off: function () {
            jQuery('.event-card-gallery').slick('destroy');
          }
        }
      });
    }
    
    // in view port init
    function initInViewport() {
      jQuery('.viewport-section').itemInViewport({
        once: true,
        visibleMode: 3
      });
    }

    /*
     * Responsive Layout helper
     */
    window.ResponsiveHelper = (function ($) {
      // init variables
      var handlers = [],
        prevWinWidth,
        win = $(window),
        nativeMatchMedia = false;
    
      // detect match media support
      if (window.matchMedia) {
        if (window.Window && window.matchMedia === Window.prototype.matchMedia) {
          nativeMatchMedia = true;
        } else if (window.matchMedia.toString().indexOf('native') > -1) {
          nativeMatchMedia = true;
        }
      }
    
      // prepare resize handler
      function resizeHandler() {
        var winWidth = win.width();
        if (winWidth !== prevWinWidth) {
          prevWinWidth = winWidth;
    
          // loop through range groups
          $.each(handlers, function (index, rangeObject) {
            // disable current active area if needed
            $.each(rangeObject.data, function (property, item) {
              if (item.currentActive && !matchRange(item.range[0], item.range[1])) {
                item.currentActive = false;
                if (typeof item.disableCallback === 'function') {
                  item.disableCallback();
                }
              }
            });
    
            // enable areas that match current width
            $.each(rangeObject.data, function (property, item) {
              if (!item.currentActive && matchRange(item.range[0], item.range[1])) {
                // make callback
                item.currentActive = true;
                if (typeof item.enableCallback === 'function') {
                  item.enableCallback();
                }
              }
            });
          });
        }
      }
      win.bind('load resize orientationchange', resizeHandler);
    
      // test range
      function matchRange(r1, r2) {
        var mediaQueryString = '';
        if (r1 > 0) {
          mediaQueryString += '(min-width: ' + r1 + 'px)';
        }
        if (r2 < Infinity) {
          mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
        }
        return matchQuery(mediaQueryString, r1, r2);
      }
    
      // media query function
      function matchQuery(query, r1, r2) {
        if (window.matchMedia && nativeMatchMedia) {
          return window.matchMedia(query).matches;
        } else if (window.styleMedia) {
          return window.styleMedia.matchMedium(query);
        } else if (window.media) {
          return window.media.matchMedium(query);
        } else {
          return prevWinWidth >= r1 && prevWinWidth <= r2;
        }
      }
    
      // range parser
      function parseRange(rangeStr) {
        var rangeData = rangeStr.split('..');
        var x1 = parseInt(rangeData[0], 10) || -Infinity;
        var x2 = parseInt(rangeData[1], 10) || Infinity;
        return [x1, x2].sort(function (a, b) {
          return a - b;
        });
      }
    
      // export public functions
      return {
        addRange: function (ranges) {
          // parse data and add items to collection
          var result = { data: {} };
          $.each(ranges, function (property, data) {
            result.data[property] = {
              range: parseRange(property),
              enableCallback: data.on,
              disableCallback: data.off
            };
          });
          handlers.push(result);
    
          // call resizeHandler to recalculate all events
          prevWinWidth = null;
          resizeHandler();
        }
      };
    }(jQuery));

  }
}
</script>

<style scoped>
/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.clearfix:after, dl:after, .container:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

.df-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 480px) {
    .df-row.df-rr-ph {
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; } }
  @media (min-width: 480px) {
    .df-row.df-aic-ph {
      -ms-flex-align: center;
          align-items: center; } }
  @media (min-width: 768px) {
    .df-row.df-rr-sm {
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; } }
  @media (min-width: 768px) {
    .df-row.df-aic-sm {
      -ms-flex-align: center;
          align-items: center; } }
  @media (min-width: 992px) {
    .df-row.df-rr-md {
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; } }
  @media (min-width: 992px) {
    .df-row.df-aic-md {
      -ms-flex-align: center;
          align-items: center; } }
  @media (min-width: 480px) {
    .df-row .col-ph-1 {
      -ms-flex: 1 0 calc(100% / 12 * 1);
          flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-ph-2 {
      -ms-flex: 1 0 calc(100% / 12 * 2);
          flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-ph-3 {
      -ms-flex: 1 0 calc(100% / 12 * 3);
          flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-ph-4 {
      -ms-flex: 1 0 calc(100% / 12 * 4);
          flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-ph-5 {
      -ms-flex: 1 0 calc(100% / 12 * 5);
          flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-ph-6 {
      -ms-flex: 1 0 calc(100% / 12 * 6);
          flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-ph-7 {
      -ms-flex: 1 0 calc(100% / 12 * 7);
          flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-ph-8 {
      -ms-flex: 1 0 calc(100% / 12 * 8);
          flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-ph-9 {
      -ms-flex: 1 0 calc(100% / 12 * 9);
          flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-ph-10 {
      -ms-flex: 1 0 calc(100% / 12 * 10);
          flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-ph-11 {
      -ms-flex: 1 0 calc(100% / 12 * 11);
          flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 480px) {
    .df-row .col-ph-12 {
      -ms-flex: 1 0 calc(100% / 12 * 12);
          flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-1 {
      -ms-flex: 1 0 calc(100% / 12 * 1);
          flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-sm-2 {
      -ms-flex: 1 0 calc(100% / 12 * 2);
          flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-sm-3 {
      -ms-flex: 1 0 calc(100% / 12 * 3);
          flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-sm-4 {
      -ms-flex: 1 0 calc(100% / 12 * 4);
          flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-sm-5 {
      -ms-flex: 1 0 calc(100% / 12 * 5);
          flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-sm-6 {
      -ms-flex: 1 0 calc(100% / 12 * 6);
          flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-sm-7 {
      -ms-flex: 1 0 calc(100% / 12 * 7);
          flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-sm-8 {
      -ms-flex: 1 0 calc(100% / 12 * 8);
          flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-sm-9 {
      -ms-flex: 1 0 calc(100% / 12 * 9);
          flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-sm-10 {
      -ms-flex: 1 0 calc(100% / 12 * 10);
          flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-sm-11 {
      -ms-flex: 1 0 calc(100% / 12 * 11);
          flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-12 {
      -ms-flex: 1 0 calc(100% / 12 * 12);
          flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 992px) {
    .df-row .col-md-1 {
      -ms-flex: 1 0 calc(100% / 12 * 1);
          flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 992px) {
    .df-row .col-md-2 {
      -ms-flex: 1 0 calc(100% / 12 * 2);
          flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 992px) {
    .df-row .col-md-3 {
      -ms-flex: 1 0 calc(100% / 12 * 3);
          flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 992px) {
    .df-row .col-md-4 {
      -ms-flex: 1 0 calc(100% / 12 * 4);
          flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 992px) {
    .df-row .col-md-5 {
      -ms-flex: 1 0 calc(100% / 12 * 5);
          flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 992px) {
    .df-row .col-md-6 {
      -ms-flex: 1 0 calc(100% / 12 * 6);
          flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 992px) {
    .df-row .col-md-7 {
      -ms-flex: 1 0 calc(100% / 12 * 7);
          flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 992px) {
    .df-row .col-md-8 {
      -ms-flex: 1 0 calc(100% / 12 * 8);
          flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 992px) {
    .df-row .col-md-9 {
      -ms-flex: 1 0 calc(100% / 12 * 9);
          flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 992px) {
    .df-row .col-md-10 {
      -ms-flex: 1 0 calc(100% / 12 * 10);
          flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 992px) {
    .df-row .col-md-11 {
      -ms-flex: 1 0 calc(100% / 12 * 11);
          flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 992px) {
    .df-row .col-md-12 {
      -ms-flex: 1 0 calc(100% / 12 * 12);
          flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 992px) {
    .df-row .col-offset-md-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row {
      margin: 0 -30px; } }

</style>

<style scoped>
html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    max-height: 1000000px;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul,
ol,
dl,
p,
h1,
h2,
h3,
h4,
h5,
h6,
address,
form,
table,
blockquote,
applet,
embed,
object,
iframe,
frameset {
    margin: 0 0 18px;
}

blockquote {
    border-left: 4px solid #ddd;
    padding-left: 10px;
    margin-left: 0;
}

table {
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 3px;
    text-align: left;
    vertical-align: top;
}

th {
    text-align: center;
    vertical-align: middle;
}

ul,
ol {
    padding-left: 20px;
}

ul ul,
ul ol,
ol ul,
ol ol {
    padding-left: 20px;
    margin: 0;
}

ul {
    list-style-type: disc;
}

dl dt {
    float: left;
    clear: left;
    padding-right: 0.3em;
    font-weight: bold;
}

dl dd {
    overflow: hidden;
}

pre {
    max-width: 100%;
    overflow: auto;
}

#wrapper {
    text-align: left;
    color: #fff !important;
    background: #1c1a4f;
    font: 300 14px/1.4 "Inter", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

@media (min-width: 768px) {
    #wrapper {
        font-size: 16px;
        line-height: 22px;
    }
}

@media (min-width: 992px) {
    #wrapper {
        font-size: 20px;
        line-height: 1.3;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    display: inline-block;
}

.gm-style img {
    max-width: none;
}

@media (max-width: 991px) {
    iframe {
        width: 100%;
    }
}

.resize-active * {
    transition: none !important;
}

form,
fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 7px 10px;
    font-size: 12px;
    line-height: 14px;
    font-family: "Inter", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid #5469f3;
    border-radius: 40px;
    background: transparent;
    width: 150px;
    color: #b8babd;
}

input[type='text']:not(textarea),
input[type='tel']:not(textarea),
input[type='email']:not(textarea),
input[type='search']:not(textarea),
input[type='password']:not(textarea),
input[type='url']:not(textarea),
textarea:not(textarea) {
    height: 30px;
}

input[type='text']:focus,
input[type='tel']:focus,
input[type='email']:focus,
input[type='search']:focus,
input[type='password']:focus,
input[type='url']:focus,
textarea:focus {
    color: inherit;
    outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='search']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='url']::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #ddd;
}

input[type='text']::-moz-placeholder,
input[type='tel']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='search']::-moz-placeholder,
input[type='password']::-moz-placeholder,
input[type='url']::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #ddd;
}

input[type='text']:-moz-placeholder,
input[type='tel']:-moz-placeholder,
input[type='email']:-moz-placeholder,
input[type='search']:-moz-placeholder,
input[type='password']:-moz-placeholder,
input[type='url']:-moz-placeholder,
textarea:-moz-placeholder {
    color: #ddd;
}

input[type='text']:-ms-input-placeholder,
input[type='tel']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder,
input[type='search']:-ms-input-placeholder,
input[type='password']:-ms-input-placeholder,
input[type='url']:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #ddd;
}

input[type='text'].placeholder,
input[type='tel'].placeholder,
input[type='email'].placeholder,
input[type='search'].placeholder,
input[type='password'].placeholder,
input[type='url'].placeholder,
textarea.placeholder {
    color: #ddd;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

textarea {
    resize: vertical;
    vertical-align: top;
    overflow: auto;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
    transition: background 0.5s ease-in-out;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    height: 30px;
    padding: 7px 10px;
    font-size: 12px;
    line-height: 14px;
    color: #5469f3;
    outline: none;
    cursor: pointer;
    border: 0 solid #5469f3;
    border-radius: 40px;
    background: transparent;
}

button:hover,
input[type='button']:hover,
input[type='reset']:hover,
input[type='file']:hover,
input[type='submit']:hover {
    background: rgba(0, 0, 0, 0);
    color: #fff;
}

h1,
.h1 {
    font-size: 34px;
}

h2,
.h2 {
    font-size: 26px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 14px;
}

h5,
.h5 {
    font-size: 14px;
}

h6,
.h6 {
    font-size: 14px;
}

@media (min-width: 768px) {

    h1,
    .h1 {
        font-size: 40px;
    }

    h2,
    .h2 {
        font-size: 30px;
    }

    h3,
    .h3 {
        font-size: 18px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }

    h6,
    .h6 {
        font-size: 16px;
    }
}

@media (min-width: 992px) {

    h1,
    .h1 {
        font-size: 58px;
    }

    h2,
    .h2 {
        font-size: 58px;
    }

    h3,
    .h3 {
        font-size: 18px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }

    h6,
    .h6 {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {

    h1,
    .h1 {
        font-size: 58px;
    }

    h2,
    .h2 {
        font-size: 58px;
    }

    h3,
    .h3 {
        font-size: 18px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }

    h6,
    .h6 {
        font-size: 16px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
    font-family: "Inter", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    color: inherit;
    /* color: #fff !important; */
}

h1,
.h1 {
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 17px;
}

@media (min-width: 768px) {

    h1,
    .h1 {
        line-height: 44px;
        margin-bottom: 33px;
    }
}

@media (min-width: 992px) {

    h1,
    .h1 {
        line-height: 64px;
        margin-bottom: 34px;
    }
}

h2,
.h2 {
    line-height: 1.1;
    font-weight: 500;
    margin: 0 0 10px;
}

@media (min-width: 768px) {

    h2,
    .h2 {
        margin-bottom: 20px;
    }
}

h3,
.h3 {
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
}

@media (min-width: 768px) {

    h3,
    .h3 {
        line-height: 24px;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {

    h3,
    .h3 {
        line-height: 26px;
    }
}

p {
    margin: 0 0 21px;
}

@media (min-width: 992px) {
    p {
        margin: 0 0 26px;
    }
}

a {
    color: #1c1a4f;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #000;
    text-decoration: none;
}

a[href*="mailto:"] {
    word-wrap: break-word;
}

.btn {
    border: 0 solid #5469f3;
    border-radius: 40px;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 15px;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    font-family: "Inter", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    overflow: hidden;
    position: relative;
    color: #5469f3;
    box-shadow: inset 0 0 0 1px #5469f3;
    transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
    .btn {
        padding: 14px 15px;
        font-size: 16px;
        line-height: 18px;
    }
}

@media (min-width: 992px) {
    .btn {
        font-size: 18px;
        line-height: 20px;
        padding: 15px 21px;
    }
}

.btn:after {
    content: attr(data-text);
    position: absolute;
    padding: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #5469f3;
    color: #1c1a4f;
    border-radius: inherit;
    transform: translateX(-100%);
    transition: all 0.5s;
}

.btn:hover {
    color: #5469f3;
}

.btn:hover:after {
    transform: translateX(0);
}

.btn-sm {
    font-size: 12px;
    line-height: 14px;
    padding: 8px 11px;
}

.btn-blue {
    background: #5469f3;
    color: #1c1a4f;
}

.btn-yellow {
    box-shadow: inset 0 0 0 1px #f9ac1b;
    color: #fff;
}

.btn-yellow:after {
    background: #f9ac1b;
    color: #fff;
}

.btn-yellow:hover {
    color: #fff;
}

.btn-yellow-full {
    padding: 7px 10px;
    color: #fff;
    box-shadow: none;
    background: #f9ac1b;
    border: 1px solid #f9ac1b;
}

.btn-yellow-full:after {
    background: #1c1a4f;
    color: #fff;
}

.btn-yellow-full:hover {
    color: #fff;
}

.btn:focus {
    color: currentColor;
}

.btn-group {
    display: -ms-flexbox;
    display: flex;
}

.btn-group>.btn+.btn {
    border-left: 0;
}

.hero-section {
    position: relative;
    padding: 8px 0 129px;
}

@media (min-width: 768px) {
    .hero-section {
        padding: 8px 29px 159px;
    }
}

@media (min-width: 992px) {
    .hero-section {
        padding: 8px 29px 227px;
    }
}

@media (min-width: 768px) {
    .hero-section__columns {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.hero-section h1 {
    position: relative;
    z-index: 5;
}

.hero-section__decor {
    position: absolute;
    right: -370px;
    bottom: 100px;
    z-index: 5;
}

@media (min-width: 768px) {
    .hero-section__decor {
        right: -87px;
        bottom: 64px;
    }
}

@media (min-width: 992px) {
    .hero-section__decor {
        right: -198px;
        bottom: 8px;
    }
}

.hero-section__decor:after {
    position: absolute;
    right: -372px;
    top: -9px;
    content: '';
    width: 1098px;
    height: 694px;
    border-radius: 1000px 0 0 1000px;
    background: #5469f3;
    z-index: -1;
    transform: translate(20%, 20%);
    transition: transform 1.8s;
}

@media (min-width: 768px) {
    .hero-section__decor:after {
        height: 1098px;
        top: -259px;
    }
}

@media (min-width: 992px) {
    .hero-section__decor:after {
        right: 0;
        top: 71px;
        height: 1040px;
    }
}

@media (min-width: 1920px) {
    .hero-section__decor:after {
        width: 52vw;
    }
}

.hero-section__decor img {
    width: 710px;
    opacity: 0;
    transform: translate(40%, 40%);
    transition: opacity 2.3s 0.5s, transform 2.3s 0.5s;
}

@media (min-width: 992px) {
    .hero-section__decor img {
        width: auto;
    }
}

.hero-section__decor.in-viewport:after {
    transform: translate(0, 0);
}

.hero-section__decor.in-viewport img {
    opacity: 1;
    transform: translate(0, 0);
}

.hero-section__content {
    width: 100%;
    padding: 15px 0 0;
}

@media (min-width: 768px) {
    .hero-section__content {
        position: relative;
        z-index: 5;
        width: 70%;
        padding: 23px 0 0;
    }
}

@media (min-width: 992px) {
    .hero-section__content {
        width: 50%;
        padding: 70px 0 0;
    }
}

.hero-section__body {
    max-width: 200px;
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    z-index: 5;
}

@media (min-width: 768px) {
    .hero-section__body {
        max-width: 190px;
        margin: 0 0 44px;
    }
}

@media (min-width: 992px) {
    .hero-section__body {
        max-width: 250px;
        margin: 0 0 47px;
        font-size: 20px;
        line-height: 26px;
    }
}

.hero-section__body p {
    margin-bottom: 10px;
}

.hero-section__visual {
    position: relative;
    z-index: 5;
}

@media (min-width: 768px) {
    .hero-section__visual {
        width: 30%;
    }
}

@media (min-width: 992px) {
    .hero-section__visual {
        width: 50%;
        margin-left: 0;
    }
}

.hero-section__visual img {
    max-width: none;
    width: 100%;
}

.hero-section__visual-holder {
    opacity: 0;
    margin: 0 10px 0 -24px;
    transform: translateY(100px);
    transition: opacity 0.8s, transform 0.8s;
    pointer-events: none;
}

@media (min-width: 768px) {
    .hero-section__visual-holder {
        padding-top: 105px;
        margin: 0 -40px 0 -154%;
    }
}

@media (min-width: 992px) {
    .hero-section__visual-holder {
        padding-top: 0;
        margin: 0 -20px 0 0;
    }
}

.hero-section .btn .text-hover {
    position: absolute;
    padding: inherit;
    top: 0;
    left: 0;
    width: 100.1%;
    height: 100%;
    border-radius: 40px;
    background: #f9ac1b;
    color: #fff;
    transform: translateX(-100%);
    transition: transform 0.5s;
}

.hero-section .btn img {
    margin-right: 6px;
    margin-top: -2px;
    margin-bottom: -2px;
}

@media (min-width: 992px) {
    .hero-section .btn img {
        margin-right: 13px;
    }
}

.hero-section .btn:after {
    display: none;
}

.hero-section .btn:hover {
    color: #fff;
}

.hero-section .btn:hover .text-hover {
    transform: translateX(0);
}

.hero-section .button-wrap {
    position: absolute;
    bottom: 68px;
    left: 30px;
    right: 30px;
    text-align: center;
    z-index: 5;
}

@media (min-width: 768px) {
    .hero-section .button-wrap {
        text-align: left;
        position: static;
    }
}

.in-viewport .hero-section__visual-holder {
    opacity: 1;
    transform: translateY(0);
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 200;
  overflow: scroll;
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  position: relative;
  width: 100%;
  max-width: 910px;
  margin: auto;
  box-shadow: 0 0 0 100px rgb(0 0 0 / 70%);
  box-shadow: 0 0 0 100vmax rgb(0 0 0 / 70%);
  background-color: transparent;
  border-radius: 0px;
  color: white;
  padding: 0;
  z-index: 300;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.modal-content div {
  display: inline-block;
  vertical-align: middle;
}

.modal-content ul {
    list-style: none;
}

.modal-content__cta {
    margin-top: 20px;
    width: 100%;
}

.modal-content__video {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
}

.modal-content iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-content__close {
  position: absolute;
  top: -35px;
  right: 10px;
  color: #1C1A4F;
}

.modal-content__close img {
  width: 23px;
  height: 23px;
}

@media screen and (max-width:414px) {
    .modal-content {
        padding: 60px 30px;
    }
}

.step-section {
    position: relative;
    z-index: 10;
}

@media (min-width: 768px) {
    .step-section {
        padding: 0 30px 50vh;
    }
}

@media (min-width: 992px) {
    .step-section {
        padding: 0 0 50vh;
    }
}

.step-section .gallery-step-pagination {
    position: absolute;
    right: 15px;
    top: 0;
}

@media (min-width: 768px) {
    .step-section .gallery-step-pagination {
        right: -5px;
    }
}

@media (min-width: 992px) {
    .step-section .gallery-step-pagination {
        right: 33px;
    }
}

.step-section .container {
    position: relative;
}

.gallery-step-slide {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
}

.heading-section {
    position: relative;
    z-index: 5;
    padding: 15px 5% 15px 0;
    min-height: 86px;
    border-radius: 0 200px 200px 0;
    background: #f7f0ea;
    color: #1c1a4f !important;
    width: 68%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

@media (min-width: 768px) {
    .heading-section {
        width: 40.2%;
    }
}

@media (min-width: 992px) {
    .heading-section {
        padding: 15px 18% 15px 0;
        width: 53%;
    }
}

.heading-section:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -9999px;
    right: 100%;
    background: #f7f0ea;
}

@media (min-width: 768px) {
    .heading-section {
        min-height: 105px;
    }
}

@media (min-width: 992px) {
    .heading-section {
        min-height: 220px;
    }
}

.heading-section h2 {
    margin: 0;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s, transform 0.8s;
}

.gallery-step {
    padding: 0 0 88px;
    z-index: 5;
}

@media (min-width: 768px) {
    .gallery-step {
        margin: -197px 0 0;
        padding: 0 0 43px;
    }
}

@media (min-width: 992px) {
    .gallery-step {
        margin: -205px 0 0;
    }
}

.step-item__columns {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.step-item__col {
    width: 100%;
}

.step-item__col:first-child {
    width: 45%;
    padding: 182px 10px 0 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}

@media (min-width: 768px) {
    .step-item__col:first-child {
        -ms-flex-pack: center;
        justify-content: center;
        width: 50%;
        padding: 270px 0;
    }
}

@media (max-height: 750px) {
    .step-item__col:first-child {
        padding-bottom: 50px;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.step-item__col:last-child {
    width: 55%;
    padding: 54px 13px 0 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

@media (min-width: 768px) {
    .step-item__col:last-child {
        width: 50%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 65px 0 0;
    }
}

@media (min-width: 992px) {
    .step-item__col:last-child {
        padding: 0 91px 0 0;
    }
}

.step-item__content {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .step-item__content {
        padding: 0 14% 0 0;
    }
}

.step-item__title {
    margin-right: -100%;
    margin-bottom: 21px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .step-item__title {
        margin-right: 0;
        margin-bottom: 0;
    }
}

.step-item__visual {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 180px;
}

@media (min-width: 768px) {
    .step-item__visual {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .step-item__visual {
        padding-top: 170px;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

.step-item__visual img {
    display: block;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (max-width: 991px) and (orientation: landscape) {
    .step-item__visual img {
        max-height: 90vh;
        width: auto;
    }
}

.gallery-step-pagination {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    line-height: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (max-width: 767px) {
    .gallery-step-pagination {
        left: auto !important;
    }
}

.gallery-step-pagination>li {
    padding: 5px;
}

.gallery-step-pagination .anchor-bullet {
    display: block;
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: transparent;
    font-size: 0;
    line-height: 0;
    opacity: 1;
}

.gallery-step-pagination .anchor-bullet:hover {
    border-color: #fff;
    background: #fff;
}

.gallery-step-pagination .anchor-active {
    border-color: #fff;
    background: #fff;
}

#heading-section {
    position: relative;
    z-index: 2;
}

.in-viewport.heading-section h2 {
    opacity: 1;
    transform: translateY(0);
}

.section-active .step-item__visual img,
.section-active .step-item__content,
.section-active .step-item__title {
    opacity: 1;
    transform: translateY(0);
}

.text-and-image {
    padding: 55px 0 87px;
    position: relative;
    z-index: 10;
}

@media (min-width: 768px) {
    .text-and-image {
        padding: 109px 30px 84px;
    }
}

@media (min-width: 992px) {
    .text-and-image {
        padding: 109px 30px 339px;
    }
}

.text-and-image h2 {
    margin-bottom: 41px;
}

@media (min-width: 768px) {
    .text-and-image h2 {
        margin-bottom: 78px;
    }
}

@media (min-width: 992px) {
    .text-and-image h2 {
        margin-bottom: 38px;
    }
}

.text-and-image .heading-section {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .text-and-image .heading-section {
        margin-bottom: 0;
        background: none;
        color: #fff;
        padding: 0;
        width: 100%;
        border-radius: 0;
        min-height: 0;
    }
}

@media (min-width: 768px) {
    .text-and-image .heading-section:after {
        display: none;
    }
}

@media (max-width: 767px) {
    .text-and-image .heading-section h2 {
        margin: 0;
    }
}

.text-and-image__holder {
    position: relative;
}

@media (min-width: 992px) {
    .text-and-image__holder {
        display: -ms-flexbox;
        display: flex;
        padding-left: 668px;
        min-height: 500px;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.text-and-image__visual {
    margin: 0 0 22px;
    position: relative;
}

@media (min-width: 768px) {
    .text-and-image__visual {
        margin: 0 0 50px;
    }
}

@media (min-width: 992px) {
    .text-and-image__visual {
        margin: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.text-and-image__visual > div {
    position: relative;
    z-index: 1;
    max-width: none;
    width: 100%;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s, transform 0.8s;
    border-radius: 10px;
}

.section-puzzle__puzzle-wrapper {
    width: 100%;
}

@media (min-width: 992px) {
    .section-puzzle__puzzle-wrapper {
        width: 600px;
    }
}

.text-and-image__visual:after {
    position: absolute;
    content: '';
    top: -104px;
    right: -238px;
    width: 428px;
    height: 428px;
    border-radius: 50%;
    background: #5469f3;
}

@media (min-width: 768px) {
    .text-and-image__visual:after {
        right: auto;
        top: -218px;
        left: -193px;
        width: 593px;
        height: 593px;
    }
}

@media (min-width: 992px) {
    .text-and-image__visual:after {
        top: auto;
        bottom: -138px;
        left: -140px;
        width: 461px;
        height: 461px;
    }
}

.text-and-image__content {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .text-and-image__content {
        padding: 0 22% 0 0;
    }
}

@media (min-width: 992px) {
    .text-and-image__content {
        padding: 0 5% 0 0;
    }
}

.text-and-image__footer {
    font-size: 12px;
    color: #b8babd;
    padding: 2px 0 0;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .text-and-image__footer {
        padding: 25px 0 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
    }
}

.text-and-image__footer-text {
    display: block;
    margin-bottom: 13px;
    font-weight: 400;
}

@media (min-width: 768px) {
    .text-and-image__footer-text {
        margin-right: 13px;
        margin-bottom: 0;
        display: inline;
    }
}

@media (max-width: 767px) {
    .text-and-image .btn {
        font-size: 16px;
        line-height: 18px;
        padding: 10px 29px;
    }
}

.text-and-image .btn-yellow {
    color: #f9ac1b;
}

.text-and-image .btn-yellow:after {
    color: #1c1a4f;
}

.text-and-image .in-viewport {
    opacity: 1;
    transform: translateY(0);
}

.tutor-chat-section {
    padding: 70px 0 10px;
    background: #f7f0ea;
    color: #1c1a4f;
    text-align: center;
}

@media (min-width: 768px) {
    .tutor-chat-section {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .tutor-chat-section {
        padding-bottom: 33px;
    }
}

.tutor-chat-section h2 {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 35px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .tutor-chat-section h2 {
        font-size: 30px;
        margin-bottom: 68px;
    }
}

@media (min-width: 992px) {
    .tutor-chat-section h2 {
        font-size: 34px;
        margin-bottom: 62px;
    }
}

.tutor-chat-section h2.in-viewport {
    opacity: 1;
    transform: translateY(0);
}

.tutor-chat-section .in-viewport .item__icon,
.tutor-chat-section .in-viewport .item__body,
.tutor-chat-section .in-viewport .item__text {
    opacity: 1;
    transform: translateY(0);
}

.tutor-chat-section .in-viewport .item__text {
    opacity: 0.7;
}

.items-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.items-list>li {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 24px;
}

@media (min-width: 768px) {
    .items-list>li {
        width: 50%;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .items-list>li {
        width: calc(100% / 3);
        margin-bottom: 31px;
    }
}

@media (min-width: 992px) {
    .item {
        font-size: 18px;
        line-height: 24px;
    }
}

.item__icon {
    height: 119px;
    text-align: center;
    margin-bottom: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .item__icon {
        margin-bottom: 7px;
        height: 140px;
    }
}

.item__icon img {
    max-height: 100%;
}

.item__body {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s 0.3s, transform 0.8s 0.3s;
}

.item h3 {
    text-transform: uppercase;
    font-size: 16px;
}

@media (min-width: 768px) {
    .item h3 {
        margin-bottom: 4px;
    }
}

@media (min-width: 992px) {
    .item h3 {
        margin-bottom: 10px;
        font-size: 18px;
    }
}

.item__text {
    max-width: 180px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s 0.6s, transform 0.8s 0.6s;
}

.events-section {
    padding: 63px 0 76px;
}

@media (min-width: 768px) {
    .events-section {
        padding: 70px 30px 60px;
    }
}

@media (min-width: 992px) {
    .events-section {
        padding-bottom: 50px;
    }
}

.events-section h2 {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 66px;
    text-align: center;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s, transform 0.8s;
}

@media (min-width: 768px) {
    .events-section h2 {
        font-size: 30px;
        margin-bottom: 100px;
    }
}

@media (min-width: 992px) {
    .events-section h2 {
        font-size: 34px;
        margin-bottom: 92px;
    }
}

.events-section h2.in-viewport {
    opacity: 1;
    transform: translateY(0);
}

[id="header"] {
    position: relative;
    padding: 23px 0;
    z-index: 99;
}

@media (min-width: 768px) {
    [id="header"] {
        padding: 38px 30px;
    }
}

@media (min-width: 992px) {
    [id="header"] {
        padding: 38px 0;
    }
}

[id="header"] .container {
    max-width: 1200px;
}

[id="header"] .box {
    margin-bottom: 11px;
    font-size: 12px;
}

@media (min-width: 768px) {
    [id="header"] .box {
        margin-bottom: 13px;
        font-size: 14px;
    }
}

.header-holder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header-upgrade-in-progress {
    padding: 30px;
    margin: 20px 50px;
    background-color: #fff;
    color: #1c1a4f;
    text-align: center;
    border: 3px solid #f9ac1b;
}

.logo {
    width: 171px;
    min-width: 171px;
    margin-right: 20px;
}

@media (min-width: 768px) {
    .logo {
        width: 217px;
        min-width: 217px;
    }
}

@media (max-width: 350px) {
    .logo {
        margin-right: 12px;
    }
}

.login-form {
    display: none;
}

@media (min-width: 992px) {
    .login-form {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.login-form .btn {
    min-width: 70px;
}

.login-form>* {
    margin-left: 10px;
}

.login-link {
    color: #b8babd;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
}

.login-link:hover {
    color: #fff;
}

@media (min-width: 768px) {
    .login-link {
        font-size: 14px;
    }
}

.login-link:hover {
    text-decoration: none;
}

[id="wrapper"] {
    width: 100%;
    overflow: hidden;
    background: #1c1a4f;
}

.container {
    max-width: 1080px;
    padding: 0 30px;
    margin: 0 auto;
}

@media (max-width: 414px) {
    .container {
        padding: 0 20px;
    }
}

@media (max-width: 350px) {
    .container {
        padding: 0 15px;
    }
}

[id="footer"] {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    [id="footer"] {
        font-size: 18px;
        line-height: 26px;
    }
}

@media (min-width: 992px) {
    [id="footer"] {
        font-size: 22px;
        line-height: 32px;
    }
}

[id="footer"] .container {
    max-width: 1200px;
}

[id="footer"] a {
    color: inherit;
    transition: all 0.5s;
}

[id="footer"] a:hover {
    color: #f9ac1b;
}

[id="footer"] .email {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

[id="footer"] .email svg {
    margin-left: 10px;
    margin-top: 4px;
}

@media (max-width: 767px) {
    [id="footer"] .email svg {
        margin-top: 2px;
        width: 23px;
    }
}

[id="footer"] .email svg polygon {
    fill: currentColor;
}

[id="footer"] h4 {
    margin: 0 0 7px;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    font-weight: 600;
    text-transform: uppercase;
    color: #454283;
}

@media (min-width: 992px) {
    [id="footer"] h4 {
        margin: 0 0 14px;
    }
}

.footer-logo {
    width: 170px;
    min-width: 170px;
    -ms-flex-order: 1;
    order: 1;
}

@media (min-width: 768px) {
    .footer-logo {
        -ms-flex-order: 0;
        order: 0;
        width: 216px;
        min-width: 216px;
    }
}

.footer-holder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px 0;
    border-top: 1px solid #363374;
}

@media (min-width: 768px) {
    .footer-holder {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 38px 30px;
    }
}

@media (min-width: 992px) {
    .footer-holder {
        padding: 38px 0;
    }
}

.footer-box {
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 767px) {
    .footer-box {
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 46px;
    }
}

.footer-box__col {
    width: 39%;
}

@media (min-width: 768px) {
    .footer-box__col {
        width: 125px;
    }
}

@media (min-width: 992px) {
    .footer-box__col {
        width: 292px;
    }
}

@media (max-width: 991px) {
    .footer-box__col:first-child {
        width: auto;
        padding-right: 54px;
    }
}

@media (max-width: 767px) {
    .footer-box__col:first-child {
        padding: 0;
    }
}

.footer-box .menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-box .list-contacts {
    margin: 0;
    padding: 11px 0 0;
    list-style: none;
}

@media (min-width: 992px) {
    .footer-box .list-contacts {
        padding: 9px 0 0;
    }
}

.footer-menu {
    padding: 13px 0 0;
}

@media (min-width: 768px) {
    .footer-menu {
        padding: 13px 0 0 25px;
    }
}
</style>

// this is not scoped, as Slick slider changes the DOM dynamically and thus if it is scoped it is not applied
<style>
@media (min-width: 768px) {
    .event-cards-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -12px;
    }
}

@media (min-width: 992px) {
    .event-cards-list {
        margin: 0 -45px;
    }
}

@media (min-width: 768px) {
    .event-cards-list>.slide {
        display: -ms-flexbox;
        display: flex;
        width: calc(100% / 3);
        padding: 0 12px;
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .event-cards-list>.slide {
        padding: 0 45px;
        margin-bottom: 88px;
    }
}

.event-cards-list .slick-dots>li {
    padding: 5px 10px;
}

.event-card-gallery {
    position: relative;
    padding-bottom: 64px;
}

@media (min-width: 768px) {
    .event-card-gallery {
        padding-bottom: 0;
    }
}

.event-card-gallery .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
}

.event-card-gallery .slick-dots button {
    display: block;
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: transparent;
    font-size: 0;
    line-height: 0;
    opacity: 1;
}

.event-card-gallery .slick-dots button:hover {
    border-color: #fff;
    background: #fff;
}

.event-card-gallery .slick-dots .slick-active button {
    border-color: #fff;
    background: #fff;
}

.event-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #f7f0ea;
    overflow: hidden;
    min-height: 412px;
    max-width: 260px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .event-card {
        max-width: none;
        min-height: 392px;
    }
}

@media (min-width: 992px) {
    .event-card {
        min-height: 442px;
    }
}

.event-card.viewport-section {
    position: relative;
    opacity: 0;
    transform: perspective(2500px) rotateX(90deg);
    transform-origin: 50% 100px;
    transition: opacity 0.8s, transform 0.8s;
}

.event-card.in-viewport {
    opacity: 1;
    transform: perspective(2500px) rotateX(0);
}

.event-card__visual {
    display: block;
    position: relative;
    padding-top: 88%;
    /* background: #b8babd; */
    background: #fff;
}

@media (min-width: 768px) {
    .event-card__visual {
        padding-top: 96%;
    }
}

@media (min-width: 992px) {
    .event-card__visual {
        padding-top: 90%;
    }
}

.event-card__visual > div {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    padding: 40px 20px 20px 20px;
}

@media (min-width: 768px) {
    .event-card__logo-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.event-card__visual img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    display: inline-block;
    vertical-align: middle;
}

.event-card__visual img[src*="placeholder"]+.event-card__placeholder-text {
    display: block;
}

.event-card__label {
    padding: 5px 7px;
    position: absolute;
    top: 0;
    left: 20px;
    background: #f7f0ea;
    color: #1c1a4f;
    text-transform: uppercase;
    z-index: 2;
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
    max-width: 77px;
}

.event-card__placeholder-text {
    display: none;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.event-card h3 {
    margin: 0;
    color: #fff;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

@media (min-width: 768px) {
    .event-card h3 {
        margin: 0 0 -2px;
    }
}

@media (min-width: 992px) {
    .event-card h3 {
        font-size: 18px;
        line-height: 24px;
    }
}

.event-card h3 a {
    color: inherit;
    transition: all 0.5s;
}

.event-card h3 a:hover {
    color: #f9ac1b;
}

.event-card__body {
    width: 100%;
    padding: 19px 20px;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

@media (min-width: 768px) {
    .event-card__body {
        padding: 10px 20px;
    }
}

@media (min-width: 992px) {
    .event-card__body {
        padding: 20px;
    }
}

.event-card time {
    font-size: 16px;
}

@media (min-width: 992px) {
    .event-card time {
        font-size: 18px;
    }
}

.event-card__button-wrap {
    width: 100%;
    padding: 4px 20px 20px;
}

.event-card__button-wrap .btn {
    min-width: 70px;
}

@media screen and (max-width:414px) {
    .hidden-mobile {
        display: none !important;
    }
}

@media screen and (min-width:415px) {
    .hidden-desktop {
        display: none !important;
    }
}

</style>