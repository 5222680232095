import { API, graphqlOperation } from "@aws-amplify/api";

// Our custom queries & mutations
import {
    getTestsHosting,
    getTestHost,
    getTestsEntriesForTeacher,
    getTestsEntries,
    questionSetsByRegistrationEnd,
    questionSetsByRating,
    questionSetsPublic,
    questionSetsUserCanScheduleByID,
    schedulersAuthorisedByUser,
    listWorksheets,
    worksheetsByPrefix,
    allWorksheetEntriesForUser
} from "../mytutor-graphql/queries";
import {updateTestDate, createTestAndHostFromQuestionSet, approveTestScheduler} from "../mytutor-graphql/mutations";

import {convertAWSTimeToMillis, convertMillisToDuration} from "../services/utils";

import worksheet from './worksheet';

let user = function(data) {
    let that = {};

    /*
     * Basic properties. These are left exposed so that Vue reactivity works. 
     * 
     * NB: All Getters and Setters must use these values for reactivity to work
     * 
     */
    that.email = data.email;
    that.receiveMarketingEmails = data.receiveMarketingEmails;
    that.nickname = data.nickname;
    that.firstName = data.firstName;
    that.surname = data.surname;
    that.mobile = data.mobile;
    that.language = data.language;
    that.grade = data.grade;
    that.gender = data.gender;
    that.race = data.race;
    that.school = data.school;
    that.country = data.country;
    that.log = data.log;

    /*
     * Getters and Setters with behaviour, or properties we want to have encapsulation on. If the method isn't using 
     * a basic property above, the property value will be on the data object passed into the constructor.
     */

    let getID = function() {
        return data.id;
    };

    let getState = function() {
        return data.state;
    };

    let isRegistering = function() {
        return data.state === 'registering' || data.state === "answer-and-continue-extended-registration";
    };

    let setState = function(state) {
        data.state = state;
    };

    let doesUserHaveAccount = function() {
        return data.accountType !== 'NO_ACCOUNT_USER';
    };

    let getEmail = function() {
        return that.email;
    };

    let setEmail = function(email) {
        that.email = email;
    };

    let getReceiveMarketingEmails = function() {
        return that.receiveMarketingEmails;
    };

    let setReceiveMarketingEmails = function(receiveMarketingEmails) {
        that.receiveMarketingEmails = receiveMarketingEmails;
    };

    let getFirstName = function() {
        return that.firstName;
    };

    let setFirstName = function(firstName) {
        that.firstName = firstName;
    };

    let hasFirstName = function() {
        return (typeof that.firstName !== 'undefined' && that.firstName !== null);
    };

    let getSurname = function() {
        return that.surname;
    };

    let setSurname = function(surname) {
        that.surname = surname;
    };

    let getNickname = function() {
        return that.nickname;
    };

    let hasNickname = function() {
        return (typeof that.nickname !== 'undefined' && that.nickname !== null);
    };

    let setNickname = function(nickname) {
        that.nickname = nickname;
    };

    let getNameForChat = function() {
        if (hasNickname()) {
            return getNickname();
          }
        if (hasFirstName()) {
            return getFirstName();
        }
        return "";    
    };

    let getMobile = function() {
        return that.mobile;
    };

    let getGrade = function() {
        return that.grade;
    };

    let hasGrade = function() {
        return (typeof that.grade !== 'undefined' && that.grade !== null);
    };

    let setGrade = function(grade) {
        that.grade = grade;
    };

    let getType = function() {
        return data.type;
    };

    let isTeacher = function() {
        return data.type === "TEACHER";
    };

    let isStudent = function() {
        return data.type === "STUDENT";
    };

    let isParent = function() {
        return data.type === "PARENT";
    };

    let isObserver = function() {
        return data.type === "OBSERVER";
    };

    let setType = function(type) {
        data.type = type;
    };

    let getLanguage = function() {
        return that.language;
    };

    let setLanguage = function(language) {
        that.language = language;
    };

    let getGender = function() {
        return that.gender;
    };

    let setGender = function(gender) {
        that.gender = gender;
    };

    let getRace = function() {
        return that.race;
    };
    
    let setRace = function(race) {
        that.race = race;
    };

    let getSchool = function() {
        return that.school;
    };

    let setSchool = function(school) {
        that.school = school;
    };

    let getCountry = function() {
        return that.country;
    };

    let isSouthAfrican = function() {
        return that.country === 'South Africa';
    };

    let isBhutanian = function() {
        return that.country === 'Bhutan';
    };

    let isBurundian = function() {
        return that.country === 'Burundi';
    };

    let isEthiopian = function() {
        return that.country === 'Ethiopia';
    };

    let isGhanaian = function() {
        return that.country === 'Ghana';
    };

    let isRwandan = function() {
        return that.country === 'Rwanda';
    };

    let isKenyan = function() {
        return that.country === 'Kenya';
    };

    let isIrish = function() {
        return that.country === 'Ireland';
    };

    let isBotswanan = function() {
        return that.country === 'Botswana';
    };

    let isNigerian = function() {
        return that.country === 'Nigeria';
    };

    let isNamibian = function() {
        return that.country === 'Namibia';
    };

    let isMauritian = function() {
        return that.country === 'Mauritius';
    };

    let isMalawian = function() {
        return that.country === 'Malawi';
    };

    let isSouthSudanian = function() {
        return that.country === 'South Sudan';
    };

    let isSudanian = function() {
        return that.country === 'Sudan';
    };

    let isSierraLeonian = function() {
        return that.country === 'Sierra Leone';
    };

    let isTanzanian = function() {
        return that.country === 'Tanzania';
    };

    let isUgandan = function() {
        return that.country === 'Uganda';
    };

    let isZimbabwian = function() {
        return that.country === 'Zimbabwe';
    };

    let isZambian = function() {
        return that.country === 'Zambia';
    };

    let isEswatinian = function() {
        return that.country === 'Eswatini';
    };

    let isLesothoan = function() {
        return that.country === 'Lesotho';
    };

    let setCountry = function(country) {
        that.country = country;
    };

    let getOwner = function() {
        return data.owner;
    };

    let getMetricsID = function() {
        if (data.metrics && data.metrics.items.length > 0) {
            return data.metrics.items[0].id;
        }

        return undefined;
    };

    let getCognitoIdentityID = function() {
        if (typeof data.cognitoIdentityID !== 'undefined' && data.cognitoIdentityID !== null) {
            return data.cognitoIdentityID;
        }

        return undefined;
    };

    let hasCognitoIdentityID = function() {
        if (typeof getCognitoIdentityID() === 'undefined') {
            return false;
        }

        return true;
    };

    let setCognitoIdentityID = function(cognitoIdentityID) {
        data.cognitoIdentityID = cognitoIdentityID;
    };

    let getLog = function() {
        return that.log;
    };

    let hasLog = function() {
        return (typeof that.log !== 'undefined') && (that.log.length > 0);
    };

    let getLastLogEntry = function() {
        if (hasLog()) {
            return that.log[that.log.length-1];
        }
    };

    let getELOScore = function() {
        return data.metrics.items[0].eloScore;
    };

    let setELOScore = function(eloScore) {
        data.metrics.items[0].eloScore = eloScore;
    };

    let getLinkCode = function() {
        return data.linkCode;
    };

    /* 
     * Ampilify API calls
     */
    let fetchTestsHosting = async function(prefixes) {
        if (isTeacher()) {
            console.log('fetching tests for teacher: ' + getEmail());
            try {
                const testsRet = await API.graphql(graphqlOperation(getTestsHosting, { userID: getID() }));
                console.log(testsRet);
                if (testsRet.data.testHostsByHostAndDate !== null  && testsRet.data.testHostsByHostAndDate.items.length >= 0) {
                    console.log("Teacher tests found");
                    let testsHosting = testsRet.data.testHostsByHostAndDate.items;
                    testsHosting.forEach(testHosting => {
                        testHosting.testHostID = testHosting.id;
                    });
                    // if there is a prefix, filter out those with names that don't start with that prefix
                    console.log("Before: " + testsHosting.length);
                    if (prefixes) {
                        let filteredTests = [];
                        for (let prefix of prefixes) {
                            filteredTests.push(...testsHosting.filter((testHosting) => { return testHosting.test.questionSet.name.startsWith(prefix); }));
                        }
                        testsHosting = filteredTests;
                    }
                    console.log("After: " + testsHosting.length);
                    console.log(testsHosting);
                    return testsHosting;
                } else {
                    console.log("Error fetching teacher tests");
                    logError("Fetching teacher tests", "No data returned", false);       
                    return null;
                }
            } catch (error) {
                logAPIError("Fetching teacher tests", error, false);
                return null;
            }
        }
        return [];
    };

    let fetchTestEntries = async function() {
        console.log('fetching test entries for user: ' + getID());
        try {
            const testsRet = await API.graphql(graphqlOperation(getTestsEntries, { userID: {eq: getID()} }));
            console.log(testsRet);
            if (testsRet.data.testEntriesByUser !== null  && testsRet.data.testEntriesByUser.items.length >= 0) {
                console.log("Entrant tests found");
                let testEntries = testsRet.data.testEntriesByUser.items;
                testEntries.forEach(testEntry => {
                    testEntry.testDate = testEntry.testHost.testDate;
                    testEntry.testHostID = testEntry.testHost.id;
                });
                return testEntries;
            } else {
                console.log("Error fetching entrants tests");
                logError("Fetching entrants tests", "No data returned", false);       
                return null;
            }
        } catch (error) {
            logAPIError("Fetching entrants tests", error, false);
            return null;
        }
    };

    let fetchInvigilatedTestEntries = async function() {
        console.log('fetching invigilated test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => { 
            return ((testEntry.test.questionSet.invigilation !== "NO_INVIGILATOR") || 
                    (!testEntry.test.questionSet.name.startsWith("Preparation Test") && !testEntry.test.questionSet.name.startsWith("Preparation Test")))}
        );
        return testEntries;
    };

    let fetchCompetitionPracticeTestEntries = async function() {
        console.log('fetching competition test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => { 
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("Preparation Test"))
        });
        return testEntries;
    };

    let fetchPastPaperTestEntries = async function() {
        console.log('fetching past paper test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => { 
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("Past Paper"))
        });
        return testEntries;
    };

    let fetchSharksQuizTestEntries = async function() {
        console.log('fetching Sharks Quiz test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("2023 Sharks Quiz"))
        });
        return testEntries;
    };

    let fetchMathsForEveryoneTestEntries = async function() {
        console.log('fetching Maths for Everyone test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("Maths for Everyone"))
        });
        return testEntries;
    };

    let fetchSchoolsQuizTestEntries = async function() {
        console.log('fetching Schools Quiz test entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("PnP School Club"))
        });
        return testEntries;
    };

    let fetchTalentSearchTestEntries = async function() {
        console.log('fetching talent search entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => { 
            return (testEntry.test.questionSet.invigilation === "NO_INVIGILATOR") && (testEntry.test.questionSet.name.startsWith("SAMF Talent Search"))
        });
        return testEntries;
    };

    let fetchKangarooTestEntries = async function() {
        console.log('fetching Kangaroo entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Kangaroo"));
        });
        return testEntries;
    };

    let fetchKangarooAfricaTestEntries = async function() {
        console.log('fetching Kangaroo Africa entries for user: ' + getID() + ' and country: ' + getCountry());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Kangaroo " + getCountry()));
        });
        return testEntries;
    };

    let fetchSAMOTestEntries = async function() {
        console.log('fetching SAMO entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("2023 Old Mutual SA Maths Olympiad") ||
                testEntry.test.questionSet.name.startsWith("2023 Old Mutual SA Wiskunde-Olimpiade"));
        });
        return testEntries;
    };

    let fetchSAMCTestEntries = async function() {
        console.log('fetching SAMC entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("2023 South African Mathematics Challenge") ||
                testEntry.test.questionSet.name.startsWith("2023 Suid-Afrikaanse Wiskunde-uitdaging"));
        });
        return testEntries;
    };

    let fetchWorldCupTestEntries = async function() {
        console.log('fetching world cup entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("World Cup"));
        });
        return testEntries;
    };

    let fetchYear2023TestEntries = async function() {
        console.log('fetching 2023 entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("2 0 2 3"));
        });
        return testEntries;
    };

    let fetchYear2024TestEntries = async function() {
        console.log('fetching 2024 entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("2 0 2 4"));
        });
        return testEntries;
    };

    let fetchWomensT20TestEntries = async function() {
        console.log('fetching world cup entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Women's T20"));
        });
        return testEntries;
    };

    let fetchNetballWorlcCupTestEntries = async function() {
        console.log('fetching Netball world cup entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Netball"));
        });
        return testEntries;
    };

    let fetchRugbyWorldCupTestEntries = async function() {
        console.log('fetching Rugby world cup entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Rugby"));
        });
        return testEntries;
    };

    let fetchLivingMathsSouthAfricaTestEntries = async function() {
        console.log('fetching Living Maths South Africa entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Living Maths 2023"));
        });
        return testEntries;
    };

    let fetchLivingMathsTestEntries = async function() {
        console.log('fetching Living Maths entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("Living Maths - 2023"));
        });
        return testEntries;
    };

    let fetchUCTTestEntries = async function() {
        console.log('fetching UCT entries for user: ' + getID());
        let testEntries = await fetchTestEntries();
        testEntries = testEntries.filter((testEntry) => {
            return (testEntry.test.questionSet.name.startsWith("UCT") || testEntry.test.questionSet.name.startsWith("UK"));
        });
        return testEntries;
    };

    let fetchNonInvigilatedTests = async function() {
        console.log('fetching non-invigilated tests for user: ' + getID());
        try {
            const privateQuestionSets = await API.graphql(graphqlOperation(questionSetsByRegistrationEnd, { registrationEnd: {gt: new Date().toISOString()}, limit: 200 }));
            console.log(privateQuestionSets);
            if (privateQuestionSets.data.questionSetsByRegistrationEnd !== null && privateQuestionSets.data.questionSetsByRegistrationEnd.items) {
                console.log("Question sets found");
                let questionSets = [];
                questionSets.push(...privateQuestionSets.data.questionSetsByRegistrationEnd.items);
                const now = new Date();
                // filter those where the registration start hasn't passed
                questionSets = questionSets.filter((questionSet) => { return questionSet.registrationStart === null || new Date(questionSet.registrationStart) < now});
                // filter those that require invigilation
                questionSets = questionSets.filter((questionSet) => { return questionSet.invigilation === "NO_INVIGILATOR"});
                // filter those that require prior test
                questionSets = questionSets.filter((questionSet) => { return !questionSet.registrationRequiresPriorTest || questionSet.registrationRequiresPriorTest === false});
                // filter those that are for the wrong grade 
                questionSets = questionSets.filter((questionSet) => { return (!isStudent() || (that.grade > 0 && questionSet.minGrade <= that.grade && questionSet.maxGrade >= that.grade))});
                // filter out the talent search for non South Africans
                questionSets = questionSets.filter((questionSet) => { return !questionSet.name.startsWith("SAMF") || isSouthAfrican()});
                console.log(questionSets);
                return questionSets;
            } else {
                console.log("Error fetching non-invigilated tests for user");
                logError("Fetching non-invigilated tests for user", "No data returned", false);
                return null;
            }
        } catch (error) {
            logAPIError("Fetching non-invigilated tests for user", error, false);
            return null;
        }
    };
    
    let fetchCompetitionPracticeTests = async function() {
        console.log('fetching competition practice tests for user: ' + getID());
        try {
            const questionSetsOrderedByRating = await API.graphql(graphqlOperation(questionSetsByRating));
            console.log(questionSetsOrderedByRating);
            console.log("Question sets found by rating");
                
            let allQuestionSets = [];

            // sort practice papers by rating
            allQuestionSets.push(...questionSetsOrderedByRating.data.questionSetsByRating.items);
            for (let questionSet of allQuestionSets) {
                questionSet.ratingDifference = Math.abs(questionSet.rating - (getELOScore()*100));
            }
            allQuestionSets.sort((a, b) => {
                return (a.ratingDifference < b.ratingDifference) ? -1 : 1;
            });

            return allQuestionSets;
        } catch (error) {
            logAPIError("Fetching competition practice tests tests for user", error, false);
            return null;
        }
    }

    let fetchPastPaperTests = async function() {
        console.log('fetching past paper tests for user: ' + getID());
        try {
            // add past papers
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Past Paper"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated past papers found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching past paper tests tests for user", error, false);
            return null;
        }
    }

    let fetchTalentSearch = async function() {
        let questionSets = await fetchNonInvigilatedTests();
        questionSets = questionSets.filter((questionSet) => { return questionSet.name.startsWith("SAMF Talent Search") && isSouthAfrican()});
        return questionSets;
    }

    let fetchSharksQuiz = async function() {
        console.log('fetching Sharks Quiz tests for user: ' + getID());
        try {
            // add Sharks Quiz tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("2023 Sharks Quiz"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Sharks Quiz tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Sharks Quiz tests tests for user", error, false);
            return null;
        }
    }

    let fetchMathsForEveryone = async function() {
        console.log('fetching Maths for Everyone tests for user: ' + getID());
        try {
            // add Maths for Everyone tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Maths for Everyone"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Maths for Everyone tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Maths for Everyone tests tests for user", error, false);
            return null;
        }
    }

    let fetchSchoolsQuiz = async function() {
        console.log('fetching Schools Quiz tests for user: ' + getID());
        try {
            // add schools quiz tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("PnP School Club"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Schools Quiz tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Schools Quiz tests tests for user", error, false);
            return null;
        }
    }

    let fetchKangarooTests = async function() {
        console.log('fetching Kangaroo tests for user: ' + getID());
        try {
            // add Kangaroo tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Kangaroo"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Kangaroo tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Kangaroo tests tests for user", error, false);
            return null;
        }
    };

    let fetchKangarooAfricaTests = async function() {
        console.log('fetching Kangaroo tests for user: ' + getID() + ' and country: ' + getCountry());
        try {
            // add Kangaroo tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Kangaroo " + cleanCountryNameForKangaroo(getCountry())); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Kangaroo tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Kangaroo tests tests for user", error, false);
            return null;
        }
    };

    let cleanCountryNameForKangaroo = function(country) {
        let bracketIndex = country.indexOf('(');
        if (bracketIndex > 0) {
            return country.substring(0, bracketIndex - 1);
        }
        return country;
    };

    let fetchSAMOTests = async function() {
        console.log('fetching SAMO tests for user: ' + getID());
        try {
            // add SAMO tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => {
                return (questionSet.name.startsWith("2023 Old Mutual SA Maths Olympiad") ||
                    questionSet.name.startsWith("2023 Old Mutual SA Wiskunde-Olimpiade"));
            });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated 2023 South African Mathematics Olympiad tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching 2023 South African Mathematics Olympiad tests tests for user", error, false);
            return null;
        }
    };

    let fetchSAMCTests = async function() {
        console.log('fetching SAMC tests for user: ' + getID());
        try {
            // add SAMC tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => {
                return (questionSet.name.startsWith("2023 South African Mathematics Challenge") ||
                    questionSet.name.startsWith("2023 Suid-Afrikaanse Wiskunde-uitdaging"));
            });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated 2023 South African Mathematics Challenge tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching 2023 South African Mathematics Challenge tests tests for user", error, false);
            return null;
        }
    };

    let fetchWorldCupTests = async function() {
        console.log('fetching WorldCup tests for user: ' + getID());
        try {
            // add World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("World Cup"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchYear2023Tests = async function() {
        console.log('fetching 2023 tests for user: ' + getID());
        try {
            // add World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("2 0 2 3"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchYear2024Tests = async function() {
        console.log('fetching 2024 tests for user: ' + getID());
        try {
            // add World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("2 0 2 4"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchWomensT20Tests = async function() {
        console.log('fetching WorldCup tests for user: ' + getID());
        try {
            // add World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Women's T20"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchNetballWorlcCupTests = async function() {
        console.log('fetching Netball World Cup tests for user: ' + getID());
        try {
            // add Netball World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Netball"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Netabll World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Netball World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchRugbyWorldCupTests = async function() {
        console.log('fetching Rugby World Cup tests for user: ' + getID());
        try {
            // add Rugby World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Rugby"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Rugby World Cup tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Rugby World Cup tests tests for user", error, false);
            return null;
        }
    };

    let fetchLivingMathsSouthAfricaTests = async function() {
        console.log('fetching Living Maths South Africa tests for user: ' + getID());
        try {
            // add Rugby World Cup tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Living Maths 2023"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Living Maths South Africa tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Living Maths South Africa tests for user", error, false);
            return null;
        }
    };

    let fetchLivingMathsTests = async function() {
        console.log('fetching Living Maths tests for user: ' + getID());
        try {
            // add Living Maths tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return questionSet.name.startsWith("Living Maths - 2023"); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated Living Maths tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching Living Maths tests for user", error, false);
            return null;
        }
    };

    let fetchUCTTests = async function() {
        console.log('fetching UCT tests for user: ' + getID());
        try {
            // add UCT tests
            let nonInvigilatedQuestionSets = await fetchNonInvigilatedTests();
            nonInvigilatedQuestionSets = nonInvigilatedQuestionSets.filter((questionSet) => { return (questionSet.name.startsWith("UCT") || questionSet.name.startsWith("UK")); });
            nonInvigilatedQuestionSets.sort((a, b) => {
                return (a.name < b.name) ? -1 : 1;
            });
            console.log(nonInvigilatedQuestionSets);
            console.log("Non-invigilated UCT tests found");
            return nonInvigilatedQuestionSets;
        } catch (error) {
            logAPIError("Fetching UCT tests for user", error, false);
            return null;
        }
    };

    let fetchTestHost = async function(testHostID) {
        console.log("Fetching TestHost with ID: " + testHostID);  
        try {
          const testHostRet = await API.graphql(graphqlOperation(getTestHost, { id: testHostID }));
          console.log(testHostRet);
          if (testHostRet.data.getTestHost !== null) {
              console.log("TestHost found");
              let testHost = testHostRet.data.getTestHost;
              testHost.testHostID = testHost.id;
              return testHost;
          } else {
              console.log("Error finding TestHost");
              logError("Finding TestHost", "No data returned", true);
              return null;
          }
        } catch (error) {
          logAPIError("Finding TestHost", error, true);
          return null;
        }
    };

    let fetchTestEntriesForTestHost = async function(testID) {
        console.log('fetching test entries for teacher: ' + getEmail());
        try {
            const testEntriesRet = await API.graphql(graphqlOperation(getTestsEntriesForTeacher, { teacherID: getID(), testID: testID }));
            console.log(testEntriesRet);
            if (testEntriesRet.data.getTestEntriesForTeacher !== null  && testEntriesRet.data.getTestEntriesForTeacher.length >= 0) {
                console.log("Test entries found");
                let entries = testEntriesRet.data.getTestEntriesForTeacher;
                // Todo: this should be moved to the server side?
                try {
                    entries.forEach((entry) => {
                        if (entry.timeTaken === null) {
                            let millis = 0;
                            let questionsAnswered = 0;
                            if (entry.entrySections && entry.entrySections.length > 0) {
                                for (let i=0; i < entry.entrySections.length; i++) {
                                    if (entry.entrySections[i].timeTaken !== null) {
                                        millis += convertAWSTimeToMillis(entry.entrySections[i].timeTaken)
                                    }
                                    if (entry.entrySections[i].questionsAnswered !== null) {
                                        questionsAnswered += entry.entrySections[i].questionsAnswered;
                                    }
                                }
                            }
                            entry.timeTaken = convertMillisToDuration(millis);
                            entry.questionsAnswered = questionsAnswered;
                        }
                    });
                    entries.sort((a, b) => {
                        return (a.surname.toLowerCase() < b.surname.toLowerCase()) ? -1 : 1;
                    });
    
                } catch (error) {
                    console.log(error);
                }
                return entries;
            } else {
                console.log("Error fetching test entries");
                logError("Fetching test entries", "No data returned", false);
                return null;
            }
        } catch (error) {
            logAPIError("Fetching test entries", error, false);
            return null;
        }
    };

    let updateTestDateOnServer = async function(testHostID, testID, date) {
        console.log('updating test date: ' + date);
        try {
          const updateTestDateRet = await API.graphql(graphqlOperation(updateTestDate, { testHostID: testHostID, testID: testID, date: date } ));
          console.log(updateTestDateRet);
          if (updateTestDateRet.data.updateTestDate) {
              console.log("Updated test date");
              return true;
          } else {
              console.log("Error updating test date");
              logError("Updating test date", "No data returned", false);
              return false;
          }
        } catch (error) {
          logAPIError("Updating test date", error, false);
          return false;
        }
    };

    /**
     * Fetch a list of question sets from which tests can be scheduled. Note that we add a "private" property to those
     * QuestionSets that are private and that the user can schedule because they have been authorised to.
     * @param prefix
     * @returns {Promise<null|S[]>}
     */
    let fetchQuestionSets = async function(prefixes) {
        // console.log('fetching question sets!!!!');
        try {
            // console.log("0-1");
            const fetchPublicQuestionSets = API.graphql(graphqlOperation(questionSetsPublic, { limit: 300 }));
            // console.log("0-2");
            const fetchPrivateQuestionSets = API.graphql(graphqlOperation(questionSetsUserCanScheduleByID, { userID: {eq: getID()}, limit: 200 }));
            // console.log("0-3");
            const results = await Promise.all([fetchPublicQuestionSets, fetchPrivateQuestionSets]);
            // console.log("0-4");
            const publicQuestionSets = results[0];
            const privateQuestionSets = results[1];
            // console.log("0");
            // console.log(publicQuestionSets);
            if (publicQuestionSets.data.questionSetsByPublicOrPrivate !== null  && 
                publicQuestionSets.data.questionSetsByPublicOrPrivate.items &&
                privateQuestionSets.data.questionSetsUserCanScheduleByID !== null  &&
                privateQuestionSets.data.questionSetsUserCanScheduleByID.items) {
                let questionSets = [];
                questionSets.push(...publicQuestionSets.data.questionSetsByPublicOrPrivate.items);
                // console.log("1");
                // console.log(questionSets);
                for (let questionSetCanSchedule of privateQuestionSets.data.questionSetsUserCanScheduleByID.items) {
                    questionSetCanSchedule.questionSet.private = true;
                    questionSets.push(questionSetCanSchedule.questionSet);
                }
                // remove duplicates
                questionSets = [...new Map(questionSets.map((item) => [item["id"], item])).values()];
                // if there is a prefix, filter out those with names that don't start with that prefix
                if (prefixes) {
                    let filteredQuestionSets = [];
                    for (let prefix of prefixes) {
                        filteredQuestionSets.push(...questionSets.filter((questionSet) => { return questionSet.name.startsWith(prefix); }));
                    }
                    questionSets = filteredQuestionSets;
                }
                // console.log("2");
                // console.log(questionSets);
                // only show those where the registration is open
                const now = new Date();
                questionSets = questionSets.filter((questionSet) => { return questionSet.registrationStart === null || new Date(questionSet.registrationStart) < now});
                questionSets.sort((a, b) => {
                    return (a.name < b.name) ? -1 : 1;
                });
                // console.log("3");
                // console.log(questionSets);
                return questionSets;
            } else {
                console.log("Error fetching question sets");
                logError("Fetching question sets", "No data returned", false);
                return null;
            }
        } catch (error) {
            console.log(error);
            logAPIError("Fetching question sets", error, false);
            return null;
        }
    };

    let createTestAndHostOnServer = async function(questionSetID, name, date) {
        console.log('creating test and host for question set: ' + questionSetID);
        try {
          const createTestRet = await API.graphql(graphqlOperation(createTestAndHostFromQuestionSet, { questionSetID: questionSetID, name: name, date: date, teacherID: getID() } ));
          console.log(createTestRet);
          if (createTestRet.data.createTestAndHostFromQuestionSet !== null) {
              console.log("Created test and host");
              let testHost = createTestRet.data.createTestAndHostFromQuestionSet;
              testHost.testHostID = testHost.id;
              return testHost;
          } else {
              console.log("Error creating test and host");
              logError("Creating test and host", "No data returned", false);
              return null;
          }
        } catch (error) {
          logAPIError("Creating test and host", error, false);
          return null;
        }
    };

    let fetchSchedulersAuthorisedBy = async function(authorisedByID) {
        console.log("Fetching Schedulers authorised by: " + authorisedByID);
        try {
            const schedulersRet = await API.graphql(graphqlOperation(schedulersAuthorisedByUser, { authorisedByID: {eq: authorisedByID} }));
            console.log(schedulersRet);
            if (schedulersRet.data.schedulersAuthorisedByUser !== null) {
                console.log("Schedulers found");
                return schedulersRet.data.schedulersAuthorisedByUser.items;
            } else {
                console.log("Error finding Schedulers");
                logError("Finding Schedulers", "No data returned", true);
                return null;
            }
        } catch (error) {
            logAPIError("Finding Schedulers", error, true);
            return null;
        }
    };

    let approveTestSchedulerForQuestionSets = async function(authorisedByID, newSchedulerEmail, questionSetIDs) {
        console.log('approving a test scheduler: ' + newSchedulerEmail);
        try {
            const approveSchedulerRet = await API.graphql(graphqlOperation(approveTestScheduler, { authorisedByID: authorisedByID, newSchedulerEmail: newSchedulerEmail, questionSetIDs: questionSetIDs } ));
            console.log(approveSchedulerRet);
            if (approveSchedulerRet.data.approveTestScheduler && approveSchedulerRet.data.approveTestScheduler.length > 0) {
                console.log("response to approved received: " + approveSchedulerRet.data.approveTestScheduler);
                return approveSchedulerRet.data.approveTestScheduler;
            } else {
                console.log("Error approving test scheduler");
                logError("Approving test scheduler", "No data returned", false);
                return [];
            }
        } catch (error) {
            logAPIError("Approving test scheduler", error, false);
            return [];
        }
    };

    let fetchWorksheetsExcludingPrefixesWithEntries = async function(excludePrefixes) {
        console.log("Fetching Worksheets");
        try {
            const worksheetsData = await API.graphql(graphqlOperation(listWorksheets, {}));
            const worksheetEntriesData = await API.graphql(graphqlOperation(allWorksheetEntriesForUser, {userID: that.getID()}));
            let worksheets = [];
            if (worksheetsData.data.listWorksheets && worksheetsData.data.listWorksheets.items) {
                for (let worksheetData of worksheetsData.data.listWorksheets.items) {
                    let exclude = false;
                    for (let excludePrefix of excludePrefixes) {
                        if (worksheetData.name.startsWith(excludePrefix)) {
                            exclude = true;
                            break;
                        }
                    }
                    if (!exclude) {
                        worksheets.push(worksheet(worksheetData, findEntryForWorksheet(worksheetEntriesData, worksheetData.id)));
                    }
                }
                worksheets.sort((w1, w2) => {
                    return (w1.getName() < w2.getName()) ? -1 : 1;
                });
                return worksheets;
            } else {
                console.log("Error finding Worksheets");
                logError("Finding Worksheets", "No data returned", true);
                return [];
            }
        } catch (error) {
            logAPIError("Finding TestHost", error, true);
            return null;
        }
    };

    let fetchWorksheetsByPrefixWithEntries = async function(includePrefixes) {
        const prefix = includePrefixes[0];
        console.log("Fetching Worksheets by prefix: " + prefix);
        try {
            const worksheetsData = await API.graphql(graphqlOperation(worksheetsByPrefix, { baseType: "Worksheet", name: {beginsWith: prefix}}));
            const worksheetEntriesData = await API.graphql(graphqlOperation(allWorksheetEntriesForUser, {userID: that.getID()}));
            let worksheets = [];
            if (worksheetsData.data.worksheetsByPrefix && worksheetsData.data.worksheetsByPrefix.items) {
                for (let worksheetData of worksheetsData.data.worksheetsByPrefix.items) {
                    if (worksheetData.name.startsWith(prefix)) {
                        console.log("Found worksheet: " + worksheetData.name);
                        worksheetData.name = worksheetData.name.substring(prefix.length+1);
                        worksheets.push(worksheet(worksheetData, findEntryForWorksheet(worksheetEntriesData, worksheetData.id)));
                    }
                }
                worksheets.sort((w1, w2) => {
                    return (w1.getName() < w2.getName()) ? -1 : 1;
                });
                return worksheets;
            } else {
                console.log("Error finding Worksheets");
                logError("Finding Worksheets", "No data returned", true);
                return [];
            }
        } catch (error) {
            logAPIError("Finding TestHost", error, true);
            return null;
        }
    };

    let findEntryForWorksheet = function (worksheetEntries, worksheetID) {
        if (worksheetEntries.data.allWorksheetEntriesForUser.items) {
            for (let entry of worksheetEntries.data.allWorksheetEntriesForUser.items) {
                if (entry.worksheetID === worksheetID) {
                    return entry;
                }
            }
        }
        return null;
    };

    let startWorksheet = async function() {
        console.log("Fetching Worksheets");
        // try {
            // const worksheetsData = await API.graphql(graphqlOperation(listWorksheets, {}));
            // const worksheetEntriesData = await API.graphql(graphqlOperation(listWorksheetEntries, {}));
            // let worksheets = [];
            // if (worksheetsData.data.listWorksheets && worksheetsData.data.listWorksheets.items) {
            //     for (let worksheetData of worksheetsData.data.listWorksheets.items) {
            //         worksheets.push(worksheet(worksheetData, findEntryForWorksheet(worksheetEntriesData, worksheetData.id)));
            //     }
            //     return worksheets;
            // } else {
            //     console.log("Error finding Worksheets");
            //     logError("Finding Worksheets", "No data returned", true);
            //     return [];
            // }
        // } catch (error) {
        //     logAPIError("Finding TestHost", error, true);
        //     return null;
        // }
    }

    let logAPIError = function(context, error, fatal) {
        if (error.errors && error.errors[0] && error.errors[0].message) {
          logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
        } else {
          logError(context, JSON.stringify(error, null, 2), fatal);
        }
    };

    let logError = function(context, errorMessage, fatal) {
          console.log("Logging an error: " + errorMessage);
          let dataObject = {
              'event': 'exception',
              'errorMessage': {
              'category': 'API',
              'description': context + ': ' + errorMessage,
              'fatal': fatal
              }
          };
          if(typeof window.dataLayer != 'undefined'){
              console.log("Sending the error to the dataLayer");
              window.dataLayer.push(dataObject);
          }
    };

    that.getID = getID;
    that.getEmail = getEmail;
    that.setEmail = setEmail;
    that.getReceiveMarketingEmails = getReceiveMarketingEmails;
    that.setReceiveMarketingEmails = setReceiveMarketingEmails;
    that.getState = getState;
    that.isRegistering = isRegistering;
    that.setState = setState;
    that.doesUserHaveAccount = doesUserHaveAccount;
    that.getFirstName = getFirstName;
    that.setFirstName = setFirstName;
    that.getSurname = getSurname;
    that.setSurname = setSurname;
    that.getNickname = getNickname;
    that.setNickname = setNickname;
    that.hasFirstName = hasFirstName;
    that.hasNickname = hasNickname;
    that.getNameForChat = getNameForChat;
    that.getMobile = getMobile;
    that.getLanguage = getLanguage;
    that.getGrade = getGrade;
    that.hasGrade = hasGrade;
    that.setGrade = setGrade;
    that.getType = getType;
    that.isTeacher = isTeacher;
    that.isStudent = isStudent;
    that.isParent = isParent;
    that.isObserver = isObserver;
    that.setType = setType;
    that.getLanguage = getLanguage;
    that.setLanguage = setLanguage;
    that.getGender = getGender;
    that.setGender = setGender;
    that.getRace = getRace;
    that.setRace = setRace;
    that.getSchool = getSchool;
    that.setSchool = setSchool;
    that.getCountry = getCountry;
    that.setCountry = setCountry;
    that.isSouthAfrican = isSouthAfrican;
    that.isBhutanian = isBhutanian;
    that.isBurundian = isBurundian;
    that.isEthiopian = isEthiopian;
    that.isGhanaian = isGhanaian;
    that.isRwandan = isRwandan;
    that.isKenyan = isKenyan;
    that.isIrish = isIrish;
    that.isBotswanan = isBotswanan;
    that.isNigerian = isNigerian;
    that.isNamibian = isNamibian;
    that.isMauritian = isMauritian;
    that.isMalawian = isMalawian;
    that.isSierraLeonian = isSierraLeonian;
    that.isSouthSudanian = isSouthSudanian;
    that.isSudanian = isSudanian;
    that.isTanzanian = isTanzanian;
    that.isUgandan = isUgandan;
    that.isZimbabwian = isZimbabwian;
    that.isZambian = isZambian;
    that.isEswatinian = isEswatinian;
    that.isLesothoan = isLesothoan;
    that.getOwner = getOwner;
    that.getMetricsID = getMetricsID;
    that.getCognitoIdentityID = getCognitoIdentityID;
    that.hasCognitoIdentityID = hasCognitoIdentityID;
    that.setCognitoIdentityID = setCognitoIdentityID;
    that.getLog = getLog;
    that.hasLog = hasLog;
    that.getLastLogEntry = getLastLogEntry;
    that.getELOScore = getELOScore;
    that.setELOScore = setELOScore;
    that.getLinkCode = getLinkCode;
    
    that.fetchTestsHosting = fetchTestsHosting;
    that.fetchTestEntries = fetchTestEntries;
    that.fetchInvigilatedTestEntries = fetchInvigilatedTestEntries;

    that.fetchCompetitionPracticeTestEntries = fetchCompetitionPracticeTestEntries;
    that.fetchPastPaperTestEntries = fetchPastPaperTestEntries;
    that.fetchSharksQuizTestEntries = fetchSharksQuizTestEntries;
    that.fetchMathsForEveryoneTestEntries = fetchMathsForEveryoneTestEntries;
    that.fetchSchoolsQuizTestEntries = fetchSchoolsQuizTestEntries;
    that.fetchTalentSearchTestEntries = fetchTalentSearchTestEntries;
    that.fetchKangarooTestEntries = fetchKangarooTestEntries;
    that.fetchKangarooAfricaTestEntries = fetchKangarooAfricaTestEntries;
    that.fetchSAMOTestEntries = fetchSAMOTestEntries;
    that.fetchSAMCTestEntries = fetchSAMCTestEntries;
    that.fetchWorldCupTestEntries = fetchWorldCupTestEntries;
    that.fetchYear2023TestEntries = fetchYear2023TestEntries;
    that.fetchYear2024TestEntries = fetchYear2024TestEntries;
    that.fetchWomensT20TestEntries = fetchWomensT20TestEntries;
    that.fetchNetballWorlcCupTestEntries = fetchNetballWorlcCupTestEntries;
    that.fetchRugbyWorldCupTestEntries = fetchRugbyWorldCupTestEntries;
    that.fetchLivingMathsSouthAfricaTestEntries = fetchLivingMathsSouthAfricaTestEntries;
    that.fetchLivingMathsTestEntries = fetchLivingMathsTestEntries;
    that.fetchUCTTestEntries = fetchUCTTestEntries;

    that.fetchCompetitionPracticeTests = fetchCompetitionPracticeTests;
    that.fetchPastPaperTests = fetchPastPaperTests;
    that.fetchTalentSearch = fetchTalentSearch;
    that.fetchSharksQuiz = fetchSharksQuiz;
    that.fetchMathsForEveryone = fetchMathsForEveryone;
    that.fetchSchoolsQuiz = fetchSchoolsQuiz;
    that.fetchKangarooTests = fetchKangarooTests;
    that.fetchKangarooAfricaTests = fetchKangarooAfricaTests;
    that.fetchSAMOTests = fetchSAMOTests;
    that.fetchSAMCTests = fetchSAMCTests;
    that.fetchWorldCupTests = fetchWorldCupTests;
    that.fetchYear2023Tests = fetchYear2023Tests;
    that.fetchYear2024Tests = fetchYear2024Tests;
    that.fetchWomensT20Tests = fetchWomensT20Tests;
    that.fetchNetballWorlcCupTests = fetchNetballWorlcCupTests;
    that.fetchRugbyWorldCupTests = fetchRugbyWorldCupTests;
    that.fetchLivingMathsSouthAfricaTests = fetchLivingMathsSouthAfricaTests;
    that.fetchLivingMathsTests = fetchLivingMathsTests;
    that.fetchRugbyWorldCupTests = fetchRugbyWorldCupTests;
    that.fetchUCTTests = fetchUCTTests;

    that.fetchTestHost = fetchTestHost;
    that.fetchTestEntriesForTestHost = fetchTestEntriesForTestHost;
    that.updateTestDateOnServer = updateTestDateOnServer;
    that.fetchQuestionSets = fetchQuestionSets;
    that.createTestAndHostOnServer = createTestAndHostOnServer;
    that.fetchSchedulersAuthorisedBy = fetchSchedulersAuthorisedBy;
    that.approveTestSchedulerForQuestionSets = approveTestSchedulerForQuestionSets;

    that.fetchWorksheetsByPrefixWithEntries = fetchWorksheetsByPrefixWithEntries;
    that.fetchWorksheetsExcludingPrefixesWithEntries = fetchWorksheetsExcludingPrefixesWithEntries;
    that.startWorksheet = startWorksheet;

    return that;
};

export default user;