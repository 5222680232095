<template>
  <div class="profile">
    <div class="close" v-on:click="closeDialog()">&times;</div>

    <template v-if="loadingTests">
      <spinner height="50px" colour="#F9AC1B"/>
    </template>

    <template v-if="!loadingTests">

      <template v-if="leaderboardTest">
        <leaderboard-test v-bind:user="user" v-bind:test="leaderboardTest"></leaderboard-test>
      </template>

      <template v-else>
        <div class="title">
          <div class="title__heading">
            <span>{{listsType}}</span>
          </div>
        </div>

        <div class="lists-of-tests">

          <ul class="lists-of-tests__help">
            <h6>{{ $t('commonTestsTitleLearnersMsg')}}</h6>
            <li><img src="../assets/plus.svg" alt="$t('commonTestsRegisterAltMsg')"/> {{ $t('commonTestsRegisterMsg')}}</li>
            <li><img src="../assets/arrow.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/> {{ $t('commonTestsOpenTestEntryMsg')}}</li>
            <li><div class="image-button image-button--view image-button--view--small"><img src="../assets/eye.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/></div> {{ $t('commonTestsOpenCompletedTestEntryMsg')}}</li>
          </ul>

          <!-- ----------------------- -->
          <!-- CURRENT TESTS           -->
          <!-- ----------------------- -->
          <div class="lists-of-tests__test-list">
            <h6>{{ $t('commonTestsTitleCurrentMsg')}}</h6>
            <ul class="my-information">
              <li v-for="test in currentTests" v-bind:key="test.id" class="my-information__detail">
                <div class="test__detail">
                  <!--                           -->
                  <!-- Self Start vs Invigilated -->
                  <!--                           -->
                  <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeSelfStartMsg')}}</span>
                  <span v-else class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeInvigilatedMsg')}}</span>

                  <!--                           -->
                  <!-- Name                      -->
                  <!--                           -->
                  <span class="test-name">{{test.test.name}}</span>

                  <!--                           -->
                  <!-- Sub heading               -->
                  <!--                           -->
                  <!-- Grade -->
                  <span class="profile-sub-heading">
                    {{ $t('commonTestsGradeMsg')}} {{test.questionSet.minGrade}}
                    <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                      - {{test.questionSet.maxGrade}}
                    </template>
                  </span>
                  <span class="pipe-break">|</span>
                  <!-- Total registered -->
                  <span class="profile-sub-heading">{{test.test.totalRegistered}} {{ $t('commonTestsLearnersRegisteredMsg')}}</span>
                  <span class="pipe-break">|</span>
                  <!-- Start date -->
                  <span class="profile-sub-heading">
                    <!-- if we have a TestEntry -->
                    <template v-if="test.testEntry">
                      <!-- has is been started? -->
                      <template v-if="test.testEntry.startedAt">
                        {{getDisplayDate(test.testEntry.startedAt)}}
                      </template>
                      <!-- else if our TestEntry has a specific start date -->
                      <template v-else-if="test.testEntry.participationStart">
                          <!-- we've passed the participation start -->
                          <template v-if="isDateInPast(test.testEntry.participationStart)">
                            {{ $t('commonTestsStartWhenReadyMsg')}}
                          </template>
                          <!-- else show when we can start -->
                          <template v-else>
                            {{getDisplayDate(test.testEntry.participationStart)}}
                          </template>
                      </template>
                      <!-- fallback is tell user to start -->
                      <template v-else>
                        {{ $t('commonTestsStartWhenReadyMsg')}}
                      </template>
                    </template>
                    <!-- otherwise look at the QuestionSet -->
                    <template v-else>
                        <!-- un-invigilated tests -->
                        <template v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'">
                          {{ $t('commonTestsStartWhenReadyMsg')}}
                        </template>
                        <!-- else it's an invigilated test, waiting for invigilator to start -->
                        <template v-else>
                          {{getDisplayDate(test.test.date)}}
                        </template>
                    </template>
                  </span>
                  <!-- If finished, score -->
                  <template v-if="test.testEntry && test.testEntry.finishedAt">
                    <span class="pipe-break">|</span>
                    <span class="profile-sub-heading">{{ $t('commonTestsScoreMsg')}} {{test.testEntry.score}}</span>
                  </template>
                  <!-- Else, optionally T&Cs -->
                  <template v-else-if="test.questionSet.termsAndConditionsURL">
                    <span class="pipe-break">|</span>
                    <span class="profile-sub-heading"><a v-bind:href="test.questionSet.termsAndConditionsURL" target="_blank">{{ $t('commonTestsTermAndConditionsMsg')}}</a></span>
                    <template v-if="isTeacher || isParent">
                      <br/>
                      <span class="test__invite-link" v-on:click="copyTestInviteLink(test)">
                        {{ $t('commonTestsInviteLearnersMsg')}}
                        <img src="../assets/copy.svg" alt="Copy to clipboard"/>
                      </span>
                    </template>
                  </template>
                </div>
                <div class="test__cta">
                  <!-- Show leaderboard -->
                  <div v-on:click="selectLeaderboard(test)"
                       class="image-button image-button--leaderboard">
                    <img src="../assets/MYTUTOR ICONS-06.svg" alt="icon"/>
                  </div>
                  <!-- Choice of actions: View test, open test, or register for test -->
                  <template v-if="test.testEntry">
                    <div v-if="test.testEntry.finishedAt" v-on:click="selectTest(test)" class="image-button image-button--view">
                      <img src="../assets/eye.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/>
                    </div>
                    <div v-else v-on:click="selectTest(test)" class="image-button">
                      <img src="../assets/arrow.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/>
                    </div>
                  </template>
                  <div v-else-if="test.questionSet.invigilation !== 'NO_INVIGILATOR'" v-on:click="selectTest(test)" class="image-button">
                    <img src="../assets/arrow.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/>
                  </div>
                  <div v-else v-on:click="registerForTest(test)" class="image-button">
                    <img src="../assets/plus.svg" alt="$t('commonTestsRegisterAltMsg')"/>
                  </div>
                </div>
              </li>
              <li v-if="currentTests.length === 0" class="my-information__detail--empty">
                {{ $t('listOfTestsNoTestsPrefixMsg')}} {{listsType}} {{ $t('listOfTestsNoTestsSuffixMsg')}}
              </li>
            </ul>
          </div>

          <!-- ----------------------- -->
          <!-- FUTURE TESTS            -->
          <!-- ----------------------- -->
          <div v-if="futureTests.length > 0" class="lists-of-tests__test-list">
            <h6>{{ $t('commonTestsTitleUpcomingMsg')}}</h6>
            <ul class="my-information">
              <li v-for="test in futureTests" v-bind:key="test.id" class="my-information__detail">
                <div class="test__detail">
                  <!--                           -->
                  <!-- Self Start vs Invigilated -->
                  <!--                           -->
                  <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeSelfStartMsg')}}</span>
                  <span v-else class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeInvigilatedMsg')}}</span>

                  <!--                           -->
                  <!-- Name                      -->
                  <!--                           -->
                  <span class="test-name">{{test.test.name}}</span>

                  <!--                           -->
                  <!-- Sub heading               -->
                  <!--                           -->
                  <!-- Grade -->
                  <span class="profile-sub-heading">
                    {{ $t('commonTestsGradeMsg')}} {{test.questionSet.minGrade}}
                    <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                      - {{test.questionSet.maxGrade}}
                    </template>
                  </span>
                  <span class="pipe-break">|</span>
                  <!-- Total registered -->
                  <span class="profile-sub-heading">{{test.test.totalRegistered}} {{ $t('commonTestsLearnersRegisteredMsg')}}</span>
                  <span class="pipe-break">|</span>
                  <!-- Start date -->
                  <span class="profile-sub-heading">
                    <template v-if="test.test">
                      {{getDisplayDate(test.test.date)}}
                    </template>
                    <template v-else>
                      {{getDisplayDate(test.questionSet.participationStart)}}
                    </template>
                  </span>
                </div>
                <div class="test__cta">
                  <div v-on:click="selectLeaderboard(test)" class="image-button image-button--leaderboard">
                    <img src="../assets/MYTUTOR ICONS-06.svg" alt="icon"/>
                  </div>
                  <!-- Choice of actions: Open test, or register for test -->
                  <div v-if="test.testEntry || test.questionSet.invigilation !== 'NO_INVIGILATOR'" v-on:click="selectTest(test)" class="image-button">
                    <img src="../assets/arrow.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/>
                  </div>
                  <div v-else v-on:click="registerForTest(test)" class="image-button">
                    <img src="../assets/plus.svg" alt="$t('commonTestsRegisterAltMsg')"/>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- ----------------------- -->
          <!-- PAST TESTS              -->
          <!-- ----------------------- -->
          <div v-if="pastTests.length > 0" class="lists-of-tests__test-list">
            <h6>{{ $t('commonTestsTitlePastMsg')}}</h6>
            <ul class="my-information">
              <li v-for="test in pastTests" v-bind:key="test.id" class="my-information__detail">
                <div class="test__detail">
                  <!--                           -->
                  <!-- Self Start vs Invigilated -->
                  <!--                           -->
                  <span v-if="test.questionSet.invigilation === 'NO_INVIGILATOR'" class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeSelfStartMsg')}}</span>
                  <span v-else class="profile-sub-heading profile-sub-heading--strong">{{ $t('commonTestsTypeInvigilatedMsg')}}</span>

                  <!--                           -->
                  <!-- Name                      -->
                  <!--                           -->
                  <span class="test-name">{{test.test.name}}</span>

                  <!--                           -->
                  <!-- Sub heading               -->
                  <!--                           -->
                  <!-- Grade -->
                  <span class="profile-sub-heading">
                    {{ $t('commonTestsGradeMsg')}} {{test.questionSet.minGrade}}
                    <template v-if="test.questionSet.minGrade !== test.questionSet.maxGrade">
                      - {{test.questionSet.maxGrade}}
                    </template>
                  </span>
                  <span class="pipe-break">|</span>
                  <!-- Total registered -->
                  <span class="profile-sub-heading">{{test.test.totalRegistered}} {{ $t('commonTestsLearnersRegisteredMsg')}}</span>
                  <span class="pipe-break">|</span>
                  <!-- Start date -->
                  <span class="profile-sub-heading">
                    <template v-if="test.testEntry">
                      <template v-if="test.testEntry.startedAt">
                        {{getDisplayDate(test.testEntry.startedAt)}}
                      </template>
                      <template v-else-if="test.testEntry.participationStart">
                        {{getDisplayDate(test.testEntry.participationStart)}}
                      </template>
                    </template>
                    <template v-else>
                        {{getDisplayDate(test.test.date)}}
                    </template>
                  </span>
                  <!-- If there is a test entry, the score -->
                  <template v-if="test.testEntry">
                    <span class="pipe-break">|</span>
                    <span class="profile-sub-heading">{{ $t('commonTestsScoreMsg')}} {{test.testEntry.score}}</span>
                  </template>
                </div>
                <div class="test__cta">
                  <div v-on:click="selectLeaderboard(test)" class="image-button image-button--leaderboard">
                    <img src="../assets/MYTUTOR ICONS-06.svg" alt="icon"/>
                  </div>
                  <div v-on:click="selectTest(test)" class="image-button image-button--view">
                    <img src="../assets/eye.svg" alt="$t('commonTestsOpenTestEntryAltMsg')"/>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>

      </template>
    </template>

    <!-- ----------------------- -->
    <!-- POPUPS                  -->
    <!-- ----------------------- -->
    <notifications v-if="showNotifications"
                   v-bind:notifications="notifications"
                   v-bind:showSpinner="false">
    </notifications>

  </div>
</template>

<script>
    import Spinner from './Spinner.vue';
    import LeaderboardTest from '../components/LeaderboardTest';
    import Notifications from '../components/Notifications';

    // Mixins
    import {testsMixin} from "../mixins/TestsMixin";

    export default {
        name: 'ListOfTests',
        components: {
            Spinner, LeaderboardTest, Notifications
        },
        mixins: [testsMixin],
        props: {
            user: Object,
            listsType: String,
            testsHostingFn: Function,
            testsFn: Function,
            entriesFn: Function,
            allowMultipleEntriesForSameTest: Boolean,
            selectFirst10Tests: Boolean,
            closeDialogKey: String
        },
        data: function () {
            return {
                typeOfTest: this.$t('')
            }
        },
        computed: {},
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            loadTests: async function() {
                this.loadingTests = true;
                this.resetTestsLists();
                this.addTestAndTestEntriesAndDivideIntoPastCurrentAndFuture(
                    await this.testsHostingFn.call(this),
                    await this.testsFn.call(this),
                    await this.entriesFn.call(this),
                    this.allowMultipleEntriesForSameTest,
                    this.selectFirst10Tests);
                this.loadingTests = false;
            },
            closeDialog: function () {
                this.$root.$emit(this.closeDialogKey);
            }
        },
        mounted: async function () {
            await this.loadTests();
            this.$root.$on('refresh-tests', this.loadTests);

            this.closeLeaderboardFn = () => {
                this.leaderboardTest = null;
            };
            this.$root.$on('close-leaderboard-test', this.closeLeaderboardFn);
        },
        beforeDestroy() {
            this.$root.$off('refresh-tests', this.loadTests);
            this.$root.$off("close-leaderboard-test", this.closeLeaderboardFn);
        },
    }
</script>

<style lang="scss" scoped>
  @import "../styles/pages/profile";
  @import "../styles/pages/tests";
</style>

