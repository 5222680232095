<template>
    <div>
        <profile v-if="showProfile" v-bind:user="user" v-bind:showingFriendProfile="false"></profile>

        <leaderboard v-if="showLeaderboard" v-bind:user="user"></leaderboard>

        <my-students v-if="showMyStudents" v-bind:user="user"></my-students>

        <profile v-if="showFriendProfile" v-bind:user="friendProfile" v-bind:showingFriendProfile="true"></profile>

        <my-students-test-entries v-if="showMyStudentsTestEntries" v-bind:teacher="user"></my-students-test-entries>

        <my-students-worksheet-entries v-if="showMyStudentsWorksheetEntries" v-bind:teacher="user"></my-students-worksheet-entries>

        <tests-to-mark v-if="showTestsToMark" v-bind:user="user" v-bind:testsToMark="testsToMark"></tests-to-mark>

        <tests v-if="showTests" v-bind:user="user"></tests>

        <list-of-tests v-if="showCompetitionPreparation"
                       v-bind:user="user"
                       v-bind:listsType="this.$t('listOfTestsCompetitionPreparationMsg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchCompetitionPracticeTests"
                       v-bind:entriesFn="this.user.fetchCompetitionPracticeTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="true"
                       v-bind:closeDialogKey="'close-competition-preparation'"
        ></list-of-tests>

        <list-of-tests v-if="showPastPapers"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsPastPapersMsg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchPastPaperTests"
                       v-bind:entriesFn="this.user.fetchPastPaperTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="true"
                       v-bind:closeDialogKey="'close-past-papers'"
        ></list-of-tests>

        <list-of-tests v-if="showKangaroo"
                       v-bind:user="user"
                       v-bind:listsType="listsTypeForKangaroo"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchKangarooAfricaTests"
                       v-bind:entriesFn="this.user.fetchKangarooAfricaTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="false"
                       v-bind:closeDialogKey="'close-kangaroo'"
        ></list-of-tests>

        <list-of-tests v-if="showYear2024"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsYear2024Msg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchYear2024Tests"
                       v-bind:entriesFn="this.user.fetchYear2024TestEntries"
                       v-bind:allowMultipleEntriesForSameTest="true"
                       v-bind:selectFirst10Tests="true"
                       v-bind:closeDialogKey="'close-year-2024'"
        ></list-of-tests>

        <list-of-tests v-if="showUCT"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsUCTMsg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchUCTTests"
                       v-bind:entriesFn="this.user.fetchUCTTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="false"
                       v-bind:closeDialogKey="'close-uct'"
        ></list-of-tests>

        <list-of-tests v-if="showTalentSearch"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsTalentSearchMsg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchTalentSearch"
                       v-bind:entriesFn="this.user.fetchTalentSearchTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="false"
                       v-bind:closeDialogKey="'close-talent-search'"
        ></list-of-tests>

        <SAMO v-if="showSAMO" v-bind:user="user"></SAMO>

        <SAMC v-if="showSAMC" v-bind:user="user"></SAMC>

        <list-of-tests v-if="showLivingMathsSouthAfrica"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsLivingMaths2023Msg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchLivingMathsSouthAfricaTests"
                       v-bind:entriesFn="this.user.fetchLivingMathsSouthAfricaTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="false"
                       v-bind:closeDialogKey="'close-living-maths-south-africa'"
        ></list-of-tests>

        <list-of-tests v-if="showLivingMaths"
                       v-bind:user="user"
                       v-bind:listsType="$t('listOfTestsLivingMaths2023Msg')"
                       v-bind:testsHostingFn="async () => {return []}"
                       v-bind:testsFn="this.user.fetchLivingMathsTests"
                       v-bind:entriesFn="this.user.fetchLivingMathsTestEntries"
                       v-bind:allowMultipleEntriesForSameTest="false"
                       v-bind:selectFirst10Tests="false"
                       v-bind:closeDialogKey="'close-living-maths'"
        ></list-of-tests>

        <list-of-worksheets v-if="showWorksheetsExtension"
                  v-bind:user="user"
                  v-bind:listsType="$t('headerMenuHomeworkMsg')"
                  v-bind:worksheetsFn="this.user.fetchWorksheetsExcludingPrefixesWithEntries"
                  v-bind:worksheetsFnParams="['Ubuntu', 'Past Paper']"
                  v-bind:closeDialogKey="'close-worksheets-extension'"
        ></list-of-worksheets>

        <list-of-worksheets v-if="showWorksheetsUbuntu"
                  v-bind:user="user"
                  v-bind:listsType="$t('headerMenuHomeworkUbuntuMsg')"
                  v-bind:worksheetsFn="this.user.fetchWorksheetsByPrefixWithEntries"
                  v-bind:worksheetsFnParams="['Ubuntu']"
                  v-bind:closeDialogKey="'close-worksheets-ubuntu'"
        ></list-of-worksheets>

        <list-of-worksheets v-if="showWorksheetsPastPaper"
                  v-bind:user="user"
                  v-bind:listsType="$t('headerMenuHomeworkPastPaperMsg')"
                  v-bind:worksheetsFn="this.user.fetchWorksheetsByPrefixWithEntries"
                  v-bind:worksheetsFnParams="   ['Past Paper']"
                  v-bind:closeDialogKey="'close-worksheets-past-paper'"
        ></list-of-worksheets>

        <div class="chat-window__header">
            <template v-if="!doesUserHaveAccount">
                <img v-on:click="signOutAndGoHome" src="../assets/logo-home.svg" class="logo-link" alt="MyTutor Logo"/>
            </template>
            <template v-else>
                <img src="../assets/logo-home.svg" alt="MyTutor Logo"/>
            </template>

            <div class="chat-window__header__menu"
                 v-show="doesUserHaveAccount"
                 v-bind:class="{'chat-window__header__menu--open': showBurgerMenu}"
                 v-on:click.prevent="showBurgerMenu = !showBurgerMenu">&#x2630;
            </div>
        </div>

        <div class="burger-menu" v-show="showBurgerMenu" v-on:click="showBurgerMenu = !showBurgerMenu">
            <ul v-on:click="checkVersionTimer()">
                <li>
                    <router-link to="/chat">{{ $t('headerMenuChatMsg')}}</router-link>
                </li>
                <template v-if="user">
                    <template v-if="!user.isRegistering() || user.isTeacher()">
                        <li><a v-on:click.prevent="loadLeaderboard">{{ $t('headerMenuLeaderboardMsg')}}</a></li>
                        <li class="burger-menu__separator"></li>
                        <li><a v-on:click.prevent="loadWorksheetsExtension">{{ $t('headerMenuHomeworkMsg')}}</a></li>
<!--                        <li><a v-on:click.prevent="loadWorksheetsUbuntu">{{ $t('headerMenuHomeworkUbuntuMsg')}}</a></li>-->
                        <li><a v-on:click.prevent="loadWorksheetsPastPaper">{{ $t('headerMenuHomeworkPastPaperMsg')}}</a></li>
                        <li class="burger-menu__separator"></li>
                        <li><a v-on:click.prevent="loadTests">{{ $t('headerMenuEventsAndTestsMsg')}}</a></li>
                        <li><a v-on:click.prevent="loadCompetitionPreparation">{{ $t('headerMenuCompetitionPreparationMsg')}}</a></li>
                        <li><a v-on:click.prevent="loadPastPapers">{{ $t('headerMenuPastPapersMsg')}}</a></li>
<!--                        <li v-if="isSouthAfricanStudent"><a v-on:click.prevent="loadTalentSearch">Talent Search</a></li>-->
<!--                        <li><a v-on:click.prevent="loadSAMO">2023 SAMO</a></li>-->
<!--                        <li><a v-on:click.prevent="loadSAMC">2023 SAMC</a></li>-->
                        <li v-if="isAfricanStudentInKangarooCountry"><a v-on:click.prevent="loadKangaroo">{{ $t('headerMenuKangarooPrefixMsg')}} {{user.getCountry()}} {{ $t('headerMenuKangarooSuffixMsg')}}</a></li>
                        <li><a v-on:click.prevent="loadYear2024">{{ $t('headerMenuYear2024Msg')}}</a></li>
                        <li><a v-on:click.prevent="loadUCT">UCT Mathematics Competition</a></li>
<!--                        <li v-if="user.isTeacher()"><a v-on:click.prevent="loadUCT">UCT Mathematics Competition</a></li>-->
<!--                        <li v-if="isSouthAfricanStudent"><a v-on:click.prevent="loadLivingMathsSouthAfrica">{{ $t('headerMenuLivingMathsMsg')}}</a></li>-->
<!--                        <li v-if="!isSouthAfricanStudent"><a v-on:click.prevent="loadLivingMaths">{{ $t('headerMenuLivingMathsMsg')}}</a></li>-->
                    </template>
                    <template v-if="isMarker">
                        <li><a v-on:click.prevent="loadTestsToMark">{{ $t('headerMenuTestsToMarkMsg')}}</a></li>
                    </template>
                </template>
                <li class="burger-menu__separator"></li>
                <li><a v-on:click.prevent="loadProfile">{{ $t('headerMenuMyProfileMsg')}}</a></li>
                <template v-if="user">
                    <template v-if="!user.isRegistering()">
                        <li v-if="user.isTeacher()"><a v-on:click.prevent="loadMyStudents">{{ $t('headerMenuMyStudentsMsg')}}</a></li>
                        <li v-if="user.isTeacher() || user.isParent() || user.isObserver()"><a v-on:click.prevent="loadMyStudentsTestEntries">{{ $t('headerMenuMyStudentsTestEntriesMsg')}}</a></li>
                        <li v-if="user.isTeacher() || user.isParent() || user.isObserver()"><a v-on:click.prevent="loadMyStudentsWorksheetEntries">{{ $t('headerMenuMyStudentsWorksheetEntriesMsg')}}</a></li>
                    </template>
                </template>
                <li><a href="/contact-us/" target="_blank">{{ $t('headerMenuContactUsMsg')}}</a></li>
                <li class="burger-menu__separator"></li>
                <li class="burger-menu__logout">
                    <amplify-sign-out></amplify-sign-out>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {store} from '../store.js'

    // Visual components
    import Profile from './Profile.vue';
    import Leaderboard from './Leaderboard.vue';
    import MyStudents from './MyStudents.vue';
    import MyStudentsTestEntries from './MyStudentsTestEntries.vue';
    import MyStudentsWorksheetEntries from './MyStudentsWorksheetEntries.vue';
    import ListOfTests from './ListOfTests.vue';
    import Tests from './Tests.vue';
    import SAMO from './SAMO.vue';
    import SAMC from './SAMC.vue';
    import TestsToMark from './TestsToMark.vue';
    import ListOfWorksheets from './ListOfWorksheets.vue';

    export default {
        name: 'Header',
        components: {
            Profile,
            Leaderboard,
            MyStudents,
            MyStudentsTestEntries,
            MyStudentsWorksheetEntries,
            ListOfTests,
            Tests,
            SAMO,
            SAMC,
            TestsToMark,
            ListOfWorksheets,
        },
        data() {
            return {
                showBurgerMenu: false,
                showProfile: false,
                showLeaderboard: false,
                showMyStudents: false,
                showFriendProfile: false,
                friendProfile: null,
                showMyStudentsTestEntries: false,
                showMyStudentsWorksheetEntries: false,
                showTests: false,
                showCompetitionPreparation: false,
                showPastPapers: false,
                showYear2024: false,
                showLivingMathsSouthAfrica: false,
                showLivingMaths: false,
                showTestsToMark: false,
                showKangaroo: false,
                showUCT: false,
                showTalentSearch: false,
                showSAMO: false,
                showSAMC: false,
                showWorksheetsExtension: false,
                showWorksheetsUbuntu: false,
                showWorksheetsPastPaper: false,
            }
        },
        computed: {
            user: function () {
                return store.user;
            },
            doesUserHaveAccount: function () {
                return store.user && store.user.doesUserHaveAccount();
            },
            isMarker: function () {
                return this.testsToMark && this.testsToMark.length > 0;
            },
            isSouthAfricanStudent: function () {
                return store.user.isSouthAfrican();
            },
            isAfricanStudentInKangarooCountry: function() {
                return (
                    // store.user.isSouthAfrican()
                    store.user.isSouthAfrican() ||
                    store.user.isBhutanian() ||
                    store.user.isBurundian() ||
                    store.user.isEthiopian() ||
                    store.user.isGhanaian() ||
                    store.user.isRwandan() ||
                    store.user.isKenyan() ||
                    store.user.isIrish() ||
                    // store.user.isBotswanan() ||
                    // store.user.isNigerian() ||
                    store.user.isNamibian() ||
                    store.user.isMauritian() ||
                    store.user.isMalawian() ||
                    store.user.isSierraLeonian() ||
                    store.user.isSouthSudanian() ||
                    store.user.isSudanian() ||
                    store.user.isTanzanian() ||
                    store.user.isUgandan() ||
                    store.user.isZimbabwian() ||
                    store.user.isZambian() ||
                    store.user.isEswatinian() ||
                    store.user.isLesothoan()
                    // store.user.isIrish()
                );
            },
            isGhanaianStudent: function () {
                return store.user.isGhanaian();
            },
            listsTypeForKangaroo: function () {
                return this.$t('headerMenuKangarooPrefixMsg') + " " + this.user.getCountry() + " 2024";
            }
        },
        methods: {
            checkVersionTimer: function () {
                this.$root.$emit('check-version-timer');
            },
            loadProfile: function () {
                console.log("Showing profile");
                this.showProfile = true;
            },
            loadLeaderboard: function () {
                console.log("Showing leaderboard");
                this.showLeaderboard = true;
            },
            loadTests: function () {
                console.log("Showing tests");
                this.showTests = true;
            },
            loadCompetitionPreparation: function () {
                console.log("Showing competition preparation");
                this.showCompetitionPreparation = true;
            },
            loadPastPapers: function () {
                console.log("Showing past papers");
                this.showPastPapers = true;
            },
            loadTalentSearch: function () {
                console.log("Showing talent search");
                this.showTalentSearch = true;
            },
            loadKangaroo: function () {
                console.log("Showing Kangaroo");
                this.showKangaroo = true;
            },
            loadUCT: function () {
                console.log("Showing UCT");
                this.showUCT = true;
            },
            loadSAMO: function () {
                console.log("Showing SAMO");
                this.showSAMO = true;
            },
            loadSAMC: function () {
                console.log("Showing SAMC");
                this.showSAMC = true;
            },
            loadYear2024: function () {
                console.log("Showing Year 2024");
                this.showYear2024 = true;
            },
            loadLivingMathsSouthAfrica: function() {
                console.log("Showing Living Maths South Africa Competition");
                this.showLivingMathsSouthAfrica = true;
            },
            loadLivingMaths: function() {
                console.log("Showing Living Maths Competition");
                this.showLivingMaths = true;
            },
            loadTestsToMark: function () {
                console.log("Showing tests to mark");
                this.showTestsToMark = true;
            },
            loadMyStudents: function () {
                console.log("Showing my students");
                this.showMyStudents = true;
            },
            loadMyStudentsTestEntries: function () {
                console.log("Showing my students test entries");
                this.showMyStudentsTestEntries = true;
            },
            loadMyStudentsWorksheetEntries: function () {
                console.log("Showing my students worksheet entries");
                this.showMyStudentsWorksheetEntries = true;
            },
            loadWorksheetsExtension: function () {
                console.log("Showing worksheets extension");
                this.showWorksheetsExtension = true;
            },
            loadWorksheetsUbuntu: function () {
                console.log("Showing worksheets Ubuntu");
                this.showWorksheetsUbuntu = true;
            },
            loadWorksheetsPastPaper: function () {
                console.log("Showing worksheets past paper");
                this.showWorksheetsPastPaper = true;
            },
            signOutAndGoHome: function() {
                this.$root.$emit('sign-out');
            }
        },
        mounted() {
            this.$root.$on('close-profile', () => {
                if (this.showFriendProfile) {
                    this.showFriendProfile = false;
                } else {
                    this.showProfile = false;
                }
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-leaderboard', () => {
                this.showLeaderboard = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-my-students', () => {
                this.showMyStudents = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('show-friend-profile', (friendProfile) => {
                this.showFriendProfile = true;
                this.friendProfile = friendProfile;
            });
            this.$root.$on('close-my-students-test-entries', () => {
                this.showMyStudentsTestEntries = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-my-students-worksheet-entries', () => {
                this.showMyStudentsWorksheetEntries = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-tests', () => {
                this.showTests = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-competition-preparation', () => {
                this.showCompetitionPreparation = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-past-papers', () => {
                this.showPastPapers = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-talent-search', () => {
                this.showTalentSearch = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-kangaroo', () => {
                this.showKangaroo = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-uct', () => {
                this.showUCT = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-samo', () => {
                this.showSAMO = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-samc', () => {
                this.showSAMC = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-year-2024', () => {
                this.showYear2024 = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-living-maths-south-africa', () => {
                this.showLivingMathsSouthAfrica = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-living-maths', () => {
                this.showLivingMaths = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('open-tests-to-mark', this.loadTestsToMark);
            this.$root.$on('close-tests-to-mark', () => {
                this.showTestsToMark = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-worksheets-extension', () => {
                this.showWorksheetsExtension = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-worksheets-ubuntu', () => {
                this.showWorksheetsUbuntu = false;
                this.showBurgerMenu = false;
            });
            this.$root.$on('close-worksheets-past-paper', () => {
                this.showWorksheetsPastPaper = false;
                this.showBurgerMenu = false;
            });
            this.signedOutFn = () => {
                this.showBurgerMenu = false;
            };
            this.$root.$on('signed-out', this.signedOutFn);
        },
        beforeDestroy() {
            this.$root.$off("close-profile");
            this.$root.$off("close-leaderboard");
            this.$root.$off("close-my-students");
            this.$root.$off("show-friend-profile");
            this.$root.$off("close-my-students-test-entries");
            this.$root.$off("close-my-students-worksheet-entries");
            this.$root.$off("close-tests");
            this.$root.$off("close-competition-preparation");
            this.$root.$off("close-past-papers");
            this.$root.$off("close-talent-search");
            this.$root.$off("close-kangaroo");
            this.$root.$off("close-uct");
            this.$root.$off("close-samo");
            this.$root.$off("close-samc");
            this.$root.$off("close-year-2024");
            this.$root.$off("close-living-maths-south-africa");
            this.$root.$off("close-living-maths");
            this.$root.$off("open-tests-to-mark");
            this.$root.$off("close-tests-to-mark");
            this.$root.$off("close-worksheets-extension");
            this.$root.$off("close-worksheets-ubuntu");
            this.$root.$off("close-worksheets-past-paper");
            this.$root.$off("signed-out", this.signedOutFn);
        },
    }

</script>

<style lang="scss" scoped>
    @import "../styles/layout/chat_window";
    @import "../styles/components/burger_menu";
</style>