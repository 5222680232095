<template>
    <div id="app" v-bind:class="{'app-active' : showApp}">
        <router-view v-bind:cognitoUser="cognitoUser"/>

        <notifications v-if="showNotifications" v-bind:notifications="notifications"
                       v-bind:showSpinner="showNotificationSpinner"></notifications>
    </div>
</template>

<script>
    import dayjs from 'dayjs';

    import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components'
    import {API, graphqlOperation} from "@aws-amplify/api";
    import {Auth} from "@aws-amplify/auth";

    import {store, mutations} from './store.js'

    import user from './domain/user';

    // Our custom queries & mutations
    import {getUserByUsername, getTestsEntries, questionSetsByRegistrationEnd, testsForTeacherToMark} from "./mytutor-graphql/queries";
    import {createAndSetupUser, updateUser, linkUsers, registerEntrantForTest} from "./mytutor-graphql/mutations";
    import {onUpdateUser} from "./mytutor-graphql/subscriptions";

    // Visual components
    import Notifications from './components/Notifications.vue';

    // Mixins
    import {refreshPageMixin} from "./mixins/RefreshPageMixin";
    import {apiMixin} from "./mixins/APIMixin";
    import {i18nMixin} from "./mixins/I18nMixin";

    export default {
        name: 'App',
        components: {Notifications},
        mixins: [refreshPageMixin, apiMixin, i18nMixin],
        data() {
            return {
                cognitoUser: null,
                authState: null,
                unsubscribeAuth: null,
                // errorMessageMap: {},
                showApp: false,
                testsToMark: [],
                notifications: [],
                showNotificationSpinner: false,
                showNotifications: false,
                userReference: null,
                testRegistrationReference: null,
            }
        },
        computed: {
            user: function () {
                return store.user;
            },
        },
        created() {
            // Handle Cognito authentication
            this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
                console.log("State change: " + authState);
                console.log(authData);
                this.authState = authState;
                switch (this.authState) {
                    case AuthState.ConfirmSignUp:
                        // this.cognitoUser = authData;
                        break;
                    case AuthState.SignedIn:
                        // this is here because of bug #7635 / #6330, as this is called twice
                        if (!this.cognitoUser || this.cognitoUser.username !== authData.username) {
                            console.log("State change to AuthState.SignedIn");
                            this.cognitoSignedIn(authData);
                        }
                        break;
                    case AuthState.SignedOut:
                        this.showApp = false;
                        this.$router.push({path: '/'});
                        this.signedOut();
                        this.cognitoUser = null;
                        this.authState = null;
                        this.$nextTick(() => {
                            mutations.setUser(null);
                        });
                        break;
                }
            })
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            fetchUser: async function (username) {
                console.log('fetching user with username: ' + username);
                if (!username) {
                    return null;
                }
                try {
                    const userRet = await API.graphql(graphqlOperation(getUserByUsername, {username: {eq: username.toLowerCase()}}));
                    console.log(userRet);
                    if (userRet.data.userByUsername.items && userRet.data.userByUsername.items.length > 0) {
                        console.log("User found");
                        return userRet.data.userByUsername.items[0];
                    } else {
                        console.log("No user returned - must be signing up");
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching user", error, true)
                    throw error;
                }
            },
            createUser: async function (owner, username, firstName, surname, email) {
                console.log('creating user: ' + username);
                try {
                    const userRet = await API.graphql(graphqlOperation(createAndSetupUser, {
                        owner: owner,
                        username: username,
                        firstName: firstName,
                        surname: surname,
                        email: email
                    }));
                    console.log(userRet);
                    if (userRet.data.createAndSetupUser !== null) {
                        console.log("User created");
                        return userRet.data.createAndSetupUser;
                    } else {
                        console.log("Error creating user");
                        this.logError("Creating user", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Creating user", error, true);
                    return null;
                }
            },
            storeUserIdentityID: async function (id, identityID) {
                console.log('storing user identityId: ' + identityID);
                try {
                    const item = {
                        input: {
                            id: id,
                            cognitoIdentityID: identityID
                        }
                    };
                    const updateUserRet = await API.graphql(graphqlOperation(updateUser, item));
                    console.log(updateUserRet);
                    if (updateUserRet.data.updateUser !== null) {
                        console.log("stored user identityId");
                    } else {
                        console.log("Error storing user identityId");
                        this.logError("Storing user identityId", "No data returned", false);
                    }
                } catch (error) {
                    this.logAPIError("Storing user identityId", error, false);
                }
            },
            linkUsersOnServer: async function (user, linkCode) {
                console.log('linking users: ' + user.getID() + " linkCode: " + linkCode);
                try {
                    const linkRet = await API.graphql(graphqlOperation(linkUsers, {
                        userID: user.getID(),
                        linkCode: linkCode
                    }));
                    console.log(linkRet);
                    if (linkRet.data.linkUsers !== null) {
                        console.log("Linked users");
                        return linkRet.data.linkUsers;
                    } else {
                        console.log("Error linking users");
                        this.logError("Linking users", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Linking users", error, false);
                }
            },
            registerEntrantForTestOnServer: async function (user, linkCode) {
                console.log('registering user for test: ' + user.getID());
                try {
                    const registeringRet = await API.graphql(graphqlOperation(registerEntrantForTest, {
                        userID: user.getID(),
                        linkCode: linkCode
                    }));
                    console.log(registeringRet);
                    if (registeringRet.data.registerEntrantForTest !== null) {
                        console.log("Registered user");
                        const test = registeringRet.data.registerEntrantForTest;
                        return {message: "You have successfully registered for the test: " + test.testName, test: test};
                    } else {
                        console.log("Error registering user");
                        this.logError("Registering user for test", "No data returned", false);
                        return {message: "There was an error registering you for the test"};
                    }
                } catch (error) {
                    this.logAPIError("Registering user for test", error, false);
                    if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                        return {message: "There was an error registering you for the test: " + error.errors[0].message};
                    } else {
                        return {message: "There was an error registering you for the test. Please carefully check the URL you used."};
                    }
                }
            },
            fetchNonInvigilatedTests: async function (user) {
                console.log('fetching non-invigilated tests for user: ' + user.getID());
                try {
                    const fetchTestEntries = API.graphql(graphqlOperation(getTestsEntries, {userID: {eq: user.getID()}}));
                    const fetchFutureEvents = API.graphql(graphqlOperation(questionSetsByRegistrationEnd, {
                        registrationEnd: {gt: new Date().toISOString()},
                        limit: 100
                    }));
                    const results = await Promise.all([fetchTestEntries, fetchFutureEvents]);
                    const testEntriesRet = results[0];
                    const futureEventsRet = results[1];
                    console.log(futureEventsRet);
                    console.log(testEntriesRet);
                    if (futureEventsRet.data.questionSetsByRegistrationEnd !== null && futureEventsRet.data.questionSetsByRegistrationEnd.items) {
                        console.log("Question sets found");
                        let uninvigilatedTests = [];
                        uninvigilatedTests.push(...futureEventsRet.data.questionSetsByRegistrationEnd.items);
                        const now = new Date();
                        // filter those that require invigilation
                        uninvigilatedTests = uninvigilatedTests.filter((questionSet) => {
                            return questionSet.invigilation === "NO_INVIGILATOR"
                        });
                        // filter those that don't have a test
                        uninvigilatedTests = uninvigilatedTests.filter((questionSet) => {
                            return questionSet.tests && questionSet.tests.items && questionSet.tests.items.length > 0
                        });
                        // filter those where the registration start hasn't passed
                        uninvigilatedTests = uninvigilatedTests.filter((questionSet) => {
                            return questionSet.registrationStart === null || new Date(questionSet.registrationStart) < now
                        });
                        // filter those that require prior test
                        uninvigilatedTests = uninvigilatedTests.filter((questionSet) => {
                            return !questionSet.registrationRequiresPriorTest || questionSet.registrationRequiresPriorTest === false
                        });
                        // filter those that are for the wrong grade
                        uninvigilatedTests = uninvigilatedTests.filter((questionSet) => {
                            return questionSet.minGrade <= user.getGrade() && questionSet.maxGrade >= user.getGrade()
                        });
                        // filter those that have already been entered
                        if (testEntriesRet.data.testEntriesByUser !== null && testEntriesRet.data.testEntriesByUser.items.length >= 0) {
                            uninvigilatedTests = uninvigilatedTests.filter((ut) => {
                                console.log("User test entries found");
                                let entries = [];
                                entries.push(...testEntriesRet.data.testEntriesByUser.items);
                                for (const entry of entries) {
                                    if (entry.test.id === ut.tests.items[0].id) {
                                        return false;
                                    }
                                }
                                return true;
                            });
                        }
                        console.log(uninvigilatedTests);
                        return uninvigilatedTests;
                    } else {
                        console.log("Error fetching non-invigilated tests for user");
                        this.logError("Fetching non-invigilated tests for user", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    console.log(error);
                    this.logAPIError("Fetching non-invigilated tests for user", error, false);
                    return null;
                }
            },
            fetchTestsUserMarks: async function (user) {
                console.log('checking for tests for which this user is a marker: ' + user.getID());
                try {
                    const testsToMarkRet = await API.graphql(graphqlOperation(testsForTeacherToMark, {userID: {eq: user.getID()}}));
                    if (testsToMarkRet.data.testsForTeacherToMark !== null && testsToMarkRet.data.testsForTeacherToMark.items) {
                        console.log('Found tests to mark');
                        let testsToMark = [];
                        for (let testMarker of testsToMarkRet.data.testsForTeacherToMark.items) {
                            testsToMark.push(testMarker.test);
                        }
                        console.log('Returning ' + testsToMark.length + ' tests to mark');
                        return testsToMark;
                    } else {
                        console.log("Error fetching tests that user marks");
                        this.logError("Fetching tests that user marks", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching tests that user marks", error, false);
                    return null;
                }
            },
            // ----------------------------------------------------------------------------
            //
            // Account behaviour
            //
            // ----------------------------------------------------------------------------
            federateFacebook: function () {
                Auth.federatedSignIn({provider: 'Facebook'});
            },
            cognitoSignedIn: async function(cognitoUser) {
                this.cognitoUser = cognitoUser;
                await this.signedIn();
                this.$nextTick(() => {
                    this.showApp = true;
                    window.scrollTo(0, 1);
                });
            },
            signedIn: async function () {
                console.log('signed in');
                let userFromServer = null;
                try {
                    userFromServer = await this.fetchUser(this.cognitoUser.username);
                    // set the locale
                    this.changeLocaleForUser(userFromServer);
                    // check if the user has completed sign up
                    if (userFromServer !== null) {
                        if (userFromServer.state === 'registering') {
                            console.debug("User is part way through registering, diverting to that flow");
                            mutations.setUser(user(userFromServer));
                            this.$router.push({ path: '/register', query: { step: 'personalisation' }});
                            return;
                        }
                    } else {
                        // and if we have no user send him/her to registration
                        this.$router.push({ path: '/register'});
                        return;
                        // userFromServer = await this.createUser(this.cognitoUser.username, this.cognitoUser.username, this.cognitoUser.attributes.given_name, this.cognitoUser.attributes.family_name, this.cognitoUser.attributes.email);
                    }
                } catch (error) {
                    this.signOut();
                    return;
                }
                mutations.setUser(user(userFromServer));

                // if the user has a proper account
                if (this.user && this.user.doesUserHaveAccount()) {
                    //  and is registering, listen to changes to the user
                    if (this.user.isRegistering()) {
                        console.log("Registering onUpdateUser, owner: " + this.user.getOwner());
                        API.graphql(graphqlOperation(onUpdateUser, {owner: this.user.getOwner()})).subscribe({
                            next: async ({provider, value}) => {
                                console.log("User updated");
                                console.log({provider, value});
                                if (this.user.getNickname() !== value.data.onUpdateUser.nickname) {
                                    this.user.setNickname(value.data.onUpdateUser.nickname);
                                }
                                if (this.user.getType() !== value.data.onUpdateUser.type) {
                                    this.user.setType(value.data.onUpdateUser.type);
                                }
                                if (this.user.getGrade() !== value.data.onUpdateUser.grade) {
                                    this.user.setGrade(value.data.onUpdateUser.grade);
                                }
                                if (this.user.getCountry() !== value.data.onUpdateUser.country) {
                                    this.user.setCountry(value.data.onUpdateUser.country);
                                }
                                if (this.user.getELOScore() !== value.data.onUpdateUser.metrics.items[0].eloScore) {
                                    this.user.setELOScore(value.data.onUpdateUser.metrics.items[0].eloScore);
                                }
                                // right, this signals the end of registration
                                if (value.data.onUpdateUser.state === "answering") {
                                    this.user.setState(value.data.onUpdateUser.state);
                                    if (this.user.isStudent()) {
                                        await this.showNotificationOfFutureEvents();
                                    }
                                }
                            },
                            error: (error) => {
                                console.warn(error)
                            }
                        });
                    } else {
                        // else if not registering, check for notifications of future events
                        console.log("Checking for future events");
                        await this.showNotificationOfFutureEvents();
                    }
                }

                // handle linking
                if (this.user !== null) {

                    // link to a user
                    if (this.userReference !== null) {
                        if (this.userReference === this.user.getLinkCode()) {
                            this.notifications.push({message: "You cannot link to yourself"});
                        } else {
                            const linked = await this.linkUsersOnServer(this.user, this.userReference);
                            if (linked) {
                                // remove the query params if there are any, to prevent App from registering again
                                let query = Object.assign({}, this.$route.query);
                                if (query.tr) {
                                    delete query.tr;
                                    await this.$router.replace({query});
                                }
                                this.notifications.push({message: "You have been linked to " + linked.firstName + " " + linked.surname});
                            } else {
                                this.notifications.push({message: "We could not find the link code: " + this.userReference});
                            }
                        }
                        this.showNotifications = true;
                    }

                    // register for a test
                    if (this.testRegistrationReference !== null) {
                        await this.registerForTest(this.testRegistrationReference);
                    }
                } else {
                    console.log("Could not load or create user");
                }

                // ensure that we have the IdentityId stored
                if (!this.user.hasCognitoIdentityID()) {
                    Auth.currentUserCredentials().then(async (credentials) => {
                        await this.storeUserIdentityID(this.user.getID(), credentials.identityId);
                        this.user.setCognitoIdentityID(credentials.identityId);
                    });
                }

                // if the user has a proper account, check if this user is a marker
                if (this.user.doesUserHaveAccount()) {
                    this.testsToMark = await this.fetchTestsUserMarks(this.user);
                }

                this.$root.$emit('signed-in');

                // if the user has a proper account, then go to chat
                if (this.user.doesUserHaveAccount()) {
                    this.$router.push(this.$route.query.redirect || '/chat');
                }
            },
            signedOut: async function () {
                console.log('signed out');
                this.$root.$emit('signed-out');
            },
            signOut: async function() {
                await Auth.signOut();
                this.$router.push({path: '/'});
            },
            checkForUserReference: function () {
                var searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has("tr")) {
                    this.userReference = searchParams.get("tr");
                    console.log("user reference: " + this.userReference);
                }
            },
            checkForTestRegistrationReference: function () {
                var searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has("rft")) {
                    this.testRegistrationReference = searchParams.get("rft");
                    console.log("test registration reference: " + this.testRegistrationReference);
                }
            },
            registerForTest: async function (testRegistrationReference) {
                this.$nextTick(() => {
                    this.notifications.splice(0, this.notifications.length);
                    this.notifications.push({message: "Registering you for the test"});
                    this.showNotificationSpinner = true;
                    this.showNotifications = true;
                });

                const messageAndTest = await this.registerEntrantForTestOnServer(this.user, testRegistrationReference);

                // update the background lists of tests (if menu open)
                this.$root.$emit('refresh-tests');

                // remove the query params if there are any, to prevent App from registering again
                let query = Object.assign({}, this.$route.query);
                if (query.rft) {
                    delete query.rft;
                    await this.$router.replace({query});
                }

                this.testRegistrationReference = null;
                this.notifications.splice(0, this.notifications.length);
                this.notifications.push({message: messageAndTest.message});

                if (messageAndTest.test && messageAndTest.test.participationStart && messageAndTest.test.testHostID) {
                    if (dayjs().isAfter(dayjs(messageAndTest.test.participationStart))) {
                        this.notifications[0].link = {
                            text: "Go to test",
                            callback: () => {
                                this.showNotifications = false;
                                let newPath = '/test/' + messageAndTest.test.testHostID + "/" + messageAndTest.test.entry.id;
                                this.$router.push({path: newPath});
                            }
                        };
                        this.showNotifications = true;
                    }
                }
                this.showNotificationSpinner = false;
            },
            showNotificationOfFutureEvents: async function () {
                this.notifications.splice(0, this.notifications.length);
                // if (this.user && !this.user.isTeacher() && this.user.isSouthAfrican()) {
                //     const futureEvents = await this.fetchNonInvigilatedTests(this.user);
                //     console.log("Fetched non invigilated tests");
                //     if (futureEvents.length > 0) {
                //         for (let futureEvent of futureEvents) {
                //             // if (futureEvent.name.startsWith("SAMF") || futureEvent.name.startsWith("PnP School Club")) {
                //             if (futureEvent.name.startsWith("PnP School Club")) {
                //                 this.notifications.push({
                //                     message: "Would you like to enter the upcoming event: " + futureEvent.name,
                //                     link: {
                //                         text: "Enter now",
                //                         callback: () => {
                //                             this.showNotifications = false;
                //                             this.registerForTest(futureEvent.tests.items[0].testHosts.items[0].linkCode);
                //                         }
                //                     }
                //                 });
                //             }
                //         }
                //         if (this.notifications.length > 0) {
                //             this.showNotifications = true;
                //         }
                //     }
                // }
                
                // if (this.user &&
                //     !this.user.isTeacher() &&
                //     (this.user.isSouthAfrican() ||
                //         this.user.isGhanaian() ||
                //         this.user.isRwandan() ||
                //         this.user.isKenyan() ||
                //         this.user.isIrish() ||
                //         this.user.isBotswanan() ||
                //         this.user.isNigerian() ||
                //         this.user.isNamibian() ||
                //         this.user.isMauritian() ||
                //         this.user.isMalawian() ||
                //         this.user.isSouthSudanian() ||
                //         this.user.isTanzanian() ||
                //         this.user.isUgandan() ||
                //         this.user.isZimbabwian() ||
                //         this.user.isZambian() ||
                //         this.user.isEswatinian() ||
                //         this.user.isLesothoan())) {
                //     const futureEvents = await this.fetchNonInvigilatedTests(this.user);
                //     console.log("Fetched non invigilated tests");
                //     console.log(futureEvents);
                //     if (futureEvents.length > 0) {
                //         for (let futureEvent of futureEvents) {
                //             if (futureEvent.name.startsWith("Kangaroo " + this.user.getCountry())) {
                //                 this.notifications.push({
                //                     message: "Would you like to enter the upcoming event: " + futureEvent.name,
                //                     link: {
                //                         text: "Enter now",
                //                         callback: () => {
                //                             this.registerForTest(futureEvent.tests.items[0].testHosts.items[0].linkCode);
                //                         }
                //                     }
                //                 });
                //             }
                //         }
                //         if (this.notifications.length > 0) {
                //             this.showNotifications = true;
                //         }
                //     }
                // }
            },
            // ----------------------------------------------------------------------------
            //
            // App versioning behaviour
            //
            // ----------------------------------------------------------------------------
            setupVersionChecking: function () {
                // Register listener for new version of app
                this.initVersionCheck();

                // On every route change, check for a new version
                this.$router.beforeEach((to, from, next) => {
                    this.checkVersionTimer();
                    next();
                });

            },
            checkVersionTimer: function () {
                this.versioningTimer.runTimersCheck();
            },
            showNewVersionAvailableMessage: function () {
                this.notifications.splice(0, this.notifications.length);
                this.notifications.push({
                    message: "A new version of MyTutor is available. Please download the new version by pressing refresh.",
                    link: {
                        text: "Refresh",
                        callback: () => {
                            this.showNotifications = false;
                            this.reloadApp();
                        }
                    }
                });
                this.showNotifications = true;
            }
        },
        mounted() {
            this.setupVersionChecking();

            this.checkForUserReference();
            this.checkForTestRegistrationReference();

            // Register event listeners
            this.$root.$on('manual-sign-in', this.cognitoSignedIn);
            this.$root.$on('sign-out', this.signOut);
            this.$root.$on('check-version-timer', this.checkVersionTimer);
            this.$root.$on('new-version-available', this.showNewVersionAvailableMessage);
            this.$root.$on('register-for-test', this.registerForTest);

            this.openAppFn = () => {
                this.showApp = true;
            };
            this.$root.$on('open-app', this.openAppFn);

            this.closeNotificationsFn = () => {
                this.notifications.splice(0, this.notifications.length);
                this.showNotifications = false;
            };
            this.$root.$on('close-notifications', this.closeNotificationsFn);
        },
        beforeDestroy() {
            this.unsubscribeAuth();

            this.$root.$off("manual-sign-in", this.cognitoSignedIn);
            this.$root.$off("sign-out", this.signOut);
            this.$root.$off("check-version-timer", this.checkVersionTimer);
            this.$root.$off("new-version-available", this.showNewVersionAvailableMessage);
            this.$root.$off("open-app", this.openAppFn);
            this.$root.$off("register-for-test", this.registerForTest);
            this.$root.$off("close-notifications", this.closeNotificationsFn);
        }
    }
</script>

<style lang="scss">
    @import "./styles/global";
</style>

<style>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html {
        height: 100%;
        min-height: 100%;
    }

    body {
        margin: 0;
        height: 100%;
        min-height: 100%;
        font-size: 14px;
        font-size: 14pt;
        font-family: 'Inter', sans-serif;
        background: #1C1A4F;
        background-image: url("./assets/background-pattern.svg");
        background-image: url("./assets/background-pattern.svg"), linear-gradient(to top, rgba(28, 26, 79, 1), 60%, rgba(28, 26, 79, 0.60)), linear-gradient(to top, rgba(28, 26, 79, 1), 75%, rgba(62, 72, 190, 1));
        background-repeat: repeat, no-repeat, no-repeat;
        background-size: 400px 400px, cover, cover;
        background-attachment: fixed;
        overflow: auto;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        /* height: 100%; */
        /* min-height: 100%; */
        overflow: auto;
    }

    .app-active {
        height: 100%;
        min-height: 100%;
    }

    .hosted-ui {
        overflow: auto;
    }

    /* Amplify Hosted UI customizations */
    :root {
        --amplify-primary-color: #F9AC1B;
        --amplify-primary-tint: #FCD174;
        --amplify-primary-shade: #5469F3;
        --amplify-font-family: 'Inter', sans-serif;
    }

    amplify-authenticator {
        --container-height: 100%;
    }

    .hosted-ui-logo {
        margin: 8px 0 20px 0;
        height: 40px;
    }

    .modal {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        z-index: 200;
        overflow: scroll;
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-content {
        position: relative;
        width: 100%;
        max-width: 500px;
        margin: auto;
        box-shadow: 0 0 0 100px rgb(0 0 0 / 70%);
        box-shadow: 0 0 0 100vmax rgb(0 0 0 / 70%);
        background-color: #5469F3;
        border-radius: 10px;
        color: white;
        padding: 100px 85px;
        z-index: 300;
        text-align: center;
        display: flex;
        flex-direction: row;
    }

    .modal-content div {
        display: inline-block;
        vertical-align: middle;
    }

    .modal-content__text {
        font: normal normal 500 26px/32px Inter;
        flex: 1;
    }

    .modal-content__close {
        position: absolute;
        top: 22px;
        right: 23px;
        color: #1C1A4F;
    }

    .modal-content__close:hover {
        cursor: pointer;
    }

    .modal-content__close img {
        width: 23px;
        height: 23px;
    }

    @media screen and (max-width: 414px) {
        .modal-content {
            padding: 60px 20px 30px 20px;
        }

        .modal-content__text {
            font: normal normal 500 20px/24px Inter;
        }

        .btn {
            font: normal normal normal 18px/20px Inter;
            font-weight: bold;
            padding: 0 20px;
            margin: 20px 10px 40px 10px;
        }
    }
</style>