export const createAndSetupUser = /* GraphQL */ `
  mutation CreateAndSetupUser($owner: String!, $username: String!, $firstName: String!, $surname: String!, $email: AWSEmail) {
    createAndSetupUser(
      owner: $owner
      username: $username
      firstName: $firstName
      surname: $surname
      email: $email
    ) {
      id
      email
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      gender
      race
      school
      country
      owner
      cognitoIdentityID
      linkCode
      metrics {
        items {
          id
          eloScore
        }
      }
    }
  }
`;

export const personaliseUser = /* GraphQL */ `
  mutation PersonaliseUser(
    $userID: ID!
    $language: Language!
    $type: StudentType!
    $country: String!
    $teacherEmail: String
    $school: String
    $grade: Int
    $mathsMark: Int
    $nickname: String
    $gender: Gender
    $race: Race
  ) {
    personaliseUser(
      userID: $userID
      language: $language
      type: $type
      country: $country
      teacherEmail: $teacherEmail
      school: $school
      grade: $grade
      mathsMark: $mathsMark
      nickname: $nickname
      gender: $gender
      race: $race
    ) {
      id
      email
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      gender
      race
      school
      country
      owner
      cognitoIdentityID
      linkCode
      metrics {
        items {
          id
          eloScore
        }
      }
    }
  }
`;

export const updateUserMarketingSubscription = /* GraphQL */ `
  mutation UpdateUserMarketingSubscription($userID: ID!, $subscribed: Boolean!) {
    updateUserMarketingSubscription(userID: $userID, subscribed: $subscribed)
  }
`;

export const migrateCognitoUser = /* GraphQL */ `
  mutation MigrateCognitoUser($username: String!, $password: String!) {
    migrateCognitoUser(username: $username, password: $password)
  }
`;

export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username)
  }
`;

export const linkUsers = /* GraphQL */ `
  mutation LinkUsers($userID: ID!, $linkCode: String!) {
    linkUsers(userID: $userID, linkCode: $linkCode) {
      id
      email
      firstName
      surname
    }
  }
`;

export const unlinkUsers = /* GraphQL */ `
  mutation UnlinkUsers($userID: ID!, $friendIDs: [String!]!) {
    unlinkUsers(userID: $userID, friendIDs: $friendIDs)
  }
`;

export const registerEntrantForTest = /* GraphQL */ `
  mutation registerEntrantForTest($userID: ID!, $linkCode: String!) {
    registerEntrantForTest(userID: $userID, linkCode: $linkCode) {
      testHostID
      testName
      testDate
      participationStart
      organisation {
        name
        logo
      }
      entry {
        id
        score
        scoreAndTime
        questionsAnswered
        timeTaken
        email
        firstName
        surname
        nickname
        entrySections {
          id
          testSectionID
          sectionScore
          sectionScoreAndTime
          totalScore
          questionsAnswered
          timeTaken
        }
        createdAt
      }
    }
  }
`;

export const registerForTestWithoutAccount = /* GraphQL */ `
  mutation RegisterForTestWithoutAccount(
    $firstName: String!
    $surname: String!
    $country: String!
    $school: String!
    $grade: Int!
    $showOnLeaderboard: Boolean
    $notificationEmail: AWSEmail
    $testHostLinkCode: String!
    $teacherLinkCode: String
    $affiliate: String
  ) {
    registerForTestWithoutAccount(
      firstName: $firstName
      surname: $surname
      country: $country
      school: $school
      grade: $grade
      showOnLeaderboard: $showOnLeaderboard
      notificationEmail: $notificationEmail
      testHostLinkCode: $testHostLinkCode
      teacherLinkCode: $teacherLinkCode
      affiliate: $affiliate
    ) {
      email
      password
      testHostID
      testEntryID
    }
  }
`;

export const openTestEntryForUserWithoutAccount = /* GraphQL */ `
  mutation OpenTestEntryForUserWithoutAccount($testEntryID: ID) {
    openTestEntryForUserWithoutAccount(testEntryID: $testEntryID) {
      testHostID
      testEntryID
      email
      accountType
      password
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      surname
      nickname
      grade
    }
  }
`;

export const updateStudentGrade = /* GraphQL */ `
  mutation UpdateStudentGrade($userID: ID!, $grade: Int) {
    updateStudentGrade(userID: $userID, grade: $grade) {
      id
      email
      grade
      metrics {
        items {
          id
          grade
        }
      }
    }
  }
`;

export const startSectionAt = /* GraphQL */ `
  mutation StartSectionAt($teacherID: ID!, $testSectionID: ID!, $startAt: AWSDateTime!) {
    startSectionAt(teacherID: $teacherID, testSectionID: $testSectionID, startAt: $startAt) {
      id
      startedAt
    }
  }
`;

export const endSection = /* GraphQL */ `
  mutation endSection($teacherID: ID!, $testSectionID: ID!) {
    endSection(teacherID: $teacherID, testSectionID: $testSectionID) {
      id
      startedAt
      endedAt
    }
  }
`;

export const entrantEndsTest = /* GraphQL */ `
  mutation EntrantEndsTest($testEntrySectionID: ID!, $markerSubmittingTest: Boolean) {
    entrantEndsTest(testEntrySectionID: $testEntrySectionID, markerSubmittingTest: $markerSubmittingTest) {
      score
      message
    }
  }
`;

/*
 * This is intentionally longer than it needs to be at face value, because
 * adaptive tests will create and return a new TestAnswer in the process of saving.
 *             // testQuestion {
            //     number
            //     points
            //     question {
            //         questionURLs
            //         answerRightURLs
            //         answerWrongURLs
            //         answerOptions
            //         placeholderText
            //         answerValue
            //         clueURL
            //         layout
            //     }
            // }
            // answer
            // answerPhotoS3Key
            // answeredDate
            // correct
            // score
            // markersComments
 */
export const saveTestAnswer = /* GraphQL */ `
    mutation SaveTestAnswer($testAnswerID: ID!, $answer: String, $answerPhotoS3Key: String) {
        saveTestAnswer(testAnswerID: $testAnswerID, answer: $answer, answerPhotoS3Key: $answerPhotoS3Key) {
            id
            number
            points
            questionURLs
            answerRightURLs
            answerWrongURLs
            answerOptions
            placeholderText
            answerValue
            clueURL
            layout          
            answer
            answerPhotoS3Key
            answerDate
            correct
            score
            markersComments
        }
    }
`;

export const skipQuestionAdaptiveTest = /* GraphQL */ `
    mutation SkipQuestionAdaptiveTest($testEntrySectionID: ID!) {
        skipQuestionAdaptiveTest(testEntrySectionID: $testEntrySectionID) {
            id
            number
            points
            questionURLs
            answerRightURLs
            answerWrongURLs
            answerOptions
            placeholderText
            answerValue
            clueURL
            layout          
            answer
            answerPhotoS3Key
            answerDate
            correct
            score
            markersComments
        }
    }
`;

export const updateTestDate = /* GraphQL */ `
    mutation UpdateTestDate($testHostID: ID!, $testID: ID!, $date: AWSDateTime!) {
        updateTestDate(testHostID: $testHostID, testID: $testID, date: $date)
}
`;

export const createTestAndHostFromQuestionSet = /* GraphQL */ `
  mutation CreateTestAndHostFromQuestionSet(
    $questionSetID: ID!
    $name: String!
    $date: AWSDateTime!
    $teacherID: ID!
  ) {
    createTestAndHostFromQuestionSet(
      questionSetID: $questionSetID
      name: $name
      date: $date
      teacherID: $teacherID
    ) {
      id
      linkCode
      testDate
      createdAt
      test {
        id
        name
        minGrade
        maxGrade
        date
        totalRegistered
        organisation {
          name
          logo
        }
      }
    }
  }
`;

export const approveTestScheduler = /* GraphQL */ `
  mutation ApproveTestScheduler(
    $authorisedByID: ID!
    $newSchedulerEmail: AWSEmail!
    $questionSetIDs: [ID!]!
  ) {
    approveTestScheduler(
      authorisedByID: $authorisedByID
      newSchedulerEmail: $newSchedulerEmail
      questionSetIDs: $questionSetIDs
    ) {
      id
      questionSetID
      userID
      authorisedByID
      studentEmail
    }
  }
`;

export const markTestAnswer = /* GraphQL */ `
  mutation MarkTestAnswer($testAnswerID: ID!, $correct: Boolean, $score: Float, $markersComments: String) {
    markTestAnswer(testAnswerID: $testAnswerID, correct: $correct, score: $score, markersComments: $markersComments) {
      id
    }
  }
`;

export const answerTodaysPuzzle = /* GraphQL */ `
  mutation AnswerTodaysPuzzle(
    $questionID: ID!
    $answer: String!
    $referrer: String
  ) {
    answerTodaysPuzzle(
      questionID: $questionID
      answer: $answer
      referrer: $referrer
    ) {
      id
      questionID
      correct
    }
  }
`;

export const createFileDownloaded = /* GraphQL */ `
  mutation CreateFileDownloaded(
    $input: CreateFileDownloadedInput!
    $condition: ModelFileDownloadedConditionInput
  ) {
    createFileDownloaded(input: $input, condition: $condition) {
      id
      userID
      fileURL
      createdAt
      updatedAt
    }
  }
`;

export const startWorksheet = /* GraphQL */ `
  mutation StartWorksheet($userID: ID!, $worksheetID: ID!) {
    startWorksheet(userID: $userID, worksheetID: $worksheetID) {
        id
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        worksheet {
            id
            name
            tag
            instructions
            createdAt
        }
        answers {
            id
            number
            answer
            answeredAt
            correct
            createdAt
            questionURLs
            answerRightURLs
            answerWrongURLs
            answerOptions
            placeholderText
            answerValue
            clueURL
        }
    }
  }
`;

export const answerWorksheetQuestion = /* GraphQL */ `
  mutation AnswerWorksheetQuestion($worksheetAnswerID: ID!, $answer: String, $timezoneOffsetHours: Int!) {
    answerWorksheetQuestion(
      worksheetAnswerID: $worksheetAnswerID
      answer: $answer
      timezoneOffsetHours: $timezoneOffsetHours
    ) {
        id
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        worksheet {
            id
            name
            tag
            instructions
            createdAt
        }
        answers {
            id
            number
            answer
            answeredAt
            correct
            createdAt
            questionURLs
            answerRightURLs
            answerWrongURLs
            answerOptions
            placeholderText
            answerValue
            clueURL
        }
    }
  }
`;
