import { API, graphqlOperation } from "@aws-amplify/api";

import { getTestEntry } from "../mytutor-graphql/queries";
//, , getPracticeQuestions, getTestForTeacher, getSectionLeaderboard, getOverallLeaderboard

let testEntry = async function(testEntryID, test) {
    let that = {};

    that.startedAt = null;
    that.questionsSkipped = 0;

    if (test.questionSet.adaptiveTestConfiguration) {
        that.maxSkips = test.questionSet.adaptiveTestConfiguration.maxSkips;
    } else {
        that.maxSkips = 3;
    }

    // ============================================================================
    // 
    // API calls
    //
    // ============================================================================

    let fetchTestEntryFromServer = async function (testEntryID) {
        console.log("Fetching test entry with ID: " + testEntryID);
        try {
            const entryRet = await API.graphql(graphqlOperation(getTestEntry, { id: testEntryID }));
            console.log(entryRet);
            if (entryRet.data.getTestEntry !== null) {
                that = Object.assign(that, entryRet.data.getTestEntry);
                that.entrySections = that.entrySections.items;
            } else {
                logError("Finding TestEntry", "No data returned", true);     
                throw new Error("Error finding TestEntry");
            }
        } catch (error) {
            logAPIError("Finding TestEntry", error, true);       
            throw new Error("API Error finding TestEntry");      
        }
    }

    let logAPIError = function(context, error, fatal) {
        if (error.errors && error.errors[0] && error.errors[0].message) {
            logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
        } else {
            logError(context, JSON.stringify(error, null, 2), fatal);
        }
    }

    let logError = function(context, errorMessage, fatal) {
        console.log("Logging an error: " + errorMessage);
        var dataObject = {
            'event': 'exception',
            'errorMessage': {
                'category': 'API',
                'description': context + ': ' + errorMessage,
                'fatal': fatal
            }
        };
        if(typeof window.dataLayer != 'undefined'){
            console.log("Sending the error to the dataLayer");
            window.dataLayer.push(dataObject);
        }
    }

    /*
     * Basic properties. These are left exposed so that Vue reactivity works. 
     * 
     * NB: All Getters and Setters must use these values for reactivity to work
     * 
     */

    
    /*
     * Getters and Setters with behaviour, or properties we want to have encapsulation on. If the method isn't using 
     * a basic property above, the property value will be on the data object passed into the constructor.
     */

    let canSkipQuestion = function() {
        return that.questionsSkipped < that.maxSkips;
    };
    that.canSkipQuestion = canSkipQuestion;

    let getSkipsRemaining = function() {
        return that.maxSkips - that.questionsSkipped;
    }
    that.getSkipsRemaining = getSkipsRemaining;

    let incrementSkipCounter = function() {
        return that.questionsSkipped++;
    };
    that.incrementSkipCounter = incrementSkipCounter;

    let findTestEntrySectionID = function(testSectionID) {
        console.log("Finding TestEntrySection with testSectionID: " + testSectionID);
        if (that.entrySections && that.entrySections.length > 0) {
            for (let entrySection of that.entrySections) {
                if (entrySection.testSectionID === testSectionID) {
                    console.log("Found TestEntrySection with ID: " + entrySection.id + " that matches TestSection with ID: " + testSectionID);
                    return entrySection.id;
                }
            }
        }
        console.log("TestEntrySection not found");
        return null;
    };
    that.findTestEntrySectionID = findTestEntrySectionID;

    //  help a test figure out what the TestEntrySectionID is
    test.setTestEntrySectionIDResolver(that.findTestEntrySectionID);

    try {
        await fetchTestEntryFromServer(testEntryID);
    } catch (error) {
        console.log(error);
        return null;
    }

    return that;
}

export default testEntry;