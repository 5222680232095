<template>
    <div class="form-entry">
        <h1>{{ $t('eventEntryExternalRegisteringMsg')}}</h1>
        <div class="progress" v-bind:class="{'progress--error' : showError}">
            <progress v-bind:value="progress" max="100"/>
            <div class="progress__message">
                {{progressMessage}}
                <template v-if="showError"><br/><br/>Please email info@mytutor.chat for assistance.</template>
            </div>
        </div>
        <spinner v-if="!showError" height="50px" colour="#FFF"/>
    </div>
</template>

<script>
    import {Auth} from "@aws-amplify/auth";

    // Visual components
    import Spinner from '../components/Spinner';

    // Mixins
    import {eventEntryMixin} from "../mixins/EventEntryMixin";

    export default {
        components: {Spinner},
        mixins: [eventEntryMixin],
        data() {
            return {
                progress: 0,
                progressMessage: "",
                registration: null,
                showError: false
            }
        },
        computed: {},
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Account behaviour
            //
            // ----------------------------------------------------------------------------
            register: async function () {
                console.debug("validating form");
                // if (!this.validateForm()) {
                //     return;
                // }

                // introduce a slight delay, will be better UX, because this is the long part
                const self = this;
                setTimeout(() => {
                    self.progress = 40;
                    self.progressMessage = this.$t('eventEntryExternalProgressRegisteringMsg');
                }, 1500);

                try {
                    console.debug("create user and register for Test");
                    this.registration = await this.createUserAndRegisterForTest(
                        this.firstName,
                        this.surname,
                        this.country,
                        this.school,
                        this.grade,
                        this.showOnLeaderboard,
                        this.notificationEmail,
                        this.testHostLinkCode,
                        this.teacherLinkCode,
                        this.affiliate);

                    if (this.registration) {
                        this.progress = 60;
                        this.progressMessage = this.$t('eventEntryExternalProgressSuccessMsg');

                        // manually sign in
                        console.debug("Auto login");
                        try {
                            const user = await Auth.signIn(this.registration.email, this.registration.password);
                            console.debug("Signed in with Auth");
                            // tell App that we've signed in
                            this.$root.$emit('manual-sign-in', user);
                        } catch (error) {
                            console.debug('Error signing in', error);
                        }

                        this.progress = 60;
                        this.progressMessage = this.$t('eventEntryExternalProgressHousekeepingMsg');

                        // wait till App has handled the sign-in
                        this.$root.$on('signed-in', this.openTest);
                    } else {
                        this.showError = true;
                        this.progressMessage = this.$t('eventEntryExternalProgressErrorMsg');
                    }
                } catch (error) {
                    this.showError = true;
                    this.progressMessage = this.$t('openTestEntryProgressErrorPrefixMsg') + error.message;
                }
            },
            openTest: function() {
                this.progress = 100;
                this.progressMessage = this.$t('eventEntryExternalProgressOpeningMsg');

                setTimeout(() => {
                    console.debug("Display link to go to Test");
                    this.$router.push({path: "/test/" + this.registration.testHostID + "/" + this.registration.testEntryID});
                }, 500);
            }
        },
        mounted: async function () {
            this.progress = 20;
            this.progressMessage = this.$t('eventEntryExternalProgressLoadingParametersMsg');
            console.debug(window.location.search);

            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("tr")) {
                this.teacherLinkCode = searchParams.get("tr");
                console.debug("teacher link code: " + this.teacherLinkCode);
            }
            if (searchParams.has("rft")) {
                this.testHostLinkCode = searchParams.get("rft");
                console.debug("test host link code: " + this.testHostLinkCode);
            }
            if (searchParams.has("aff")) {
                this.affiliate = searchParams.get("aff");
                console.debug("affiliate: " + this.affiliate);
            }
            if (searchParams.has("fn")) {
                this.firstName = searchParams.get("fn");
                console.debug("first name: " + this.firstName);
            }
            if (searchParams.has("ln")) {
                this.surname = searchParams.get("ln");
                console.debug("last name: " + this.surname);
            }
            if (searchParams.has("ctry")) {
                this.country = searchParams.get("ctry");
                console.debug("country: " + this.country);
            }
            if (searchParams.has("sch")) {
                this.school = searchParams.get("sch");
                console.debug("school: " + this.school);
            }
            if (searchParams.has("grd")) {
                this.grade = parseInt(searchParams.get("grd"));
                console.debug("grade: " + this.grade);
            }
            if (searchParams.has("fte")) {
                this.showOnLeaderboard = searchParams.get("fte") === 'true';
                console.debug("leaderboard: " + this.showOnLeaderboard);
            }
            if (searchParams.has("eml")) {
                this.notificationEmail = searchParams.get("eml");
                console.debug("notification email: " + this.notificationEmail);
            }

            let gradeInt = parseInt(this.grade);
            if (this.affiliate &&
                this.firstName &&
                this.surname &&
                this.country &&
                this.school &&
                isFinite(gradeInt) &&
                this.testHostLinkCode) {
                this.register();
            } else {
                this.showError = true;
                this.progressMessage = this.$t('eventEntryExternalProgressErrorMissingParametersMsg');
            }
        },
        beforeDestroy() {
            this.$root.$off('signed-in', this.openTest);
        },
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
</style>