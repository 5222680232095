<template>
    <transition name="fade">
        <div ref="quizOverview" class="test-overview"
             v-bind:class="{'test-overview--no-rounds' : isShowAllQuestionsInOverview, 'test-overview--behind' : !showingOverview}">
            <ul ref="quizOverviewList" v-show="showingOverview">
                <li v-for="(question, index) in questions"
                    v-bind:key="index"
                    v-bind:class="{'test-overview__question--answered' : question.answer !== null, 'test-overview__question--scroll-visible' : questionNumberInTopHalf === question.number}"
                    v-on:click="jumpToQuestion(index)"
                >{{question.number}}
                </li>
                <li v-if="isShowLeaderboardForSelectedRound" class="test-overview__question--icon"><img
                        src="../assets/leaderboard.svg" alt="$t('commonLeaderboardTitleMsg')" class="test-rounds-overview__icon"/></li>
            </ul>
        </div>
    </transition>
</template>

<script>

    export default {
        name: 'TestQuestionOverviewPills',
        mixins: [],
        components: {
        },
        created() {
        },
        props: {
            questions: Array,
            showingOverview: Boolean,
            questionNumberInTopHalf: Number,
            isShowAllQuestionsInOverview: Boolean,
            isShowLeaderboardForSelectedRound: Boolean
        },
        data() {
            return {
            }
        },
        computed: {},
        methods: {
            jumpToQuestion: function(index) {
                this.$root.$emit('jump-to-question', index);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/pages/test";
</style>