<template>

    <div class="quiz-question" v-if="question">
        <div class="quiz-question-wrapper">
            <chat-line v-bind:chatLine="question" v-bind:questionInQuiz="true" v-bind:questionInQuizStandalone="true"
                       v-bind:questionAnswered="answered" class="quiz-question__question"/>
        </div>

        <div class="quiz-question__spacer" v-bind:class="{'quiz-question__spacer--answered' : answered}"></div>

        <ul v-if="isMultipleChoiceAnswer" class="quiz-question__options"
            v-bind:class="{'quiz-question__options--answered' : answered, 'quiz-question__options--disabled' : answered}">
            <li v-for="(answerOption, index) in answerOptionsAsArray" v-bind:key="index">
                <div class="quiz-question__option" v-on:click="changeAnswer(answerOption)">
                    <div v-bind:class="{'quiz-question__option--selected': answer === answerOption, 'quiz-question__option--correct': answer === answerOption && question.correct === true, 'quiz-question__option--incorrect': answer === answerOption && question.correct === false}">
                        <span v-if="answer === answerOption && question.correct === true"><img src="../assets/tick.svg"
                                                                                               alt="Correct" class=""/></span>
                        <span v-if="answer === answerOption && question.correct === false"><img
                                src="../assets/cross.svg" alt="$t('quizQuestionIncorrectMsg')" class=""/></span>
                    </div>
                </div>{{answerOption}}</li>  <!-- NOTE: This MUST be on one line else the option wrap! -->
        </ul>
    </div>

</template>

<script>
    import Vue from 'vue';

    import {API, GRAPHQL_AUTH_MODE} from "@aws-amplify/api";

    import ChatLine from './ChatLine.vue';

    import {getTodaysPuzzle} from "../mytutor-graphql/queries";
    import {answerTodaysPuzzle} from "../mytutor-graphql/mutations";

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";
    import {i18nMixin} from "../mixins/I18nMixin";

    export default {
        name: 'DailyPuzzle',
        components: {
            ChatLine,
        },
        mixins: [apiMixin, i18nMixin],
        props: {},
        created() {
        },
        data() {
            return {
                referrer: "",
                questionID: "",
                date: "",
                advert: "daily_puzzle_advert.jpg",
                question: null,
                answer: null
            }
        },
        computed: {
            isMultipleChoiceAnswer: function () {
                return this.question.layout === 'LAYOUT_1_2_3_4_5' ||
                    this.question.layout === 'LAYOUT_1_2_3_NL_4_5' ||
                    this.question.layout === 'LAYOUT_1_2_NL_3_4_NL_5' ||
                    this.question.layout === 'LAYOUT_XL_1_2_NL_3_4_NL_5' ||
                    this.question.layout === 'LAYOUT_1_NL_2_NL_3_NL_4_NL_5';
            },
            placeholderText: function () {
                return this.question.placeholderText;
            },
            answered: function () {
                return this.answer !== null;
            },
            answerOptionsAsArray: function () {
                if (this.question.answerOptions && this.question.answerOptions.length > 4) {
                    const options = this.question.answerOptions.substring(2, this.question.answerOptions.length - 2);
                    const answerOpts = options.split("|");
                    return answerOpts;
                } else {
                    console.log("Error, there are no answer options");
                    return [];
                }
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            fetchDailyPuzzle: async function () {
                console.log('fetching daily puzzle');
                try {
                    let variables = {
                        language: this.locale
                    };
                    if (this.questionID) {
                        variables.questionID = this.questionID;
                    } else if (this.date) {
                        variables.date = this.date;
                    } else {
                        const timezoneOffsetHours = -new Date().getTimezoneOffset() / 60;
                        console.log(typeof timezoneOffsetHours);
                        variables.timezoneOffsetHours = timezoneOffsetHours;
                    }
                    const puzzleRet = await API.graphql({
                        query: getTodaysPuzzle,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    if (puzzleRet.data.getTodaysPuzzle !== null) {
                        console.log("Today's puzzle found");
                        return puzzleRet.data.getTodaysPuzzle;
                    } else {
                        console.log("No today's puzzle returned - should not be");
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching today's puzzle", error, true)
                }
                return null;
            },
            saveAnswer: async function (questionID, answer, referrer) {
                console.log('saving answer on server');
                try {
                    const variables = {
                        questionID: questionID,
                        answer: answer,
                        referrer: referrer
                    };
                    const answerTodaysPuzzleRet = await API.graphql({
                        query: answerTodaysPuzzle,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.log(answerTodaysPuzzleRet);
                    if (answerTodaysPuzzleRet.data.answerTodaysPuzzle !== null) {
                        console.log("Today's puzzle answered");
                        return answerTodaysPuzzleRet.data.answerTodaysPuzzle;
                    } else {
                        console.log("Error answering today's puzzle");
                        this.logError("Answering today's puzzle", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Answering today's puzzle", error, true);
                    return null;
                }
            },

            // ----------------------------------------------------------------------------
            //
            // Daily puzzle behaviour
            //
            // ----------------------------------------------------------------------------
            processQueryParams() {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                this.referrer = params.ref;
                console.log(this.referrer);
                this.questionID = params.qid;
                console.log(this.questionID);
                this.date = params.date;
                console.log(this.questionID);
                if (params.advert) {
                    if (params.advert === 'blank') {
                        this.advert = null;
                    } else {
                        this.advert = params.advert;
                    }
                }
                console.log(this.advert);
                // prioritise the questionID
                if (this.questionID && this.date) {
                    this.date = "";
                }
            },
            loadQuestion: async function() {
                this.question = await this.fetchDailyPuzzle();
                let images = [...this.question.questionURLs];
                if (this.question) {
                    Vue.set(this.question, 'images', images);
                    Vue.set(this.question, 'logType', 'MYTUTOR_FILE');
                }
            },
            changeAnswer: async function (answer) {
                if (this.answer === null) {
                    this.answer = answer;
                    if (this.answer === this.question.answerValue) {
                        Vue.set(this.question, 'correct', true);
                        this.question.images = this.question.answerRightURLs;
                    } else {
                        Vue.set(this.question, 'correct', false);
                        this.question.images = this.question.answerWrongURLs;
                    }
                    if (this.advert) {
                        this.question.images.push("https://gregbeckermytutor.s3.af-south-1.amazonaws.com/" + this.advert);
                    }
                    await this.saveAnswer(this.question.id, this.answer, this.referrer);
                }
            }
        },
        watch: {
            '$i18n.locale': async function() {
                console.log("Local changed");
                await this.loadQuestion();
            }
        },
        mounted: async function () {
            this.processQueryParams();
            await this.loadQuestion();
        },
        beforeDestroy() {
        }
    }
</script>

<style scoped>

    .quiz-question {
        text-align: left;
        position: relative;
        margin-bottom: 100px;
    }

    .quiz-question__number, .quiz-question__stats-total-answered, .quiz-question__formula-sheets {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: -40px;
        width: 140px;
        margin-left: 10px;
        padding: 8px 0 16px 0;
        background-color: #ffffff;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        letter-spacing: 0.48px;
        text-align: center;
        border: 3px solid #FFFFFF;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .quiz-question__stats-total-answered, .quiz-question__formula-sheets {
        left: 150px;
        width: 500px;
        text-align: left;
        padding-left: 15px;
        background: none;
        border-color: transparent;
    }

    .quiz-question__formula-sheets {
        right: 0;
        width: inherit;
        text-align: left;
        margin-right: 10px;
        padding: 0;
    }

    .quiz-question__formula-sheets img {
        height: 35px;
    }

    .quiz-question__number--answered {
        border-color: #5469F3;
        background-color: #5469F3;
        color: #ffffff;
    }

    .quiz-question__question {
        position: relative;
        z-index: 2;
    }

    .quiz-question__spacer {
        height: 4px;
        border: 4px solid transparent;
        border-top: none;
        border-bottom: none;
    }

    .quiz-question__spacer--answered {
        border: 4px solid #5469F3;
        border-top: none;
        border-bottom: none;
        margin: 0 10px;
    }

    .quiz-question__options {
        text-align: center;
        list-style: none;
        margin: 0 10px;
        height: 100px;
        background-color: #F2F2F2;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__options--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__options li {
        display: inline-block;
        margin: 28px 3%;
        font: normal normal normal 26px/26px Inter;
        letter-spacing: 0px;
        color: #1C1A4F;
    }

    .quiz-question__option {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid #1C1A4F;
        border-radius: 20px;
        vertical-align: middle;
        margin-right: 10px;
    }

    .quiz-question__options--disabled li {
        color: #B8BABD !important;
    }

    .quiz-question__options--disabled .quiz-question__option {
        border-color: #B8BABD;
    }

    .quiz-question__option div {
        border-radius: 15px;
        height: 28px;
        width: 28px;
        margin-top: 4px;
        margin-left: 4px;
    }

    .quiz-question__option span {
        color: white !important;
    }

    .quiz-question__option span img {
        width: 17px;
    }

    .quiz-question__option--selected {
        background-color: #5469F3;
    }

    .quiz-question__option--correct {
        background-color: #02C664;
    }

    .quiz-question__option--incorrect span {
        vertical-align: top;
    }

    .quiz-question__option--incorrect {
        background-color: #E0312E;
    }

    .quiz-question__answer-text {
        text-align: left;
        margin: 0 10px;
        font: normal normal 400 28px/44px Inter;
        font: normal normal 600 16px/20px Inter;
        color: #5469F3;
        padding: 10px 0;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        border: 4px solid #F2F2F2;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .quiz-question__answer-text--paragraph {
        flex-direction: column;
    }

    .quiz-question__answer-text-row {
        flex-direction: row;
        display: flex;
        margin-bottom: 20px;
    }

    .quiz-question__answer-text span {
        padding: 13px 20px;
    }

    .quiz-question__answer-text input[type="text"], .quiz-question__answer-text--paragraph textarea {
        flex: 1;
        padding: 5px 15px;
        margin-left: 10px;
        font: normal normal 400 28px/22px Inter;
        color: #5469F3;
        /* border: 1px solid #363374; */
        border: 2px solid #666;
        border-radius: 10px;
        outline: none;
    }

    .quiz-question__answer-text input[type="text"]:focus {
        border-color: #5469F3;
    }

    .quiz-question__answer-text--paragraph textarea {
        height: 200px;
        min-height: 200px;
    }

    .quiz-question__answer-text--disabled {
        color: #ccc;
        /* border-color: #ccc; */
    }

    .quiz-question__answer-text--disabled input[type="text"] {
        color: #363374;
    }

    .quiz-question__answer-text-status {
        width: 120px;
    }

    .quiz-question__answer-text-marked-and-score {
        width: 120px;
    }

    .quiz-question__answer-text-marked {
        width: 40px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        padding: 10px 10px;
        border-radius: 20px;
        height: 40px;
        margin-top: 3px;
    }

    .quiz-question__answer-text--answered {
        border: 4px solid #5469F3;
        border-top: none;
    }

    .quiz-question__answer-text--marked {
        border-bottom: none !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .quiz-question__answer-text--feedback {
        display: block !important;
    }

    .quiz-question__answer-text--feedback div {
        padding: 13px 20px;
        font: normal normal 400 20px/24px Inter !important;
        color: #5469F3;
    }

    .quiz-question__answer-photo {
        min-height: 200px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 20px 10px 10px 10px;
        flex: 1;
        text-align: center;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: #DDE1FD url("../assets/camera.png") no-repeat center;
        color: dimgray;
        position: relative;
        cursor: pointer;
    }

    .quiz-question__answer-photo:hover {
        background: #5469F3 url("../assets/camera.png") no-repeat center;
        color: #fff;
    }

    .quiz-question__answer-photo-input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
    }

    .quiz-question__answer-photo p {
        font-size: 1.2em;
        text-align: center;
        margin-bottom: 0;
    }

    .quiz-question__answer-photo-thumbnail {
        min-height: 200px;
        max-height: 200px;
        margin-left: 10px;
        padding: 10px;
        flex: 1;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -5px;
    }

    .quiz-question__answer-photo-thumbnail img {
        max-height: 100%;
        max-width: 100%;
    }

    .quiz-question__answer-photo-thumbnail--no-photo {
        width: 100%;
        height: 100%;
        padding-top: 70px;
        text-align: center;
    }

    .quiz-question__answer-photo-delete {
        border-radius: 25px;
        background-color: #B8BABD;
        padding: 3px 0 0 3px;
        width: 48px;
        height: 48px;
        margin-left: 10px;
    }

    .quiz-question__message {
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: 49px;
        padding: 0 15px;
        z-index: 100;
        text-align: center;
    }

    .quiz-question__message > div {
        position: relative;
        padding: 10px;
        left: 0%;
        background-color: #5469F3;
        color: white;
        font-weight: bold;
    }

    .quiz-question__message > div:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #5469F3;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }

    .quiz-question__answer-stats {
        font: normal normal 600 14px/32px Inter;
        letter-spacing: 0.28px;
        color: #1C1A4F;
    }

    @media screen and (max-width: 650px) {
        .quiz-question__number {
            font: normal normal 600 14px/18px Inter;
        }

        .quiz-question__options {
            height: 70px;
        }

        .quiz-question__options li {
            margin: 16px 20px;
            margin: 16px 4%;
            font: normal normal normal 22px/22px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 10px;
            height: 20px;
            width: 20px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 18px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 1px;
            vertical-align: top;
        }
    }

    @media screen and (max-width: 510px) {
        .quiz-question__options {
            height: 60px;
        }

        .quiz-question__options li {
            margin: 14px 4% 14px 2%;
            font: normal normal normal 20px/20px Inter;
        }

        .quiz-question__option {
            margin-right: 4px;
        }
    }

    @media screen and (max-width: 414px) {
        .quiz-question__number {
            font: normal normal 600 14px/18px Inter;
        }

        .quiz-question__options {
            height: 55px;
        }

        .quiz-question__options li {
            margin: 14px 3% 14px 2%;
            font: normal normal normal 18px/20px Inter;
        }

        .quiz-question__option {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }

        .quiz-question__option div {
            border-radius: 7px;
            height: 14px;
            width: 14px;
            margin-top: 2px;
            margin-left: 2px;
        }

        .quiz-question__option span {
            font-size: 14px;
        }

        .quiz-question__option--incorrect span {
            margin-left: 0;
            vertical-align: top;
        }

        .hidden-mobile {
            display: none !important;
        }
    }

    @media screen and (max-width: 380px) {
        .quiz-question__options {
            height: 50px;
        }

        .quiz-question__options li {
            margin: 14px 3% 14px 2%;
            font: normal normal normal 16px/20px Inter;
        }

        .quiz-question__option {
            margin-right: 2px;
        }
    }

    @media screen and (max-width: 320px) {
        .quiz-question__options {
            height: 50px;
        }

        .quiz-question__options li {
            margin: 14px 4% 14px 0;
            font: normal normal normal 15px/20px Inter;
        }

        .quiz-question__option {
            margin-right: 2px;
        }
    }

</style>
