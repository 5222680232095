<template>
    <div class="form-entry">
        <h3>Enter a test using a code:</h3>
        <form v-on:submit.prevent="enter()">
            <ul>
                <li>
                    <input type="text" id="testCode" v-model="testCode" v-on:keypress="clearErrorMessage">
                    <button v-show="!registering" type="submit">Enter</button>
                    <p v-if="showValidateErrorCode" class="form-entry__error">
                        Please enter a 13 character test code
                    </p>
                </li>
            </ul>
        </form>
    </div>
</template>

<script>
    export default {
        name: "TestEntryByCode",
        components: {},
        created() {
        },
        mixins: [],
        props: {
        },
        data() {
            return {
                testCode: "",
                showValidateErrorCode: false,
                registering: false
            }
        },
        computed: {
        },
        methods: {
            clearErrorMessage: function() {
                this.showValidateErrorCode = false;
            },
            enter: function() {
                console.debug("validating code form");
                    if (!this.validateCodeForm()) {
                    return;
                }

                console.log("opening register link for test with code: " + this.testCode);
                this.$nextTick(() => {
                    this.$root.$emit('register-for-test', this.testCode);
                });
                this.registering = true;
            },
            validateCodeForm: function() {
                this.showValidateErrorCode = false;
                if (!this.testCode || this.testCode.length !== 13) {
                    this.showValidateErrorCode = true;
                    return false;
                }
                return true;
            }
        },
        mounted: async function () {
            this.testScheduledFn = () => {
                this.registering = false;
            };
            this.$root.$on('test-scheduled', this.testScheduledFn);
        },
        beforeDestroy: async function () {
            this.$root.$off('test-scheduled', this.testScheduledFn);
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
</style>

<style lang="scss" scoped>
    .form-entry {
        width: 100%;
        margin: 20px 0;
        max-width: 100%;
    }

    ul {
        margin-top: 0px !important;

        li {
            padding-bottom: 10px !important;
        }
    }


    input {
        width: 55% !important;
        margin-right: 30px !important;
    }

    button {
        width: 35% !important;
        margin: 10px 0 !important;
    }

    @media screen and (max-width:414px) {
        input {
            width: 100% !important;
            margin-right: inherit !important;
        }

        button {
            width: 100% !important;
            margin-bottom: inherit !important;
        }

    }
</style>
