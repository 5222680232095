<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-content__text">
                    <ul v-for="(notification, index) in notifications" v-bind:key="index">
                        <li>
                            <div v-html="notification.message"></div>
                            <div v-if="notification.link" class="modal-content__cta">
                                <button v-if="notification.cancelLink"
                                        v-on:click="executeCallback(notification.cancelLink.callback)"
                                        class="btn btn-secondary">{{notification.cancelLink.text}}
                                </button>
                                <button v-on:click="executeCallback(notification.link.callback)"
                                        class="btn btn-primary">{{notification.link.text}}
                                </button>
                            </div>
                        </li>
                    </ul>
                    <template v-if="showSpinner">
                        <spinner height="50px" colour="#1C1A4F"/>
                    </template>
                </div>
                <div class="modal-content__close" v-on:click="close"><img src="../assets/cross.svg" alt="Close"/></div>
            </div>
        </div>
    </div>
</template>

<script>
    // Visual components
    import Spinner from './Spinner.vue';

    export default {
        name: 'Notifications',
        components: {
            Spinner
        },
        props: {
            notifications: Array,
            showSpinner: Boolean
        },
        data() {
            return {}
        },
        computed: {},
        methods: {
            executeCallback: function (callback) {
                callback();
                this.close();
            },
            close: function () {
                this.$root.$emit('close-notifications');
            }
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        z-index: 200;
        overflow: scroll;
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-content {
        position: relative;
        width: 100%;
        max-width: 500px;
        margin: auto;
        box-shadow: 0 0 0 100px rgb(0 0 0 / 70%);
        box-shadow: 0 0 0 100vmax rgb(0 0 0 / 70%);
        background-color: #5469F3;
        border-radius: 10px;
        color: white;
        padding: 100px 50px;
        z-index: 300;
        text-align: center;
        display: flex;
        flex-direction: row;
    }

    .modal-content div {
        display: inline-block;
        vertical-align: middle;
    }

    .modal-content ul {
        list-style: none;
    }

    .modal-content__cta {
        margin-top: 20px;
        width: 100%;
    }

    .modal-content__text {
        font: normal normal 500 26px/32px Inter;
        flex: 1;

        :any-link {
            color: white;
        }
    }

    .modal-content__close {
        position: absolute;
        top: 22px;
        right: 23px;
        color: #1C1A4F;
    }

    .modal-content__close img {
        width: 23px;
        height: 23px;
    }

    .btn {
        font: normal normal normal 20px/22px Inter;
        letter-spacing: 0.6px;
        color: #fff;
        font-weight: 600;
        border-radius: 20px;
        height: 40px;
        padding: 0 20px;
        margin: 20px 20px 40px 20px;
        text-align: center;
        opacity: 1;
        border: none;
    }

    .btn:hover {
        cursor: pointer;
    }

    .btn-primary {
        background: #F9AC1B 0% 0% no-repeat padding-box;
    }

    .btn-secondary {
        background: #B8BABD 0% 0% no-repeat padding-box;
        color: #1C1A4F;
    }

    @media screen and (max-width: 414px) {
        .modal-content {
            padding: 60px 20px 30px 20px;
        }

        .modal-content__text {
            font: normal normal 500 20px/24px Inter;
        }

        .btn {
            font: normal normal normal 18px/20px Inter;
            font-weight: bold;
            padding: 0 20px;
            margin: 20px 10px 40px 10px;
        }

    }


</style>