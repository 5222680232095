<template>
  <main-layout>
    <div class="cta">
      <a href="https://mytutor.chat/?rft=LOGIC-DAY-2022"><button class="btn btn-primary">{{ $t('logicDayCTAMsg')}}</button></a>
    </div>
    <img src="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/MyTutorChatLogicDay.png"/>
    <div class="cta">
      <a href="https://mytutor.chat/?rft=LOGIC-DAY-2022"><button class="btn btn-primary">{{ $t('logicDayCTAMsg')}}</button></a>
      <br/>
      <a href="https://gregbeckermytutor.s3.af-south-1.amazonaws.com/MyTutor.chat+Introduction+-+learner.pdf" target="__blank">
        <button class="btn btn-secondary">{{ $t('commonWhatIsMyTutorMsg')}}</button>
      </a>
    </div>

    <div class="video-wrapper">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/83ohJX8HS5Y" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div class="video-spacer"></div>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>

<style scoped>
  img {
    width: 100%;
    margin-bottom: 30px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */ 
    margin-top: 50px;
  }

  .video-spacer {
    height: 50px;
  }

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width:414px) {
    img {
      margin-bottom: 15px;
    }
  }

</style>