<template>
    <!-- Main chat component -->
    <div class="chat-window">
        <header-bar></header-bar>

        <div class="chat-window__body">

            <template v-if="loadingWorksheetEntry">
                <spinner height="50px" colour="#F9AC1B"/>
            </template>

            <template v-else>
                <template v-if="worksheetEntry">
                    <div class="worksheet__header">
                        <h2>{{worksheetEntry.getWorksheet().name}}</h2>

        <!--                <div class="worksheet__header__progress">-->
        <!--                    <ul class="worksheet__header__progress-pills">-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;incorrect"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;incorrect"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;incorrect"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill&#45;&#45;correct"></li>-->
        <!--                        <li class="worksheet__header__progress-pill">?</li>-->
        <!--                    </ul>-->
        <!--                </div>-->

        <!--                <div class="worksheet__header__progress__message">-->
        <!--                    One correct answer to go!-->
        <!--                </div>-->

<!--                        <div class="worksheet__header__progress">-->
<!--                            <div class="worksheet__header__progress-container">-->
<!--                                <div class="worksheet__header__progress-loader" v-bind:style="'width:' + percentageComplete + '%'">-->
<!--                                    <span v-if="percentageComplete > 5">{{percentageComplete}}%</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="worksheet__header__progress__message">-->
<!--                            14 Questions left-->
<!--                        </div>-->

                        <div class="worksheet__header__stats">
                            <div>
                                Answered: {{worksheetEntry.answered}}
                            </div>
                            <div>
                                Right: {{worksheetEntry.correct}}
                            </div>
                            <div>
                                Streak: {{worksheetEntry.streak}}
                            </div>
                        </div>
                        <div class="worksheet__header__progress__message">
                        </div>
                    </div>

                    <div ref="worksheetContentScroller" class="worksheet__content" @scroll="scrollWorksheet">
                        <div ref="worksheetContent">
                            <div class="worksheet__content__instructions">
                                <h4 v-on:click="scrollToBottom()">Instructions</h4>
                                <span v-html="worksheetEntry.getWorksheet().instructions"></span>
                            </div>

                            <div class="chat-window__loading" v-show="loadingHistory">
                                <spinner height="50px" colour="#1C1A4F"/>
                            </div>

                            <div>
                                <quiz-question v-for="(answer, index) in worksheetEntry.getAnswers()"
                                           ref="question"
                                           v-bind:key="index"
                                           v-bind:index="index"
                                           v-bind:question="answer"
                                           v-bind:sectionInProgress="false"
                                           v-bind:canAnswer="!isObserver && !isAnswered(answer) && !savingAnswer"
                                           v-bind:isTeacher="false"
                                           v-show="index < worksheetEntry.getAnswers().length - 1 || !lastQuestionHidden"/>

                                <div v-if="showFeedback" class="worksheet__feedback">
                                    <div v-if="worksheetEntry.isFinished()">
                                        Congratulations, you have completed this worksheet!
                                    </div>
                                    <div v-if="savingAnswer" class="worksheet__feedback__saving-answer">
                                        Saving your answer
                                        <spinner height="30px" colour="#F9AC1B"/>
                                    </div>
                                    <template v-if="showNextQuestionButton">
                                        <button class="worksheet__feedback__next-question" v-on:click="showNextQuestion()">Next Question</button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="worksheet__not-found">
                        <h3>We could not find your Worksheet Entry</h3>
                        Please email info@mytutor.chat for assistance<br/>
                        We apologise for the inconvenience
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import {store} from '../store.js'

    import worksheetEntry from '../domain/worksheet-entry';

    // Visual components
    import Spinner from '../components/Spinner.vue';
    import HeaderBar from '../components/Header.vue';
    import QuizQuestion from '../components/QuizQuestion.vue';

    export default {
        name: 'Worksheet',
        components: {
            Spinner, HeaderBar, QuizQuestion
        },
        data() {
          return {
              loadingWorksheetEntry: true,
              worksheetEntry: null,
              isObserver: false,
              savingAnswer: false,
              lastQuestionHidden: false,
              loadingHistory: false
          }
        },
        computed: {
            percentageComplete: function() {
                if (this.worksheetEntry) {
                    return Math.round(this.worksheetEntry.getAnswers().length / this.worksheetEntry.getWorksheet().numberOfQuestions * 100);
                }
                return 0;
            },
            showFeedback: function() {
                return this.worksheetEntry && this.worksheetEntry.isFinished() || this.savingAnswer || this.showNextQuestionButton;
            },
            showNextQuestionButton: function () {
                return (!this.savingAnswer && !this.worksheetEntry.isFinished() && this.lastQuestionHidden);
            },
        },
        methods: {
            isAnswered: function(answer) {
                if (answer.answeredAt) {
                    return true;
                } else {
                    return false;
                }
            },
            changeAnswer: async function(answerID, answer) {
                console.log("Changing answer with ID: " + answerID + " to: " + answer);
                this.savingAnswer = true;
                this.$nextTick(() => this.scrollToBottom());
                await this.worksheetEntry.answerQuestion(answerID, answer);
                if (!this.worksheetEntry.isFinished()) {
                    this.lastQuestionHidden = true;
                }
                this.savingAnswer = false;
            },
            showClue: function(answerID) {
                console.log("Showing clue in Worksheet");
                this.worksheetEntry.showClueForQuestion(answerID);
            },
            hideClue: function(answerID) {
                console.log("Hiding clue in Worksheet");
                this.worksheetEntry.hideClueForQuestion(answerID);
            },
            showNextQuestion: function() {
                this.lastQuestionHidden = false;
                this.$nextTick(() => this.scrollToBottom());
            },
            scrollToBottom: function() {
                if (this.$refs.worksheetContentScroller) {
                    let heightOfWorksheet = this.$refs.worksheetContent.clientHeight;
                    console.log("Height: " + heightOfWorksheet);
                    this.$refs.worksheetContentScroller.scrollTop = heightOfWorksheet;
                }
            },
            scrollWorksheet: async function (event) {
                var topOfWindow = event.target.scrollTop === 0;
                if (!this.savingAnswer && !this.lastQuestionHidden && topOfWindow && store.user) {
                    console.log("Top of window");
                    this.loadingHistory = true;
                    await this.worksheetEntry.fetchMoreHistory();
                    this.$nextTick(() => {
                        this.loadingHistory = false;
                    });
                }
            },
            signedIn: async function () {
                console.log("Signed in, loading worksheet response");
                const worksheetOrEntryID = this.$route.params.worksheetOrEntryID;
                if (worksheetOrEntryID) {
                    if (this.$route.query.start) {
                        console.log("Starting worksheet");
                        this.worksheetEntry = await worksheetEntry({ userID: store.user.getID(), worksheetID: worksheetOrEntryID });
                        // replace the URL to prevent starting the worksheet again
                        this.$router.replace({path: "/worksheet/" + this.worksheetEntry.id + "/"});
                    } else if (this.$route.query.observer) {
                        console.log("Opening observer view of worksheet");
                        this.isObserver = true;
                        this.worksheetEntry = await worksheetEntry({ worksheetEntryID: worksheetOrEntryID, observer: true });
                        // this.$router.replace({path: "/worksheet/" + this.worksheetEntry.id + "/"});
                    } else {
                        console.log("Opening worksheet");
                        this.worksheetEntry = await worksheetEntry({ worksheetEntryID: worksheetOrEntryID });
                    }
                }
                this.loadingWorksheetEntry = false;
                this.$nextTick(() => this.scrollToBottom());
            },
            signedOut: function () {
                console.log("Signed out");
                this.worksheetEntry = null;
            }
        },
        watch: {
            '$route.params.worksheetOrEntryID': function (worksheetOrEntryID) {
                console.log('worksheetOrEntryID changed to : ' + worksheetOrEntryID);
                this.loadingWorksheetEntry = true;
                this.signedIn();
            },
        },
        mounted: async function () {
            if (store.user) {
                console.log("Worksheet mounted: " + store.user.getID());
                this.signedIn();
            } else {
                this.$router.push({ path: '/signin' });
            }
            this.$root.$on('change-answer', this.changeAnswer);
            this.$root.$on('show-clue', this.showClue);
            this.$root.$on('hide-clue', this.hideClue);
            this.$root.$on('image-loaded', this.scrollToBottom);
        },
        beforeDestroy() {
            this.$root.$off('change-answer', this.changeAnswer);
            this.$root.$off('show-clue', this.showClue);
            this.$root.$off('hide-clue', this.hideClue);
            this.$root.$off('image-loaded', this.scrollToBottom);
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/pages/worksheet";
    @import "../styles/layout/chat_window";
</style>
