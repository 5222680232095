import { API, graphqlOperation } from "@aws-amplify/api";

// Our custom queries & mutations
import { openWorksheetEntry, getWorksheetEntryHistory, openWorksheetEntryForTeacher } from "../mytutor-graphql/queries";
import { startWorksheet, answerWorksheetQuestion } from "../mytutor-graphql/mutations";

/**
 * This represents a Worksheet. It's a composition of a Worksheet and WorksheetEntry
 * if one compares to the schema
 *
 * @param
 * @returns {Promise<null>}
 */
var worksheetEntry = async function(options) {
    var that = {};

    that.streak = 0;
    that.answered = 0;
    that.correct = 0;
    that.finishedAt = null;

    // ============================================================================
    //
    // API calls
    //
    // ============================================================================

    let startWorksheetEntryOnServer = async function (userID, worksheetID) {
        console.log("Starting worksheet with ID: " + worksheetID);
        try {
            const entryRet = await API.graphql(graphqlOperation(startWorksheet, { userID: userID, worksheetID: worksheetID }));
            marshallDataForWorksheetEntry(entryRet.data.startWorksheet);
        } catch (error) {
            logAPIError("Finding WorksheetEntry", error, true);
            throw new Error("API Error finding WorksheetEntry");
        }
    }

    let fetchWorksheetEntryFromServer = async function (worksheetEntryID) {
        console.log("Fetching worksheet entry with ID: " + worksheetEntryID);
        try {
            const entryRet = await API.graphql(graphqlOperation(openWorksheetEntry, { worksheetEntryID: worksheetEntryID }));
            marshallDataForWorksheetEntry(entryRet.data.openWorksheetEntry);
        } catch (error) {
            logAPIError("Finding WorksheetEntry", error, true);
            throw new Error("API Error finding WorksheetEntry");
        }
    }

    let fetchWorksheetEntryForTeacherFromServer = async function (worksheetEntryID) {
        console.log("Fetching worksheet entry with ID: " + worksheetEntryID);
        try {
            const entryRet = await API.graphql(graphqlOperation(openWorksheetEntryForTeacher, { worksheetEntryID: worksheetEntryID }));
            marshallDataForWorksheetEntry(entryRet.data.openWorksheetEntryForTeacher);
            that.nextTokenHistory = null;
        } catch (error) {
            logAPIError("Finding WorksheetEntry", error, true);
            throw new Error("API Error finding WorksheetEntry");
        }
    }

    let marshallDataForWorksheetEntry = function (worksheetEntryData) {
        console.log(worksheetEntryData);
        if (worksheetEntryData !== null) {
            that = Object.assign(that, worksheetEntryData);
            for (let answer of that.getAnswers()) {
                marshallDataForQuizQuestion(answer);
            }
        } else {
            logError("Finding WorksheetEntry", "No data returned", true);
            throw new Error("Error finding WorksheetEntry");
        }
    }

    let marshallDataForQuizQuestion = function (answer) {
        answer.layout = "LAYOUT_1_2_3_4_5";
        answer.logType = 'MYTUTOR_FILE';
        if (answer.answeredAt) {
            if (answer.correct) {
                answer.images = answer.answerRightURLs;
            } else {
                answer.images = answer.answerWrongURLs;
            }
        } else {
            answer.images = answer.questionURLs;
        }
        return answer;
    }

    let answerWorksheetAnswerOnServer = async function (worksheetAnswer, answer) {
        console.log("Answering worksheet answer with ID: " + worksheetAnswer.id);
        try {
            let variables = {
                worksheetAnswerID: worksheetAnswer.id,
                answer: answer,
                timezoneOffsetHours: -new Date().getTimezoneOffset() / 60
            };
            const entryRet = await API.graphql(graphqlOperation(answerWorksheetQuestion, variables));
            console.log(entryRet);
            if (entryRet.data.answerWorksheetQuestion !== null) {
                // update the WorksheetEntry
                const entry = entryRet.data.answerWorksheetQuestion;
                that.finishedAt = entry.finishedAt;
                that.streak = entry.streak;
                that.answered = entry.answered;
                that.correct = entry.correct;
                // update the WorksheetAnswer
                const markedAnswer = entry.answers[0];
                worksheetAnswer.correct = markedAnswer.correct;
                worksheetAnswer.answer = markedAnswer.answer;
                worksheetAnswer.answeredAt = markedAnswer.answeredAt;
                if (worksheetAnswer.correct) {
                    worksheetAnswer.images = worksheetAnswer.answerRightURLs;
                } else {
                    worksheetAnswer.images = worksheetAnswer.answerWrongURLs;
                }
                // if there is a new WorksheetAnswer, add it
                if (entry.answers.length === 2) {
                    that.answers.push(marshallDataForQuizQuestion(entry.answers[1]));
                }
            } else {
                logError("Answering WorksheetAnswer", "No data returned", true);
                throw new Error("Error answering WorksheetAnswer");
            }
        } catch (error) {
            logAPIError("Answering WorksheetAnswer", error, true);
            throw new Error("API Error answering WorksheetAnswer");
        }
    }

    let fetchHistory = async function () {
        if (that.nextTokenHistory === null) {
            console.log("Fetched all history");
            return;
        }

        console.log('fetching worksheet history for worksheet entry with ID: ' + that.id)
        try {
            let variables = {
                worksheetEntryID: that.id,
            };
            if (that.nextTokenHistory) {
                variables.nextToken = that.nextTokenHistory
            }
            let historyResponse = await API.graphql(graphqlOperation(getWorksheetEntryHistory, variables));
            if (historyResponse.data) {
                that.nextTokenHistory = historyResponse.data.getWorksheetEntryHistory.nextToken;
                if (historyResponse.data.getWorksheetEntryHistory.answers.length > 0) {
                    let history = historyResponse.data.getWorksheetEntryHistory.answers;
                    for (let answer of history) {
                        if (answer.number < that.answers[0].number) {
                            that.answers.unshift(marshallDataForQuizQuestion(answer));
                        }
                    }
                }
            } else {
                console.log("Error fetching worksheet history");
                this.logError("Fetching worksheet history", "No data returned", true);
                return [];
            }
        } catch (error) {
            this.logAPIError("Fetching worksheet history", error, true);
            return [];
        }
    },

    logAPIError = function(context, error, fatal) {
        if (error.errors && error.errors[0] && error.errors[0].message) {
            logError(context, JSON.stringify(error, error.errors[0].message, 2), fatal);
        } else {
            logError(context, JSON.stringify(error, null, 2), fatal);
        }
    }

    let logError = function(context, errorMessage, fatal) {
        console.log("Logging an error: " + errorMessage);
        var dataObject = {
            'event': 'exception',
            'errorMessage': {
                'category': 'API',
                'description': context + ': ' + errorMessage,
                'fatal': fatal
            }
        };
        if(typeof window.dataLayer != 'undefined'){
            console.log("Sending the error to the dataLayer");
            window.dataLayer.push(dataObject);
        }
    }

    /*
     * Basic properties. These are left exposed so that Vue reactivity works.
     *
     * NB: All Getters and Setters must use these values for reactivity to work
     *
     */
    let getAnswers = function() {
        if (that.answers) {
            return that.answers;
        }
        return [];
    }

    let getWorksheet = function() {
        if (that.worksheet) {
            return that.worksheet;
        }
        return null;
    }

    let isFinished = function() {
        return that.finishedAt !== null;
    }

    /*
     * Getters and Setters with behaviour, or properties we want to have encapsulation on. If the method isn't using
     * a basic property above, the property value will be on the data object passed into the constructor.
     */

    let fetchMoreHistory = async function() {
        await fetchHistory();
    }

    let showClueForQuestion = function(answerID) {
        console.log("Showing clue in WorksheetEntry");
        for (let worksheetAnswer of that.getAnswers()) {
            if (worksheetAnswer.id === answerID) {
                if (worksheetAnswer.clueURL) {
                    worksheetAnswer.backupImages = [...worksheetAnswer.images];
                    worksheetAnswer.images.splice(0, worksheetAnswer.images.length);
                    worksheetAnswer.images.push(worksheetAnswer.clueURL);
                    break;
                }
            }
        }
    }

    let hideClueForQuestion = function(answerID) {
        console.log("Hiding clue in WorksheetEntry");
        for (let worksheetAnswer of that.getAnswers()) {
            if (worksheetAnswer.id === answerID) {
                if (worksheetAnswer.backupImages) {
                    worksheetAnswer.images.splice(0, worksheetAnswer.images.length);
                    worksheetAnswer.images.push(...worksheetAnswer.backupImages);
                    break;
                }
            }
        }
    }

    let answerQuestion = async function(answerID, answer) {
        for (let worksheetAnswer of that.getAnswers()) {
            if (worksheetAnswer.id === answerID) {
                await answerWorksheetAnswerOnServer(worksheetAnswer, answer);
                break;
            }
        }
    }

    that.getAnswers = getAnswers;
    that.getWorksheet = getWorksheet;
    that.isFinished = isFinished;
    that.fetchMoreHistory = fetchMoreHistory;
    that.showClueForQuestion = showClueForQuestion;
    that.hideClueForQuestion = hideClueForQuestion;
    that.answerQuestion = answerQuestion;

    // let answerQuestion = function(answerID, answer, timezoneOffsetHours) {
    //     console.log("Starting worksheet with ID: " + worksheetID);
    //     return null;
    // }
    // that.answerQuestion = answerQuestion;

    try {
        console.log(options);
        if (options.worksheetEntryID) {
            if (options.observer) {
                await fetchWorksheetEntryForTeacherFromServer(options.worksheetEntryID);
            } else {
                await fetchWorksheetEntryFromServer(options.worksheetEntryID);
            }
        } else if (options.userID && options.worksheetID) {
            await startWorksheetEntryOnServer(options.userID, options.worksheetID);
        } else {
            throw new Error("Invalid arguments sent to WorksheetEntry constructor");
        }
    } catch (error) {
        console.log(error);
        return null;
    }

    return that;


};

export default worksheetEntry;