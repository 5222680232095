/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPasswordResetRequest = /* GraphQL */ `
  mutation CreatePasswordResetRequest(
    $input: CreatePasswordResetRequestInput!
    $condition: ModelPasswordResetRequestConditionInput
  ) {
    createPasswordResetRequest(input: $input, condition: $condition) {
      id
      userID
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePasswordResetRequest = /* GraphQL */ `
  mutation UpdatePasswordResetRequest(
    $input: UpdatePasswordResetRequestInput!
    $condition: ModelPasswordResetRequestConditionInput
  ) {
    updatePasswordResetRequest(input: $input, condition: $condition) {
      id
      userID
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePasswordResetRequest = /* GraphQL */ `
  mutation DeletePasswordResetRequest(
    $input: DeletePasswordResetRequestInput!
    $condition: ModelPasswordResetRequestConditionInput
  ) {
    deletePasswordResetRequest(input: $input, condition: $condition) {
      id
      userID
      reference
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      email
      notificationEmail
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      email
      notificationEmail
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      email
      notificationEmail
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentMetrics = /* GraphQL */ `
  mutation CreateStudentMetrics(
    $input: CreateStudentMetricsInput!
    $condition: ModelStudentMetricsConditionInput
  ) {
    createStudentMetrics(input: $input, condition: $condition) {
      id
      userID
      name
      school
      teacherID
      grade
      totalAnswered
      totalRight
      rightStreak
      wrongStreak
      longestRightStreak
      eloScore
      highestELOScore
      score
      totalDaysActive
      registrationQuestionsAnswered
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      daysActive {
        nextToken
        __typename
      }
      weeksActive {
        nextToken
        __typename
      }
      monthsActive {
        nextToken
        __typename
      }
      yearsActive {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateStudentMetrics = /* GraphQL */ `
  mutation UpdateStudentMetrics(
    $input: UpdateStudentMetricsInput!
    $condition: ModelStudentMetricsConditionInput
  ) {
    updateStudentMetrics(input: $input, condition: $condition) {
      id
      userID
      name
      school
      teacherID
      grade
      totalAnswered
      totalRight
      rightStreak
      wrongStreak
      longestRightStreak
      eloScore
      highestELOScore
      score
      totalDaysActive
      registrationQuestionsAnswered
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      daysActive {
        nextToken
        __typename
      }
      weeksActive {
        nextToken
        __typename
      }
      monthsActive {
        nextToken
        __typename
      }
      yearsActive {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteStudentMetrics = /* GraphQL */ `
  mutation DeleteStudentMetrics(
    $input: DeleteStudentMetricsInput!
    $condition: ModelStudentMetricsConditionInput
  ) {
    deleteStudentMetrics(input: $input, condition: $condition) {
      id
      userID
      name
      school
      teacherID
      grade
      totalAnswered
      totalRight
      rightStreak
      wrongStreak
      longestRightStreak
      eloScore
      highestELOScore
      score
      totalDaysActive
      registrationQuestionsAnswered
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      daysActive {
        nextToken
        __typename
      }
      weeksActive {
        nextToken
        __typename
      }
      monthsActive {
        nextToken
        __typename
      }
      yearsActive {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createStudentDayActive = /* GraphQL */ `
  mutation CreateStudentDayActive(
    $input: CreateStudentDayActiveInput!
    $condition: ModelStudentDayActiveConditionInput
  ) {
    createStudentDayActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentDayActive = /* GraphQL */ `
  mutation UpdateStudentDayActive(
    $input: UpdateStudentDayActiveInput!
    $condition: ModelStudentDayActiveConditionInput
  ) {
    updateStudentDayActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentDayActive = /* GraphQL */ `
  mutation DeleteStudentDayActive(
    $input: DeleteStudentDayActiveInput!
    $condition: ModelStudentDayActiveConditionInput
  ) {
    deleteStudentDayActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentWeekActive = /* GraphQL */ `
  mutation CreateStudentWeekActive(
    $input: CreateStudentWeekActiveInput!
    $condition: ModelStudentWeekActiveConditionInput
  ) {
    createStudentWeekActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentWeekActive = /* GraphQL */ `
  mutation UpdateStudentWeekActive(
    $input: UpdateStudentWeekActiveInput!
    $condition: ModelStudentWeekActiveConditionInput
  ) {
    updateStudentWeekActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentWeekActive = /* GraphQL */ `
  mutation DeleteStudentWeekActive(
    $input: DeleteStudentWeekActiveInput!
    $condition: ModelStudentWeekActiveConditionInput
  ) {
    deleteStudentWeekActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentMonthActive = /* GraphQL */ `
  mutation CreateStudentMonthActive(
    $input: CreateStudentMonthActiveInput!
    $condition: ModelStudentMonthActiveConditionInput
  ) {
    createStudentMonthActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentMonthActive = /* GraphQL */ `
  mutation UpdateStudentMonthActive(
    $input: UpdateStudentMonthActiveInput!
    $condition: ModelStudentMonthActiveConditionInput
  ) {
    updateStudentMonthActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentMonthActive = /* GraphQL */ `
  mutation DeleteStudentMonthActive(
    $input: DeleteStudentMonthActiveInput!
    $condition: ModelStudentMonthActiveConditionInput
  ) {
    deleteStudentMonthActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentYearActive = /* GraphQL */ `
  mutation CreateStudentYearActive(
    $input: CreateStudentYearActiveInput!
    $condition: ModelStudentYearActiveConditionInput
  ) {
    createStudentYearActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentYearActive = /* GraphQL */ `
  mutation UpdateStudentYearActive(
    $input: UpdateStudentYearActiveInput!
    $condition: ModelStudentYearActiveConditionInput
  ) {
    updateStudentYearActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentYearActive = /* GraphQL */ `
  mutation DeleteStudentYearActive(
    $input: DeleteStudentYearActiveInput!
    $condition: ModelStudentYearActiveConditionInput
  ) {
    deleteStudentYearActive(input: $input, condition: $condition) {
      id
      studentMetricsID
      date
      totalAnswered
      totalRight
      rightStreak
      eloScore
      startScore
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeacher = /* GraphQL */ `
  mutation CreateTeacher(
    $input: CreateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    createTeacher(input: $input, condition: $condition) {
      id
      studentEmail
      linkCode
      school
      baseType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeacher = /* GraphQL */ `
  mutation UpdateTeacher(
    $input: UpdateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    updateTeacher(input: $input, condition: $condition) {
      id
      studentEmail
      linkCode
      school
      baseType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeacher = /* GraphQL */ `
  mutation DeleteTeacher(
    $input: DeleteTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    deleteTeacher(input: $input, condition: $condition) {
      id
      studentEmail
      linkCode
      school
      baseType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      id
      userID
      friendID
      inviterID
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      friend {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      id
      userID
      friendID
      inviterID
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      friend {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      id
      userID
      friendID
      inviterID
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      friend {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      name
      logo
      schoolId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      name
      logo
      schoolId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      name
      logo
      schoolId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      country
      name
      nameASCII
      province
      lat
      lng
      natEmis
      dataYear
      status
      sector
      typePED
      phasePED
      specialization
      ownerLand
      exDept
      gisSource
      districtMunicipalityName
      localMunicipalityName
      ward
      addressee
      suburb
      townCity
      streetAddress
      postalAddress
      telephone
      quintile
      noFeeSchool
      learners
      educators
      myTutorType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      country
      name
      nameASCII
      province
      lat
      lng
      natEmis
      dataYear
      status
      sector
      typePED
      phasePED
      specialization
      ownerLand
      exDept
      gisSource
      districtMunicipalityName
      localMunicipalityName
      ward
      addressee
      suburb
      townCity
      streetAddress
      postalAddress
      telephone
      quintile
      noFeeSchool
      learners
      educators
      myTutorType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      country
      name
      nameASCII
      province
      lat
      lng
      natEmis
      dataYear
      status
      sector
      typePED
      phasePED
      specialization
      ownerLand
      exDept
      gisSource
      districtMunicipalityName
      localMunicipalityName
      ward
      addressee
      suburb
      townCity
      streetAddress
      postalAddress
      telephone
      quintile
      noFeeSchool
      learners
      educators
      myTutorType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrationQuestion = /* GraphQL */ `
  mutation CreateRegistrationQuestion(
    $input: CreateRegistrationQuestionInput!
    $condition: ModelRegistrationQuestionConditionInput
  ) {
    createRegistrationQuestion(input: $input, condition: $condition) {
      id
      edition
      registrationSection
      numberInSection
      question
      answerOptions
      placeholderText
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistrationQuestion = /* GraphQL */ `
  mutation UpdateRegistrationQuestion(
    $input: UpdateRegistrationQuestionInput!
    $condition: ModelRegistrationQuestionConditionInput
  ) {
    updateRegistrationQuestion(input: $input, condition: $condition) {
      id
      edition
      registrationSection
      numberInSection
      question
      answerOptions
      placeholderText
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistrationQuestion = /* GraphQL */ `
  mutation DeleteRegistrationQuestion(
    $input: DeleteRegistrationQuestionInput!
    $condition: ModelRegistrationQuestionConditionInput
  ) {
    deleteRegistrationQuestion(input: $input, condition: $condition) {
      id
      edition
      registrationSection
      numberInSection
      question
      answerOptions
      placeholderText
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrationResponse = /* GraphQL */ `
  mutation CreateRegistrationResponse(
    $input: CreateRegistrationResponseInput!
    $condition: ModelRegistrationResponseConditionInput
  ) {
    createRegistrationResponse(input: $input, condition: $condition) {
      id
      userID
      registrationQuestionID
      response
      createdAt
      updatedAt
      registrationQuestion {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRegistrationResponse = /* GraphQL */ `
  mutation UpdateRegistrationResponse(
    $input: UpdateRegistrationResponseInput!
    $condition: ModelRegistrationResponseConditionInput
  ) {
    updateRegistrationResponse(input: $input, condition: $condition) {
      id
      userID
      registrationQuestionID
      response
      createdAt
      updatedAt
      registrationQuestion {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRegistrationResponse = /* GraphQL */ `
  mutation DeleteRegistrationResponse(
    $input: DeleteRegistrationResponseInput!
    $condition: ModelRegistrationResponseConditionInput
  ) {
    deleteRegistrationResponse(input: $input, condition: $condition) {
      id
      userID
      registrationQuestionID
      response
      createdAt
      updatedAt
      registrationQuestion {
        id
        edition
        registrationSection
        numberInSection
        question
        answerOptions
        placeholderText
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      edition
      purpose
      questionURL
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      canRandomiseOptions
      rating
      coveredInGrade
      organisationID
      createdAt
      updatedAt
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      edition
      purpose
      questionURL
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      canRandomiseOptions
      rating
      coveredInGrade
      organisationID
      createdAt
      updatedAt
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      edition
      purpose
      questionURL
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      canRandomiseOptions
      rating
      coveredInGrade
      organisationID
      createdAt
      updatedAt
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionTag = /* GraphQL */ `
  mutation CreateQuestionTag(
    $input: CreateQuestionTagInput!
    $condition: ModelQuestionTagConditionInput
  ) {
    createQuestionTag(input: $input, condition: $condition) {
      id
      questionID
      tagID
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionTag = /* GraphQL */ `
  mutation UpdateQuestionTag(
    $input: UpdateQuestionTagInput!
    $condition: ModelQuestionTagConditionInput
  ) {
    updateQuestionTag(input: $input, condition: $condition) {
      id
      questionID
      tagID
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionTag = /* GraphQL */ `
  mutation DeleteQuestionTag(
    $input: DeleteQuestionTagInput!
    $condition: ModelQuestionTagConditionInput
  ) {
    deleteQuestionTag(input: $input, condition: $condition) {
      id
      questionID
      tagID
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      baseType
      createdAt
      updatedAt
      taggedQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      baseType
      createdAt
      updatedAt
      taggedQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      baseType
      createdAt
      updatedAt
      taggedQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      studentEmail
      userID
      questionID
      answer
      correct
      eloScoreAfterAnswering
      createdAt
      timezoneOffsetHours
      secondsToAnswer
      answeredInQuestionSet
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      studentEmail
      userID
      questionID
      answer
      correct
      eloScoreAfterAnswering
      createdAt
      timezoneOffsetHours
      secondsToAnswer
      answeredInQuestionSet
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      studentEmail
      userID
      questionID
      answer
      correct
      eloScoreAfterAnswering
      createdAt
      timezoneOffsetHours
      secondsToAnswer
      answeredInQuestionSet
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSolutionView = /* GraphQL */ `
  mutation CreateSolutionView(
    $input: CreateSolutionViewInput!
    $condition: ModelSolutionViewConditionInput
  ) {
    createSolutionView(input: $input, condition: $condition) {
      id
      userID
      questionID
      solutionURL
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateSolutionView = /* GraphQL */ `
  mutation UpdateSolutionView(
    $input: UpdateSolutionViewInput!
    $condition: ModelSolutionViewConditionInput
  ) {
    updateSolutionView(input: $input, condition: $condition) {
      id
      userID
      questionID
      solutionURL
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteSolutionView = /* GraphQL */ `
  mutation DeleteSolutionView(
    $input: DeleteSolutionViewInput!
    $condition: ModelSolutionViewConditionInput
  ) {
    deleteSolutionView(input: $input, condition: $condition) {
      id
      userID
      questionID
      solutionURL
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createEncouragement = /* GraphQL */ `
  mutation CreateEncouragement(
    $input: CreateEncouragementInput!
    $condition: ModelEncouragementConditionInput
  ) {
    createEncouragement(input: $input, condition: $condition) {
      id
      edition
      order
      imageURL
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEncouragement = /* GraphQL */ `
  mutation UpdateEncouragement(
    $input: UpdateEncouragementInput!
    $condition: ModelEncouragementConditionInput
  ) {
    updateEncouragement(input: $input, condition: $condition) {
      id
      edition
      order
      imageURL
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEncouragement = /* GraphQL */ `
  mutation DeleteEncouragement(
    $input: DeleteEncouragementInput!
    $condition: ModelEncouragementConditionInput
  ) {
    deleteEncouragement(input: $input, condition: $condition) {
      id
      edition
      order
      imageURL
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEncouragementReceived = /* GraphQL */ `
  mutation CreateEncouragementReceived(
    $input: CreateEncouragementReceivedInput!
    $condition: ModelEncouragementReceivedConditionInput
  ) {
    createEncouragementReceived(input: $input, condition: $condition) {
      id
      userID
      encouragementID
      totalAnswered
      totalWrong
      createdAt
      updatedAt
      encouragement {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateEncouragementReceived = /* GraphQL */ `
  mutation UpdateEncouragementReceived(
    $input: UpdateEncouragementReceivedInput!
    $condition: ModelEncouragementReceivedConditionInput
  ) {
    updateEncouragementReceived(input: $input, condition: $condition) {
      id
      userID
      encouragementID
      totalAnswered
      totalWrong
      createdAt
      updatedAt
      encouragement {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteEncouragementReceived = /* GraphQL */ `
  mutation DeleteEncouragementReceived(
    $input: DeleteEncouragementReceivedInput!
    $condition: ModelEncouragementReceivedConditionInput
  ) {
    deleteEncouragementReceived(input: $input, condition: $condition) {
      id
      userID
      encouragementID
      totalAnswered
      totalWrong
      createdAt
      updatedAt
      encouragement {
        id
        edition
        order
        imageURL
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createLogEntry = /* GraphQL */ `
  mutation CreateLogEntry(
    $input: CreateLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    createLogEntry(input: $input, condition: $condition) {
      id
      userID
      logType
      registrationQuestionID
      questionID
      text
      images
      validationRegex
      placeholderText
      baseType
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateLogEntry = /* GraphQL */ `
  mutation UpdateLogEntry(
    $input: UpdateLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    updateLogEntry(input: $input, condition: $condition) {
      id
      userID
      logType
      registrationQuestionID
      questionID
      text
      images
      validationRegex
      placeholderText
      baseType
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const deleteLogEntry = /* GraphQL */ `
  mutation DeleteLogEntry(
    $input: DeleteLogEntryInput!
    $condition: ModelLogEntryConditionInput
  ) {
    deleteLogEntry(input: $input, condition: $condition) {
      id
      userID
      logType
      registrationQuestionID
      questionID
      text
      images
      validationRegex
      placeholderText
      baseType
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const createDailyPuzzle = /* GraphQL */ `
  mutation CreateDailyPuzzle(
    $input: CreateDailyPuzzleInput!
    $condition: ModelDailyPuzzleConditionInput
  ) {
    createDailyPuzzle(input: $input, condition: $condition) {
      id
      date
      questionID
      language
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateDailyPuzzle = /* GraphQL */ `
  mutation UpdateDailyPuzzle(
    $input: UpdateDailyPuzzleInput!
    $condition: ModelDailyPuzzleConditionInput
  ) {
    updateDailyPuzzle(input: $input, condition: $condition) {
      id
      date
      questionID
      language
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteDailyPuzzle = /* GraphQL */ `
  mutation DeleteDailyPuzzle(
    $input: DeleteDailyPuzzleInput!
    $condition: ModelDailyPuzzleConditionInput
  ) {
    deleteDailyPuzzle(input: $input, condition: $condition) {
      id
      date
      questionID
      language
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createHomePageEvent = /* GraphQL */ `
  mutation CreateHomePageEvent(
    $input: CreateHomePageEventInput!
    $condition: ModelHomePageEventConditionInput
  ) {
    createHomePageEvent(input: $input, condition: $condition) {
      id
      name
      hostedByMyTutor
      imageURL
      entryURL
      startDate
      dateMessage
      displayFrom
      displayTo
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHomePageEvent = /* GraphQL */ `
  mutation UpdateHomePageEvent(
    $input: UpdateHomePageEventInput!
    $condition: ModelHomePageEventConditionInput
  ) {
    updateHomePageEvent(input: $input, condition: $condition) {
      id
      name
      hostedByMyTutor
      imageURL
      entryURL
      startDate
      dateMessage
      displayFrom
      displayTo
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHomePageEvent = /* GraphQL */ `
  mutation DeleteHomePageEvent(
    $input: DeleteHomePageEventInput!
    $condition: ModelHomePageEventConditionInput
  ) {
    deleteHomePageEvent(input: $input, condition: $condition) {
      id
      name
      hostedByMyTutor
      imageURL
      entryURL
      startDate
      dateMessage
      displayFrom
      displayTo
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWorksheet = /* GraphQL */ `
  mutation CreateWorksheet(
    $input: CreateWorksheetInput!
    $condition: ModelWorksheetConditionInput
  ) {
    createWorksheet(input: $input, condition: $condition) {
      id
      name
      tag
      instructions
      nextQuestionStrategy
      progression
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorksheet = /* GraphQL */ `
  mutation UpdateWorksheet(
    $input: UpdateWorksheetInput!
    $condition: ModelWorksheetConditionInput
  ) {
    updateWorksheet(input: $input, condition: $condition) {
      id
      name
      tag
      instructions
      nextQuestionStrategy
      progression
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorksheet = /* GraphQL */ `
  mutation DeleteWorksheet(
    $input: DeleteWorksheetInput!
    $condition: ModelWorksheetConditionInput
  ) {
    deleteWorksheet(input: $input, condition: $condition) {
      id
      name
      tag
      instructions
      nextQuestionStrategy
      progression
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWorksheetEntry = /* GraphQL */ `
  mutation CreateWorksheetEntry(
    $input: CreateWorksheetEntryInput!
    $condition: ModelWorksheetEntryConditionInput
  ) {
    createWorksheetEntry(input: $input, condition: $condition) {
      id
      worksheetID
      userID
      currentLevel
      startedAt
      finishedAt
      streak
      answered
      correct
      createdAt
      owner
      updatedAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateWorksheetEntry = /* GraphQL */ `
  mutation UpdateWorksheetEntry(
    $input: UpdateWorksheetEntryInput!
    $condition: ModelWorksheetEntryConditionInput
  ) {
    updateWorksheetEntry(input: $input, condition: $condition) {
      id
      worksheetID
      userID
      currentLevel
      startedAt
      finishedAt
      streak
      answered
      correct
      createdAt
      owner
      updatedAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteWorksheetEntry = /* GraphQL */ `
  mutation DeleteWorksheetEntry(
    $input: DeleteWorksheetEntryInput!
    $condition: ModelWorksheetEntryConditionInput
  ) {
    deleteWorksheetEntry(input: $input, condition: $condition) {
      id
      worksheetID
      userID
      currentLevel
      startedAt
      finishedAt
      streak
      answered
      correct
      createdAt
      owner
      updatedAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createWorksheetAnswer = /* GraphQL */ `
  mutation CreateWorksheetAnswer(
    $input: CreateWorksheetAnswerInput!
    $condition: ModelWorksheetAnswerConditionInput
  ) {
    createWorksheetAnswer(input: $input, condition: $condition) {
      id
      worksheetEntryID
      questionID
      number
      answer
      answeredAt
      correct
      createdAt
      owner
      updatedAt
      worksheetEntry {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateWorksheetAnswer = /* GraphQL */ `
  mutation UpdateWorksheetAnswer(
    $input: UpdateWorksheetAnswerInput!
    $condition: ModelWorksheetAnswerConditionInput
  ) {
    updateWorksheetAnswer(input: $input, condition: $condition) {
      id
      worksheetEntryID
      questionID
      number
      answer
      answeredAt
      correct
      createdAt
      owner
      updatedAt
      worksheetEntry {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteWorksheetAnswer = /* GraphQL */ `
  mutation DeleteWorksheetAnswer(
    $input: DeleteWorksheetAnswerInput!
    $condition: ModelWorksheetAnswerConditionInput
  ) {
    deleteWorksheetAnswer(input: $input, condition: $condition) {
      id
      worksheetEntryID
      questionID
      number
      answer
      answeredAt
      correct
      createdAt
      owner
      updatedAt
      worksheetEntry {
        id
        worksheetID
        userID
        currentLevel
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
        __typename
      }
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      organiserRegisteredEmail {
        subject
        content
        __typename
      }
      createdAt
      updatedAt
      entries {
        nextToken
        __typename
      }
      questionSets {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      organiserRegisteredEmail {
        subject
        content
        __typename
      }
      createdAt
      updatedAt
      entries {
        nextToken
        __typename
      }
      questionSets {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      organiserRegisteredEmail {
        subject
        content
        __typename
      }
      createdAt
      updatedAt
      entries {
        nextToken
        __typename
      }
      questionSets {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createEventEntry = /* GraphQL */ `
  mutation CreateEventEntry(
    $input: CreateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    createEventEntry(input: $input, condition: $condition) {
      id
      eventID
      externalSchoolIdentifier
      numberOfEntries
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventEntry = /* GraphQL */ `
  mutation UpdateEventEntry(
    $input: UpdateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    updateEventEntry(input: $input, condition: $condition) {
      id
      eventID
      externalSchoolIdentifier
      numberOfEntries
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventEntry = /* GraphQL */ `
  mutation DeleteEventEntry(
    $input: DeleteEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    deleteEventEntry(input: $input, condition: $condition) {
      id
      eventID
      externalSchoolIdentifier
      numberOfEntries
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestionSet = /* GraphQL */ `
  mutation CreateQuestionSet(
    $input: CreateQuestionSetInput!
    $condition: ModelQuestionSetConditionInput
  ) {
    createQuestionSet(input: $input, condition: $condition) {
      id
      name
      organisationID
      eventID
      logo
      publicOrPrivate
      minGrade
      maxGrade
      rating
      suggestedDuration
      registrationStart
      registrationEnd
      registrationRequiresPriorTest
      registrationConfirmationEmail {
        subject
        content
        __typename
      }
      allowRegistrationWithoutAccount
      participationStart
      participationEnd
      invigilation
      instructions
      termsAndConditionsURL
      category
      format
      adaptiveTestConfiguration {
        progression
        maxQuestions
        maxSkips
        __typename
      }
      totalPoints
      markingConfiguration {
        whenToShow
        whenToShowDate
        negativeMarking
        notifyByEmail
        __typename
      }
      createdByID
      createdByEmail {
        subject
        content
        __typename
      }
      updateUserELOAfterTestEnded
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      formulaSheetSlides {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      nextQuestionSets {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionSet = /* GraphQL */ `
  mutation UpdateQuestionSet(
    $input: UpdateQuestionSetInput!
    $condition: ModelQuestionSetConditionInput
  ) {
    updateQuestionSet(input: $input, condition: $condition) {
      id
      name
      organisationID
      eventID
      logo
      publicOrPrivate
      minGrade
      maxGrade
      rating
      suggestedDuration
      registrationStart
      registrationEnd
      registrationRequiresPriorTest
      registrationConfirmationEmail {
        subject
        content
        __typename
      }
      allowRegistrationWithoutAccount
      participationStart
      participationEnd
      invigilation
      instructions
      termsAndConditionsURL
      category
      format
      adaptiveTestConfiguration {
        progression
        maxQuestions
        maxSkips
        __typename
      }
      totalPoints
      markingConfiguration {
        whenToShow
        whenToShowDate
        negativeMarking
        notifyByEmail
        __typename
      }
      createdByID
      createdByEmail {
        subject
        content
        __typename
      }
      updateUserELOAfterTestEnded
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      formulaSheetSlides {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      nextQuestionSets {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionSet = /* GraphQL */ `
  mutation DeleteQuestionSet(
    $input: DeleteQuestionSetInput!
    $condition: ModelQuestionSetConditionInput
  ) {
    deleteQuestionSet(input: $input, condition: $condition) {
      id
      name
      organisationID
      eventID
      logo
      publicOrPrivate
      minGrade
      maxGrade
      rating
      suggestedDuration
      registrationStart
      registrationEnd
      registrationRequiresPriorTest
      registrationConfirmationEmail {
        subject
        content
        __typename
      }
      allowRegistrationWithoutAccount
      participationStart
      participationEnd
      invigilation
      instructions
      termsAndConditionsURL
      category
      format
      adaptiveTestConfiguration {
        progression
        maxQuestions
        maxSkips
        __typename
      }
      totalPoints
      markingConfiguration {
        whenToShow
        whenToShowDate
        negativeMarking
        notifyByEmail
        __typename
      }
      createdByID
      createdByEmail {
        subject
        content
        __typename
      }
      updateUserELOAfterTestEnded
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      formulaSheetSlides {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      nextQuestionSets {
        nextToken
        __typename
      }
      tests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionSetSection = /* GraphQL */ `
  mutation CreateQuestionSetSection(
    $input: CreateQuestionSetSectionInput!
    $condition: ModelQuestionSetSectionConditionInput
  ) {
    createQuestionSetSection(input: $input, condition: $condition) {
      id
      questionSetID
      number
      suggestedDuration
      createdAt
      updatedAt
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionSetSection = /* GraphQL */ `
  mutation UpdateQuestionSetSection(
    $input: UpdateQuestionSetSectionInput!
    $condition: ModelQuestionSetSectionConditionInput
  ) {
    updateQuestionSetSection(input: $input, condition: $condition) {
      id
      questionSetID
      number
      suggestedDuration
      createdAt
      updatedAt
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionSetSection = /* GraphQL */ `
  mutation DeleteQuestionSetSection(
    $input: DeleteQuestionSetSectionInput!
    $condition: ModelQuestionSetSectionConditionInput
  ) {
    deleteQuestionSetSection(input: $input, condition: $condition) {
      id
      questionSetID
      number
      suggestedDuration
      createdAt
      updatedAt
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionSetQuestion = /* GraphQL */ `
  mutation CreateQuestionSetQuestion(
    $input: CreateQuestionSetQuestionInput!
    $condition: ModelQuestionSetQuestionConditionInput
  ) {
    createQuestionSetQuestion(input: $input, condition: $condition) {
      id
      questionSetSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionSetQuestion = /* GraphQL */ `
  mutation UpdateQuestionSetQuestion(
    $input: UpdateQuestionSetQuestionInput!
    $condition: ModelQuestionSetQuestionConditionInput
  ) {
    updateQuestionSetQuestion(input: $input, condition: $condition) {
      id
      questionSetSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionSetQuestion = /* GraphQL */ `
  mutation DeleteQuestionSetQuestion(
    $input: DeleteQuestionSetQuestionInput!
    $condition: ModelQuestionSetQuestionConditionInput
  ) {
    deleteQuestionSetQuestion(input: $input, condition: $condition) {
      id
      questionSetSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionSetFormulaSheetSlide = /* GraphQL */ `
  mutation CreateQuestionSetFormulaSheetSlide(
    $input: CreateQuestionSetFormulaSheetSlideInput!
    $condition: ModelQuestionSetFormulaSheetSlideConditionInput
  ) {
    createQuestionSetFormulaSheetSlide(input: $input, condition: $condition) {
      id
      number
      questionSetID
      slideURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestionSetFormulaSheetSlide = /* GraphQL */ `
  mutation UpdateQuestionSetFormulaSheetSlide(
    $input: UpdateQuestionSetFormulaSheetSlideInput!
    $condition: ModelQuestionSetFormulaSheetSlideConditionInput
  ) {
    updateQuestionSetFormulaSheetSlide(input: $input, condition: $condition) {
      id
      number
      questionSetID
      slideURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestionSetFormulaSheetSlide = /* GraphQL */ `
  mutation DeleteQuestionSetFormulaSheetSlide(
    $input: DeleteQuestionSetFormulaSheetSlideInput!
    $condition: ModelQuestionSetFormulaSheetSlideConditionInput
  ) {
    deleteQuestionSetFormulaSheetSlide(input: $input, condition: $condition) {
      id
      number
      questionSetID
      slideURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestionSetPracticeQuestion = /* GraphQL */ `
  mutation CreateQuestionSetPracticeQuestion(
    $input: CreateQuestionSetPracticeQuestionInput!
    $condition: ModelQuestionSetPracticeQuestionConditionInput
  ) {
    createQuestionSetPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      questionSetID
      questionID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestionSetPracticeQuestion = /* GraphQL */ `
  mutation UpdateQuestionSetPracticeQuestion(
    $input: UpdateQuestionSetPracticeQuestionInput!
    $condition: ModelQuestionSetPracticeQuestionConditionInput
  ) {
    updateQuestionSetPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      questionSetID
      questionID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestionSetPracticeQuestion = /* GraphQL */ `
  mutation DeleteQuestionSetPracticeQuestion(
    $input: DeleteQuestionSetPracticeQuestionInput!
    $condition: ModelQuestionSetPracticeQuestionConditionInput
  ) {
    deleteQuestionSetPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      questionSetID
      questionID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestionSetProgression = /* GraphQL */ `
  mutation CreateQuestionSetProgression(
    $input: CreateQuestionSetProgressionInput!
    $condition: ModelQuestionSetProgressionConditionInput
  ) {
    createQuestionSetProgression(input: $input, condition: $condition) {
      id
      fromQuestionSetID
      toQuestionSetID
      markFrom
      markTo
      email {
        subject
        content
        __typename
      }
      message
      baseType
      createdAt
      updatedAt
      from {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionSetProgression = /* GraphQL */ `
  mutation UpdateQuestionSetProgression(
    $input: UpdateQuestionSetProgressionInput!
    $condition: ModelQuestionSetProgressionConditionInput
  ) {
    updateQuestionSetProgression(input: $input, condition: $condition) {
      id
      fromQuestionSetID
      toQuestionSetID
      markFrom
      markTo
      email {
        subject
        content
        __typename
      }
      message
      baseType
      createdAt
      updatedAt
      from {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionSetProgression = /* GraphQL */ `
  mutation DeleteQuestionSetProgression(
    $input: DeleteQuestionSetProgressionInput!
    $condition: ModelQuestionSetProgressionConditionInput
  ) {
    deleteQuestionSetProgression(input: $input, condition: $condition) {
      id
      fromQuestionSetID
      toQuestionSetID
      markFrom
      markTo
      email {
        subject
        content
        __typename
      }
      message
      baseType
      createdAt
      updatedAt
      from {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      to {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createQuestionSetScheduler = /* GraphQL */ `
  mutation CreateQuestionSetScheduler(
    $input: CreateQuestionSetSchedulerInput!
    $condition: ModelQuestionSetSchedulerConditionInput
  ) {
    createQuestionSetScheduler(input: $input, condition: $condition) {
      id
      questionSetID
      studentEmail
      userID
      authorisedByID
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateQuestionSetScheduler = /* GraphQL */ `
  mutation UpdateQuestionSetScheduler(
    $input: UpdateQuestionSetSchedulerInput!
    $condition: ModelQuestionSetSchedulerConditionInput
  ) {
    updateQuestionSetScheduler(input: $input, condition: $condition) {
      id
      questionSetID
      studentEmail
      userID
      authorisedByID
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteQuestionSetScheduler = /* GraphQL */ `
  mutation DeleteQuestionSetScheduler(
    $input: DeleteQuestionSetSchedulerInput!
    $condition: ModelQuestionSetSchedulerConditionInput
  ) {
    deleteQuestionSetScheduler(input: $input, condition: $condition) {
      id
      questionSetID
      studentEmail
      userID
      authorisedByID
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      questionSetID
      organisationID
      name
      minGrade
      maxGrade
      date
      totalRegistered
      totalStarted
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      testHosts {
        nextToken
        __typename
      }
      entries {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      questionSetID
      organisationID
      name
      minGrade
      maxGrade
      date
      totalRegistered
      totalStarted
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      testHosts {
        nextToken
        __typename
      }
      entries {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      questionSetID
      organisationID
      name
      minGrade
      maxGrade
      date
      totalRegistered
      totalStarted
      baseType
      createdAt
      updatedAt
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      testHosts {
        nextToken
        __typename
      }
      entries {
        nextToken
        __typename
      }
      practiceQuestions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestSection = /* GraphQL */ `
  mutation CreateTestSection(
    $input: CreateTestSectionInput!
    $condition: ModelTestSectionConditionInput
  ) {
    createTestSection(input: $input, condition: $condition) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestSection = /* GraphQL */ `
  mutation UpdateTestSection(
    $input: UpdateTestSectionInput!
    $condition: ModelTestSectionConditionInput
  ) {
    updateTestSection(input: $input, condition: $condition) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestSection = /* GraphQL */ `
  mutation DeleteTestSection(
    $input: DeleteTestSectionInput!
    $condition: ModelTestSectionConditionInput
  ) {
    deleteTestSection(input: $input, condition: $condition) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestSectionPerformance = /* GraphQL */ `
  mutation CreateTestSectionPerformance(
    $input: CreateTestSectionPerformanceInput!
    $condition: ModelTestSectionPerformanceConditionInput
  ) {
    createTestSectionPerformance(input: $input, condition: $condition) {
      id
      testSectionID
      totalStarted
      totalSubmitted
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTestSectionPerformance = /* GraphQL */ `
  mutation UpdateTestSectionPerformance(
    $input: UpdateTestSectionPerformanceInput!
    $condition: ModelTestSectionPerformanceConditionInput
  ) {
    updateTestSectionPerformance(input: $input, condition: $condition) {
      id
      testSectionID
      totalStarted
      totalSubmitted
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTestSectionPerformance = /* GraphQL */ `
  mutation DeleteTestSectionPerformance(
    $input: DeleteTestSectionPerformanceInput!
    $condition: ModelTestSectionPerformanceConditionInput
  ) {
    deleteTestSectionPerformance(input: $input, condition: $condition) {
      id
      testSectionID
      totalStarted
      totalSubmitted
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTestQuestion = /* GraphQL */ `
  mutation CreateTestQuestion(
    $input: CreateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    createTestQuestion(input: $input, condition: $condition) {
      id
      testSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestQuestion = /* GraphQL */ `
  mutation UpdateTestQuestion(
    $input: UpdateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    updateTestQuestion(input: $input, condition: $condition) {
      id
      testSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestQuestion = /* GraphQL */ `
  mutation DeleteTestQuestion(
    $input: DeleteTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    deleteTestQuestion(input: $input, condition: $condition) {
      id
      testSectionID
      questionID
      number
      points
      level
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestQuestionPerformance = /* GraphQL */ `
  mutation CreateTestQuestionPerformance(
    $input: CreateTestQuestionPerformanceInput!
    $condition: ModelTestQuestionPerformanceConditionInput
  ) {
    createTestQuestionPerformance(input: $input, condition: $condition) {
      id
      testQuestionID
      totalAnswered
      answerCounts
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTestQuestionPerformance = /* GraphQL */ `
  mutation UpdateTestQuestionPerformance(
    $input: UpdateTestQuestionPerformanceInput!
    $condition: ModelTestQuestionPerformanceConditionInput
  ) {
    updateTestQuestionPerformance(input: $input, condition: $condition) {
      id
      testQuestionID
      totalAnswered
      answerCounts
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTestQuestionPerformance = /* GraphQL */ `
  mutation DeleteTestQuestionPerformance(
    $input: DeleteTestQuestionPerformanceInput!
    $condition: ModelTestQuestionPerformanceConditionInput
  ) {
    deleteTestQuestionPerformance(input: $input, condition: $condition) {
      id
      testQuestionID
      totalAnswered
      answerCounts
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTestHost = /* GraphQL */ `
  mutation CreateTestHost(
    $input: CreateTestHostInput!
    $condition: ModelTestHostConditionInput
  ) {
    createTestHost(input: $input, condition: $condition) {
      id
      testID
      teacherID
      userID
      linkCode
      testDate
      owner
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestHost = /* GraphQL */ `
  mutation UpdateTestHost(
    $input: UpdateTestHostInput!
    $condition: ModelTestHostConditionInput
  ) {
    updateTestHost(input: $input, condition: $condition) {
      id
      testID
      teacherID
      userID
      linkCode
      testDate
      owner
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestHost = /* GraphQL */ `
  mutation DeleteTestHost(
    $input: DeleteTestHostInput!
    $condition: ModelTestHostConditionInput
  ) {
    deleteTestHost(input: $input, condition: $condition) {
      id
      testID
      teacherID
      userID
      linkCode
      testDate
      owner
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestMarker = /* GraphQL */ `
  mutation CreateTestMarker(
    $input: CreateTestMarkerInput!
    $condition: ModelTestMarkerConditionInput
  ) {
    createTestMarker(input: $input, condition: $condition) {
      id
      testID
      userID
      baseType
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateTestMarker = /* GraphQL */ `
  mutation UpdateTestMarker(
    $input: UpdateTestMarkerInput!
    $condition: ModelTestMarkerConditionInput
  ) {
    updateTestMarker(input: $input, condition: $condition) {
      id
      testID
      userID
      baseType
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestMarker = /* GraphQL */ `
  mutation DeleteTestMarker(
    $input: DeleteTestMarkerInput!
    $condition: ModelTestMarkerConditionInput
  ) {
    deleteTestMarker(input: $input, condition: $condition) {
      id
      testID
      userID
      baseType
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTestEntry = /* GraphQL */ `
  mutation CreateTestEntry(
    $input: CreateTestEntryInput!
    $condition: ModelTestEntryConditionInput
  ) {
    createTestEntry(input: $input, condition: $condition) {
      id
      testID
      testHostID
      userID
      school
      score
      scoreAndTime
      currentLevel
      questionsSkipped
      questionsAnswered
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      affiliate
      showOnLeaderboard
      baseType
      marked
      manuallyMarkedDate
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testHost {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      entrySections {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestEntry = /* GraphQL */ `
  mutation UpdateTestEntry(
    $input: UpdateTestEntryInput!
    $condition: ModelTestEntryConditionInput
  ) {
    updateTestEntry(input: $input, condition: $condition) {
      id
      testID
      testHostID
      userID
      school
      score
      scoreAndTime
      currentLevel
      questionsSkipped
      questionsAnswered
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      affiliate
      showOnLeaderboard
      baseType
      marked
      manuallyMarkedDate
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testHost {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      entrySections {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestEntry = /* GraphQL */ `
  mutation DeleteTestEntry(
    $input: DeleteTestEntryInput!
    $condition: ModelTestEntryConditionInput
  ) {
    deleteTestEntry(input: $input, condition: $condition) {
      id
      testID
      testHostID
      userID
      school
      score
      scoreAndTime
      currentLevel
      questionsSkipped
      questionsAnswered
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      affiliate
      showOnLeaderboard
      baseType
      marked
      manuallyMarkedDate
      createdAt
      owner
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testHost {
        id
        testID
        teacherID
        userID
        linkCode
        testDate
        owner
        baseType
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      entrySections {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestEntrySection = /* GraphQL */ `
  mutation CreateTestEntrySection(
    $input: CreateTestEntrySectionInput!
    $condition: ModelTestEntrySectionConditionInput
  ) {
    createTestEntrySection(input: $input, condition: $condition) {
      id
      userID
      testEntryID
      testSectionID
      questionsToAsk {
        level
        testQuestionID
        __typename
      }
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      startedAt
      timeTaken
      baseType
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      testAnswers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestEntrySection = /* GraphQL */ `
  mutation UpdateTestEntrySection(
    $input: UpdateTestEntrySectionInput!
    $condition: ModelTestEntrySectionConditionInput
  ) {
    updateTestEntrySection(input: $input, condition: $condition) {
      id
      userID
      testEntryID
      testSectionID
      questionsToAsk {
        level
        testQuestionID
        __typename
      }
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      startedAt
      timeTaken
      baseType
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      testAnswers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestEntrySection = /* GraphQL */ `
  mutation DeleteTestEntrySection(
    $input: DeleteTestEntrySectionInput!
    $condition: ModelTestEntrySectionConditionInput
  ) {
    deleteTestEntrySection(input: $input, condition: $condition) {
      id
      userID
      testEntryID
      testSectionID
      questionsToAsk {
        level
        testQuestionID
        __typename
      }
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      startedAt
      timeTaken
      baseType
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testSection {
        id
        testID
        number
        duration
        startedAt
        ended
        scheduledToEndAt
        endedAt
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      testAnswers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTestAnswer = /* GraphQL */ `
  mutation CreateTestAnswer(
    $input: CreateTestAnswerInput!
    $condition: ModelTestAnswerConditionInput
  ) {
    createTestAnswer(input: $input, condition: $condition) {
      id
      userID
      testQuestionID
      testEntrySectionID
      answer
      answerPhotoS3Key
      answeredDate
      correct
      score
      markersComments
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testQuestion {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      testEntrySection {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateTestAnswer = /* GraphQL */ `
  mutation UpdateTestAnswer(
    $input: UpdateTestAnswerInput!
    $condition: ModelTestAnswerConditionInput
  ) {
    updateTestAnswer(input: $input, condition: $condition) {
      id
      userID
      testQuestionID
      testEntrySectionID
      answer
      answerPhotoS3Key
      answeredDate
      correct
      score
      markersComments
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testQuestion {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      testEntrySection {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestAnswer = /* GraphQL */ `
  mutation DeleteTestAnswer(
    $input: DeleteTestAnswerInput!
    $condition: ModelTestAnswerConditionInput
  ) {
    deleteTestAnswer(input: $input, condition: $condition) {
      id
      userID
      testQuestionID
      testEntrySectionID
      answer
      answerPhotoS3Key
      answeredDate
      correct
      score
      markersComments
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testQuestion {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      testEntrySection {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTestPracticeQuestion = /* GraphQL */ `
  mutation CreateTestPracticeQuestion(
    $input: CreateTestPracticeQuestionInput!
    $condition: ModelTestPracticeQuestionConditionInput
  ) {
    createTestPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      testID
      questionID
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateTestPracticeQuestion = /* GraphQL */ `
  mutation UpdateTestPracticeQuestion(
    $input: UpdateTestPracticeQuestionInput!
    $condition: ModelTestPracticeQuestionConditionInput
  ) {
    updateTestPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      testID
      questionID
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestPracticeQuestion = /* GraphQL */ `
  mutation DeleteTestPracticeQuestion(
    $input: DeleteTestPracticeQuestionInput!
    $condition: ModelTestPracticeQuestionConditionInput
  ) {
    deleteTestPracticeQuestion(input: $input, condition: $condition) {
      id
      number
      testID
      questionID
      baseType
      createdAt
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTestEntryELORecalc = /* GraphQL */ `
  mutation CreateTestEntryELORecalc(
    $input: CreateTestEntryELORecalcInput!
    $condition: ModelTestEntryELORecalcConditionInput
  ) {
    createTestEntryELORecalc(input: $input, condition: $condition) {
      id
      userID
      questionSetID
      testEntryID
      eloBefore
      eloAfter
      questionsAnsweredBeforeTest
      questionsInTest
      testDifficulty
      score
      parScore
      inertia
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateTestEntryELORecalc = /* GraphQL */ `
  mutation UpdateTestEntryELORecalc(
    $input: UpdateTestEntryELORecalcInput!
    $condition: ModelTestEntryELORecalcConditionInput
  ) {
    updateTestEntryELORecalc(input: $input, condition: $condition) {
      id
      userID
      questionSetID
      testEntryID
      eloBefore
      eloAfter
      questionsAnsweredBeforeTest
      questionsInTest
      testDifficulty
      score
      parScore
      inertia
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteTestEntryELORecalc = /* GraphQL */ `
  mutation DeleteTestEntryELORecalc(
    $input: DeleteTestEntryELORecalcInput!
    $condition: ModelTestEntryELORecalcConditionInput
  ) {
    deleteTestEntryELORecalc(input: $input, condition: $condition) {
      id
      userID
      questionSetID
      testEntryID
      eloBefore
      eloAfter
      questionsAnsweredBeforeTest
      questionsInTest
      testDifficulty
      score
      parScore
      inertia
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntry {
        id
        testID
        testHostID
        userID
        school
        score
        scoreAndTime
        currentLevel
        questionsSkipped
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        affiliate
        showOnLeaderboard
        baseType
        marked
        manuallyMarkedDate
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createFileDownloaded = /* GraphQL */ `
  mutation CreateFileDownloaded(
    $input: CreateFileDownloadedInput!
    $condition: ModelFileDownloadedConditionInput
  ) {
    createFileDownloaded(input: $input, condition: $condition) {
      id
      userID
      fileURL
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateFileDownloaded = /* GraphQL */ `
  mutation UpdateFileDownloaded(
    $input: UpdateFileDownloadedInput!
    $condition: ModelFileDownloadedConditionInput
  ) {
    updateFileDownloaded(input: $input, condition: $condition) {
      id
      userID
      fileURL
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const deleteFileDownloaded = /* GraphQL */ `
  mutation DeleteFileDownloaded(
    $input: DeleteFileDownloadedInput!
    $condition: ModelFileDownloadedConditionInput
  ) {
    deleteFileDownloaded(input: $input, condition: $condition) {
      id
      userID
      fileURL
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const createAndSetupUser = /* GraphQL */ `
  mutation CreateAndSetupUser(
    $owner: String!
    $username: String!
    $firstName: String!
    $surname: String!
    $email: AWSEmail
  ) {
    createAndSetupUser(
      owner: $owner
      username: $username
      firstName: $firstName
      surname: $surname
      email: $email
    ) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const personaliseUser = /* GraphQL */ `
  mutation PersonaliseUser(
    $userID: ID!
    $language: Language!
    $type: StudentType!
    $country: String!
    $teacherEmail: String
    $school: String
    $grade: Int
    $mathsMark: Int
    $nickname: String
    $gender: Gender
    $race: Race
  ) {
    personaliseUser(
      userID: $userID
      language: $language
      type: $type
      country: $country
      teacherEmail: $teacherEmail
      school: $school
      grade: $grade
      mathsMark: $mathsMark
      nickname: $nickname
      gender: $gender
      race: $race
    ) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateUserMarketingSubscription = /* GraphQL */ `
  mutation UpdateUserMarketingSubscription(
    $userID: ID!
    $subscribed: Boolean!
  ) {
    updateUserMarketingSubscription(userID: $userID, subscribed: $subscribed)
  }
`;
export const migrateCognitoUser = /* GraphQL */ `
  mutation MigrateCognitoUser($username: String!, $password: String!) {
    migrateCognitoUser(username: $username, password: $password)
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username)
  }
`;
export const linkUsers = /* GraphQL */ `
  mutation LinkUsers($userID: ID!, $linkCode: String!) {
    linkUsers(userID: $userID, linkCode: $linkCode) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const unlinkUsers = /* GraphQL */ `
  mutation UnlinkUsers($userID: ID!, $friendIDs: [String!]!) {
    unlinkUsers(userID: $userID, friendIDs: $friendIDs)
  }
`;
export const updateStudentGrade = /* GraphQL */ `
  mutation UpdateStudentGrade($userID: ID!, $grade: Int) {
    updateStudentGrade(userID: $userID, grade: $grade) {
      id
      username
      email
      notificationEmail
      receiveMarketingEmails
      state
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      oldAccount
      country
      school
      gender
      race
      owner
      cognitoIdentityID
      linkCode
      baseType
      createdAt
      updatedAt
      metrics {
        nextToken
        __typename
      }
      friends {
        nextToken
        __typename
      }
      isFriendOf {
        nextToken
        __typename
      }
      registrationInfo {
        nextToken
        __typename
      }
      responses {
        nextToken
        __typename
      }
      encouragementReceived {
        nextToken
        __typename
      }
      log {
        nextToken
        __typename
      }
      worksheetEntries {
        nextToken
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const registerEntrantForTest = /* GraphQL */ `
  mutation RegisterEntrantForTest($userID: ID!, $linkCode: String!) {
    registerEntrantForTest(userID: $userID, linkCode: $linkCode) {
      testHostID
      testName
      testDate
      participationStart
      organisation {
        id
        name
        logo
        schoolId
        createdAt
        updatedAt
        __typename
      }
      entry {
        testName
        id
        score
        scoreAndTime
        questionsAnswered
        timeTaken
        email
        firstName
        surname
        nickname
        grade
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const registerForTestWithoutAccount = /* GraphQL */ `
  mutation RegisterForTestWithoutAccount(
    $firstName: String!
    $surname: String!
    $country: String!
    $school: String!
    $grade: Int!
    $showOnLeaderboard: Boolean
    $notificationEmail: AWSEmail
    $testHostLinkCode: String!
    $teacherLinkCode: String
    $affiliate: String
  ) {
    registerForTestWithoutAccount(
      firstName: $firstName
      surname: $surname
      country: $country
      school: $school
      grade: $grade
      showOnLeaderboard: $showOnLeaderboard
      notificationEmail: $notificationEmail
      testHostLinkCode: $testHostLinkCode
      teacherLinkCode: $teacherLinkCode
      affiliate: $affiliate
    ) {
      email
      password
      testHostID
      testEntryID
      __typename
    }
  }
`;
export const saveTestAnswer = /* GraphQL */ `
  mutation SaveTestAnswer(
    $testAnswerID: ID!
    $answer: String
    $answerPhotoS3Key: String
  ) {
    saveTestAnswer(
      testAnswerID: $testAnswerID
      answer: $answer
      answerPhotoS3Key: $answerPhotoS3Key
    ) {
      id
      number
      points
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      answer
      answerPhotoS3Key
      answerDate
      correct
      score
      markersComments
      performance {
        id
        testQuestionID
        totalAnswered
        answerCounts
        __typename
      }
      __typename
    }
  }
`;
export const skipQuestionAdaptiveTest = /* GraphQL */ `
  mutation SkipQuestionAdaptiveTest($testEntrySectionID: ID!) {
    skipQuestionAdaptiveTest(testEntrySectionID: $testEntrySectionID) {
      id
      number
      points
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      clueURL
      layout
      answer
      answerPhotoS3Key
      answerDate
      correct
      score
      markersComments
      performance {
        id
        testQuestionID
        totalAnswered
        answerCounts
        __typename
      }
      __typename
    }
  }
`;
export const startSectionAt = /* GraphQL */ `
  mutation StartSectionAt(
    $teacherID: ID!
    $testSectionID: ID!
    $startAt: AWSDateTime!
  ) {
    startSectionAt(
      teacherID: $teacherID
      testSectionID: $testSectionID
      startAt: $startAt
    ) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const endSection = /* GraphQL */ `
  mutation EndSection($teacherID: ID!, $testSectionID: ID!) {
    endSection(teacherID: $teacherID, testSectionID: $testSectionID) {
      id
      testID
      number
      duration
      startedAt
      ended
      scheduledToEndAt
      endedAt
      createdAt
      updatedAt
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      performance {
        nextToken
        __typename
      }
      questions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const entrantEndsTest = /* GraphQL */ `
  mutation EntrantEndsTest(
    $testEntrySectionID: ID!
    $markerSubmittingTest: Boolean
  ) {
    entrantEndsTest(
      testEntrySectionID: $testEntrySectionID
      markerSubmittingTest: $markerSubmittingTest
    ) {
      score
      message
      __typename
    }
  }
`;
export const createTestAndHostFromQuestionSet = /* GraphQL */ `
  mutation CreateTestAndHostFromQuestionSet(
    $questionSetID: ID!
    $name: String!
    $date: AWSDateTime!
    $teacherID: ID!
  ) {
    createTestAndHostFromQuestionSet(
      questionSetID: $questionSetID
      name: $name
      date: $date
      teacherID: $teacherID
    ) {
      id
      testID
      teacherID
      userID
      linkCode
      testDate
      owner
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      test {
        id
        questionSetID
        organisationID
        name
        minGrade
        maxGrade
        date
        totalRegistered
        totalStarted
        baseType
        createdAt
        updatedAt
        __typename
      }
      testEntries {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTestDate = /* GraphQL */ `
  mutation UpdateTestDate($testHostID: ID!, $testID: ID!, $date: AWSDateTime!) {
    updateTestDate(testHostID: $testHostID, testID: $testID, date: $date)
  }
`;
export const markTestAnswer = /* GraphQL */ `
  mutation MarkTestAnswer(
    $testAnswerID: ID!
    $correct: Boolean
    $score: Float
    $markersComments: String
  ) {
    markTestAnswer(
      testAnswerID: $testAnswerID
      correct: $correct
      score: $score
      markersComments: $markersComments
    ) {
      id
      userID
      testQuestionID
      testEntrySectionID
      answer
      answerPhotoS3Key
      answeredDate
      correct
      score
      markersComments
      createdAt
      owner
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      testQuestion {
        id
        testSectionID
        questionID
        number
        points
        level
        createdAt
        updatedAt
        __typename
      }
      testEntrySection {
        id
        userID
        testEntryID
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        startedAt
        timeTaken
        baseType
        createdAt
        owner
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const answerTodaysPuzzle = /* GraphQL */ `
  mutation AnswerTodaysPuzzle(
    $questionID: ID!
    $answer: String!
    $referrer: String
  ) {
    answerTodaysPuzzle(
      questionID: $questionID
      answer: $answer
      referrer: $referrer
    ) {
      id
      studentEmail
      userID
      questionID
      answer
      correct
      eloScoreAfterAnswering
      createdAt
      timezoneOffsetHours
      secondsToAnswer
      answeredInQuestionSet
      updatedAt
      question {
        id
        edition
        purpose
        questionURL
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        canRandomiseOptions
        rating
        coveredInGrade
        organisationID
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const approveTestScheduler = /* GraphQL */ `
  mutation ApproveTestScheduler(
    $authorisedByID: ID!
    $newSchedulerEmail: AWSEmail!
    $questionSetIDs: [ID!]!
  ) {
    approveTestScheduler(
      authorisedByID: $authorisedByID
      newSchedulerEmail: $newSchedulerEmail
      questionSetIDs: $questionSetIDs
    ) {
      id
      questionSetID
      studentEmail
      userID
      authorisedByID
      baseType
      createdAt
      updatedAt
      user {
        id
        username
        email
        notificationEmail
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        oldAccount
        country
        school
        gender
        race
        owner
        cognitoIdentityID
        linkCode
        baseType
        createdAt
        updatedAt
        __typename
      }
      questionSet {
        id
        name
        organisationID
        eventID
        logo
        publicOrPrivate
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        allowRegistrationWithoutAccount
        participationStart
        participationEnd
        invigilation
        instructions
        termsAndConditionsURL
        category
        format
        totalPoints
        createdByID
        updateUserELOAfterTestEnded
        baseType
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const openTestEntryForUserWithoutAccount = /* GraphQL */ `
  mutation OpenTestEntryForUserWithoutAccount($testEntryID: ID) {
    openTestEntryForUserWithoutAccount(testEntryID: $testEntryID) {
      testHostID
      testEntryID
      email
      accountType
      password
      __typename
    }
  }
`;
export const startWorksheet = /* GraphQL */ `
  mutation StartWorksheet($userID: ID!, $worksheetID: ID!) {
    startWorksheet(userID: $userID, worksheetID: $worksheetID) {
      id
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        __typename
      }
      __typename
    }
  }
`;
export const answerWorksheetQuestion = /* GraphQL */ `
  mutation AnswerWorksheetQuestion(
    $worksheetAnswerID: ID!
    $answer: String
    $timezoneOffsetHours: Int!
  ) {
    answerWorksheetQuestion(
      worksheetAnswerID: $worksheetAnswerID
      answer: $answer
      timezoneOffsetHours: $timezoneOffsetHours
    ) {
      id
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        baseType
        createdAt
        updatedAt
        __typename
      }
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
        __typename
      }
      __typename
    }
  }
`;
