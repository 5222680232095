<template>
    <div class="approve-schedulers">

        <h2>{{ $t('addSchedulersTitleMainMsg')}}</h2>

        <ul class="approve-schedulers__instructions">
            <li>{{ $t('addSchedulersInstructionsPoint1Msg')}}</li>
            <li>{{ $t('addSchedulersInstructionsPoint2Msg')}}</li>
        </ul>

        <ul class="approve-schedulers__list">
            <li v-for="(questionSet, index) in questionSets" v-bind:key="index">
                {{questionSet.name}}
            </li>
        </ul>


        <h2>{{ $t('addSchedulersTitleListMsg')}}</h2>

        <ul class="approve-schedulers__instructions">
            <li>{{ $t('addSchedulersListMsg')}}</li>
        </ul>

        <div>
            <ul class="approve-schedulers__list">
                <template v-if="loadingSchedulers">
                    <spinner height="50px" colour="#F9AC1B"/>
                </template>
                <template v-else>
                    <li v-for="(scheduler, index) in uniqueSchedulers" v-bind:key="index">
                        {{scheduler}}
                    </li>
                    <li v-if="schedulers.length === 0" class="text-center approve-schedulers__list--empty-list">{{ $t('addSchedulersListNonApprovedMsg')}}</li>
                    <li v-if="showAddSchedulerForm && !addingScheduler" class="add-approver">
                        <input type="text" placeholder="Email address" v-model="approverEmailAddress"/>
                        <button v-on:click="closeAddSchedulerForm()" class="btn btn-secondary">{{ $t('commonButtonCancelMsg')}}</button>
                        <button v-on:click="addScheduler()" class="btn btn-primary">{{ $t('commonButtonAddMsg')}}</button>
                    </li>
                    <li v-else-if="addingScheduler">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </li>
                    <li v-else class="add-approver">
                        <div></div>
                        <button v-on:click="openAddSchedulerForm()" class="btn">{{ $t('commonButtonAddMsg')}}
                            <span class="hidden-mobile">{{ $t('addSchedulersAddButtonSchedulerMsg')}}</span></button>
                    </li>
                </template>
            </ul>
        </div>

        <div class="text-center">
            <button v-on:click="closeDialog" class="btn btn-secondary">{{ $t('commonDoneMsg')}}</button>
        </div>

    </div>
</template>

<script>
    import Spinner from './Spinner.vue';

    export default {
        name: 'AddSchedulers',
        components: {
            Spinner
        },
        mixins: [],
        props: {
            user: Object,
            questionSets: Array
        },
        data: function () {
            return {
                approverEmailAddress: "",
                loadingSchedulers: false,
                showAddSchedulerForm: false,
                addingScheduler: false,
                schedulers: []
            }
        },
        computed: {
            uniqueSchedulers: function() {
                let unique = new Set();
                this.schedulers.map(qss => unique.add(qss.studentEmail));
                return unique;
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            openAddSchedulerForm: function() {
                console.debug("Open add form");
                this.showAddSchedulerForm = true;
            },
            closeAddSchedulerForm: function() {
                console.debug("Close add form");
                this.showAddSchedulerForm = false;
            },
            addScheduler: async function() {
                console.debug("Add scheduler");
                this.addingScheduler = true;
                let questionSetIDs = [];
                for (let questionSet of this.questionSets) {
                    questionSetIDs.push(questionSet.id);
                }
                const schedulers = await this.user.approveTestSchedulerForQuestionSets(this.user.getID(), this.approverEmailAddress, questionSetIDs);
                if (schedulers) {
                    this.schedulers.push(...schedulers);
                    this.approverEmailAddress = "";
                }
                this.addingScheduler = false;
                this.showAddSchedulerForm = false;
            },
            closeDialog: function () {
                this.$root.$emit('close-show-schedulers')
            }
        },
        mounted: async function () {
            this.loadingSchedulers = true;
            const questionSetIDs = new Set(this.questionSets.map(qs => qs.id));
            const allAuthorisedByMe = await this.user.fetchSchedulersAuthorisedBy(this.user.getID());
            if (allAuthorisedByMe && allAuthorisedByMe.length > 0) {
                this.schedulers = allAuthorisedByMe.filter((scheduler) => {
                    return questionSetIDs.has(scheduler.questionSetID);
                });
            }
            this.loadingSchedulers = false;
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/buttons";
    @import "../styles/pages/profile";
    @import "../styles/pages/tests";
    @import "../styles/pages/approvers";
</style>
