<template>
    <div class="test-detail">
        <div class="test-detail__header-bar">
            <div v-on:click="closeDialog()" class="image-button">
                <img src="../assets/arrow.svg" alt="Close test details"/>
            </div>
            <div class="test-detail__heading">{{ $t('createTestHeadingMsg')}}
                <span class="hidden-mobile">{{ $t('createTestHeadingDesktopSuffixMsg')}}</span></div>
        </div>

        <template v-if="creatingTest">
            <template v-if="creatingTest">
                {{ $t('createTestCreatingMsg')}}
            </template>
            <spinner height="50px" colour="#F9AC1B"/>
            <div v-if="errorMessage !== null">{{errorMessage}}</div>
        </template>

        <template v-else-if="selectedQuestionSet === null">
            <div class="lists-of-tests">
                <ul class="lists-of-tests__help">
                    <h6>{{ $t('commonTeachersHeadingMsg')}}</h6>
                    <li v-if="isTeacher"><img src="../assets/arrow.svg" alt="$t('createTestOpenToScheduleAltMsg')"/> {{ $t('createTestOpenToScheduleMsg')}}</li>
                </ul>
            </div>

            <ul class="my-information">
                <li v-for="(questionSet, index) in questionSets" v-bind:key="index"
                    class="my-information__detail">
                    <div>
                        <span class="test-name">{{questionSet.name}}</span>
                        <span class="profile-sub-heading">
                            Grade {{questionSet.minGrade}}
                            <template v-if="questionSet.minGrade !== questionSet.maxGrade">
                                - {{questionSet.maxGrade}}
                            </template>
                        </span>
                        <span class="pipe-break">|</span>
                        <span class="profile-sub-heading">Duration {{questionSet.suggestedDuration}}</span>
                        <span class="pipe-break">|</span>
                        <template v-if="questionSet.participationStart">
                            <span class="profile-sub-heading">From {{getDisplayDate(questionSet.participationStart)}}</span>
                            <span class="pipe-break">|</span>
                        </template>
                        <span class="profile-sub-heading">{{getDisplayTestFormat(questionSet.format)}}</span>
                    </div>
                    <div v-on:click="editTestToSchedule(questionSet)" class="image-button">
                        <img src="../assets/arrow.svg" alt="Add test"/>
                    </div>
                </li>
            </ul>
        </template>

        <template v-else-if="selectedQuestionSet !== null">
            <ul class="my-information">
                <li>
                    <span class="profile-sub-heading">{{ $t('createTestQuestionSetMsg')}}</span>
                    <div class="my-information__edit">
                        {{selectedQuestionSet.name}}
                    </div>
                </li>
                <li>
                    <span class="profile-sub-heading">GRADE</span>
                    <div class="my-information__edit">
                        {{ $t('commonTestsGradeMsg')}} {{selectedQuestionSet.minGrade}}
                        <template v-if="selectedQuestionSet.minGrade !== selectedQuestionSet.maxGrade">
                            - {{selectedQuestionSet.maxGrade}}
                        </template>
                    </div>
                </li>
                <li v-if="selectedQuestionSet.participationStart">
                    <span class="profile-sub-heading">{{ $t('createTestWhenCanScheduleMsg')}}</span>
                    <div class="my-information__edit">
                        {{getDisplayDate(selectedQuestionSet.participationStart)}} to
                        {{getDisplayDate(selectedQuestionSet.participationEnd)}}
                    </div>
                </li>
                <li>
                    <span class="profile-sub-heading">{{ $t('createTestDurationMsg')}}</span>
                    <div class="my-information__edit">
                        {{selectedQuestionSet.suggestedDuration}}
                    </div>
                </li>
                <li>
                    <span class="profile-sub-heading">{{ $t('createTestNameMsg')}}</span>
                    <div class="my-information__edit">
                        <input type="text" v-model="editTestName">
                    </div>
                </li>
                <li>
                    <span class="profile-sub-heading">{{ $t('commonLeaderboardDateMsg')}}</span>
                    <div class="my-information__edit">
                        <input type="text" v-model="editTestDate"
                               v-on:keydown="scheduledDateValidationError = null">
                    </div>
                    <div v-if="scheduledDateValidationError" class="my-information__edit--validation-error">
                        {{scheduledDateValidationError}}
                    </div>
                </li>
            </ul>
            <div class="my-information__add">
                <button v-on:click="cancelEditTest()" class="btn btn-secondary">{{ $t('commonButtonCancelMsg')}}</button>
                <button v-on:click="createTest()" class="btn btn-primary">{{ $t('createTestAddTestButtonMsg')}}</button>
            </div>
        </template>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import Spinner from './Spinner.vue';

    import { getDisplayDate } from "../services/utils";

    export default {
        name: 'ScheduleTest',
        components: {
            Spinner
        },
        mixins: [],
        props: {
            user: Object,
            questionSets: Array
        },
        data: function () {
            return {
                selectedQuestionSet: null,
                editingName: false,
                editingDate: false,
                editTestName: "",
                editTestDate: "",
                creatingTest: false,
                errorMessage: null,
                scheduledDateValidationError: null,
            }
        },
        computed: {
            isTeacher: function () {
                return this.user.isTeacher();
            },
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // Chat behaviour
            //
            // ----------------------------------------------------------------------------
            getDisplayDate: function (dateString) {
                return getDisplayDate(dateString);
            },
            getDisplayTestFormat: function (format) {
                switch (format) {
                    case "OLYMPIAD":
                        return this.$t('createTestFormatOlympiadMsg');
                    case "PUB_QUIZ":
                        return this.$t('createTestFormatPubQuizMsg');
                    case "WORKSHEET":
                        return this.$t('createTestFormatWorksheetMsg');
                }
            },
            editTestToSchedule: function (questionSet) {
                this.selectedQuestionSet = questionSet;
                this.editTestName = questionSet.name;
                let testDate = dayjs();
                const now = dayjs();
                if (questionSet.participationStart) {
                    const participationStart = dayjs(questionSet.participationStart);
                    if (participationStart.isBefore(now)) {
                        testDate = now.add(15, 'minute');
                    } else {
                        testDate = dayjs(questionSet.participationStart);
                        if (testDate.hour() < 8) {
                            testDate = testDate.hour(8);
                        }
                    }
                } else {
                    testDate = now.add(1, 'hour');
                }
                this.editTestDate = testDate.format('YYYY-MM-DD HH:mm');
            },
            cancelEditTest: function() {
                this.selectedQuestionSet = null;
            },
            createTest: async function () {
                console.log("creating test: " + this.editTestDate);
                // check that the test is being scheduled within the participation window
                const testDate = dayjs(this.editTestDate, 'YYYY-MM-DD HH:mm');
                const earliestStart = dayjs(this.selectedQuestionSet.participationStart);
                const durationComponents = this.selectedQuestionSet.suggestedDuration.split(':');
                const latestStart = dayjs(this.selectedQuestionSet.participationEnd).subtract(durationComponents[0], 'hour').subtract(durationComponents[1], 'minute').subtract(durationComponents[2], 'second');
                if (earliestStart > testDate || latestStart < testDate) {
                    this.scheduledDateValidationError = this.$t('createTestOutsideWindowMsg');
                    return;
                }
                this.creatingTest = true;
                const testHost = await this.user.createTestAndHostOnServer(this.selectedQuestionSet.id, this.editTestName, testDate.toISOString());
                if (testHost !== null) {
                    // strangely, the TestHost returned does not include the Test
                    const testHostWithTest = await this.user.fetchTestHost(testHost.id);
                    this.creatingTest = false;
                    this.selectedQuestionSet = null;
                    this.$root.$emit('test-scheduled', testHostWithTest)
                    this.closeDialog();
                } else {
                    this.errorMessage = this.$t('createTestErrorMsg');
                }
            },
            closeDialog: function () {
                this.$root.$emit('close-schedule-test')
            }
        },
        mounted: async function () {
        }
    }

</script>

<style lang="scss" scoped>
    @import "../styles/layout/buttons";
    @import "../styles/pages/profile";
    @import "../styles/pages/tests";
    @import "../styles/pages/approvers";
</style>
