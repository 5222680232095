import i18n from '../plugins/i18n';

export const i18nMixin = {
    data() {
        return {
            languages: [
                { flag: 'gb', language: 'en', title: 'English' },
                { flag: 'fr', language: 'fr', title: 'French' },
                { flag: 'pt', language: 'pt', title: 'Portuguese' }
            ]
        }
    },
    computed: {
        locale: function () {
            return i18n.locale;
        }
    },
    methods: {
        changeLocaleForUser: function(user) {
            console.log("User");
            console.log(user);
            switch(user.language) {
                case 'FRENCH':
                    this.changeLocale('fr');
                    break;
                case 'PORTUGUESE':
                    this.changeLocale('pt');
                    break;
                case 'ENGLISH':
                default:
                    this.changeLocale('en');
                    break;
            }
        },
        changeLocale: function(locale) {
            i18n.locale = locale;
        },
    }
};
