import Vue from "vue";

export const store = Vue.observable({
    user: null,
});
Vue.prototype.store = store;

export const mutations = {
    setUser: function (user) {
        console.log("Setting user in store: " + ((user ? user.getID() : "null")));
        store.user = user;
    }
};