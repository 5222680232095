<template>
    <!-- Main chat component -->
    <div class="chat-window">
        <header-bar></header-bar>

        <div class="chat-window__body">
            <!-- ----------------------- -->
            <!-- ROUND OVERVIEW          -->
            <!-- ----------------------- -->
            <div class="test-rounds-overview" v-if="isShowRoundsOverview && !isWaitingForTestToStart">
                <span>{{ $t('testRoundMsg')}}</span><ul class="test-rounds" v-bind:class="{'test-rounds--teacher': isInvigilator}">
                <li v-for="section in test.sections"
                    v-bind:key="section.number"
                    v-bind:class="{'test-round--past': section.number !== selectedSection && section.number < currentSection, 'test-round--future': section.number !== selectedSection && section.number > currentSection, 'test-round--current': section.number === selectedSection}"
                    v-on:click="selectSection(section.number)">
                    <template v-if="isInvigilator || (section.number <= currentSection)">
                        {{section.number}}
                    </template>
                    <template v-else>
                        <img src="../assets/lock.svg" alt="$t('commonLeaderboardTitleMsg')" class="test-rounds-overview__icon"/>
                    </template>
                    <img v-if="isSectionInProgress && (section.number === currentSection)" src="../assets/clock.svg" alt="$t('commonLeaderboardTitleMsg')" class="test-rounds-overview__icon--clock"/>
                </li>
                <li v-if="test.leaderboard" v-on:click="showOverallLeaderboard()" v-bind:class="{'test-round--current': selectedSection === -1}" class="hidden-mobile">
                    <img src="../assets/leaderboard.svg" alt="$t('commonLeaderboardTitleMsg')" class="test-rounds-overview__icon"/>
                </li>
                <li v-if="isInvigilator" v-on:click="showStatisticsPage()" v-bind:class="{'test-round--current': selectedSection === -2}" class="hidden-mobile">
                    <img src="../assets/stats.svg" alt="Stats" class="test-rounds-overview__icon"/>
                </li>
                <li v-if="isInvigilator" v-on:click="toggleStatsInRounds()" class="hidden-mobile">
                    <img v-if="showStats" src="../assets/toggle-on.svg" alt="$t('testToggleStatsOffMsg')" class="test-rounds-overview__icon"/>
                    <img v-if="!showStats" src="../assets/toggle-off.svg" alt="$t('testToggleStatsOnMsg')" class="test-rounds-overview__icon"/>
                </li>
            </ul>
            </div>

            <!-- ----------------------- -->
            <!-- CONTENT                 -->
            <!-- ----------------------- -->
            <div class="chat-window__conv" ref="chatContent" v-on:scroll="scrollQuestions">
                <div class="chat-window__conv__scrollable">

                    <!-- ----------------------- -->
                    <!-- Loading test            -->
                    <!-- ----------------------- -->
                    <template v-if="isLoadingTest">
                        <div>
                            {{ $t('testFetchingTestMsg')}}
                        </div>
                        <div class="chat-window__loading">
                            <spinner height="50px" colour="#1C1A4F"/>
                        </div>
                    </template>

                    <!-- ----------------------- -->
                    <!-- Loaded                  -->
                    <!-- ----------------------- -->
                    <div v-if="isTestLoaded">
                        <template v-if="test.isInvigilated()">
                            {{ $t('testScheduledStartMsg')}} {{getDisplayDate(test.date)}}.
                            {{ $t('testScheduledStartComeBackMsg')}}
                        </template>
                        <template v-else>
                            {{ $t('testStartAnytimePart1Msg')}} {{testDuration}} {{ $t('testStartAnytimePart2Msg')}}
                        </template>
                    </div>

                    <!-- ----------------------- -->
                    <!-- Before Test Started     -->
                    <!-- ----------------------- -->
                    <div v-else-if="isBeforeStartingTest" ref="quizQuestions">

                        <!-- ----------------------- -->
                        <!-- Instructions            -->
                        <!-- ----------------------- -->
                        <div class='message-wrapper'>
                            <div class='message message--note test-instructions'>
                                <p><b>{{ $t('testInstructionsMsg')}}</b></p>
                                <div v-html="test.questionSet.instructions"></div>
                            </div>
                        </div>

                        <!-- ----------------------- -->
                        <!-- Practice Questions      -->
                        <!-- ----------------------- -->
                        <test-practice v-bind:test="test"
                                       v-bind:isBeforeStartingTest="isBeforeStartingTest"></test-practice>

                        <div v-if="test.questionSet.logo" class="test-logo">
                            <div class="test-round__header"> </div>

                            <img v-bind:src="test.questionSet.logo"/>
                        </div>
                    </div>

                    <!-- ------------------------- -->
                    <!-- Waiting for test to start -->
                    <!-- ------------------------- -->
                    <div v-else-if="!isInvigilator && isBeforeStartingSection">
                        <template v-if="test.sections.length === 1">
                            {{ $t('testStartInMsg')}} {{timerText}}
                        </template>
                        <template v-else>
                            {{ $t('testSectionStartInMsg')}} {{timerText}}
                        </template>
                    </div>

                    <!-- ----------------------- -->
                    <!-- Result feedback         -->
                    <!-- ----------------------- -->
                    <div v-else-if="!isInvigilator  && isTestFinished && isDelayRevealingSolutions" class="test-finished">
                        <div class="test-round__header test-round__header--leaderboard">{{ $t('testFinishedMsg')}}</div>

                        <template v-if="testResult">{{testResult}}</template>
                    </div>

                    <template v-else>
                        <div ref="quizQuestions" v-if="test && test.sections.length > 0">

                            <!-- ----------------------- -->
                            <!-- Round                   -->
                            <!-- ----------------------- -->
                            <div v-for="sectionIndex in maxSectionToShow" v-bind:key="sectionIndex" v-show="selectedSection === sectionIndex">
                                <div v-if="test && test.isPubQuiz()" class="test-round__header">Round {{test.sections[sectionIndex-1].number}}</div>

                                <template v-if="test.isAdaptiveTest()">

                                    <!-- When loading the next question -->
                                    <template v-if="isStartingSection || loadingNextQuestion">
                                        <div>
                                            {{ $t('testLoadingQuestionMsg')}}
                                        </div>
                                        <div class="chat-window__loading">
                                            <spinner height="50px" colour="#1C1A4F"/>
                                        </div>
                                    </template>

                                    <!-- Show the last question only-->
                                    <div v-else-if="isTestInProgress" class="adaptive-test__question">
                                        <quiz-question v-bind:question="test.getLastQuestionInSection(sectionIndex - 1)"
                                                       v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                                       v-bind:sectionInProgress="isSectionInProgress"
                                                       v-bind:canAnswer="isSectionInProgress && (selectedSection === currentSection) && !test.getLastQuestionInSection(sectionIndex - 1).submittingAnswer"
                                                       v-bind:isTeacher="isInvigilator"/>

                                        <template v-if="showSkipQuestion(test.getLastQuestionInSection(sectionIndex - 1))">
                                            <button v-on:click="skipQuestion()">{{ $t('commonSkipMsg')}}</button><br/>
                                            ({{testEntry.getSkipsRemaining()}}
                                            <template v-if="testEntry.getSkipsRemaining() > 1">{{ $t('commonSkipsLCMsg')}}</template>
                                            <template v-else>{{ $t('commonSkipLCMsg')}}</template>
                                            {{ $t('commonSkipsLeftMsg')}})
                                        </template>
                                        <div v-show="isSectionInProgress && (selectedSection === currentSection) && test.getLastQuestionInSection(sectionIndex - 1).submittingAnswer" class="adaptive-test__question-saving">
                                            {{ $t('testSavingAnswerMsg')}}
                                            <spinner height="25px" colour="#1C1A4F"/>
                                        </div>
                                    </div>

                                    <template v-else>
                                        <template v-if="test.sections[sectionIndex - 1].answers && test.sections[sectionIndex - 1].answers.length === 0">
                                            {{ $t('testNoQuestionsInTestEntryMsg')}}
                                        </template>

                                        <template v-else>
                                            <quiz-question v-for="(question, index) in test.sections[sectionIndex - 1].answers"
                                                           ref="question"
                                                           v-bind:key="index"
                                                           v-bind:index="index"
                                                           v-bind:question="question"
                                                           v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                                           v-bind:sectionInProgress="false"
                                                           v-bind:canAnswer="false"
                                                           v-bind:isTeacher="isInvigilator"/>
                                        </template>
                                    </template>
                                </template>

                                <template v-else>
                                    <quiz-question v-for="(question, index) in test.sections[sectionIndex - 1].answers"
                                                   ref="question"
                                                   v-bind:key="index"
                                                   v-bind:index="index"
                                                   v-bind:question="question"
                                                   v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides"
                                                   v-bind:sectionInProgress="isSectionInProgress"
                                                   v-bind:canAnswer="isSectionInProgress && (selectedSection === currentSection) && !question.submittingAnswer"
                                                   v-bind:isTeacher="isInvigilator"/>

                                    <button v-if="isShowSubmitTest" v-on:click="submitTest()" class="test-section__submit">{{ $t('testFinishedSubmitMsg')}}</button>
                                </template>

                                <!-- ------------------------- -->
                                <!-- Entrant Round leaderboard -->
                                <!-- ------------------------- -->
                                <div v-if="!isInvigilator && isShowLeaderboardForSelectedRound && test.sections[sectionIndex-1].leaderboard" class="test-section__leaderboard">
                                    <div class="test-round__header test-round__header--leaderboard">{{ $t('testRankingsAfterRoundMsg')}} {{sectionIndex}}</div>
                                    <table class="test-leaderboard">
                                        <thead>
                                        <th class="test-leaderboard__rank">#</th>
                                        <th>{{ $t('commonLeaderboardNameMsg')}}</th>
                                        </thead>
                                        <tr v-for="(entry, index) in test.sections[sectionIndex-1].leaderboard" v-bind:key="index">
                                            <td class="test-leaderboard__rank">
                                                <template v-if="index === 0 || (index > 0 && entry.sectionScoreAndTime !== test.sections[sectionIndex-1].leaderboard[index-1].sectionScoreAndTime)">{{index+1}}</template>
                                            </td>
                                            <td>{{entry.firstName}} {{entry.surname}}</td>
                                        </tr>
                                    </table>
                                </div>

                                <!-- ------------------------- -->
                                <!-- Teacher Round leaderboard -->
                                <!-- ------------------------- -->
                                <div v-if="isInvigilator && isShowLeaderboardForSelectedRound && test.sections[sectionIndex-1].leaderboard" v-show="showStats" class="test-section__leaderboard">
                                    <div class="test-round__header test-round__header--leaderboard">{{ $t('testRankingsAfterRoundMsg')}} {{sectionIndex}}</div>
                                    <table class="test-leaderboard">
                                        <thead>
                                        <th class="test-leaderboard__rank">#</th>
                                        <th>{{ $t('commonLeaderboardNameMsg')}}</th>
                                        <th class="test-leaderboard__score-wide">{{ $t('commonLeaderboardAnsweredMsg')}}</th>
                                        <th class="test-leaderboard__score-wide">{{
                                            $t('commonLeaderboardRoundScoreMsg')}}</th>
                                        <th class="test-leaderboard__score-wide">{{
                                            $t('commonLeaderboardTotalScoreMsg')}}</th>
                                        </thead>
                                        <tr v-for="(entry, index) in test.sections[sectionIndex-1].leaderboard.entries" v-bind:key="index">
                                            <td class="test-leaderboard__rank">
                                                <template v-if="index === 0 || (index > 0 && entry.sectionScoreAndTime !== test.sections[sectionIndex-1].leaderboard.entries[index-1].sectionScoreAndTime)">{{index+1}}</template>
                                            </td>
                                            <td>{{entry.surname}}, {{entry.firstName}}</td>
                                            <td class="test-leaderboard__score-wide">{{entry.questionsAnswered}}</td>
                                            <td class="test-leaderboard__score-wide">{{entry.sectionScore}}</td>
                                            <td class="test-leaderboard__score-wide">{{entry.totalScore}}</td>
                                        </tr>
                                    </table>
                                </div>

                            </div>

                            <!-- ----------------------- -->
                            <!-- Overall leaderboard     -->
                            <!-- ----------------------- -->
                            <div v-if="test.leaderboard" class="test-section__leaderboard test-section__leaderboard--overall test-finished" v-show="selectedSection === -1">

                                <template v-if="isInvigilator">

                                    <template v-if="test.isOlympiad">
                                        {{ $t('testThanksForTakingPartMsg')}}
                                    </template>

                                    <template v-else>
                                        <div class="test-round__header test-round__header--leaderboard">{{ $t('testOverallLeaderboardMsg')}}</div>

                                        <table class="test-leaderboard">
                                            <thead>
                                            <th class="test-leaderboard__rank">#</th>
                                            <th class="test-leaderboard__name">{{ $t('commonLeaderboardNameMsg')}}</th>
                                            <th class="test-leaderboard__email">{{ $t('commonLeaderboardEmailMsg')}}</th>
                                            <th class="test-leaderboard__ans">{{ $t('commonLeaderboardAnsweredAbrMsg')}}</th>
                                            <th class="test-leaderboard__time">{{ $t('commonLeaderboardTimeMsg')}}</th>
                                            <th class="test-leaderboard__score">{{ $t('commonLeaderboardScoreMsg')}}</th>
                                            </thead>
                                            <tr v-for="(entry, index) in test.leaderboard.entries" v-bind:key="index">
                                                <td class="test-leaderboard__rank">
                                                    <template v-if="index === 0 || (index > 0 && entry.scoreAndTime !== test.leaderboard.entries[index-1].scoreAndTime)">{{index+1}}</template>
                                                </td>
                                                <td>{{entry.surname}}, {{entry.firstName}}</td>
                                                <td>{{entry.email}}</td>
                                                <td class="test-leaderboard__ans">{{entry.questionsAnswered}}</td>
                                                <td class="test-leaderboard__time">{{getDisplayDuration(entry.timeTaken)}}</td>
                                                <td class="test-leaderboard__score">{{entry.score}}</td>
                                            </tr>
                                        </table>
                                    </template>
                                </template>

                                <template v-else>
                                    <div class="test-round__header test-round__header--leaderboard">{{ $t('testFinishedMsg')}}</div>

                                    <p>{{ $t('testFinishedWellDoneMsg')}}</p>

                                    <template v-if="!test.isOlympiad">
                                        <p>{{ $t('testTestFinishedRoundAdviceMsg')}}</p>
                                        <p>{{ $t('testFinishedLeaderboardMsg')}}</p>

                                        <div class="test-round__header test-round__header--leaderboard">{{ $t('testOverallLeaderboardMsg')}}</div>

                                        <table class="test-leaderboard">
                                            <thead>
                                            <th class="test-leaderboard__rank">#</th>
                                            <th>{{ $t('commonLeaderboardNameMsg')}}</th>
                                            <th class="test-leaderboard__score">{{
                                                $t('commonLeaderboardAnsweredAbrMsg')}}</th>
                                            <th class="test-leaderboard__score">{{ $t('commonLeaderboardScoreMsg')}}</th>
                                            </thead>
                                            <tr v-for="(entry, index) in test.leaderboard" v-bind:key="index">
                                                <td class="test-leaderboard__rank">
                                                    <template v-if="index === 0 || (index > 0 && entry.scoreAndTime !== test.leaderboard[index-1].scoreAndTime)">{{index+1}}</template>
                                                </td>
                                                <td>{{entry.firstName}} {{entry.surname}},</td>
                                                <td class="test-leaderboard__ans">{{entry.questionsAnswered}}</td>
                                                <td class="test-leaderboard__score">{{entry.score}}</td>
                                            </tr>
                                        </table>
                                    </template>
                                </template>
                            </div>

                            <!-- ----------------------- -->
                            <!-- Stats page              -->
                            <!-- ----------------------- -->
                            <div v-if="isInvigilator && test.sections" class="test-section__leaderboard test-section__leaderboard--overall" v-show="selectedSection === -2">
                                <div class="test-round__header test-round__header--leaderboard">{{ $t('testQuestionStatisticsMsg')}}</div>

                                <table class="test-leaderboard">
                                    <thead>
                                    <th class="test-leaderboard__rank">#</th>
                                    <th class="test-leaderboard__answer">{{ $t('commonLeaderboardAnswerMsg')}}</th>
                                    <th>{{ $t('commonLeaderboardSpreadMsg')}}</th>
                                    <th class="test-leaderboard__score-wide">% {{ $t('commonLeaderboardAnsweredMsg')}}</th>
                                    <th class="test-leaderboard__score-wide">% {{ $t('commonLeaderboardCorrectMsg')}}</th>
                                    </thead>
                                    <tbody v-for="section in test.sections" v-bind:key="section.number">
                                    <template v-if="section.performance">
                                        <tr><td colspan="5" class="test-leaderboard__round-header">{{ $t('testRoundLCMsg')}} {{section.number}} ({{section.performance.totalStarted}} {{ $t('testRoundPeopleStartedMsg')}})</td></tr>
                                        <tr v-for="question in section.answers" v-bind:key="question.number">
                                            <td class="test-leaderboard__rank">{{question.number}}</td>
                                            <td class="test-leaderboard__answer">{{question.answerValue}}</td>
                                            <td>
                                                <table class="test-leaderboard">
                                                    <tr>
                                                        <td v-for="(tot, index) in question.performance.answerCounts" v-bind:key="index">{{tot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td v-for="(tot, index) in question.performance.answerCounts" v-bind:key="index">
                                                            {{ (question.performance.totalAnswered > 0) ? Math.round(tot / (question.performance.totalAnswered) * 100) : 0}}%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td v-for="(val, index) in getAnswerOptions(question.answerOptions)" v-bind:key="index">{{val}}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td class="test-leaderboard__score-wide">
                                                <template v-if="section.performance.totalStarted > 0">{{ Math.round(question.performance.totalAnswered / section.performance.totalStarted * 100)}} %</template>
                                                <template v-else> - </template>
                                            </td>
                                            <td class="test-leaderboard__score-wide">
                                                <template v-if="section.performance.totalStarted > 0">{{ Math.round(getAnswerTotal(question.answerOptions, question.answerValue, question.performance.answerCounts) / section.performance.totalStarted * 100) }} %</template>
                                                <template v-else> - </template>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </template>
                </div>
            </div>

            <!-- ----------------------- -->
            <!-- RESPONSE BAR            -->
            <!-- ----------------------- -->
            <div class="chat-window-resp chat-window-resp--quiz">
                <!-- Teacher bar -->
                <template v-if="isInvigilator">
                    <div v-if="isLoadingTest">{{ $t('testLoadingTestMsg')}}</div>
                    <div v-else-if="isWaitingForTestToStart">
                        <span class="hidden-mobile">{{ $t('testStartsMsg')}}</span> {{getDisplayDate(test.date)}}
                        <!-- <span class="hidden-desktop">() {{getDisplayDateMobile(testDate)}}</span> -->
                        <button v-on:click="startTest()" class="test-control-button">{{ $t('testGoToTestMsg')}}</button>
                    </div>
                    <div v-else-if="isBeforeStartingSection || isSectionFinished">
                        <button v-on:click="getConfirmationOfStart()" class="test-control-button">
                            <template v-if="test.isPubQuiz()">{{ $t('testStartRoundMsg')}} {{currentSection}}</template>
                            <template v-else>{{ $t('testStartTestMsg')}}</template>
                        </button>
                    </div>
                    <div v-else-if="isStartingSection">
                        <template v-if="test.isInvigilated()">
                            <template v-if="test.isPubQuiz()">{{ $t('testStartingRoundMsg')}} {{currentSection}} in </template>
                            <template v-else>{{ $t('testWillStartInMsg')}} </template>
                            <span class="test-timer">{{timerText}}</span>
                        </template>
                    </div>
                    <div v-else-if="isSectionInProgress">
                        {{ $t('testTimeRemainingMsg')}}: <span class="test-timer">{{timerText}}</span>
                        <button v-if="!test.isOlympiad()" v-on:click="endSection()" class="test-control-button">{{ $t('testEndRoundMsg')}} {{currentSection}}</button>
                    </div>
                    <div v-else-if="isTestFinished">{{ $t('testTestHasFinishedMsg')}}</div>
                </template>

                <!-- Entrant bar -->
                <template v-else>
                    <template v-if="test && !test.isInvigilated()">
                        <div v-if="isLoadingTest">
                            {{ $t('testLoadingMsg')}}
                        </div>
                        <div v-else-if="isBeforeStartingTest">
                            <template v-if="isInParticipationWindow">
                                <button v-on:click="startUninvigilatedTest()" class="test-control-button">{{ $t('testStartTestMsg')}}</button>
                            </template>
                            <template v-else-if="this.testEntry.participationStart">
                                {{ $t('testComeBackAfterMsg')}} {{getDisplayDate(this.testEntry.participationStart)}}
                            </template>
                            <template v-else>
                                {{ $t('testComeBackAfterMsg')}} {{getDisplayDate(test.date)}}
                            </template>
                        </div>
                        <div v-else-if="isStartingSection">
                            {{ $t('testStartingMsg')}}
                        </div>
                        <div v-else-if="isSectionInProgress">
                            {{ $t('testTimeRemainingMsg')}} <span class="test-timer">{{timerText}}</span>
                        </div>
                        <div v-else>{{testType}} {{ $t('testCompleteMsg')}}</div>
                    </template>
                    <template v-else>
                        {{timerPrefix}} <span v-show="isShowTimer" class="test-timer">{{timerText}}</span>
                    </template>
                </template>
            </div>

            <!-- ----------------------- -->
            <!-- QUESTION OVERVIEW PILLS -->
            <!-- ----------------------- -->
            <test-question-overview-pills v-if="isBeforeStartingTest"
                                          v-bind:questions="test.practiceQuestions"
                                          v-bind:showingOverview="showingOverview"
                                          v-bind:questionNumberInTopHalf="questionNumberInTopHalf"
                                          v-bind:isShowAllQuestionsInOverview="test && test.isPubQuiz()"
                                          v-bind:isShowLeaderboardForSelectedRound="false">
            </test-question-overview-pills>

            <test-question-overview-pills v-if="isTestFinished || (test && !test.isAdaptiveTest() && !isWaitingForTestToStart && selectedSection > 0)"
                                          v-bind:questions="test.sections[selectedSection-1].answers"
                                          v-bind:showingOverview="showingOverview"
                                          v-bind:questionNumberInTopHalf="questionNumberInTopHalf"
                                          v-bind:isShowAllQuestionsInOverview="test && test.isPubQuiz()"
                                          v-bind:isShowLeaderboardForSelectedRound="isShowLeaderboardForSelectedRound">
            </test-question-overview-pills>


            <!-- ----------------------- -->
            <!-- POPUPS                  -->
            <!-- ----------------------- -->
            <notifications v-if="showNotifications"
                           v-bind:notifications="notifications"
                           v-bind:showSpinner="false">
            </notifications>

            <formula-sheets v-if="test && test.hasFormulaSheets()"
                            v-show="showFormulaSheets"
                            v-bind:formulaSheetSlides="test.questionSet.formulaSheetSlides">
            </formula-sheets>
        </div>
    </div>
</template>

<script>
    import {Auth} from "@aws-amplify/auth";

    import {store} from '../store.js'

    import test from '../domain/test';
    import testEntry from '../domain/test-entry';

    import { convertMillisToTime, convertDurationToMillis, getDisplayDate } from "../services/utils";

    // Visual components
    import HeaderBar from '../components/Header.vue';
    import Notifications from '../components/Notifications.vue';
    import Spinner from '../components/Spinner.vue';
    import QuizQuestion from '../components/QuizQuestion.vue';
    import TestPractice from '../components/TestPractice.vue';
    import TestQuestionOverviewPills from '../components/TestQuestionOverviewPills.vue';
    import FormulaSheets from '../components/FormulaSheets.vue';

    export const TEST_STATES = Object.freeze({
        TEST_STATE_LOADING: 0,
        TEST_STATE_LOADED: 1,
        TEST_STATE_BEFORE_TEST_START: 2,
        TEST_STATE_BEFORE_SECTION_START: 3,
        TEST_STATE_STARTING_SECTION: 4,
        TEST_STATE_SECTION_IN_PROGRESS: 5,
        TEST_STATE_SECTION_FINISHED: 6,
        TEST_STATE_FINISHED: 7,
        TEST_STATE_MARKED: 8,
    });

    const MINUTES_BEFORE_TEST_TO_SHOW_INSTRUCTIONS = 30;

    export default {
        name: 'Test',
        components: {
            HeaderBar, Spinner, QuizQuestion, TestPractice, TestQuestionOverviewPills, Notifications, FormulaSheets
        },
        created() {
        },
        data() {
            return {
                isInvigilator: false,
                testHostID: null,
                testEntryID: null,
                test: null,
                testState: TEST_STATES.TEST_STATE_LOADING,
                testEntry: null,
                currentSection: 1,
                selectedSection: 1,
                timer: null,
                currentTime: new Date(),
                timerEndTime: new Date(),
                timerCallback: null,
                loadingNextQuestion: false,
                noQuestionsLeft: false,
                showingOverview: false,
                questionNumberInTopHalf: 1,
                questionJumpScrollerTimer: null,
                jumpedToQuestion: false,
                answerUpdatePromises: [],
                showStats: true,
                testResult: null,
                notifications: [],
                showNotifications: false,
                showFormulaSheets: false,
                score: 0
            }
        },
        computed: {
            // Test State
            isLoadingTest: function() {
                return this.testState === TEST_STATES.TEST_STATE_LOADING;
            },
            isTestLoaded: function() {
                return this.testState === TEST_STATES.TEST_STATE_LOADED;
            },
            isBeforeStartingTest: function() {
                return this.testState === TEST_STATES.TEST_STATE_BEFORE_TEST_START;
            },
            isBeforeStartingSection: function() {
                return this.testState === TEST_STATES.TEST_STATE_BEFORE_SECTION_START;
            },
            isStartingSection: function() {
                return this.testState === TEST_STATES.TEST_STATE_STARTING_SECTION;
            },
            isSectionInProgress: function() {
                return this.testState === TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
            },
            isSectionFinished: function() {
                return this.testState === TEST_STATES.TEST_STATE_SECTION_FINISHED;
            },
            isTestFinished: function() {
                return this.testState === TEST_STATES.TEST_STATE_FINISHED;
            },
            isTestMarked: function() {
                return this.testState === TEST_STATES.TEST_STATE_MARKED;
            },
            isWaitingForTestToStart: function() {
                switch(this.testState) {
                    case TEST_STATES.TEST_STATE_LOADING:
                    case TEST_STATES.TEST_STATE_LOADED:
                    case TEST_STATES.TEST_STATE_BEFORE_TEST_START:
                        return true;
                    default:
                        return false;
                }
            },
            isTestInProgress: function() {
                switch(this.testState) {
                    case TEST_STATES.TEST_STATE_STARTING_SECTION:
                    case TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS:
                    case TEST_STATES.TEST_STATE_SECTION_FINISHED:
                        return true;
                    default:
                        return false;
                }
            },
            isShowSubmitTest: function() {
                return !this.isInvigilator
                    && this.test !== null
                    && !this.test.isPubQuiz()
                    && this.selectedSection === this.currentSection
                    && this.testState === TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
            },
            isShowRoundsOverview() {
                return this.test !== null && this.test.isPubQuiz();
            },
            isShowLeaderboardForSelectedRound: function() {
                if (!this.isPubQuiz) {
                    return false;
                }

                switch(this.testState) {
                    case TEST_STATES.TEST_STATE_LOADING:
                    case TEST_STATES.TEST_STATE_LOADED:
                    case TEST_STATES.TEST_STATE_BEFORE_TEST_START:
                        return false;
                    case TEST_STATES.TEST_STATE_FINISHED:
                    case TEST_STATES.TEST_STATE_MARKED:
                        return true;
                }

                if (this.isInvigilator) {
                    return true;
                }

                if (this.selectedSection < this.currentSection) {
                    return true;
                }

                if (this.testState === TEST_STATES.TEST_STATE_SECTION_FINISHED) {
                    return true;
                }

                return false;
            },
            isInParticipationWindow: function () {
                return this.test.isInParticipationWindow(this.testEntry);
            },
            maxSectionToShow: function() {
                if (this.isInvigilator) {
                    return this.test.sections.length;
                } else {
                    return this.currentSection;
                }
            },
            isDelayRevealingSolutions: function() {
                if (this.test && this.test.questionSet.markingConfiguration && this.test.questionSet.markingConfiguration.whenToShow === "AFTER_TEST_WINDOW") {
                    if (new Date() < new Date(this.test.questionSet.participationEnd)) {
                        return true;
                    }
                }
                if (this.test && this.test.questionSet.markingConfiguration && this.test.questionSet.markingConfiguration.whenToShow === "AFTER_DATE_TIME") {
                    if (new Date() < new Date(this.test.questionSet.markingConfiguration.whenToShowDate)) {
                        return true;
                    }
                }
                return false;
            },
            // Duration & Timers
            testDuration: function() {
                return this.getDisplayDuration(this.test.questionSet.suggestedDuration);
            },
            isShowTimer: function() {
                if (this.test !== null) {
                    switch (this.testState) {
                        case TEST_STATES.TEST_STATE_BEFORE_TEST_START:
                        case TEST_STATES.TEST_STATE_BEFORE_SECTION_START:
                        case TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS:
                            return true;
                    }
                }
                return false;
            },
            timerPrefix: function() {
                if (this.test !== null) {
                    switch (this.testState) {
                        case TEST_STATES.TEST_STATE_LOADING:
                            return this.$t('testLoadingTestMsg');
                        case TEST_STATES.TEST_STATE_LOADED:
                            return this.$t('testTestWillStartOnMsg') + " " + this.getDisplayDate(this.test.date);
                        case TEST_STATES.TEST_STATE_BEFORE_TEST_START:
                            return this.$t('testTestStartInMsg') + " ";
                        case TEST_STATES.TEST_STATE_BEFORE_SECTION_START:
                            if (this.test.sections.length === 1) {
                                return this.$t('testTestStartInMsg') + " ";
                            } else {
                                return this.$t('testRoundStartsInMsg') + " ";
                            }
                        case TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS:
                            return this.$t('testTimeRemainingMsg') + " ";
                        case TEST_STATES.TEST_STATE_SECTION_FINISHED:
                            if (this.test.isOlympiad()) {
                                return this.$t('testTestHasFinishedMsg');
                            } else {
                                return this.$t('testWaitingForRoundToStartMsg');
                            }
                        case TEST_STATES.TEST_STATE_FINISHED:
                        case TEST_STATES.TEST_STATE_MARKED:
                        default:
                            return this.$t('testTestHasFinishedMsg');
                    }
                } else {
                    return ""
                }
            },
            timerText: function() {
                let showTimer = false;
                if (this.test !== null) {
                    switch (this.testState) {
                        case TEST_STATES.TEST_STATE_BEFORE_TEST_START:
                        case TEST_STATES.TEST_STATE_BEFORE_SECTION_START:
                        case TEST_STATES.TEST_STATE_STARTING_SECTION:
                        case TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS:
                            showTimer = true;
                    }
                }

                let timeToDisplay = "00:00:00";
                if (this.currentTime < this.timerEndTime) {
                    timeToDisplay = convertMillisToTime(this.timerEndTime - this.currentTime);
                }

                if (showTimer) {
                    return timeToDisplay;
                } else {
                    return "";
                }
            },
            testType: function() {
                if (this.test) {
                    if (this.test.isPubQuiz()) {
                        return this.$t('testTypeQuizMsg');
                    }
                    if (this.test.isOlympiad()) {
                        return this.$t('testTypeTestMsg');
                    }
                    if (this.test.isWorksheet()) {
                        return this.$t('testTypeAssignmentMsg');
                    }
                    if (this.test.isTest()) {
                        return this.$t('testTypeTestMsg');
                    }
                    if (this.test.isAdaptiveTest()) {
                        return this.$t('testTypeTestMsg');
                    }
                }
                return "";
            }
        },
        methods: {
            // ============================================================================
            //
            // Test behaviour
            //
            // ============================================================================


            // ------------------------------------
            // Common behaviour
            // ------------------------------------
            reset: function () {
                this.testState = TEST_STATES.TEST_STATE_LOADING;
                this.answerUpdatePromises.splice(0);
                this.currentSection = 1;
                this.selectedSection = 1;
                this.showingOverview = false;
                this.questionNumberInTopHalf = 1;
                this.currentTime = new Date();
                this.timerEndTime = new Date();
                this.timer = null;
                this.questionJumpScrollerTimer = null;
                this.jumpedToQuestion = false;
                this.showStats = true;
                this.loadingNextQuestion = false;
                this.noQuestionsLeft = false;
                this.testResult = null;
                this.notifications.splice(0);
                this.showNotifications = false;
                this.showFormulaSheets = false;
                this.score = 0;
            },
            resetAndReload: function () {
                this.cancelTimerCallback();
                this.reset();
                if (this.test) {
                    this.test.unsubscribeFromUpdates();
                    this.test = null;
                }
                if (this.testHost) {
                    this.testHost = null;
                }
                this.userChanged();
            },
            userChanged: async function() {
                if (store.user) {
                    console.log("User changed: " + store.user.getID());
                    // Open the test. We don't at this stage know whether it's invigilated, and whether the Teacher
                    // is the invigilator for this test. So try to open for a Teacher if the user is a Teacher, and
                    // if the test is non-invigilated, open as a entrant instead
                    if (store.user.isTeacher()) {
                        this.openTestForTeacher();
                    } else {
                        this.openTestForEntrant();
                    }
                } else {
                    console.log("No user");
                    this.$router.push({ name: 'signin', query: { redirect: this.$route.fullPath } } );
                }
            },
            updateStateToSectionOrTestFinished: function(sectionIndex) {
                // if the last section is finished, change to test complete
                if (sectionIndex === (this.test.sections.length - 1)) {
                    this.testState = TEST_STATES.TEST_STATE_FINISHED;
                } else {
                    this.testState = TEST_STATES.TEST_STATE_SECTION_FINISHED;
                }
            },
            signedOut: function() {
                this.cancelTimerCallback();
                this.test.unsubscribeFromUpdates();
                this.test = null;
            },

            // ------------------------------------
            // Timer functionality
            // ------------------------------------
            invokeCallbackAtTime: function(timerEndTime, callback) {
                this.currentTime = new Date();
                this.timerEndTime = timerEndTime;
                this.timerCallback = callback;
                this.timer = setTimeout(this.updateTimers, 1000);
            },
            updateTimers: function() {
                this.currentTime = new Date();
                this.timer = setTimeout(this.updateTimers, 1000);

                if (this.currentTime >= this.timerEndTime && this.timerCallback !== null) {
                    console.log("Invoking callback");
                    const oldCallback = this.timerCallback;
                    this.cancelTimerCallback();
                    oldCallback.call(this);
                }
            },
            cancelTimerCallback: function() {
                console.log("Clearing timeout");
                clearTimeout(this.timer);
                console.log("Clearing timer values");
                this.timerEndTime = null;
                this.timerCallback = null;
            },


            // ------------------------------------
            // Entrant behaviour
            // ------------------------------------
            openTestForEntrant: async function () {
                if (store.user && this.testHostID) {
                    console.log("Loading test (hostID: " + this.testHostID + ") for entrant: " + store.user.getID());
                    this.test = await test(this.testHostID);
                    if (this.test) {
                        if (this.test.isInvigilated()) {
                            await this.test.subscribeToSectionChanges(this.updateOnSectionStarted, this.updateOnSectionEnded);
                            this.setTimerIfTestTooFarInFuture();
                            await this.loadAnySectionsStartedByInvigilator();
                        } else {
                            await this.loadQuestionsIfEntrantStartedUninvigilatedTest();
                        }
                    }
                }
            },
            updateOnSectionStarted: function(sectionIndex, section) {
                this.setCurrentSection(sectionIndex);
                this.testState = TEST_STATES.TEST_STATE_BEFORE_SECTION_START;
                console.log(section);
                this.setupTimersForStartOfSection(section.startedAt, section.duration);
            },
            updateOnSectionEnded: function(sectionIndex) {
                this.setCurrentSection(sectionIndex);
                this.$nextTick(() => {
                    this.jumpToQuestion(0);
                });
                this.updateStateToSectionOrTestFinished(sectionIndex);
                this.loadSectionLeaderboardForEntrant(sectionIndex);
                if (this.testState === TEST_STATES.TEST_STATE_FINISHED) {
                    if (this.test.isOlympiad()) {
                        this.loadEndOfTestMessageForEntrant();
                    } else {
                        this.loadOverallLeaderboardForEntrant();
                        this.selectedSection = -1;
                    }
                }
            },
            loadAnySectionsStartedByInvigilator: async function() {
                this.testEntry = await testEntry(this.testEntryID, this.test);
                if (this.test.sections) {
                    for (let i = 0; i < this.test.sections.length; i++) {
                        const section = this.test.sections[i];
                        if (section.startedAt !== null) {
                            const sectionWithQuestions = await this.loadSectionForEntrant(i);
                            if (section.endedAt === null) {
                                this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
                                this.setupTimersForStartOfSection(sectionWithQuestions.startedAt, sectionWithQuestions.duration);
                            } else {
                                this.updateStateToSectionOrTestFinished(i);
                                if (!this.test.isOlympiad) {
                                    this.loadSectionLeaderboardForEntrant(i);
                                }
                                if (this.testState === TEST_STATES.TEST_STATE_FINISHED) {
                                    if (this.test.isOlympiad) {
                                        this.loadEndOfTestMessageForEntrant();
                                    } else {
                                        this.loadOverallLeaderboardForEntrant();
                                        this.selectedSection = -1;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            loadQuestionsIfEntrantStartedUninvigilatedTest: async function () {
                // check test entry to see if test started
                this.testEntry = await testEntry(this.testEntryID, this.test);
                if (this.testEntry.startedAt !== null) {
                    // lambda will load answers if appropriate
                    await this.loadSectionForEntrant(0);
                    if (this.testEntry.finishedAt !== null) {
                        this.testState = TEST_STATES.TEST_STATE_FINISHED;
                        this.loadEndOfTestMessageForEntrant();
                    } else {
                        this.setupTimersForStartOfSection(new Date(this.testEntry.startedAt).toISOString(), this.test.sections[0].duration);
                        this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
                    }
                } else {
                    this.testState = TEST_STATES.TEST_STATE_BEFORE_TEST_START;
                }
            },
            startUninvigilatedTest: async function() {
                // lambda will load answers if appropriate
                this.testState = TEST_STATES.TEST_STATE_STARTING_SECTION;
                await this.loadSectionForEntrant(0);
                this.startUninvigilatedTestTimer(new Date().toISOString(), this.test.sections[0].duration);
                this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
            },
            loadSectionForEntrant: async function(sectionIndex) {
                const sectionWithQuestions = await this.test.loadSectionForEntrant(sectionIndex, this.testEntry.findTestEntrySectionID(this.test.sections[sectionIndex].id));
                this.setCurrentSection(sectionIndex);
                if (sectionWithQuestions.answers.length == 0) {
                    this.noQuestionsLeft = true;
                }
                return sectionWithQuestions;
            },
            setCurrentSection: function (sectionIndex) {
                this.currentSection = sectionIndex + 1;
                this.selectedSection = this.currentSection;
            },
            startUninvigilatedTestTimer: function(dateToStartSectionISOString, duration) {
                const dateToStartSection = new Date(dateToStartSectionISOString);
                const dateToEndSection = new Date(dateToStartSection.getTime() +  convertDurationToMillis(duration));
                this.invokeCallbackAtTime(dateToEndSection, () => {
                    console.log("Timer is ending test");
                    this.submitTest();
                })
            },
            setupTimersForStartOfSection: function(dateToStartSectionISOString, duration) {
                const dateToStartSection = new Date(dateToStartSectionISOString);
                console.log("Time now: " + new Date());
                console.log("Starting countdown timer to start section at: " + dateToStartSectionISOString);
                const dateToEndSection = new Date(dateToStartSection.getTime() +  convertDurationToMillis(duration));
                console.log("Date to end section: " + dateToEndSection);
                this.timer = this.invokeCallbackAtTime(dateToStartSection, () => {
                    console.log("Section starting. Staring countdown timer to end section");
                    this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
                    this.$nextTick(() => {
                        this.jumpToQuestion(0);
                    });
                    this.timer = this.invokeCallbackAtTime(dateToEndSection, () => {
                        console.log("Timer is ending section");
                        this.testState = TEST_STATES.TEST_STATE_SECTION_FINISHED;
                    })
                });
            },
            setTimerIfTestTooFarInFuture: function() {
                const currentTime = new Date();
                const startDate = new Date(this.test.date);
                const millisToStartOfTest = startDate.getTime() - currentTime.getTime();
                const millisLengthOfTimeBeforeTestToShowInstructions = MINUTES_BEFORE_TEST_TO_SHOW_INSTRUCTIONS * 60000;
                if (millisToStartOfTest > millisLengthOfTimeBeforeTestToShowInstructions) {
                    this.testState = TEST_STATES.TEST_STATE_LOADED;
                    const dateToShowInstructions = new Date(startDate.getTime() - millisLengthOfTimeBeforeTestToShowInstructions);
                    this.timer = this.invokeCallbackAtTime(dateToShowInstructions, () => {
                        console.log("Showing instructions");
                        this.testState = TEST_STATES.TEST_STATE_BEFORE_TEST_START;
                        this.timer = this.invokeCallbackAtTime(startDate, () => {
                            console.log("Test should start now");
                        });
                    });
                } else {
                    if (millisToStartOfTest > 0) {
                        this.timerEndTime = new Date(this.test.date);
                        this.timer = this.invokeCallbackAtTime(startDate, () => {
                            console.log("Test should start now");
                        });
                    }
                    this.testState = TEST_STATES.TEST_STATE_BEFORE_TEST_START;
                }
            },
            changeAnswer: async function(answerId, answer) {
                if (!this.isBeforeStartingTest && this.test) {
                    this.$root.$emit('saving-answer', answerId);
                    const section = this.test.sections[this.currentSection - 1];
                    for (let i = 0; i < section.answers.length; i++) {
                        const testAnswer = section.answers[i];
                        if (testAnswer.id === answerId) {
                            const oldAnswer = testAnswer.answer;

                            // check if we are answering, or un-answering an multiple choice question
                            if ((this.test.isMultipleChoiceQuestion(testAnswer) && testAnswer.answer === answer) || answer === "") {
                                testAnswer.answer = null;
                            } else {
                                testAnswer.answer = answer;
                            }

                            testAnswer.submittingAnswer = true;
                            if (this.test.isAdaptiveTest()) {
                                this.loadingNextQuestion = true;
                            }

                            let success = false;
                            try {
                                this.answerUpdatePromises[i] = this.test.saveAnswer(testAnswer.id, testAnswer.answer, testAnswer.answerPhotoS3Key, this.currentSection - 1);
                                success = await this.answerUpdatePromises[i];
                                console.log('Answer saved on server');
                                this.answerUpdatePromises[i] = null;
                                this.$root.$emit('saved-answer', answerId);
                            } catch (error) {
                                // an error occurred updating on the server - revert to previous answer. We need to handle this better
                                testAnswer.answer = oldAnswer;
                                this.$root.$emit('saved-answer', answerId, "Error");
                                testAnswer.submittingAnswer = false;
                                return;
                            }

                            if (this.test.isAdaptiveTest()) {
                                this.noQuestionsLeft = !success;
                                this.loadingNextQuestion = false;
                                if (this.noQuestionsLeft) {
                                    this.cancelTimerCallback();
                                    await this.submitTest();
                                }
                            }

                            testAnswer.submittingAnswer = false;
                            return;
                        }
                    }
                }
            },
            changeAnswerPhoto: async function(answerId, file) {
                this.$root.$emit('saving-answer-photo', answerId);
                try {
                    const section = this.test.sections[this.currentSection - 1];
                    for (let i = 0; i < section.answers.length; i++) {
                        const testAnswer = section.answers[i];

                        if (testAnswer.id === answerId) {
                            const oldAnswerPhotoS3Key = testAnswer.answerPhotoS3Key;
                            testAnswer.submittingAnswer = true;

                            // need to have a wrapper Promise around both actions, and we manually control that promise,
                            // else if person clicks finish while image is uploading, might not get stored in DB
                            try {
                                this.answerUpdatePromises[i] = Storage.put(file.name, file, { level: "protected" });
                                const result = await this.answerUpdatePromises[i];
                                console.log(result);
                                testAnswer.answerPhotoS3Key = result.key;
                                console.log("photo uploaded: " + testAnswer.answerPhotoS3Key);
                                this.answerUpdatePromises[i] = null;
                            } catch (error) {
                                this.$root.$emit('saved-answer-photo', answerId, "Error");
                                console.log("Error uploading photo to S3: " + file.name, error);
                                testAnswer.submittingAnswer = false;
                                return;
                            }

                            // if saving to S3 worked
                            this.answerUpdatePromises[i] = this.test.saveTestAnswer(testAnswer.id, testAnswer.answer, testAnswer.answerPhotoS3Key);
                            const result = await this.answerUpdatePromises[i];
                            this.answerUpdatePromises[i] = null;
                            console.log('Answer saved on server');

                            // if saving the key to the DB worked
                            if (result === null) {
                                // an error occurred updating on the server - revert do previous answer. We need to handle this better
                                testAnswer.answerPhotoS3Key = oldAnswerPhotoS3Key;
                                this.$root.$emit('saved-answer-photo', answerId, "Error");
                            } else {
                                this.$root.$emit('saved-answer-photo', answerId);
                            }

                            // finished
                            testAnswer.submittingAnswer = false;
                        }
                    }
                } catch (error) {
                    console.log("failed to upload photo: " + file.name + ":" + error);
                    this.$root.$emit('saved-answer-photo', answerId, "Error");
                }
            },
            deleteAnswerPhoto: async function(answerId) {
                this.$root.$emit('deleting-answer-photo', answerId);
                const section = this.test.sections[this.currentSection - 1];
                for (let i = 0; i < section.answers.length; i++) {
                    const testAnswer = section.answers[i];

                    if (testAnswer.id === answerId && testAnswer.answerPhotoS3Key) {
                        const oldAnswerPhotoS3Key = testAnswer.answerPhotoS3Key;
                        testAnswer.submittingAnswer = true;

                        // need to have a wrapper Promise around both actions, and we manually control that promise,
                        // else if person clicks finish while deleting image, might not get removed from DB
                        try {
                            this.answerUpdatePromises[i] = await Storage.remove(testAnswer.answerPhotoS3Key, { level: 'protected' });
                            const result = await this.answerUpdatePromises[i];
                            console.log(result);
                            console.log("photo deleted: " + testAnswer.answerPhotoS3Key);
                            testAnswer.answerPhotoS3Key = null;
                        } catch (error) {
                            this.$root.$emit('deleted-answer-photo', answerId, "Error");
                            console.log("Error deleting photo from S3: " + testAnswer.answerPhotoS3Key, error);
                            testAnswer.submittingAnswer = false;
                            return;
                        }

                        // if deleting from S3 worked
                        this.answerUpdatePromises[i] = this.test.saveAnswer(testAnswer.id, testAnswer.answer, null);
                        const result = await this.answerUpdatePromises[i];
                        this.answerUpdatePromises[i] = null;
                        console.log('Answer photo deleted from server');

                        // if saving the key to the DB worked
                        if (result === null) {
                            // an error occurred updating on the server - revert do previous answer. We need to handle this better
                            testAnswer.answerPhotoS3Key = oldAnswerPhotoS3Key;
                            this.$root.$emit('saved-answer-photo', answerId, "Error");
                        } else {
                            this.$root.$emit('saved-answer-photo', answerId);
                        }

                        // finished
                        testAnswer.submittingAnswer = false;
                    }
                }
                // setTimeout(() => { this.$root.$emit('deleted-answer-photo', answerId, "Some error") }, 3000);
            },
            showSkipQuestion: function(question) {
                if (this.isSectionInProgress &&
                    this.selectedSection === this.currentSection &&
                    !question.submittingAnswer &&
                    !question.answer &&
                    this.testEntry.canSkipQuestion() &&
                    !this.noQuestionsLeft) {
                    return true;
                }
                return false;
            },
            skipQuestion: async function() {
                console.log("Skipping question in Adaptive Test");
                if (this.testEntry.canSkipQuestion()) {
                    this.loadingNextQuestion = true;
                    const success = await this.test.skipQuestion(this.currentSection - 1, this.testEntry.findTestEntrySectionID(this.test.sections[this.currentSection - 1].id));
                    console.log("Success? " + success);
                    this.testEntry.incrementSkipCounter();
                    this.loadingNextQuestion = false;
                    this.noQuestionsLeft = !success;
                    if (this.noQuestionsLeft) {
                        await this.submitTest();
                    }
                }
            },
            submitTest: async function () {
                console.log("Stopping test");
                this.showSubmittingNotification();
                await Promise.all(this.answerUpdatePromises)
                    .then(async () => {
                        console.log("All questions saved, marking test as finished");
                        this.testState = TEST_STATES.TEST_STATE_FINISHED;
                        const testResult = await this.test.entrantEndsTest(this.testEntry.findTestEntrySectionID(this.test.sections[this.currentSection - 1].id));
                        this.showTestResultNotification(testResult);
                        const section = await this.test.loadSectionForEntrant(0, this.testEntry.findTestEntrySectionID(this.test.sections[0].id));
                        if (section != null) {
                            const sectionIndex = this.test.getSectionIndexFromSectionID(section.id);
                            if (sectionIndex !== null) {
                                this.setCurrentSection(sectionIndex);
                                this.$nextTick(() => {
                                    this.jumpToQuestion(0);
                                });
                            }
                        }
                    })
                    .catch(() => {
                        console.log("Error waiting for a question to finish saving");
                    });
            },
            showSubmittingNotification: function() {
                this.notifications = [ { message: "Submitting and marking your entry" } ];
                this.showNotifications = true;
            },
            loadEndOfTestMessageForEntrant: async function() {
                console.log("Loading end of test message for entrant");
                const testResult = await this.test.fetchTestResultForEntrant(this.testEntry.id);
                this.showTestResultNotification(testResult);
            },
            showTestResultNotification: function(testResult) {
                if (testResult && testResult.message) {
                    this.notifications = [ { message: testResult.message } ];
                    this.showNotifications = true;
                    this.testResult = testResult.message;
                }
            },
            loadSectionLeaderboardForEntrant: async function(sectionIndex) {
                console.log("Loading leaderboard for section: " + sectionIndex);
                await this.test.loadSectionLeaderboard(store.user, sectionIndex);
            },
            loadOverallLeaderboardForEntrant: async function() {
                console.log("Loading overall leaderboard");
                await this.test.loadOverallLeaderboard(store.user);
            },

            // ------------------------------------
            // Teacher behaviour
            // ------------------------------------
            /**
             * This opens the test for the Teacher. If the test is invigilated, open as a teacher with teacher
             * controls. Otherwise, open as a Entrant to allow the Teacher to do the Test.
             */
            openTestForTeacher: async function() {
                if (store.user && store.user.isTeacher() && this.testHostID) {
                    console.log("Loading test (testHostID: " + this.testHostID + ") for teacher: " + store.user.getID());
                    this.test = await test(this.testHostID);
                    if (this.test) {
                        // check if the test is invigilated
                        if (this.test.isInvigilated()) {
                            // only the invigilator can run this test, if it's invigilated
                            if (this.test.host.userID && this.test.host.userID !== store.user.getID()) {
                                this.showWarningThatTeacherNotInvigilator();
                                return;
                            }
                            this.isInvigilator = true;
                            await this.test.loadAllSectionsAndSubscribeToChangesForTeacher(store.user, this.updateStateOnSectionEnded);
                            this.alignWithStartedAndFinishedSection();
                        } else {
                            // otherwise, we'll assume the teacher wants to do the non-invigilated test
                            this.openTestForEntrant();
                        }
                    }
                }
            },
            showWarningThatTeacherNotInvigilator: async function() {
                this.notifications = [];
                this.notifications.push({
                    message: this.$t('testTeacherNotInvigilatorMsg'),
                    link: {
                        text: this.$t('commonCloseMsg'),
                        callback: () => {
                            this.showNotifications = false;
                            this.$router.push({ path: '/' });
                        }
                    },
                });
                this.showNotifications = true;
                this.$root.$on('close-notifications', () => {
                    this.$router.push({ path: '/' });
                });
            },
            alignWithStartedAndFinishedSection: function() {
                console.log("checking which sections have been started and finished");
                this.currentSection = 1;
                this.selectedSection = 1;
                for (let i = 0; i < this.test.sections.length; i++) {
                    const section = this.test.sections[i];
                    if (section.startedAt !== null) {
                        console.log("section has been started");
                        this.currentSection = i+1;
                        this.selectedSection = this.currentSection;
                        if (section.endedAt === null) {
                            this.startSectionTimers(section.startedAt, section.duration);
                            this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
                        } else {
                            console.log("section has ended");
                            this.updateStateToSectionOrTestFinished(i);
                            this.currentSection++;
                        }
                    }
                }
                if (this.testState === TEST_STATES.TEST_STATE_LOADING) {
                    this.testState = TEST_STATES.TEST_STATE_BEFORE_TEST_START;
                }
            },
            startTest: function() {
                this.testState = TEST_STATES.TEST_STATE_BEFORE_SECTION_START;
                this.$nextTick(() => {
                    this.jumpToQuestion(0);
                });
            },
            getConfirmationOfStart: async function() {
                if (this.isInvigilator && this.currentSection <= this.test.sections.length && this.test.sections[this.currentSection-1].startedAt === null) {
                    // confirm that the teacher actually intended to start the test
                    this.notifications = [];
                    this.notifications.push({
                        message: this.$t('testConfirmStartTestMsg'),
                        link: {
                            text: this.$t('commonStartMsg'),
                            callback: () => {
                                this.showNotifications = false;
                                this.startSection();
                            }
                        },
                        cancelLink: {
                            text: this.$t('commonCancelMsg'),
                            callback: () => {
                                this.showNotifications = false;
                            }
                        }

                    });
                    this.showNotifications = true;
                }
            },
            startSection: async function() {
                if (this.isInvigilator && this.currentSection <= this.test.sections.length && this.test.sections[this.currentSection-1].startedAt === null) {
                    this.selectedSection = this.currentSection;
                    const currentSection = this.test.sections[this.currentSection-1];
                    const dateToStartSection = new Date((new Date()).getTime() + 30000);
                    // const dateToStartSection = new Date((new Date()).getTime() + 15000);
                    const dateToStartSectionISOString = dateToStartSection.toISOString();
                    currentSection.startedAt = dateToStartSectionISOString;
                    this.startSectionTimers(currentSection.startedAt, currentSection.duration);
                    this.testState = TEST_STATES.TEST_STATE_STARTING_SECTION;
                    await this.test.teacherStartsSection(store.user, currentSection.id, dateToStartSectionISOString);
                    this.$nextTick(() => {
                        this.jumpToQuestion(0);
                    });
                }
            },
            endSection: async function() {
                const currentSection = this.test.sections[this.currentSection-1];
                if (this.isInvigilator && currentSection.endedAt === null) {
                    console.log("Ending section");
                    this.cancelTimerCallback();
                    await this.test.teacherEndsSection(store.user, currentSection.id);
                    currentSection.endedAt = (new Date()).toISOString();
                    this.updateStateOnSectionEnded();
                }
            },
            updateStateOnSectionEnded: function() {
                this.testState = TEST_STATES.TEST_STATE_SECTION_FINISHED;
                if (this.currentSection < this.test.sections.length) {
                    this.currentSection++;
                } else {
                    this.testState = TEST_STATES.TEST_STATE_FINISHED;
                    // an Olympiad has no round selection bar - send to the end of test "page"
                    if (this.test.isOlympiad()) {
                        this.selectedSection = -1;
                    }
                }
            },
            startSectionTimers: function(dateToStartSectionISOString, duration) {
                const dateToStartSection = new Date(dateToStartSectionISOString);
                console.log("Starting countdown timer to start section at: " + dateToStartSection);
                const dateToEndSection = new Date(dateToStartSection.getTime() +  convertDurationToMillis(duration));
                console.log("Date to end: " + dateToEndSection);
                this.timer = this.invokeCallbackAtTime(dateToStartSection, () => {
                    console.log("Starting countdown timer to end section");
                    this.testState = TEST_STATES.TEST_STATE_SECTION_IN_PROGRESS;
                    this.timer = this.invokeCallbackAtTime(dateToEndSection, () => {
                        console.log("Timer is ending section - scheduled event should update and stop the section now");
                        this.endSection();
                    })
                });
            },

            // ============================================================================
            //
            // Look and feel functions
            //
            // ============================================================================
            getAnswerOptions: function(answerOptions) {
                if (answerOptions.length > 4) {
                    const options = answerOptions.substring(2, answerOptions.length - 2);
                    return options.split("|");
                }
                return [];
            },
            getAnswerTotal: function(answerOptions, answerValue, answerCounts) {
                if (answerOptions && answerValue && answerCounts) {
                    const options = answerOptions.substring(2, answerOptions.length - 2);
                    const answerOpts = options.split("|");
                    for (let i = 0; i < answerOpts.length; i++) {
                        if (answerOpts[i] === answerValue) {
                            return answerCounts[i];
                        }
                    }
                }
                return 0;
            },
            getDisplayDate: function(dateString) {
                return getDisplayDate(dateString);
            },
            getDisplayDuration: function(duration) {
                if (duration !== null && typeof(duration) !== 'undefined') {
                    if (duration.startsWith("00:")) {
                        return duration.substring(3);
                    }
                }
                return duration;
            },
            selectSection: function(number) {
                if (number <= this.currentSection || this.isInvigilator) {
                    this.selectedSection = number;
                }
            },
            toggleStatsInRounds: function() {
                this.showStats = !this.showStats;
            },
            showOverallLeaderboard: function() {
                this.selectSection(-1)
            },
            showStatisticsPage: function() {
                // Todo: temporary solution
                this.selectSection(-2);
            },
            scrollQuestions: function() {
                this.showingOverview = true;
                if (this.questionJumpScrollerTimer !== null) {
                    clearTimeout(this.questionJumpScrollerTimer);
                }
                this.questionJumpScrollerTimer = setTimeout(() => {
                    this.showingOverview = false;
                }, 2500);

                this.updateOverviewScrollPos();
                this.updateOverviewCurrentQuestionMarker();
            },
            updateOverviewScrollPos: function() {
                if (this.$refs.quizQuestions && this.$refs.quizOverviewList) {
                    const heightQuestions = this.$refs.quizQuestions.clientHeight;
                    const heightQuestionsViewport = this.$refs.chatContent.clientHeight;
                    const heightOverview = this.$refs.quizOverviewList.clientHeight;
                    const heightOverviewViewport = this.$refs.quizOverview.clientHeight;
                    const scrollPos = this.$refs.chatContent.scrollTop;
                    const newPos = scrollPos * (heightOverview - heightOverviewViewport) / (heightQuestions - heightQuestionsViewport);
                    if (this.jumpedToQuestion) {
                        setTimeout(() => {this.$refs.quizOverview.scrollTop = newPos}, 500);
                        this.jumpedToQuestion = false;
                    } else {
                        this.$refs.quizOverview.scrollTop = newPos;
                    }
                }
            },
            updateOverviewCurrentQuestionMarker: function() {
                const heightQuestionsViewport = this.$refs.chatContent.clientHeight;
                const scrollPos = this.$refs.chatContent.scrollTop;

                for (let i = 0; this.$refs.question && i < this.$refs.question.length; i++) {
                    if (this.$refs.question[i].$el.offsetTop > scrollPos &&
                        this.$refs.question[i].$el.offsetTop < scrollPos + (heightQuestionsViewport / 2)) {
                        this.questionNumberInTopHalf = i + 1;
                        break;
                    }
                }
            },
            jumpToQuestion: function(index) {
                if (this.$refs.question && this.$refs.question[index]) {
                    // the 50px is subtracted because the 'tab' for the question is absolutely positioned 40px above the top edge of the question
                    this.jumpedToQuestion = true;
                    this.$refs.chatContent.scrollTop = this.$refs.question[index].$el.offsetTop - 50;
                }
            },
        },
        watch: {
            'store.user': function () {
                this.resetAndReload();
            },
            '$route.params.testHostID': function (testHostID) {
                console.log('Test changed to testHostID: ' + testHostID);
                this.testHostID = testHostID;
                this.resetAndReload();
            },
            '$route.params.testEntryID': function (testEntryID) {
                console.log('Test entry changed to testEntryID: ' + testEntryID);
                this.testEntryID = testEntryID;
                this.resetAndReload();
            }
        },
        mounted() {
            console.log("Mounted Test");
            this.testHostID = this.$route.params.testHostID;
            this.testEntryID = this.$route.params.testEntryID;
            if (!this.testHostID) {
                this.$router.push({ path: '/signin' });
            }
            this.userChanged();

            this.$root.$on('change-answer', this.changeAnswer);
            this.$root.$on('change-answer-photo', this.changeAnswerPhoto);
            this.$root.$on('delete-answer-photo', this.deleteAnswerPhoto);
            this.$root.$on('jump-to-question', this.jumpToQuestion);
            this.$root.$on('signed-out', this.signedOut);

            this.closeNotificationsFn = () => {
                console.log("clearing notifications for this test host: " + this.testHostID);
                this.notifications = [];
                this.showNotifications = false;
            };
            this.$root.$on('close-notifications', this.closeNotificationsFn);

            this.showFormulaSheetFn = () => {
                this.showFormulaSheets = true;
            };
            this.$root.$on('show-formula-sheets', this.showFormulaSheetFn);

            this.hideFormulaSheetFn = () => {
                this.showFormulaSheets = false;
            };
            this.$root.$on('hide-formula-sheets', this.hideFormulaSheetFn);
        },
        beforeDestroy: async function () {
            if (this.test) {
                this.test.unsubscribeFromUpdates();
            }

            // if we're leaving for someone who doesn't have a proper account, log out
            if (store.user && !store.user.doesUserHaveAccount()) {
                await Auth.signOut();
            }

            this.$root.$off("change-answer", this.changeAnswer);
            this.$root.$off("change-answer-photo", this.changeAnswerPhoto);
            this.$root.$off("delete-answer-photo", this.deleteAnswerPhoto);
            this.$root.$off("jump-to-question", this.jumpToQuestion);
            this.$root.$off("signed-out", this.signedOut);
            this.$root.$off("close-notifications", this.closeNotificationsFn);
            this.$root.$off("show-formula-sheets", this.showFormulaSheetFn);
            this.$root.$off("hide-formula-sheets", this.hideFormulaSheetFn);
        },
    }
</script>

<style lang="scss" scoped>
    @import "../styles/pages/test";
    @import "../styles/layout/chat_window";
</style>
