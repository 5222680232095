export const getTodaysPuzzle = /* GraphQL */ `
  query GetTodaysPuzzle($timezoneOffsetHours: Int, $questionID: ID, $date: AWSDate, $language: String) {
    getTodaysPuzzle(timezoneOffsetHours: $timezoneOffsetHours, questionID: $questionID, date: $date, language: $language) {
      id
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerValue
      answerOptions
      placeholderText
      clueURL
      layout
    }
  }
`;

export const isUsernameTaken = /* GraphQL */ `
  query IsUsernameTaken($username: String) {
    isUsernameTaken(username: $username)
  }
`;

export const canMigrateCognitoUser = /* GraphQL */ `
  query CanMigrateCognitoUser($username: String!) {
    canMigrateCognitoUser(username: $username)
  }
`;

export const getUserByUsername = /* GraphQL */ `
  query userByUsername($username: ModelStringKeyConditionInput!) {
    userByUsername(baseType: "User", username: $username) {
      items {
        id
        email
        receiveMarketingEmails
        state
        firstName
        surname
        nickname
        mobile
        language
        grade
        type
        accountType
        gender
        race
        school
        country
        owner
        cognitoIdentityID
        linkCode
        metrics {
          items {
            id
            eloScore
          }
        }
      }
    }
  }
`;

export const getUserProfileWithMetrics = /* GraphQL */ `
  query GetUserProfileWithMetrics($id: ID!) {
    getUser(id: $id) {
      metrics {
        items {
          totalAnswered
          totalRight
          rightStreak
          wrongStreak
          longestRightStreak
          score
          totalDaysActive
          registrationQuestionsAnswered
          totalBlueAwards
          totalBronzeAwards
          totalSilverAwards
          totalGoldAwards
          totalDiamondAwards
        }
      }
    }
  }
`;

export const searchSchools = /* GraphQL */ `
  query SearchSchools(
    $filter: SearchableSchoolFilterInput
    $sort: SearchableSchoolSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSchools(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        name
        province
      }
      nextToken
      total
    }
  }
`;

export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($metricsID: ID!, $grade: Int) {
    getLeaderboard(metricsID: $metricsID, grade: $grade) {
      smId
      position
      percentile
      firstName
      surname
      nickname
      school
      teacher
      grade
      totalAnswered
      totalRight
      rightStreak
      longestRightStreak
      score
      totalDaysActive
      totalBlueAwards
      totalBronzeAwards
      totalSilverAwards
      totalGoldAwards
      totalDiamondAwards
    }
  }
`;

export const getLeaderboardForTest = /* GraphQL */ `
  query GetLeaderboardForTest($userID: ID!, $questionSetID: ID!, $leaderboardIndex: TestLeaderboardIndex!, $grade: Int, $country: String) {
    getLeaderboardForTest(userID: $userID, questionSetID: $questionSetID, leaderboardIndex: $leaderboardIndex, grade: $grade, country: $country) {
      id
      position
      email
      firstName
      surname
      nickname
      grade
      school
      country
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      percentile
    }
  }
`;
export const getSchoolLeaderboardForTest = /* GraphQL */ `
  query GetSchoolLeaderboardForTest($userID: ID!, $questionSetID: ID!, $leaderboardIndex: TestLeaderboardIndex!, $school: String) {
    getSchoolLeaderboardForTest(userID: $userID, questionSetID: $questionSetID, leaderboardIndex: $leaderboardIndex, school: $school) {
      position
      school
      top10
      entries
      averageScore
      percentile
    }
  }
`;

export const getMyStudents = /* GraphQL */ `
  query GetMyStudents($teacherID: ID!, $grade: Int, $timezoneOffsetHours: Int!) {
    getMyStudents(teacherID: $teacherID, grade: $grade, timezoneOffsetHours: $timezoneOffsetHours) {
      id
      student
      school
      grade
      score
      performanceLast7Days {
        totalAnswered
        totalRight
        longestRightStreak
        highestEloScore
        endScore
      }
      performanceToday {
        totalAnswered
        totalRight
        longestRightStreak
        highestEloScore
        endScore
      }
    }
  }
`;


export const getMyStudentsTestEntries = /* GraphQL */ `
  query GetMyStudentsTestEntries($teacherID: ID!) {
    getMyStudentsTestEntries(teacherID: $teacherID) {
      testName
      id
      score
      scoreAndTime
      questionsAnswered
      timeTaken
      email
      firstName
      surname
      nickname
      grade
      entrySections {
        id
        testSectionID
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
      }
      createdAt
    }
  }
`;

export const getMyStudentsWorksheetEntries = /* GraphQL */ `
  query GetMyStudentsWorksheetEntries($teacherID: ID!) {
    getMyStudentsWorksheetEntries(teacherID: $teacherID) {
      worksheetName
      id
      email
      firstName
      surname
      nickname
      grade
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
    }
  }
`;

export const getTestsHosting = /* GraphQL */ `
  query TestHostsByHostAndDate($userID: ID!) {
    testHostsByHostAndDate(userID: $userID, sortDirection: ASC, limit: 500) {
      items {
        id
        linkCode
        testDate
        createdAt
        test {
          id
          name
          minGrade
          maxGrade
          date
          totalRegistered
          organisation {
            name
            logo
          }
          questionSet {
            id
            name
            format
            invigilation
            minGrade
            maxGrade
            publicOrPrivate
            suggestedDuration
            registrationStart
            registrationEnd
            registrationRequiresPriorTest
            termsAndConditionsURL
            participationStart
            participationEnd
          }          
        }
      }
    }
  }
`;

export const getTestHost = /* GraphQL */ `
  query GetTestHost($id: ID!) {
    getTestHost(id: $id) {
      id
      linkCode
      testDate
      createdAt
      test {
        id
        name
        minGrade
        maxGrade
        date
        totalRegistered
        organisation {
          name
          logo
        }
        questionSet {
            format
            publicOrPrivate
            registrationStart
            registrationEnd
            participationStart
            participationEnd
        }
      }
    }
  }
`;

export const getTestsEntries = /* GraphQL */ `
  query GetTestEntries($userID: ModelIDKeyConditionInput!) {
    testEntriesByUser(baseType: "TestEntry", userID: $userID) {
      items {
        id
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
        score
        createdAt
        test {
          id
          name
          date
          totalRegistered
          questionSet {
            id
            name
            minGrade
            maxGrade
            suggestedDuration
            registrationStart
            registrationEnd
            registrationRequiresPriorTest
            termsAndConditionsURL
            participationStart
            participationEnd
            invigilation
            format
          }
        }
        testHost {
          id
          testDate
          linkCode
        }
      }
    }
  }
`;

export const getTestEntry = /* GraphQL */ `
  query GetTestEntry($id: ID!) {
    getTestEntry(id: $id) {
      id
      participationStart
      startedAt
      scheduledToFinishAt
      finishedAt
      timeTaken
      score
      createdAt
      test {
        id
        name
        minGrade
        maxGrade
        date
        totalRegistered
        organisation {
          name
          logo
        }
        questionSet {
          id
          name
          invigilation
          registrationStart
          registrationEnd
          participationStart
          participationEnd
          formulaSheetSlides {
            items {
              number
              slideURL
            }
          }
        }
      }
      testHost {
        id
        testDate
      }
      entrySections {
        items {
          id
          testSectionID
        }
      }
    }
  }
`;

export const getTestHostWithTestByID = /* GraphQL */ `
  query GetTestHost($id: ID!) {
    getTestHost(id: $id) {
      id
      userID
      testDate
      test {
        id
        name
        minGrade
        maxGrade
        date
        organisation {
          id
          name
          logo
        }
        questionSet {
          id
          name
          logo
          minGrade
          maxGrade
          suggestedDuration
          participationStart
          participationEnd
          invigilation
          instructions
          format
          adaptiveTestConfiguration {
            maxSkips
          }
          markingConfiguration {
            whenToShow
            whenToShowDate
          }
          formulaSheetSlides {
            items {
              number
              slideURL
            }
          }
        }
        sections(limit: 100) {
          items {
            id
            number
            duration
            startedAt
            endedAt
          }
        }
      }
    }
  }
`;

export const testEntriesByTestHostAndUser = /* GraphQL */ `
  query TestEntriesByTestHostAndUser(
    $testHostID: ID
    $userID: ModelIDKeyConditionInput
  ) {
    testEntriesByTestHostAndUser(
      testHostID: $testHostID
      userID: $userID
    ) {
      items {
        id
        questionsSkipped
        participationStart
        startedAt
        finishedAt
      }
    }
  }
`;

export const getTestForTeacher = /* GraphQL */ `
  query GetTestForTeacher($teacherID: ID!, $testID: ID!) {
    getTestForTeacher(teacherID: $teacherID, testID: $testID) {
      sections {
        id
        testSectionID
        number
        duration
        startedAt
        endedAt
        sectionScore
        sectionScoreAndTime
        totalScore
        questionsAnswered
        timeTaken
        answers {
          id
          number
          points
          questionURLs
          answerRightURLs
          answerWrongURLs
          answerOptions
          placeholderText
          answerValue
          clueURL
          layout
          answer
          answerDate
          correct
          performance {
            id
            testQuestionID
            totalAnswered
            answerCounts
          }
        }
        performance {
          id
          testSectionID
          totalStarted
          totalSubmitted
        }
      }
      entries {
        id
        score
        scoreAndTime
        questionsAnswered
        timeTaken
        email
        firstName
        surname
        nickname
        entrySections {
          id
          testSectionID
          sectionScore
          sectionScoreAndTime
          totalScore
          questionsAnswered
          timeTaken
        }
      }
    }
  }
`;

export const getTestsEntriesForTeacher = /* GraphQL */ `
  query GetTestEntriesForTeacher($teacherID: ID!, $testID: ID!) {
    getTestEntriesForTeacher(teacherID: $teacherID, testID: $testID) {
      id
      score
      questionsAnswered
      timeTaken
      email
      firstName
      surname
      nickname
      createdAt
      entrySections {
          timeTaken
          questionsAnswered
      }
    }
  }
`;

export const getPracticeQuestions = /* GraphQL */ `
query GetPracticeQuestions($testID: ID!) {
  getPracticeQuestions(testID: $testID) {
      id
      number
      questionURLs
      answerRightURLs
      answerWrongURLs
      answerOptions
      placeholderText
      answerValue
      layout
    }
  }
`;

export const getOverallLeaderboard = /* GraphQL */ `
  query GetTestLeaderboardForEntrant($userID: ID!, $testID: ID!) {
    getTestLeaderboardForEntrant(userID: $userID, testID: $testID) {
      id
      firstName
      surname
      score
      scoreAndTime
      questionsAnswered
      timeTaken
    }
  }
`;

export const getSectionLeaderboard = /* GraphQL */ `
query GetTestSectionLeaderboardForEntrant($userID: ID!, $testSectionID: ID!) {
  getTestSectionLeaderboardForEntrant(userID: $userID, testSectionID: $testSectionID) {
      id
      firstName
      surname
      sectionScore
      sectionScoreAndTime
      totalScore
      questionsAnswered
      timeTaken
    }
  }
`;

export const questionSetsPublic = /* GraphQL */ `
  query QuestionSetsByPublicOrPrivate(
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByPublicOrPrivate(
      baseType: "QuestionSet"
      publicOrPrivate: {eq: "public" }
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        minGrade
        maxGrade
        suggestedDuration
        registrationStart
        registrationEnd
        participationStart
        participationEnd
        invigilation
        format
      }
      nextToken
    }
  }
`;

export const questionSetsUserCanScheduleByID = /* GraphQL */ `
  query QuestionSetsUserCanScheduleByID(
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetSchedulerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsUserCanScheduleByID(
      baseType: "QuestionSetScheduler"
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        questionSet {
          id
          name
          minGrade
          maxGrade
          suggestedDuration
          registrationStart
          registrationEnd
          registrationRequiresPriorTest
          participationStart
          participationEnd
          invigilation
          format
        }
      }
      nextToken
    }
  }
`;

export const questionSetsByRegistrationEnd = /* GraphQL */ `
  query QuestionSetsByRegistrationEnd(
    $registrationEnd: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByRegistrationEnd(
      publicOrPrivate: "private"
      registrationEnd: $registrationEnd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        format
        invigilation
        minGrade
        maxGrade
        publicOrPrivate
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        termsAndConditionsURL
        participationStart
        participationEnd
        tests(limit: 1) {
          items {
            id
            name
            date
            totalRegistered
            testHosts(limit: 1) {
              items {
                id
                testDate
                linkCode
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const questionSetsByRating = /* GraphQL */ `
  query QuestionSetsByRating(
    $rating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionSetsByRating(
      publicOrPrivate: "private"
      rating: $rating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        minGrade
        maxGrade
        rating
        suggestedDuration
        registrationStart
        registrationEnd
        registrationRequiresPriorTest
        participationStart
        participationEnd
        invigilation
        format
        tests(limit: 1) {
          items {
            id
            name
            date
            totalRegistered
            testHosts(limit: 1) {
              items {
                id
                testDate
                linkCode
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const testsForTeacherToMark = /* GraphQL */ `
  query TestsForTeacherToMark(
    $userID: ModelIDKeyConditionInput
  ) {
    testsForTeacherToMark(
      baseType: "TestMarker"
      userID: $userID
    ) {
      items {
        id
        baseType
        test {
          id
          name
          minGrade
          maxGrade
          date
          totalRegistered
          totalStarted
        }
      }
    }
  }
`;

export const testEntriesByTestAndMarkingState = /* GraphQL */ `
  query TestEntriesByTestAndMarkingState(
    $testID: ID
  ) {
    testEntriesByTestAndMarkingState(
      testID: $testID
      marked: {eq: "ManualMarking"}
    ) {
      items {
        id
        userID
        score
        questionsAnswered
        participationStart
        startedAt
        scheduledToFinishAt
        finishedAt
        timeTaken
      }
    }
  }
`;

export const getTestsCanEnterWithoutAccount = /* GraphQL */ `
  query GetTestsCanEnterWithoutAccount {
    getTestsCanEnterWithoutAccount {
      name
      logo
      suggestedDuration
      participationEnd
      minGrade
      maxGrade
      termsAndConditionsURL
      testHostLinkCode
    }
  }
`;

export const getTeacherDetails = /* GraphQL */ `
  query GetTeacherDetails($linkCode: String) {
    getTeacherDetails(linkCode: $linkCode) {
      name
      school
    }
  }
`;

export const schedulersAuthorisedByUser = /* GraphQL */ `
  query SchedulersAuthorisedByUser(
    $authorisedByID: ModelIDKeyConditionInput
  ) {
    schedulersAuthorisedByUser(
      baseType: "QuestionSetScheduler"
      authorisedByID: $authorisedByID
    ) {
      items {
        id
        questionSetID
        studentEmail
        authorisedByID
      }
      nextToken
    }
  }
`;

export const getFriendProfile = /* GraphQL */ `
  query GetFriendProfile($friendID: ID!) {
    getFriendProfile(friendID: $friendID) {
      id
      username
      email
      notificationEmail
      firstName
      surname
      nickname
      mobile
      language
      grade
      type
      accountType
      country
      school
      gender
      race
      owner
      linkCode
        metrics {
          items {
            totalAnswered
            totalRight
            rightStreak
            wrongStreak
            longestRightStreak
            score
            totalDaysActive
            registrationQuestionsAnswered
            totalBlueAwards
            totalBronzeAwards
            totalSilverAwards
            totalGoldAwards
            totalDiamondAwards
          }
        }
      }
  }
`;

export const listWorksheets = /* GraphQL */ `
  query ListWorksheets($limit: Int, $nextToken: String) {
    listWorksheets(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tag
        instructions
        createdAt
      }
    }
  }
`;

export const worksheetsByPrefix = /* GraphQL */ `
  query WorksheetsByPrefix($baseType: String $name: ModelStringKeyConditionInput) {
    worksheetsByPrefix(baseType: $baseType, name: $name) {
      items {
        id
        name
        tag
        instructions
        createdAt
      }
    }
  }
`;

export const allWorksheetEntriesForUser = /* GraphQL */ `
  query AllWorksheetEntriesForUser($userID: ID!) {
    allWorksheetEntriesForUser(userID: $userID) {
      items {
        id
        worksheetID
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        owner
        updatedAt
      }
    }
  }
`;

export const openWorksheetEntry = /* GraphQL */ `
  query OpenWorksheetEntry($worksheetEntryID: ID!) {
    openWorksheetEntry(worksheetEntryID: $worksheetEntryID) {
        id
        startedAt
        finishedAt
        streak
        answered
        correct
        createdAt
        worksheet {
            id
            name
            tag
            instructions
            createdAt
        }
        answers {
            id
            number
            answer
            answeredAt
            correct
            createdAt
            questionURLs
            answerRightURLs
            answerWrongURLs
            answerOptions
            placeholderText
            answerValue
            clueURL
        }
    }
  }
`;

export const getWorksheetEntryHistory = /* GraphQL */ `
  query GetWorksheetEntryHistory($worksheetEntryID: ID!, $nextToken: String) {
    getWorksheetEntryHistory(worksheetEntryID: $worksheetEntryID, nextToken: $nextToken) {
      answers {
          id
          number
          answer
          answeredAt
          correct
          createdAt
          questionURLs
          answerRightURLs
          answerWrongURLs
          answerOptions
          placeholderText
          answerValue
          clueURL
      }
      nextToken
    }
  }
`;

export const openWorksheetEntryForTeacher = /* GraphQL */ `
  query OpenWorksheetEntryForTeacher($worksheetEntryID: ID!) {
    openWorksheetEntryForTeacher(worksheetEntryID: $worksheetEntryID) {
      id
      streak
      answered
      correct
      startedAt
      finishedAt
      createdAt
      worksheet {
        id
        name
        tag
        instructions
        nextQuestionStrategy
        progression
        createdAt
      }
      answers {
        id
        number
        answer
        answeredAt
        correct
        createdAt
        questionURLs
        answerRightURLs
        answerWrongURLs
        answerOptions
        placeholderText
        answerValue
        clueURL
        layout
      }
    }
  }
`;

export const homePageEventsToDisplay = /* GraphQL */ `
  query HomePageEventsToDisplay($language: String, $displayTo: ModelStringKeyConditionInput) {
    homePageEventsToDisplay(language: $language displayTo: $displayTo sortDirection: ASC) {
      items {
        id
        name
        hostedByMyTutor
        imageURL
        entryURL
        startDate
        dateMessage
        displayFrom
        displayTo
        language
      }
    }
  }
`;