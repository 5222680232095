import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "../views/Home.vue";
import Register from "../views/Register";
import SignIn from "../views/SignIn";
import PasswordReset from "../views/PasswordReset";
import EventEntryNoAccount from "../views/EventEntryNoAccount";
import Chat from "../views/Chat";
import Test from "../views/Test.vue";
import Worksheet from "../views/Worksheet.vue";
import ManualMark from "../views/ManualMark.vue";
import EventEntryExternal from "../views/EventEntryExternal";
import OpenTestEntry from "../views/OpenTestEntry";
import Unsubscribe from "../views/Unsubscribe";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordReset
    },
    {
        path: '/login',
        redirect: '/signin'
    },
    {
        path: '/event-entry',
        name: '',
        component: EventEntryNoAccount
    },
    {
        path: '/event-entry-external',
        name: '',
        component: EventEntryExternal
    },
    {
        path: '/test-entry/:testEntryID?',
        name: '',
        component: OpenTestEntry
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat
    },
    {
        path: '/test/:testHostID/:testEntryID?',
        name: 'test',
        component: Test
    },
    {
        path: '/worksheet/:worksheetOrEntryID?/',
        name: 'worksheet',
        component: Worksheet
    },
    {
        path: '/manual-mark/:id',
        name: 'manual-mark',
        component: ManualMark
    },
    {
        path: '/unsubscribe',
        name: '',
        component: Unsubscribe
    },
];

const router = new VueRouter({
    routes: routes,
    mode: 'history',
})
  
export default router