import dayjs from 'dayjs';

const millisInHour = 3600000;
const millisInMinute = 60000;
const millisInSecond = 1000;

const sortByNumber = function(list) {
    if (list) {
        list.sort((a, b) => {
            const first = parseInt(a.number);
            const second = parseInt(b.number);
            if (first < second) {
                return -1;
            } else if (first > second) {
                return 1;
            } else {
                return 0;
            }
        });
    }
};

const sortByField = function(list, sortFieldName) {
    list.sort((first, second) => {
        if (second[sortFieldName] > first[sortFieldName]) {
            return 1;
        } else if (second[sortFieldName] < first[sortFieldName]) {
            return -1;
        } else {
            return 0;
        }
    });
};

const convertAWSTimeToMillis = function(duration) {
    let millis = 0;
    let parts = duration.split(":");
    const indexForMillis = parts[2].indexOf(".");
    if (indexForMillis !== -1) {
        millis = parseInt(parts[2].substring(indexForMillis+1));
        parts[2] = parts[2].substring(0, indexForMillis);
    }
    millis += (parseInt(parts[2]) * millisInSecond) + (parseInt(parts[1]) * millisInMinute) + (parseInt(parts[0]) * millisInHour);
    return millis;
};

const convertMillisToDuration = function(millis) {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    hours = Math.floor(millis / millisInHour);
    millis = millis % millisInHour;

    if (millis > 0) {
        minutes = Math.floor(millis / millisInMinute);
        millis = millis % millisInMinute;
    }

    if (millis > 0) {
        seconds = Math.floor(millis / millisInSecond);
        millis = millis % millisInSecond;
    }

    return padIfLessThan10(hours) + ":" + padIfLessThan10(minutes) + ":" + padIfLessThan10(seconds);
};

let convertDurationToMillis = function(duration) {
    const time = duration.split(":");
    return (time[2] * 1000) + (time[1] * 60000) + (time[0] * 3600000);
};

const convertMillisToTime = function(millis) {
    const hours = Math.floor(millis / 3600000);
    const minutes = Math.floor((millis % 3600000) / 60000);
    const seconds = Math.floor((millis % 60000) / 1000);
    return padIfLessThan10(hours) + ":" + padIfLessThan10(minutes) + ":" + padIfLessThan10(seconds);
};

const isDateInPast = function (dateString) {
    return dayjs().isAfter(dayjs(dateString));
};

const getDisplayDate = function(dateString) {
    if (dateString) {
        const date = dayjs(dateString);
        return date.format('DD MMM [at] HH:mm');
    }
    return "";
};

const getDescriptiveDisplayDate = function (dateString) {
    const date = dayjs(dateString);
    const today = dayjs().startOf('day');
    const yesterday = today.subtract(1, 'day');
    const weekAgo = yesterday.subtract(5, 'day');
    if (date.isAfter(today)) {
        return "Today";
    } else if (date.isAfter(yesterday)) {
        return "Yesterday";
    } else if (date.isAfter(weekAgo)) {
        return date.format("ddd");
    } else {
        return date.format('D MMM');
    }
};

const getDisplayDateForEditing = function(dateString) {
    if (dateString) {
        const date = dayjs(dateString);
        return date.format('YYYY-MM-DD HH:mm');
    }
    return "";
};

const getDisplayDateMobile = function(dateString) {
    if (dateString) {
        const date = dayjs(dateString);
        return date.format('DD MMM @ HH:mm');
    }
    return "";
};

const getDisplayDuration = function(duration) {
    if (duration !== null && typeof(duration) !== 'undefined') {
        if (duration.startsWith("00:")) {
            return duration.substring(3);
        }
    }
    return duration;
};

const padIfLessThan10 = function(val) {
    return (val >= 10) ? val : "0" + val;
};

const padIfLessThan100 = function(val) {
    if (val < 10) {
        return "00" + val;
    } else if (val < 100) {
        return "0" + val;
    } else {
        return val;
    }
};

export {
    sortByNumber,
    sortByField,
    convertAWSTimeToMillis,
    convertMillisToDuration,
    convertDurationToMillis,
    convertMillisToTime,
    isDateInPast,
    getDisplayDate,
    getDescriptiveDisplayDate,
    getDisplayDateForEditing,
    getDisplayDateMobile,
    getDisplayDuration,
    padIfLessThan10,
    padIfLessThan100
};
