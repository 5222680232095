<template>
    <div class="leaderboard leaderboard--full-width">
        <div class="close" v-on:click="$root.$emit('close-my-students-worksheet-entries')">&times;</div>
        <div class="title">
            <div class="title__heading">
                <span>{{ $t('myStudentsWorksheetEntriesTitleMsg')}}</span>
            </div>
        </div>
        <table class="board" id="datatable">
            <thead>
            <tr>
                <th class="board-hdr__num--wide board-hdr--nostretch">#</th>
                <th class="board-hdr__name">{{ $t('commonLeaderboardEventMsg')}}</th>
                <th class="board-hdr__num--wide board-hdr--nostretch">{{ $t('commonLeaderboardGradeMsg')}}</th>
                <th class="board-hdr__name board-hdr--nostretch">{{ $t('commonLeaderboardDateMsg')}}</th>
                <th class="board-hdr__name board-hdr--nostretch">{{ $t('commonLeaderboardFirstNameMsg')}}</th>
                <th class="board-hdr__name board-hdr--nostretch">{{ $t('commonLeaderboardSurnameMsg')}}</th>
                <th class="board-hdr__score board-hdr--nostretch">{{ $t('commonLeaderboardAnsweredAbrMsg')}}</th>
                <th class="board-hdr__score board-hdr--nostretch">{{ $t('commonLeaderboardCorrectMsg')}}</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="loadingTestEntries">
                <tr class="board__loading">
                    <td colspan="7">
                        <spinner height="50px" colour="#F9AC1B"/>
                    </td>
                </tr>
            </template>
            <template v-if="!loadingTestEntries">
                <tr v-for="(entry, index) in testEntries" v-bind:key="index" v-on:click="openWorksheetEntry(entry.id)">
                    <td class="board__num">{{index+1}}</td>
                    <td class="board__name">{{entry.worksheetName}}</td>
                    <td class="board__num"><span v-if="entry.grade">{{entry.grade}}</span><span v-else>?</span></td>
                    <td class="board__date">{{getDisplayDate(entry.createdAt)}}</td>
                    <td class="board__name">{{entry.firstName}}</td>
                    <td class="board__name">{{entry.surname}}</td>
                    <td class="board__score">{{entry.answered}}</td>
                    <td class="board__score">{{entry.correct}}</td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-dt';

    import dayjs from 'dayjs';
    import {API, graphqlOperation} from "@aws-amplify/api";

    // Our custom queries & mutations
    import {getMyStudentsWorksheetEntries} from "../mytutor-graphql/queries";

    // Visual components
    import Spinner from './Spinner.vue';

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";

    export default {
        name: 'MyStudentsWorksheetEntries',
        components: {
            Spinner
        },
        mixins: [apiMixin],
        props: {
            teacher: Object
        },
        data() {
            return {
                loadingTestEntries: true,
                worksheetEntries: [],
            }
        },
        computed: {},
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            fetchMyStudentsTestEntriesFromServer: async function (teacher) {
                console.log('fetching entrants test worksheets for teacher with ID: ' + teacher.getID());
                try {
                    let worksheetEntriesRet = await API.graphql(graphqlOperation(getMyStudentsWorksheetEntries, {teacherID: teacher.getID()}));
                    console.log(worksheetEntriesRet);
                    if (worksheetEntriesRet.data.getMyStudentsWorksheetEntries !== null) {
                        console.log("Test worksheets found");
                        return worksheetEntriesRet.data.getMyStudentsWorksheetEntries;
                    } else {
                        console.log("Error fetching test worksheets");
                        this.logError("Fetching test worksheets", "No data returned", false);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching test worksheets", error, false);
                }
            },
            // ----------------------------------------------------------------------------
            //
            // MyStudentsTestEntries behaviour
            //
            // ----------------------------------------------------------------------------
            getDisplayDate: function (dateString) {
                const date = dayjs(dateString);
                return date.format('YYYY-MM-DD HH:mm');
            },
            loadWorksheetEntries: async function () {
                console.log("Loading MyStudents");
                this.loadingTestEntries = true;
                this.testEntries = await this.fetchMyStudentsTestEntriesFromServer(this.teacher);
                // safety, in case an error occurs
                if (!this.loadingTestEntries) {
                    this.loadingTestEntries = [];
                }
                this.loadingTestEntries = false;

                setTimeout(() => {
                    new DataTable("#datatable", {
                        lengthMenu: [
                            [5, 10, 25, 50, -1],
                            [5, 10, 25, 50, "All"],
                        ],
                        pageLength: 50,
                        language: {
                            "emptyTable": this.$t('myStudentsWorksheetEntriesNoEntriesMsg')
                        },
                    });
                });
            },
            openWorksheetEntry: function (worksheetEntryID) {
                console.log("Open worksheet entry: " + worksheetEntryID);
                let newPath = '/worksheet/' + worksheetEntryID + "/?observer=true";
                if (this.$router.currentRoute.path === newPath) {
                    console.log("route identical, ignoring");
                } else {
                    this.$router.push({path: newPath});
                }
                this.closeDialog();
            },
            closeDialog: function () {
                this.$root.$emit('close-my-students-worksheet-entries');
            }
        },
        mounted() {
            console.log("Mounted MyStudentsTestEntries");
            this.loadWorksheetEntries();
        }
    }
</script>

<style lang="scss" scoped>
    .leaderboard {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: #1C1A4F;
        padding: 34px 40px;
        overflow-y: auto;
    }

    .leaderboard--full-width {
        position: fixed;
        left: 0;
    }

    .close {
        text-align: right;
        color: white;
        line-height: 23px;
        font-size: 46px;
    }

    .close:hover {
        cursor: pointer;
    }

    .flag {
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .title {
        color: white;
        z-index: 1000;
        text-align: left;
        /* font-size: 46px; */
    }

    .title__flag {
        padding-left: 10px;
        display: inline-block;
        vertical-align: top;
        padding-top: 6px;
    }

    .title__heading {
        margin-top: 20px;
        padding-left: 10%;
        display: inline-block;
    }

    .title span {
        display: block;
        text-align: left;
        font: normal normal 600 46px/48px Inter;
    }

    .title span.grade {
        font: normal normal 300 46px/48px Inter;
    }

    .leaderboard__grade-selector {
        margin-top: 15%;
        display: flex;
        flex-direction: row;
    }

    .leaderboard__grade-selector-arrow {
        width: 56px !important;
    }

    .leaderboard__grade-selector ul {
        flex: 1;
        list-style-type: none;
        background-color: #14123E;
        border-radius: 33px;
        padding: 10px 16px;
        margin: 0 23px;
        overflow: hidden;
        display: table;
    }

    .leaderboard__grade-selector li {
        text-align: center;
        font: normal normal normal 20px/24px Inter;
        letter-spacing: 0.6px;
        color: #B8BABD;
        display: table-cell;
        text-align: center;
    }

    .leaderboard__grade-selector li > span {
        display: inline-block;
        box-sizing: border-box;
        border: #14123E 2px solid;
        padding: 10px 20px;
        width: 80px;
        height: 50px;
    }

    .leaderboard__grade-selector li > span:hover {
        cursor: pointer;
        border: #5469F3 2px solid;
        box-shadow: 2px 2px 2px #00000066;
        border-radius: 23px;
    }

    .leaderboard__grade-selector--selected {
        background: #5469F3 0% 0% no-repeat padding-box;
        box-shadow: 2px 2px 2px #00000066;
        border-radius: 23px;
    }


    .board {
        margin-top: 30px;
        width: 100%;
        color: white;
        border-collapse: collapse;
        table-layout: auto;
    }

    .board th {
        padding: 10px 3px;
        color: #B8BABD;
        text-align: left;
        font: normal normal medium Inter;
        font-size: 14px;
        line-height: 32px;

        &::before {
            color: white;
        }

        &::after {
            color: white;
        }
    }

    .board td {
        padding: 17px 3px;
        text-align: left;
        font: normal normal medium Inter;
        font-size: 20px;
        line-height: 32px;
        border-top: 1px solid #363374;
        box-shadow: 0 -1px 0 0 black;

        &:hover {
            cursor: pointer;
        }
    }

    .board-hdr__hll, .board-hdr__hlr {
        min-width: 40px;
        width: 40px;
    }

    .board-hdr__num {
        min-width: 20px;
        width: 20px;
    }

    .board-hdr__num--wide {
        min-width: 60px;
        width: 60px;
    }

    .board-hdr--nostretch {
        white-space: nowrap;
    }

    .board-hdr__total {
        width: 80px !important;
        min-width: 50px !important;
        text-align: center !important;
    }

    .board-hdr__score {
        min-width: 28px;
        width: 28px;
        text-align: right !important;
    }

    .board__name {
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .board__date {
        min-width: 190px;
        width: 190px;
    }

    .board__total {
        text-align: right !important;
        padding-right: 30px !important;
    }

    .board__score {
        text-align: right !important;
        font: normal normal bold 20px/32px Inter;
    }

    tr.board__row--selected td {
        background: #5469F3 0% 0% no-repeat padding-box;
        box-shadow: 2px 2px 2px #00000066;
        border-top: none;
    }

    tr.board__row--selected td:first-child {
        border-color: #1C1A4F;
        border-radius: 70px 0 0 70px;
    }

    tr.board__row--selected td:last-child {
        border-color: #1C1A4F;
        border-radius: 0 70px 70px 0;
    }

    tr.board__row--above-selected td {
        box-shadow: none;
        border-top: none;
    }

    @media screen and (max-width: 600px) {
        .leaderboard {
            padding: 15px 10px;
        }

        .flag {
            top: -50px;
        }

        .title__flag {
            padding-left: 20px;
        }

        .title__flag-top {
            font: normal normal bold 16px/16px Inter !important;
        }

        .title__heading {
            padding-left: 15%;
        }

        .title span {
            font: normal normal 600 30px/32px Inter;
        }

        .title span.grade {
            font: normal normal 300 30px/32px Inter;
        }

        .leaderboard__grade-selector {
            border-radius: 22px;
            padding: 5px 8px;
        }

        .leaderboard__grade-selector li {
            text-align: center;
            font: normal normal normal 14px/18px Inter;
        }

        .leaderboard__grade-selector li span {
            padding: 5px 5px;
            height: 32px;
            width: 40px;
        }

        .board th {
            padding: 8px 3px;
            font-size: 12px;
        }

        .board td {
            padding: 8px 3px;
            font-size: 14px;
        }

        .board-hdr__hll, .board-hdr__hlr {
            min-width: 20px;
            width: 20px;
        }

        .board-hdr__num {
            min-width: 20px;
            width: 20px;
        }

        .board-hdr__num--wide {
            min-width: 60px;
            width: 60px;
        }

        .board-hdr__total {
            width: 40px !important;
            min-width: 30px !important;
            text-align: center !important;
        }

        .board__total {
            text-align: right !important;
            padding-right: 15px !important;
        }

        .board-hdr__score {
            min-width: 28px;
            width: 28px;
        }

    }
</style>

<style scoped>
    @import "../styles/components/datatables.css";
</style>