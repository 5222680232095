/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://2dewpbpzyfg2fda53afmt54cyi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:c7ff86da-87c3-41be-b43f-1824a45a644d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_T1LnUJeev",
    "aws_user_pools_web_client_id": "4k59nd8brtdo3q8fgph50joqup",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mytutor-prod-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2eqeodevjk02l.cloudfront.net",
    "aws_user_files_s3_bucket": "mytutor-user-photos85851-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
