<template>
  <main-layout>
    <p>{{ $t('page404Msg')}}</p>
  </main-layout>
</template>

<script>
  import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      MainLayout
    }
  }
</script>