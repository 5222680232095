<template>
    <div class="form-entry">

        <template v-if="isCreatingAccount">
            <div class="text-center form-entry__header">
<!--                <div>-->
<!--                    <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">-->
<!--                        <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}-->
<!--                    </button>-->
<!--                </div>-->
                <a v-on:click="$router.push({ path: '/' })" class="logo-link"><img src="../assets/logo-home.svg"/></a>
                <h1>{{ $t('registerTitleMsg')}}</h1>
                <h5 v-html="$t('registerSubTitleMsg')"></h5>
            </div>

            <form v-on:submit.prevent="register()">
                <ul>
                    <li>
                        <label for="firstName">{{ $t('registerLabelFirstNameMsg')}}</label>
                        <input type="text" id="firstName" v-model="firstName">
                        <p v-if="showValidateErrorFirstName" class="form-entry__error">
                            {{ $t('registerEnterFirstNameMsg')}}
                        </p>
                    </li>
                    <li>
                        <label for="lastName">{{ $t('registerLabelLastNameMsg')}}</label>
                        <input type="text" id="lastName" v-model="lastName">
                        <p v-if="showValidateErrorLastName" class="form-entry__error">
                            {{ $t('registerEnterLastNameMsg')}}
                        </p>
                    </li>
                    <li>
                        <label for="username">{{ $t('commonLabelUsernameMsg')}}</label>
                        <p class="form-entry--note">{{ $t('registerUsernameNote1Msg')}}</p>
                        <p class="form-entry--note">{{ $t('registerUsernameNote2Msg')}}</p>
                        <div class="form-entry__field-validation">
                            <input type="text" id="username" v-model="username" v-on:blur="testIfUserNameTaken" v-on:keydown="checkKeyDownValidUsernameChar($event)">
                            <div class="form-entry__field-validation__indicator">
                                <spinner v-show="checkingUsername" height="20px" colour="#fff"/>
                                <img v-show="showUsernameTaken" src="../assets/cross-red.svg" class="form-entry__field-validation__indicator--wrong">
                                <img v-show="showUsernameUnique" src="../assets/tick-green.svg" class="form-entry__field-validation__indicator--right">
                            </div>
                        </div>
                        <p v-if="showUsernameTaken" class="form-entry__error">
                            {{ $t('registerUsernameTakenMsg')}}<br>
                            {{ $t('registerSignInInsteadPart1Msg')}} <a href="#" v-on:click="$router.push({ path: '/signin' })">{{ $t('registerSignInInsteadPart2Msg')}}</a> {{ $t('registerSignInInsteadPart3Msg')}}?
                        </p>
                        <p v-if="showValidateErrorUsername" class="form-entry__error">
                            {{ $t('registerEnterUsernameMsg')}}
                        </p>
                    </li>
                    <li>
                        <label for="password">{{ $t('commonPasswordMsg')}}</label>
                        <input type="password" ref="passwordInput" id="password" v-model="password">
                        <div class="form-entry--show-password">
                            <input id="showPassword" type="checkbox" v-model="showPassword">
                            <label for="showPassword">{{ $t('commonShowPasswordMsg')}}</label>
                        </div>
                        <p v-if="showValidateErrorPassword" class="form-entry__error">
                            {{ $t('commonEnterPasswordMsg')}}
                        </p>
                    </li>
                    <li>
                        <label for="email">{{ $t('registerLabelEmailMsg')}} <span class="form-entry--note">({{ $t('registerEmailRecommendedMsg')}})</span></label>
                        <p class="form-entry--note">{{ $t('registerNote1EmailMsg')}}</p>
                        <p class="form-entry--note">{{ $t('registerNote2EmailMsg')}}</p>
                        <input type="email" id="email" v-model="email">
                        <p v-if="showValidateErrorEmail" class="form-entry__error">
                            {{ $t('registerEmailInvalidMsg')}}
                        </p>
                    </li>
                    <li v-if="errorMessage" >
                        <p class="form-entry__error">
                            {{ $t('registerEmailRegistrationErrorMsg')}}<br>{{errorMessage}}
                        </p>
                    </li>
                    <li v-show="registering">
                        <spinner height="40px" colour="#fff"/>
                    </li>
                    <li v-show="!registering">
                        <button type="submit">{{ $t('commonSignUpMsg')}}</button>
                        <p class="text-center">{{ $t('commonAlreadyHaveAccountMsg')}} <a href="#" v-on:click="$router.push({ path: '/signin' })">{{ $t('commonSignInMsg')}}</a></p>
                    </li>
                </ul>
            </form>
        </template>

        <template v-if="isAboutYou">
            <div class="text-center form-entry__header">
                <a v-on:click="$router.push({ path: '/' })" class="logo-link"><img src="../assets/logo-home.svg"/></a>
                <h1>{{ $t('registerAboutYouHeadingMsg')}}</h1>
                <h5 v-html="$t('registerAboutYouSubHeadingMsg')"></h5>
            </div>

            <form v-on:submit.prevent="personalise()">
                <ul>
                    <li v-if="errorMessage" >
                        <p class="form-entry__error">
                            {{ $t('registerAboutYouRegistrationErrorMsg')}}<br>{{errorMessage}}
                        </p>
                    </li>
                    <li>
                        <label for="language">{{ $t('registerLabelLanguageMsg')}}</label>
                        <select id="language" v-model="language">
                            <option value="ENGLISH">{{ $t('commonLanguageEnglishMsg')}}</option>
                            <option value="FRENCH">{{ $t('commonLanguageFrenchMsg')}}</option>
                            <option value="PORTUGUESE">{{ $t('commonLanguagePortugueseMsg')}}</option>
                        </select>
                    </li>
                    <li>
                        <label for="userType">{{ $t('registerLabelUserTypeMsg')}}</label>
                        <select id="userType" v-model="userType">
                            <option value="STUDENT">{{ $t('commonUserTypeLearnerMsg')}}</option>
                            <option value="TEACHER">{{ $t('commonUserTypeTeacherMsg')}}</option>
                            <option value="PARENT">{{ $t('commonUserTypeParentMsg')}}</option>
                            <option value="NOT_APPLICABLE">{{ $t('registerOptionUserTypeNotApplicableMsg')}}</option>
                            <option value="OBSERVER">{{ $t('registerOptionUserTypeObserverMsg')}}</option>
                        </select>
                    </li>
                    <div v-show="isLearner">
                        <li>
                            <label for="grade">{{ $t('registerLabelGradeMsg')}}</label>
                            <select id="grade" v-model="grade">
                                <option value="0">{{ $t('registerOptionGradeSelectMsg')}}</option>
                                <option v-for="gradeNum in 12" v-bind:key="gradeNum" v-bind:value="gradeNum">{{gradeNum}}
                                </option>
                            </select>
                            <p v-if="showValidateErrorGrade" class="form-entry__error">
                                {{ $t('registerGradeValidationErrorMsg')}}
                            </p>
                        </li>
                        <li>
                            <label for="mathsMark">{{ $t('registerLabelMathsMarkMsg')}}</label>
                            <input type="text" id="mathsMark" v-model="mathsMark" placeholder="e.g. 75%" v-on:keydown="checkKeyDownValidMathsMark($event)">
                            <p v-if="showValidateErrorMathsMark" class="form-entry__error">
                                {{ $t('registerMathsMarkValidationErrorMsg')}}
                            </p>
                        </li>
                        <li>
                            <label for="nickname">{{ $t('registerLabelNicknameMsg')}} <span class="form-entry--note">(optional)</span></label>
                            <input type="text" id="nickname" v-model="nickname" placeholder="e.g. Bob">
                        </li>
                    </div>
                    <li>
                        <label for="country">{{ $t('registerLabelCountryMsg')}}</label>
                        <p class="form-entry--note">{{ $t('registerCountryNoteMsg')}}</p>
                        <select id="country" v-model="country">
                            <option v-for="(country, index) in countries" v-bind:key="index" v-bind:value="country">{{country}}</option>
                        </select>
                    </li>
                    <div v-if="isTeacher && !this.email">
                        <li>
                            <label for="teacherEmail">{{ $t('registerLabelEmail2Msg')}}<span class="form-entry--note"></span></label>
                            <input type="email" id="teacherEmail" v-model="teacherEmail">
                            <p v-if="showValidateErrorTeacherEmail" class="form-entry__error">
                                {{ $t('registerEmailValidationErrorMsg')}}
                            </p>
                        </li>
                    </div>
                    <div v-show="isLearnerOrTeacher">
                        <li class="register--school">
                            <label for="school">{{ $t('registerLabelSchoolMsg')}}</label>
                            <input type="text" id="school" v-model="school" v-on:keyup="autoComplete()" v-on:focusout="hideAutoComplete">
                            <div class="register--school--auto-complete" v-show="showAutoComplete">
                                <div>
                                    <ul>
                                        <li v-for="(item, index) in autoCompleteOptions" v-bind:key="index" v-on:click="selectSchool(item)">{{item}}</li>
                                    </ul>
                                </div>
                            </div>
                            <p v-if="showValidateErrorSchool" class="form-entry__error">
                                {{ $t('registerSchoolValidationErrorMsg')}}
                            </p>
                        </li>
                    </div>
                    <div v-show="isLearner && isSouthAfrican">
                        <li>
                            <label for="gender">{{ $t('registerLabelGenderMsg')}}</label>
                            <select id="gender" v-model="gender">
                                <option value="0">{{ $t('registerOptionGenderSelectMsg')}}</option>
                                <option value="MALE">{{ $t('commonGenderMaleMsg')}}</option>
                                <option value="FEMALE">{{ $t('commonGenderFemaleMsg')}}</option>
                                <option value="OTHER">{{ $t('commonGenderOtherMsg')}}</option>
                                <option value="ANONYMOUS">{{ $t('commonAnonymousMsg')}}</option>
                            </select>
                            <p v-if="showValidateErrorGender" class="form-entry__error">
                                {{ $t('registerGenderValidationErrorMsg')}}
                            </p>
                        </li>
                        <li>
                            <label for="demographic">{{ $t('registerLabelRaceMsg')}}</label>
                            <select id="demographic" v-model="demographic">
                                <option value="0">{{ $t('registerOptionRaceSelectMsg')}}</option>
                                <option value="BLACK">{{ $t('commonRaceBlackMsg')}}</option>
                                <option value="COLOURED">{{ $t('commonRaceColouredMsg')}}</option>
                                <option value="INDIAN">{{ $t('commonRaceIndianMsg')}}</option>
                                <option value="WHITE">{{ $t('commonRaceWhiteMsg')}}</option>
                                <option value="ANONYMOUS">{{ $t('commonAnonymousMsg')}}</option>
                            </select>
                            <p v-if="showValidateErrorDemographic" class="form-entry__error">
                                {{ $t('registerRaceValidationErrorMsg')}}
                            </p>
                        </li>
                    </div>
                    <li v-show="personalising">
                        <spinner height="40px" colour="#fff"/>
                    </li>
                    <li v-show="!personalising">
                        <button type="submit">{{ $t('registerButtonMsg')}}</button>
                    </li>
                </ul>
            </form>
        </template>
    </div>
</template>

<script>
    import {API, GRAPHQL_AUTH_MODE, graphqlOperation} from "@aws-amplify/api";
    import { Auth } from 'aws-amplify';
    import { countries } from '../countries.js'

    import {store, mutations} from '../store.js'

    import user from '../domain/user';

    // Queries & mutations
    import {isUsernameTaken, searchSchools} from "../mytutor-graphql/queries";
    import {createAndSetupUser, personaliseUser} from "../mytutor-graphql/mutations";

    // Mixins
    import {apiMixin} from "../mixins/APIMixin";
    import {i18nMixin} from "../mixins/I18nMixin";

    // Visual components
    import Spinner from '../components/Spinner.vue';

    export const REGISTRATION_STATES = Object.freeze({
        CREATE_ACCOUNT: 0,
        ABOUT_YOU: 1,
    });

    export const USER_TYPES = Object.freeze({
        LEARNER: "STUDENT",
        TEACHER: "TEACHER",
        PARENT: "PARENT",
        NOT_APPLICABLE: "NOT_APPLICABLE",
        OBSERVER: "OBSERVER",
    });

    export const LANGUAGES = Object.freeze({
        ENGLISH: "ENGLISH",
        FRENCH: "FRENCH",
        PORTUGUESE: "PORTUGUESE",
    });

    export default {
        name: "Register",
        components: {Spinner},
        created() {
        },
        mixins: [apiMixin, i18nMixin],
        props: {
            cognitoUser: Object
        },
        data() {
            return {
                state: REGISTRATION_STATES.CREATE_ACCOUNT,
                localCognitoUser: null,
                userSub: null,
                user: null,

                firstName: "",
                lastName: "",
                username: "",
                password: "",
                email: "",
                checkingUsername: false,
                showUsernameUnique: false,
                showUsernameTaken: false,
                showPassword: false,
                showValidateErrorFirstName: false,
                showValidateErrorLastName: false,
                showValidateErrorUsername: false,
                showValidateErrorPassword: false,
                showValidateErrorEmail: false,
                registering: false,

                nickname: "",
                language: LANGUAGES.ENGLISH,
                userType: USER_TYPES.LEARNER,
                teacherEmail: "",
                grade: 0,
                mathsMark: "",
                country: "South Africa",
                school: "",
                gender: 0,
                demographic: 0,
                showAutoComplete: false,
                autoCompleteOptions: ["Rondebosch Junion School", "Rondebosch High School"],
                showValidateErrorTeacherEmail: false,
                showValidateErrorGrade: false,
                showValidateErrorMathsMark: false,
                showValidateErrorSchool: false,
                showValidateErrorGender: false,
                showValidateErrorDemographic: false,
                personalising: false,

                errorMessage: ""
            }
        },
        computed: {
            getUser: function() {
                return store.user;
            },
            isCreatingAccount: function() {
                return this.state === REGISTRATION_STATES.CREATE_ACCOUNT;
            },
            isAboutYou: function() {
                return this.state === REGISTRATION_STATES.ABOUT_YOU;
            },
            isLearner: function() {
                return this.userType === USER_TYPES.LEARNER;
            },
            isTeacher: function() {
                return this.userType === USER_TYPES.TEACHER;
            },
            isLearnerOrTeacher: function() {
                const type = this.userType;
                return (type === USER_TYPES.LEARNER || type === USER_TYPES.TEACHER);
            },
            countries: function() {
                return countries;
            },
            isSouthAfrican: function() {
                return this.country === 'South Africa';
            }
        },
        methods: {
            // ----------------------------------------------------------------------------
            //
            // API calls
            //
            // ----------------------------------------------------------------------------
            checkIfUsernameTaken: async function(username) {
                console.debug('checking if username has been taken: ' + username);
                try {
                    let variables = {
                        username: username,
                    };
                    const usernameRet = await API.graphql({
                        query: isUsernameTaken,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(usernameRet);
                    if (usernameRet.data.isUsernameTaken !== null) {
                        console.debug("Answer received");
                        return usernameRet.data.isUsernameTaken;
                    } else {
                        console.debug("Error checking if username has been taken");
                        this.logError("Checking if username taken", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Checking if username taken", error, true);
                    return null;
                }
            },
            registerUser: async function(owner, username, firstName, lastName, email) {
                console.debug('registering user: ' + username);
                this.errorMessage = "";
                try {
                    let variables = {
                        owner: owner,
                        username: username,
                        firstName: firstName,
                        surname: lastName
                    };
                    if (email) {
                        variables.email = email;
                    }
                    const userRet = await API.graphql({
                        query: createAndSetupUser,
                        variables: variables,
                        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                    });
                    console.debug(userRet);
                    if (userRet.data.createAndSetupUser !== null) {
                        console.debug("Answer received");
                        return userRet.data.createAndSetupUser;
                    } else {
                        console.debug("Error registering user in MyTutor");
                        this.logError("Error registering user in MyTutor", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Error registering user in MyTutor", error, true);
                    console.debug('Error registering user in MyTutor', error);
                    return null;
                }
            },
            personaliseUser: async function(userID, language, type, country, teacherEmail, school, grade, mathsMark, nickname, gender, race) {
                console.debug('personalising user: ' + userID);
                try {
                    let variables = {
                        userID: userID,
                        language: language,
                        type: type,
                        country: country,
                    };
                    if (teacherEmail) {
                        variables.teacherEmail = teacherEmail;
                    }
                    if (school) {
                        variables.school = school;
                    }
                    if (grade) {
                        variables.grade = grade;
                    }
                    if (mathsMark) {
                        if (mathsMark.charAt(mathsMark.length - 1) === '%') {
                            mathsMark = mathsMark.substring(0, mathsMark.length - 1);
                        }
                        variables.mathsMark = mathsMark;
                    }
                    if (nickname) {
                        variables.nickname = nickname;
                    }
                    if (gender) {
                        variables.gender = gender;
                    }
                    if (race) {
                        variables.race = race;
                    }
                    console.debug(variables);
                    const personalisationRet = await API.graphql(graphqlOperation(personaliseUser, variables));
                    console.debug(personalisationRet);
                    if (personalisationRet.data.personaliseUser !== null) {
                        console.debug("Answer received");
                        return personalisationRet.data.personaliseUser;
                    } else {
                        console.debug("Error checking if username has been taken");
                        this.logError("Checking if username taken", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Checking if username taken", error, true);
                    return null;
                }
            },
            fetchAutoCompleteOptionsFromServer: async function(country, response) {
                console.log('fetching auto complete options for response: ' + response);
                if (!response) {
                    return [];
                }

                try {
                    const searchText = response.toLowerCase();
                    const terms = searchText.split(" ");
                    let andTerms = [];
                    andTerms.push({country: {eq: country}});
                    for (let term of terms) {
                        andTerms.push({ or: [{name: {wildcard: "*" + term + "*"}}, {nameASCII: {wildcard: "*" + term + "*"}}] });
                    }

                    const autoCompleteRet = await API.graphql(graphqlOperation(searchSchools, {filter: {and: andTerms} , limit: 5 }));
                    console.log(autoCompleteRet);
                    if (autoCompleteRet.data.searchSchools !== null) {
                        console.log("Auto complete options found");
                        return autoCompleteRet.data.searchSchools.items;
                    } else {
                        console.log("Error fetching auto complete options");
                        this.logError("Fetching auto complete", "No data returned", true);
                        return null;
                    }
                } catch (error) {
                    this.logAPIError("Fetching auto complete", error, true);
                    return null;
                }
            },
            checkKeyDownValidUsernameChar: function(event) {
                this.showValidateErrorUsername = false;
                if (!/[a-zA-Z0-9\-_.@]/.test(event.key)) {
                    event.preventDefault();
                }
            },
            checkKeyDownValidMathsMark: function(event) {
                if (/ArrowLeft|ArrowRight|Delete|Backspace|Shift|Tab/.test(event.key)) {
                    return;
                }
                this.showValidateErrorMathsMark = false;
                if (!/[0-9%]/.test(event.key)) {
                    const ignoredValue = event.key ? event.key : "";
                    console.debug("Ignored: " + ignoredValue);
                    event.preventDefault();
                }
                if (this.mathsMark && !this.isValidMathsMark()) {
                    this.showValidateErrorMathsMark = true;
                }
            },
            isValidMathsMark: function() {
                if (/^[1-9][0-9]?(%)?$|^100(%)?$|^0(%)?$/.test(this.mathsMark)) {
                    return true;
                } else {
                    return false;
                }
            },


            // ----------------------------------------------------------------------------
            //
            // Functionality
            //
            // ----------------------------------------------------------------------------
            testIfUserNameTaken: async function () {
                this.showUsernameTaken = false;
                this.showUsernameUnique = false;
                this.showValidateErrorUsername = false;

                if (this.username) {
                    if (this.username.length < 6) {
                        this.showValidateErrorUsername = true;
                        return;
                    }

                    this.checkingUsername = true;
                    const taken = await this.checkIfUsernameTaken(this.username);
                    if (taken) {
                        this.showUsernameTaken = true;
                    } else {
                        this.showUsernameUnique = true;
                    }
                    this.checkingUsername = false;
                }
            },
            revealPassword: function () {
                if (this.showPassword) {
                    console.debug("showing password");
                    // this.$refs.passwordInput.type = "text";
                } else {
                    console.debug("hiding password");
                    // this.$refs.passwordInput.type = "password";
                }
            },
            register: async function() {
                console.debug("validating registration form");
                if (!this.validateRegistrationForm()) {
                    return;
                }

                console.debug("Registering with Cognito: " + this.username);
                this.registering = true;
                try {
                    let params = {
                        username: this.username,
                        password: this.password,
                        attributes: {
                            given_name: this.firstName,
                            family_name: this.lastName,
                        },
                        autoSignIn: { // optional - enables auto sign in after user is confirmed
                            enabled: true,
                        }
                    };
                    if (this.email) {
                        params.attributes.email = this.email.toLowerCase();
                    }
                    console.debug(params);

                    const userRet = await Auth.signUp(params);
                    this.localCognitoUser = userRet.user;
                    this.userSub = userRet.userSub;
                    console.debug("Registered with Cognito");
                    console.debug(this.localCognitoUser);
                } catch (error) {
                    console.debug('Error registering', error);
                    if (error.message) {
                        this.errorMessage = error.message;
                    } else {
                        this.errorMessage = this.$t('commonUnknownErrorMsg');
                    }
                    this.registering = false;
                    return;
                }

                console.debug("Registering with Mytutor: " + this.username);
                const mytutorUser = await this.registerUser(this.username, this.username, this.firstName, this.lastName, this.email);
                if (!mytutorUser) {
                    this.errorMessage = this.$t('registerErrorRegisteringOnPlatformMsg');
                    return;
                }
                mutations.setUser(user(mytutorUser));

                try {
                    console.debug("Signing in with Cognito");
                    this.localCognitoUser = await Auth.signIn(this.username, this.password);
                    console.debug(this.localCognitoUser);
                } catch (error) {
                    console.debug('Error registering - cannot confirm account');
                    console.error(error);
                    this.errorMessage = this.$t('registerErrorRegisteringCannotConfirmMsg');
                    this.registering = false;
                    return;
                }

                this.registering = false;
                this.state = REGISTRATION_STATES.ABOUT_YOU;
            },
            validateRegistrationForm: function() {
                let validated = true;

                this.showValidateErrorFirstName = false;
                this.showValidateErrorLastName = false;
                this.showValidateErrorUsername = false;
                this.showValidateErrorPassword = false;

                if (!this.firstName) {
                    this.showValidateErrorFirstName = true;
                    validated = false;
                }
                if (!this.lastName) {
                    this.showValidateErrorLastName = true;
                    validated = false;
                }
                if (!this.username || this.username.length < 6) {
                    this.showValidateErrorUsername = true;
                    validated = false;
                }
                if (!this.password) {
                    this.showValidateErrorPassword = true;
                    validated = false;
                }
                if (this.email) {
                    const emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$', 'i');
                    if (!emailRegex.test(this.email)) {
                        this.showValidateErrorEmail = true;
                        validated = false;
                    }
                }
                return validated;
            },
            personalise: async function() {
                console.debug("validating personalisation form");
                if (!this.validatePersonalisationForm()) {
                    return;
                }

                this.personalising = true;
                const updatedUser = await this.personaliseUser(this.getUser.getID(),
                    this.language,
                    this.userType,
                    this.country,
                    this.teacherEmail,
                    this.school,
                    this.grade,
                    this.mathsMark,
                    this.nickname,
                    this.gender,
                    this.demographic);

                if (updatedUser) {
                    this.$root.$emit('manual-sign-in', this.localCognitoUser);
                } else {
                    this.errorMessage = this.$t('registerErrorRegisteringOnPlatformMsg');
                    this.personalising = false;
                    return;
                }
            },
            validatePersonalisationForm: function() {
                let validated = true;

                this.showValidateErrorGrade = false;
                this.showValidateErrorMathsMark = false;
                this.showValidateErrorSchool = false;
                this.showValidateErrorGender = false;
                this.showValidateErrorDemographic = false;

                if (this.userType === USER_TYPES.LEARNER || this.userType === USER_TYPES.TEACHER) {
                    if (!this.school) {
                        this.showValidateErrorSchool = true;
                        validated = false;
                    }
                }

                if (this.userType === USER_TYPES.TEACHER && !this.email) {
                    if (this.teacherEmail) {
                        const emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$', 'i');
                        if (!emailRegex.test(this.teacherEmail)) {
                            this.showValidateErrorTeacherEmail = true;
                            validated = false;
                        }
                    } else {
                        this.showValidateErrorTeacherEmail = true;
                        validated = false;
                    }
                }

                if (this.userType === USER_TYPES.LEARNER) {
                    if (this.grade === 0) {
                        this.showValidateErrorGrade = true;
                        validated = false;
                    }
                    if (!this.mathsMark) {
                        this.showValidateErrorMathsMark = true;
                        validated = false;
                    }
                    if (!this.isValidMathsMark()) {
                        this.showValidateErrorMathsMark = true;
                        validated = false;
                    }
                    if (this.isSouthAfrican) {
                        if (this.gender === 0) {
                            this.showValidateErrorGender = true;
                            validated = false;
                        }
                        if (this.demographic === 0) {
                            this.showValidateErrorDemographic = true;
                            validated = false;
                        }
                    }
                }

                return validated;
            },
            autoComplete: async function() {
                let options = await this.fetchAutoCompleteOptionsFromServer(this.country, this.school);
                if (options && options.length > 0) {
                    this.autoCompleteOptions = options.map(school => school.name);
                    this.showAutoComplete = true;
                }
            },
            hideAutoComplete: function() {
                setTimeout(() => {
                    this.showAutoComplete = false;
                }, 500);
            },
            selectSchool: function(school) {
                this.school = school;
                this.showAutoComplete = false;
            },
            selectLocale: function(locale) {
                switch (locale) {
                    case 'en':
                        this.language = LANGUAGES.ENGLISH;
                        break;
                    case 'fr':
                        this.language = LANGUAGES.FRENCH;
                        break;
                    case 'pt':
                        this.language = LANGUAGES.PORTUGUESE;
                        break;
                }
            }
        },
        watch: {
            'showPassword': function () {
                if (this.showPassword) {
                    this.$refs.passwordInput.type = "text";
                } else {
                    this.$refs.passwordInput.type = "password";
                }
            },
            '$i18n.locale': function(newVal) {
                this.selectLocale(newVal);
            }
        },
        mounted: async function () {
            let query = Object.assign({}, this.$route.query);
            if (query.step && query.step === 'personalisation') {
                this.state = REGISTRATION_STATES.ABOUT_YOU;
                this.user = store.user;
                this.localCognitoUser = this.cognitoUser;
                delete query.step;
                await this.$router.replace({query});
            }
            this.selectLocale(this.locale);
        },
        beforeDestroy: async function () {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/layout/forms";
    @import "../styles/pages/register";
</style>

<style lang="scss" scoped>
    form {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px 30px;
    }

    @media screen and (max-width:414px) {
        form {
            padding: 20px 10px;
        }
    }
</style>